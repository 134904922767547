import React from 'react';
import { DateRange } from '../index';
import SHARED_CONSTANTS from '../../common/sharedConstants';

const { DATE_FILTERS } = SHARED_CONSTANTS;

const FilterByDate = ({
  customText,
  minDate,
  maxDate,
  startDate,
  endDate,
  quickFilterSelected,
  onQuickFilterSelectFn,
  clearDateFn,
  selectDateRangeFn
}) => (
  <div className='row'>
    <div className='col-xs-8 text-left'>
      {customText ? (
        <p className='inline-block marginBottom0 marginRight5 paddingLeft5 marginTop3'>
          {customText}
        </p>
      ) : (
        ''
      )}
      <DateRange
        classes='inline-block'
        innerClasses='float-right'
        minDate={minDate}
        maxDate={maxDate}
        startDate={startDate}
        endDate={endDate}
        clearDateFn={clearDateFn}
        onSelect={selectDateRangeFn}
      />
    </div>
    <div className='col-xs-8 text-right'>
      <a
        className={`${
          quickFilterSelected === DATE_FILTERS.PAST_YEAR ? 'red' : ' '
        } dark-grey marginTop3 marginBottom0 inline-block marginRight20`}
        onClick={() => {
          onQuickFilterSelectFn(DATE_FILTERS.PAST_YEAR);
        }}
      >
        Past Year
      </a>
      <a
        className={`${
          quickFilterSelected === DATE_FILTERS.PAST_SIX_MONTHS ? 'red' : ' '
        } dark-grey marginTop3 marginBottom0 inline-block marginRight20`}
        onClick={() => {
          onQuickFilterSelectFn(DATE_FILTERS.PAST_SIX_MONTHS);
        }}
      >
        Past 6 Months
      </a>
      <a
        className={`
            ${
              quickFilterSelected === DATE_FILTERS.PAST_THREE_MONTHS
                ? 'red'
                : ' '
            } dark-grey marginTop3 marginBottom0 inline-block marginRight20`}
        onClick={() => {
          onQuickFilterSelectFn(DATE_FILTERS.PAST_THREE_MONTHS);
        }}
      >
        Past 3 Months
      </a>
      <a
        className={`${
          quickFilterSelected === DATE_FILTERS.PAST_MONTH ? 'red' : ' '
        } dark-grey marginTop3 marginBottom0 inline-block marginRight20`}
        onClick={() => {
          onQuickFilterSelectFn(DATE_FILTERS.PAST_MONTH);
        }}
      >
        Past Month
      </a>
    </div>
  </div>
);

export default FilterByDate;
