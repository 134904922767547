import React from 'react';
import COMMON_CONSTANTS from 'common/commonConstants';

const { FEEDBACK_VISIBLE } = COMMON_CONSTANTS;

const VisibleSelector = ({
  value, onClick, userName, managerName
}) => (
  <div>
    <div>
      <button
        className={`${
          value === FEEDBACK_VISIBLE.PUBLIC
            ? 'bg-yellow rounded-lg py-1 text-black'
            : 'text-light-gray hover:text-black transition-colors duration-300'
        } focus:outline-none px-2 mr-4 font-bold text-md`}
        onClick={() => {
          onClick(FEEDBACK_VISIBLE.PUBLIC);
        }}
      >
        Public
      </button>
      <button
        className={`${
          value === FEEDBACK_VISIBLE.PRIVATE
            ? 'bg-yellow rounded-lg py-1 text-black'
            : 'text-light-gray hover:text-black transition-colors duration-300'
        } focus:outline-none px-2 mr-4 font-bold text-md`}
        onClick={() => {
          onClick(FEEDBACK_VISIBLE.PRIVATE);
        }}
      >
        Private
      </button>
      {managerName ? (
        <>
          <button
            className={`${
              value === FEEDBACK_VISIBLE.PRIVATE_AND_MANAGER
                ? 'bg-yellow rounded-lg py-1 text-black'
                : 'text-light-gray hover:text-black transition-colors duration-300'
            } focus:outline-none px-2 mr-4 font-bold text-md`}
            onClick={() => {
              onClick(FEEDBACK_VISIBLE.PRIVATE_AND_MANAGER);
            }}
          >
            Private + Manager
          </button>
          <button
            className={`${
              value === FEEDBACK_VISIBLE.MANAGER
                ? 'bg-yellow rounded-lg py-1 text-black'
                : 'text-light-gray hover:text-black transition-colors duration-300'
            } focus:outline-none px-2 mr-4 font-bold text-md`}
            onClick={() => {
              onClick(FEEDBACK_VISIBLE.MANAGER);
            }}
          >
            Manager Only
          </button>
          <button
            className={`${
              value === FEEDBACK_VISIBLE.MANAGER_AND_UP
                ? 'bg-yellow rounded-lg py-1 text-black'
                : 'text-light-gray hover:text-black transition-colors duration-300'
            } focus:outline-none px-2 mr-4 font-bold text-md`}
            onClick={() => {
              onClick(FEEDBACK_VISIBLE.MANAGER_AND_UP);
            }}
          >
            Manager and Above
          </button>
        </>
      ) : null}
    </div>
    <p className='mt-4 ml-2 text-md'>
      {value === FEEDBACK_VISIBLE.PUBLIC ? 'Visible to organization.' : null}
      {value === FEEDBACK_VISIBLE.PRIVATE
        ? `Only ${userName} will be able to see this feedback.`
        : null}
      {managerName && value === FEEDBACK_VISIBLE.PRIVATE_AND_MANAGER
        ? `Visible only to ${userName} and their manager (${managerName}).`
        : ''}
      {managerName && value === FEEDBACK_VISIBLE.MANAGER
        ? `Visible only to ${userName}'s manager (${managerName}).`
        : ''}
      {managerName && value === FEEDBACK_VISIBLE.MANAGER_AND_UP
        ? `Visible to everyone above ${userName}.`
        : ''}
    </p>
  </div>
);

export default VisibleSelector;
