import React, { createContext, useReducer } from 'react';
import reducer from './reducer';
import initialState from './state';
import { updateData } from './actions';

export const CompanyDashContext = createContext();

const Provider = ({ children }) => {
  const [context, dispatch] = useReducer(reducer, initialState);

  const updateContext = (data) => {
    dispatch(updateData(data));
  };

  const resetContext = () => {
    dispatch(updateData(initialState));
  };

  return (
    <CompanyDashContext.Provider
      value={{
        context,
        dispatch,
        updateContext,
        resetContext
      }}
    >
      {children}
    </CompanyDashContext.Provider>
  );
};

export default Provider;
