import React, { useContext, useState } from 'react';
import BuilderContainer from 'src/pages/PerformanceBuilder/containers/BuilderContainer';
import ChevronDownSVG from 'src/assets/svg/chevronDown.svg';
import { Button, toast } from 'src/components';
import {
  useUpdateBuilderReport,
  QUERY_KEYS as BUILDER_QUERY_KEYS,
  useBuilderReport
} from 'src/queries/builder';
import commonUtils from 'common/commonUtils';
import { PerformanceBuilderContext } from 'src/pages/PerformanceBuilder/PerformanceBuilderContext';
import { useQueryClient } from 'react-query';
import { isEmpty, get } from 'lodash';
import COMMON_QUESTION_CONSTANTS from 'common/commonQuestionConstants';
import commonReviewUtils from 'common/commonReviewUtils';

const SupplementalQuestion = ({ question }) => {
  const {
    data: {
      editToken, form, prevDataRef, setOpenState, reportId
    }
  } = useContext(PerformanceBuilderContext);
  const { isFetching } = useBuilderReport(reportId);

  const queryClient = useQueryClient();
  const { register, watch, getValues } = form;
  const prevData = prevDataRef.current;
  const { update: updateReport, isLoading: isUpdateReportLoading } = useUpdateBuilderReport(editToken);

  const [isQuestionOpen, setIsQuestionOpen] = useState(false);

  const { id: questionId, status } = question;
  const isQuestionActive = status === COMMON_QUESTION_CONSTANTS.STATUS.ACTIVE;

  const formValues = watch();
  const {
    supplementalReviews, reviewerId, revieweeId, revieweeFirstName
  } = formValues;
  const index = supplementalReviews.findIndex(
    (r) => r.questionId === questionId
  );
  const supplementalReview = supplementalReviews[index];
  if (!supplementalReview) return null;

  const { comments: reviewComments } = supplementalReview;

  let questionText = '';
  const isSelf = reviewerId === revieweeId;
  if (isSelf) questionText = get(question, 'self', '');
  else questionText = get(question, 'question', '');

  if (isEmpty(questionText)) return null;

  questionText = commonReviewUtils.filloutReviewQuestionName(
    questionText,
    revieweeFirstName
  );

  const switchIsQuestionOpen = () => setIsQuestionOpen(!isQuestionOpen);
  setOpenState[questionId] = setIsQuestionOpen;

  const getIsButtonDisabled = () => {
    if (!isQuestionActive || isFetching || isEmpty(reviewComments)) return true;

    const { supplementalReviews: oldSupplementalReviews } = prevData;
    const oldSupplementalReview = oldSupplementalReviews.find(
      (r) => r.questionId === questionId
    );
    if (!oldSupplementalReview) return false;
    const { comments: oldComments } = oldSupplementalReview;

    const canSubmitForm = !commonUtils.isSame(oldComments, reviewComments);

    return !canSubmitForm;
  };

  const update = async () => {
    const { supplementalReviews: oldSupplementalReviewsArray } = prevData;
    const newSupplementalReviewsArray = [...oldSupplementalReviewsArray];
    const existingSupplementalReview = newSupplementalReviewsArray.find(
      (r) => r.questionId === questionId
    );
    existingSupplementalReview.comments = reviewComments;

    const supplementalReviewsToSave = newSupplementalReviewsArray
      .filter((r) => !isEmpty(r.comments))
      .map((r) => ({
        roleId: r.roleId,
        questionId: r.questionId,
        comments: r.comments
      }));
    const data = {
      supplementalReviews: supplementalReviewsToSave
    };

    await updateReport({ data });
    queryClient.invalidateQueries([BUILDER_QUERY_KEYS.BUILDER, reportId]);
    queryClient.invalidateQueries([BUILDER_QUERY_KEYS.BUILDER, editToken]);
    queryClient.invalidateQueries(BUILDER_QUERY_KEYS.BUILDER_DASHBOARD);
    toast.show('Performance review updated!');

    prevDataRef.current = JSON.parse(JSON.stringify(getValues()));
    setIsQuestionOpen(false);
  };

  return (
    <BuilderContainer loading={isUpdateReportLoading}>
      <div
        className={`mb-4 min-h-20 py-2 w-full flex flex-col items-center rounded-lg text-left border border-slate-300 shadow px-6 ${
          isQuestionOpen ? 'pb-4' : ''
        }`}
      >
        <button
          className='w-full items-center text-left min-h-16 flex justify-between'
          onClick={switchIsQuestionOpen}
          type='button'
        >
          <span className='text-sm leading-5 font-bold w-[90%]'>
            {questionText}
          </span>
          <span
            className={`border-none focus:ring-0 transform ${
              isQuestionOpen ? '' : 'rotate-180'
            } focus:outline-none w-4 h-4 mr-2`}
          >
            <ChevronDownSVG width={20} height={20} />
          </span>
        </button>
        {isQuestionOpen ? (
          <div className='w-full flex flex-col'>
            <div className='w-full h-full'>
              <textarea
                maxLength='2000'
                {...register(`supplementalReviews[${index}].comments`)}
                className='h-28 text-sm border border-slate-300 bg-white rounded-lg w-full'
                placeholder='Type your response here'
                disabled={!isQuestionActive}
              />
            </div>
            <div className='w-full flex justify-between mt-6'>
              <Button
                type='button'
                disabled={getIsButtonDisabled()}
                variant='custom'
                classes='w-[7rem] bg-[#0F172A] text-white text-base px-4 py-2 rounded-md transition-colors duration-300'
                onClick={update}
              >
                Save
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    </BuilderContainer>
  );
};

export default SupplementalQuestion;
