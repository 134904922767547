import React, { useState, useContext } from 'react';
import {
  Base,
  Modal,
  ToggleBundledCategories,
  DisplayBundledCategories,
  DisplayRoleCategories
} from 'src/components';
import CategoryBox from 'src/componentsAdvanced/CategoryBox/CategoryBox';
import PerformanceCategoryModal from 'src/containers/PerformanceCategoryModal/PerformanceCategoryModal';
import { useTeamProfile } from 'src/pagesDashboard/DashTeam/queries';
import { DashTeamContext } from 'src/pagesDashboard/DashTeam/context/DashTeamProvider';
import STYLE from 'src/constants/style';

const Categories = () => {
  const { context, updateContext } = useContext(DashTeamContext);
  const { showBundledCategories } = context;
  const {
    categories,
    rolesData,
    bundledCategories,
    isFetching: isFetchingTeamProfile,
    isError: isErrorTeamProfile
  } = useTeamProfile();

  const isFetching = isFetchingTeamProfile;
  const isError = isErrorTeamProfile;
  const isReady = categories && rolesData && bundledCategories && !isFetching && !isError;

  const [local, setLocal] = useState({ showModal: false });

  if (!isReady) {
    return null;
  }

  const showModal = (data) => {
    setLocal({ ...local, showModal: data });
  };
  const hideModal = () => {
    setLocal({ ...local, showModal: false });
  };

  if (!categories) {
    return <Base classes='w-full h-40' loading />;
  }

  const bundleCategories = () => {
    const newState = {
      ...context,
      showBundledCategories: !showBundledCategories
    };
    updateContext({ showBundledCategories: !showBundledCategories });
  };

  return (
    <Base classes={STYLE.CONTAINER_BLUE}>
      {local.showModal ? (
        <PerformanceCategoryModal
          close={hideModal}
          data={local.showModal}
          options={{
            showFeedbackTab: false
          }}
        />
      ) : null}

      <ToggleBundledCategories
        onClick={bundleCategories}
        showBundled={showBundledCategories}
      />

      {showBundledCategories ? (
        <DisplayBundledCategories
          categories={bundledCategories}
          onCategoryClick={(category) => {
            showModal({
              name: category.label,
              category
            });
          }}
        />
      ) : null}

      {!showBundledCategories ? (
        <DisplayRoleCategories
          roles={rolesData}
          onCategoryClick={(category) => {
            showModal({
              name: category.label,
              category
            });
          }}
        />
      ) : null}
    </Base>
  );
};

export default Categories;
