import React from 'react';

const PulseInputSection = ({
  id,
  labelText,
  children,
  errorMessage,
  labelClasses,
  description
}) => (
  <section className='mb-10 md:mb-16 flex justify-between flex-col md:flex-row'>
    <label
      htmlFor={id}
      className={`text-gray-500 mb-4 font-bold text-xl ${labelClasses}`}
    >
      <span className='font-bold text-xl text-gray-500 uppercase'>
        {labelText}
      </span>
      {description && <span className='block'>{description}</span>}
    </label>

    <div className='w-full max-w-34rem'>
      {children}
      <div className='text-red whitespace-no-wrap flex items-center mt-2 absolute'>
        {errorMessage}
      </div>
    </div>
  </section>
);
export default PulseInputSection;
