import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import {
  Button, Base, toast, Radio
} from 'src/components/';
import { useCompany } from 'src/queries/company';
import commonQuestions from 'common/commonQuestions';
import appUtils from 'src/components/appUtils';
import {
  disconnectCommunicationQuery,
  updateCommunicationQuery
} from 'src/queries/user';
import COMMON_CONSTANTS from 'common/commonConstants';
import { sendManualReview } from 'src/queries/reviews';
import STYLE from 'src/constants/style';
import { useAccount } from 'src/queries/account';

const { COMMUNICATION_TYPES } = COMMON_CONSTANTS;

const Communication = () => {
  const [communication, setCommunication] = useState(null);
  const {
    data: account,
    refetch: refetchAccount,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount('me');
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const {
    mutateAsync: updateCommunication,
    isLoading: isLoadingUpdateCommunications
  } = updateCommunicationQuery();
  const { mutateAsync: requestAReview, isLoading: isLoadingSendManualReview } = sendManualReview();
  const {
    mutateAsync: disconnectCommunication,
    isLoading: isLoadingDisconnectCommunication
  } = disconnectCommunicationQuery();

  const isFetching = isFetchingAccount || isFetchingCompany;
  const isError = isErrorAccount || isErrorCompany;
  const isReady = account && account._id && company && company.id && !isFetching && !isError;

  useEffect(() => {
    if (!isReady) return;
    setCommunication(account.preferredCommunication);
  }, [isReady]);

  if (!isReady) {
    return null;
  }

  const triggerRequestReview = async () => {
    try {
      toast.show('Sending test event');
      const reviewRelationship = get(account, 'reviews[0]', null);
      const roleId = get(reviewRelationship, 'roles[0]', null);
      if (!reviewRelationship || !roleId) {
        return toast.error('You do not review anyone');
      }
      const roleObj = commonQuestions.getRoleById(roleId, company.questions);
      const categoryId = roleObj.categories[0];
      const categoryObj = commonQuestions.getCategory(
        categoryId,
        company.questions.CATEGORIES
      );
      const questionId = categoryObj.questions[0];

      const result = await requestAReview({
        reviewerId: account._id,
        revieweeId: reviewRelationship.userId,
        roleId,
        reviewsRequestData: [
          {
            categoryId,
            questionId
          }
        ],
        schedule: {
          scheduleTime: 'immediately'
        }
      });
      if (!result || !result.success) {
        throw result;
      }

      toast.show('Done');
    } catch (error) {
      console.error('Communication.requestReview', error, account);
      return toast.error('We ran into an issue - try again later!');
    }
  };

  const discSlack = async () => {
    toast.show('Disconnecting..');
    const result = await disconnectCommunication({
      communication: COMMUNICATION_TYPES.SLACK
    });
    refetchAccount();
    if (!result || !result.success) {
      const errMessage = result.message || 'Oops! Try again later.';
      return toast.error(errMessage);
    }
    toast.show('Slack disconnected');
  };

  const discMSTeams = async () => {
    toast.show('Disconnecting..');
    const result = await disconnectCommunication({
      communication: COMMUNICATION_TYPES.MS_TEAMS
    });
    refetchAccount();
    if (!result || !result.success) {
      const errMessage = result.message || 'Oops! Try again later.';
      return toast.error(errMessage);
    }
    toast.show('Microsoft Teams disconnected');
  };

  const discWebex = async () => {
    toast.show('Disconnecting..');
    const result = await disconnectCommunication({
      communication: COMMUNICATION_TYPES.WEBEX
    });
    refetchAccount();
    if (!result || !result.success) {
      const errMessage = result.message || 'Oops! Try again later.';
      return toast.error(errMessage);
    }
    toast.show('Webex disconnected');
  };

  const save = async () => {
    const result = await updateCommunication({
      communication
    });
    refetchAccount();
    if (!result || !result.success) {
      return toast.error('Oops. Try again later.');
    }
    toast.show('Preference saved');
  };

  const showSaveBtn = true;

  return (
    <Base
      classes={STYLE.CONTAINER_WHITE}
      loading={
        isFetching
        || isLoadingDisconnectCommunication
        || isLoadingSendManualReview
        || isLoadingUpdateCommunications
      }
    >
      <h5>Communication</h5>
      <p>Please select your preferred communication method:</p>
      <div className='ml-2'>
        <div className='mb-3'>
          <Radio
            classes='align-center inline-block'
            name='preferredCommunication'
            value={COMMUNICATION_TYPES.EMAIL}
            title='Email'
            onChange={() => {
              setCommunication(COMMUNICATION_TYPES.EMAIL);
            }}
            checked={
              !communication || communication === COMMUNICATION_TYPES.EMAIL
            }
          />
        </div>
        {account.msTeamsAuth ? (
          <div className='mb-3 flex items-center'>
            <Radio
              classes='align-top inline-block'
              name='preferredCommunication'
              value={COMMUNICATION_TYPES.MS_TEAMS}
              title='Microsoft Teams'
              onChange={() => {
                setCommunication(COMMUNICATION_TYPES.MS_TEAMS);
              }}
              checked={communication === COMMUNICATION_TYPES.MS_TEAMS}
            />
            {account.msTeamsAuth ? (
              <button
                type='button'
                onClick={discMSTeams}
                className='align-top ml-5 mb-0 font-bold text-purple inline-block'
              >
                Disconnect
              </button>
            ) : null}
          </div>
        ) : null}
        {account.slackAuth ? (
          <div className='mb-3'>
            <Radio
              classes='align-top inline-block'
              name='preferredCommunication'
              value='slack'
              title='Slack'
              onChange={() => {
                setCommunication(COMMUNICATION_TYPES.SLACK);
              }}
              checked={communication === COMMUNICATION_TYPES.SLACK}
            />
            {account.slackAuth ? (
              <button
                type='button'
                onClick={discSlack}
                className='align-top -mt-0.5 ml-5 font-bold text-purple inline-block'
              >
                Disconnect
              </button>
            ) : null}
          </div>
        ) : null}
        {account.webexAuth ? (
          <div className='mb-3'>
            <div>
              <Radio
                classes='align-top inline-block'
                name='preferredCommunication'
                value='webex'
                title='Webex'
                onChange={() => {
                  setCommunication(COMMUNICATION_TYPES.WEBEX);
                }}
                checked={communication === COMMUNICATION_TYPES.WEBEX}
              />
              <button
                type='button'
                onClick={discWebex}
                className='align-top mt-0.5 ml-5 font-bold text-purple inline-block'
              >
                Disconnect
              </button>
            </div>
          </div>
        ) : null}

        {showSaveBtn ? (
          <div className='block mt-5'>
            <Button
              disabled={communication === account.preferredCommunication}
              variant='yellow'
              onClick={save}
            >
              Save
            </Button>
            {appUtils.canSendTestReviews(account.companyid) ? (
              <button
                className='mb-2 text-purple font-bold ml-5'
                onClick={triggerRequestReview}
              >
                Send test review
              </button>
            ) : null}
          </div>
        ) : null}
      </div>
    </Base>
  );
};

export default Communication;
