import commonTreeUtils from 'common/commonTreeUtils';
import COMMON_CONSTANTS from 'common/commonConstants';

const { REVIEW_FILTERS } = COMMON_CONSTANTS;

const utils = {};

utils.getActionsOptions = () => [{ id: 'excel', label: 'Export Data' }];

utils.getQuickFilterOptions = (userIds, tree, userTree, prefix = '') => {
  if (!tree || !userTree) {
    return [];
  }
  const reviewerOptions = [];
  userIds.forEach((userId) => {
    const treeNode = commonTreeUtils.findNodeById(tree, userId);
    if (!treeNode) {
      return;
    }
    reviewerOptions.push({
      id: userId,
      label: treeNode.name,
      name: treeNode.name
    });
  });

  const options = [];
  if (userTree.managerId) {
    options.push({
      id: REVIEW_FILTERS.ALL_ABOVE,
      label: `${prefix} higher levels`,
      name: 'All above'
    });

    // options.push({
    //   id: REVIEW_FILTERS.MANAGERS,
    //   label: '${prefix} managers',
    //   name: 'Managers'
    // });

    options.push({
      id: REVIEW_FILTERS.MANAGER,
      label: `${prefix} manager`,
      name: 'Manager'
    });

    const managerTree = commonTreeUtils.findNodeById(tree, userTree.managerId);
    const coworkers = managerTree.children.filter(
      (childTree) => childTree.id !== userTree.id
    );
    if (coworkers && coworkers.length) {
      options.push({
        id: REVIEW_FILTERS.COWORKERS,
        label: `${prefix} coworkers`,
        name: 'Coworkers'
      });
    }
  }

  if (userTree.children && userTree.children.length) {
    options.push({
      id: REVIEW_FILTERS.EMPLOYEES,
      label: `${prefix} direct reports`,
      name: 'Employees'
    });
  }

  options.push({
    id: REVIEW_FILTERS.SELF,
    label: `${prefix} self`,
    name: 'Self'
  });

  options.push({
    id: REVIEW_FILTERS.ALL_BUT_SELF,
    label: `${prefix} everyone except self`,
    name: 'All except self'
  });

  return [
    ...options,
    ...(reviewerOptions.length
      ? [
        { id: 'separator' },
        ...reviewerOptions.sort((a, b) => (a.name > b.name ? 1 : -1))
      ]
      : [])
  ];
};

export default utils;
