import React from 'react';
import SpeechBubbleSVG from 'src/assets/svg/speech-bubble.svg';
import NavigationItem from 'src/pagesDashboard/HomePage/components/mobile/NavigationItem';
import TrendSVG from 'src/assets/svg/trend.svg';
import FlagSVG from 'src/assets/svg/flag.svg';
import SpeechBubbleOvalSVG from 'src/assets/svg/speech-bubble-oval.svg';
import RequestFeedbackSVG from 'src/assets/svg/request-feedback.svg';
import { UserGroupIcon, UserIcon } from '@heroicons/react/24/outline';
import { Base } from 'src/components/';
import STYLE from 'src/constants/style';
import LogoutSVG from 'src/assets/log-out.svg';
import appUtils from 'src/components/appUtils';
import { useFlags } from 'launchdarkly-react-client-sdk';

const MobileHomePage = () => {
  const { showRequestReviewButton } = useFlags();

  return (
    <Base classes={STYLE.MOBILE_CONTAINER}>
      <NavigationItem
        variant='home'
        title='My Feedback'
        subtitle='Read through feedback shared with you'
        url='/dashboard/me/feedback'
      >
        <SpeechBubbleSVG />
      </NavigationItem>
      <NavigationItem
        variant='home'
        title='My Performance'
        subtitle='See your performance categories and scores'
        url='/dashboard/me/analytics'
      >
        <TrendSVG />
      </NavigationItem>
      <NavigationItem
        variant='home'
        title='My Goals'
        subtitle='View your goals and set new ones'
        url='/dashboard/me/goals'
      >
        <FlagSVG />
      </NavigationItem>
      <NavigationItem
        variant='home'
        title='Give Feedback'
        subtitle='Share feedback for your fellow team members'
        url='/dashboard/submit-feedback'
      >
        <SpeechBubbleOvalSVG />
      </NavigationItem>
      {
        showRequestReviewButton && (
          <NavigationItem
            variant='home'
            title='Request Feedback'
            subtitle='Ask for feedback from your fellow team members'
            url='/dashboard/request-feedback'
          >
            <RequestFeedbackSVG />
          </NavigationItem>
        )
      }
      <NavigationItem
        variant='home'
        title='People'
        subtitle='See who else is on your team'
        url='/dashboard/people'
      >
        <UserGroupIcon className='w-6 h-6' />
      </NavigationItem>
      <NavigationItem
        variant='home'
        title='My Profile'
        subtitle='View and edit your personal information'
        url='/dashboard/settings'
      >
        <UserIcon className='w-6 h-6' />
      </NavigationItem>
      <NavigationItem
        variant='home'
        title='Log Out'
        onClickFn={() => appUtils.logUserOut()}
      >
        <LogoutSVG stroke='black' />
      </NavigationItem>
    </Base>
  );
};

export default MobileHomePage;
