import commonDateUtils from 'common/commonDateUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import { appUtils } from 'src/components/index';

const { DATE_RANGE_FILTERS, CHART_VIEW, GOALS } = COMMON_CONSTANTS;

export default {
  userId: undefined,
  userTree: undefined,
  filters: {
    reviewerIds: null,
    reviewerGroup: null,
    revieweeIds: null,
    revieweeGroup: null,
    category: null,
    roles: null,
    status: Object.keys(GOALS.STATUS)
      .map((key) => GOALS.STATUS[key])
      .filter((status) => status !== GOALS.STATUS.COMPLETE)
  },
  options: {
    chartView: CHART_VIEW.OVERALL_AND_CATEGORIES
  },
  range: {
    value: DATE_RANGE_FILTERS.THREE_MONTHS.key,
    start: commonDateUtils.getDateFromDaysAgo(
      DATE_RANGE_FILTERS.THREE_MONTHS.days
    ).unix,
    end: undefined
  },
  userData: undefined,
  showFiltersView: true,
  showBundledCategories: true,
  showSelectDirectReview: false,
  historicReviews: {
    page: 1,
    pageSize: 5
  },
  profileHistoricReviews: {
    page: 1,
    pageSize: 10
  },
  currentPages: {
    actHistoricReviews: 1,
    actFeedback: 1,
    profileFeedbacks: 1
  },
  viewerId: appUtils.getLoggedUserId()
};
