import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import { Base } from 'src/components/';
import SpeechBubbleOvalSVG from 'src/assets/svg/speech-bubble-oval.svg';
import NavigationItem from 'src/pagesDashboard/HomePage/components/mobile/NavigationItem';
import COMMON_CONSTANTS from 'common/commonConstants';
import COMMON_QUESTION_CONSTANTS from 'common/commonQuestionConstants';
import STYLE from 'src/constants/style';

const { REVIEW_ANSWER_TYPE, IM_NOT_SURE } = COMMON_CONSTANTS;
const { QUESTION_ANSWER_TYPES } = COMMON_QUESTION_CONSTANTS;

const getAnswerObject = (answer, questionObject) => {
  if (
    answer.id === REVIEW_ANSWER_TYPE.NOT_AVAIL
    || answer === REVIEW_ANSWER_TYPE.NOT_AVAIL
  ) {
    return {
      id: REVIEW_ANSWER_TYPE.NOT_AVAIL,
      value: IM_NOT_SURE,
      answer: '-1',
      score: null
    };
  }
  return questionObject.answers.custom.find(
    (a) => a.id.toString() === answer.toString()
  );
};

const MobileReviewForm = ({
  userId,
  revieweeName,
  revieweeTitle,
  question,
  imageUrl,
  saveReview,
  questionObject,
  loading,
  allCategoriesQuestions,
  handleSubmit,
  control,
  commentsRequired,
  errors
}) => {
  const navigate = useNavigate();

  return (
  // WIP - waiting for mockup
    <Base classes={STYLE.MOBILE_CONTAINER}>
      <div className='w-full fixed top-0 mt-12 bg-white z-50'>
        <NavigationItem
          goBack={() => navigate('/dashboard/home')}
          title='Give Feedback'
        >
          <SpeechBubbleOvalSVG />
        </NavigationItem>
      </div>
      <form
        className='flex flex-col items-center mt-[132px]'
        onSubmit={handleSubmit(saveReview)}
      >
        <div className=''>
          <Controller
            name='reviewComments'
            control={control}
            rules={{
              minLength: {
                value: commentsRequired ? 10 : 0,
                message:
                  'Please provide a longer comment to answer this question.'
              },
              required: {
                value: commentsRequired,
                message:
                  'Please provide a longer comment to answer this question.'
              }
            }}
            render={({ field }) => (
              <textarea
                className='w-full bg-white border border-0 rounded-md ring-1 ring-inset ring-gray-300 px-3 h-20'
                placeholder='Note your updates here'
                {...field}
              />
            )}
          />
          <div className='text-red whitespace-nowrap flex items-center'>
            {errors?.reviewComments?.message}
          </div>
        </div>
      </form>
    </Base>
  );
};
export default MobileReviewForm;
