import React, { useContext } from 'react';
import { CompanyDashContext } from 'src/pagesDashboard/CompanyDash/context/Provider';
import { useLocation, useNavigate } from 'react-router-dom';

const OrganizationTabNavigator = () => {
  const { resetContext } = useContext(CompanyDashContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const tabButtonStyle = (url) => `flex p-2 items-center gap-1 focus:outline-none border-b-4 border-purple text-xl ${
    pathname.includes(url)
      ? 'border-opacity-100 text-purple font-bold'
      : 'border-opacity-0 text-zinc-800 font-bold'
  } hover:text-purple hover:border-opacity-100 hover:font-bold transition-colors duration-200 whitespace-nowrap`;

  const baseUrl = '/dashboard/organization';

  return (
    <div className='w-full flex justify-between items-end place-self-end px-4 h-52px'>
      <div className='flex gap-4'>
        <button
          className={tabButtonStyle('overview')}
          onClick={() => {
            resetContext();
            navigate(`${baseUrl}/overview`);
          }}
        >
          Overview
        </button>
        <button
          className={tabButtonStyle('reports')}
          onClick={() => {
            resetContext();
            navigate(`${baseUrl}/reports`);
          }}
        >
          Reviews
        </button>
      </div>
    </div>
  );
};

export default OrganizationTabNavigator;
