import React from 'react';

const OnboardingBase = ({ children }) => (
  <div className='m-auto w-full mini:w-1/2 h-full bg-white'>
    <div className='flex flex-col items-center gap-8 w-[80%] h-full mx-auto'>
      {children}
    </div>
  </div>
);

export default OnboardingBase;
