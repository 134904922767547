/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useState } from 'react';

const PerformanceBuilderContext = createContext();

const initialData = {
  reportId: null,
  editToken: null,
  companyid: null,
  dataset: [],
  form: null,
  page: null,
  canReviewAnyone: null,
  isInitialized: false,
  reviewMode: null,
  companyMode: null,
  prevDataRef: null,
  setOpenState: {},
  mode: null
};

const PerformanceBuilderProvider = ({ children }) => {
  const [data, setData] = useState(initialData);

  const resetData = (overrideData) => setData({ ...initialData, ...overrideData });
  const setDataObject = (newData) => setData({ ...data, ...newData });
  const setDataAttribute = (key, value) => setData({ ...data, [key]: value });

  return (
    <PerformanceBuilderContext.Provider
      value={{
        data,
        resetData,
        setDataObject,
        setDataAttribute
      }}
    >
      {children}
    </PerformanceBuilderContext.Provider>
  );
};

export { PerformanceBuilderProvider, PerformanceBuilderContext };
