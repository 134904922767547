import React, { useEffect } from 'react';
import { Circle } from 'src/components/';
import { useAccount } from 'src/queries/account';

const UserInfo = ({ userId, setIsLoading = () => {} }) => {
  const {
    data: userData,
    isFetching: isFetchingUserAccount,
    isError: isErrorAccount
  } = useAccount(userId);

  useEffect(() => {
    setIsLoading(isFetchingUserAccount);
  }, [isFetchingUserAccount]);

  const isFetching = isFetchingUserAccount;
  const isError = isErrorAccount;
  const isReady = !isFetching && !isError && userData;

  if (!isReady) return <div />;

  const { imageUrl, name, title } = userData;

  return (
    <div className='mb-10 pb-6 flex flex-row gap-6 items-center justify-center'>
      <Circle
        size='xl'
        imageUrl={imageUrl}
        classes='mb-4 user-setting-profile-image relative h-131 w-131'
      />
      <div className='text-left'>
        <div className='text-2xl bold'>{name}</div>
        <div className='text-base text-gray-400'>{title}</div>
      </div>
    </div>
  );
};

export default UserInfo;
