import React from 'react';
import { useCompany } from 'src/queries/company';
import STYLE from 'src/constants/style';
import { Base } from 'src/components/index';
import COMMON_CONSTANTS from 'common/commonConstants';
import SearchSVG from '../../../assets/search.svg';
import ArrowRight from '../../../assets/arrowRight.svg';
import PlusCircle from '../../../assets/plusInCircle.svg';
import EditSvg from '../../../assets/edit-3.svg';
import SHARED_CONSTANTS from '../../../common/sharedConstants';

const { DEFAULT_CATEGORY } = COMMON_CONSTANTS;

const { EDIT_MODES } = SHARED_CONSTANTS;

const CategoryColumn = ({
  selectedRole,
  categories,
  selectedCategory,
  selectCategory,
  editCategory
}) => {
  let categoryList = [];
  if (categories) {
    Object.keys(categories).map((key) => {
      const categoryObj = categories[key];

      if (categoryObj.id === DEFAULT_CATEGORY.id) return;

      categoryList.push({
        ...categoryObj,
        key
      });
    });
  }
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError;

  if (!isReady) {
    return false;
  }

  categoryList = categoryList.sort((a, b) => (a.label > b.label ? 1 : -1));
  const selectedRoleObject = company && company.questions ? company.questions.ROLES[selectedRole] : null;

  return (
    <Base className={STYLE.CONTAINER_GRAY_PADDINGLESS}>
      <div className='w-full h-full pt-6 px-2'>
        {/*
          <a className="inline-block relative mb-0 mr-4">
            <SearchSVG />
          </a>
        */}
        <h4 className='inline-block mr-3 ml-0'>
          {selectedRoleObject
            ? `${selectedRoleObject.label} Categories`
            : 'All Categories'}
        </h4>
        {selectedRole ? (
          <a
            onClick={() => {
              editCategory(EDIT_MODES.ADD, selectedRole);
            }}
            className='inline-block float-right mr-3 mb-0'
          >
            <PlusCircle />
          </a>
        ) : null}
        <div className='clear-both' />
        <div className='overflow-scroll h-41rem'>
          {!selectedRole ? (
            <p className='text-center mt-10 mb-0 pb-5'>Select a role</p>
          ) : null}
          {categoryList.map((categoryObj, index) => {
            const { key } = categoryObj;
            return (
              <div
                key={index}
                className={`hover:bg-purple hover:text-white rounded-lg m-2 ml-0 ${
                  selectedCategory === key ? 'bg-purple text-white' : 'bg-white'
                }`}
              >
                <a
                  onClick={() => {
                    editCategory(
                      EDIT_MODES.EDIT,
                      selectedRole,
                      categoryObj,
                      key
                    );
                  }}
                  className='inline-block w-1/12 mb-0 pt-2 pb-2 pl-3 hover:visible'
                >
                  <EditSvg className='w-4 hover:stroke-white' />
                </a>
                <a
                  onClick={(e) => {
                    selectCategory(e, categoryObj, key);
                  }}
                  className={`inline-block w-9/12 mb-0 p-4 pt-2 pb-2 font-bold linkWhite hover:no-underline
                         ${selectedCategory === key ? 'white' : ''}
                        `}
                >
                  {categoryObj.label}
                </a>
                <a
                  onClick={(e) => {
                    selectCategory(e, categoryObj, key);
                  }}
                  className='inline-block w-2/12 mb-0 p-4 pt-3 pb-2'
                >
                  <ArrowRight className='float-right' />
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </Base>
  );
};

export default CategoryColumn;
