import React, { forwardRef } from 'react';
import './Input.scss';

const Input = forwardRef(
  ({
    title, classes, inputClasses, placeholder, onEnter, ...rest
  }, ref) => {
    const id = Math.floor(Math.random() * 900000) + 10000;
    return (
      <div className={`${classes || ''} input-component`}>
        {title ? (
          <label htmlFor={`${title}_${id}`} className='input-title'>
            {title}
          </label>
        ) : null}
        <input
          type='text'
          id={`${title ?? ''}_${id}`}
          placeholder={placeholder || ''}
          className={`s-input ${inputClasses || ''} ${classes || ''}`}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && onEnter) {
              onEnter();
            }
          }}
          ref={ref}
          {...rest}
        />
      </div>
    );
  }
);

export default Input;
