import React from 'react';
import { Button, Base, Modal } from 'src/components';

const ConfirmArchivePulseModal = ({
  pulseQuestionId,
  isLoading,
  close,
  archiveFn
}) => (
  <Modal innerClasses='w-34rem' classes='pt-40' variant='custom' close={close}>
    <Base variant='transparent' loading={isLoading}>
      <h5> Are you sure you want to archive this pulse question?</h5>

      <div className='mt-20 -mb-1'>
        <div className='w-1/2 inline-block text-left'>
          <button
            type='button'
            variant='transparent'
            className='text-black text-lg px-4 py-2 mr-0 font-bold'
            onClick={close}
          >
            Cancel
          </button>
        </div>
        <div className='w-1/2 inline-block text-right'>
          <Button
            variant='custom'
            classes='bg-red text-white px-4 py-2 text-lg rounded-sm font-bold answer transition-colors duration-300'
            disabled={isLoading}
            onClick={() => archiveFn(pulseQuestionId)}
          >
            Confirm Archive
          </Button>
        </div>
      </div>
    </Base>
  </Modal>
);

export default ConfirmArchivePulseModal;
