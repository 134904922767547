import React, { useContext } from 'react';
import { Base } from 'src/components';
import PreviewInfo from 'src/pages/PerformanceBuilder/PreviewComponents/PreviewInfo';
import PreviewReviews from 'src/pages/PerformanceBuilder/PreviewComponents/PreviewReviews';
import PreviewSupplemental from 'src/pages/PerformanceBuilder/PreviewComponents/PreviewSupplemental';
import PreviewSummary from 'src/pages/PerformanceBuilder/PreviewComponents/PreviewSummary';
import { PerformanceBuilderContext } from 'src/pages/PerformanceBuilder/PerformanceBuilderContext';

const ReportPreview = () => {
  const {
    data: {
      page, companyMode, form, companyid, reportId
    }
  } = useContext(PerformanceBuilderContext);
  const isViewPage = page === 'view';
  const isCompanySpecific = companyMode === 'company';

  return (
    <Base
      classes={`${isViewPage ? 'w-full' : 'w-1/2'} h-[calc(100vh-3.5rem)] max-h-32 overflow-y-scroll`}
    >
      <div className='px-8 py-8 mb-20'>
        <PreviewInfo
          mode='form'
          data={{ form, params: { companyid, reportIds: [reportId] } }}
        />
        <PreviewReviews mode='form' data={{ form }} />
        {isCompanySpecific ? (
          <PreviewSupplemental mode='form' data={{ form }} />
        ) : null}
        <PreviewSummary mode='form' data={{ form }} />
      </div>
    </Base>
  );
};

export default ReportPreview;
