import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import StarsSvg from 'src/assets/svg/stars.svg';
import CircleSpinner from 'src/assets/svg/circle-spinner.svg';
import RightArrowSVG from 'src/assets/right-arrow.svg';
import { useReviewSuggestions } from 'src/queries/Wizard/wizard';
import COMMON_CONSTANTS from 'common/commonConstants';
import './ReviewAiSuggestions.scss';

const { PROMPT_TYPES } = COMMON_CONSTANTS;

const ReviewAiSuggestions = ({
  comments,
  userId,
  revieweeName,
  question,
  companyid,
  reviewerId
}) => {
  const [disableGetSuggestions, setDisableGetSuggestions] = useState(false);
  const {
    generateSuggestions,
    data: suggestions,
    isLoading: isCreateSuggestionsLoading
  } = useReviewSuggestions();

  const sanitizedText = () => ({
    __html: DOMPurify.sanitize(suggestions[0])
  });
  const showGetSuggestionsButton = comments.length >= 15 && !disableGetSuggestions;

  const getSuggestions = () => {
    setDisableGetSuggestions(true);
    generateSuggestions({
      filters: {
        text: comments,
        type: PROMPT_TYPES.FEEDBACK_SUGGESTION,
        question,
        userId,
        reviewerId,
        companyid
      }
    });
  };

  return (
    <div className='ai-review-suggestions'>
      <div className='mb-8 flex flex-col md:flex-row'>
        <div className='text-gray-500 mb-2 md:w-2/5 font-bold text-xl'>
          AI ASSIST
        </div>
        <div className='md:w-3/5'>
          {!disableGetSuggestions ? (
            <div className='bg-sky-blue p-4 rounded-md mb-2'>
              <div className='flex mb-2 text-gray-450'>
                <StarsSvg className='mr-2' />
                WorkStory AI Assistant
              </div>
              <div>
                👋 Hey! We can help you improve your feedback. Provide a comment
                and we’ll let you know how you can make it even better!
              </div>
            </div>
          ) : null}
          {!disableGetSuggestions && comments.length && comments.length < 15 ? (
            <div className='bg-sky-blue p-2 px-3 rounded-3xl w-fit'>
              Please add a little more feedback before we can make a suggestion.
            </div>
          ) : null}
          {showGetSuggestionsButton ? (
            <button
              type='button'
              className='bg-sky-blue p-2 rounded-3xl w-48 flex'
              onClick={getSuggestions}
            >
              <RightArrowSVG className='mr-1' />
              Suggest Improvements
            </button>
          ) : null}
          {isCreateSuggestionsLoading ? (
            <button
              type='button'
              className='bg-sky-blue py-2 px-3 rounded-3xl flex'
              disabled
            >
              <CircleSpinner viewBox='0 0 24 24' />
              Processing
            </button>
          ) : null}
          {!isCreateSuggestionsLoading && suggestions && suggestions.length ? (
            <div className='bg-sky-blue p-4 rounded-md'>
              <div className='flex mb-2 text-gray-450'>
                <StarsSvg className='mr-2' />
                WorkStory AI Assistant
              </div>
              <p className='mb-1'>
                {`Suggestions for improving your feedback for ${revieweeName}:`}
              </p>
              <div dangerouslySetInnerHTML={sanitizedText()} className='pl-8' />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ReviewAiSuggestions;
