import React from 'react';

const Button = ({
  variant,
  disabled,
  classes,
  onClick,
  onEnter,
  loading,
  children,
  dataCy,
  paddingless = false,
  ...rest
}) => {
  const isLoading = loading || false;
  let variantClass = 'py-tiny text-base px-10 bg-red rounded-lg text-white';

  if (variant === 'custom') {
    variantClass = classes;
  }

  if (variant === 'red' && !disabled) {
    variantClass += ' rounded-none transition duration-500 bg-red hover:bg-hover-red';
  }

  if (variant === 'blue') {
    variantClass += ' bg-light-blue text-white';
  }
  if (variant === 'reverse-red') {
    variantClass += ' bg-transparent border border-red text-white';
  }
  if (variant === 'gray') {
    variantClass += ' bg-transparent-grey text-dark-grey';
  }
  if (variant === 'transparent') {
    variantClass = ` text-base py-2 pl-10 pr-10 bg-transparent focus:outline-none ${
      disabled ? 'text-gray-400' : 'text-dark-grey'
    }`;
  }
  if (variant === 'black') {
    variantClass = ' bg-black text-white font-bold text-sm px-4 pt-2 pb-2 border rounded-md border-black';
  }
  if (variant === 'black-outline') {
    variantClass = ' bg-white font-bold text-sm px-4 pt-2 pb-2 border rounded-md';
    if (disabled) {
      variantClass += ' text-mid-gray border-mid-gray';
    } else {
      variantClass += ' text-black border-black';
    }
  }
  // new variants (yellow will be default)
  if (variant === 'yellow') {
    variantClass = ' bg-yellow text-black text-lg px-4 py-2 rounded-sm font-bold answer transition-colors duration-300';
    if (disabled) {
      variantClass += ' ';
    } else {
      variantClass += ' hover:bg-hover-yellow';
    }
  }
  if (variant === 'white') {
    variantClass = ' bg-white text-black text-lg px-4 rounded-sm font-bold answer transition-colors duration-300';
    if (disabled) {
      variantClass += ' ';
    } else {
      variantClass += ' hover:bg-hover-white';
    }
  }

  if (variant === 'purple') {
    variantClass = ' bg-purple text-white text-lg px-4 py-2 rounded-sm font-bold answer transition-colors duration-300';
    if (disabled) {
      variantClass += ' ';
    } else {
      variantClass += ' hover:bg-hover-purple';
    }
  }

  if (variant === 'purple-2') {
    variantClass = 'text-white text-md text-center font-semibold tracking-wide rounded-md px-4 py-2 focus:outline-none transition-colors duration-300';
    if (!disabled) {
      variantClass += ' bg-purple hover:bg-hover-purple';
    }
  }

  if (variant === 'new-gray') {
    variantClass = ' bg-light-gray text-black text-lg px-4 py-2 rounded-sm font-bold hover:bg-hover-yellow answer transition-colors duration-300';
  }
  if (variant === 'empty') {
    variantClass = ' text-black text-lg px-4 py-2 mr-0 font-bold';
  }

  if (variant === 'purple-with-border') {
    variantClass = ' bg-purple text-white text-lg px-4 py-2 rounded-sm font-bold border border-purple transition-colors duration-300';
    if (disabled) {
      variantClass += ' ';
    } else {
      variantClass += ' hover:bg-hover-purple';
    }
  }

  if (variant === 'purple-with-border-sm') {
    variantClass = ' bg-purple text-white text-sm px-4 py-1 rounded-md font-bold border border-purple transition-colors duration-300';
    if (disabled) {
      variantClass = variantClass.replace('border-purple', 'border-mid-gray');
    } else {
      variantClass += ' hover:bg-hover-purple';
    }
  }

  if (variant === 'empty-with-border') {
    variantClass = 'text-black disabled:text-light-gray text-lg px-4 py-2 rounded-sm font-bold border border-black disabled:border-light-gray transition-colors duration-300 hover:text-dark-grey hover:border-dark-grey';
  }

  if (variant === 'empty-with-border-sm') {
    variantClass = 'text-black text-sm px-4 py-1 rounded-md font-bold border border-black transition-colors duration-300 hover:text-dark-grey hover:border-dark-grey';
  }

  if (paddingless) {
    variantClass = variantClass.replace(/px-\d+/g, '');
    variantClass = variantClass.replace(/py-\d+/g, '');
    variantClass = variantClass.replace(/pl-\d+/g, '');
    variantClass = variantClass.replace(/pr-\d+/g, '');
    variantClass = variantClass.replace(/pt-\d+/g, '');
    variantClass = variantClass.replace(/pb-\d+/g, '');
    variantClass = variantClass.replace(/p-\d+/g, '');
  }

  if (
    disabled
    && (!variant || variant !== 'transparent' || !variant.includes('empty'))
  ) {
    variantClass = variantClass.replace('bg-yellow', '');
    variantClass = variantClass.replace('bg-purple', '');
    variantClass = variantClass.replace('bg-light-gray', '');
    variantClass = variantClass.replace('bg-white', '');
    variantClass += ' bg-mid-gray text-white';
    if (variant === 'black') {
      variantClass += ' border-mid-gray';
    }
  }

  return (
    <button
      type='button'
      className={`${variant === 'custom' ? '' : classes || ''} ${variantClass}`}
      onClick={onClick}
      data-cy={dataCy}
      disabled={disabled}
      onKeyPress={(e) => {
        if (e.key === 'Enter' && onEnter) {
          onEnter();
        }
      }}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
