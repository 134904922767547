import React from 'react';
import CircledCheckmarkIcon from 'src/assets/svg/circled-checkmark.svg';

const UserConnected = () => (
  <>
    <p className='mb-4'>
      Receive review requests and other WorkStory notifications directly through
      Microsoft Teams.
    </p>
    <p className='flex items-center gap-3'>
      <CircledCheckmarkIcon className='text-purple' />
      Your account has been connected
    </p>
  </>
);

export default UserConnected;
