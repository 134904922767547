import React from 'react';
import { get } from 'lodash';
import { useQueryClient } from 'react-query';
import { toast } from 'src/components';
import {
  useCompany,
  QUERY_KEYS as COMPANY_QUERY_KEYS
} from 'src/queries/company';
import COMMON_QUESTION_CONSTANTS from 'common/commonQuestionConstants';
import commonQuestions from 'common/commonQuestions';
import {
  updateCategoryQuery,
  updateQuestionQuery
} from 'src/queries/questions';

const { STATUS } = COMMON_QUESTION_CONSTANTS;

const printQuestionsOfCategory = (companyQuestions, questionId) => {
  const categories = commonQuestions.getCategoriesWithQuestion(
    questionId,
    companyQuestions
  );
  return categories
    ? categories.map((c) => c.label).join(', ')
    : 'Question is not in any categories';
};

const ArchivedQuestions = () => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError;

  const queryClient = useQueryClient();
  const { mutateAsync: updateQuestion, isLoading: updateQuestionQueryLoading } = updateQuestionQuery();
  const { mutateAsync: updateCategory, isLoading: updateCategoryLoading } = updateCategoryQuery();

  if (!isReady) {
    return null;
  }

  const companyQuestions = get(company, 'questions');
  if (!companyQuestions) {
    return null;
  }
  const archived = company.questions.QUESTIONS.filter(
    (questionObj) => questionObj.status === STATUS.ARCHIVED
  );

  const ROLES_LIST = commonQuestions.convertCompanyRolesToList(
    company.questions.ROLES
  );
  const ARCHIVED_ROLES = ROLES_LIST.filter((r) => r.status === STATUS.ARCHIVED);

  const CATEGORY_LIST = commonQuestions.getCategoryList(company.questions);
  const ARCHIVED_CATEGORIES = CATEGORY_LIST.filter(
    (c) => c.status === STATUS.ARCHIVED
  );

  const unArchiveQuestion = async (questionId) => {
    try {
      toast.show('Enabling question..');
      await updateQuestion({
        questionId,
        data: JSON.stringify({
          status: COMMON_QUESTION_CONSTANTS.STATUS.ACTIVE
        })
      });
      queryClient.invalidateQueries(COMPANY_QUERY_KEYS.COMPANY);
      queryClient.invalidateQueries(COMPANY_QUERY_KEYS.COMPANY_QUESTIONS);
      toast.show('Question enabled!');
    } catch (error) {
      return toast.error(error);
    }
  };

  const unArchiveCategory = async (categoryId) => {
    try {
      console.log('unarchive this category..', categoryId);
      // const rolesContainingCat = commonQuestions.getRolesOfCategory(catId, companyQuestions);
      // console.log('roles containing cat..', rolesContainingCat);
      toast.show('Enabling category..');
      await updateCategory({
        categoryId,
        data: JSON.stringify({
          status: COMMON_QUESTION_CONSTANTS.STATUS.ACTIVE
        })
      });
      queryClient.invalidateQueries(COMPANY_QUERY_KEYS.COMPANY);
      queryClient.invalidateQueries(COMPANY_QUERY_KEYS.COMPANY_QUESTIONS);

      toast.show('Category enabled!');
    } catch (error) {
      return toast.error(error);
    }
  };

  return (
    <div className='mt-10 pb-5 text-left ml-3'>
      <h5 className='mb-2 ml-2 text-purple'>
        Archived Questions (Superuser View Only)
      </h5>

      <div className='text-dark-grey border-b border-transparent-grey mr-2 mb-2 py-1 px-1 text-black rounded-sm'>
        <p className='mb-0 pl-1 w-9/12 inline-block font-bold'>Question</p>
        <p className='mb-0 w-2/12 inline-block font-bold'>Type</p>
      </div>

      {!archived || !archived.length ? (
        <div className='text-center text-md mt-6 mb-5 font-bold'>
          No archived questions
        </div>
      ) : null}
      {archived.map((questionObj, index) => (
        <div
          key={`archived-question-${index}`}
          className='mb-2 py-1 px-1 bg-background-gray text-black font-bold rounded-sm'
        >
          <p className='mb-0 pl-1 w-9/12 inline-block'>
            {questionObj.question}
          </p>
          <p className='mb-0 w-2/12 inline-block'>{questionObj.answers.type}</p>
          <button
            className='inline-block w-1/12'
            type='button'
            onClick={() => unArchiveQuestion(questionObj.id)}
          >
            Enable
          </button>

          <div>
            Categories:
            {printQuestionsOfCategory(companyQuestions, questionObj.id)}
          </div>
        </div>
      ))}

      <h5 className='mb-2 ml-2 text-purple'>Archived Roles</h5>
      <div className='text-dark-grey border-b border-transparent-grey mr-2 mb-2 py-1 px-1 text-black rounded-sm'>
        <p className='mb-0 pl-1 w-9/12 inline-block font-bold'>Role</p>
      </div>

      {!ARCHIVED_ROLES || !ARCHIVED_ROLES.length ? (
        <div className='text-center text-md mt-6 mb-5 font-bold'>
          No archived roles
        </div>
      ) : null}
      {ARCHIVED_ROLES.map((roleObj, index) => (
        <div
          key={`archived-role-${index}`}
          className='mb-2 py-1 px-1 bg-background-gray text-black font-bold rounded-sm'
        >
          <p className='mb-0 pl-1 w-9/12 inline-block'>{roleObj.label}</p>
        </div>
      ))}

      <h5 className='mb-2 ml-2 text-purple'>Archived Categories</h5>
      <div className='text-dark-grey border-b border-transparent-grey mr-2 mb-2 py-1 px-1 text-black rounded-sm'>
        <p className='mb-0 pl-1 w-9/12 inline-block font-bold'>Category</p>
      </div>

      {!ARCHIVED_CATEGORIES || !ARCHIVED_CATEGORIES.length ? (
        <div className='text-center text-md mt-6 mb-5 font-bold'>
          No archived categories
        </div>
      ) : null}
      {ARCHIVED_CATEGORIES.map((categoryObj, index) => (
        <div
          key={`archived-category-${index}`}
          className='flex justify-between mb-2 py-1 px-1 bg-background-gray text-black font-bold rounded-sm pr-4'
        >
          <p className='mb-0 pl-1 w-6/12 flex'>{categoryObj.label}</p>
          <p className='mb-0 w-3/12 flex text-right'>{categoryObj.id}</p>
          <button onClick={() => unArchiveCategory(categoryObj.id)}>
            Un-archive category
          </button>
        </div>
      ))}
    </div>
  );
};

export default ArchivedQuestions;
