import React from 'react';
import CategoryBox from 'src/containers/UserProfile/Categories/CategoryBox';

const DisplayRoleCategories = ({ roles, onCategoryClick }) => {
  if (!roles) {
    return null;
  }

  return roles.map((roleObj, index) => {
    const { categories } = roleObj;
    return (
      <div className='block mb-3' key={index}>
        <h3 className='text-xl'>
          {roleObj.label}
          {roleObj.active ? (
            <span className='mb-0 font-bold text-dark-grey align-middle ml-2 text-sm'>
              Active role
            </span>
          ) : null}
        </h3>

        <div className='grid grid-cols-4 gap-3'>
          {categories.map((category, secIndex) => (
            <CategoryBox
              key={secIndex}
              label={category.scoreLabel || 'Not applicable'}
              onClick={() => onCategoryClick(category)}
              title={category.label}
              value={category.score}
            />
          ))}
        </div>
      </div>
    );
  });
};

export default DisplayRoleCategories;
