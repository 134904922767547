import React from 'react';
import CONSTANTS from './constants';
import './Toast.scss';

const TOAST_CONTAINER_ID = 'speed-toast-container';

const toasts = {};
let i = 0;

const closeToast = (toastId) => {
  const myToastEl = document.getElementById(toastId);
  if (myToastEl && myToastEl.parentElement) {
    myToastEl.parentElement.removeChild(myToastEl);
    if (toasts[toastId]) {
      delete toasts[toastId];
    }
    return true;
  }
  return false;
};

const closeAllToasts = () => {
  Object.keys(toasts).forEach((key) => {
    closeToast(key);
  });
};

// create a toast element and show it
// variant = success | error | info | moreinfo
const toastit = (description, variant, singleToast, timeout) => {
  try {
    timeout = timeout || 5000;
    const activeToasts = Object.keys(toasts).length;
    if (singleToast && activeToasts !== 0) {
      return;
    }
    const container = document.getElementById(TOAST_CONTAINER_ID);
    const newToast = document.createElement('div');
    const newToastId = `toast_${i}`;
    toasts[newToastId] = 1;
    i++;
    newToast.id = newToastId;
    newToast.setAttribute('class', 'speed-toast-wrap');

    const chatEl = document.getElementsByClassName('hoUQRS');
    if (chatEl && chatEl.length) {
      newToast.setAttribute(
        'style',
        'margin-right: 65px; position: relative; top: 2px;'
      );
    }
    if (!container) {
      throw 'No toast container found!';
    }

    newToast.classList.add('speed-toast', `speed-toast-${variant}`);
    const infoToastDiv = document.createElement('div');
    infoToastDiv.classList.add('info-toast');
    const sideDiv = document.createElement('div');
    sideDiv.classList.add('side');
    const contentDiv = document.createElement('div');
    contentDiv.classList.add('content');
    const toastDescriptionP = document.createElement('p');
    toastDescriptionP.classList.add('toast-description');
    toastDescriptionP.textContent = description;

    infoToastDiv.appendChild(sideDiv);
    infoToastDiv.appendChild(contentDiv);
    contentDiv.appendChild(toastDescriptionP);

    newToast.appendChild(infoToastDiv);
    container.appendChild(newToast);

    setTimeout(() => {
      newToast.classList.add('pop-in');
    }, 50);

    setTimeout(() => {
      if (closeToast(newToastId)) {
        delete toasts[newToastId];
      }
    }, timeout);
  } catch (error) {
    console.error('toastit', error, {
      description,
      variant,
      singleToast,
      timeout
    });
    console.error('toast it description', description);
    console.error('toast it variant', variant);
    console.error('toast it error', error);
  }
};

const ToastContainer = () => (
  <div id={TOAST_CONTAINER_ID} className='speed-toast-container' />
);

const callToastInfo = (description, timeout) => {
  toastit(description, 'info', false, timeout);
};

const callToastError = (description, timeout) => {
  toastit(description, 'error', false, timeout);
};

const toast = {
  show: callToastInfo,
  error: callToastError,
  closeAll: closeAllToasts,
  closeToast,
  TYPES: CONSTANTS
};

export { ToastContainer, toast, TOAST_CONTAINER_ID };
