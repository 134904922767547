import React from 'react';
import HeaderV2 from 'src/containers/Header/HeaderV2';
import { Base, appUtils } from 'src/components/';
import CircleV2 from 'src/components/Circle/CircleV2';
import Button from 'src/components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

const HomeHeader = () => {
  const loggedUser = appUtils.getLoggedUser();
  const loggedUserId = appUtils.getLoggedUserId();

  const { showRequestFeedbackButton } = useFlags();

  const navigate = useNavigate();
  return (
    <Base
      classes='w-full text-white rounded-md relative z-1'
      variant='transparent'
    >
      <HeaderV2
        title={`Welcome ${loggedUser.firstName}!`}
        titleClasses='text-3xl font-bold ml-2'
        icon={<CircleV2 imageUrl={loggedUser.imageUrl} size='md' />}
      />
      <div className='flex w-full justify-end gap-2 mb-2 -mt-8 h-[39.5px]'>
        <button
          type='button'
          onClick={() => navigate('/dashboard/submit-feedback')}
          className='text-black rounded-md border border-black text-sm font-bold p-1 px-3 py-1 h-full'
        >
          Give Feedback
        </button>
        {
          showRequestFeedbackButton && (
            <Button
              type='button'
              onClick={() => navigate(`/dashboard/request-feedback?revieweeId=${loggedUserId}`)}
              variant='black'
              classes='px-2 py-1 whitespace-nowrap h-full'
              paddingless
            >
              Request Feedback
            </Button>
          )
        }
      </div>
    </Base>
  );
};

export default HomeHeader;
