import React, { useContext } from 'react';
import qs from 'qs';
import { Select } from 'src/components/';
import { DashTeamContext } from 'src/pagesDashboard/DashTeam/context/DashTeamProvider';
import { updateData } from 'src/pagesDashboard/DashTeam/context/actions';
import { useTeamProfile } from 'src/pagesDashboard/DashTeam/queries';
import { getCategoryOptions } from 'src/utils/historicFilterUtils';
import { useNavigate } from 'react-router-dom';

const HistoricReviewFilter = ({ mode }) => {
  const navigate = useNavigate();
  const {
    categories,
    isFetching: isFetchingTeamProfile,
    isError: isErrorTeamProfile
  } = useTeamProfile();

  const isError = isErrorTeamProfile;
  const isReady = categories && !isError;

  const { context, dispatch } = useContext(DashTeamContext);

  if (!isReady) {
    return null;
  }

  const categoryOptions = getCategoryOptions(categories);

  const categorySelected = context.category;
  const cat = categorySelected
    ? categoryOptions.find((option) => option.id === categorySelected)
    : null;

  const title = cat ? `Filter by ${cat.label}` : 'Filter by category';

  return (
    <div className='w-60 block'>
      <Select
        variant='shadow'
        title={title}
        options={categoryOptions}
        onChange={(option) => {
          if (mode === 'dashTeam') {
            const categoryFilters = qs.stringify({
              team: context.managerId || null,
              userIds: context.userIds || null,
              category: option.id
            });
            navigate(`/dashboard/team?${categoryFilters}`);
          }
          dispatch(
            updateData({
              ...context,
              category: option.id,
              teamHistoricReviews: {
                ...context.teamHistoricReviews,
                page: 1
              }
            })
          );
        }}
      />
    </div>
  );
};

export default HistoricReviewFilter;
