/* eslint-disable import/prefer-default-export */
import { useContext } from 'react';
import { UserProfileContext } from 'src/pagesDashboard/UserProfile/context/UserProfileProvider';
import { useReviews } from 'src/queries/reviews';
import { useFeedback } from 'src/queries/feedback';

export const useUserProfile = ({
  includeEmptyComments = true,
  reportUserId
}) => {
  const { context } = useContext(UserProfileContext);
  const range = context.range.value;

  const {
    range: { start, end }
  } = context;
  const {
    category, reviewerGroup, reviewerIds, roles
  } = context.filters;

  let { userId } = context;
  userId = userId || reportUserId;
  const filters = {
    range,
    start,
    end,
    category,
    includeEmptyComments,
    reviewerIds,
    reviewerGroup,
    roles
  };
  const { profileHistoricReviews } = context;

  const {
    data: { reviews: userReviews, pagination },
    isFetching: isFetchingReviews
  } = useReviews({
    userId,
    filters,
    options: {
      page: profileHistoricReviews.page,
      size: profileHistoricReviews.pageSize
    }
  });

  return {
    pagination,
    userReviews,
    isFetchingReviews
  };
};

export const useUserFeedback = (userId, queryFilters, queryOptions = {}) => {
  const {
    range: { value: rangeValue, start, end },
    pages,
    viewerId
  } = queryFilters;
  const {
    category,
    reviewerIds,
    reviewerGroup,
    roles,
    includeEmptyComments,
    includeAnonymousReviews
  } = queryFilters.filters;
  const filters = {
    ids: [userId],
    range: rangeValue,
    start,
    end,
    category,
    reviewerIds,
    reviewerGroup,
    roles,
    includeEmptyComments,
    includeAnonymousReviews
  };

  const options = {
    page: pages?.page,
    size: pages?.pageSize,
    joinReviews: true,
    ...(viewerId && { viewerId })
  };

  const {
    data: { feedbacks, notes, pagination },
    isFetching: isFetchingFeedbacks,
    isError: isErrorFeedbacks,
    isRefetching: isRefetchingFeedbacks
  } = useFeedback(
    {
      ...filters,
      options
    },
    {
      ...queryOptions
    }
  );

  return {
    data: {
      pagination,
      userFeedbacks: feedbacks,
      userNotes: notes
    },
    isFetching: isFetchingFeedbacks,
    isError: isErrorFeedbacks,
    isRefetching: isRefetchingFeedbacks
  };
};
