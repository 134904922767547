/* eslint-disable import/prefer-default-export */
import React from 'react';
import { createRoot } from 'react-dom/client';
import 'promise-polyfill';
// import 'isomorphic-fetch';
import featureFlags from 'src/utils/featureFlags';
import { Theme } from '@radix-ui/themes';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import datadog from 'src/utils/datadog';
import rootReducer from './reducers/reducers';
import App from './components/app';
import '@radix-ui/themes/styles.css';
import './style/global.css';
import './style/reactQuill.scss';
import './style/mobile.scss';
// import '@radix-ui/colors/black-alpha.css';
// import '@radix-ui/colors/mauve.css';
// import '@radix-ui/colors/violet.css';

const removeServiceWorkers = () => {
  if (navigator?.serviceWorker) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (const registration of registrations) {
        registration.unregister();
      }
    });
  }
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      staleTime: 300000, // 5 min default stale time,
      cacheTime: 600000 // 10 min default cache time
    }
  }
});

window.claritySessionId = null;

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const root = createRoot(document.getElementById('app'));
async function init() {
  let LDProvider = null;
  try {
    if (!LDProvider) {
      LDProvider = await featureFlags.initLaunchDarkly();
    }
  } catch (error) {
    console.error('index.init launch darkly provider', error);
  }

  datadog.init();
  removeServiceWorkers();
  const GOOGLE_CLIENT_ID = `${process.env.GOOGLE_CLIENT_ID}.apps.googleusercontent.com`;

  const MainComponent = () => (
    <Provider store={store}>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <QueryClientProvider client={queryClient}>
          <Theme>
            <LDProvider>
              <App />
            </LDProvider>
          </Theme>
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </Provider>
  );

  root.render(<MainComponent />);
}

// register ServiceWorker via OfflinePlugin, for prod only:
// if (process.env.NODE_ENV==='production') {
// require('./pwa');
// }

// in development, set up HMR:
if (module.hot) {
  module.hot.accept('./components/app', () => requestAnimationFrame(init));
}

window.onError = (error, arg) => {
  console.error('window.onError', error, arg);
};

init();

export default init;
