import React, { useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import { Button, Multiselect, toast } from 'src/components/';
import SpeechBubbleOvalSVG from 'src/assets/svg/speech-bubble-oval.svg';
import NavigationItem from 'src/pagesDashboard/HomePage/components/mobile/NavigationItem';
import CategoryOption from 'src/containers/UserProfile/TopScores/mobile/CategoryOption';
import { QUERY_KEYS as COMPANY_QUERY_KEYS } from 'src/queries/company';

const MobileSelectGiveFeedback = ({
  revieweeProps,
  goToReview,
  submitButtonTitle,
  isSubmitDisabled,
  isReview,
  roleOptions,
  selectedRole,
  titleText,
  categoryOptions,
  checkedQuestions,
  addQuestion,
  loadingProps,
  stateProps,
  refetchCompany
}) => {
  const {
    revieweeOptions,
    feedbackReviewees,
    selectedRevieweeData,
    onChangeReviewee,
    searchFn,
    isFetchingRevieweeAccounts,
    revieweeId
  } = revieweeProps;
  const { isLoadingAddQuestion, isRefetchingCompany } = loadingProps;
  const { setCheckedQuestions, setIsCheckedQuestionsEmpty, setSelectedRole } = stateProps;
  const selectedReviewee = selectedRevieweeData?.data;
  const cleanupRef = useRef(() => {});
  const queryClient = useQueryClient();
  const [isQuestionsOpen, setIsSelectQuestionsOpen] = useState(false);
  const isNextDisabled = !selectedReviewee || !selectedRole;
  const navigate = useNavigate();
  return (
    <div>
      {!isQuestionsOpen ? (
        <div>
          <div className='w-full fixed top-0 mt-12 bg-white z-50'>
            <NavigationItem
              goBack={() => navigate('/dashboard/home')}
              title='Give Feedback'
            >
              <SpeechBubbleOvalSVG />
            </NavigationItem>
          </div>
          <div className='px-3 mt-[87px]'>
            <p className='mb-1 mt-3'>Who would you like to give feedback to?</p>
            {isReview ? (
              <Multiselect
                classes='w-full text-black'
                title={selectedReviewee?.name ?? 'Select a Team Member'}
                options={revieweeOptions}
                placeholder='Select Team Member'
                multiselect={false}
                onChange={(option, selectionType) => {
                  if (selectionType === 'select') {
                    onChangeReviewee(option, 'review');
                  }
                }}
                onSearch={debounce(searchFn, 200)}
                onDropdownClose={() => searchFn('')}
                loading={isFetchingRevieweeAccounts}
                disabled={revieweeId}
              />
            ) : (
              <Multiselect
                classes='w-full text-black'
                title='Select a Team Member(s)'
                options={feedbackReviewees}
                placeholder='Select Team Member(s)'
                onChange={(option, selectionType) => {
                  if (selectionType === 'select') {
                    const foundInOption = feedbackReviewees.find(
                      (opt) => opt.id === option.id
                    );
                    if (foundInOption) {
                      const newOptions = feedbackReviewees.map((opt) => {
                        if (opt.id === foundInOption.id) {
                          return { ...opt, checked: !opt.checked };
                        }
                        return opt;
                      });

                      onChangeReviewee(newOptions, 'feedback');
                    }
                  }
                }}
                onSearch={debounce(searchFn, 200)}
                onDropdownClose={() => searchFn('')}
                loading={isFetchingRevieweeAccounts}
                disabled={revieweeId}
              />
            )}
            {selectedReviewee && isReview ? (
              <div>
                {roleOptions.length ? (
                  <div className='mb-4'>
                    <span className='font-semibold text-black'>Role</span>

                    <Multiselect
                      classes='w-full text-black'
                      title={
                        selectedRole ? selectedRole.label : 'Select a Role'
                      }
                      options={roleOptions}
                      placeholder='Select Team Member'
                      multiselect={false}
                      onChange={(roleOption) => {
                        setCheckedQuestions([]);
                        setIsCheckedQuestionsEmpty(true);
                        setSelectedRole(roleOption);
                      }}
                      loading={isFetchingRevieweeAccounts}
                    />
                  </div>
                ) : (
                  <div className='h-80 flex items-center'>
                    <span className='font-base'>
                      {`You do not review ${selectedReviewee.name} by any roles. Please adjust review relationships if necessary`}
                    </span>
                  </div>
                )}
              </div>
            ) : null}
            {isReview ? (
              <div className='px-6 fixed bottom-0 pb-2 w-full flex flex-col items-center w-full fixed bottom-0 gap-3'>
                <Button
                  variant='black'
                  classes='w-full'
                  onClick={() => setIsSelectQuestionsOpen(true)}
                  disabled={isNextDisabled}
                >
                  Next
                </Button>
                <Button
                  variant='custom'
                  disabled={isLoadingAddQuestion}
                  classes='bg-white w-full text-black font-bold text-sm px-4 pt-2 pb-2 border rounded-md border-gray-300'
                  onClick={() => navigate('/dashboard/home')}
                >
                  Cancel
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <div className='mb-4'>
          <div className='w-full fixed top-0 mt-12 bg-white z-50'>
            <NavigationItem
              goBack={() => setIsSelectQuestionsOpen(false)}
              title='Give Feedback'
            >
              <SpeechBubbleOvalSVG />
            </NavigationItem>
          </div>
          <p className='font-semibold text-black mb-0 mt-4'>{titleText}</p>
          <div className='mt-3 w-full h-40vh overflow-y-scroll'>
            {categoryOptions.map((category) => (
              <CategoryOption
                key={category.id}
                category={category}
                checkedQuestions={checkedQuestions}
                setCheckedQuestions={setCheckedQuestions}
                setIsButtonDisabled={setIsCheckedQuestionsEmpty}
                showCustomQuestion
                isLoadingSaveCustomQuestion={
                  isLoadingAddQuestion || isRefetchingCompany
                }
                onSaveCustomQuestion={async (questionObject) => {
                  const { success, questionId } = await addQuestion({
                    questionObject,
                    categoryId: category.id
                  });

                  if (!success) return toast.error('Failed to add question');

                  cleanupRef.current = () => {
                    queryClient.invalidateQueries(COMPANY_QUERY_KEYS.COMPANY);
                    queryClient.invalidateQueries(
                      COMPANY_QUERY_KEYS.COMPANY_QUESTIONS
                    );
                  };

                  refetchCompany();
                  setCheckedQuestions((prev) => [
                    ...prev,
                    {
                      categoryId: category.id,
                      questionId
                    }
                  ]);
                  setIsCheckedQuestionsEmpty(false);
                }}
              />
            ))}
          </div>
          <div className='px-6 fixed bottom-0 pb-2 w-full flex flex-col items-center w-full fixed bottom-0 gap-3'>
            <Button
              variant='black'
              classes='w-full'
              onClick={goToReview}
              disabled={isSubmitDisabled}
            >
              {submitButtonTitle}
            </Button>
            <Button
              variant='custom'
              disabled={isLoadingAddQuestion}
              classes='bg-white w-full text-black font-bold text-sm px-4 pt-2 pb-2 border rounded-md border-gray-300'
              onClick={() => navigate('/dashboard/home')}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
      {!isReview ? (
        <div className='px-6 fixed bottom-0 pb-2 w-full flex flex-col items-center w-full fixed bottom-0 gap-3'>
          <Button
            variant='black'
            classes='w-full'
            onClick={goToReview}
            disabled={isSubmitDisabled}
          >
            {submitButtonTitle}
          </Button>
          <Button
            variant='custom'
            disabled={isLoadingAddQuestion}
            classes='bg-white w-full text-black font-bold text-sm px-4 pt-2 pb-2 border rounded-md border-gray-300'
            onClick={() => navigate('/dashboard/home')}
          >
            Cancel
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default MobileSelectGiveFeedback;
