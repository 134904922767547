import React, { useContext } from 'react';
import { QuestionBuilderContext } from 'src/pagesDashboard/QuestionBuilder/context/QuestionBuilderProvider';
import { updateData } from 'src/pagesDashboard/QuestionBuilder/context/actions';
import QuestionPreviewAnswers from 'src/pagesDashboard/QuestionBuilder/components/QuestionPreviewAnswers';

const QuestionPreview = () => {
  const {
    context: { question }
  } = useContext(QuestionBuilderContext);

  const QuestionDisplayed = () => {
    if (!question || question === '') {
      return (
        <span>
          <i>
            How well has
            {' '}
            <span className='bg-gray text-light-blue bold rounded-md pl-1 pr-1'>
              Jim
            </span>
            {' '}
            been able to make quality decisions
            <span className='bg-gray text-red bold rounded-md pl-1 pr-1'>
              {' '}
              in the last 2 weeks?
            </span>
          </i>
        </span>
      );
    }

    const nameStyle = 'font-weight: bold; color: #46AEFF;padding-left: 0.25rem; padding-right: 0.25rem; border-radius: 0.375rem; background-color: #F2F2F2; line-height: 22px;';
    const frequencyStyle = 'font-weight: bold; color: #FF5964; padding-left: 0.25rem; padding-right: 0.25rem; border-radius: 0.375rem; background-color: #F2F2F2; line-height: 22px;';

    const questionFormatted = question.split(' ').map((word, index) => {
      if (word.match(new RegExp('@name', 'gi'))) {
        const wordsAround = word.split('@name');
        const nameFormatted = `<span style='line-height: 22px;font-size:15px;'>${wordsAround[0]}<span style='${nameStyle}'>Jim</span>${wordsAround[1]}</span>`;

        return (
          <span
            key={index}
            dangerouslySetInnerHTML={{ __html: nameFormatted }}
          />
        );x
      }
      if (word.match(new RegExp('@frequency', 'gi'))) {
        const wordsAround = word.split('@frequency');
        const frequencyFormatted = `<span style='line-height: 22px;font-size:15px;'>${wordsAround[0]}<span style='${frequencyStyle}'>in the last 2 weeks</span>${wordsAround[1]}</span>`;

        return (
          <span
            key={index}
            dangerouslySetInnerHTML={{ __html: frequencyFormatted }}
          />
        );
      }
      return typeof word === 'string' ? ` ${word} ` : word;
    });

    return <div className='mb-4'>{questionFormatted}</div>;
  };

  return (
    <div className='p-4 pt-2 bg-lightest-blue rounded-md'>
      <p className='font-bold'>Question preview:</p>
      <QuestionDisplayed />
      <QuestionPreviewAnswers />
    </div>
  );
};

export default QuestionPreview;
