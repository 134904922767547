import React, { useContext, useState, useEffect } from 'react';
import { PulseQuestionResultsContext } from 'src/pagesDashboard/PulseQuestionResults/context/PulseQuestionResultsProvider';
import pulseActions from 'src/queries/actions/pulse';
import {
  notifyPulseParticipation,
  usePulseParticipationOverall,
  usePulseParticipationStats
} from 'src/queries/Pulse/pulseData';
import { useTree } from 'src/queries/tree';
import COMMON_CONSTANTS from 'common/commonConstants';
import {
  Base, Modal, Button, toast, Pagination
} from 'src/components/';
import commonTreeUtils from 'common/commonTreeUtils';
import commonDateUtils from 'common/commonDateUtils';

const { PULSE_PARTICIPATION_TYPES } = COMMON_CONSTANTS;

const PulseParticipationModal = ({ close, companyid, questionId }) => {
  const { context, dispatch } = useContext(PulseQuestionResultsContext);
  const { range } = context;

  const [totalPages, setTotalPages] = useState(1);
  const [curPage, setCurPage] = useState(1);
  const [tabsTotal, setTabsTotal] = useState();
  const { mutateAsync: notifyUsers, isLoading: notifyUserLoading } = notifyPulseParticipation();
  const [notifyDisable, setNotifyDisable] = useState([]);
  const [completionAvgConditional, setCompletionAvgConditional] = useState({
    completionAvgMin: 75,
    completionAvgMax: 100
  });

  const {
    participantsPagination: {
      current: currentPage,
      pages,
      selectPage,
      isFetching: isFetchingParticipantsPagination,
      isError: isErrorParticipantsPagination
    },
    participantsData
  } = usePulseParticipationStats(completionAvgConditional, questionId);

  const {
    data: { tabsTotalData, overallParticipation },
    isFetching: isFetchingTabsTotal,
    isError: isErrorTabsTotal
  } = usePulseParticipationOverall(questionId);

  useEffect(() => {
    if (pages && pages !== totalPages) {
      setTotalPages(pages);
    }
    if (currentPage && currentPage !== curPage) setCurPage(currentPage);
  }, [pages, currentPage]);

  useEffect(() => {
    const result = tabsTotalData;
    setTabsTotal(result);
  }, [tabsTotalData]);

  const isFetching = isFetchingTabsTotal;
  const isError = isErrorParticipantsPagination || isErrorTabsTotal;
  const isReady = tabsTotalData && overallParticipation && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const getRange = (rangeValue) => {
    if (rangeValue === 'lastMonth') {
      return 'Last month';
    }
    if (rangeValue === '3months') {
      return '3 months';
    }
    if (rangeValue === '6months') {
      return '6 months';
    }
    if (rangeValue === '1year') {
      return '1 year';
    }
    if (rangeValue === 'custom') {
      const startDate = commonDateUtils.unixToMonthDayYearFormat(range.start);
      const endDate = commonDateUtils.unixToMonthDayYearFormat(range.end);
      return `${startDate} - ${endDate}`;
    }
    return 'All time';
  };

  const { completionAvgMin, completionAvgMax } = completionAvgConditional;

  const completionAvgList = ['75-100%', '50-74%', '25-49%', '0-24%'];

  const changeCompletionAverageRange = (completionAvg) => {
    if (completionAvg === '75-100%') {
      setCompletionAvgConditional({
        completionAvgMin: 75,
        completionAvgMax: 100
      });
    }
    if (completionAvg === '50-74%') {
      setCompletionAvgConditional({
        completionAvgMin: 50,
        completionAvgMax: 74
      });
    }
    if (completionAvg === '25-49%') {
      setCompletionAvgConditional({
        completionAvgMin: 25,
        completionAvgMax: 49
      });
    }
    if (completionAvg === '0-24%') {
      setCompletionAvgConditional({
        completionAvgMin: 0,
        completionAvgMax: 24
      });
    }
  };

  const isNotifyDisabled = (userId, participationRate) => {
    if (notifyDisable.includes(userId) || participationRate >= 90) {
      return true;
    }
    return false;
  };

  const isNotifyGroupDisabled = () => {
    if (participantsData.length === 0) {
      return true;
    }
    if (
      participantsData.every((user) => isNotifyDisabled(user.userId, user.completionAverage))
    ) {
      return true;
    }
    return false;
  };

  const handleNotification = async ({
    userId,
    completionAverage,
    notifyGroup = false
  }) => {
    if (notifyGroup) {
      const disableList = participantsData.map((user) => user.userId);
      setNotifyDisable([...notifyDisable, ...disableList]);
      const usersToNotify = participantsData.filter(
        (user) => user.completionAverage < 90 && !notifyDisable.includes(user.userId)
      );
      toast.show('Notifying members...');
      const result = await notifyUsers({
        participantsData: usersToNotify,
        range,
        companyid,
        questionId
      });
      if (!result || !result.success) {
        toast.error('Failed to notify members');
        return;
      }
      toast.show('Members notified');
    }
    if ((userId && completionAverage === 0) || (userId && completionAverage)) {
      setNotifyDisable([...notifyDisable, userId]);
      toast.show('Notifying user...');
      const result = await notifyUsers({
        participantsData: [{ userId, completionAverage }],
        range,
        companyid,
        questionId
      });
      if (!result.success) {
        toast.error('Failed to notify user');
        return;
      }
      toast.show('Member notified');
    }
  };

  return (
    <Modal
      variant='custom-no-scroll'
      close={close}
      classes='shadow text-left pl-0'
      innerClasses='w-3/5 text-left'
    >
      <Base
        classes='text-left shadow-none pl-1'
        loading={isFetchingParticipantsPagination}
      >
        {!overallParticipation && overallParticipation !== 0 ? (
          <div>
            <div className='w-3/6 text-left inline-block align-top'>
              <h5 className='font-bold'>
                {`Pulse Participation stats (${getRange(range.value)})`}
              </h5>
              <p>
                User participation rates on pulse reviews throughout the
                organization
              </p>
            </div>
            <div className='flex items-center w-max h-80 justify-around mr-5'>
              <p className='font-bold text-xl'>
                Your organization has no reviews performed yet.
              </p>
            </div>
            <div className='w-max flex justify-end h-24 items-end'>
              <Button
                onClick={close}
                variant='white'
                classes='text-2xl pr-12 pt-0 h-12 pt-2 font-extrabold focus:outline-none mb-8'
              >
                Close
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <div className='w-3/6 text-left inline-block align-top'>
              <h5 className='font-bold'>
                {`Pulse Participation stats (${getRange(range.value)})`}
              </h5>
              <p>
                User participation rates on pulse reviews throughout the
                organization
              </p>
            </div>
            <div className='w-3/6 text-right inline-block align-top'>
              <p className='mb-4 text-right text-5xl mt-4 text-purple font-extrabold pr-6'>{`${overallParticipation}%`}</p>
              <p className='mb-6 text-purple text-lg font-extrabold pr-8'>
                Overall
              </p>
            </div>
            <div className='w-full pr-8'>
              <div className='flex justify-between text-center'>
                {tabsTotal
                  && completionAvgList.map((completionAvg) => (
                    <div className='w-3/12' key={completionAvg}>
                      <button
                        type='button'
                        onClick={(event) => {
                          event.preventDefault();
                          changeCompletionAverageRange(completionAvg);
                        }}
                        className='text-left rounded-none focus:outline-none'
                      >
                        <h3 className='text-base pb-0'>{`${completionAvg} (${tabsTotal[completionAvg]} employees)`}</h3>
                      </button>
                      {`${completionAvgMin}-${completionAvgMax}%`
                        === completionAvg && (
                        <div className='block border border-t-0 border-l-0 border-r-0 border-b-4 border-purple' />
                      )}
                    </div>
                  ))}
              </div>
              <table className='m-0 pb-2 w-full bg-gray-100'>
                <thead className='flex w-full pr-4'>
                  <tr className='flex w-full pb-2'>
                    <th className='p-4 w-1/4 text-base'>Name</th>
                    <th className='p-4 w-1/4 text-base'>Participation rate</th>
                    <th className='p-4 w-1/4 text-base'>Questions Completed</th>
                    <th className='p-4 w-1/4 text-base'> </th>
                  </tr>
                </thead>
                <tbody className='bg-grey-light items-center flex flex-col w-full h-52'>
                  {participantsData.map((participant) => (
                    <tr className='flex w-full mb-3 h-8 items-center'>
                      <td className='pl-4 w-1/4 text-base truncate'>{`${participant.name}`}</td>
                      <td className='pl-4 w-1/4 text-base'>{`${participant.completionAverage}%`}</td>
                      <td className='pl-4 w-1/4 text-base'>{`${participant.reviewed}/${participant.sent}`}</td>
                      <td className='pl-4 w-1/4 text-right pr-6'>
                        <Button
                          variant='yellow'
                          classes='w-24 h-8 pt-1 pb-1'
                          disabled={isNotifyDisabled(
                            participant.userId,
                            participant.completionAverage
                          )}
                          onClick={() => {
                            handleNotification(participant);
                          }}
                        >
                          Notify
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className='flex flex-row items-center justify-between w-100 h-16 mt-2 w-auto'>
                <div className='mt-2 flex h-full w-64 items-center'>
                  <div>
                    <Pagination
                      name='Participation Stats'
                      totalPages={totalPages}
                      currentPage={curPage}
                      selectPage={selectPage}
                      isLoading={isFetchingParticipantsPagination}
                    />
                  </div>
                </div>
                <div className='h-16 w-36'>
                  <Button
                    classes='h-10 pt-1 pb-1'
                    variant='yellow'
                    disabled={isNotifyGroupDisabled()}
                    onClick={() => {
                      handleNotification({ notifyGroup: true });
                    }}
                  >
                    Notify Group
                  </Button>
                </div>
              </div>
            </div>
            <div className='block border border-t-0 border-l-0 border-r-0 border-b-2 mb-3 border-lightest-black mt-4 mr-8' />
            <div className='w-full h-16 flex justify-between mt-2'>
              <p className='w-max text-sm leading-4 mb-0 mt-2 ml-2'>
                <strong>Notify </strong>
                - sends a message to the user alerting them of their
                <br />
                participation rate and ways in which they can improve
              </p>
              <Button
                onClick={close}
                variant='white'
                classes='text-2xl pr-12 pt-0 h-12 pt-2 font-extrabold focus:outline-none'
              >
                Close
              </Button>
            </div>
          </div>
        )}
      </Base>
    </Modal>
  );
};

export default PulseParticipationModal;
