import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavigationItem from 'src/pagesDashboard/HomePage/components/mobile/NavigationItem';
import SpeechBubbleOvalSVG from 'src/assets/svg/speech-bubble-oval.svg';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Button, Base, Radio } from 'src/components';
import STYLE from 'src/constants/style';

const SubmitFeedbackContainer = (props) => {
  const navigate = useNavigate();
  const {
    children,
    parentProps: { type, rest: currentRoute }
  } = props;

  const { textAlternativeOnGiveFeedbackPage } = useFlags();

  const [selectionValue, setSelectionValue] = useState();
  // hide feedback type selection on mobile
  const [isSubmitted, setIsSubmitted] = useState(true);

  const opts = [
    {
      title: textAlternativeOnGiveFeedbackPage
        ? 'Share a general feedback comment'
        : 'Give Feedback',
      value: 'feedback',
      description: textAlternativeOnGiveFeedbackPage
        ? 'Add "on-demand" feedback for the team here! Kudos from a client? Want to thank them for helping out on a project? Add it here!'
        : 'Provide general feedback without assigning it to a performance category'
    }
  ];

  useEffect(() => {
    if (type || currentRoute) {
      setSelectionValue(type ?? currentRoute);
    }
  }, [type, currentRoute]);

  const submitFeedbackOption = () => {
    setIsSubmitted(true);
    navigate(`/dashboard/submit-feedback/${selectionValue}`);
  };

  useEffect(() => {
    // hide feedback type selection on mobile
    navigate(`/dashboard/submit-feedback/feedback`);
  }, []);

  return (
    <Base classes={STYLE.MOBILE_CONTAINER_NO_SCROLL}>
      {!isSubmitted ? (
        <div className='w-full'>
          <div className='w-full fixed top-0 mt-12 bg-white z-50'>
            <NavigationItem
              goBack={() => navigate('/dashboard/home')}
              title='Give Feedback'
            >
              <SpeechBubbleOvalSVG />
            </NavigationItem>
          </div>
          <div className='mt-[87px]'>
            <div className='px-2'>
              {opts.map((option) => (
                <div className='font-semibold' key={option.title}>
                  <Radio
                    name='option'
                    value={selectionValue}
                    title={option.title}
                    checked={selectionValue === option.value}
                    onChange={() => setSelectionValue(option.value)}
                  />
                  <div>
                    <p className='text-dark-grey ml-9'>{option.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className='px-3 w-full flex flex-col items-center w-full fixed bottom-0 gap-3 pb-2'>
              <Button
                variant='black'
                classes='w-full'
                disabled={!selectionValue}
                onClick={() => submitFeedbackOption(true)}
              >
                Next
              </Button>
              <Button
                variant='custom'
                classes='bg-white w-full text-black font-bold text-sm px-4 pt-2 pb-2 border rounded-md border-gray-300'
                onClick={() => navigate('/dashboard/home')}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div>{children}</div>
      )}
    </Base>
  );
};

export default SubmitFeedbackContainer;
