import qs from 'qs';
import { useQuery, useMutation } from 'react-query';
import commonUserUtils from 'common/commonUserUtils';
import appUtils from 'src/components/appUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import api from '../services/api';

const { USER_STATE } = COMMON_CONSTANTS;

const accountPlaceholder = {
  access: null,
  companyid: null,
  email: null,
  firstName: null,
  forgotPasswordTimestamp: null,
  forgotPasswordToken: null,
  googleOauth: null,
  groups: [],
  id: null,
  imageUrl: null,
  invite_token: null,
  lastName: null,
  loginType: null,
  msTeamsAuth: {},
  msTeamsEmail: null,
  msTeamsUserId: null,
  name: null,
  other: null,
  permissions: {},
  phone: null,
  preferredCommunication: null,
  reviewedBy: [],
  reviews: [],
  scheduleTime: {},
  settings: {},
  showIntro: {},
  slackAuth: {},
  slackUserId: null,
  startDate: null,
  status: null,
  timezone: null,
  title: null,
  tz: null,
  verified: null,
  verifyToken: null,
  webexAuth: {},
  webexEmail: null,
  webexUserId: null,
  zip: null
};

export const uploadImageQ = () => useMutation((formData) => {
  console.log('Making POST /user/uploadImage call');
  return api.postForm('/user/uploadImage', formData);
});

export const updateMyProfileImage = () => useMutation((formData) => api.postForm('/user/profilePicture', formData));

// ***
// Can we deprecate this and use useSearchUsers instead?
export const useUsers = () => useQuery(
  ['users'],
  () => api.get('/user/company').then((resp) => {
    if (!resp.success) {
      console.error('GET /user/company error');
      return [];
    }
    const { users } = resp;
    const noSlackAuthUsers = users.filter(
      (user) => !commonUserUtils.hasSlackAuthenticated(user)
    );
    return {
      users,
      noSlackAuthUsers
    };
  }),
  {
    placeholderData: {
      users: [],
      noSlackAuthUsers: []
    }
  }
);

export const useSearchUsers = (filters = {}, options = {}, enabled = true) => useQuery(
  ['users', filters, options],
  () => {
    const stringified = qs.stringify(
      { filters, options },
      { skipNulls: true }
    );
    return api.get(`/user/search?${stringified}`).then((resp) => {
      if (!resp.success) {
        console.error('GET /user/search error', resp);
        return null;
      }
      return resp;
    });
  },
  {
    enabled: Boolean(enabled)
  }
);

// ***
// Can we deprecate this and use useSearchUsers instead?
export const doesUserExistQuery = () => useMutation(({ email }) => {
  const encodedEmail = appUtils.encodeURIString(email);
  return api.get(`/user/exists/${encodedEmail}`).then((resp) => resp);
});

export const useInviteToken = (token) => useQuery(
  ['invite'],
  () => api
    .get(`/user/getUserWithInviteToken/${token}`)
    .then((resp) => resp.user),
  {
    enabled: Boolean(token)
  }
);

export const sendInvites = () => useMutation((data) => api.post('/user/invite', data));

export const disconnectCommunicationQuery = () => useMutation((data) => api.delete('/user/communication', data));

export const connectSlackQuery = () => useMutation(({ code, state, redirectUrl }) => {
  console.log('Making POST /user/slack call');
  return api.post('/user/slack', { code, state, redirectUrl });
});

export const connectMSTeamsQuery = () => useMutation(({
  code, state, session_state, redirectUrl
}) => api.post('/user/msteams', {
  code,
  state,
  session_state,
  redirectUrl
}));

export const update = () => useMutation((data) => api
  .post('/user/profile/update', data)
  .then((resp) => resp)
  .catch((err) => {
    console.error('update settings error:', err, data);
    return Promise.reject(err);
  }));

// ***
// Can we deprecate this in favor of updating the user
export const updateCommunicationQuery = () => useMutation(({ communication }) => api
  .post('/user/updatePreferredCommunication', { communication })
  .then((resp) => resp)
  .catch((err) => {
    console.error('update settings ERROR response..', err);
    return Promise.reject(err);
  }));