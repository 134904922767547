import React from 'react';
import InformationCircleSVG from 'src/assets/svg/informationCircle.svg';
import InfoCircleAlterSVG from 'src/assets/svg/infoCircle-alter.svg';

const Tooltip = ({
  text,
  containerClasses,
  textClasses,
  children,
  infoClasses,
  iconType
}) => (
  <div
    className={`tooltip ${
      containerClasses ?? 'w-5 h-5 inline-block ml-2 mt-1 align-top'
    }`}
  >
    {children || (
      <span
        className={`tooltip-text ${
          textClasses ?? 'bg-black text-white ml-5 max-w-19rem'
        }`}
      >
        {text}
      </span>
    )}
    {iconType === 'alter' ? (
      <InfoCircleAlterSVG className={infoClasses} />
    ) : (
      <InformationCircleSVG className={infoClasses} />
    )}
  </div>
);

export default Tooltip;
