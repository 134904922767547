import React, { useState } from 'react';
import {
  Radio, Button, toast, Base, Placeholder
} from 'src/components';
import { useCompany, updateCompanyQuery } from 'src/queries/company';
import COMMON_CONSTANTS from 'common/commonConstants';
import STYLE from 'src/constants/style';
import { get } from 'lodash';

const { VIEW_USER_PROFILE_PERMISSIONS } = COMMON_CONSTANTS;

const UserProfileViewPermissions = () => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany,
    refetch
  } = useCompany();
  const { mutateAsync: updateSettings, isLoading: updateSettingsLoading } = updateCompanyQuery();
  const [state, setState] = useState({
    newViewUserProfile: get(company, 'settings.viewUserProfile', VIEW_USER_PROFILE_PERMISSIONS.OPEN)
  });

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError && !updateSettingsLoading;
  if (!isReady) {
    return <Placeholder />;
  }

  const update = async () => {
    const res = await updateSettings({
      settings: {
        ...company.settings,
        viewUserProfile: state.newViewUserProfile
      }
    });
    refetch();
    if (!res.success) {
      return toast.error(
        "We've encountered an issue. Please try again or contact us."
      );
    }
    toast.show('Updated!');
  };

  return (
    <Base classes={STYLE.CONTAINER_WHITE}>
      <h5 className='mb-2'>User Profile Permissions</h5>
      <p className='mb-4'>
        Determines the ability to view team members' public user profiles
      </p>
      <div className='mb-4'>
        <Radio
          title='Can view all user profiles (default)'
          checked={
            state.newViewUserProfile === VIEW_USER_PROFILE_PERMISSIONS.OPEN
          }
          name='viewUserProfile'
          onChange={(e) => {
            setState({
              ...state,
              newViewUserProfile: VIEW_USER_PROFILE_PERMISSIONS.OPEN
            });
          }}
        />
        <p className='text-sm ml-3 mt-2 mb-0 italic'>
          <li>
            Scores, historic reviews and private feedback is still restricted
            based on permissions
          </li>
        </p>
      </div>

      <div className='mb-4'>
        <Radio
          title='Limited to hierarchy views'
          checked={
            state.newViewUserProfile === VIEW_USER_PROFILE_PERMISSIONS.HIERARCHY
          }
          name='viewUserProfile'
          onChange={(e) => {
            setState({
              ...state,
              newViewUserProfile: VIEW_USER_PROFILE_PERMISSIONS.HIERARCHY
            });
          }}
        />
        <p className='text-sm ml-3 mt-2 mb-0 italic'>
          <li>
            Based on tree hierarchy - if user A is above user B, user A can view
            user B's profile page
          </li>
          <li>Admins can see all profiles</li>
          <li>Managers cannot see all profiles</li>
        </p>
      </div>
      <div className='mb-1'>
        <Button
          variant='yellow'
          disabled={
            company.settings.viewUserProfile === state.newViewUserProfile
          }
          onClick={update}
        >
          Update
        </Button>
      </div>
    </Base>
  );
};

export default UserProfileViewPermissions;
