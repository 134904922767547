import React, {
  useState, useEffect, useRef, forwardRef
} from 'react';
import Litepicker from 'litepicker';
import appUtils from '../appUtils';
import './SimpleDatePicker.scss';

const SimpleDatePicker = forwardRef(
  (
    {
      date,
      onSelect,
      classes,
      inputClasses,
      placeholder,
      properties = {},
      disabled = false
    },
    ref
  ) => {
    const [picker, setPicker] = useState({
      id: `picker_${appUtils.getRandomString(35)}`,
      rendered: false
    });

    const inputRef = useRef();

    useEffect(() => {
      if (!picker.rendered && !disabled) {
        const li = new Litepicker({
          element: inputRef.current,
          singleMode: true,
          onSelect,
          setup: (pickerr) => {
            pickerr.on('selected', (date1) => onSelect(date1.dateInstance));
          },
          ...properties
        });

        setPicker({
          ...picker,
          rendered: true
        });
      }
    }, []);

    const textPlaceholder = placeholder || 'All Time';

    return (
      <div className={classes || ''}>
        <input
          className={`${inputClasses || ''} picker-input`}
          placeholder={textPlaceholder}
          type='text'
          id={picker.id}
          value={`${date ? date.toDateString() : ''}`}
          ref={inputRef}
          disabled={disabled}
          onChange={() => {}}
        />
      </div>
    );
  }
);

export default SimpleDatePicker;
