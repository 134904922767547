import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { Provider, ErrorBoundary } from '@rollbar/react';
import { connect } from 'react-redux';
import OurRoutes from 'src/app/Routes';
import InitApp from 'src/components/InitApp/InitApp';
import rollbarConfig from 'src/utils/rollbar';
import { ToastContainer, TOAST_CONTAINER_ID } from 'src/components/Toast/Toast';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import featureFlags from 'src/utils/featureFlags';
import { useAccount } from 'src/queries/account';
import { useCompany } from 'src/queries/company';
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  TimeScale
} from 'chart.js';
import { ErrorUI } from '.';
import { MobileMenu, ModalsContainer } from '../containers';
import appUtils from './appUtils';
import appThunks from '../thunks/appThunks';
import { appActions, sidebarActions } from '../reducers/actions';
import { APP_CONSTANTS } from '../constants';
import './app.scss';
import api from '../services/api';

const { USER_STATE } = COMMON_CONSTANTS;

const isAdPage = () => /^\/$/.test(window.location.pathname);

const TopLevel = ({ children }) => {
  const [ready, setReady] = useState(false);
  // React defers running useEffect with no dependency until after the browser has rendered
  // https://legacy.reactjs.org/docs/hooks-effect.html
  useEffect(() => {
    const doesToastContainerExist = document.getElementById(TOAST_CONTAINER_ID);
    if (!doesToastContainerExist) {
      console.error('Toast container not loaded in TopLevel');
    } else setReady(true);
  }, []);

  return (
    <>
      <ToastContainer />
      {ready ? children : null}
    </>
  );
};

function App({ app, dispatch }) {
  const { showMobileMenu } = app;

  const [isCheckingAccount, setIsCheckingAccount] = useState(true);

  // required for react-chart-2 to work
  Chart.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const handleLoginStatus = async () => {
    try {
      const isLoggedIn = appUtils.isLoggedIn();

      const shouldBeLoggedOut = (userData) => {
        const canLogin = get(userData, 'permissions.canLogin', false);
        const isUnassigned = userData.status === USER_STATE.UNASSIGNED;
        return canLogin === false || isUnassigned;
      };
      if (isLoggedIn) {
        appThunks.loggedUserStatusChecked(false)(dispatch);
        const loggedUser = appUtils.getLoggedUser();
        if (shouldBeLoggedOut(loggedUser)) return appThunks.logOut()(dispatch);
        if (['/login', '/'].includes(window.location.pathname)) {
          api.abort(['get']);
          window.location.href = appUtils.getHomeRoute();
        }
      } else {
        const response = await api.get('/user/me');
        if (response && response.success) {
          const userToBeLogged = response.user;
          if (!shouldBeLoggedOut(userToBeLogged)) {
            api.abort(['get', 'post']);
            return appThunks.logUserIn(userToBeLogged)(dispatch);
          }
        }
        appThunks.loggedUserStatusChecked(false)(dispatch);
      }
    } catch (error) {
      console.error('app.js.handleLoginStatus', error);
      const shouldLogUserOut = get(error, 'response.data.logUserOut');
      if (shouldLogUserOut) {
        appThunks.logOut()(dispatch);
      }
    }
  };

  const initWatchWidthOfScreen = () => {
    const WidthChange768 = (mq) => {
      if (mq.matches) {
        if (showMobileMenu) {
          dispatch(appActions.showMobileMenu(false));
        }
      }
    };

    const WidthChange1200 = (mq) => {
      if (!mq.matches) {
        dispatch(sidebarActions.minimizeSidebar());
      }
    };

    if (matchMedia) {
      const mq = window.matchMedia('(min-width: 1000px)');
      const mq768 = window.matchMedia('(min-width: 768px)');
      const mq1200 = window.matchMedia(
        `(min-width: ${APP_CONSTANTS.FORCE_MINIMUM_DASH_SIDEBAR_AT_WIDTH}px)`
      );

      mq1200.addListener(WidthChange1200);
      mq768.addListener(WidthChange768);
    }
  };

  useEffect(() => {
    handleLoginStatus();

    if (!window.speedMatchMediaWatcher) {
      initWatchWidthOfScreen();
    }
  }, []);

  useEffect(() => {
    appThunks.loggedUserStatusChecked(false)(dispatch);
  }, [isCheckingAccount]);

  const { enableRollbar } = useFlags();

  const rollbarLogConfig = rollbarConfig(enableRollbar);
  const loggedUser = appUtils.getLoggedUserId();
  const userId = loggedUser ? 'me' : false;
  const { data: userData } = useAccount(userId);
  const { data: company } = useCompany({
    isEnabled: Boolean(appUtils.getLoggedUserId())
  });
  const ldClient = useLDClient();

  useEffect(() => {
    const isValid = get(userData, '_id', false) && get(company, 'id', false);
    if (ldClient && isValid) {
      featureFlags.identify(ldClient, userData, company);
    }
  }, [userData, company, ldClient]);

  return (
    <React.StrictMode>
      <Provider config={rollbarLogConfig}>
        <ErrorBoundary fallbackUI={ErrorUI}>
          <TopLevel>
            <>
              <InitApp />
              <ModalsContainer />
              {showMobileMenu ? <MobileMenu /> : null}
              <canvas id='myChart' />

              <div
                className={`getspeedback full-size ${
                  isAdPage() ? 'getspeedback-intro-footer' : ''
                }`}
              >
                <OurRoutes />
              </div>
            </>
          </TopLevel>
        </ErrorBoundary>
      </Provider>
    </React.StrictMode>
  );
}

export const mapStateToProps = (state) => ({
  app: state.appReducer
});

export const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
