import React, { useState, useEffect, useRef } from 'react';
import './Circle.scss';
import appUtils from 'src/components/appUtils';
import CameraIcon from '../../assets/svg/camera.svg';

const Circle = ({
  size,
  onUpload,
  imageUrl,
  isDisabled = false,
  border = false
}) => {
  const [isMouseOver, setMouseOver] = useState(false);
  const fileInput = useRef();
  const inputEnabled = onUpload && !isDisabled;

  let circleSize = 'w-32 h-32';
  if (size === '2xl') circleSize = 'w-24 h-24';
  if (size === 'xl') circleSize = 'w-20 h-20';
  if (size === 'lg') circleSize = 'w-16 h-16';
  if (size === 'md') circleSize = 'w-14 h-14';
  if (size === 'sm') circleSize = 'w-12 h-12';
  if (size === 'xsm') circleSize = 'w-8 h-8';

  let borderClasses = '';
  if (border) {
    borderClasses = 'border-white';
    if (border === 'xl') borderClasses += ' border-16';
    if (border === 'lg') borderClasses += ' border-8';
    if (border === 'md') borderClasses += ' border-4';
    if (border === 'sm') borderClasses += ' border-2';
  }

  const openInput = (inputRef) => {
    if (inputEnabled) inputRef.current.click();
  };

  let top = false;
  if (size === 'md') {
    top = 5;
  } else if (size === 'lg') {
    top = 22;
  } else {
    top = parseInt(size, 10);
  }

  const [currentImage, setCurrentImage] = useState(appUtils.getImageUrl());
  const [loading, setLoading] = useState(true);

  const fetchImage = (src) => {
    const loadingImage = new Image();
    loadingImage.src = appUtils.getImageUrl(src);
    loadingImage.onload = () => {
      setCurrentImage(loadingImage.src);
      setLoading(false);
    };
  };

  useEffect(() => {
    fetchImage(imageUrl);
  }, [imageUrl]);

  return (
    <div className='inline-block justify-center'>
      <input
        type='file'
        className='hidden'
        ref={fileInput}
        onChange={(e) => onUpload(e)}
      />
      <div
        className={`relative cursor-pointer ${size !== 'fit' ? 'mb-4' : ''}`}
        onClick={() => openInput(fileInput)}
      >
        <img
          src={currentImage}
          className={`${circleSize} rounded-full shadow-inner-md shadow-md object-cover ${borderClasses} ${loading ? 'blur-md' : ''}`}
          alt='Profile circle'
        />
        <div
          className={`${circleSize} rounded-full bg-lightest-black flex justify-center items-center absolute top-0 transition duration-300 
          ${inputEnabled && isMouseOver ? 'opacity-100' : 'opacity-0'}`}
          onMouseOver={() => setMouseOver(true)}
          onMouseLeave={() => setMouseOver(false)}
        >
          <CameraIcon />
        </div>
      </div>
    </div>
  );
};

export default Circle;
