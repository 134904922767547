import React from 'react';
import { appUtils, Base } from 'src/components/index';
import STYLE from 'src/constants/style';
import { useAccount } from 'src/queries/account';
import { get } from 'lodash';

const NoData = ({
  title = 'Nothing here yet!',
  prefix = 'Once',
  prefixSelf = 'Once',
  userId = appUtils.getLoggedUserId(),
  postFixSelf = `have received enough feedback, we'll show data here.`,
  postfix = `has received enough feedback, we'll show data here.`
}) => {
  const {
    data: account,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount(userId);

  const isFetching = isFetchingAccount;
  const isError = isErrorAccount;
  const isReady = !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const loggedUserId = appUtils.getLoggedUserId();
  const isSelf = userId === loggedUserId;
  const name = isSelf ? 'you' : get(account, 'name', 'this person');
  return (
    <>
      <Base
        classes={`${STYLE.CONTAINER_WHITE_PADDINGLESS_MARGINLESS_SHADOWLESS}`}
        relative
      >
        <div className='h-full w-full flex flex-col test-left'>
          <p className='font-semibold text-sm leading-7 m-0'>{title}</p>
          <p className='text-sm leading-7 m-0'>
            {isSelf ? prefixSelf : prefix}
            {' '}
            {name}
            {' '}
            {isSelf ? postFixSelf : postfix}
          </p>
        </div>
      </Base>
    </>
  );
};

export default NoData;
