import { isEmpty, isPlainObject } from 'lodash';
import COMMON_QUESTION_CONSTANTS from 'common/commonQuestionConstants';
import COMMON_CONSTANTS from 'common/commonConstants';
import commonCompanyUtils from 'common/commonCompanyUtils';

const { DEFAULT_QUESTION, SHARE_REVIEW_WITH } = COMMON_CONSTANTS;
const { STATUS } = COMMON_QUESTION_CONSTANTS;

const utils = {};

// in the database, the questions are structured differently
// (smaller names to preserve size and no follow up function since you can't store functions in the db)

// + creates the follow up function for the app's base questions
// + transforms the base questions into the format that is currently used in the application
utils.questionsDbToApp = (baseQuestions) => {
  baseQuestions.QUESTIONS.forEach((qObject) => {
    const { followup } = qObject;
    if (followup) {
      qObject.getFollowUpChoiceQuestionObject = (score) => {
        if (score > 0 && score < 7) {
          return {
            id: followup.id,
            question: followup.question,
            multipleChoiceAnswers: followup.mc
          };
        }

        return false;
      };
    } else {
      qObject.getFollowUpChoiceQuestionObject = () => undefined;
    }

    qObject.questionHeaders = qObject.headers;
    qObject.questionYourself = qObject.self;
    delete qObject.headers;
    delete qObject.self;
  });

  return baseQuestions;
};

utils.questionDbToApp = (question) => {
  const newQuestion = { ...question };
  newQuestion.questionHeaders = newQuestion.headers;
  newQuestion.questionYourself = newQuestion.self;
  delete newQuestion.headers;
  delete newQuestion.self;
  return newQuestion;
};

utils.questionsAppToDb = (baseQuestions) => {
  const newBaseQuestions = { ...baseQuestions };
  newBaseQuestions.QUESTIONS.forEach((qObject) => {
    qObject.self = qObject.questionYourself;
    qObject.headers = qObject.questionHeaders;
    delete qObject.followup;
    delete qObject.getFollowUpChoiceQuestionObject;
    delete qObject.questionYourself;
    delete qObject.questionHeaders;
  });

  return newBaseQuestions;
};

utils.findRole = (companyQuestions, roleId) => {
  if (!roleId) return null;
  let roleObject = null;
  Object.keys(companyQuestions.ROLES).forEach((key) => {
    const roleObj = companyQuestions.ROLES[key];
    if (roleObj.id.toString() === roleId.toString()) {
      roleObject = roleObj;
    }
  });
  return roleObject;
};

utils.findCategory = (companyQuestions, categoryId) => {
  if (!categoryId) return null;
  let categoryObject = null;
  Object.keys(companyQuestions.CATEGORIES).forEach((key) => {
    const catObj = companyQuestions.CATEGORIES[key];
    if (catObj.id.toString() === categoryId.toString()) {
      categoryObject = catObj;
    }
  });
  return categoryObject;
};

utils.findQuestion = (companyQuestions, questionId) => {
  if (!questionId) return null;
  return companyQuestions.QUESTIONS.find(
    (questionObj) => questionObj.id === questionId
  );
};

utils.filloutReviewQuestion = (question, name, companyFrequency, boldName) => {
  try {
    let updatedName = name;
    if (boldName) {
      updatedName = `<b>${name}</b>`;
    } else {
      updatedName = name;
    }
    let html = question.replace(/@name/gi, updatedName);
    if (companyFrequency) {
      const text = commonCompanyUtils.getFrequencyText(companyFrequency);
      html = html.replace('@frequency', `${text}`);
    }
    return html;
  } catch (error) {
    console.error('commonQuestionsUtils.filloutReviewQuestion error', {
      error,
      question,
      name,
      companyFrequency
    });
    return question;
  }
};

utils.getCategoriesContainingQuestion = (companyQuestions, questionId) => {
  const categoryObjects = [];
  Object.keys(companyQuestions.CATEGORIES).forEach((key) => {
    const catObj = companyQuestions.CATEGORIES[key];
    if (catObj.questions.includes(questionId)) {
      categoryObjects.push(catObj);
    }
  });
  return categoryObjects;
};

// check if question with id `questionId` can be archived
utils.canArchiveQuestion = (companyQuestions, questionId) => {
  let invalidOperation = false;
  let invalidMessage = '';
  const inCategories = [];

  if (questionId === DEFAULT_QUESTION.id) {
    return {
      success: false,
      message: 'Cannot archive the default question.'
    };
  }

  Object.keys(companyQuestions.CATEGORIES).forEach((key) => {
    const categoryObj = companyQuestions.CATEGORIES[key];
    const activeAndRecurringQs = categoryObj.questions.filter((qid) => {
      const questionObj = utils.findQuestion(companyQuestions, qid);
      return (
        questionObj
        && questionObj.status === STATUS.ACTIVE
        && questionObj.recurring
      );
    });

    if (activeAndRecurringQs.includes(questionId)) {
      if (activeAndRecurringQs.length < 2) {
        invalidOperation = true;
        invalidMessage = `Each category must have at least 1 active and recurring question. Please add other questions to category ${categoryObj.label} before deleting this question from the system.`;
      }
      inCategories.push(key);
    }
  });

  if (invalidOperation) {
    return {
      success: false,
      message: invalidMessage,
      inCategories
    };
  }

  return { success: true, inCategories };
};

utils.canMakeQuestionNonRecurring = (
  questionId,
  companyQuestionsWithQuestion,
  catWithQuestion = {}
) => {
  if (questionId === DEFAULT_QUESTION.id) {
    return {
      success: false,
      message: 'Cannot make default question non recurring.'
    };
  }

  let categoriesToCheck = [];

  if (isEmpty(catWithQuestion)) {
    categoriesToCheck = utils.getCategoriesContainingQuestion(
      companyQuestionsWithQuestion,
      questionId
    );
  } else if (isPlainObject(catWithQuestion)) {
    categoriesToCheck = [catWithQuestion];
  } else if (Array.isArray(catWithQuestion)) {
    categoriesToCheck = catWithQuestion;
  } else {
    return {
      success: false,
      message: 'Invalid parameter for categoryObject'
    };
  }

  let message = '';
  for (const category of categoriesToCheck) {
    const activeCategoryQuestions = companyQuestionsWithQuestion.QUESTIONS.filter(
      (question) => category.questions.includes(question.id)
          && question.status === STATUS.ACTIVE
    );
    const isLastRecurringQuestion = activeCategoryQuestions.every(
      (question) => !question.recurring || question.id === questionId
    );
    if (isLastRecurringQuestion) {
      if (message) {
        message += `, ${category.label}`;
      } else {
        message = `Categories ${category.label}`;
      }
    }
  }

  if (message) {
    return {
      success: false,
      message: `${message} need at least one recurring question.`
    };
  }

  return { success: true };
};

utils.getShareCommentOptions = (
  reviewVisibilitySettings,
  visibilityOptions
) => {
  const options = [];

  if (reviewVisibilitySettings[SHARE_REVIEW_WITH.OPEN_TO_EVERYONE]) {
    options.push(visibilityOptions[0]);
  }
  if (reviewVisibilitySettings[SHARE_REVIEW_WITH.OPENLY_WITH_REVIEWEE]) {
    options.push(visibilityOptions[1]);
  }
  if (reviewVisibilitySettings[SHARE_REVIEW_WITH.REVIEWEE]) {
    options.push(visibilityOptions[2]);
  }
  if (reviewVisibilitySettings[SHARE_REVIEW_WITH.REVIEWEE_MANAGER]) {
    options.push(visibilityOptions[3]);
  }
  if (reviewVisibilitySettings[SHARE_REVIEW_WITH.ANONYMOUS]) {
    options.push(visibilityOptions[4]);
  }

  return options;
};

export default utils;
