import React from 'react';
import { useAccount } from 'src/queries/account';
import { Base } from 'src/components/';
import STYLE from 'src/constants/style';
import UserNotConnected from './UserNotConnected';
import UserConnected from './UserConnected';

const MSTeamsIntegration = () => {
  const {
    data: account,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount('me');

  const isFetching = isFetchingAccount;
  const isError = isErrorAccount;
  const isReady = account && account._id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  return (
    <Base classes={STYLE.CONTAINER_WHITE}>
      <h5>Microsoft Teams Integration</h5>
      {account && !account.msTeamsAuth ? (
        <UserNotConnected />
      ) : (
        <UserConnected />
      )}
    </Base>
  );
};

export default MSTeamsIntegration;
