import React, { useMemo } from 'react';
import CircleV2 from 'src/components/Circle/CircleV2';
import appUtils from 'src/components/appUtils';
import { useTree } from 'src/queries/tree';
import { useAccounts } from 'src/queries/account';
import { Base, toast } from 'src/components/index';
import STYLE from 'src/constants/style';
import commonViewPermissions from 'common/commonViewPermissions';
import { useCompany } from 'src/queries/company';
import { useNavigate } from 'react-router-dom';

export const renderPerson = (
  { _id, name, imageUrl },
  {
    rounded = '', border = '', canAccess = false, navigateTo = () => {}
  } = {}
) => (
  <div
    key={_id}
    className={`flex items-center gap-2 border-[#E5E7EB] pt-1 pb-2 
      ${rounded.includes('top') ? 'border-t rounded-t-lg' : ''} ${
      rounded.includes('bottom') ? 'border-b rounded-b-lg' : ''
    } ${border.includes('sides') ? 'border-r border-l' : ''} ${
      border.includes('bottom') ? 'border-b' : ''
    } ${border.includes('top') ? 'border-t' : ''}`}
  >
    <div>
      <CircleV2 imageUrl={imageUrl} size='xs' />
    </div>
    <button
      className='m-0 font-medium text-base text-black leading-6 truncate hover:text-purple'
      onClick={() => {
        if (canAccess) return navigateTo(appUtils.getDashRoute(_id));
        toast.show('You do not have access to view this team member.');
      }}
    >
      {name}
    </button>
  </div>
);

const GoToPeopleButton = ({ navigateTo = () => {} }) => (
  <button
    type='button'
    onClick={() => navigateTo('/dashboard/people')}
    className='text-black rounded-md border border-black text-sm font-bold p-1 px-3 py-1 h-fit'
  >
    Go to People
  </button>
);

const getFirstSection = ({ manager, peers, directReports }) => {
  if (manager) return 'manager';
  if (peers.length) return 'peers';
  if (directReports.length) return 'directReports';
};

const HomeTeam = () => {
  const loggedUserId = appUtils.getLoggedUserId();
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: iErrorTree
  } = useTree();

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const {
    data: [account],
    isFetching: isFetchingAccount,
    isFetched: isFetchedAccount,
    isError: isErrorAccount
  } = useAccounts(
    {
      ids: [loggedUserId]
    },
    {
      page: {
        size: 1
      },
      include: {
        peers: true,
        manager: true,
        directReports: true
      }
    }
  );

  const navigate = useNavigate();

  const firstSection = useMemo(() => {
    if (isFetchedAccount && account) {
      const { manager, peers, directReports } = account;
      return getFirstSection({
        manager,
        peers,
        directReports
      });
    }
  }, [isFetchedAccount, account]);

  const isFetching = isFetchingTree
    || isFetchingAccount
    || !isFetchedAccount
    || isFetchingCompany;
  const isError = iErrorTree || isErrorAccount || isErrorCompany;
  const isReady = tree && tree.id && company && company.id && !isFetching && !isError;
  if (!isReady) return null;
  const { viewUserProfile } = company.settings;
  const { manager, peers, directReports } = account;

  const maxItems = 4;

  const noData = !manager && !peers.length && !directReports.length;

  return (
    <Base classes={STYLE.CONTAINER_WHITE}>
      <div className='w-full'>
        {noData ? (
          <div className='flex justify-between align-center'>
            <p className='my-3 text-lg font-thin text-[#A2A6AF]'>
              No team members to display.
            </p>
            <GoToPeopleButton navigateTo={navigate} />
          </div>
        ) : (
          <>
            {manager ? (
              <div>
                <div className='flex justify-between align-center'>
                  <p className='mb-2 font-semibold text-xl text-black leading-8'>
                    Manager
                  </p>
                  {firstSection === 'manager' ? (
                    <GoToPeopleButton navigateTo={navigate} />
                  ) : null}
                </div>
                <div className='mb-5'>
                  {renderPerson(manager, {
                    canAccess: commonViewPermissions.canAccessUserDashboard(
                      account,
                      manager,
                      tree,
                      viewUserProfile
                    ),
                    navigateTo: navigate
                  })}
                </div>
              </div>
            ) : null}
            {peers.length ? (
              <div>
                <div className='flex justify-between align-center'>
                  <p className='mb-2 font-semibold text-xl text-black leading-8'>
                    Peers
                  </p>
                  {firstSection === 'peers' ? (
                    <GoToPeopleButton navigateTo={navigate} />
                  ) : null}
                </div>
                <div className='flex flex-col gap-2 mb-5'>
                  {peers.map((peer, index) => {
                    if (index > maxItems) return null;
                    if (index === maxItems) {
                      return (
                        <p key='max-peers' className='m-0'>
                          {`and ${peers.length - maxItems} more`}
                        </p>
                      );
                    }

                    let border = 'bottom';
                    if (index === maxItems - 1 || index === peers.length - 1) border = '';
                    const canAccess = commonViewPermissions.canAccessUserDashboard(
                      account,
                      peer,
                      tree,
                      viewUserProfile
                    );
                    return renderPerson(peer, {
                      border,
                      canAccess,
                      navigateTo: navigate
                    });
                  })}
                </div>
              </div>
            ) : null}
            {directReports.length ? (
              <div>
                <div className='flex justify-between align-center'>
                  <p className='mb-2 font-semibold text-xl text-black leading-8'>
                    Direct Reports
                  </p>
                  {firstSection === 'directReports' ? (
                    <GoToPeopleButton navigateTo={navigate} />
                  ) : null}
                </div>

                <div className='flex flex-col gap-2'>
                  {directReports.map((dr, index) => {
                    if (index > maxItems) return null;
                    if (index === maxItems) {
                      return (
                        <p key='max-dr' className='m-0'>
                          {`and ${directReports.length - maxItems} more`}
                        </p>
                      );
                    }

                    let border = 'bottom';
                    if (
                      index === maxItems - 1
                      || index === directReports.length - 1
                    ) border = '';
                    const canAccess = commonViewPermissions.canAccessUserDashboard(
                      account,
                      dr,
                      tree,
                      viewUserProfile
                    );
                    return renderPerson(dr, {
                      border,
                      canAccess,
                      navigateTo: navigate
                    });
                  })}
                </div>
              </div>
            ) : null}
          </>
        )}
      </div>
    </Base>
  );
};
export default HomeTeam;
