import React, { useState, useEffect } from 'react';
import {
  Radio, Base, Button, toast
} from 'src/components';
import { useCompany, updateCompanyQuery } from 'src/queries/company';
import appUtils from 'src/components/appUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import LockClosedSVG from 'src/assets/svg/lock-closed.svg';
import STYLE from 'src/constants/style';

const { REVIEW_SCHEDULING_TIME } = COMMON_CONSTANTS;

const ReviewSchedule = () => {
  const {
    data: company,
    refetch: refetchCompany,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError;

  const { mutateAsync: updateCompany, isLoading: updateCompanyLoading } = updateCompanyQuery();
  const [schedule, setSchedule] = useState({ schedule: null });

  useEffect(() => {
    if (isReady) {
      setSchedule(company.scheduleTime);
    }
  }, [isReady]);

  if (!isReady) {
    return null;
  }

  const save = async () => {
    toast.show('Updating schedule..');
    await updateCompany({
      scheduleTime: schedule
    });
    toast.show('Saved!');
    refetchCompany();
  };

  const getDisabledTooltip = () => {
    if (!company.active) {
      return 'Company is not active';
    }
    if (company.demo) {
      return 'Disabled in demo mode';
    }
  };

  return (
    <Base classes={`${STYLE.CONTAINER_WHITE} items-start`}>
      <h5 className='mb-1 font-bold inline-block'>Schedule</h5>
      {company.demo ? (
        <span className='ml-2 mt-1 w-5 h-5 align-top inline-block tooltip'>
          <span className='tooltip-text bg-black text-white -mt-10 -ml-16'>
            {getDisabledTooltip()}
          </span>
          <LockClosedSVG />
        </span>
      ) : null}
      <p className='mb-4'>
        When review requests will be sent (all times are Monday-Friday).
      </p>
      {/* {
          appUtils.isSuperUser() ? ( */}
      <div className='mb-4'>
        <Radio
          name='reviewScheduleTime'
          value={REVIEW_SCHEDULING_TIME.EIGHTAM_NINEAM}
          title='8am - 9am'
          disabled={company.demo || !company.active}
          onChange={() => {
            setSchedule(REVIEW_SCHEDULING_TIME.EIGHTAM_NINEAM);
          }}
          checked={schedule === REVIEW_SCHEDULING_TIME.EIGHTAM_NINEAM}
        />
      </div>
      {/* }  ) : null
        } */}
      <div className='mb-4'>
        <Radio
          name='reviewScheduleTime'
          value={REVIEW_SCHEDULING_TIME.EIGHT_TO_FIVE}
          title='8am - 5pm'
          disabled={company.demo || !company.active}
          onChange={() => {
            setSchedule(REVIEW_SCHEDULING_TIME.EIGHT_TO_FIVE);
          }}
          checked={schedule === REVIEW_SCHEDULING_TIME.EIGHT_TO_FIVE}
        />
      </div>
      <div className='mb-4'>
        <Radio
          name='reviewScheduleTime'
          value={REVIEW_SCHEDULING_TIME.TEN_TO_FIVE}
          title='10am - 5pm'
          disabled={company.demo || !company.active}
          onChange={() => {
            setSchedule(REVIEW_SCHEDULING_TIME.TEN_TO_FIVE);
          }}
          checked={schedule === REVIEW_SCHEDULING_TIME.TEN_TO_FIVE}
        />
      </div>
      {/* <div className="mb-5">
          <Radio
            name="reviewScheduleTime"
            value={REVIEW_SCHEDULING_TIME.ANYTIME}
            title="Anytime"
            disabled={company.demo || !company.active}
            onChange={() => { setSchedule(REVIEW_SCHEDULING_TIME.ANYTIME); }}
            checked={schedule === REVIEW_SCHEDULING_TIME.ANYTIME}
          />
        </div> */}

      <Button
        classes='mt-10 mb-1'
        variant='yellow'
        disabled={schedule === company.scheduleTime || updateCompanyLoading}
        onClick={save}
      >
        Save
      </Button>
    </Base>
  );
};

export default ReviewSchedule;
