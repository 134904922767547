import React from 'react';
import { Tooltip } from 'src/components';

const ToggleBundledCategories = ({ onClick, showBundled }) => (
  <div className='block mb-4 text-right'>
    <button
      onClick={onClick}
      className='rounded text-md bg-white border border-mid-gray text-black py-2 px-5 font-bold'
    >
      {showBundled ? 'Toggle Roles View' : 'Toggle Category View'}
    </button>
    <Tooltip
      text={
        showBundled
          ? 'Category scores are bundled'
          : 'Category scores are role-specific'
      }
      containerClasses='w-5 h-5 inline-block ml-2 align-middle'
      textClasses='bg-black text-white -ml-20 -mt-8'
    />
  </div>
);

export default ToggleBundledCategories;
