import React from 'react';
import { Base } from 'src/components';
import BarsSVG from 'src/assets/svg/bars.svg';
import commonUtils from 'common/commonUtils';

const TopScoreBox = ({
  onClick,
  title,
  subtitle = null,
  value,
  tooltip,
  tooltipClasses = '',
  loading = false,
  isPurple = true,
  classes,
  valueClasses
}) => (
  <div className='mb-0 inline-block'>
    <div
      onClick={onClick}
      className={`${
        isPurple ? 'bg-purple text-white' : 'bg-white text-black'
      } h-full tooltip shadow p-1 text-center rounded-md cursor-default relative ${
        loading ? 'loading-blur' : ''
      } ${classes || ''}`}
    >
      {title === 'View participation stats' ? (
        <div className='h-max'>
          <BarsSVG className='text-center w-full h-6 mt-8' />
          <p className='block mt-4 h-12 text-lg mb-0 font-bold truncate text-ellipsis'>
            {title}
          </p>
        </div>
      ) : (
        <div>
          <div className='tooltip-text w-full left-0 -mt-8'>
            <span
              className={`inline-block py-0.5 p-1.5 bg-black text-white rounded ${tooltipClasses}`}
            >
              {tooltip}
            </span>
          </div>
          <p className='block mt-4 text-xl sm:text-2xl lg:text-xl xl:text-2xl mb-0 font-bold truncate text-ellipsis'>
            {title}
          </p>
          <div className='mb-0'>
            {subtitle ? (
              <p className='mb-0 w-5/6 mx-auto text-sm truncate overflow-ellipsis'>
                {subtitle}
              </p>
            ) : (
              <div className='mt-2 mb-4 mx-auto w-32 block border border-t-0 border-l-0 border-r-0' />
            )}
          </div>
          <p
            className={`${valueClasses || 'mb-4 mt-0 text-4xl font-bold pt-2 pb-2'}  `}
          >
            {typeof value === 'string'
            && (value.includes('%') || value.includes('.'))
              ? value
              : commonUtils.getToLocaleStringValue(value)}
          </p>
        </div>
      )}
    </div>
  </div>
);

export default TopScoreBox;
