import React, { useContext } from 'react';
import { Base, Select, toast } from 'src/components';
import FilterByDate from 'src/containers/UserProfile/FilterView/FilterByDate';
import { updateData } from 'src/pagesDashboard/PulseQuestionResults/context/actions';
import { PulseQuestionResultsContext } from 'src/pagesDashboard/PulseQuestionResults/context/PulseQuestionResultsProvider';
import STYLE from 'src/constants/style';

const TopFilters = ({ setShowModal }) => {
  const { context, dispatch } = useContext(PulseQuestionResultsContext);

  const setRange = (value, start, end) => {
    if (value === 'custom') {
      return dispatch(
        updateData({
          ...context,
          range: { value, start, end }
        })
      );
    }

    if (value) {
      dispatch(
        updateData({
          ...context,
          range: { value, start, end: undefined }
        })
      );
    }
  };

  return (
    <Base classes={STYLE.CONTAINER_BLUE}>
      <div className='w-full flex justify-between'>
        <button
          type='button'
          className='mb-1 text-purple underline pl-2 text-base my-auto'
          onClick={() => setShowModal(true)}
        >
          View participation stats
        </button>

        <div className='inline-block'>
          <FilterByDate
            dateRangeSelected={context.range}
            onSelectDateRange={setRange}
          />
        </div>
      </div>
    </Base>
  );
};

export default TopFilters;
