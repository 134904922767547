import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Button,
  Base,
  Select,
  toast,
  appUtils,
  PickDate
} from 'src/components';
import { useTree } from 'src/queries/tree';
import {
  useCompany,
  QUERY_KEYS as COMPANY_QUERY_KEYS
} from 'src/queries/company';
import commonTreeUtils from 'common/commonTreeUtils';
import commonQuestions from 'common/commonQuestions';
import COMMON_QUESTION_CONSTANTS from 'common/commonQuestionConstants';
import CategoryOption from 'src/containers/UserProfile/TopScores/CategoryOption';
import commonReviewUtils from 'common/commonReviewUtils';
import STYLE from 'src/constants/style';
import { useAccount, useAccounts } from 'src/queries/account';
import COMMON_CONSTANTS from 'common/commonConstants';
import {
  sendManualReview as useSendManualReview,
  QUERY_KEYS as REVIEWS_QUERY_KEYS
} from 'src/queries/reviews';
import { requestFeedback as useRequestFeedback } from 'src/queries/feedback';
import commonDateUtils from 'common/commonDateUtils';
import utils from 'src/containers/Reviews/utils/utils';
import { newQuestionQuery } from 'src/queries/questions';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { get } from 'lodash';
import MobileSelectRequestFeedback from 'src/pagesDashboard/SelectFeedback/mobile/SelectRequestFeedback';

const {
  USER_STATE, DIRECT_REVIEW_PERMISSIONS, ACCESS, GET_ACCOUNT_SPEC_OPS
} = COMMON_CONSTANTS;

const PAGE_ROUTES = {
  FEEDBACK: 'feedback',
  REVIEW: 'review'
};

const SelectRequestFeedback = ({ type = PAGE_ROUTES.FEEDBACK }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const revieweeId = searchParams.get('revieweeId');
  const { textAlternativeOnRequestFeedbackPage } = useFlags();

  const isFeedback = type === PAGE_ROUTES.FEEDBACK;
  const isReview = type === PAGE_ROUTES.REVIEW;

  const {
    mutateAsync: sendManualReview,
    isLoading: isLoadingSendManualReview
  } = useSendManualReview();
  const { mutateAsync: requestFeedback, isLoading: isLoadingRequestFeedback } = useRequestFeedback();
  const { mutateAsync: addQuestion, isLoading: isLoadingAddQuestion } = newQuestionQuery();

  const {
    watch,
    control,
    resetField,
    formState: { isDirty },
    reset,
    setValue
  } = useForm({
    defaultValues: {
      selectedReviewee: null,
      selectedReviewer: null,
      selectedRole: null,
      scheduledDate: null,
      scheduledTime: null,
      feedbackText: ''
    }
  });

  const {
    data: defaultReviewee,
    isFetching: isFetchingDefaultReviewee,
    isError: isErrorDefaultReviewee
  } = useAccount(
    revieweeId,
    {
      projection: ['name']
    },
    { enabled: Boolean(revieweeId) }
  );

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany,
    refetch: refetchCompany,
    isRefetching: isRefetchingCompany
  } = useCompany({
    keepPreviousData: true,
    isSelectFeedback: true
  });

  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const isInitalFetchingCompany = isFetchingCompany && !isRefetchingCompany;
  const isFetching = isInitalFetchingCompany || isFetchingTree || isFetchingDefaultReviewee;
  const isError = isErrorCompany || isErrorTree || isErrorDefaultReviewee;
  const isReady = company && company.id && tree && tree.id && !isFetching && !isError;

  const cleanupRef = useRef(() => {});
  useEffect(() => () => cleanupRef.current(), []);

  const [isCheckedQuestionsEmpty, setIsCheckedQuestionsEmpty] = useState(true);
  const [checkedQuestions, setCheckedQuestions] = useState([]);

  const loggedUser = appUtils.getLoggedUser();
  const loggedUserId = appUtils.getLoggedUserId();
  const isAdmin = loggedUser.access === ACCESS.ADMIN;
  const isManager = loggedUser.access === ACCESS.MANAGER;
  const isBasic = loggedUser.access === ACCESS.BASIC;
  const accountIdsBelowLoggedUser = commonTreeUtils.getIdsUnderNode(
    tree,
    loggedUserId
  );
  const canReviewAnyone = get(company, 'settings.directReviewPermissions')
      === DIRECT_REVIEW_PERMISSIONS.OPEN || isFeedback;
  const canRequestReviewForDefaultReviewee = canReviewAnyone
    || revieweeId === loggedUserId
    || accountIdsBelowLoggedUser.includes(revieweeId);

  const [revieweeSearchText, setRevieweeSearchText] = useState('');
  const { data: revieweeAccounts, isFetching: isFetchingRevieweeAccounts } = useAccounts(
    {
      ...(isManager && {
        ids: [loggedUserId],
        specs: [
          {
            op: GET_ACCOUNT_SPEC_OPS.BELOW_ACCOUNTID,
            accountId: loggedUserId
          }
        ]
      }),
      status: [USER_STATE.ACTIVE, USER_STATE.PASSIVE]
    },
    {
      search: {
        enabled: true,
        field: 'name',
        value: revieweeSearchText
      },
      page: { size: 20 },
      projection: ['name']
    },
    { enabled: !isBasic }
  );

  const formValues = watch();

  const selectedReviewee = get(formValues.selectedReviewee, 'data');
  const selectedRevieweeId = get(selectedReviewee, '_id');
  const isAdminRequestingReviewForThemselves = (isAdmin
      || (isManager && accountIdsBelowLoggedUser.includes(selectedRevieweeId)))
    && isReview
    && selectedRevieweeId === loggedUserId;

  const shouldFetchRevieweeReviewers = get(company, 'settings.directReviewPermissions')
      === DIRECT_REVIEW_PERMISSIONS.PER_REVIEW_RELATIONSHIPS
    && !isAdminRequestingReviewForThemselves;

  const [reviewerSearchText, setReviewerSearchText] = useState('');
  const { data: reviewerAccounts, isFetching: isFetchingReviewerAccounts } = useAccounts(
    {
      ...(isFeedback
        ? {
          status: [USER_STATE.ACTIVE, USER_STATE.PASSIVE]
        }
        : {}),
      ...(isReview
        ? {
          status: [USER_STATE.ACTIVE],
          ...(shouldFetchRevieweeReviewers
            ? { reviews: [selectedRevieweeId] }
            : {})
        }
        : {})
    },
    {
      search: {
        enabled: true,
        field: 'name',
        value: reviewerSearchText
      },
      page: { size: 100 }
    }
  );

  const shouldSetDefaultReviewee = Boolean(
    revieweeId && canRequestReviewForDefaultReviewee
  );

  useEffect(() => {
    if (!isReady) return;

    if (!isDirty) {
      const defaultValues = {
        scheduledDate: new Date(),
        ...(shouldSetDefaultReviewee
          ? {
            selectedReviewee: {
              value: get(defaultReviewee, '_id'),
              label: get(defaultReviewee, 'name'),
              data: defaultReviewee
            }
          }
          : {})
      };
      reset(defaultValues);
    }
  }, [isReady]);

  useEffect(() => {
    if (shouldSetDefaultReviewee) {
      if (isFetchingDefaultReviewee) return;
      resetField('selectedReviewee');
      resetField('selectedReviewer');
    }
  }, [shouldSetDefaultReviewee, isFetchingDefaultReviewee]);

  const selectedReviewer = get(watch('selectedReviewer'), 'data');

  useEffect(() => {
    resetField('selectedRole');
    setCheckedQuestions([]);
    setIsCheckedQuestionsEmpty(true);
    resetField('scheduledDate');
    resetField('scheduledTime');

    if (
      !canReviewAnyone
      && !isAdmin
      && type === PAGE_ROUTES.REVIEW
      && selectedReviewer
    ) {
      const reviewRelationship = selectedReviewer.reviews.find(
        (relationship) => relationship.userId === selectedReviewee._id
      );
      if (!reviewRelationship) {
        resetField('selectedReviewer');
        resetField('feedbackText');
        toast.error(
          selectedReviewee._id === loggedUserId
            ? 'The selected team member can not give you Feedback for a Performance Category'
            : `The selected team member can not give ${selectedReviewee.name} Feedback for a Performance Category`,
          2500
        );
      }
    }
  }, [type]);

  if (!isReady) {
    return null;
  }

  const queryClient = appUtils.getQueryClient();
  const COMPANY_QUESTIONS = company.questions;
  const unixNow = commonDateUtils.getUnixDateNow();

  const revieweeOptions = [];
  revieweeAccounts.forEach((reviewer) => revieweeOptions.push({
    value: reviewer._id,
    label: reviewer.name,
    data: reviewer
  }));

  const reviewerOptions = [];
  if (selectedReviewee) {
    reviewerAccounts.forEach((reviewee) => reviewerOptions.push({
      value: reviewee._id,
      label: reviewee.name,
      data: reviewee
    }));
  }

  const roleOptions = [];
  if (selectedReviewer) {
    let roleIds = [];

    if (
      canReviewAnyone
      || isAdmin
      || (isManager && accountIdsBelowLoggedUser.includes(selectedReviewee._id))
    ) {
      const revieweeNode = commonTreeUtils.findNodeById(
        tree,
        selectedReviewee._id
      );
      roleIds = revieweeNode.roles;
    } else {
      const reviewRelationship = selectedReviewer.reviews.find(
        (relationship) => relationship.userId === selectedReviewee._id
      );
      if (reviewRelationship) {
        roleIds = reviewRelationship.roles;
      }
    }
    roleIds.forEach((roleId) => {
      const role = commonQuestions.getRoleById(roleId, COMPANY_QUESTIONS);
      roleOptions.push({
        id: roleId,
        label: role.label
      });
    });
  }

  const selectedRole = watch('selectedRole');
  const categoryOptions = [];
  if (selectedRole) {
    const roleCategories = commonQuestions.getRoleCategories(
      [selectedRole.id],
      COMPANY_QUESTIONS
    );
    roleCategories.forEach((category) => {
      categoryOptions.push({
        ...category,
        questionObjects: category.questions
          .filter(
            (qid) => commonQuestions.getQuestion(qid, COMPANY_QUESTIONS.QUESTIONS)
              .status === COMMON_QUESTION_CONSTANTS.STATUS.ACTIVE
          )
          .map((qid) => commonReviewUtils.getDirectReviewQuestion({
            name: selectedReviewee.name,
            frequency: company.emailFrequency,
            isSelfReview: selectedReviewee._id === selectedReviewer._id,
            roleId: selectedRole.id,
            questionData: {
              questionId: qid,
              categoryId: category.id
            },
            COMPANY_QUESTIONS
          }))
      });
    });
  }

  const feedbackText = watch('feedbackText');

  const scheduledDate = watch('scheduledDate');
  const scheduledTime = watch('scheduledTime');
  const isToday = scheduledDate && commonDateUtils.isDateToday(scheduledDate);

  const requestReview = async () => {
    if (!company.active) {
      return toast.error('Company is not active.');
    }

    toast.show('Sending request...');

    if (isReview) {
      const requestCount = checkedQuestions.length;

      const response = await sendManualReview({
        revieweeId: selectedReviewee._id,
        reviewerId: selectedReviewer._id,
        roleId: selectedRole.id,
        reviewsRequestData: checkedQuestions,
        schedule: {
          scheduledDate,
          scheduleTime: isToday ? 'immediately' : scheduledTime
        }
      });

      if (!response.success) {
        return toast.error(
          'We encountered an issue and have been notified! Please try again later'
        );
      }

      if (loggedUserId === selectedReviewer._id) queryClient.invalidateQueries([REVIEWS_QUERY_KEYS.REVIEWS_DUE]);

      if (isToday) {
        toast.show(
          `${requestCount} review request(s) have been sent to ${selectedReviewer.name}`
        );
      } else {
        toast.show(
          `${requestCount} review request(s) will be sent to ${selectedReviewer.name} at ${scheduledTime.label} on ${commonDateUtils.dateToMonthDayYearFormat(
            scheduledDate
          )}`
        );
      }
    }

    if (isFeedback) {
      const response = await requestFeedback({
        revieweeId: selectedReviewee._id,
        reviewerId: selectedReviewer._id,
        text: feedbackText
      });

      if (!response.success) {
        return toast.error(
          'We encountered an issue and have been notified! Please try again later'
        );
      }
      toast.show(`Feedback request has been sent to ${selectedReviewer.name}`);
    }

    return navigate(appUtils.getHomeRoute());
  };

  const isSelf = selectedReviewee
    && selectedReviewer
    && selectedReviewee._id === selectedReviewer._id;
  const requestCount = checkedQuestions.length;

  const getIsSubmitDisabled = () => {
    if (isFeedback) {
      const areDataFieldsEmpty = !selectedReviewee || !selectedReviewer || !feedbackText;
      return areDataFieldsEmpty || isLoadingRequestFeedback;
    }

    if (isReview) {
      const areDataFieldsEmpty = !selectedReviewee || !selectedReviewer || !selectedRole;
      const areSchedulingFieldsEmpty = !scheduledDate || (!isToday && !scheduledTime);
      return (
        areDataFieldsEmpty
        || areSchedulingFieldsEmpty
        || isCheckedQuestionsEmpty
        || isLoadingSendManualReview
        || isLoadingAddQuestion
      );
    }
  };

  const titleText = textAlternativeOnRequestFeedbackPage
    ? 'Select questions to base your review(s) on – please choose at least two areas of strength and at least two areas of development'
    : 'Select questions to base your review request(s) on';

  const revieweeHasNoReviewers = isReview
    && shouldFetchRevieweeReviewers
    && !reviewerAccounts.length
    && !isFetchingReviewerAccounts
    && !reviewerSearchText;
  const renderWarning = () => (
    <div className='flex items-center bg-red h-6 px-2 mr-2 rounded-sm mb-2 text-white'>
      {selectedReviewee._id === loggedUserId
        ? 'No team members review you'
        : `No team members review ${selectedReviewee.name}`}
    </div>
  );

  const isMobile = appUtils.getMobileSize();

  const loadingProps = {
    isLoadingAddQuestion,
    isRefetchingCompany,
    isLoadingSendManualReview,
    isLoadingRequestFeedback
  };

  const reviewerProps = {
    reviewerOptions,
    selectedReviewer,
    setReviewerSearchText,
    isFetchingReviewerAccounts
  };

  const revieweeProps = {
    revieweeOptions,
    selectedReviewee,
    setRevieweeSearchText,
    isFetchingRevieweeAccounts,
    revieweeHasNoReviewers
  };

  if (isMobile) {
    return (
      <MobileSelectRequestFeedback
        revieweeId={revieweeId}
        isReview={isReview}
        isFeedback={isFeedback}
        control={control}
        resetField={resetField}
        setValue={setValue}
        reviewerProps={reviewerProps}
        revieweeProps={revieweeProps}
        isBasic={isBasic}
        isSubmitDisabled={getIsSubmitDisabled()}
        requestReview={requestReview}
        loadingProps={loadingProps}
      />
    );
  }

  return (
    <Base classes={STYLE.STANDARD}>
      <div className='min-h-80 bg-white'>
        <div className='mb-4'>
          <span className='font-semibold text-black'>
            {isReview ? 'Reviewee' : null}
            {isFeedback ? 'Reviewee' : null}
          </span>
          {revieweeHasNoReviewers ? renderWarning() : null}
          <Controller
            name='selectedReviewee'
            control={control}
            rules={{ required: true }}
            render={({ field, field: { onChange } }) => {
              let title = '';

              if (field.value) title = field.value.label;
              else {
                if (isFeedback) title = 'Select a Recipient';
                if (isReview) title = 'Select a Reviewee';
              }

              return (
                <Select
                  {...field}
                  placeholder='selectedReviewee'
                  classes='w-full'
                  options={revieweeOptions}
                  title={title}
                  onChange={(revieweeOption) => {
                    if (revieweeId) {
                      searchParams.delete('revieweeId');
                      setSearchParams(searchParams);
                    }
                    resetField('selectedReviewer');
                    resetField('selectedRole');
                    setCheckedQuestions([]);
                    setIsCheckedQuestionsEmpty(true);
                    resetField('scheduledDate');
                    resetField('scheduledTime');
                    onChange(revieweeOption);
                  }}
                  loading={isFetchingRevieweeAccounts}
                  showSearch
                  onSearch={(value) => setRevieweeSearchText(value)}
                  onDropdownClose={() => setRevieweeSearchText('')}
                  disabled={isBasic}
                />
              );
            }}
          />
        </div>
        {selectedReviewee ? (
          <div>
            <div className='mb-4'>
              <span className='font-semibold text-black'>Reviewer</span>
              <Controller
                name='selectedReviewer'
                control={control}
                rules={{ required: true }}
                render={({ field, field: { onChange } }) => {
                  const title = field.value
                    ? field.value.label
                    : 'Select a Reviewer';
                  return (
                    <Select
                      {...field}
                      placeholder='selectedReviewer'
                      classes='w-full'
                      disabled={revieweeHasNoReviewers}
                      options={reviewerOptions}
                      title={title}
                      onChange={(reviewerOption) => {
                        resetField('selectedRole');
                        setCheckedQuestions([]);
                        setIsCheckedQuestionsEmpty(true);
                        resetField('scheduledDate');
                        resetField('scheduledTime');
                        onChange(reviewerOption);
                      }}
                      loading={isFetchingReviewerAccounts}
                      showSearch
                      onSearch={(value) => setReviewerSearchText(value)}
                      onDropdownClose={() => setReviewerSearchText('')}
                    />
                  );
                }}
              />
            </div>
            {selectedReviewer && isReview ? (
              <div>
                {roleOptions.length ? (
                  <div className='mb-4'>
                    <span className='font-semibold text-black'>Role</span>
                    <Controller
                      name='selectedRole'
                      control={control}
                      rules={{ required: true }}
                      render={({ field, field: { onChange } }) => {
                        const title = field.value
                          ? field.value.label
                          : 'Select a Role';
                        return (
                          <Select
                            {...field}
                            placeholder='selectedRole'
                            classes='w-full'
                            options={roleOptions}
                            title={title}
                            onChange={(roleOption) => {
                              setCheckedQuestions([]);
                              setIsCheckedQuestionsEmpty(true);
                              resetField('scheduledDate');
                              resetField('scheduledTime');
                              onChange(roleOption);
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                ) : (
                  <div className='h-80 flex items-center'>
                    <span className='font-base'>
                      {selectedReviewer.name}
                      {' '}
                      does not review
                      {' '}
                      {isSelf ? 'themselves' : selectedReviewer.name}
                      {' '}
                      by any
                      roles. Please adjust review relationships if necessary
                    </span>
                  </div>
                )}
                {selectedRole ? (
                  <div className='mb-4'>
                    {checkedQuestions.length ? (
                      <div className='mb-4'>
                        <span className='font-semibold text-black'>
                          Scheduling
                        </span>
                        <Controller
                          name='scheduledDate'
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => {
                            const date = field.value
                              ? new Date(field.value)
                              : new Date();
                            return (
                              <PickDate
                                classes='float-none w-full mb-2 text-base text-black'
                                minDate={unixNow}
                                inputClasses='w-full'
                                placeholder='Select Date'
                                date={date}
                                onSelect={(selectedDate) => {
                                  resetField('scheduledTime');
                                  field.onChange(selectedDate);
                                }}
                                {...field}
                              />
                            );
                          }}
                        />
                        <Controller
                          name='scheduledTime'
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => {
                            let title = 'Select Time';
                            if (isToday) {
                              title = 'Send Request Immediately';
                            } else if (field.value) {
                              const selectedOption = utils.TIME_OPTIONS.find(
                                (option) => option.id === field.value.id
                              );
                              title = selectedOption.label;
                            }
                            return (
                              <Select
                                title={title}
                                classes='w-full'
                                options={utils.TIME_OPTIONS}
                                disabled={!scheduledDate || isToday}
                                {...field}
                              />
                            );
                          }}
                        />
                      </div>
                    ) : null}
                    <span className='font-semibold text-black'>
                      {titleText}
                    </span>
                    {categoryOptions.map((category) => (
                      <CategoryOption
                        key={category.id}
                        category={category}
                        checkedQuestions={checkedQuestions}
                        setCheckedQuestions={setCheckedQuestions}
                        setIsButtonDisabled={setIsCheckedQuestionsEmpty}
                        showCustomQuestion
                        isLoadingSaveCustomQuestion={
                          isLoadingAddQuestion || isRefetchingCompany
                        }
                        onSaveCustomQuestion={async (questionObject) => {
                          const { success, questionId } = await addQuestion({
                            questionObject,
                            categoryId: category.id
                          });

                          if (!success) return toast.error('Failed to add question');

                          cleanupRef.current = () => {
                            queryClient.invalidateQueries(
                              COMPANY_QUERY_KEYS.COMPANY
                            );
                            queryClient.invalidateQueries(
                              COMPANY_QUERY_KEYS.COMPANY_QUESTIONS
                            );
                          };

                          refetchCompany();
                          setCheckedQuestions((prev) => [
                            ...prev,
                            {
                              categoryId: category.id,
                              questionId
                            }
                          ]);
                          setIsCheckedQuestionsEmpty(false);
                        }}
                      />
                    ))}
                  </div>
                ) : null}
              </div>
            ) : null}
            {selectedReviewer && isFeedback ? (
              <div className='mb-4'>
                <span className='font-bold text-sm text-black'>
                  What would you like to receive feedback about (ex. specific
                  project, campaign, event)
                </span>
                <Controller
                  name='feedbackText'
                  control={control}
                  rules={{ required: true }}
                  render={({ field, field: { onChange } }) => (
                    <textarea
                      {...field}
                      className='w-full h-40 border border-gray-300 rounded-md p-2 mt-2'
                      placeholder='Add your feedback prompt here'
                      onChange={(e) => onChange(e.target.value)}
                    />
                  )}
                />
              </div>
            ) : null}
          </div>
        ) : null}
        <div className='flex mt-6 mb-4'>
          <Button
            onClick={requestReview}
            disabled={getIsSubmitDisabled()}
            variant='black'
            classes='!border-0 !rounded-lg'
          >
            {isReview
              ? `Request Feedback ${requestCount > 1 ? `(${requestCount})` : ''}`
              : 'Request Feedback'}
          </Button>
        </div>
      </div>
    </Base>
  );
};

export default SelectRequestFeedback;
