import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { isEmpty, get } from 'lodash';
import { Button, WorkStoryLogo, toast } from 'src/components';
import OnboardingBase from 'src/pagesDashboard/Onboarding/OnboardingBase';
import { useInviteToken } from 'src/queries/user';
import appUtils from 'src/components/appUtils';
import api from 'src/services/api';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useParams, useNavigate } from 'react-router-dom';

const { USER_ERRORS, USER_STATE } = COMMON_CONSTANTS;

const OnboardingSetup = () => {
  const { inviteToken } = useParams();
  const navigate = useNavigate();
  const {
    data: account,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useInviteToken(inviteToken);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty }
  } = useForm({
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      password: ''
    }
  });

  const [isLoading, setIsLoading] = useState(false);

  const isFetching = isFetchingAccount;
  const isError = isErrorAccount;
  const isReady = !isFetching && !isError;

  useEffect(() => {
    if (!isReady) return;

    if (!isDirty) {
      const defaultValues = {
        email: account.email || '',
        firstName: account.firstName || '',
        lastName: account.lastName || '',
        password: ''
      };
      reset(defaultValues);
    }
  }, [isReady]);

  if (!isReady) return null;

  if (isEmpty(account)) {
    return (
      <OnboardingBase>
        <div className='text-center py-5'>
          <WorkStoryLogo />
          <div className='text-center mt-12 text-lg font-bold'>
            Invite has expired. Please check your email for another invite!
          </div>
        </div>
      </OnboardingBase>
    );
  }

  if (
    account.status === USER_STATE.UNASSIGNED
    || !get(account, 'permissions.canLogin')
  ) {
    return (
      <OnboardingBase>
        <div className='text-center py-5'>
          <WorkStoryLogo />
          <div className='text-center mt-12 text-lg font-bold'>
            Your account is not allowed to login. Please contact your manager.
          </div>
        </div>
      </OnboardingBase>
    );
  }

  const onSubmit = async (data) => {
    try {
      const { password, firstName, lastName } = data;

      setIsLoading(true);
      const { user } = await api.post('/user/confirmInviteAccount', {
        inviteToken,
        userEmail: account.email,
        newPassword: password,
        firstName,
        lastName
      });
      appUtils.saveLoggedUser(user);
      setIsLoading(false);

      return navigate('/dashboard/onboarding/video');
    } catch (error) {
      toast.error(error);
      if (error === USER_ERRORS.UNAVAILABLE_ACCOUNT) {
        setTimeout(() => window.open(appUtils.getBaseUrl(), '_self'), 2000);
      }
    }
  };

  const getIsButtonDisabled = () => {
    if (isLoading) return true;

    const { password, firstName, lastName } = watch();

    if (!password || !firstName || !lastName) return true;

    if (password.length < 8) return true;
  };

  return (
    <OnboardingBase>
      <div className='w-full text-left'>
        <p className='text-xl font-bold m-0 text-black'>
          Get Started with Workstory
        </p>
        <p className='m-0'>Follow the steps to get set up.</p>
      </div>
      <form
        className='flex flex-col items-center gap-4 w-full'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='w-full'>
          <label htmlFor='email' className='my-2 inline-block font-bold'>
            Email
          </label>
          <input
            disabled
            value={account.email}
            placeholder='Your Email'
            className='bg-white rounded-lg border border-slate-300 disabled:bg-tertiary-gray h-11 w-[100%]'
            {...register('email', { required: true })}
          />
          <label htmlFor='name' className='my-2 inline-block font-bold'>
            First Name
          </label>
          <input
            placeholder='Your First Name'
            className='bg-white rounded-lg border border-slate-300 h-11 w-[100%]'
            {...register('firstName')}
          />

          <label htmlFor='name' className='my-2 inline-block font-bold'>
            Last Name
          </label>
          <input
            placeholder='Your Last Name'
            className='bg-white rounded-lg border border-slate-300 h-11 w-[100%]'
            {...register('lastName')}
          />

          <label htmlFor='password' className='my-2 inline-block font-bold'>
            Password
          </label>
          <input
            type='password'
            placeholder='Create a password'
            className='bg-white rounded-lg border border-slate-300 h-11 w-[100%]'
            {...register('password')}
          />
          <p className='text-sm m-0'>Must be at least 8 characters</p>
        </div>

        <Button
          variant='purple'
          type='submit'
          disabled={getIsButtonDisabled()}
          classes='!rounded-md !w-3/5 !h-11 !mx-auto'
        >
          {isLoading ? 'Loading...' : 'Continue'}
        </Button>
      </form>
    </OnboardingBase>
  );
};

export default OnboardingSetup;
