import React from 'react';
import Spinner from '../../assets/spinner.svg';
import './SectionBox.scss';

const SECTION_TYPES = {
  FRONT: 'front',
  DASH: 'dash',
  NOTIFICATION: 'notification',
  TRANSPARENT: 'transparent'
};

// loading spinner props: loading (true or false) and loadingPosition ('bottom' or 'center')
// background: blue
// stripeBackground: blue
// width: full or false by default
// type: front / dash / notification / transparent
// minHeight: '120px'
// height: primary / ..
const SectionBox = ({
  id,
  loading,
  loadingPosition,
  height,
  minHeight,
  sectionType,
  width,
  background,
  stripeBackground,
  classes,
  innerClasses,
  children,
  className,
  ...rest
}) => {
  background = background || null;
  stripeBackground = stripeBackground || null;
  width = width || false;
  sectionType = sectionType || SECTION_TYPES.FRONT;
  minHeight = minHeight || {};

  let mainClass = 'section-box';
  if (width === 'full') {
    mainClass += ' section-box-full-width';
  }

  mainClass = background ? `${mainClass} section-box-${background}` : mainClass;
  mainClass = classes ? `${mainClass} ${classes}` : mainClass;
  mainClass = height ? `${mainClass} section-max-height-${height}` : mainClass;

  let innerClass = 'section-box-inner';
  innerClass = innerClasses ? `${innerClass} ${innerClasses}` : innerClass;

  innerClass = sectionType === SECTION_TYPES.DASH
    ? `${innerClass} section-box-inner-dash-section`
    : innerClass;
  mainClass = sectionType === SECTION_TYPES.DASH
    ? `${mainClass} section-box-dash-section`
    : mainClass;

  innerClass = sectionType === SECTION_TYPES.TRANSPARENT
    ? `${innerClass} section-box-inner-transparent-section`
    : innerClass;
  mainClass = sectionType === SECTION_TYPES.TRANSPARENT
    ? `${mainClass} section-box-transparent-section`
    : mainClass;

  innerClass = sectionType === SECTION_TYPES.NOTIFICATION
    ? `${innerClass} section-box-inner-notification-section`
    : innerClass;
  mainClass = sectionType === SECTION_TYPES.NOTIFICATION
    ? `${mainClass} section-box-notification-section`
    : mainClass;

  return (
    <div
      style={{ minHeight }}
      {...(id ? { id } : {})}
      className={className || mainClass}
    >
      {loading ? (
        <>
          <div
            className={`sectionbox-loading-spinner ${
              loadingPosition
                ? `sectionbox-loading-spinner-${loadingPosition}`
                : null
            }`}
          >
            <Spinner />
          </div>
          <div className='sectionbox-loading-content'>
            {stripeBackground ? (
              <div className='color-back'>
                <div className='color-back-body' />
                <div className='color-back-bottom' />
              </div>
            ) : null}
            <div className={innerClass}>{children}</div>
          </div>
        </>
      ) : null}

      {!loading ? (
        <>
          {stripeBackground ? (
            <div className='color-back'>
              <div className='color-back-body' />
              <div className='color-back-bottom' />
            </div>
          ) : null}
          <div className={innerClass}>{children}</div>
        </>
      ) : null}
    </div>
  );
};

export default SectionBox;
