import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import {
  Button, toast, Base, Modal, Select
} from 'src/components';
import { useCompany, updateCompanyQuery } from 'src/queries/company';
import commonDateUtils from 'common/commonDateUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import appUtils from 'src/components/appUtils';
import STYLE from 'src/constants/style';
import Quill from 'src/components/Quill/Quill';
import { useParams } from 'react-router-dom';

const { REPORT_TEMPLATE_TYPES_LABEL } = COMMON_CONSTANTS;

const ConfirmDeleteModal = ({
  data, isLoading, close, deleteFn
}) => (
  <Modal innerClasses='w-34rem' classes='pt-40' variant='custom' close={close}>
    <Base variant='transparent' loading={isLoading}>
      <h5>
        {' '}
        Are you sure you want to delete report template
        {' '}
        <strong>{data.name}</strong>
        ?
      </h5>

      <div className='mt-20 -mb-1'>
        <div className='w-1/2 inline-block text-left'>
          <button
            type='button'
            className='text-black text-lg px-4 py-2 mr-0 font-bold'
            onClick={close}
          >
            Cancel
          </button>
        </div>
        <div className='w-1/2 inline-block text-right'>
          <Button
            variant='custom'
            classes='bg-red text-white px-4 py-2 text-lg rounded-sm font-bold answer transition-colors duration-300'
            disabled={isLoading}
            onClick={() => deleteFn(data.id)}
          >
            Delete template
          </Button>
        </div>
      </div>
    </Base>
  </Modal>
);

const ReportTemplates = () => {
  const { templateId } = useParams();
  const isEditing = templateId !== undefined;
  const queryClient = useQueryClient();
  const loggedUserId = appUtils.getLoggedUserId();

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError;

  const { mutateAsync: updateCompany, isLoading: updateCompanyLoading } = updateCompanyQuery();

  const [showDeleteConfirmationData, setShowDeleteConfirmationData] = useState(false);

  const {
    handleSubmit,
    control,
    register,
    getValues,
    watch,
    formState: { errors, isDirty },
    reset
  } = useForm({
    defaultValues: {
      reportTemplateName: '',
      templateType: null,
      templateContent: null
    }
  });

  useEffect(() => {
    if (company.templates && company.templates.length && templateId) {
      const currentTemplate = templateId
        ? company.templates.find((t) => t.id === templateId)
        : null;

      reset({
        reportTemplateName: currentTemplate.name,
        templateType: currentTemplate.type,
        templateContent: currentTemplate.content
      });
    }
  }, [company.templates, reset]);

  if (!isReady) return null;

  const selectTemplateOptions = Object.keys(REPORT_TEMPLATE_TYPES_LABEL).map(
    (key) => ({ id: key, label: REPORT_TEMPLATE_TYPES_LABEL[key] })
  );

  const closePage = () => {
    reset();
    window.history.back();
  };

  const createNewTemplate = async () => {
    const formValues = getValues();
    toast.show('Saving template...');

    const newTemplate = {
      type: formValues.templateType,
      name: formValues.reportTemplateName,
      content: formValues.templateContent,
      author: loggedUserId
    };
    const res = await updateCompany({
      templates: [...company.templates, newTemplate]
    });
    if (!res) {
      toast.show('An error occured, please try again later');
      return false;
    }
    toast.show('Saved!');
    return true;
  };

  const updateTemplate = async () => {
    const formValues = getValues();
    toast.show('Updating template...');

    const updatedTemplate = {
      type: formValues.templateType,
      name: formValues.reportTemplateName,
      content: formValues.templateContent,
      updatedAt: commonDateUtils.getUnixDateNow()
    };

    const templates = company.templates.map((t) => {
      if (t.id === templateId) {
        return {
          ...t,
          ...updatedTemplate
        };
      }
      return t;
    });

    const res = await updateCompany({
      templates
    });

    if (!res) {
      toast.show('An error occured, please try again later');
      return false;
    }
    toast.show('Updated!');
    return true;
  };

  const openDeleteTemplateModal = () => {
    const templateData = company.templates.find((t) => t.id === templateId);
    setShowDeleteConfirmationData(templateData);
  };

  const closeDeleteTemplateModal = () => setShowDeleteConfirmationData(null);

  const deleteReportTemplate = async () => {
    const templates = company.templates.filter((t) => t.id !== templateId);
    toast.show('Deleting template...');
    const res = await updateCompany({
      templates
    });
    if (!res) {
      toast.show('An error occured, please try again later');
      return false;
    }
    toast.show('Deleted!');
    queryClient.invalidateQueries('company');
    closeDeleteTemplateModal();
    closePage();
    return true;
  };

  const save = async () => {
    if (!isEditing) await createNewTemplate();
    else await updateTemplate();

    queryClient.invalidateQueries('company');
    reset();
    closePage();
  };

  const templateContentValue = watch('templateContent');
  const templateTypeValue = watch('templateType');
  const isSaveDisabled = updateCompanyLoading || !isDirty || !templateTypeValue;

  return (
    <Base classes={STYLE.BASE} loading={isFetchingCompany}>
      {showDeleteConfirmationData ? (
        <ConfirmDeleteModal
          data={showDeleteConfirmationData}
          isLoading={updateCompanyLoading}
          close={closeDeleteTemplateModal}
          deleteFn={deleteReportTemplate}
        />
      ) : null}
      <div className='bg-white px-6 py-2'>
        <p className='text-xl mt-4 mb-3 font-bold'>
          {isEditing ? 'Edit Template' : 'Create Template'}
        </p>

        <div className='flex-col items-start w-3/4'>
          <div className='flex mb-2'>
            <p className='text-lg mb-0 flex items-center w-1/5'>
              Template name:
            </p>
            <input
              type='input'
              placeholder='Template name'
              className='text-lg focus:outline-none rounded bg-white text-black w-60'
              {...register('reportTemplateName', {
                required: 'Missing template name!'
              })}
            />
            <div className='text-red whitespace-nowrap ml-2 flex items-center'>
              {errors?.reportTemplateName?.message}
            </div>
          </div>
          <div className='flex mt-2'>
            <p className='text-lg mb-0 flex items-center w-1/5'>Type:</p>
            <Controller
              name='templateType'
              control={control}
              rules={{
                required: 'Please select template type'
              }}
              render={({ field, field: { onChange } }) => (
                <Select
                  {...field}
                  classes='w-60'
                  options={selectTemplateOptions}
                  title={
                    templateTypeValue
                      ? REPORT_TEMPLATE_TYPES_LABEL[templateTypeValue]
                      : 'Select'
                  }
                  onChange={(option) => onChange(option.id)}
                />
              )}
            />
            <div className='text-red whitespace-nowrap ml-2 flex items-center'>
              {errors?.reportType?.message}
            </div>
          </div>
        </div>

        <div
          className='flex flex-col gap-8 w-full text-center text-black
        transition-colors duration-300 rounded my-5 h-35rem'
        >
          <Controller
            name='templateContent'
            control={control}
            rules={{
              required: 'Please enter template content'
            }}
            render={({ field }) => (
              <Quill
                {...field}
                placeholder='Write template content'
                value={templateContentValue}
                onChange={(text) => {
                  field.onChange(text);
                }}
                className='h-30rem'
              />
            )}
          />
          <div className='text-red mt-4 text-left'>
            {errors?.templateContent?.message}
          </div>
          <div className='flex'>
            {isEditing ? (
              <div className='w-1/2 text-left'>
                <Button
                  variant='custom'
                  classes='bg-red text-white px-4 py-2 rounded-md'
                  disabled={updateCompanyLoading}
                  onClick={() => openDeleteTemplateModal()}
                >
                  Delete
                </Button>
              </div>
            ) : null}
            <div className='w-1/2 text-right ml-auto'>
              <Button type='button' variant='white' onClick={closePage}>
                Cancel
              </Button>
              <Button
                onClick={handleSubmit(save)}
                disabled={isSaveDisabled}
                variant='yellow'
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Base>
  );
};

export default ReportTemplates;
