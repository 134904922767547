import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  Button, Base, Radio, appUtils
} from 'src/components';
import STYLE from 'src/constants/style';

const MobileRequestFeedbackContainer = (props) => {
  const {
    children
  } = props;
  const [searchParams] = useSearchParams();
  const revieweeId = searchParams.get('revieweeId');
  const { '*': currentRoute } = useParams();

  const navigate = useNavigate();
  const loggedUser = appUtils.getLoggedUser();

  const [selectionValue, setSelectionValue] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const opts = [
    {
      title: 'Request a feedback comment',
      value: 'feedback',
      description:
        'Ask others for general feedback or prompt them to give you specific feedback'
    }/* ,
    {
      title: 'Request a review',
      value: 'review',
      description: 'Ask others to evaluate you by a specific category'
    } */
  ];

  useEffect(() => {
    const searchQ = revieweeId ? `?revieweeId=${revieweeId}` : '';

    if (selectionValue) {
      navigate(`/dashboard/request-feedback/${selectionValue}${searchQ}`);
    }
  }, [selectionValue, revieweeId]);

  /* useEffect(() => {
    if (currentRoute) {
      setSelectionValue(currentRoute);
    }
  }, [type, currentRoute]); */

  // hide request feedback type selection on mobile
  useEffect(() => {
    setIsSubmitted(true);
    navigate(`/dashboard/request-feedback/feedback`);
  }, []);

  const submitFeedbackOption = () => {
    setIsSubmitted(true);
    navigate(`/dashboard/request-feedback/${selectionValue}`);
  };

  return (
    <Base classes={isSubmitted ? STYLE.MOBILE_CONTAINER_NO_SCROLL : STYLE.MOBILE_CONTAINER}>
      {
        !isSubmitted ? (
          <div className='mt-4'>
            <div className='px-2'>
              {opts.map((option) => (
                <div className='font-semibold'>
                  <Radio
                    name='option'
                    value={selectionValue}
                    title={option.title}
                    checked={selectionValue === option.value}
                    onChange={() => setSelectionValue(option.value)}
                  />
                  <div>
                    <p className='text-dark-grey ml-9'>{option.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className='px-3 w-full flex flex-col items-center w-full fixed bottom-0 gap-3 pb-2'>
              <Button
                variant='black'
                classes='w-full'
                disabled={!selectionValue}
                onClick={() => submitFeedbackOption(true)}
              >
                Next
              </Button>
              <Button
                variant='custom'
                classes='bg-white w-full text-black font-bold text-sm px-4 pt-2 pb-2 border rounded-md border-gray-300'
                onClick={() => navigate('/dashboard/home')}
              >
                Cancel
              </Button>
            </div>
          </div>
        ) : (
          <div>{children}</div>
        )
      }
    </Base>
  );
};

export default MobileRequestFeedbackContainer;
