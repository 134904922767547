import React from 'react';
import STYLE from 'src/constants/style';
import UserInformation from 'src/containers/Settings/UserInformation/UserInformation';
import SlackIntegration from 'src/containers/Settings/SlackIntegration/SlackIntegration';
import MSTeamsIntegration from 'src/containers/Settings/MSTeams/MSTeamsIntegration';
import Communication from 'src/containers/Settings/Communication/Communication';
import GoogleIntegration from 'src/containers/Settings/GoogleIntegration/GoogleIntegration';
import WebexIntegration from 'src/containers/Settings/WebexIntegration/WebexIntegration';
import Password from 'src/containers/Settings/Password/Password';
import UserSchedule from 'src/containers/UserSchedule/UserSchedule';
import { Base } from 'src/components/index';
import HeaderV2 from 'src/containers/Header/HeaderV2';
import appUtils from 'src/components/appUtils';

const Settings = (props) => {
  const mobileSize = appUtils.getMobileSize();
  const isMobile = mobileSize;

  return isMobile ? (
    <div>
      <UserInformation />
    </div>
  ) : (
    <>
      <HeaderV2 overtitle='Profile' title='Personal Information' />
      <Base className={STYLE.BASE}>
        <UserInformation />
        <Communication />
        <SlackIntegration />
        <MSTeamsIntegration />
        <WebexIntegration />
        <GoogleIntegration />
        <UserSchedule />
        <Password />
      </Base>
    </>
  );
};

export default Settings;
