import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import api from 'src/services/api';
import commonBuilderUtils from 'common/commonBuilderUtils';
import { get, isEmpty } from 'lodash';
import qs from 'qs';
import { toast } from 'src/components/index';

export const QUERY_KEYS = {
  BUILDER_DATASET: 'builderDataset',
  BUILDER: 'builder',
  BUILDER_DASHBOARD: 'builderReports'
};

export const useBuilderDataset = () => {
  const { data, ...rest } = useQuery([QUERY_KEYS.BUILDER_DATASET], () => api.get('/builder/dataset').then((resp) => {
    if (!resp.success) {
      console.error(`GET /builder/dataset error`);
      return {
        success: false
      };
    }
    return resp;
  }));

  return {
    data: get(data, 'data', {}),
    meta: get(data, 'meta', {}),
    success: get(data, 'success'),
    ...rest
  };
};

export const useBuilderReport = (id, options = {}, queryOptions = {}) => {
  const stringified = qs.stringify({ options }, { skipNulls: true });

  const { data, ...rest } = useQuery(
    [QUERY_KEYS.BUILDER, id],
    () => api.get(`/builder/${id}?${stringified}`).then((resp) => {
      if (!resp.success) {
        console.error(`GET /builder/${id}?${stringified} error`);
        return {
          success: false
        };
      }
      return resp;
    }),
    {
      enabled: Boolean(id),
      ...queryOptions
    }
  );

  return {
    data: get(data, 'data', {}),
    meta: get(data, 'meta', {}),
    success: get(data, 'success'),
    ...rest
  };
};

export const useBuilderReports = (
  {
    ids,
    companyid,
    companyMode,
    reviewMode,
    roleIds,
    createdDate,
    cycleIds,
    notCycleIds,
    revieweeIds,
    lastUpdated
  } = {},
  {
    page,
    sort,
    include,
    search,
    projection,
    bundleCycleAndReviewee,
    viewerId = null
  } = {},
  queryOptions = {}
) => {
  const filters = {
    ids,
    companyid,
    companyMode,
    reviewMode,
    roleIds,
    createdDate,
    cycleIds,
    notCycleIds,
    revieweeIds,
    lastUpdated
  };
  const options = {
    page,
    sort,
    include,
    search,
    projection,
    bundleCycleAndReviewee,
  };
  const stringified = qs.stringify(
    { filters, options },
    { skipNulls: true, arrayFormat: 'brackets' }
  );

  const { data, ...rest } = useQuery(
    [QUERY_KEYS.BUILDER_DASHBOARD, ...(ids || []), filters, options],
    () => api.get(`/builder?${stringified}`).then((resp) => {
      if (!resp.success) {
        console.error(`GET /builder?${stringified} error`);
        return {
          success: false
        };
      }
      return resp;
    }),
    queryOptions
  );

  return {
    data: get(data, 'data', []),
    meta: get(data, 'meta', {
      page: {
        number: null,
        size: null,
        totalItems: null,
        totalPages: null
      }
    }),
    success: get(data, 'success'),
    ...rest
  };
};

export const useCreateBuilderReport = () => {
  const queryClient = useQueryClient();
  const { mutateAsync: mutate, ...rest } = useMutation(
    (data) => api.post('/builder', data).then((resp) => {
      const { success, error, warning } = resp;

      if (!success) {
        if (warning) return resp;
        console.error(`/builder`, error);
        return toast.error(
          'Uh oh, we ran into an issue. Please try again later!'
        );
      }

      return resp;
    }),
    {
      throwOnError: true,
      mutationKey: [QUERY_KEYS.BUILDER],
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.BUILDER);
        queryClient.invalidateQueries(QUERY_KEYS.BUILDER_DASHBOARD);
      }
    }
  );

  return {
    create: mutate,
    ...rest
  };
};

export const useUpdateBuilderReport = (editToken) => {
  const { mutateAsync: mutate, ...rest } = useMutation(
    (data) => api.patch(`/builder/${editToken}`, data).then((resp) => {
      const { success, error, warning } = resp;

      if (!success) {
        if (warning) return resp;
        console.error(`/builder`, error);
        return toast.error(
          'Uh oh, we ran into an issue. Please try again later!'
        );
      }

      return resp;
    }),
    {
      throwOnError: true,
      mutationKey: [QUERY_KEYS.BUILDER, editToken]
    }
  );

  return {
    update: mutate,
    ...rest
  };
};

export const useDeleteBuilderReport = () => {
  const { mutateAsync: mutate, ...rest } = useMutation((reportId) => api.delete(`/builder/${reportId}`).then((resp) => {
    const {
      success, error, message, warning
    } = resp;

    if (!success) {
      if (warning) return toast.show(message);
      console.error(`/builder/${reportId}`, error);
      return toast.error(
        'Uh oh, we ran into an issue. Please try again later!'
      );
    }

    return resp;
  }));

  return {
    delete: mutate,
    ...rest
  };
};

export const sendShareLinkEmail = () => useMutation((data) => api.post('/builder/shareLink', data));

export const sendLinksToReviewer = () => useMutation((data) => api.post('/builder/reviewerLinks', data));

export const useSendFeedbackEmail = () => useMutation((data) => api.post('/admin/builderFeedback', data));

export const useCreatePdf = () => useMutation((data) => api.post('/builder/pdf', data));

export const useBuilderForm = (reportData, companyData, builderDataset) => {
  const doesReportExist = !isEmpty(reportData);
  const doesCompanyExist = !isEmpty(companyData);
  let defaultValues = {};

  let reviewMode = null;
  if (doesReportExist) {
    reviewMode = reportData.reviewMode;
  } else if (doesCompanyExist) {
    reviewMode = 'categories';
  } else {
    reviewMode = 'questions';
  }

  let companyMode = null;
  if (doesReportExist) {
    companyMode = reportData.companyMode;
  } else if (doesCompanyExist) {
    companyMode = 'company';
  } else {
    companyMode = 'public';
  }

  const dataset = commonBuilderUtils.getDatasetByCompanyMode(companyMode, {
    builderDataset,
    ...(doesCompanyExist && { companyDataset: companyData.dataset })
  });
  if (doesReportExist) {
    const {
      reviewer, reviewee, companyName, title, reviewDate, cycleId
    } = reportData;
    defaultValues = {
      reviewerId: reviewer.reviewerId || null,
      reviewerFirstName: reviewer.firstName || '',
      reviewerLastName: reviewer.lastName || '',
      reviewerEmail: reviewer.email || '',
      revieweeId: reviewee.revieweeId || null,
      revieweeFirstName: reviewee.firstName || '',
      revieweeLastName: reviewee.lastName || '',
      revieweeTitle: reviewee.title || '',
      companyName: companyName || '',
      title: title || '',
      reviewDate: new Date(reviewDate)
    };

    const { industryId, roleId } = reportData;
    if (industryId && dataset.length) {
      const industryData = dataset.find(
        (industry) => industry.industryId === industryId
      );
      defaultValues.industry = {
        id: industryData.industryId,
        label: industryData.name
      };

      const { cycles } = industryData;
      if (cycleId) {
        const cycle = cycles.find((c) => c.id === cycleId);
        defaultValues.cycleId = cycle.id;
        defaultValues.cycleLabel = cycle.label;
      } else {
        defaultValues.cycleId = null;
        defaultValues.cycleLabel = 'No Review Cycle';
      }

      if (roleId) {
        const { roles } = industryData;
        const roleData = roles.find((role) => role.roleId === roleId);
        defaultValues.role = {
          id: roleData.roleId,
          label: roleData.name
        };
      } else {
        defaultValues.role = commonBuilderUtils.getEmptyOption();
      }
    } else {
      defaultValues.cycleId = null;
      defaultValues.cycleLabel = 'No Review Cycle';
      defaultValues.industry = commonBuilderUtils.getEmptyOption();
      defaultValues.role = commonBuilderUtils.getEmptyOption();
    }

    const { supplementalReviews, reviews, summary } = reportData;
    let populatedSupplementalReviews = [];
    if (doesCompanyExist) {
      populatedSupplementalReviews = commonBuilderUtils.getPopulatedSupplementalReviews(dataset, {
        industryId,
        roleId,
        supplementalReviews
      });
    }
    defaultValues.supplementalReviews = populatedSupplementalReviews;
    const populatedReviews = commonBuilderUtils.getPopulatedReviews(dataset, {
      industryId,
      roleId,
      reviews,
      summary,
      reviewMode
    });
    defaultValues.reviews = populatedReviews;
    defaultValues.summary = summary || '';
    defaultValues.summaryTemplate = null;
  } else {
    let cycleId = null;
    let cycleLabel = 'No Review Cycle';

    if (doesCompanyExist) {
      const { _id: companyid } = companyData;
      const industryData = dataset.find(
        (industry) => industry.industryId === companyid
      );

      const { cycles = [] } = industryData;
      const mostRecentCycle = cycles.reduce((acc, cycle) => {
        if (!acc) return cycle;
        return cycle.createdDate > acc.createdDate ? cycle : acc;
      }, null);

      if (mostRecentCycle) {
        cycleId = mostRecentCycle.id;
        cycleLabel = mostRecentCycle.label;
      }
    }

    defaultValues = {
      reviewerId: null,
      reviewerFirstName: '',
      reviewerLastName: '',
      reviewerEmail: '',
      revieweeId: null,
      revieweeFirstName: '',
      revieweeLastName: '',
      revieweeTitle: '',
      companyName: doesCompanyExist ? companyData.name : '',
      title: 'Performance Review',
      reviewDate: new Date(),
      industry: commonBuilderUtils.getEmptyOption(),
      cycleId,
      cycleLabel,
      role: commonBuilderUtils.getEmptyOption(),
      reviews: [],
      supplementalReviews: [],
      summary: '',
      summaryTemplate: null
    };
  }

  defaultValues.companyMode = companyMode;
  defaultValues.reviewMode = reviewMode;

  return {
    form: useForm({ defaultValues }),
    defaultValues
  };
};
