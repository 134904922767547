import commonTreeUtils from 'common/commonTreeUtils';
import CONSTANTS from 'common/commonConstants';
import { isEmpty } from 'lodash';

const { ACCESS } = CONSTANTS;

const commonPermissions = {};

commonPermissions.isAdmin = (account) => {
  const { access } = account;
  return access === ACCESS.ADMIN;
};

commonPermissions.isManager = (account) => {
  const { access } = account;
  return access === ACCESS.MANAGER;
};

commonPermissions.isAbove = (account, tree, accountIds = []) => {
  if (!accountIds.length) {
    return true;
  }

  const { _id: viewerId } = account;
  for (let i = 0; i < accountIds.length; i += 1) {
    const userId = accountIds[i];
    if (userId === viewerId) {
      return false;
    }

    const isViewerAboveUser = commonTreeUtils.isNodeDirectlyAbove(
      tree,
      userId,
      viewerId
    );
    if (!isViewerAboveUser) {
      return false;
    }
  }

  return true;
};

commonPermissions.isAboveOrSelf = (account, tree, accountIds = []) => {
  if (!accountIds.length) {
    return true;
  }

  const { _id: viewerId } = account;
  for (let i = 0; i < accountIds.length; i += 1) {
    const userId = accountIds[i];

    if (userId !== viewerId) {
      const isViewerAboveUser = commonTreeUtils.isNodeDirectlyAbove(
        tree,
        userId,
        viewerId
      );
      if (!isViewerAboveUser) {
        return false;
      }
    }
  }

  return true;
};

commonPermissions.canManageAccounts = (account, accountIds = []) => {
  if (isEmpty(account)) return false;
  const { manageAccounts } = account;
  return accountIds.every((id) => manageAccounts.includes(id));
};

commonPermissions.canManageUsers = (account, tree, userIds = []) => {
  if (isEmpty(account)) return false;

  const isAdmin = commonPermissions.isAdmin(account);
  if (isAdmin) return true;

  const isAbove = commonPermissions.isAbove(account, tree, userIds);
  if (isAbove) return true;

  const canManageAccounts = commonPermissions.canManageAccounts(
    account,
    userIds
  );
  if (canManageAccounts) return true;

  return false;
};

commonPermissions.canViewFeedback = ({
  tree,
  revieweeId,
  reviewerId,
  account
}) => {
  const isAdmin = account.access === ACCESS.ADMIN;
  const isAbove = commonTreeUtils.isNodeDirectlyAbove(
    tree,
    revieweeId,
    account._id
  );
  const canManageAccount = commonPermissions.canManageAccounts(account, [
    revieweeId._id
  ]);

  return isAbove || isAdmin || canManageAccount || revieweeId === reviewerId;
};

export default commonPermissions;
