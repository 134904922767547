import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Base, appUtils, Pagination } from 'src/components';
import { useTree } from 'src/queries/tree';
import Goal from 'src/containers/Goals/Goal';
import { useGoals } from 'src/queries/goal';
import commonTreeUtils from 'common/commonTreeUtils';
import ReportSVG from 'src/assets/svg/report.svg';
import STYLE from 'src/constants/style';
import FilterView from 'src/containers/UserProfile/FilterView/FilterView';
import { UserProfileContext } from 'src/pagesDashboard/UserProfile/context/UserProfileProvider';
import { DashContext } from 'src/pagesDashboard/Dash/context/DashProvider';
import MobileUserGoals from 'src/containers/UserProfile/Goals/components/mobile/MobileUserGoals';

const Goals = ({ userId }) => {
  const { pathname } = useLocation();

  const [currentPage, setCurrentPage] = useState(1);
  const loggedUserId = appUtils.getLoggedUserId();
  const isMyProfile = userId === loggedUserId;
  const { context } = useContext(
    isMyProfile ? DashContext : UserProfileContext
  );

  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const { status } = context.filters;
  const {
    data: goals,
    isFetching: isFetchingGoals,
    isError: isErrorGoals,
    meta: {
      page: { totalPages }
    }
  } = useGoals(
    {
      isTopLevel: true,
      assignees: [userId],
      ...(status.length && { status })
    },
    {
      sort: {
        field: 'lastUpdated',
        order: 'desc'
      },
      page: {
        size: 10,
        number: currentPage
      }
    }
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [status]);

  const isFetching = isFetchingGoals || isFetchingTree;
  const isError = isErrorGoals || isErrorTree;
  const isReady = tree && tree.id && goals && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const userNode = commonTreeUtils.findNodeById(tree, userId);

  const mobileSize = appUtils.getMobileSize();
  const isMobile = mobileSize;

  return (
    <div>
      {isMobile ? (
        <MobileUserGoals
          userId={userId}
          goals={goals}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
      ) : (
        <>
          <FilterView userId={userId} show={['status']} />
          <Base classes={STYLE.CONTAINER_WHITE} loading={!isReady}>
            <div className='flex justify-between'>
              <div className='w-3/5'>
                <h5 className='text-black text-left text-xl mb-2'>Goals</h5>
              </div>
            </div>

            <div className='mb-2 py-1 px-1 bg-background-gray text-black font-bold rounded-sm'>
              <p className='mb-0 pl-1 w-5/12 inline-block font-bold'>Title</p>
              <p className='mb-0 w-2/12 inline-block font-bold'>Assignee</p>
              <p className='mb-0 w-2/12 inline-block font-bold'>Progress</p>
              <p className='mb-0 w-2/12 inline-block font-bold'>Status</p>
              <p className='mb-0 w-1/12 inline-block font-bold'>Action</p>
            </div>

            <div>
              {goals && !goals.length && (
                <div className='mt-8 mb-8 text-center font-bold'>
                  <div className='w-10 h-10 mx-auto mb-2'>
                    <ReportSVG />
                  </div>
                  <p className='cursor-default'>{`${userNode.name} has no goals saved.`}</p>
                </div>
              )}

              {goals
                && !!goals.length
                && goals.map((goal) => (
                  <Goal goal={goal} redirectUrl={pathname} key={goal._id} />
                ))}
              <Pagination
                name={`Dash-goals-${userId}`}
                totalPages={totalPages}
                currentPage={currentPage}
                selectPage={setCurrentPage}
              />
            </div>
          </Base>
        </>
      )}
    </div>
  );
};

export default Goals;
