import React, { useContext } from 'react';
import { PerformanceBuilderContext } from 'src/pages/PerformanceBuilder/PerformanceBuilderContext';
import SupplementalQuestion from 'src/pages/PerformanceBuilder/BuilderComponents/SupplementalQuestion';
import { isEmpty } from 'lodash';

const PerformanceSupplemental = () => {
  const {
    data: { dataset, prevDataRef }
  } = useContext(PerformanceBuilderContext);

  const prevData = prevDataRef.current;
  const {
    industry, role, reviewerId, revieweeId
  } = prevData;

  const industryData = dataset.find((data) => data.industryId === industry.id);
  const { supplementalQuestions } = industryData;
  if (isEmpty(supplementalQuestions)) return null;

  const roleData = industryData.roles.find((data) => data.roleId === role.id);
  const { supplementalQuestionIds } = roleData;
  const roleSupplementalQuestions = supplementalQuestions.filter((question) => supplementalQuestionIds.includes(question.id));

  const isSelf = reviewerId === revieweeId;
  const hasQuestions = roleSupplementalQuestions.some((question) => {
    if (isSelf) return !isEmpty(question.self);
    return !isEmpty(question.question);
  });
  if (!hasQuestions) return null;

  return (
    <>
      <div
        id='performance-data-section'
        className='mb-8 border-b rounded border-[#E2E8F0]'
      />
      <h2>Supplemental Questions</h2>
      <p className='mb-3 text-lg font-normal leading-7'>
        Gathering additional input on team member performance.
      </p>
      {roleSupplementalQuestions.map((question, index) => (
        <div key={index}>
          <SupplementalQuestion question={question} />
        </div>
      ))}
    </>
  );
};

export default PerformanceSupplemental;
