import React, { useRef, useEffect, useState } from 'react';
import { isString, isEmpty, debounce } from 'lodash';
import { Base, toast } from 'src/components';
import appUtils from 'src/components/appUtils';
import STYLE from 'src/constants/style';
import CircledCheckmarkLargeSVG from 'src/assets/svg/circled-checkmark-large.svg';
import COMMON_CONSTANTS from 'common/commonConstants';
import HeaderV2 from 'src/containers/Header/HeaderV2';
import {
  useNotifications,
  QUERY_KEYS as NOTIFICATIONS_QUERY_KEYS
} from 'src/queries/notifications';
import SpinnerSVG from 'src/assets/svg/spinner.svg';
import commonDateUtils from 'common/commonDateUtils';
import InfoCircleAlterSVG from 'src/assets/svg/infoCircle-alter.svg';
import PositiveSVG from 'src/assets/svg/positive.svg';
import NegativeSVG from 'src/assets/svg/negative.svg';
import FeedbackSVG from 'src/assets/svg/feedback.svg';
import FlagSVG from 'src/assets/svg/flag.svg';
import { useReviewsV2 } from 'src/queries/reviews';
import commonReviewUtils from 'common/commonReviewUtils';
import { useLocation, useNavigate } from 'react-router-dom';

const { NOTIFICATION_TYPES, REVIEW_STATUS } = COMMON_CONSTANTS;

export const renderNotification = (
  {
    text, createdDate, isRead, type, meta, key = null
  },
  {
    rounded = '',
    border = '',
    refetchQuery = async () => {},
    navigateTo = () => {},
    pathname
  } = {}
) => {
  const queryClient = appUtils.getQueryClient();
  let callback = () => {};

  let icon = (
    <InfoCircleAlterSVG className='h-4 w-4 mini:h-6 mini:w-6 text-purple' />
  );

  if (type === NOTIFICATION_TYPES.RECEIVED_REVIEW) {
    // const { reviewId } = meta;
    icon = <FeedbackSVG className='h-4 w-4 mini:h-6 mini:w-6 text-purple' />;
    // callback = () => navigateTo(`/dashboard/reviews/view/${reviewId}`);
  } else if (type === NOTIFICATION_TYPES.RECEIVED_FEEDBACK) {
    // const { revieweeId } = meta;
    icon = <FeedbackSVG className='h-4 w-4 mini:h-6 mini:w-6 text-purple' />;
    // callback = () => navigateTo(appUtils.getDashRoute(revieweeId, 'feedback'));
  } else if (type === NOTIFICATION_TYPES.SENTIMENT_ALERT) {
    const {
      // revieweeId,
      reviewSentiment: { score }
    } = meta;
    if (score > 0) icon = <PositiveSVG className='h-4 w-4 mini:h-6 mini:w-6 text-purple' />;
    else if (score < 0) icon = <NegativeSVG className='h-4 w-4 mini:h-6 mini:w-6 text-purple' />;
    // callback = () => navigateTo(appUtils.getDashRoute(revieweeId, 'feedback'));
  } else if (type === NOTIFICATION_TYPES.PARTICIPATION_ALERT) {
    // const { subjectId } = meta;
    icon = <FlagSVG className='h-4 w-4 mini:h-6 mini:w-6 text-purple' />;
    // callback = () => navigateTo(appUtils.getDashRoute(subjectId, 'participation'));
  } else if (type === NOTIFICATION_TYPES.REVIEW_REQUEST) {
    const { reviewId } = meta;
    icon = <FeedbackSVG className='h-4 w-4 mini:h-6 mini:w-6 text-purple' />;

    callback = async () => {
      const {
        data: { success, data }
      } = await refetchQuery();
      if (!success) {
        return toast.error(
          'Uh oh, we ran into an issue. Please try again later!'
        );
      }

      const review = data.find((r) => r._id === reviewId);

      if (isEmpty(review)) {
        return toast.show('Review is not available anymore.');
      }

      if (
        ![REVIEW_STATUS.SENT, REVIEW_STATUS.DELIVERY_FAILED].includes(
          review.status
        )
      ) {
        return toast.show('Review already completed.');
      }

      navigateTo(
        `/dashboard/inbox/reviews?reviewId=${reviewId}&redir=${pathname}`
      );
    };
  }

  const plainText = commonReviewUtils.getPlainTextFromHTML(text);

  return (
    <button
      key={key ?? `notification-${Math.random()}`}
      className={`transition duration-300 hover:bg-gray flex flex-row items-center justify-start w-full h-[85px] grow-0 shrink-0 border-stone-gray p-1 mini:py-4 mini:px-6
      ${rounded.includes('top') ? 'border-t rounded-t-lg' : ''} ${
        rounded.includes('bottom') ? 'border-b rounded-b-lg' : ''
      } ${border.includes('sides') ? 'border-r border-l' : ''} ${
        border.includes('bottom') ? 'border-b' : ''
      } ${border.includes('top') ? 'border-t' : ''} ${isRead ? '' : 'bg-[#DCE8F9]'}`}
      onClick={() => {
        queryClient.invalidateQueries([
          NOTIFICATIONS_QUERY_KEYS.NOTIFICATIONS,
          NOTIFICATIONS_QUERY_KEYS.NOTIFICATION_COUNT
        ]);
        callback();
      }}
    >
      <div className='h-fit w-1/20 shrink-0 pr-2 mini:pr-4'>{icon}</div>
      <div className='w-16.5/20 text-left py-4'>
        <p className='m-0 text-sm leading-4 tracking-wide text-black multiline-ellipsis'>
          {plainText}
        </p>
      </div>
      <div className='pl-2 mini:pl-4 w-2.5/20 shrink-0 text-right'>
        <p className='m-0 text-sm leading-4 tracking-wide'>
          {commonDateUtils.dateToTimeAgo(createdDate)}
        </p>
      </div>
    </button>
  );
};

const buildNotificationFeed = () => {
  const queryClient = appUtils.getQueryClient();
  const queryCache = queryClient.getQueryCache();
  const cachedQueries = queryCache.findAll([
    NOTIFICATIONS_QUERY_KEYS.NOTIFICATIONS,
    NOTIFICATIONS_QUERY_KEYS.NOTIFICATION_DATA
  ]);
  const notifications = [];

  cachedQueries.forEach((query) => {
    const { data: queryData = {} } = query.state;
    const { data = [] } = queryData;
    notifications.push(...data);
  });
  return notifications;
};

export const NotificationList = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const params = new URLSearchParams(search);

  const shouldSetAsReadParam = params.get('r');
  const isSuperUser = appUtils.isSuperUser();
  const shouldSetAsRead = isString(shouldSetAsReadParam) || !isSuperUser;

  const loggedUserId = appUtils.getLoggedUserId();

  // workaround until finding better solution:
  // a ref is being used because it's the only way I found
  // to stop updating the currentPage number after
  // the max page was reached
  const currentPage = useRef(1);
  const [pageQuery, setPageQuery] = useState(currentPage.current);

  const {
    meta: {
      page: { totalPages }
    },
    isFetching: isFetchingNotifications,
    isError: isErrorNotifications
  } = useNotifications(
    {
      recipientIds: [loggedUserId]
    },
    {
      setAsRead: shouldSetAsRead,
      sort: {
        createdDate: -1
      },
      page: {
        number: pageQuery,
        size: 15
      }
    },
    {
      index: pageQuery
    }
  );

  const queryClient = appUtils.getQueryClient();
  useEffect(() => {
    const refetchQueriesCallback = async () => queryClient.refetchQueries([NOTIFICATIONS_QUERY_KEYS.NOTIFICATIONS]);
    refetchQueriesCallback();
  }, []);

  const notifications = buildNotificationFeed();
  const reviewIds = [];
  if (notifications.length) {
    notifications.forEach((notification) => {
      if (notification.type === NOTIFICATION_TYPES.REVIEW_REQUEST) {
        reviewIds.push(notification.meta.reviewId);
      }
    });
  }

  const handleScroll = debounce((event) => {
    if (currentPage.current > totalPages) return;

    const isFetchingAny = Boolean(
      queryClient.isFetching([
        NOTIFICATIONS_QUERY_KEYS.NOTIFICATIONS,
        NOTIFICATIONS_QUERY_KEYS.NOTIFICATION_DATA
      ])
    );

    if (
      event.deltaY > 0
      && Math.round(window.innerHeight + window.scrollY)
        >= document.body.offsetHeight
      && !isFetchingAny
      && totalPages !== null
      && currentPage.current < totalPages
    ) {
      currentPage.current += 1;
      setPageQuery(currentPage.current);
    }
  }, 300);

  window.addEventListener('wheel', handleScroll);

  useEffect(
    () => () => {
      const hasReadNotifications = Boolean(
        notifications.filter((notification) => !notification.isRead).length
      );
      if (hasReadNotifications) {
        queryClient.invalidateQueries([
          NOTIFICATIONS_QUERY_KEYS.NOTIFICATIONS,
          NOTIFICATIONS_QUERY_KEYS.NOTIFICATION_COUNT
        ]);
      }
      queryClient.removeQueries([
        NOTIFICATIONS_QUERY_KEYS.NOTIFICATIONS,
        NOTIFICATIONS_QUERY_KEYS.NOTIFICATION_DATA
      ]);
      window.removeEventListener('wheel', handleScroll);
    },
    []
  );

  const { refetch: refetchReviews } = useReviewsV2(
    {
      ids: reviewIds
    },
    {
      page: {
        size: reviewIds.length
      }
    },
    {
      enabled: false
    }
  );

  const isFetching = isFetchingNotifications
    || Boolean(
      queryClient.isFetching([
        NOTIFICATIONS_QUERY_KEYS.NOTIFICATIONS,
        NOTIFICATIONS_QUERY_KEYS.NOTIFICATION_DATA
      ])
    );
  const isError = isErrorNotifications;
  const isReady = !isFetching && !isError;
  if (!isReady && currentPage === 1) {
    return (
      <>
        <HeaderV2 overtitle='Inbox' title='Notifications' />
        <div className='w-full h-full flex flex-col gap-3 items-center justify-center'>
          <SpinnerSVG className='w-8 h-8' />
        </div>
      </>
    );
  }

  return (
    <>
      <HeaderV2 overtitle='Inbox' title='Notifications' />
      <Base classes={STYLE.BASE}>
        {notifications.length ? (
          <div className='w-full mini:w-16/20 full:w-14/20 mx-auto'>
            <Base
              classes={STYLE.CONTAINER_WHITE_PADDINGLESS_MARGINLESS_SHADOWLESS}
            >
              {notifications.map((notification, index) => {
                const isTopRounded = index === 0;
                const isBottomRounded = index === notifications.length - 1;

                let rounded;
                if (isTopRounded) rounded = 'top';
                if (isBottomRounded) rounded = 'bottom';
                if (isTopRounded && isBottomRounded) rounded = 'top bottom';

                const border = 'sides bottom';

                let refetchQuery = async () => {};
                if (notification.type === NOTIFICATION_TYPES.REVIEW_REQUEST) refetchQuery = refetchReviews;

                notification.key = `notification-${index}`;
                return renderNotification(notification, {
                  rounded,
                  border,
                  refetchQuery,
                  navigateTo: navigate,
                  pathname
                });
              })}
              <div className='flex w-full justify-center h-8 bg-cloud-blue pt-2'>
                {isFetchingNotifications ? (
                  <SpinnerSVG className='w-8 h-8' />
                ) : null}
                {!isFetching && currentPage < totalPages ? (
                  <p className='m-0'>Scroll down to load more</p>
                ) : null}
              </div>
            </Base>
          </div>
        ) : (
          <div className='w-full h-full flex flex-col gap-3 items-center justify-center'>
            <CircledCheckmarkLargeSVG color='#5B7FFF' />
            <p className='text-purple text-xl font-medium leading-8'>
              You're up to date!
            </p>
          </div>
        )}
      </Base>
    </>
  );
};

export default NotificationList;
