import React, { useContext } from 'react';
import { get } from 'lodash';
import { useUserReportReviews } from 'src/pagesDashboard/UserProfile/queries';
import { UserProfileContext } from 'src/pagesDashboard/UserProfile/context/UserProfileProvider';
import { HistoricReviews } from 'src/components';
import ViewQuestions from 'src/containers/PerformanceCategoryModal/Tabs/ViewQuestions';
import { useTree } from 'src/queries/tree';
import commonTreeUtils from 'common/commonTreeUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import commonViewPermissions from 'common/commonViewPermissions';
import AddNote from 'src/containers/UserReports/AddNote/AddNote';
import Notes from 'src/containers/UserReports/AddNote/Notes';
import { useCreateNote } from 'src/hooks/UserReports/useNote';
import { useReportById } from 'src/queries/reports';
import { useCompany } from 'src/queries/company';

const { REPORT_NOTE_TYPES, REPORT_TEMPLATE_TYPES } = COMMON_CONSTANTS;

const ReportCategoryDetail = ({
  reportId, userId, category, roleId
}) => {
  const {
    data: { myTreeRow, tree }
  } = useTree();
  const { data: company } = useCompany();

  const { context, updateContext } = useContext(UserProfileContext);
  const { saveNote, isLoading: createNoteLoading } = useCreateNote();
  const { data: reportResponse, refetch: refetchReport } = useReportById(reportId);
  const categoryId = category.id.toString();
  const reportRoleView = true;
  const {
    reviews,
    topReviews,
    bottomReviews,
    pagination,
    isLoading: isLoadingHistoricReviews
  } = useUserReportReviews(userId, categoryId, roleId, reportRoleView);

  const report = get(reportResponse, 'report', null);

  if (!tree.id || !report) {
    return null;
  }
  const userTree = commonTreeUtils.findNodeById(tree, userId);

  const categoryNotes = (report
      && report.notes
      && report.notes.filter(
        (n) => n.type === REPORT_NOTE_TYPES.CATEGORY
          && n.meta.categoryId.toString() === categoryId.toString()
      ))
    || [];

  const viewerId = myTreeRow.id;
  const canViewHistoricReviews = commonViewPermissions.canViewUserReportHistoricReviews(
    tree,
    viewerId,
    userId
  );
  const viewHistoricReviews = userId !== viewerId && canViewHistoricReviews;

  const selectPage = (page) => {
    updateContext({
      historicReviews: {
        ...context.historicReviews,
        page: parseInt(page, 10)
      }
    });
  };
  // const viewHistoricReviews = false

  const categoryNotesTemplates = company.templates
    .filter((t) => t.type === REPORT_TEMPLATE_TYPES.CATEGORY_TEMPLATE)
    .map((t) => ({
      id: t.id,
      label: t.name,
      content: t.content
    }));

  categoryNotesTemplates.unshift({
    id: 'clear',
    label: 'No template',
    content: ''
  });

  return (
    <div className='px-1 -mt-1'>
      <div className='px-2 pb-1 bg-bg-light-purple rounded'>
        <div className='pt-4' />
        <div className='focus:outline-none text-xl transition-colors duration-300 bg-white w-full shadow rounded px-2 py-1 pb-2'>
          <p className='text-black focus:outline-none text-xl mr-5 mb-3 inline-block mt-2 ml-1'>
            Category Questions
          </p>
          <ViewQuestions
            revieweeName={userTree.name}
            questionIds={category.questions}
          />
        </div>

        {viewHistoricReviews ? (
          <>
            <HistoricReviews
              showViewReviewButton={false}
              userId={userId}
              reviews={topReviews}
              isLoading={isLoadingHistoricReviews}
              title='Top 5 Reviews'
              showRequestReview={false}
              showVisibleTooltip={false}
            />

            <HistoricReviews
              showViewReviewButton={false}
              userId={userId}
              reviews={bottomReviews}
              isLoading={isLoadingHistoricReviews}
              title='Bottom 5 Reviews'
              showRequestReview={false}
              showVisibleTooltip={false}
            />

            <HistoricReviews
              showViewReviewButton={false}
              userId={userId}
              reviews={reviews}
              isLoading={isLoadingHistoricReviews}
              showRequestReview={false}
              showVisibleTooltip={false}
              title='All Historic Reviews'
              pagination={{
                totalPages: pagination.pages,
                currentPage: pagination.current,
                selectPage
              }}
            />
          </>
        ) : null}

        <Notes
          reportId={reportId}
          title='Notes'
          notes={categoryNotes}
          useRefetchReport={refetchReport}
        />
        <AddNote
          title='Category note'
          reportId={reportId}
          noteType={REPORT_NOTE_TYPES.CATEGORY}
          templatesOptions={categoryNotesTemplates}
          meta={{ categoryId }}
          onSave={async (...rest) => {
            await saveNote(...rest);
            refetchReport();
          }}
        />
      </div>
    </div>
  );
};

export default ReportCategoryDetail;
