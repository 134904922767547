import React, { useContext, useState } from 'react';
import { get } from 'lodash';
import { Base } from 'src/components/';
import TopScoreBox from 'src/componentsAdvanced/TopScoreBox/TopScoreBox';
import { useCompanyDashboard } from 'src/pagesDashboard/CompanyDash/queries/queries';
import { useCompany } from 'src/queries/company';
import { useCompanyFeedbackCount } from 'src/pagesDashboard/UserProfile/queries';
import { CompanyDashContext } from 'src/pagesDashboard/CompanyDash/context/Provider';
import DASH_CONSTANTS from 'src/constants/dashboard';
import { InfoModal } from 'src/componentsTailwind/index';

import STYLE from 'src/constants/style';

const { DASH_TILES_INFO } = DASH_CONSTANTS;

const TopScores = () => {
  const { context } = useContext(CompanyDashContext);
  const {
    teamScore,
    isFetching: isFetchingCompanyDashboard,
    isError: isErrorCompanyDashboard
  } = useCompanyDashboard();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const {
    pagination: feedbackPagination,
    isFetching: isFetchingFeedbackCount
  } = useCompanyFeedbackCount({
    ...context.range,
    includeUnassignedUsers: true,
    options: {
      size: 1,
      sortBy: 'createdAt',
      page: 1,
      joinReviews: false
    }
  });

  const [showInfoModal, setShowInfoModal] = useState(false);
  const [infoTitle, setInfoTitle] = useState('');
  const [infoContent, setInfoContent] = useState('');

  const isFetching = isFetchingCompany || isFetchingCompanyDashboard || isFetchingFeedbackCount;
  const isError = isErrorCompany || isErrorCompanyDashboard;
  const isReady = teamScore
    && teamScore.overview
    && company
    && company.id
    && !isFetching
    && !isError;

  if (!isReady) {
    return <Base classes='h-36 mb-4 w-full' loading />;
  }

  const feedbackRateText = teamScore?.participationStats?.feedbackRate >= 0
    ? `${teamScore?.participationStats?.feedbackRate}%`
    : '-';

  // feedback + review comments (not empty)
  const allFeedbackCount = get(teamScore, 'participationStats.rich', 0)
    + get(feedbackPagination, 'total', 0);

  const openInfoModal = (tileInfo) => {
    setInfoTitle(tileInfo.label);
    setInfoContent(tileInfo.content);
    setShowInfoModal(true);
  };

  return (
    <Base classes={STYLE.CONTAINER_BLUE}>
      <div className='grid sm:grid-cols-2 lg:grid-cols-4 gap-3'>
        <InfoModal
          close={() => setShowInfoModal(false)}
          isOpen={showInfoModal}
          title={infoTitle}
          content={infoContent}
        />
        <TopScoreBox
          classes='cursor-pointer'
          subtitle={teamScore?.overview?.label || '-'}
          title='Score'
          value={teamScore?.overview?.score || '-'}
          onClick={() => openInfoModal(DASH_TILES_INFO.ORG.SCORE)}
          tooltip='Score'
        />
        <TopScoreBox
          classes='cursor-pointer'
          title='Reviews'
          value={teamScore?.participationStats?.reviewed || '-'}
          onClick={() => openInfoModal(DASH_TILES_INFO.ORG.REVIEWS)}
          tooltip='Reviews'
        />
        <TopScoreBox
          classes='cursor-pointer'
          title='Total Feedback'
          value={allFeedbackCount || '-'}
          tooltip='Total Feedback'
          onClick={() => openInfoModal(DASH_TILES_INFO.ORG.FEEDBACK)}
        />
        <TopScoreBox
          classes='cursor-pointer'
          title='Reviews With Comments'
          value={feedbackRateText}
          onClick={() => openInfoModal(DASH_TILES_INFO.ORG.REVIEWS_RATE)}
          tooltip='Reviews With Comments'
        />
      </div>
    </Base>
  );
};

export default TopScores;
