import React from 'react';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { isString } from 'lodash';
import {
  patchReviewQuery,
  QUERY_KEYS as REVIEWS_QUERY_KEYS
} from 'src/queries/reviews';
import { QUERY_KEYS as SCORE_QUERY_KEYS } from 'src/queries/score';
import { QUERY_KEYS as FEEDBACK_QUERY_KEYS } from 'src/queries/feedback';
import { QUERY_KEYS as ACCOUNT_QUERY_KEYS } from 'src/queries/account';
import { toast } from 'src/components';
import { AlertModal } from 'src/componentsTailwind/index';

const DeleteReviewModal = ({ data: review, close }) => {
  const navigate = useNavigate();
  const { state: historyState } = useLocation();
  const queryClient = useQueryClient();

  const { mutateAsync: updateReview, isLoading: deleteLoading } = patchReviewQuery();

  const deleteFn = async () => {
    const { reviewee } = review;
    let revieweeId = null;

    if (isString(reviewee)) {
      revieweeId = reviewee;
    } else {
      revieweeId = reviewee._id || reviewee.id;
    }

    const response = await updateReview({
      action: 'DISABLE_REVIEW',
      revieweeId,
      data: { reviewId: review._id }
    });
    if (!response || !response.success) {
      console.error('Failed to delete a review', { response, review });
      return toast.error("Oops, we've run into an issue. Try again later!");
    }

    toast.show('Review archived');

    queryClient.invalidateQueries(SCORE_QUERY_KEYS.SCORE);
    queryClient.invalidateQueries(REVIEWS_QUERY_KEYS.REVIEWS);
    queryClient.invalidateQueries(FEEDBACK_QUERY_KEYS.FEEDBACK);
    queryClient.invalidateQueries([
      ACCOUNT_QUERY_KEYS.ACCOUNT,
      ACCOUNT_QUERY_KEYS.OVERVIEW
    ]);
    return navigate(historyState?.returnTo ?? -1);
  };

  return (
    <AlertModal
      isLoading={deleteLoading}
      isOpen
      close={close}
      onAction={deleteFn}
      title='Are you sure you want to delete this review?'
      content='Deleted reviews are saved and visible to organization admins.'
    />
  );
};

export default DeleteReviewModal;
