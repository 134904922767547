import React from 'react';
import Spinner from '../../assets/spinnerBlue.svg';
import './LoadingOverlay.scss';

// variant = xs | sm | md | lg
const LoadingOverlay = ({
  loading, variant, top, children, classes
}) => {
  variant = variant || 'md';
  const noTop = top === false;
  const left = 0;
  const El = () => children;

  return (
    <>
      {loading ? (
        <>
          <div className='spin'>
            <div
              style={{ top: `${top}px`, left: `${left}px` }}
              className={`spinner-container spinner-container-${variant} ${
                noTop ? 'spinner-container-no-top' : ''
              } ${classes}`}
            >
              <Spinner />
            </div>
          </div>
          <div className={`loading-overlay loading-overlay-${variant}`}>
            <El />
          </div>
        </>
      ) : (
        <El />
      )}
    </>
  );
};

export default LoadingOverlay;
