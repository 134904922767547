import React, { useEffect } from 'react';
import { useUserScore } from 'src/queries/score';
import { useReport } from 'src/queries/reports';
import { useAccountScore } from 'src/queries/account';
import { getAvgSentimentLabel } from 'common/commonNlpUtils';
import { TopScoreBoxNewReport } from 'src/pagesDashboard/NewUserReport/components';

const TopScores = ({ userId, setIsLoading = () => {}, reportId }) => {
  const {
    data: report,
    isFetching: isFetchingReport,
    isError: isErrorReport
  } = useReport(reportId);

  const previousReport = report.previousReportData;

  if (isFetchingReport) return <div className='h-full' />;

  const {
    data: topScores,
    isFetching: isFetchingTopScores,
    isError: isErrorTopScores
  } = useAccountScore(
    {
      id: userId
    },
    {
      start: report.start,
      end: report.end,
      role: 'reviewee',
      include: {
        account: true
      },
      reportId,
      enabled: reportId
    }
  );

  const {
    data: userScore,
    isFetching: isFetchingUserScore,
    isError: isErrorUserScore
  } = useUserScore({
    userId,
    filters: {
      reportId,
      start: report.start,
      end: report.end,
      roles: report.roles,
      reviewerGroup: report.reviewerGroup,
      reviewerIds: report.reviewerIds
    },
    options: {
      isReport: reportId
    }
  });

  const {
    data: topScoresPreviousReport,
    isFetching: isFetchingPreviousTopScores,
    isError: isErrorPreviousTopScores
  } = useAccountScore(
    {
      id: userId,
      start: previousReport ? previousReport.start : null,
      end: previousReport ? previousReport.end : null
    },
    previousReport
      ? {
        role: 'reviewee',
        include: {
          account: true
        }
      }
      : {}
  );

  const {
    data: userScorePreviousReport,
    isFetching: isFetchingPreviousScore,
    isError: isErrorPreviousScore
  } = useUserScore({
    userId,
    filters: previousReport
      ? {
        reportId: report?.previousReport,
        start: previousReport.start,
        end: previousReport.end,
        roles: previousReport.roles,
        reviewerGroup: previousReport.reviewerGroup,
        reviewerIds: previousReport.reviewerIds
      }
      : {},
    options: {
      isReport: report.previousReport
    }
  });

  const isFetching = isFetchingUserScore
    || isFetchingTopScores
    || isFetchingPreviousTopScores
    || isFetchingPreviousScore;
  const isError = isErrorUserScore
    || isErrorTopScores
    || isErrorReport
    || isErrorPreviousTopScores
    || isErrorPreviousScore;
  const isReady = !isFetching && !isError;

  useEffect(() => {
    setIsLoading(isFetching);
  }, [isFetching]);

  if (!isReady) return <div />;

  const { avgSentimentReceived } = topScores;

  const previousReviewsCountLabel = userScorePreviousReport?.overview?.reviews
    ? ''
    : '0';

  return (
    <div className='flex flex-row justify-center 2xl:justify-between flex-wrap gap-2 m-0 mb-4'>
      <TopScoreBoxNewReport
        title='Performance Score'
        subtitle='This team member’s overall performance (out of 100) based on their reviews.'
        score={{
          value: userScore?.overview?.score,
          label: userScore?.overview?.label
        }}
        previousScore={{
          value: report.previousReport
            ? userScorePreviousReport?.overview?.score
            : null,
          label: report.previousReport
            ? userScorePreviousReport?.overview?.label
            : null
        }}
        // tooltip='Performance Score'
        loading={false}
      />

      <TopScoreBoxNewReport
        title='Reviews Received'
        subtitle='Number of reviews comprising this report based on the designated date range.'
        score={{
          value: userScore?.overview?.reviews,
          label: userScore?.overview?.reviews ? '' : '0'
        }}
        previousScore={{
          value: report.previousReport
            ? userScorePreviousReport?.overview?.reviews
            : null,
          label: report.previousReport ? previousReviewsCountLabel : null
        }}
        // tooltip='Reviews Received'
        loading={false}
      />

      <TopScoreBoxNewReport
        title='Sentiment Score'
        subtitle='How positive or negative the feedback is for this team member (-100 to +100).'
        score={{
          value: avgSentimentReceived,
          label: getAvgSentimentLabel(avgSentimentReceived)
        }}
        previousScore={
          previousReport
            ? {
              value: report.previousReport
                ? topScoresPreviousReport?.avgSentimentReceived
                : null,
              label: getAvgSentimentLabel(
                topScoresPreviousReport?.avgSentimentReceived
              )
            }
            : {}
        }
        // tooltip='Sentiment Score'
        loading={false}
      />
    </div>
  );
};

export default TopScores;
