import React, { useEffect, useState } from 'react';
import {
  Base, Button, Placeholder, Toggle, toast
} from 'src/components';
import {
  useCompany,
  updateCompanyQuery,
  QUERY_KEYS as COMPANY_QUERY_KEYS
} from 'src/queries/company';
import STYLE from 'src/constants/style';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useTree } from 'src/queries/tree';
import { useQueryClient } from 'react-query';
import { QUERY_KEYS as ACCOUNT_QUERY_KEYS } from 'src/queries/account';
import { get } from 'lodash';
import { QUERY_KEYS as EVENT_QUERY_KEYS } from 'src/queries/events';

const { SCHEDULING_STATUS, ENABLED_STATUS } = COMMON_CONSTANTS;

const isValid = (value) => Object.values(ENABLED_STATUS).includes(value);

const EventsMasterSwitch = () => {
  const queryClient = useQueryClient();
  const {
    data: quietCompany,
    isFetching: isFetchingQuietCompany,
    isRefetching: isRefetchingQuietCompany,
    isPlaceholderData: isPlaceholderQuietCompany,
    refetch: refetchQuietCompany
  } = useCompany({
    bulkReports: true
  });
  const {
    data: dataTree,
    isFetching: isFetchingTree,
    isError: isErrorTree,
    refetch: refetchTree
  } = useTree();

  const [localState, setLocalState] = useState({
    reviews: get(quietCompany, 'events.reviews', ENABLED_STATUS.DISABLED),
    goals: get(quietCompany, 'events.goals', ENABLED_STATUS.DISABLED),
    pulse: get(quietCompany, 'events.pulse', ENABLED_STATUS.DISABLED)
  });

  const NOT_INITIALIZED = 'NOT_INITIALIZED';
  const [isCompanySchedulingEvents, setIsCompanySchedulingEvents] = useState(NOT_INITIALIZED);

  const syncLocalState = async (intervalId) => {
    const [
      {
        data: {
          events: {
            reviews, goals, pulse, schedulingStatus
          }
        }
      }
    ] = await Promise.all([refetchQuietCompany(), refetchTree()]);

    setLocalState({
      reviews,
      goals,
      pulse
    });
    if (schedulingStatus === SCHEDULING_STATUS.IN_PROGRESS) return setIsCompanySchedulingEvents(true);
    if (intervalId) clearInterval(intervalId);
    setIsCompanySchedulingEvents(false);
  };

  useEffect(() => {
    let intervalId = null;
    const setIntervalCallback = async () => {
      await syncLocalState(intervalId);
      intervalId = setInterval(async () => syncLocalState(intervalId), 5000);
    };
    setIntervalCallback();

    return () => clearInterval(intervalId);
  }, []);

  const isInitialFetchingQuietCompany = isFetchingQuietCompany
    && (isPlaceholderQuietCompany || !isRefetchingQuietCompany);
  const isFetching = isInitialFetchingQuietCompany || isFetchingTree;
  const isError = isErrorTree;
  const isReady = isCompanySchedulingEvents !== NOT_INITIALIZED
    && quietCompany
    && quietCompany.id
    && dataTree
    && dataTree.treeList
    && !isFetching
    && !isError;

  const { mutateAsync: updateCompany, isLoading: isLoadingUpdateCompany } = updateCompanyQuery();

  if (!isReady) return <Placeholder variant='7' />;

  const switchStatus = (value) => {
    if (value === ENABLED_STATUS.ENABLED) return ENABLED_STATUS.DISABLED;
    if (value === ENABLED_STATUS.DISABLED) return ENABLED_STATUS.ENABLED;
  };
  const haveValuesChanged = () => {
    const { reviews, goals, pulse } = quietCompany.events;
    return (
      localState.reviews !== reviews
      || localState.goals !== goals
      || localState.pulse !== pulse
    );
  };

  const isCompanyDisabled = !quietCompany.active;
  const isCompanyTooSmall = dataTree.treeList.length <= 1;
  const renderWarning = () => {
    if (isCompanySchedulingEvents) {
      return (
        <div className='flex items-center bg-red h-6 px-2 rounded-sm mb-2 text-white'>
          Your organization is currently being updated. Please wait before
          making any changes.
        </div>
      );
    }

    if (isCompanyDisabled) {
      return (
        <div className='flex items-center bg-red h-6 px-2 rounded-sm mb-2 text-white'>
          Your account is not active. Please subscribe to enable events.
        </div>
      );
    }

    if (isCompanyTooSmall) {
      return (
        <div className='flex items-center bg-red h-6 px-2 rounded-sm mb-2 text-white'>
          You need a minimum of 2 users in order to enable events.
        </div>
      );
    }
  };

  const isCompanyInvalid = isCompanyDisabled || isCompanyTooSmall || isCompanySchedulingEvents;
  const isButtonDisabled = isCompanyInvalid || isLoadingUpdateCompany || !haveValuesChanged();
  if (isLoadingUpdateCompany) return <Placeholder variant='7' />;

  return (
    <Base classes={STYLE.CONTAINER_WHITE}>
      <div className='flex w-full justify-between'>
        <h5 className='mb-2'>Scheduling</h5>
        {renderWarning()}
      </div>
      <div className='flex w-full justify-between mb-5'>
        <div>
          <p className='font-bold mb-0'>Reviews</p>
          <p className='mb-0'>
            Turn reviews on/off to enable reviewing between team members
          </p>
        </div>
        <Toggle
          disabled={isCompanyInvalid || !isValid(localState.reviews)}
          checked={localState.reviews === ENABLED_STATUS.ENABLED}
          onChange={() => setLocalState({
            ...localState,
            reviews: switchStatus(localState.reviews)
          })}
        />
      </div>
      <div className='flex w-full justify-between mb-5'>
        <div>
          <p className='font-bold mb-0'>Goals</p>
          <p className='mb-0'>
            Turn goals on/off to enable goal update requests to be sent to team
            members
          </p>
        </div>
        <Toggle
          disabled={isCompanyInvalid || !isValid(localState.goals)}
          checked={localState.goals === ENABLED_STATUS.ENABLED}
          onChange={() => setLocalState({
            ...localState,
            goals: switchStatus(localState.goals)
          })}
        />
      </div>
      <div className='flex w-full justify-between mb-5'>
        <div>
          <p className='font-bold mb-0'>Pulse Questions</p>
          <p className='mb-0'>
            Turn pulse questions on/off to pulse sends to organization
          </p>
        </div>
        <Toggle
          disabled={isCompanyInvalid || !isValid(localState.pulse)}
          checked={localState.pulse === ENABLED_STATUS.ENABLED}
          onChange={() => setLocalState({
            ...localState,
            pulse: switchStatus(localState.pulse)
          })}
        />
      </div>
      <div className='flex w-full justify-end'>
        <Button
          onClick={async () => {
            toast.show('Updating');
            await updateCompany(
              {
                events: {
                  ...quietCompany.events,
                  ...localState
                }
              },
              {
                onSuccess: async () => {
                  await syncLocalState();
                },
                onError: async (err) => {
                  const { message } = err;
                  if (message.warning) {
                    toast.error(message.message);
                    await syncLocalState();
                  } else toast.error(err.message);
                }
              }
            );
            queryClient.invalidateQueries([ACCOUNT_QUERY_KEYS.TASKS]);
            queryClient.invalidateQueries([COMPANY_QUERY_KEYS.COMPANY]);
            queryClient.invalidateQueries([EVENT_QUERY_KEYS.EVENTS]);
            toast.show('Done');
          }}
          variant='yellow'
          classes='w-24'
          disabled={isButtonDisabled}
        >
          Update
        </Button>
      </div>
    </Base>
  );
};

export default EventsMasterSwitch;
