import COMMON_CONSTANTS from 'common/commonConstants';
import appUtils from 'src/components/appUtils';

const { GOALS, getGoalStatusValueByKey } = COMMON_CONSTANTS;

const GOAL_PROGRESS = [];

const GOAL_LEVELS = [];
Object.keys(GOALS.LEVELS).forEach((key) => {
  const val = GOALS.LEVELS[key];
  if (val === GOALS.LEVELS.ALL) {
    return;
  }
  GOAL_LEVELS.push({
    label: val,
    value: val
  });
});

const GOAL_TYPES = [];
Object.keys(GOALS.TYPES).forEach((key) => {
  const val = GOALS.TYPES[key];
  GOAL_TYPES.push({
    label: val,
    value: val
  });
});

// this function should replace the GOAL_STATUSES above
const getGoalStatuses = () => {
  const loggedUser = appUtils.getLoggedUser();
  const { companyid } = loggedUser;
  const list = [];
  Object.keys(GOALS.STATUS)
    .filter((key) => GOALS.STATUS[key] !== GOALS.STATUS.ARCHIVED)
    .forEach((key) => {
      const val = GOALS.STATUS[key];
      list.push({
        label: getGoalStatusValueByKey(key, companyid),
        value: val
      });
    });
  return list;
};

const GOAL_UPDATE_FREQUENCIES = [];
Object.keys(GOALS.FREQUENCY).forEach((key) => {
  const val = GOALS.FREQUENCY[key];
  GOAL_UPDATE_FREQUENCIES.push({
    label: val.label,
    value: val.value
  });
});

export {
  GOAL_LEVELS, GOAL_TYPES, GOAL_UPDATE_FREQUENCIES, getGoalStatuses
};
