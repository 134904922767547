import React, { cloneElement } from 'react';
import appUtils from 'src/components/appUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
// import Header from 'src/containers/CompanyDash/Header/Header'; // DEPRECATE
import STYLE from 'src/constants/style';
import { Base, toast } from 'src/components';
import HeaderV2 from 'src/containers/Header/HeaderV2';
import OrganizationTabNavigator from 'src/components/TabNavigator/OrganizationTabNavigator';
import { useNavigate } from 'react-router-dom';

const { ACCESS } = COMMON_CONSTANTS;

const CompanyDash = ({ children }) => {
  const loggedUser = appUtils.getLoggedUser();

  const navigate = useNavigate();
  if (loggedUser.access !== ACCESS.ADMIN) {
    console.error(
      'User does not have access to company dash, redirecting to /dashboard/me/feedback'
    );
    toast.error('You do not have access to this page');
    navigate(appUtils.getDashRoute());
  }

  return (
    <>
      <HeaderV2 overtitle='Dashboards' title='Organization' />
      <OrganizationTabNavigator />
      <Base classes={STYLE.BASE}>{cloneElement(children)}</Base>
    </>
  );
};

export default CompanyDash;
