import React, { useEffect, useRef } from 'react';
import appUtils from '../appUtils';
import sharedDateUtils from '../../common/sharedDateUtils';
import SHARED_CONSTANTS from '../../common/sharedConstants';
import './FeedbackSection.scss';

const { FEEDBACK_VISIBLE } = SHARED_CONSTANTS;

const FeedbackSection = ({ list, email, loggedEmail }) => {
  const containerRef = useRef(null);
  useEffect(() => {
    const { hash } = window.location;
    if (hash && hash !== '' && hash === '#feedback-section') {
      const offsetObj = appUtils.getOffsetOfElement(containerRef.current);
      window.scrollTo({
        top: offsetObj.top,
        behavior: 'smooth'
      });
    }
  });

  const filteredList = list.filter((row) => row.reviewee === email);
  filteredList.forEach((row) => {
    row.feedbackDate = sharedDateUtils.dateToMonthDayYearTimeFormat(
      new Date(row.createdDate)
    );
  });

  return (
    <section className='marginTop10'>
      <div className='feedback-container marginBottom10'>
        {list
          && list.map((row, index) => {
            const {
              visible, visibleTo, text, reviewerName, feedbackDate
            } = row;
            let last = false;
            if (list.length === index + 1) {
              last = true;
            }
            let subText = `Feedback from ${reviewerName} on ${feedbackDate}`;

            if (
              visible === FEEDBACK_VISIBLE.PRIVATE
              || (visibleTo
                && visibleTo.length === 1
                && visibleTo.indexOf(loggedEmail) > -1)
            ) {
              subText = `Note to self on ${feedbackDate}`;
            }

            return (
              <div
                className={`marginBottom10 ${
                  last ? 'noBorder' : 'borderBottom'
                }`}
              >
                <p className='marginBottom10'>{text}</p>
                <p className='marginTop10 marginBottom10 italic fontSize13'>
                  {subText}
                </p>
              </div>
            );
          })}

        {filteredList.length === 0 ? (
          <div>
            <p>No feedback comments at this time.</p>
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default FeedbackSection;
