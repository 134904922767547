import React, { createContext, useReducer } from 'react';
import reducer from './reducer';
import initialState from './state';
import { updateData } from './actions';

export const PulseQuestionResultsContext = createContext();

const PulseQuestionResultsProvider = ({ children }) => {
  const [context, dispatch] = useReducer(reducer, initialState);

  const updateContext = (data) => {
    dispatch(updateData(data));
  };

  return (
    <PulseQuestionResultsContext.Provider
      value={{ context, dispatch, updateContext }}
    >
      {children}
    </PulseQuestionResultsContext.Provider>
  );
};

export default PulseQuestionResultsProvider;
