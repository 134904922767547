import React, { useState } from 'react';
import { Modal, Button, Base } from 'src/components';
import { get } from 'lodash';
import { usePerformanceModal } from 'src/containers/PerformanceCategoryModal/queries';
import ViewQuestions from 'src/containers/PerformanceCategoryModal/Tabs/ViewQuestions';
import RequestFeedback from 'src/containers/PerformanceCategoryModal/Tabs/RequestFeedback';
import commonCompanyUtils from 'common/commonCompanyUtils';
import { useFlags } from 'launchdarkly-react-client-sdk';

const TABS = {
  QUESTIONS: 'Questions',
  REQUEST_FEEDBACK: 'Request Feedback'
};

const PerformanceCategoryModal = ({
  close,
  data: { name, category, revieweeId },
  options = { showFeedbackTab: true }
}) => {
  const { showFeedbackTab } = options;
  const { showRequestFeedbackButton } = useFlags();
  const [activeTab, setActiveTab] = useState(TABS.QUESTIONS);
  const {
    company,
    tree,
    isFetching: isFetchingPerformanceModal,
    isError: isErrorPerformanceModal,
    showRequestFeedback,
    revieweeName,
    questionIds
  } = usePerformanceModal({ categoryId: category.id, revieweeId });

  const isFetching = isFetchingPerformanceModal;
  const isError = isErrorPerformanceModal;
  const isReady = company && company.id && tree && tree.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }
  const isRequestFeedbackVisible = showRequestFeedback && showFeedbackTab;

  return (
    <Modal
      variant='custom'
      innerClasses='max-w-90-percent w-60rem shadow'
      close={() => close()}
    >
      <Base classes='shadow-none' loading={isFetching}>
        <div className='w-3/6 text-left inline-block align-top'>
          <h5 className='text-left font-bold'>
            Category:
            {name}
          </h5>
          <p>{`Based on ${category.reviews} review(s)`}</p>
        </div>
        <div className='w-3/6 text-right inline-block align-top'>
          <p className='mb-4 text-right text-5xl mt-4 text-purple font-bold'>
            {category.score || 'N/A'}
          </p>
          <p className='mb-4 text-purple text-lg font-bold'>
            {category.scoreLabel}
          </p>
        </div>

        <div className='text-left block'>
          <div>
            <a
              onClick={() => setActiveTab(TABS.QUESTIONS)}
              className={`inline-block hover:no-underline hover:text-black pb-2 px-2 mb-0 m-4 mt-0 ml-0 text-xl font-bold  ${
                activeTab === TABS.QUESTIONS
                  ? 'border-b-4 border-purple text-black'
                  : 'text-dark-grey border-0'
              }`}
            >
              Questions
            </a>
            {isRequestFeedbackVisible && showRequestFeedbackButton && (
              <a
                onClick={() => setActiveTab(TABS.REQUEST_FEEDBACK)}
                className={`inline-block hover:no-underline hover:text-black pb-2 mb-0 m-4 mt-0 text-xl font-bold ${
                  activeTab === TABS.REQUEST_FEEDBACK
                    ? 'border-b-4 border-purple text-black'
                    : 'text-dark-grey border-0'
                }`}
              >
                Request Feedback
              </a>
            )}
          </div>

          <div className='bg-background-gray py-4'>
            {activeTab === TABS.QUESTIONS ? (
              <ViewQuestions
                revieweeName={revieweeName}
                questionIds={questionIds}
              />
            ) : null}
            {isRequestFeedbackVisible && activeTab === TABS.REQUEST_FEEDBACK && (
              <RequestFeedback
                close={close}
                categoryName={name}
                revieweeId={revieweeId}
              />
            )}
          </div>
        </div>

        <div className='float-right'>
          <Button classes='float-left mt-4' variant='empty' onClick={close}>
            Close
          </Button>
        </div>
      </Base>
    </Modal>
  );
};

export default PerformanceCategoryModal;
