import React, { useContext } from 'react';
import { QuestionBuilderContext } from 'src/pagesDashboard/QuestionBuilder/context/QuestionBuilderProvider';
import { Radio } from 'src/components/';
import COMMON_QUESTION_CONSTANTS from 'common/commonQuestionConstants';
import { updateData } from 'src/pagesDashboard/QuestionBuilder/context/actions';
import CustomAnswers from 'src/pagesDashboard/QuestionBuilder/components/CustomAnswers';
import customAnswersUtils from 'src/pagesDashboard/QuestionBuilder/components/customAnswersUtils';
import { useCompany } from 'src/queries/company';
import commonQuestions from 'common/commonQuestions';

const { QUESTION_ANSWER_TYPES, ONE_TO_FIVE_CUSTOM, ONE_TO_TEN_CUSTOM } = COMMON_QUESTION_CONSTANTS;

const CustomQuestionAnswers = () => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany,
    refetch: refetchCompany
  } = useCompany();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError;

  if (!isReady) {
    return true;
  }

  const { context, dispatch } = useContext(QuestionBuilderContext);

  const onChange = (newType) => {
    const newAnswers = {
      ...context.answers,
      type: newType
    };

    const existingQuestionObj = context.questionObj
      ? commonQuestions.getQuestion(
        context.questionObj.id,
        company.questions.QUESTIONS
      )
      : null;

    if (
      !existingQuestionObj
      || existingQuestionObj.answers.type !== QUESTION_ANSWER_TYPES.CUSTOM
    ) {
      newAnswers.custom = customAnswersUtils.getDefaultCustomAnswers();
    }

    if (
      existingQuestionObj
      && existingQuestionObj.answers.type === QUESTION_ANSWER_TYPES.CUSTOM
    ) {
      newAnswers.custom = existingQuestionObj.answers.custom;
    }

    dispatch(updateData({ answers: newAnswers }));
  };

  return (
    <div className='mb-5'>
      <p>Answer Options</p>
      <div className='mb-3'>
        <Radio
          name='question_answer'
          onChange={(e) => {
            onChange(QUESTION_ANSWER_TYPES.ONE_TO_TEN);
          }}
          title='1 to 10'
          checked={context.answers.type === QUESTION_ANSWER_TYPES.ONE_TO_TEN}
        />
      </div>

      <div className='mb-3'>
        <Radio
          name='question_answer'
          onChange={(e) => {
            onChange(QUESTION_ANSWER_TYPES.ONE_TO_FIVE);
          }}
          title='1 to 5'
          checked={context.answers.type === QUESTION_ANSWER_TYPES.ONE_TO_FIVE}
        />
      </div>

      <div className='mb-3'>
        <Radio
          name='question_answer'
          onChange={(e) => {
            onChange(QUESTION_ANSWER_TYPES.FREE_TEXT);
          }}
          title='Free Text'
          checked={context.answers.type === QUESTION_ANSWER_TYPES.FREE_TEXT}
        />
      </div>

      <div className='mb-3'>
        <Radio
          name='question_answer'
          onChange={(e) => {
            onChange(QUESTION_ANSWER_TYPES.CUSTOM);
          }}
          title='Custom Labels (up to 7) (low to high)'
          checked={context.answers.type === QUESTION_ANSWER_TYPES.CUSTOM}
        />
      </div>
      {context.answers.type === QUESTION_ANSWER_TYPES.CUSTOM ? (
        <CustomAnswers />
      ) : null}
    </div>
  );
};

export default CustomQuestionAnswers;
