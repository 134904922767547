import React from 'react';
import COMMON_CONSTANTS from 'common/commonConstants';
import appUtils from 'src/components/appUtils';
import STYLE from 'src/constants/style';
import SidebarSettings from 'src/containers/Sidebar/SidebarSettings';
import SidebarInbox from 'src/containers/Sidebar/SidebarInbox';
import SidebarAnalytics from 'src/containers/Sidebar/SidebarAnalytics';
import SidebarPeople from 'src/containers/Sidebar/SidebarPeople';
import SidebarSupport from 'src/containers/Sidebar/SidebarSupport';
import LinesSVG from 'src/assets/svg/lines.svg';
import SidebarHeader from 'src/containers/Sidebar/SidebarHeader';
import { useCompany } from 'src/queries/company';
import { Base } from 'src/components/';
import FlagSVG from 'src/assets/svg/flag.svg';
import NavigationItem from 'src/pagesDashboard/HomePage/components/mobile/NavigationItem';
import TrendSVG from 'src/assets/svg/trend.svg';
import SpeechBubbleOvalSVG from 'src/assets/svg/speech-bubble-oval.svg';
import RequestFeedbackSVG from 'src/assets/svg/request-feedback.svg';
import SpeechBubbleSVG from 'src/assets/svg/speech-bubble.svg';
import { UserGroupIcon, UserIcon } from '@heroicons/react/24/outline';
import LogoutSVG from 'src/assets/log-out.svg';

const { ACCESS } = COMMON_CONSTANTS;

const MobileSidebar = ({ page, state }) => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError;

  if (!isReady) return null;

  const {
    styling: { logoUrl }
  } = company;

  const logoSrc = appUtils.getCompanyLogoSrc(logoUrl, 'black');

  const loggedUser = appUtils.getLoggedUser();
  const isSuperUser = appUtils.isSuperUser();
  const isAdmin = loggedUser.access === ACCESS.ADMIN;
  const isManager = loggedUser.access === ACCESS.MANAGER;
  const permissions = {
    isSuperUser,
    isAdmin,
    isManager
  };

  const { isExpanded, setIsExpanded } = state;

  const isPhone = appUtils.getMobileSize();

  return (
    <div>
      <div className='h-12 w-full flex justify-between items-center px-3 py-2 bg border border-t-0 border-l-0 border-r-0 border-b-2 mb-24 border-lightest-black fixed z-50 bg-white'>
        <img
          className='max-h-[100%] max-w-[130px]'
          src={logoSrc}
          alt='Company Logo'
        />
        {
          !isPhone ? (
            <button
              className='h-8 focus:outline-none z-50 mr-0.5'
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <LinesSVG />
            </button>
          ) : null
        }
      </div>
      {isExpanded && !isPhone ? (
        <div className={STYLE.MOBILE_SIDEBAR}>
          <SidebarHeader routeCallback={() => setIsExpanded(false)} />
          <div className='h-full overflow-y-auto overflow-x-hidden scrollbar-none w-full flex flex-col items-center'>
            <SidebarInbox
              page={page}
              permissions={permissions}
              routeCallback={() => setIsExpanded(false)}
            />
            <SidebarAnalytics
              page={page}
              permissions={permissions}
              routeCallback={() => setIsExpanded(false)}
            />
            <SidebarPeople
              page={page}
              permissions={permissions}
              routeCallback={() => setIsExpanded(false)}
            />
            <SidebarSettings
              page={page}
              permissions={permissions}
              routeCallback={() => setIsExpanded(false)}
            />
            <SidebarSupport routeCallback={() => setIsExpanded(false)} />
          </div>
        </div>
      ) : null}
      {
        isExpanded && isPhone ? (
          <Base classes={STYLE.MOBILE_CONTAINER}>
            <NavigationItem
              variant='home'
              title='My Feedback'
              subtitle='Read through feedback shared with you'
              url='/dashboard/me/feedback'
              routeCallback={() => setIsExpanded(false)}
            >
              <SpeechBubbleSVG />
            </NavigationItem>
            <NavigationItem
              variant='home'
              title='My Performance'
              subtitle='See your performance categories and scores'
              url='/dashboard/me/analytics'
              routeCallback={() => setIsExpanded(false)}
            >
              <TrendSVG />
            </NavigationItem>
            <NavigationItem
              variant='home'
              title='My Goals'
              subtitle='View your goals and set new ones'
              url='/dashboard/me/goals'
              routeCallback={() => setIsExpanded(false)}
            >
              <FlagSVG />
            </NavigationItem>
            <NavigationItem
              variant='home'
              title='Give Feedback'
              subtitle='Share feedback for your fellow team members'
              url='/dashboard/submit-feedback'
              routeCallback={() => setIsExpanded(false)}
            >
              <SpeechBubbleOvalSVG />
            </NavigationItem>
            <NavigationItem
              variant='home'
              title='Request Feedback'
              subtitle='Ask for feedback from your fellow team members'
              url='/dashboard/request-feedback'
              routeCallback={() => setIsExpanded(false)}
            >
              <RequestFeedbackSVG />
            </NavigationItem>
            <NavigationItem
              variant='home'
              title='People'
              subtitle='See who else is on your team'
              url='/dashboard/people'
              routeCallback={() => setIsExpanded(false)}
            >
              <UserGroupIcon className='w-6 h-6' />
            </NavigationItem>
            <NavigationItem
              variant='home'
              title='My Profile'
              subtitle='View and edit your personal information'
              url='/dashboard/settings'
              routeCallback={() => setIsExpanded(false)}
            >
              <UserIcon className='w-6 h-6' />
            </NavigationItem>
            <NavigationItem
              variant='home'
              title='Log Out'
              onClickFn={() => appUtils.logUserOut()}
            >
              <LogoutSVG className='w-6 h-6' />
            </NavigationItem>
          </Base>
        ) : null
      }
    </div>
  );
};

export default MobileSidebar;
