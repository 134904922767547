import React, { useState } from 'react';
import { useCompany, updateCompanyQuery } from 'src/queries/company';
import {
  Button, Base, Select, toast, Placeholder
} from 'src/components/';
import commonCompanyUtils, {
  requireCommentsOptions
} from 'common/commonCompanyUtils';
import STYLE from 'src/constants/style';
import { get } from 'lodash';

const defaultState = {
  requireComments: commonCompanyUtils.getRequireComments(),
  newRequireComments: commonCompanyUtils.getRequireComments()
};

const ReviewRules = () => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany,
    refetch: refetchCompany
  } = useCompany();

  const { mutateAsync: updateSettings, isLoading: updateSettingsLoading } = updateCompanyQuery();
  const [state, setState] = useState({
    requireComments: commonCompanyUtils.getRequireComments(
      get(company, 'settings.requireComments', defaultState.requireComments.id)
    ),
    newRequireComments: commonCompanyUtils.getRequireComments(
      get(company, 'settings.requireComments', defaultState.requireComments.id)
    )
  });

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError && !updateSettingsLoading;

  if (!isReady) {
    return <Placeholder variant='5' />;
  }

  const saveRequireComments = async () => {
    const { newRequireComments } = state;
    const res = await updateSettings({
      settings: {
        ...company.settings,
        requireComments: newRequireComments.id
      }
    });
    if (!res.success) {
      return toast.error(
        "We've encountered an issue. Please try again or contact us."
      );
    }
    toast.show('Updated!');
    refetchCompany();
  };

  const onScoreChange = (option) => {
    setState({ ...state, newRequireComments: option });
  };

  const updateDisabled = state.newRequireComments === state.requireComments;

  return (
    <Base classes={STYLE.CONTAINER_WHITE}>
      <h5 className='mb-4'>Reviews</h5>
      <div>
        <label className='inline-block'>
          <p className='inline-block pt-1 pl-0'>Require comments for reviews</p>
        </label>
        <Select
          classes='align-top ml-4 w-64 inline-block'
          options={requireCommentsOptions}
          title={state.newRequireComments.label}
          onChange={(option) => {
            onScoreChange(option);
          }}
        />
      </div>
      <div className='mb-1'>
        <Button
          variant='yellow'
          disabled={updateDisabled}
          onClick={saveRequireComments}
        >
          Update
        </Button>
      </div>
    </Base>
  );
};

export default ReviewRules;
