import React, { useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import HeaderV2 from 'src/containers/Header/HeaderV2';
import appUtils from 'src/components/appUtils';
import {
  usePulseReviewsDue,
  submitPulseReviewDueQuery
} from 'src/queries/pulse';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import {
  Base, toast, Button, Radio
} from 'src/components';
import { STYLE } from 'src/constants/style';
import PULSE_CONSTANTS from 'common/pulseConstants';
import COMMON_QUESTION_CONSTANTS from 'common/commonQuestionConstants';
import GroupSVG from 'src/assets/svg/group.svg';
import { useNavigate } from 'react-router-dom';

const { PULSE_ANONYMITY, PULSE_ANONYMITY_SHARE_OPTIONS } = PULSE_CONSTANTS;
const { QUESTION_ANSWER_TYPES } = COMMON_QUESTION_CONSTANTS;

const getAnonymous = (questionAnonymity, anonymousValue) => {
  if (questionAnonymity === PULSE_ANONYMITY.FORCE_ANONYMOUS) {
    return true;
  }
  if (questionAnonymity === PULSE_ANONYMITY.PREVENT_ANONYMOUS) {
    return false;
  }
  if (
    anonymousValue === PULSE_ANONYMITY_SHARE_OPTIONS.SHARE_ANONYMOUSLY.value
  ) {
    return true;
  }
  return false;
};

const PulseReviewsDue = () => {
  const {
    pulseReviews,
    isFetching: isFetchingPulseReviewsDue,
    isError: isErrorPulseReviewsDue,
    refetch: refetchPulseReviewsDue
  } = usePulseReviewsDue();
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const {
    mutateAsync: submitPulseReview,
    isLoading: submitPulseReviewLoading
  } = submitPulseReviewDueQuery();

  const isFetching = isFetchingCompany || isFetchingTree || isFetchingPulseReviewsDue;
  const isError = isErrorCompany || isErrorPulseReviewsDue || isErrorTree;
  const isReady = company && company.id && tree && tree.id && !isFetching && !isError;

  const navigate = useNavigate();

  const {
    watch,
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {
      comments: '',
      choice: null,
      anonymous: {
        label: PULSE_ANONYMITY_SHARE_OPTIONS.SHARE_OPENLY.label,
        value: PULSE_ANONYMITY_SHARE_OPTIONS.SHARE_OPENLY.value
      }
    }
  });

  if (!isReady) return null;
  if (!pulseReviews.length) {
    toast.show('No pulse reviews to complete.');
    return navigate(appUtils.getHomeRoute());
  }

  const anonymityOptions = [
    {
      label: PULSE_ANONYMITY_SHARE_OPTIONS.SHARE_OPENLY.label,
      value: PULSE_ANONYMITY_SHARE_OPTIONS.SHARE_OPENLY.value
    },
    {
      label: PULSE_ANONYMITY_SHARE_OPTIONS.SHARE_ANONYMOUSLY.label,
      value: PULSE_ANONYMITY_SHARE_OPTIONS.SHARE_ANONYMOUSLY.value
    }
  ];

  const pulseReview = pulseReviews[0];

  const {
    questionText, questionChoices, questionAnonymity, questionType
  } = pulseReview;

  const isFreeTextAnswerType = questionType === PULSE_CONSTANTS.TYPES.FREE;

  const submit = async (values) => {
    const { comments, choice, anonymous } = values;
    const data = {
      pulseId: pulseReview.id,
      answer: isFreeTextAnswerType ? null : choice.label,
      score: isFreeTextAnswerType ? null : choice.score,
      comments,
      anonymous: getAnonymous(questionAnonymity, anonymous.value),
      companyid: company.id
    };
    const result = await submitPulseReview(data);

    if (!result || !result.success) {
      toast.error('Uh oh, we ran into an issue. Please try again later!');
    }
    toast.show('Thank you!');

    const {
      data: { pulseReviews: newPulseReviews }
    } = await refetchPulseReviewsDue();
    if (!newPulseReviews.length) {
      toast.show('You have completed all pulse reviews!');
      navigate(appUtils.getHomeRoute());
    }
    reset();
    appUtils.scrollToTop();
  };

  const { comments, choice } = watch();
  const isCommentsTooShort = comments.length <= 10;
  const isSubmitDisabled = (!isFreeTextAnswerType || isCommentsTooShort)
    && (!choice || isCommentsTooShort);

  const pulseReviewsLength = pulseReviews.length;

  const getPulseNumericChoices = (num) => {
    const choices = [];
    for (let i = 1; i <= num; i += 1) {
      choices.push({ label: i, encodedLabel: encodeURIComponent(i), score: i });
    }
    return choices;
  };

  const getPulseCustomChoices = (options) => {
    const choices = [];
    for (let i = 0; i < options.length; i += 1) {
      choices.push({
        label: options[i].label,
        encodedLabel: encodeURIComponent(options[i].label),
        score: options[i].score
      });
    }
    return choices;
  };

  const getChoices = () => {
    if (
      questionType === PULSE_CONSTANTS.TYPES.CUSTOM
      && Array.isArray(questionChoices)
    ) {
      const choices = getPulseCustomChoices(questionChoices);
      return choices;
    }
    if (questionType === PULSE_CONSTANTS.TYPES.MC) {
      if (questionChoices === QUESTION_ANSWER_TYPES.ONE_TO_FIVE) {
        const choices = getPulseNumericChoices(5);
        return choices;
      }
      if (questionChoices === QUESTION_ANSWER_TYPES.ONE_TO_TEN) {
        const choices = getPulseNumericChoices(10);
        return choices;
      }
    }
    if (questionType === PULSE_CONSTANTS.TYPES.NPS) {
      const choices = getPulseNumericChoices(10);
      return choices;
    }
  };

  return (
    <div className='mt-4'>
      <HeaderV2
        overtitle={
          pulseReviewsLength > 1
            ? `Pulse Reviews Due: ${pulseReviewsLength} left`
            : 'Pulse Review Due'
        }
        title='Pulse'
      />
      <Base classes={STYLE.BASE}>
        <Base
          classes={`${STYLE.CONTAINER_WHITE_PADDINGLESS} h-full`}
          loading={submitPulseReviewLoading}
        >
          <form
            className='flex flex-col items-center h-full'
            onSubmit={handleSubmit(submit)}
          >
            <div className='mb-20 pb-6 flex flex-col items-center'>
              <div className='w-16 mt-10 h-16 rounded-full bg-purple flex justify-center items-center transition duration-300'>
                <GroupSVG className='w-10 h-10' />
              </div>
              <div className='text-center text-4xl text-black font-bold mt-4'>
                Pulse Question
              </div>
            </div>
            <div className='mx-auto w-5/6'>
              <div className='mb-8 flex flex-col font-bold md:flex-row'>
                <div className='uppercase text-gray-500 text-xl mb-2 md:w-2/5'>
                  Question
                </div>
                <div className='text-lg md:w-3/5'>{questionText}</div>
              </div>
              {!isFreeTextAnswerType ? (
                <div className='flex flex-col mb-8 md:flex-row'>
                  <div className='mb-2 md:w-2/5 uppercase text-gray-500 font-bold text-xl'>
                    Answer
                  </div>
                  <div className='md:w-3/5'>
                    {getChoices().map((option, index) => (
                      <div key={index} className='mb-2 font-bold flex'>
                        <Controller
                          name='choice'
                          control={control}
                          rules={{ required: false }}
                          render={({ field: { value, onChange } }) => (
                            <Radio
                              name='choice'
                              value={value}
                              title={option.label}
                              checked={value?.label === option.label}
                              onChange={() => onChange(option)}
                            />
                          )}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
              <div className='mb-8 flex flex-col md:flex-row'>
                <div className='text-gray-500 mb-2 md:w-2/5'>
                  <div className='uppercase font-bold text-xl mb-1'>
                    Comments
                  </div>
                  <div className='text-base pr-6'>
                    Give an example of what you think
                  </div>
                </div>
                <div className='flex flex-col md:w-3/5 '>
                  <textarea
                    maxLength='2000'
                    {...register('comments', {
                      minLength: {
                        value: 10,
                        message:
                          'Please provide a longer comment to answer this question.'
                      }
                    })}
                    className='h-36 text-base mb-1'
                    placeholder='Please provide constructive feedback.'
                  />
                  <div className='text-red whitespace-no-wrap flex items-center'>
                    {errors?.comments?.message}
                  </div>
                </div>
              </div>
              <div className='flex flex-col mb-20 md:flex-row'>
                <div className='uppercase text-gray-500 font-bold text-xl mb-2 md:w-2/5'>
                  Anonymity options
                </div>
                <div className='md:w-3/5'>
                  {questionAnonymity === PULSE_ANONYMITY.ALLOW_ANONYMOUS
                    ? anonymityOptions.map((option, index) => (
                      <div key={index} className='mb-0.5 font-bold flex'>
                        <Controller
                          name='anonymous'
                          control={control}
                          rules={{ required: false }}
                          render={({ field: { value, onChange } }) => (
                            <Radio
                              name='anonymous'
                              value={value}
                              title={option.label}
                              checked={value?.value === option.value}
                              onChange={() => onChange(option)}
                            />
                          )}
                        />
                        <div>
                          <p className='text-dark-grey'>{option.comments}</p>
                        </div>
                      </div>
                    ))
                    : null}
                </div>
              </div>
            </div>
            <div className='flex justify-end items-end w-5/6'>
              <div className='pb-8 mr-0'>
                <Button
                  classes='w-32'
                  disabled={isSubmitDisabled}
                  variant='yellow'
                  onClick={handleSubmit(submit)}
                >
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </Base>
      </Base>
    </div>
  );
};

export default PulseReviewsDue;
