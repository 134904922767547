import React, { useContext, useState } from 'react';
import { round, isFinite, get } from 'lodash';
import { Base } from 'src/components/';
import TopScoreBox from 'src/componentsAdvanced/TopScoreBox/TopScoreBox';
import { useTeamProfile } from 'src/pagesDashboard/DashTeam/queries';
import { useCompany } from 'src/queries/company';
import { useCompanyFeedbackCount } from 'src/pagesDashboard/UserProfile/queries';
import { DashTeamContext } from 'src/pagesDashboard/DashTeam/context/DashTeamProvider';
import STYLE from 'src/constants/style';
import DASH_CONSTANTS from 'src/constants/dashboard';
import { InfoModal } from 'src/componentsTailwind/index';

const { DASH_TILES_INFO } = DASH_CONSTANTS;

const TopScores = () => {
  const { context } = useContext(DashTeamContext);
  const {
    teamScore,
    activityScores,
    isFetching: isFetchingTeamProfile,
    isError: isErrorTeamProfile
  } = useTeamProfile();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const {
    pagination: feedbackPagination,
    isFetching: isFetchingFeedbackCount,
    isError: isErrorFeedbackCount
  } = useCompanyFeedbackCount({
    ...context.range,
    includeUnassignedUsers: true,
    managerId: context.managerId,
    options: {
      size: 1,
      sortBy: 'createdAt',
      page: 1,
      joinReviews: false
    }
  });

  const [showInfoModal, setShowInfoModal] = useState(false);
  const [infoTitle, setInfoTitle] = useState('');
  const [infoContent, setInfoContent] = useState('');

  const isFetching = isFetchingCompany || isFetchingTeamProfile || isFetchingFeedbackCount;
  const isError = isErrorCompany || isErrorTeamProfile || isErrorFeedbackCount;
  const isReady = company && company.id && teamScore && !isFetching && !isError;

  if (!isReady) {
    return <Base classes='h-36 mb-4 w-full' loading />;
  }

  const feedbackRateText = teamScore?.participationStats?.feedbackRate >= 0
    ? `${teamScore?.participationStats?.feedbackRate}%`
    : '-';

  const allFeedbackCount = get(teamScore, 'participationStats.rich', 0)
    + get(feedbackPagination, 'total', 0);

  const openInfoModal = (tileInfo) => {
    setInfoTitle(tileInfo.label);
    setInfoContent(tileInfo.content);
    setShowInfoModal(true);
  };

  return (
    <Base classes={STYLE.CONTAINER_BLUE}>
      <div className='grid sm:grid-cols-2 lg:grid-cols-4 gap-3'>
        <InfoModal
          close={() => setShowInfoModal(false)}
          isOpen={showInfoModal}
          title={infoTitle}
          content={infoContent}
        />
        <TopScoreBox
          classes='cursor-pointer'
          subtitle={teamScore?.overview?.label || '-'}
          title='Score'
          value={teamScore?.overview?.score || '-'}
          tooltip='Overall Score'
          onClick={() => openInfoModal(DASH_TILES_INFO.TEAM.SCORE)}
        />
        <TopScoreBox
          classes='cursor-pointer'
          title='Reviews'
          value={teamScore?.participationStats?.reviewed || '-'}
          tooltip='Reviews'
          onClick={() => openInfoModal(DASH_TILES_INFO.TEAM.REVIEWS)}
        />
        <TopScoreBox
          classes='cursor-pointer'
          title='Feedback'
          value={allFeedbackCount || '-'}
          tooltip='Feedback'
          onClick={() => openInfoModal(DASH_TILES_INFO.TEAM.FEEDBACK)}
        />
        <TopScoreBox
          classes='cursor-pointer'
          title='Reviews With Comments'
          value={feedbackRateText}
          tooltip='Reviews With Comments'
          onClick={() => openInfoModal(DASH_TILES_INFO.TEAM.REVIEWS_RATE)}
        />
        <TopScoreBox
          classes='cursor-pointer'
          title='Avg. Score Given'
          value={
            activityScores.scoredGivenCount ? activityScores.avgScoreGiven : '-'
          }
          tooltip='Overall score given'
          onClick={() => openInfoModal(DASH_TILES_INFO.TEAM.AVG_SCORE_GIVEN)}
        />
        <TopScoreBox
          classes='cursor-pointer'
          title='Reviews Completed'
          value={activityScores.reviewedGivenCount || '-'}
          tooltip='Reviews given'
          onClick={() => openInfoModal(DASH_TILES_INFO.TEAM.REVIEWS_COMPLETED)}
        />
        <TopScoreBox
          classes='cursor-pointer'
          title='Avg. Sentiment'
          value={
            isFinite(activityScores.avgSentimentReceived)
              ? round(activityScores.avgSentimentReceived)
              : '-'
          }
          tooltip='(Negative) -100 to 100 (Positive)'
          onClick={() => openInfoModal(DASH_TILES_INFO.TEAM.AVG_SENTIMENT)}
        />
        <TopScoreBox
          classes='cursor-pointer'
          title='Avg. Sentiment Given'
          value={
            isFinite(activityScores.avgSentimentGiven)
              ? round(activityScores.avgSentimentGiven)
              : '-'
          }
          tooltip='(Negative) -100 to 100 (Positive)'
          onClick={() => openInfoModal(DASH_TILES_INFO.TEAM.AVG_SENTIMENT_GIVEN)}
        />
      </div>
    </Base>
  );
};

export default TopScores;
