import React from 'react';
import { Bar } from 'react-chartjs-2';

const options = {
  layout: {
    padding: {
      right: 5,
      left: 5
    }
  },
  scales: {
    x: {
      border: {
        display: false
      },
      grid: {
        display: false
      }
    },
    y: {
      border: {
        display: false
      },
      grid: {
        display: false
      }
    }
  }
};

const BarChart = ({ data }) => console.log(data) && (
  <div className='chart-container relative'>
    <Bar type='bar' data={data} options={options} />
  </div>
);

export default BarChart;
