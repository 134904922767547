import React from 'react';
import { Base, toast } from 'src/components/';
import { usePulseReviews, usePulseQuestions } from 'src/queries/pulse';
import { useTree } from 'src/queries/tree';
import commonDateUtils from 'common/commonDateUtils';
import commonTreeUtils from 'common/commonTreeUtils';
import STYLE from 'src/constants/style';
import PULSE_CONSTANTS from 'common/pulseConstants';

const { PULSE_QUESTION_VISIBILITY } = PULSE_CONSTANTS;

const PendingPulseReviews = () => {
  const {
    data: pulseReviews,
    isFetching: isFetchingPulseReviews,
    isError: isErrorPulseReviews
  } = usePulseReviews({});
  const {
    data: pulseQuestions,
    isFetching: isFetchingPulseQuestions,
    isError: isErrorPulseQuestions
  } = usePulseQuestions({
    filters: {
      visibility: PULSE_QUESTION_VISIBILITY.DEFAULT
    }
  });
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const isFetching = isFetchingPulseQuestions || isFetchingPulseReviews || isFetchingTree;
  const isError = isErrorPulseQuestions || isErrorPulseReviews || isErrorTree;
  const isReady = tree
    && tree.id
    && pulseReviews
    && pulseReviews.list
    && pulseQuestions
    && !isFetching
    && !isError;

  if (!isReady) {
    return null;
  }

  const pulseReviewList = pulseReviews.list.sort((a, b) => (parseFloat(a.timestamp) < parseFloat(b.timestamp) ? 1 : -1));

  return (
    <Base classes={STYLE.CONTAINER_WHITE}>
      <h5 className='font-bold text-xl'>Superuser only</h5>

      <div className='mt-6'>
        <div className='mb-2 py-1 px-1 bg-background-gray text-black font-bold rounded-sm cursor-default'>
          <p className='mb-0 pl-1 w-2/12 inline-block font-bold'>User</p>
          <p className='mb-0 pl-1 w-2/12 inline-block font-bold'>Question</p>
          <p className='mb-0 w-2/12 inline-block font-bold'>Status</p>
          <p className='mb-0 w-2/12 inline-block font-bold'>Your Local Date</p>
          <p className='mb-0 w-2/12 inline-block font-bold'>UTC Date</p>
        </div>
      </div>
      {pulseReviewList
        ? pulseReviewList.map((reviewObj, index) => {
          const reviewerTree = commonTreeUtils.findNodeById(
            tree,
            reviewObj.reviewerId
          );
          const questionObj = pulseQuestions.find(
            (pulseQuestion) => reviewObj.questionId.toString() === pulseQuestion.id.toString()
          );
          return (
            <div key={index}>
              <p className='w-2/12 inline-block'>
                {reviewerTree ? reviewerTree.name : '-'}
              </p>
              <p className='w-2/12 inline-block'>
                {questionObj ? questionObj.text : ''}
              </p>
              <p className='w-2/12 inline-block'>{reviewObj.status}</p>
              <p className='w-2/12 inline-block'>
                {commonDateUtils.unixToMonthDayYearTimeFormat(
                  reviewObj.timestamp ?? reviewObj.lastUpdated
                )}
              </p>
              <p className='w-2/12 inline-block'>
                {commonDateUtils.unixToMonthDayYearTimeFormatUTC(
                  reviewObj.timestamp ?? reviewObj.lastUpdated
                )}
                {/*
                  <button className="text-purple hover:font-bold mr-4" onClick={() => { pulseReviewAction(reviewObj, 'retry') }}>Retry</button>
                  <button className="text-purple hover:font-bold" onClick={() => { expirePulseReview(reviewObj, 'expire') }}>Expire</button>
                  */}
              </p>
            </div>
          );
        })
        : null}
    </Base>
  );
};

export default PendingPulseReviews;
