import React from 'react';
import commonDateUtils from 'common/commonDateUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import appUtils from 'src/components/appUtils';
import { useAccount } from 'src/queries/account';

const { DATE_RANGE_FILTERS } = COMMON_CONSTANTS;

const NoSummaryCard = ({ account }) => {
  const loggedUserId = appUtils.getLoggedUserId();
  const {
    data: userAccount,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount(account);

  const isFetching = isFetchingAccount;
  const isError = isErrorAccount;
  const isReady = userAccount && userAccount._id && !isFetching && !isError;

  if (!isReady) return null;

  const startDate = commonDateUtils.getDateFromDaysAgo(
    DATE_RANGE_FILTERS.LAST_MONTH.days
  ).unix;
  const endDate = commonDateUtils.getUnixDateNow();

  const startMonthYear = commonDateUtils.unixToMonthYear(startDate);
  const endMonthYear = commonDateUtils.unixToMonthYear(endDate);

  const isSelf = loggedUserId === account;

  return (
    <div className='ql-editor'>
      <div className='flex justify-between'>
        <h1 className='text-purple text-xl mb-0 p-0'>WorkStory AI Assist</h1>
        <a
          className='text-purple mb-0'
          href='https://home.workstory.team/faq/what-is-workstory-ai-assist'
          target='_blank'
          rel='noreferrer'
        >
          Have Questions?
        </a>
      </div>

      <h2 className='p-0 text-lg'>Performance Summary</h2>
      <p className='text-sm p-0 mt-0 mb-1'>
        {startMonthYear}
        {' - '}
        {endMonthYear}
      </p>
      <hr className='mt-0 mb-2 border-[#eeeeee] bg-[#eeeeee]' />

      <p className='mb-2'>
        {isSelf
          ? 'You do not have enough historic reviews to generate a summary.'
          : 'Team member does not have enough historic reviews to generate a summary.'}
      </p>

      <p className='mb-2'>
        In order for us generate an accurate summary for
        {' '}
        {userAccount.name}
        ,
        they must meet the following criteria:
      </p>
      <ul className='ml-4 mb-0 list-disc'>
        <li>At least 25 reviews in this date range</li>
        <li>
          At least 10 review comments (each with a minimum of 40 characters) in
          this date range
        </li>
      </ul>
    </div>
  );
};

export default NoSummaryCard;
