import React, { useEffect, useState } from 'react';
import { get, isEmpty } from 'lodash';
import { useReport } from 'src/queries/reports';
import { useUserScore } from 'src/queries/score';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import { CategoryNewReport } from 'src/pagesDashboard/NewUserReport/components';
import populateCategories, {
  populateCategoryData
} from 'src/utils/populateCategories';

const ReportCategoriesSection = ({
  reportId,
  userId,
  isEditorOpenByDefault,
  setIsLoading = () => {},
  viewOnly = false
}) => {
  const { data: report } = useReport(reportId);

  const { data: company } = useCompany();

  const previousReport = report.previousReportData;

  const { data: userScore } = useUserScore({
    userId,
    filters: {
      reportId,
      start: report.start,
      end: report.end,
      roles: report.roles,
      reviewerGroup: report.reviewerGroup,
      reviewerIds: report.reviewerIds
    },
    options: {
      isReport: reportId
    }
  });

  const { data: userScorePreviousReport } = useUserScore({
    userId,
    filters: previousReport
      ? {
        reportId: report?.previousReport,
        start: previousReport.start,
        end: previousReport.end,
        roles: previousReport.roles,
        reviewerGroup: previousReport.reviewerGroup,
        reviewerIds: previousReport.reviewerIds
      }
      : {},
    options: {
      isReport: report.previousReport
    }
  });

  const {
    data: { myTreeRow }
  } = useTree();

  const [renderedCategories, setRenderedCategories] = useState([]);
  const [myCategories, setMyCategories] = useState([]);

  useEffect(() => {
    if (userScore.roles && company && userScore) {
      const categories = populateCategories(
        userScore.roles,
        company,
        userScore,
        { showBundledCategories: false },
        reportId
      );
      setMyCategories(categories);
    }
  }, [userScore.roles, company, userScore, reportId]);

  useEffect(() => {
    if (
      myCategories
      && (!report.previousReport
        || (report.previousReport && !isEmpty(previousReport)))
    ) {
      const userRoles = get(myTreeRow, 'roles', []);
      const { roles: rolesData } = populateCategoryData(
        userScore,
        company,
        null,
        false,
        {
          userRoles
        }
      );
      const categories = myCategories.map((category) => ({
        ...category,
        roleLabels: rolesData
          .filter((role) => role.categories.some(
            (cat) => cat.id.toString() === category.id.toString()
          ))
          .map((role) => role.label)
      }));

      const { roles: previousRolesData } = populateCategoryData(
        userScorePreviousReport,
        company,
        null,
        false,
        {
          userRoles
        }
      );
      const previousCategories = previousReport
        ? myCategories.map((category) => ({
          ...category,
          roleLabels: previousRolesData
            .filter((role) => role.categories.some(
              (cat) => cat.id.toString() === category.id.toString()
            ))
            .map((role) => role.label)
        }))
        : [];

      const newCategories = [];
      categories.forEach((c) => {
        if (!newCategories.find((n) => n.id === c.id)) {
          const previousCategory = previousCategories.find(
            (n) => n.id === c.id
          );
          if (previousCategory) {
            newCategories.push({ ...c, previousCategory });
          } else {
            newCategories.push(c);
          }
        }
      });
      setRenderedCategories(newCategories);
    }
  }, [myCategories.length, report.previousReport]);

  const isRenderReady = myCategories && userScore;

  if (!isRenderReady) return <div className='h-full' />;

  return (
    <div className='mt-5'>
      {renderedCategories.map((category) => (
        <CategoryNewReport
          category={category}
          previousCategory={category.previousCategory}
          reportId={reportId}
          previousReport={report.previousReport}
          userId={userId}
          roleLabels={category.roleLabels}
          isEditorOpenByDefault={isEditorOpenByDefault}
          viewOnly={viewOnly}
          start={report.start}
          end={report.end}
          key={category.id}
        />
      ))}
      {!renderedCategories.length ? (
        <div className='flex justify-center mt-10'>
          <p className='text-lg'>
            No category data available for this time range
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default ReportCategoriesSection;
