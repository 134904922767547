import React from 'react';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  QUERY_KEYS as FEEDBACK_QUERY_KEYS,
  useFeedbackUpdate
} from 'src/queries/feedback';
import { toast } from 'src/components';
import { AlertModal } from 'src/componentsTailwind/index';

const DeleteFeedbackModal = ({ data: feedback, close }) => {
  const navigate = useNavigate();
  const { state: historyState } = useLocation();
  const queryClient = useQueryClient();

  const { update: updateFeedback, isLoading: isLoadingDeleteFeedback } = useFeedbackUpdate(feedback._id || feedback.id);

  const deleteFn = async () => {
    const response = await updateFeedback({
      data: { enabled: false }
    });
    if (!response || !response.success) {
      console.error('Failed to delete a feedback', {
        response,
        feedback
      });
      return toast.error("Oops, we've run into an issue. Try again later!");
    }

    toast.show('Feedback deleted');

    queryClient.invalidateQueries(FEEDBACK_QUERY_KEYS.FEEDBACK);
    queryClient.invalidateQueries(FEEDBACK_QUERY_KEYS.FEEDBACK_FEED);
    return navigate(historyState?.returnTo ?? -1);
  };

  return (
    <AlertModal
      isLoading={isLoadingDeleteFeedback}
      isOpen
      close={close}
      onAction={deleteFn}
      title='Are you sure you want to delete this feedback?'
      content='Deleted feedback is saved and visible to organization admins.'
    />
  );
};

export default DeleteFeedbackModal;
