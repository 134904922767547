import React from 'react';
import commonTreeUtils from 'common/commonTreeUtils';
import HeaderV2 from 'src/containers/Header/HeaderV2';
import { Circle, Base } from 'src/components/';
import { useTree } from 'src/queries/tree';
import { useAccount } from 'src/queries/account';
import CircleV2 from 'src/components/Circle/CircleV2';

const ProfileHeader = ({ userId }) => {
  const {
    data: account,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount(userId);
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const isFetching = isFetchingAccount || isFetchingTree;
  const isError = isErrorAccount || isErrorTree;
  const isReady = account && account._id && tree && tree.id && !isFetching && !isError;
  if (!isReady) {
    return null;
  }
  const userTree = userId && tree.id ? commonTreeUtils.findNodeById(tree, userId) : {};
  if (!userTree) {
    console.error('user tree not found', { userId, account });
  }
  const getWebsiteLink = (websiteLink) => {
    if (!websiteLink.includes('http://') && !websiteLink.includes('https://')) {
      return `https://${websiteLink}`;
    }
    return websiteLink;
  };

  return (
    <Base
      classes='w-full text-white rounded-md relative z-1'
      loading={isFetching}
      variant='transparent'
    >
      <HeaderV2
        overtitle='Dashboards'
        title={account.name}
        icon={<CircleV2 imageUrl={account.imageUrl} size='xs' />}
      />
    </Base>
  );
};

export default ProfileHeader;
