import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavigationItem from 'src/pagesDashboard/HomePage/components/mobile/NavigationItem';
import {
  Base,
  Multiselect,
  Select,
  NewInput,
  SimpleDatePicker,
  Circle,
  Button
} from 'src/components/';
import STYLE from 'src/constants/style';
import { UserIcon } from '@heroicons/react/24/outline';
import COMMON_CONSTANTS from 'common/commonConstants';
import MobileSelectGroups from 'src/containers/UserProfile/Profile/components/mobile/SelectGroups';
import commonDateUtils from 'common/commonDateUtils';
import sharedDateUtils from 'src/common/sharedDateUtils';

const { COLORS, ACCESS, USER_STATE } = COMMON_CONSTANTS;

const MobileProfileTab = ({
  isMutating,
  canUpdate,
  updateData,
  newData,
  oldData,
  roleOptions,
  isAdmin,
  isRoot,
  company,
  loadingProps,
  manageUsersProps,
  managerProps,
  renderWarningProps,
  isButtonDisabled,
  onUpload,
  onUpdate
}) => {
  const {
    isFetchingCurrentManageAccountsList,
    isFetchingManageAccountsList,
    isFetchingEmailAccount,
    isFetchingManagerList,
    isUpdating
  } = loadingProps;

  const {
    manageAccountsOptions,
    setManageAccountsSearchText
  } = manageUsersProps;

  const {
    managerOptions,
    selectedRoot,
    managerData,
    setSelectedRoot,
    setNewData,
    setManagerData,
    setManagerSearchText
  } = managerProps;

  const {
    renderRootWarning,
    renderManagerWarning
  } = renderWarningProps;

  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(oldData.startDate);

  const parsedNewStartDate = startDate
    ? commonDateUtils.unixToDateWithTimezone(startDate)
    : null;

  const selectDate = (date) => {
    setStartDate(sharedDateUtils.dateToUnix(date));
  };

  const activeOptions = [
    { id: 1, label: USER_STATE.ACTIVE },
    { id: 2, label: USER_STATE.INACTIVE },
    { id: 3, label: USER_STATE.PASSIVE }
  ];
  const accessOptions = [
    { id: ACCESS.BASIC, label: ACCESS.BASIC },
    { id: ACCESS.MANAGER, label: ACCESS.MANAGER },
    { id: ACCESS.ADMIN, label: ACCESS.ADMIN }
  ];
  const canLoginOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
  ];

  const isInputDisabled = isMutating || isFetchingEmailAccount || !canUpdate;

  return (
    <Base
      classes={STYLE.MOBILE_CONTAINER}
    >
      <div className='w-full fixed top-0 mt-12 bg-white z-50'>
        <NavigationItem
          goBack={() => navigate('/dashboard/home')}
          title='My Profile'
        >
          <UserIcon className='w-6 h-6' />
        </NavigationItem>
      </div>
      <div className='px-3 mt-36 mb-40'>
        <div className='w-full flex justify-center'>
          <Circle
            size='2xl'
            onUpload={onUpload}
            imageUrl={newData.imageUrl}
            isDisabled={isInputDisabled}
          />
        </div>
        <div className='mb-4 mt-2'>
          <p className='mb-1 mt-3'>First Name</p>
          <input
            className='w-full bg-white border border-0 rounded-md ring-1 ring-inset ring-gray-300 h-40px px-3'
            placeholder='Johnny'
            type='text'
            onChange={(e) => updateData('firstName', e.target.value)}
            value={newData.firstName}
            disabled={isInputDisabled}
          />
        </div>
        <div className='mb-4 mt-2'>
          <p className='mb-1 mt-3'>Last Name</p>
          <input
            className='w-full bg-white border border-0 rounded-md ring-1 ring-inset ring-gray-300 h-40px px-3'
            placeholder='Silverhand'
            type='text'
            onChange={(e) => updateData('lastName', e.target.value)}
            value={newData.lastName}
            disabled={isInputDisabled}
          />
        </div>
        <div className='mb-4 mt-2'>
          <p className='mb-1 mt-3'>Email</p>
          <input
            className='w-full bg-white border border-0 rounded-md ring-1 ring-inset ring-gray-300 h-40px px-3'
            placeholder='johnnysilverhand@workstory.com'
            type='text'
            onChange={(e) => updateData('email', e.target.value)}
            value={newData.email}
            disabled={isInputDisabled}
          />
        </div>
        <div className='mb-4 mt-2'>
          <p className='mb-1 mt-3'>Title</p>
          <input
            className='w-full bg-white border border-0 rounded-md ring-1 ring-inset ring-gray-300 h-40px px-3'
            placeholder='Frontend Developer'
            type='text'
            onChange={(e) => updateData('title', e.target.value)}
            value={newData.title}
            disabled={isInputDisabled}
          />
        </div>
        <div className='mb-4 mt-2'>
          <p className='mb-1 mt-3'>Role(s)</p>
          <Multiselect
            multiSelectSelectedItemClasses='w-8.5rem'
            options={roleOptions}
            placeholder='Select roles'
            other={{
              variant: 'sm',
              size: 300
            }}
            multiselect
            onChange={(option, type) => {
              if (type === 'select') {
                const newRoles = option.checked
                  ? newData.roles.filter((r) => r !== option.id)
                  : [...newData.roles, option.id];
                updateData('roles', newRoles);
              }
            }}
            disabled={isInputDisabled}
          />
        </div>
        <div className='mb-4 mt-2'>
          <p className='mb-1 mt-3'>Manage Users</p>
          <Multiselect
            textColor={COLORS['tertiary-black']}
            loading={
              isFetchingCurrentManageAccountsList
              || isFetchingManageAccountsList
            }
            multiSelectSelectedItemClasses='w-8.5rem'
            options={manageAccountsOptions}
            placeholder='Select users'
            other={{
              variant: 'sm',
              size: 300
            }}
            multiselect
            onDropdownClose={() => setManageAccountsSearchText('')}
            onSearch={(value) => setManageAccountsSearchText(value)}
            onChange={(option, type) => {
              if (type === 'select') {
                const newManageAccounts = option.checked
                  ? newData.manageAccounts.filter((r) => r !== option.id)
                  : [...newData.manageAccounts, option.id];
                updateData('manageAccounts', newManageAccounts);
              }
            }}
            disabled={isInputDisabled}
          />
        </div>
        {isAdmin ? (
          <div>
            <MobileSelectGroups />
            <div className='mb-4 mt-2'>
              <p className='mb-1 mt-3'>{isRoot ? 'Switch primary account' : 'Reports to'}</p>
              <Select
                classes='w-full'
                placeholder='Manager'
                options={managerOptions}
                title={isRoot ? selectedRoot.name : managerData.name}
                onChange={({ data }) => {
                  if (isRoot) {
                    setSelectedRoot(data);
                  } else {
                    setNewData({
                      ...newData,
                      managerId: data._id,
                      managerEmail: data.email
                    });
                    setManagerData(data);
                  }
                }}
                loading={isFetchingManagerList}
                showSearch
                onSearch={(value) => setManagerSearchText(value)}
                onDropdownClose={() => setManagerSearchText('')}
                disabled={isInputDisabled}
              />
            </div>
          </div>
        ) : null}
        <div className='text-left'>
          {isRoot
            ? renderRootWarning(
              selectedRoot._id !== newData._id,
              selectedRoot
            )
            : renderManagerWarning(oldData, newData, managerData)}
        </div>
        <div className='mb-4 mt-2'>
          <p className='mb-1 mt-3'>Website link</p>
          <input
            className='w-full bg-white border border-0 rounded-md ring-1 ring-inset ring-gray-300 h-40px px-3'
            placeholder='Website link'
            type='text'
            onChange={(e) => updateData('websiteLink', e.target.value)}
            value={newData.websiteLink}
            disabled={isInputDisabled}
          />
        </div>
        <div className='mb-4 mt-2'>
          <p className='mb-1 mt-3'>Employee start date</p>
          <SimpleDatePicker
            date={parsedNewStartDate}
            onSelect={selectDate}
            inputClasses='bg-white w-full'
            properties={{
              dropdowns: {
                minYear: 1950,
                years: true,
                months: true
              }
            }}
            disabled={isInputDisabled}
            placeholder='Date Picker'
          />
        </div>
        <div className='mb-4 mt-2'>
          <p className='mb-1 mt-3'>Access *</p>
          {isAdmin ? (
            <Select
              options={accessOptions}
              disabled={isMutating || isFetchingEmailAccount}
              classes='w-full'
              title={newData.access}
              onChange={(option) => updateData('access', option.id)}
            />
          ) : (
            <NewInput
              type='text'
              classes='inline-block w-full'
              disabled
              variant='white'
              value={newData.access}
            />
          )}
        </div>
        {newData.access !== oldData.access
          && (newData.access === ACCESS.MANAGER
            || newData.access === ACCESS.ADMIN) && (
            <div className='mb-6 mx-auto text-left'>
              <div className='bg-red-warning mx-auto p-3 rounded inline-block  max-w-32rem'>
                {newData.access === ACCESS.MANAGER
                  ? `${
                    newData.name || 'This user'
                  } will have access to historic review information for anyone on their team`
                  : `${
                    newData.name || 'This user'
                  } will have access to historic review information for anyone in the organization`}
              </div>
            </div>
        )}
        {canUpdate ? (
          <>
            <div className='mb-4 mt-2'>
              <p className='mb-1 mt-3'>Status *</p>
              {canUpdate ? (
                <Select
                  options={activeOptions}
                  disabled={isMutating || isFetchingEmailAccount}
                  classes='w-full'
                  title={newData.status}
                  dataCy='active-select'
                  onChange={(option) => updateData('status', option.label)}
                />
              ) : (
                <NewInput
                  type='text'
                  classes='inline-block w-full'
                  disabled
                  variant='white'
                  value={newData.status}
                />
              )}
            </div>
            <div className='mb-4 mt-2'>
              <p className='mb-1 mt-3'>User can login *</p>
              {canUpdate ? (
                <Select
                  drop='up'
                  disabled={isMutating || isFetchingEmailAccount}
                  options={canLoginOptions}
                  classes='w-full'
                  title={
                    canLoginOptions.find(
                      (opt) => newData.permissions.canLogin === opt.value
                    ).label
                  }
                  onChange={(option) => updateData('permissions', {
                    canLogin: option.value
                  })}
                />
              ) : (
                <NewInput
                  type='text'
                  classes='inline-block w-full'
                  disabled
                  variant='white'
                  value={
                    canLoginOptions.find(
                      (opt) => newData.permissions.canLogin === opt.value
                    ).label
                  }
                />
              )}
            </div>
          </>
        ) : null}
      </div>
      <div className='w-full flex flex-col items-center pt-4 px-6 pb-2 gap-3 fixed bottom-0 bg-white'>
        <Button
          variant='black'
          disabled={isButtonDisabled()}
          classes='w-full'
          type='submit'
          onClick={onUpdate}
        >
          Update
        </Button>
        <Button
          variant='custom'
          disabled={isUpdating}
          classes='bg-white w-full text-black font-bold text-sm px-4 pt-2 pb-2 border rounded-md border-gray-300'
          onClick={() => navigate('/dashboard/home')}
        >
          Cancel
        </Button>
      </div>
    </Base>
  );
};

export default MobileProfileTab;
