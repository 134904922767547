import React from 'react';
import { useAccount } from 'src/queries/account';
import { useTree } from 'src/queries/tree';

const truncateString = (str) => {
  if (!str) {
    return '';
  }
  return str.length > 30 ? `${str.substr(0, 30)}..` : str;
};

const NodeTooltip = ({ nodeId }) => {
  const { data: userData, isLoading: isAccountLoading } = useAccount(nodeId);
  const {
    data: { treeList }
  } = useTree();

  if (!isAccountLoading) {
    const names = [];
    const showNumberOfUsers = 5;

    const userName = userData.name;

    if (userData.reviews.length > 0) {
      userData.reviews.forEach((relationship) => {
        const { userId: revieweeId } = relationship;

        if (names.length === showNumberOfUsers) {
          return names.push('and others.');
        }
        if (names.length > showNumberOfUsers) {
          return;
        }

        const reviewee = treeList.filter((node) => node.id === revieweeId)[0];

        if (reviewee) {
          const revieweeName = reviewee.id === nodeId ? 'Themselves' : reviewee.name;

          names.push(
            `<p style="margin-bottom: 0; line-height: 23px; font-size: 14px">&bull; ${revieweeName}</p>`
          );
        }
      });
    }

    const innerHtml = names.length === 0
      ? `${userName} does not review anyone.`
      : `<div className="text-left pb-1 text-sm">${truncateString(
        userName
      )} reviews ${userData.reviews.length} member${
        userData.reviews.length > 1 ? 's' : ''
      }:<br />${names.join('').toString()}</div>`;

    return (
      <span
        className='bg-black text-white p-1 tooltip-text ml-24 rounded z-10'
        dangerouslySetInnerHTML={{ __html: innerHtml }}
      />
    );
  }
};

export default NodeTooltip;
