import React from 'react';
import { useMyProfile } from 'src/pagesDashboard/Dash/queries';
import { SIZES } from 'src/constants/style';

const Notifications = () => {
  const {
    userScore,
    isFetching: isFetchingMyProfile,
    isError: isErrorMyProfile
  } = useMyProfile();

  const isFetching = isFetchingMyProfile;
  const isError = isErrorMyProfile;
  const isReady = userScore && userScore.overview && !isError && !isFetching;

  if (!isReady) {
    return null;
  }

  // not showing this anymore. keeping this code in case we want any other banners or notifications in the future
  // const reviewsCount = userScore?.overview?.reviews || 0;
  // if (!reviewsCount) {
  //   return (
  //     <div className='w-full cursor-default p-3 rounded text-center font-bold bg-yellow hover:bg-hover-yellow transition duration-300'>
  //       You don’t have enough reviews completed at this time
  //     </div>
  //   );
  // }

  return null;
};

export default Notifications;
