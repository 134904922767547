import React, { cloneElement } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import appUtils from 'src/components/appUtils';
import commonTreeUtils from 'common/commonTreeUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useTree } from 'src/queries/tree';
import { useAccount } from 'src/queries/account';
import commonPermissions from 'common/commonPermissions';
import commonViewPermissions from 'common/commonViewPermissions';
import { isFunction } from 'lodash';
import Button from 'src/components/Button/Button';
import { useLocation, useNavigate } from 'react-router-dom';

const { ACCESS } = COMMON_CONSTANTS;

export const TABS = {
  OVERVIEW: 'overview',
  FEEDBACK: 'feedback',
  ANALYTICS: 'analytics',
  GOALS: 'goals',
  PARTICIPATION: 'participation',
  REPORTS: 'reports',
  INFORMATION: 'information',
  AI: 'ai'
};

const renderActions = (tab, actions = []) => {
  const actionsToRender = actions.filter(
    (action) => action.tabs.includes('*') || action.tabs.includes(tab)
  );

  return (
    <div className='flex mb-2 items-end justify-end gap-2 h-[39.5px]'>
      {actionsToRender.map((action, index) => {
        if (action.hidden) return;
        if (action.component) return cloneElement(action.component, { key: index });
        return (
          <Button
            key={index}
            disabled={action.disabled}
            onClick={isFunction(action.onClick) ? action.onClick : () => {}}
            variant={action.buttonVariant || 'black'}
            classes={`px-2 py-1 whitespace-nowrap h-full ${action.buttonClasses || ''}`}
            paddingless
          >
            {action.buttonText || 'Action'}
          </Button>
        );
      })}
    </div>
  );
};

const TabNavigator = ({ userId, actions = [] }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const {
    data: loggedAccount,
    isFetching: isFetchingLoggedAccount,
    isError: isErrorLoggedAccount
  } = useAccount('me');

  const {
    showMydashboardTabActivity,
    showMydashboardTabGoals,
    showMydashboardTabInsights,
    showUserDashboardTabInsights,
    showUserDashboardTabGoals,
    showMydashboardTabAnalytics,
    showUserDashboardTabAnalytics,
    showUserDashboardTabActivity
  } = useFlags();

  const isFetching = isFetchingTree || isFetchingLoggedAccount;
  const isError = isErrorTree || isErrorLoggedAccount;
  const isReady = tree && tree.id && !isFetching && !isError;

  if (!isReady) return null;

  const loggedUser = appUtils.getLoggedUser();
  const loggedUserId = appUtils.getLoggedUserId();
  const isMyDashboard = userId === loggedUserId;
  const isUserDashboard = userId !== loggedUserId;
  const isManager = loggedUser.access === ACCESS.MANAGER;
  const isAdmin = loggedUser.access === ACCESS.ADMIN;
  const isDirectlyAbove = commonTreeUtils.isNodeDirectlyAbove(
    tree,
    userId,
    loggedUserId
  );

  const canViewScore = commonViewPermissions.canViewUserScores(
    tree,
    loggedUserId,
    userId
  );
  const canManageAccount = commonPermissions.canManageAccounts(loggedAccount, [
    userId
  ]);

  let canViewActivityTab = (isMyDashboard || isDirectlyAbove || isAdmin || canManageAccount);
  if (isUserDashboard && !showUserDashboardTabActivity) {
    canViewActivityTab = false;
  }
  if (isMyDashboard && !showMydashboardTabActivity) {
    canViewActivityTab = false;
  }

  const canViewGoalsTab = (isMyDashboard && showMydashboardTabGoals) || (isUserDashboard && showUserDashboardTabGoals);
  const canViewInsightsTab = (isMyDashboard && showMydashboardTabInsights) ||
    (isUserDashboard && showUserDashboardTabInsights && (isDirectlyAbove || isAdmin || canManageAccount));
  const canViewProfileTab = isMyDashboard
    || isDirectlyAbove
    || isManager
    || isAdmin
    || canManageAccount;
  let canViewAnalyticsTab = isMyDashboard || canViewScore || canManageAccount || isAdmin;
  if (isMyDashboard && !showMydashboardTabAnalytics) {
    canViewAnalyticsTab = false;
  }
  if (isUserDashboard && !showUserDashboardTabAnalytics) {
    canViewAnalyticsTab = false;
  }
  const tabButtonStyle = (isSelected) => `flex p-2 items-center gap-1 focus:outline-none border-b-4 border-purple text-xl ${
    isSelected
      ? 'border-opacity-100 text-purple font-bold'
      : 'border-opacity-0 text-zinc-800 font-bold'
  } hover:text-purple hover:border-opacity-100 hover:font-bold transition-colors duration-200 whitespace-nowrap`;

  const baseUrl = isMyDashboard
    ? '/dashboard/me'
    : `/dashboard/profile/${userId}`;

  const currentTab = pathname
    .split('/')
    .find((str) => Object.values(TABS).includes(str));

  return (
    <div className='w-full flex justify-between items-end place-self-end pl-4 h-52px'>
      <div className='flex gap-4'>
        {appUtils.getIsOverviewTabEnabled() ? (
          <button
            className={tabButtonStyle(currentTab === TABS.OVERVIEW)}
            onClick={() => navigate(`${baseUrl}/overview`)}
          >
            Overview
          </button>
        ) : null}
        {appUtils.getIsFeedbackTabEnabled() ? (
          <button
            className={tabButtonStyle(currentTab === TABS.FEEDBACK)}
            onClick={() => navigate(`${baseUrl}/feedback`)}
          >
            Feedback
          </button>
        ) : null}
        {canViewAnalyticsTab ? (
          <button
            className={tabButtonStyle(currentTab === TABS.ANALYTICS)}
            onClick={() => navigate(`${baseUrl}/analytics`)}
          >
            Analytics
          </button>
        ) : null}
        {canViewGoalsTab ? (
          <button
            className={tabButtonStyle(currentTab === TABS.GOALS)}
            onClick={() => navigate(`${baseUrl}/goals`)}
          >
            Goals
          </button>
        ) : null}
        {canViewActivityTab ? (
          <button
            className={tabButtonStyle(currentTab === TABS.PARTICIPATION)}
            onClick={() => navigate(`${baseUrl}/participation`)}
          >
            Participation
          </button>
        ) : null}
        <button
          className={tabButtonStyle(currentTab === TABS.REPORTS)}
          onClick={() => navigate(`${baseUrl}/reports`)}
        >
          Reviews
        </button>
        {canViewProfileTab ? (
          <button
            className={tabButtonStyle(currentTab === TABS.INFORMATION)}
            onClick={() => navigate(`${baseUrl}/information/profile`)}
          >
            User Profile
          </button>
        ) : null}
        {canViewInsightsTab ? (
          <button
            className={tabButtonStyle(currentTab === TABS.AI)}
            onClick={() => navigate(`${baseUrl}/ai`)}
          >
            Insights
          </button>
        ) : null}
      </div>
      {renderActions(currentTab, actions)}
    </div>
  );
};

export default TabNavigator;
