import React from 'react';
import Base from 'src/components/Base/Base';
import STYLE from 'src/constants/style';

// variant = 1/2/3/4/5/6/7/8/9
const Placeholder = ({ variant = '4' }) => {
  let classes = `${STYLE.CONTAINER_WHITE} h-20`;
  switch (variant) {
    case '1': // placeholder for now
      classes = `${STYLE.CONTAINER_WHITE}`;
      break;
    case '2': // placeholder for now
      classes = `${STYLE.CONTAINER_WHITE}`;
      break;
    case '3':
      classes = `${STYLE.CONTAINER_WHITE}`;
      break;
    case '4':
      classes = `${STYLE.CONTAINER_WHITE} h-24 pt-5`;
      break;
    case '5':
      classes = `${STYLE.CONTAINER_WHITE} h-32 pt-8`;
      break;
    case '6':
      classes = `${STYLE.CONTAINER_WHITE} h-44 pt-16`;
      break;
    case '7':
      classes = `${STYLE.CONTAINER_WHITE} h-52 pt-20`;
      break;
    case '8':
      classes = `${STYLE.CONTAINER_WHITE} h-20rem pt-32`;
      break;
    case '9':
      classes = `${STYLE.CONTAINER_WHITE} h-25rem pt-40`;
      break;
    default:
      classes = `${STYLE.CONTAINER_WHITE} h-20`;
  }

  return <Base classes={classes} loading />;
};

export default Placeholder;
