import React, { useState } from 'react';
import STYLE from 'src/constants/style';
import {
  Radio, Button, toast, Base, Placeholder
} from 'src/components';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useCompany, updateCompanyQuery } from 'src/queries/company';
import { get } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';

const { DIRECT_REVIEW_PERMISSIONS } = COMMON_CONSTANTS;

const defaultState = {
  builderDirectReviewPermissions: false,
  newBuilderDirectReviewPermissions: false
};

const BuilderReviewSettings = () => {
  const { enableWorkstoryBuilder } = useFlags();

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany,
    refetch
  } = useCompany();
  const { mutateAsync: updateCompany, isLoading: updateSettingsLoading } = updateCompanyQuery();

  const [state, setState] = useState({
    builderDirectReviewPermissions: get(
      company,
      'settings.builderDirectReviewPermissions',
      defaultState.builderDirectReviewPermissions
    ),
    newBuilderDirectReviewPermissions: get(
      company,
      'settings.builderDirectReviewPermissions',
      defaultState.builderDirectReviewPermissions
    )
  });

  if (!enableWorkstoryBuilder) return null;

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError && !updateSettingsLoading;

  if (!isReady) {
    return <Placeholder />;
  }

  const updateBuilderDirectReviewPermissions = async () => {
    const res = await updateCompany({
      settings: {
        ...company.settings,
        builderDirectReviewPermissions: state.newBuilderDirectReviewPermissions
      }
    });
    refetch();
    if (!res.success) {
      return toast.error(
        "We've encountered an issue. Please try again or contact us."
      );
    }
    toast.show('Updated!');
  };

  return (
    <Base classes={STYLE.CONTAINER_WHITE}>
      <h5 className='mb-2'>Workstory Builder</h5>
      <p className='mb-4'>
        Determines the ability of team members to review others directly in the
        {' '}
        <a href='/builder/dashboard'>Workstory Builder</a>
        {' '}
        application
      </p>
      <div className='mb-4'>
        <Radio
          title='Can review everyone'
          checked={
            state.newBuilderDirectReviewPermissions
            === DIRECT_REVIEW_PERMISSIONS.OPEN
          }
          name='builderDirectReviewPermissions'
          onChange={(e) => {
            setState({
              ...state,
              newBuilderDirectReviewPermissions: DIRECT_REVIEW_PERMISSIONS.OPEN
            });
          }}
        />
      </div>

      <div className='mb-4'>
        <Radio
          title='Limited to review relationship rules'
          checked={
            state.newBuilderDirectReviewPermissions
            === DIRECT_REVIEW_PERMISSIONS.PER_REVIEW_RELATIONSHIPS
          }
          name='builderDirectReviewPermissions'
          onChange={(e) => {
            setState({
              ...state,
              newBuilderDirectReviewPermissions:
                DIRECT_REVIEW_PERMISSIONS.PER_REVIEW_RELATIONSHIPS
            });
          }}
        />
      </div>
      <div className='mb-1'>
        <Button
          variant='yellow'
          disabled={
            state.builderDirectReviewPermissions
            === state.newBuilderDirectReviewPermissions
          }
          onClick={updateBuilderDirectReviewPermissions}
        >
          Update
        </Button>
      </div>
    </Base>
  );
};

export default BuilderReviewSettings;
