import React, { useContext } from 'react';
import commonDateUtils from 'common/commonDateUtils';
import commonUtils from 'common/commonUtils';
import { get, isEmpty } from 'lodash';
import { useCreatePdf } from 'src/queries/builder';
import { toast, Button } from 'src/components/index';
import { PerformanceBuilderContext } from 'src/pages/PerformanceBuilder/PerformanceBuilderContext';

const PreviewInfo = ({ mode = null, data = {} }) => {
  const {
    data: { page }
  } = useContext(PerformanceBuilderContext);
  const isViewPage = page === 'view';
  const isGroupedViewPage = page === 'group-view';
  const isAnyViewPage = isViewPage || isGroupedViewPage;

  let revieweeName = 'Reviewee Name';
  let reviewerNames = ['Reviewer Name'];
  let date = new Date();
  let roleLabels = ['Role'];

  if (mode === 'form') {
    const { form } = data;
    const { watch } = form;
    const {
      reviewerFirstName,
      reviewerLastName,
      revieweeFirstName,
      revieweeLastName,
      reviewDate,
      role
    } = watch();

    revieweeName = commonUtils.getFullName(revieweeFirstName, revieweeLastName);
    reviewerNames = [
      commonUtils.getFullName(reviewerFirstName, reviewerLastName)
    ];
    date = new Date(reviewDate);
    roleLabels = [role.label];
  }

  if (mode === 'data') {
    revieweeName = get(data, 'revieweeName', 'Reviewee Name');
    reviewerNames = get(data, 'reviewerNames', ['Reviewer Name']);
    date = new Date(get(data, 'date', new Date()));
    roleLabels = get(data, 'roleLabels', ['Role']);
  }

  const formattedReviewerNames = [];
  reviewerNames.forEach((name) => {
    if (!formattedReviewerNames.includes(name)) {
      formattedReviewerNames.push(name);
    }
  });

  let formattedRoleLabels = roleLabels.slice(0, 3).join(', ');
  if (roleLabels.length > 3) formattedRoleLabels += ' and more';

  const { mutateAsync: createPdf, isLoading: isLoadingCreatePdf } = useCreatePdf();

  let reportIds = [];
  let companyid = null;
  const params = get(data, 'params', {});
  if (!isEmpty(params)) {
    reportIds = get(params, 'reportIds', []);
    companyid = get(params, 'companyid', null);
  }

  const onClickCreatePdf = async () => {
    toast.show('Creating PDF');
    let response;
    try {
      response = await createPdf({ reportIds, companyid });
    } catch (e) {
      return toast.error('Failed to create PDF');
    }
    toast.show('Report exported as pdf');

    const { documentUrl, name } = response;
    const a = document.createElement('a');
    a.setAttribute('href', documentUrl);
    a.setAttribute('download', name);
    const el = document.getElementById('app');
    el.appendChild(a);
    a.click();
  };

  return (
    <div className='w-full'>
      <div className='flex flex-col justify-between'>
        <div className='w-full flex justify-between'>
          <p className='bold text-3xl leading-7 text-stone-gray mb-4'>
            Performance Review
          </p>
          {isAnyViewPage && (
            <Button
              variant='purple'
              classes='px-16 h-fit !rounded-md text-sm -mt-4'
              disabled={isLoadingCreatePdf}
              onClick={onClickCreatePdf}
            >
              Download PDF
            </Button>
          )}
        </div>
        <div className='flex justify-between'>
          <p className='m-0 bold text-3xl text-black'>{revieweeName}</p>
          <span className='text-3xl text-black bold'>
            {commonDateUtils.dateToMonthDayYearFormat(new Date(date))}
          </span>
        </div>
        <p className='bold text-lg text-black mt-4 mb-0'>
          {formattedRoleLabels}
        </p>
      </div>
      <div className='flex gap-1' />
      <p className='bold text-lg text-black my-2'>
        {`Reviewer${formattedReviewerNames.length > 1 ? 's' : ''}`}
      </p>
      {formattedReviewerNames.map((name) => (
        <p className='text-lg text-black my-1' key={name}>
          {name}
        </p>
      ))}
      <div className='my-5 border-b rounded border-[#E2E8F0]' />
    </div>
  );
};

export default PreviewInfo;
