import React from 'react';
import { Base } from 'src/components';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import { useBilling } from 'src/queries/billing';
import commonDateUtils from 'common/commonDateUtils';

const GrowthPlan = () => {
  const {
    data: { treeListActive },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const {
    data: billing,
    isFetching: isFetchingBilling,
    isError: isErrorBilling
  } = useBilling();

  const isFetching = isFetchingBilling || isFetchingCompany || isFetchingTree;
  const isError = isErrorBilling || isErrorCompany || isErrorTree;
  const isReady = company
    && company.id
    && treeListActive
    && billing
    && !isFetching
    && !isError;

  if (!isReady) {
    return null;
  }

  let price = 0;
  if (billing && billing.stripe && billing.stripe.price) {
    price = parseFloat(billing.stripe.price.unit_amount / 100, 10);
  }

  return (
    <Base loading={!isReady} classes='w-34rem pl-5 pt-2 pb-3 mb-2'>
      <h5>Growth Plan</h5>
      <p className='mb-0 pr-2 mb-1 mt-1'>
        This team’s plan is set to $
        {price}
        {' '}
        per user per month.
      </p>
      <p className='mb-0 pr-2'>
        Your organization currently has
        {' '}
        {treeListActive.length}
        {' '}
        users and will
        be charged $
        {treeListActive.length * price}
        {' '}
        on
        {' '}
        {company.planEnd
          ? commonDateUtils.unixToMonthDayYearFormat(company.planEnd).toString()
          : '-'}
        .
      </p>
    </Base>
  );
};

export default GrowthPlan;
