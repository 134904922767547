import React, { useContext } from 'react';
import TopScoreBox from 'src/componentsAdvanced/TopScoreBox/TopScoreBox';
import { PulseQuestionResultsContext } from 'src/pagesDashboard/PulseQuestionResults/context/PulseQuestionResultsProvider';
import { Base, BarChart } from 'src/components';
import { usePulseData } from 'src/queries/Pulse/pulseData';
import pulseActions from 'src/queries/actions/pulse';
import STYLE from 'src/constants/style';
import { useParams } from 'react-router-dom';

const NPSTrend = () => {
  const { questionId } = useParams();
  const { context, dispatch } = useContext(PulseQuestionResultsContext);
  const {
    range: { value: range, start, end }
  } = context;
  const pulseAction = pulseActions.getQuestionData({
    questionId,
    range,
    start,
    end
  });
  const {
    data: pulseData,
    isFetching: isFetchingPulseData,
    isError: isErrorPulseData
  } = usePulseData(pulseAction);

  const isFetching = isFetchingPulseData;
  const isError = isErrorPulseData;
  const isReady = pulseData && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const chartData = {
    labels: pulseData.chart.data.labels,
    datasets: [
      {
        label: 'Answers',
        data: pulseData.chart.data.data
      }
    ]
  };

  return (
    <div className={STYLE.CONTAINER_WHITE}>
      <div className='grid sm:grid-cols-2 lg:grid-cols-4 gap-3'>
        <TopScoreBox
          title='NPS'
          value={pulseData.scores.score || '-'}
          tooltip='NPS Score'
        />
        <TopScoreBox
          title='Detractors'
          value={pulseData.scores.detractors}
          isPurple={false}
          tooltip='Scores below 7'
        />
        <TopScoreBox
          title='Passives'
          value={pulseData.scores.passives}
          isPurple={false}
          tooltip='Scores 7 and 8'
        />
        <TopScoreBox
          title='Promoters'
          value={pulseData.scores.promoters}
          isPurple={false}
          tooltip='Scores above 8'
        />
      </div>
      <Base classes='bg-white p-2 px-3 mt-3'>
        <h5 className='font-bold text-lg'>Answer Trend</h5>
        <BarChart data={chartData} />
      </Base>
    </div>
  );
};

export default NPSTrend;
