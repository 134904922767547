import React from 'react';
import { Base, Pagination } from 'src/components';
import FeedbackList from 'src/containers/Feedback/FeedbackList/FeedbackList';
import COMMON_CONSTANTS from 'common/commonConstants';
import STYLE from 'src/constants/style';
import { useActivityTab } from 'src/containers/UserProfile/Activity/queries';

const {
  FEEDBACK_TYPE: { FEEDBACK }
} = COMMON_CONSTANTS;

const FeedbackForReviewer = ({ userId }) => {
  const {
    feedback: {
      data: feedback,
      isFetching: isFetchingFeedback,
      isError: isErrorFeedback,
      page: { setPage, totalPages, number }
    }
  } = useActivityTab(userId);

  const isFetching = isFetchingFeedback;
  const isError = isErrorFeedback;
  const isReady = feedback && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  return (
    <Base classes={STYLE.CONTAINER_WHITE} loading={isFetchingFeedback}>
      <div className='flex justify-between text-left'>
        <h5 className='text-black focus:outline-none text-xl mr-5 mb-5 inline-block'>
          Feedback
        </h5>
      </div>
      <div className='overflow-y-scroll h-20rem'>
        <FeedbackList
          emptyPlaceholder='No feedback given'
          userId={userId}
          view={FEEDBACK}
          list={feedback}
        />
      </div>
      <Pagination
        name='actFeedback'
        totalPages={totalPages}
        currentPage={number}
        selectPage={setPage}
        isLoading={isFetchingFeedback}
      />
    </Base>
  );
};

export default FeedbackForReviewer;
