import React from 'react';
import { Tooltip } from 'src/components';

const ReportGenerationSection = ({
  id,
  labelText,
  children,
  errorMessage,
  isDisabled
}) => (
  <section className='mb-10 md:mb-16 flex justify-between flex-col md:flex-row'>
    <div className='w-full'>
      {isDisabled ? (
        <Tooltip
          textClasses='bg-black text-white ml-5'
          containerClasses='flex'
          infoClasses='h-5 mt-1 ml-2'
        >
          <span className='tooltip-text bg-black text-white transform translate-x-28 -translate-y-16 rounded px-6 w-64'>
            AI-Assist is only available when creating new reports.
          </span>
          <label
            htmlFor={id}
            className='text-gray-500 mb-4 md:my-auto uppercase font-bold text-xl'
          >
            {labelText}
          </label>
        </Tooltip>
      ) : (
        <label
          htmlFor={id}
          className='text-gray-500 mb-4 md:my-auto uppercase font-bold text-xl'
        >
          {labelText}
        </label>
      )}
    </div>

    <div className='w-full max-w-34rem'>
      {children}
      <div className='text-red whitespace-no-wrap flex items-center mt-2 absolute'>
        {errorMessage}
      </div>
    </div>
  </section>
);
export default ReportGenerationSection;
