import appUtils from '../../components/appUtils';

const loggedInUser = appUtils.getLoggedUser();
const isLoggedIn = appUtils.isLoggedIn();

export default {
  userLogged: isLoggedIn ? loggedInUser : null,
  tree: null,
  treeList: null,
  enabled: false,
  emailing: null,
  emailFrequency: null,
  newEmailFrequency: null,
  reviewThisUser: null,
  reviewThisUserApiCallInProgress: false,
  showMobileMenu: false,
  isTourLoading: false,
  showIntroModal: false,
  // loading components by chunks:
  LineChartComponent: null,
  DashContainer: null,
  DashTeamPage: null,
  OrganizationPage: null,
  DirectReviewPage: null,
  ReviewPage: null,
  // show user card
  showUserCard: null,
  isCheckingLoggedUserStatus: true
};
