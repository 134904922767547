import React, { useMemo } from 'react';
import ChevronDownSVG from 'src/assets/svg/chevronDown.svg';
import './Pagination.scss';

const commomClassnames = 'pagination-items text-sm text-center hover:bg-gray rounded';
const paginationItemClassnames = 'w-8 h-8 mx-0.5';
const selectedClassnames = 'bg-gray font-bold';

const Pagination = ({
  name,
  totalPages,
  currentPage,
  selectPage,
  isLoading
}) => {
  if (!totalPages) {
    return null;
  }

  const renderedPagination = useMemo(() => {
    const pagesRendered = [
      <button
        type='button'
        className={`
          ${commomClassnames}
          ${currentPage === 1 ? 'text-light-gray' : ''}
          w-18 pr-3`}
        key={`${name}-page-previous`}
        onClick={() => selectPage(currentPage - 1)}
        disabled={isLoading || currentPage === 1}
      >
        <span className='transform w-4 h-4 mt-0.3rem pt-1.5 inline-block -rotate-90'>
          <ChevronDownSVG />
        </span>
        <span className='inline-block ml-3 text-sm'>Prev</span>
      </button>
    ];

    if (currentPage <= 3) {
      for (let p = 1; p <= totalPages; p++) {
        pagesRendered.push(
          <button
            type='button'
            className={`${commomClassnames} ${paginationItemClassnames} ${
              p === currentPage ? selectedClassnames : ''
            }`}
            key={`${name}-page-${p}`}
            onClick={() => selectPage(p)}
            disabled={isLoading || currentPage === p}
          >
            {p}
          </button>
        );
        if (p === 3) break;
      }
    }

    if (currentPage === 3 && totalPages > 4) {
      pagesRendered.splice(1, 1);

      pagesRendered.push(
        <button
          type='button'
          className={`${commomClassnames} ${paginationItemClassnames}`}
          key={`${name}-page-${currentPage + 1}`}
          onClick={() => selectPage(currentPage + 1)}
          disabled={isLoading}
        >
          {currentPage + 1}
        </button>
      );
    }

    if (currentPage > 3) {
      if (currentPage === totalPages) {
        pagesRendered.push(
          <button
            type='button'
            className={`${commomClassnames} ${paginationItemClassnames}`}
            key={`${name}-page-${currentPage - 2}`}
            onClick={() => selectPage(currentPage - 2)}
            disabled={isLoading}
          >
            {currentPage - 2}
          </button>
        );
      }

      pagesRendered.push(
        <button
          type='button'
          className={`${commomClassnames} ${paginationItemClassnames}`}
          key={`${name}-page-${currentPage - 1}`}
          onClick={() => selectPage(currentPage - 1)}
          disabled={isLoading}
        >
          {currentPage - 1}
        </button>
      );

      pagesRendered.push(
        <button
          type='button'
          className={`${commomClassnames} ${paginationItemClassnames} ${selectedClassnames}`}
          key={`${name}-page-${currentPage}`}
          onClick={() => selectPage(currentPage)}
          disabled={isLoading}
        >
          {currentPage}
        </button>
      );

      if (currentPage !== totalPages) {
        pagesRendered.push(
          <button
            type='button'
            className={`${commomClassnames} ${paginationItemClassnames}`}
            key={`${name}-page-${currentPage + 1}`}
            onClick={() => selectPage(currentPage + 1)}
            disabled={isLoading}
          >
            {currentPage + 1}
          </button>
        );
      }
    }

    pagesRendered.push(
      <button
        type='button'
        className={`
          ${commomClassnames}
          ${currentPage === totalPages && 'text-light-gray'}
          w-18 pl-3`}
        key={`${name}-page-next`}
        onClick={() => selectPage(currentPage + 1)}
        disabled={isLoading || currentPage === totalPages}
      >
        <span className='inline-block mr-3 text-sm'>Next</span>
        <span className='transform w-4 h-4 mt-0.3rem pt-1.5 inline-block rotate-90'>
          <ChevronDownSVG />
        </span>
      </button>
    );

    return pagesRendered;
  }, [name, totalPages, currentPage, selectPage, isLoading]);

  return (
    <div className='flex mt-4'>
      <div className='ml-auto cursor-default flex'>{renderedPagination}</div>
    </div>
  );
};

export default Pagination;
