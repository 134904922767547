import { get } from 'lodash';
import React, { cloneElement } from 'react';
import ThreeDotsBubbleSVG from 'src/assets/svg/three-dots-bubble.svg';

export const CIRCLED_ICON_COLORS = {
  PURPLE: 'purple'
};

const CircledIcon = ({
  svg = <ThreeDotsBubbleSVG className='text-purple' />,
  color = CIRCLED_ICON_COLORS.PURPLE
}) => {
  let colorClasses = '';

  if (color === CIRCLED_ICON_COLORS.PURPLE) {
    colorClasses = 'bg-light-purple border-alt-light-purple';
  }

  return (
    <div
      className={`absolute right-0 top-0 m-2 p-1 rounded-full h-fit w-fit border-[6px] ${colorClasses}`}
    >
      {svg}
    </div>
  );
};

export default CircledIcon;
