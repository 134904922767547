import React, { useContext, useState } from 'react';
import { appUtils, Base } from 'src/components/index';
import STYLE from 'src/constants/style';
import { useCompany } from 'src/queries/company';
import { useAccount, useAccountScore } from 'src/queries/account';
import { UserProfileContext } from 'src/pagesDashboard/UserProfile/context/UserProfileProvider';
import { DashContext } from 'src/pagesDashboard/Dash/context/DashProvider';
import commonQuestionsUtils from 'common/commonQuestionsUtils';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement } from 'chart.js';
import { get, isEmpty, isNumber } from 'lodash';
import FilterByDate, {
  LABEL_AS
} from 'src/containers/UserProfile/FilterView/FilterByDate';
import COMMON_CONSTANTS from 'common/commonConstants';
import commonDateUtils from 'common/commonDateUtils';
import NoData from 'src/containers/UserProfile/NoData/NoData';
import commonPermissions from 'common/commonPermissions';
import { useTree } from 'src/queries/tree';

const { DATE_RANGE_FILTERS, VIEW_SCORES_PERMISSIONS } = COMMON_CONSTANTS;

ChartJS.register(BarElement);

const RangeComparedScores = ({
  userId = appUtils.getLoggedUserId(),
  viewerId = appUtils.getLoggedUserId()
}) => {
  const loggedUserId = appUtils.getLoggedUserId();
  const isMyProfile = userId === loggedUserId;
  const { context } = useContext(
    isMyProfile ? DashContext : UserProfileContext
  );
  const { filters } = context;
  const {
    roles: roleIds,
    category: categoryId,
    reviewerIds,
    reviewerGroup
  } = filters;

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const {
    data: viewerAccount,
    isFetching: isFetchingViewerAccount,
    isError: isErrorViewerAccount
  } = useAccount(viewerId);

  const {
    data: userAccount,
    isFetching: isFetchingUserAccount,
    isError: isErrorUserAccount
  } = useAccount(userId);

  const [baseRange, setBaseRange] = useState({
    value: DATE_RANGE_FILTERS.THREE_MONTHS.key,
    start: commonDateUtils.getFirstUnixOfDateFromUnix(
      commonDateUtils.getDateFromDaysAgo(DATE_RANGE_FILTERS.THREE_MONTHS.days)
        .unix
    ),
    end: commonDateUtils.getLastUnixOfDateFromUnix(
      commonDateUtils.getUnixDateNow()
    )
  });

  const [rangeToCompare, setRangeToCompare] = useState({
    value: DATE_RANGE_FILTERS.CUSTOM.key,
    start: commonDateUtils.getFirstUnixOfDateFromUnix(
      commonDateUtils.getDateFromDaysAgo(
        DATE_RANGE_FILTERS.THREE_MONTHS.days * 2
      ).unix
    ),
    end: commonDateUtils.getFirstUnixOfDateFromUnix(
      commonDateUtils.getDateFromDaysAgo(DATE_RANGE_FILTERS.THREE_MONTHS.days)
        .unix
    )
  });

  const setRanges = (value, start, end) => {
    setBaseRange({
      value,
      start,
      end
    });

    const now = commonDateUtils.getUnixDateNow();
    const startDiff = now - start;
    const compareStart = now - startDiff * 2;
    const compareEnd = start;

    setRangeToCompare({
      value: DATE_RANGE_FILTERS.CUSTOM.key,
      start: compareStart,
      end: compareEnd
    });
  };

  const {
    data: baseScore,
    isFetching: isFetchingBaseScore,
    isRefetching: isRefetchingBaseScore,
    isError: isErrorBaseScore
  } = useAccountScore(
    {
      id: userId,
      ...(roleIds && { roleIds }),
      ...(categoryId && { categoryIds: [categoryId] }),
      ...(baseRange.start && {
        start: baseRange.start,
        end: baseRange.end
      }),
      ...(!isEmpty(reviewerIds) && { reviewerIds }),
      ...(reviewerGroup && { reviewerGroup })
    },
    {
      bundleCategories: true,
      role: 'reviewee',
      viewerId
    },
    {
      keepPreviousData: true
    }
  );

  const {
    data: compareScore,
    isFetching: isFetchingCompareScore,
    isRefetching: isRefetchingCompareScore,
    isError: isErrorCompareScore
  } = useAccountScore(
    {
      id: userId,
      ...(roleIds && { roleIds }),
      ...(categoryId && { categoryIds: [categoryId] }),
      ...(rangeToCompare.start && {
        start: rangeToCompare.start,
        end: rangeToCompare.end
      }),
      ...(!isEmpty(reviewerIds) && { reviewerIds }),
      ...(reviewerGroup && { reviewerGroup })
    },
    {
      bundleCategories: true,
      role: 'reviewee',
      viewerId
    },
    {
      keepPreviousData: true
    }
  );

  const isInitialFetchingBaseScore = isFetchingBaseScore && !isRefetchingBaseScore;
  const isInitialFetchingCompareScore = isFetchingCompareScore && !isRefetchingCompareScore;
  const isFetching = isFetchingCompany
    || isInitialFetchingBaseScore
    || isInitialFetchingCompareScore
    || isFetchingTree
    || isFetchingViewerAccount
    || isFetchingUserAccount;
  const isError = isErrorCompany
    || isErrorBaseScore
    || isErrorCompareScore
    || isErrorTree
    || isErrorViewerAccount
    || isErrorUserAccount;
  const isReady = company && company.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const { questions: companyQuestions } = company;
  const { bundledCategories: baseBundledCategories } = baseScore;
  const { bundledCategories: compareBundledCategories } = compareScore;
  const numericBaseBundledCategories = baseBundledCategories.filter(
    ({ avgScore }) => isNumber(avgScore)
  );
  const numericCompareBundledCategories = compareBundledCategories.filter(
    ({ avgScore }) => isNumber(avgScore)
  );
  const hasNumericData = !isEmpty(numericBaseBundledCategories)
    && !isEmpty(numericCompareBundledCategories);

  let labels = [];
  let fullLabels = [];
  const datasets = [
    {
      label: 'Previous Period',
      data: [],
      backgroundColor: '#E2E9FF',
      hoverBackgroundColor: '#E2E9FF',
      barPercentage: 0.7,
      categoryPercentage: 0.75,
      borderColor: '#FFFFFF'
    },
    {
      label: 'Current Period',
      data: [],
      backgroundColor: '#5B7FFF',
      hoverBackgroundColor: '#5B7FFF',
      barPercentage: 0.7,
      categoryPercentage: 0.75,
      borderColor: '#FFFFFF'
    }
  ];

  let maxChars = 20;

  if (numericBaseBundledCategories.length > 8) {
    maxChars = 6;
  } else if (numericBaseBundledCategories.length > 6) {
    maxChars = 8;
  } else if (numericBaseBundledCategories.length > 4) {
    maxChars = 10;
  }

  numericBaseBundledCategories.forEach(
    ({ _id: catId, avgScore: baseCategoryScore }) => {
      const category = commonQuestionsUtils.findCategory(
        companyQuestions,
        catId
      );

      const categoryLabel = category.label;
      fullLabels = fullLabels.concat(categoryLabel);
      let formattedCategoryLabel = categoryLabel.substring(0, maxChars);
      if (formattedCategoryLabel.length < categoryLabel.length) formattedCategoryLabel += '...';

      labels = labels.concat(formattedCategoryLabel);
      datasets[1].data.push(baseCategoryScore);

      const comparedScore = compareBundledCategories.find(
        ({ _id }) => _id === catId
      );
      const comparedCategoryScore = get(comparedScore, 'avgScore', 0);
      datasets[0].data.push(comparedCategoryScore);
    }
  );

  const graphData = {
    labels,
    datasets
  };

  const graphOptions = {
    plugins: {
      tooltip: {
        mode: 'index',
        intersect: false,
        yAlign: 'bottom',
        callbacks: {
          title: (c) => {
            const [graphContext] = c;
            const { dataIndex } = graphContext;
            const fullLabel = fullLabels[dataIndex];
            return fullLabel;
          }
        }
      },
      legend: {
        fullSize: false,
        align: 'start',
        labels: {
          font: {
            size: 14
          },
          usePointStyle: true,
          pointStyle: 'circle',
          useBorderRadius: true,
          borderRadius: 999,
          boxWidth: 10,
          boxHeight: 10,
          padding: 15,
          generateLabels: (chart) => {
            const original = ChartJS.defaults.plugins.legend.labels.generateLabels(chart);
            return original.map((label) => {
              if (label.text === 'Current Period') {
                label.text = `Current period: ${commonDateUtils.dateToMonthYearFormat(
                  new Date(rangeToCompare.start * 1000)
                )} - ${commonDateUtils.dateToMonthYearFormat(
                  new Date(rangeToCompare.end * 1000)
                )}`;
              } else if (label.text === 'Previous Period') {
                label.text = `Previous period: ${commonDateUtils.dateToMonthYearFormat(
                  new Date(baseRange.start * 1000)
                )} - ${commonDateUtils.dateToMonthYearFormat(new Date(baseRange.end * 1000))}`;
              }
              return label;
            });
          }
        }
      }
    },
    scales: {
      y: {
        grid: {
          display: true
        },
        max: 100,
        min: 0
      },
      x: {
        grid: { display: false },
        ticks: {
          maxRotation: 0,
          minRotation: 0,
          autoSkip: false
        }
      }
    }
  };

  const canManageUser = commonPermissions.canManageUsers(viewerAccount, tree, [
    userId
  ]);
  const companyViewScorePermissions = get(
    company,
    'settings.viewScores',
    VIEW_SCORES_PERMISSIONS.MANAGERS
  );
  const isViewingAsUser = viewerId === userId;
  const canViewScores = isViewingAsUser
    ? companyViewScorePermissions === VIEW_SCORES_PERMISSIONS.ALL
    : canManageUser;

  const isLoading = isFetchingCompany || isFetchingBaseScore || isFetchingCompareScore;
  return (
    <>
      <Base
        classes={`${STYLE.CONTAINER_WHITE} !p-4`}
        relative
        loading={isLoading}
      >
        <div className='flex justify-between'>
          <div className='w-14/20'>
            <p className='text-xl text-black font-bold mb-0 mr-8'>
              Comparison Chart
            </p>
            <p className='text-sm mt-2 mb-2'>
              {hasNumericData && canViewScores
                ? `Comparing current performance versus previous periods.`
                : ''}
              {!hasNumericData && canViewScores
                ? `Once ${isMyProfile ? 'you have' : `${userAccount.firstName} has`} received enough feedback, we'll show data here.`
                : ''}
              {!canViewScores
                ? `You don't have permission to view scores.`
                : ''}
            </p>
          </div>
          <div className='w-8/20'>
            <span className='flex items-center gap-2 justify-end w-[100%]'>
              <p className='text-base whitespace-nowrap leading-6 m-0'>
                Compared to:
              </p>
              <FilterByDate
                prefix=''
                labelAs={LABEL_AS.WORDED}
                topClasses='!py-0'
                classes='w-full'
                containerClasses='w-full'
                wrapperClasses='w-full'
                optionsWidth='fit'
                hideKeys={[
                  DATE_RANGE_FILTERS.ALL_TIME.key,
                  DATE_RANGE_FILTERS.CUSTOM.key
                ]}
                dateRangeSelected={baseRange}
                onSelectDateRange={(value, start, end) => setRanges(value, start, end)}
                disabled={!canViewScores}
              />
            </span>
          </div>
        </div>
        {canViewScores ? <Bar data={graphData} options={graphOptions} /> : null}
      </Base>
    </>
  );
};

export default RangeComparedScores;
