import React, { createContext, useReducer } from 'react';
import { updateData } from 'src/pagesDashboard/Dash/context/actions';
import reducer from './reducer';
import initialState from './state';

export const DashContext = createContext();

const DashProvider = ({ children }) => {
  const [context, dispatch] = useReducer(reducer, initialState);
  const updateContext = (data) => {
    dispatch(updateData(data));
  };
  const resetPages = () => {
    updateContext({
      currentPages: initialState.currentPages,
      historicReviews: initialState.historicReviews,
      userDashReviews: initialState.userDashReviews,
      userDashFeedback: initialState.userDashFeedback
    });
  };
  return (
    <DashContext.Provider
      value={{
        context,
        dispatch,
        updateContext,
        resetPages
      }}
    >
      {children}
    </DashContext.Provider>
  );
};

export default DashProvider;
