import React, { useContext } from 'react';
import { isEmpty, get } from 'lodash';
import { PerformanceBuilderContext } from 'src/pages/PerformanceBuilder/PerformanceBuilderContext';
import PreviewQuestion from 'src/pages/PerformanceBuilder/PreviewComponents/PreviewQuestion';
import commonReviewUtils from 'common/commonReviewUtils';

const PreviewSupplemental = ({ mode = null, data = {} }) => {
  const {
    data: { dataset: contextDataset }
  } = useContext(PerformanceBuilderContext);

  let dataset = [];
  let industryIds = [];
  let roleIds = [];
  let supplementalReviews = [];
  let revieweeFirstName = '';

  if (mode === 'form') {
    const { form } = data;
    const { watch } = form;
    const {
      industry,
      role,
      supplementalReviews: formSupplementalReviews,
      revieweeFirstName: formRevieweeFirstName
    } = watch();
    dataset = contextDataset;
    industryIds = [industry.id];
    roleIds = [role.id];
    supplementalReviews = formSupplementalReviews;
    revieweeFirstName = formRevieweeFirstName;
  }

  if (mode === 'data') {
    const {
      dataset: dataDataset,
      industryIds: dataIndustryIds,
      roleIds: dataRoleIds,
      supplementalReviews: dataSupplementalReviews,
      revieweeFirstName: dataRevieweeFirstName
    } = data;
    dataset = dataDataset;
    industryIds = dataIndustryIds;
    roleIds = dataRoleIds;
    supplementalReviews = dataSupplementalReviews;
    revieweeFirstName = dataRevieweeFirstName;
  }

  if (isEmpty(industryIds) || isEmpty(roleIds)) return null;
  const displayedReviews = supplementalReviews.filter(
    (review) => !isEmpty(review.comments)
  );
  if (isEmpty(displayedReviews)) return null;

  const roles = [];
  const supplementalQuestions = [];

  industryIds.forEach((industryId) => {
    const industryData = dataset.find((d) => d.industryId === industryId);
    const {
      roles: industryRoles = [],
      supplementalQuestions: industrySupplementalQuestions = []
    } = industryData;
    roles.push(...industryRoles.filter((r) => roleIds.includes(r.roleId)));
    const supplementalQuestionIds = roles.reduce(
      (acc, role) => [...acc, ...role.supplementalQuestionIds],
      []
    );
    const rolesSupplementalQuestions = industrySupplementalQuestions.filter(
      (q) => supplementalQuestionIds.includes(q.id)
    );
    supplementalQuestions.push(...rolesSupplementalQuestions);
  });

  return (
    <>
      <h2>Supplemental Questions</h2>
      {supplementalQuestions.map((question, index) => {
        const supplementalQuestionReviews = displayedReviews.filter(
          (r) => r.questionId === question.id
        );
        if (isEmpty(supplementalQuestionReviews)) return null;

        const questionText = get(question, 'question', null) || get(question, 'self', null);

        if (isEmpty(questionText)) return null;

        question.question = commonReviewUtils.filloutReviewQuestionName(
          questionText,
          revieweeFirstName
        );

        return (
          <div key={`${index}`}>
            <PreviewQuestion
              reviews={supplementalQuestionReviews}
              question={question}
            />
          </div>
        );
      })}
      <div className='my-8 border-b rounded border-[#E2E8F0]' />
    </>
  );
};

export default PreviewSupplemental;
