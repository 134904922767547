import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Logo, Button } from '../../components';
import appUtils from '../../components/appUtils';
import { appActions } from '../../reducers/actions';
import { appThunks } from '../../thunks';
import CalendarSVG from '../../assets/calendarIcon.svg';
import './Header.scss';
import WorkStoryTitle from 'src/assets/svg/workstory-title.svg';
import LinesSVG from 'src/assets/svg/lines.svg';

class Header extends Component {
  constructor(props) {
    super(props);
  }

  logOut = () => {
    const { dispatch } = this.props;
    return appThunks.logOut()(dispatch);
  };

  showMobileMenu = () => {
    const { dispatch } = this.props;
    dispatch(appActions.showMobileMenu(true));
  };

  clickLogo = () => {
    const {
      app: { userLogged }
    } = this.props;
    if (userLogged) {
      if (appUtils.showOrganizationIntroSection(userLogged)) {
        return (window.location.href = '/dashboard/get-started/');
      }
      return (window.location.href = appUtils.getDashRoute());
    }

    const landingPageUrl = appUtils.getLandingPageUrl();
    window.location.href = landingPageUrl;
  };

  openStartModal = (show) => {
    this.setState({ ...this.state });
  };

  render() {
    const {
      app: { isTourLoading },
      classes
    } = this.props;
    let actionText = '';
    let actionHref = '/sign-up';
    const isLoggedIn = appUtils.isLoggedIn();
    return (
      <div className={`w-full ${classes ? classes : ''}`}>
        <div className='flex w-full justify-between items-center mb-4 tablet:w-[420px]'>
          <button className='flex w-[200px] h-[50px]' onClick={this.clickLogo}>
            <WorkStoryTitle className='w-full h-full' fill='#1C2434' />
          </button>
          <button
            className='h-8 w-8 focus:outline-none tablet:hidden'
            onClick={this.showMobileMenu}
          >
            <LinesSVG className='h-full w-full' />
          </button>
        </div>
        {isLoggedIn ? (
          <div className='col-xs-16 col-sm-12 hidden-xs navigation text-left'>
            <div className='inline-block float-right'>
              <a className='link marginRight30' onClick={this.clickLogo}>
                <h5 className='inline-block hoverBottomBorderRed height38'>
                  Dashboard
                </h5>
              </a>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    app: state.appReducer
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
