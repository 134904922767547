import moment from 'moment';
import { DateTime } from 'luxon';
import COMMON_CONSTANTS from 'common/commonConstants';

// https://stackoverflow.com/questions/3280323/get-week-of-the-month
Date.prototype.getWeekOfMonth = function (exact) {
  const month = this.getMonth();
  const year = this.getFullYear();
  const firstWeekday = new Date(year, month, 1).getDay();
  const lastDateOfMonth = new Date(year, month + 1, 0).getDate();
  const offsetDate = this.getDate() + firstWeekday - 1;
  const index = 1; // start index at 0 or 1, your choice
  const weeksInMonth = index + Math.ceil((lastDateOfMonth + firstWeekday - 7) / 7);
  const week = index + Math.floor(offsetDate / 7);
  if (exact || week < 2 + index) return week;
  return week === weeksInMonth ? index + 5 : week;
};

const { SCHEDULE_TIMES, REVIEW_SCHEDULING_TIME } = COMMON_CONSTANTS;

const utils = {};

const SIMPLE_DATE_FORMAT = 'YYYY-MM-DD hh:mm a';

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
const SHORT_MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const THREE_MONTHS_IN_SECONDS = 7257600;

const holidays = {
  // keys are formatted as month,week,day
  // "0,2,1": "Martin Luther King, Jr. Day",
  // "1,2,1": "President's Day",
  // "2,1,0": "Daylight Savings Time Begins",
  // "3,3,3": "Administrative Assistants Day",
  // "4,1,0": "Mother's Day",
  // "4,-1,1": "Memorial Day",
  // "5,2,0": "Father's Day",
  // "6,2,0": "Parents Day",
  // "8,1,1": "Labor Day",
  // "8,1,0": "Grandparents Day",
  // "8,-1,0": "Gold Star Mothers Day",
  // "9,1,1": "Columbus Day",
  // "10,0,0": "Daylight Savings Time Ends",
  '10,4,4': 'Thanksgiving Day',
  '10,4,5': 'Thanksgiving Day 2', // November, 4th week, 5th day
  '11,4,5': 'before christmas eve',
  '11,5,1': 'christmas',
  '11,5,2': 'holiday 3',
  '11,5,3': 'holiday 4',
  '11,5,4': 'holiday 5',
  '11,5,5': 'holiday 6',
  '11,6,1': 'holiday 7',
  '11,6,2': 'holiday 8', // tuesday
  '11,6,3': 'holiday 9', // wed
  '11,6,4': 'holiday 10', // thurs
  '11,6,5': 'holiday 11' // fri
};

utils.isHoliday = (month, week, day) => holidays[`${month},${week},${day}`];

// year start at 0
// week starts at 1
// day starts at 1 (monday is 1, tuesday is 2, etc.)
utils.isTodayAHoliday = () => {
  const date = new Date();
  const month = date.getMonth();
  const weekOfMonth = date.getWeekOfMonth();
  const day = date.getDay();
  const isHoliday = Boolean(utils.isHoliday(month, weekOfMonth, day));
  return isHoliday;
};

utils.isTodayMonday = () => {
  try {
    const date = new Date();
    return date.getDay() === 1;
  } catch (error) {
    console.error('commonDateUtils.isTodayMonday', error);
    return false;
  }
};

// gets a random number between 0 and 60
utils.getRandomMinutes = () => {
  const start = 0;
  const end = 60;
  return (start + Math.random() * (end - start)) | 0;
};

utils.getRandomHour = (startHour, endHour) => (startHour + Math.random() * (endHour - startHour)) | 0;

utils.dateToUnix = (date) => new Date(date).getTime() / 1000;

utils.getUnixDateNow = () => Math.floor(new Date() / 1000);

utils.unixToDate = (unixTime) => new Date(unixTime * 1000);
utils.unixToDateWithTimezone = (unixTime) => {
  const d = new Date();
  const timezoneSeconds = -(d.getTimezoneOffset() * 60);
  const unixWithTimezone = parseFloat(unixTime) - timezoneSeconds;
  return new Date(unixWithTimezone * 1000);
};

utils.dateToNewYorkTimezone = (date) => date.toLocaleString('en-US', { timeZone: 'America/New_York' });

utils.dateToEdmontonTimezone = (date) => date.toLocaleString('en-US', { timeZone: 'America/Edmonton' });

utils.unixToRange = (start, end) => {
  const startDate = utils.unixToDate(start || utils.getJan1st2020().unix);
  const startRange = `${
    startDate.getMonth() + 1
  }_${startDate.getDate()}_${startDate.getFullYear()}`;
  const endDate = utils.unixToDate(end);
  const endRange = `${
    endDate.getMonth() + 1
  }_${endDate.getDate()}_${endDate.getFullYear()}`;
  return `${startRange}-${endRange}`;
};

utils.unixToSimpleDate = (unixTime) => {
  const d = new Date();
  const n = d.getTimezoneOffset();
  return new Date(unixTime * 1000);
};

utils.getCompanyHours = (scheduleTime) => {
  if (scheduleTime === REVIEW_SCHEDULING_TIME.TEN_TO_FIVE) {
    return { hourStart: 10, hourEnd: 17 };
  }

  if (scheduleTime === REVIEW_SCHEDULING_TIME.EIGHT_TO_FIVE) {
    return { hourStart: 8, hourEnd: 17 };
  }

  if (scheduleTime === REVIEW_SCHEDULING_TIME.EIGHTAM_NINEAM) {
    return { hourStart: 8, hourEnd: 9 };
  }

  return { hourStart: 8, hourEnd: 17 };
};

utils.getScheduleTime = (companySchedule, userSchedule) => {
  try {
    const userScheduleType = userSchedule.type;
    if (userScheduleType === REVIEW_SCHEDULING_TIME.CUSTOM) {
      return userSchedule.time;
    }
    if (userScheduleType === REVIEW_SCHEDULING_TIME.COMPANY_DEFAULT) {
      return companySchedule;
    }
    return false;
  } catch (error) {
    console.error('commonDateUtils.getScheduleTime error', error, {
      userSchedule,
      companySchedule
    });
    throw error;
  }
};

utils.getStartEndHourOfScheduleTime = (scheduleTime) => {
  try {
    let hourStart;
    let hourEnd;

    if (Object.values(REVIEW_SCHEDULING_TIME).includes(scheduleTime)) {
      const companyHours = utils.getCompanyHours(scheduleTime);
      hourStart = companyHours.hourStart;
      hourEnd = companyHours.hourEnd;
    } else {
      hourStart = scheduleTime.min;
      hourEnd = scheduleTime.max;
    }
    return { start: hourStart, end: hourEnd };
  } catch (error) {
    console.error(
      'commonDateUtils.getStartEndHourOfScheduleTime error',
      error,
      { scheduleTime }
    );
    throw error;
  }
};

const getEventsScheduledOnDate = (date, upcomingEvents) => upcomingEvents.filter((event) => {
  const eventScheduledDate = DateTime.fromJSDate(
    utils.unixToDate(event.timestamp)
  );
  return (
    eventScheduledDate.year === date.year
      && eventScheduledDate.month === date.month
      && eventScheduledDate.day === date.day
  );
}).length;

utils.getNextTimestamp = ({
  account,
  company,
  date: jsDate,
  upcomingEvents
}) => {
  try {
    const isDayWeekend = (weekdayIndex) => weekdayIndex === 6 || weekdayIndex === 7;
    const timezone = account.timezone || company.timezone;

    const userSchedule = account.scheduleTime;
    const companySchedule = company.scheduleTime;

    const scheduleTime = utils.getScheduleTime(companySchedule, userSchedule);
    const { start: hourStart, end: hourEnd } = utils.getStartEndHourOfScheduleTime(scheduleTime);
    const randomHour = utils.getRandomHour(hourStart, hourEnd);
    const randomMinutes = utils.getRandomMinutes();

    let scheduledDate = DateTime.fromObject(
      {
        day: jsDate.getDate(),
        hour: randomHour,
        minute: randomMinutes,
        month: jsDate.getMonth() + 1,
        year: jsDate.getFullYear()
      },
      { zone: timezone }
    );

    let isWeekend = isDayWeekend(scheduledDate.weekday);
    let eventsScheduledOnDate = getEventsScheduledOnDate(
      scheduledDate,
      upcomingEvents
    );

    while (isWeekend) {
      scheduledDate = scheduledDate.plus({ days: 1 });
      isWeekend = isDayWeekend(scheduledDate.weekday);
    }

    while (isWeekend || eventsScheduledOnDate >= 2) {
      scheduledDate = scheduledDate.plus({ days: 1 });
      isWeekend = isDayWeekend(scheduledDate.weekday);
      eventsScheduledOnDate = getEventsScheduledOnDate(
        scheduledDate,
        upcomingEvents
      );
    }

    const t = scheduledDate.valueOf() / 1000;
    return t;
  } catch (error) {
    console.error('commonDateUtils.getNextTimestamp error', {
      error,
      account
    });
    throw error;
  }
};

utils.randomDateBeforeEndUnix = (
  endUnix,
  startHour,
  endHour,
  timezoneOffset
) => {
  try {
    const currentHours = new Date().getHours();
    let startUnix = utils.getUnixDateNow();
    startHour += -timezoneOffset / 60;
    endHour += -timezoneOffset / 60;

    if (currentHours > startHour) {
      startUnix += 86400; // add one day
    }
    const start = new Date(startUnix * 1000);
    const end = new Date(parseInt(endUnix, 10) * 1000);

    let specificDate = new Date(+start + Math.random() * (end - start));
    let hour = (startHour + Math.random() * (endHour - startHour)) | 0;
    specificDate.setUTCHours(hour);

    // make sure dates scheduled are not on the weekend. day index 6 represents saturday, 0 represents sunday, 1 represents monday
    let isSpecificDateOnWeekend = specificDate.getDay() === 6
      || specificDate.getDay() === 0
      || specificDate.getDay() === 1; // adding monday temporarily until MS Teams gets fixed

    while (
      utils.dateToUnix(specificDate) > utils.dateToUnix(end)
      || isSpecificDateOnWeekend
    ) {
      specificDate = new Date(+start + Math.random() * (end - start));
      hour = (startHour + Math.random() * (endHour - startHour)) | 0;
      specificDate.setUTCHours(hour);
      // make sure dates scheduled are not on the weekend. day index 6 represents saturday, 0 represents sunday
      isSpecificDateOnWeekend = specificDate.getDay() === 6 || specificDate.getDay() === 0;
    }

    return utils.dateToUnix(specificDate);
  } catch (err) {
    console.error('commonDateUtils.randomDateBeforeEndUnix error', err);
    return false;
  }
};

utils.unixToTimedateFormat = (unix) => {
  const dateObj = new Date(unix * 1000);
  const day = dateObj.getDate();
  const monthIndex = dateObj.getMonth() + 1;
  const year = dateObj.getFullYear();
  return `${year}-${addZero(monthIndex)}-${addZero(day)}T12:00`;
};

utils.dateToMonthDayFormat = (dateObj, separator = '.') => {
  const day = dateObj.getDate();
  const monthIndex = dateObj.getMonth();
  const month = SHORT_MONTHS[monthIndex];
  return `${month}${separator} ${day}`;
};

utils.dateToMonthYearFormat = (dateObj) => {
  const monthIndex = dateObj.getMonth();
  const year = dateObj.getFullYear();
  const month = SHORT_MONTHS[monthIndex];
  return `${month} ${year}`;
};

utils.dateToMonthDayYearFormat = (dateObj) => {
  const day = dateObj.getDate();
  const monthIndex = dateObj.getMonth();
  const year = dateObj.getFullYear();
  const month = SHORT_MONTHS[monthIndex];
  return `${month} ${day}, ${year}`;
};

utils.unixToMonth = (unix) => {
  const dateObj = new Date(unix * 1000);
  const monthIndex = dateObj.getMonth();
  const month = MONTHS[monthIndex];
  return month;
};

utils.unixToMonthYear = (unix) => {
  const dateObj = new Date(unix * 1000);
  const monthIndex = dateObj.getMonth();
  const month = MONTHS[monthIndex];
  const year = dateObj.getFullYear();
  return `${month} ${year}`;
};

utils.unixToMonthDayYearFormat = (unix) => {
  const dateObj = new Date(unix * 1000);
  const day = dateObj.getDate();
  const monthIndex = dateObj.getMonth();
  const year = dateObj.getFullYear();
  const month = SHORT_MONTHS[monthIndex];
  return `${month} ${day}, ${year}`;
};

utils.unixToMonthDayYearHourMinutesFormat = (unix) => {
  const dateObj = new Date(unix * 1000);
  const day = dateObj.getDate();
  const monthIndex = dateObj.getMonth();
  const year = dateObj.getFullYear();
  const month = SHORT_MONTHS[monthIndex];
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  return `${month} ${day}, ${year} ${hours}:${String(minutes).padStart(
    2,
    '0'
  )}`;
};

utils.unixToMonthDayFormat = (unix) => {
  const dateObj = new Date(unix * 1000);
  const day = dateObj.getDate();
  const monthIndex = dateObj.getMonth();
  const month = SHORT_MONTHS[monthIndex];
  return `${month} ${day}`;
};

utils.getDateFromDaysAgo = (days) => {
  const date = new Date(new Date().getTime());
  date.setDate(date.getDate() - days);
  return {
    unix: utils.dateToUnix(date),
    date
  };
};

utils.getUnixDateFromDaysAgo = (days) => {
  const date = new Date(new Date().getTime());
  date.setDate(date.getDate() - days);
  const unix = utils.dateToUnix(date);
  return unix;
};

utils.secondsToHours = (seconds) => parseFloat(seconds) / 3600;

utils.getDateDaysFromNow = (days) => {
  const date = new Date(new Date().getTime());
  date.setDate(date.getDate() + days);
  return {
    unix: utils.dateToUnix(date),
    date
  };
};

utils.getUnixDateFromNow = (days) => {
  const date = new Date(new Date().getTime());
  date.setDate(date.getDate() + days);
  const unix = utils.dateToUnix(date);
  return unix;
};

const addZero = (i) => {
  if (i < 10) {
    i = `0${i}`;
  }
  return i;
};

utils.unixToMonthDayYearTimeFormatUTC = (unix) => {
  if (!unix) {
    console.error('unixToMonthDayYearTimeFormatUTC date not provided', unix);
  }
  const dateObj = new Date(unix * 1000);
  const day = dateObj.getUTCDate();
  const monthIndex = dateObj.getUTCMonth();
  const year = dateObj.getUTCFullYear();
  const month = SHORT_MONTHS[monthIndex];

  const hours = addZero(dateObj.getUTCHours());
  const mins = addZero(dateObj.getUTCMinutes());
  const ampm = hours >= 12 ? 'pm' : 'am';
  const hoursToDisplay = dateObj.getUTCHours() % 12 || 12;
  return `${month} ${day}, ${year} (${hoursToDisplay}:${mins}${ampm} GMT)`;
};

utils.dateToMonthDayYearTimeFormatUTC = (dateObj) => {
  const day = dateObj.getUTCDate();
  const monthIndex = dateObj.getUTCMonth();
  const year = dateObj.getUTCFullYear();
  const month = SHORT_MONTHS[monthIndex];

  const hours = addZero(dateObj.getUTCHours());
  const mins = addZero(dateObj.getUTCMinutes());
  const ampm = hours >= 12 ? 'pm' : 'am';
  const hoursToDisplay = dateObj.getUTCHours() % 12 || 12;
  return `${month} ${day}, ${year} (${hoursToDisplay}:${mins}${ampm} GMT)`;
};

utils.unixToMonthDayYearTimeFormat = (unix) => {
  if (!unix) {
    console.error('unixToMonthDayYearTimeFormat date not provided', unix);
  }
  const dateObj = new Date(unix * 1000);
  const day = dateObj.getDate();
  const monthIndex = dateObj.getMonth();
  const year = dateObj.getFullYear();
  const month = SHORT_MONTHS[monthIndex];

  const hours = addZero(dateObj.getHours());
  const mins = addZero(dateObj.getMinutes());
  const ampm = hours >= 12 ? 'pm' : 'am';
  const hoursToDisplay = dateObj.getHours() % 12 || 12;
  return `${month} ${day}, ${year} (${hoursToDisplay}:${mins}${ampm})`;
};

utils.dateToMonthDayYearTimeFormat = (dateObj) => {
  const day = dateObj.getDate();
  const monthIndex = dateObj.getMonth();
  const year = dateObj.getFullYear();
  const month = SHORT_MONTHS[monthIndex];

  const hours = addZero(dateObj.getHours());
  const mins = addZero(dateObj.getMinutes());
  const ampm = hours >= 12 ? 'pm' : 'am';
  const hoursToDisplay = dateObj.getHours() % 12 || 12;
  return `${month} ${day}, ${year} (${hoursToDisplay}:${mins}${ampm})`;
};

utils.checkDateAttribute = (object, year, month, day) => {
  if (!object[year]) {
    object[year] = {};
  }
  if (!object[year][month]) {
    object[year][month] = {};
  }
  if (!object[year][month][day]) {
    object[year][month][day] = 0;
  }
};

utils.formatAMPM = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}:${minutes}${ampm}`;
};

utils.isWeekday = (date) => {
  const day = date.getDay();
  return day >= 1 && day <= 5; // Monday to Friday
};

utils.isDateToday = (date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate()
    && date.getMonth() === today.getMonth()
    && date.getFullYear() === today.getFullYear()
  );
};

utils.isUnixToday = (unix) => {
  const dateObj = new Date(unix * 1000);
  return utils.isDateToday(dateObj);
};

// June 9 2014
// utils.unixToShortDescriptiveDate = (unixTimestamp) => {
//   return moment.unix(unixTimestamp).format('LL');
// };

utils.getFirstUnixOfDate = (date) => {
  let dateObj = date;

  if (typeof date === 'string' || typeof date === 'number') {
    dateObj = new Date(date);
  }

  const firstUnixFromDate = dateObj.setHours(0, 0, 0, 0) / 1000;
  return firstUnixFromDate;
};

utils.getFirstUnixOfDateFromUnix = (unix) => {
  const date = utils.unixToDate(unix);
  const firstUnixFromDate = utils.getFirstUnixOfDate(date);
  return firstUnixFromDate;
};

utils.getLastUnixOfDate = (date) => {
  let dateObj = date;

  if (typeof date === 'string' || typeof date === 'number') {
    dateObj = new Date(date);
  }

  const lastUnixFromDate = dateObj.setHours(23, 59, 59, 999) / 1000;
  return lastUnixFromDate;
};

utils.getLastUnixOfDateFromUnix = (unix) => {
  const date = utils.unixToDate(unix);
  const lastUnixFromDate = utils.getLastUnixOfDate(date);
  return lastUnixFromDate;
};

utils.getJan1st2020 = () => {
  const date = new Date('2020-01-01T00:00:00.00');
  return {
    unix: utils.dateToUnix(date),
    date
  };
};

utils.dateToTimeAgo = (date) => moment(date).fromNow();

utils.unixToTimeAgo = (unix) => {
  const date = utils.unixToDate(unix);
  return utils.dateToTimeAgo(date);
};

utils.isSameDay = (dates) => {
  const firstDate = new Date(dates[0]);
  const day = firstDate.getDate();
  const month = firstDate.getMonth();
  const year = firstDate.getFullYear();

  for (const date of dates) {
    const dateToCompare = new Date(date);
    const dayToCompare = dateToCompare.getDate();
    const monthToCompare = dateToCompare.getMonth();
    const yearToCompare = dateToCompare.getFullYear();
    if (
      day !== dayToCompare
      || month !== monthToCompare
      || year !== yearToCompare
    ) {
      return false;
    }
  }

  return true;
};

utils.getDaysBetweenDates = (date1, date2) => {
  const oneDay = 24 * 60 * 60 * 1000;
  const firstDate = new Date(date1);
  const secondDate = new Date(date2);
  return Math.round(Math.abs((firstDate - secondDate) / oneDay));
};

utils.addDaysToDate = (date, days) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

utils.getWeeksBetweenDates = (date1, date2) => {
  const firstDate = new Date(date1);
  const secondDate = new Date(date2);
  return Math.round((secondDate - firstDate) / (7 * 24 * 60 * 60 * 1000));
};

utils.addWeeksToDate = (date, weeks) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + weeks * 7);
  return newDate;
};

utils.getMonthsBetweenDates = (date1, date2) => {
  const firstDate = new Date(date1);
  const secondDate = new Date(date2);
  return (
    secondDate.getMonth()
    - firstDate.getMonth()
    + 12 * (secondDate.getFullYear() - firstDate.getFullYear())
  );
};

utils.addMonthsToDate = (date, months) => {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + months);
  return newDate;
};

utils.getYearsBetweenDates = (date1, date2) => {
  const firstDate = new Date(date1);
  const secondDate = new Date(date2);
  return secondDate.getFullYear() - firstDate.getFullYear();
};

utils.addYearsToDate = (date, years) => {
  const newDate = new Date(date);
  newDate.setFullYear(newDate.getFullYear() + years);
  return newDate;
};

export default utils;
