import React, { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Select, Button } from 'src/components/';
import SpeechBubbleOvalSVG from 'src/assets/svg/speech-bubble-oval.svg';
import NavigationItem from 'src/pagesDashboard/HomePage/components/mobile/NavigationItem';

const MobileSelectRequestFeedback = ({
  reviewerProps,
  revieweeProps,
  requestReview,
  isSubmitDisabled,
  isFeedback,
  isBasic,
  isReview,
  loadingProps,
  control,
  setValue,
  resetField
}) => {
  const navigate = useNavigate();
  const {
    reviewerOptions,
    selectedReviewer,
    setReviewerSearchText,
    isFetchingReviewerAccounts
  } = reviewerProps;
  const {
    revieweeOptions,
    selectedReviewee,
    setRevieweeSearchText,
    isFetchingRevieweeAccounts,
    revieweeHasNoReviewers
  } = revieweeProps;
  const {
    isLoadingAddQuestion,
    isLoadingSendManualReview,
    isLoadingRequestFeedback
  } = loadingProps;

  const [showRequestFeedbackLastStep, setShowRequestFeedbackLastStep] = useState(false);

  useEffect(() => {
    if (showRequestFeedbackLastStep) {
      setValue('feedbackText', '');
    }
  }, [showRequestFeedbackLastStep]);

  return (
    <div>
      <div className='w-full fixed top-0 mt-12 bg-white z-50'>
        <NavigationItem
          goBack={() => navigate('/dashboard/home')}
          title='Request Feedback'
        >
          <SpeechBubbleOvalSVG />
        </NavigationItem>
      </div>
      <div className='mt-[87px]'>
        {showRequestFeedbackLastStep ? (
          <div>
            <div className='px-3'>
              <p className='mb-1 mt-3 leading-5'>
                What would you like to receive feedback about (ex. specific
                project, campaign, event)
              </p>
              <Controller
                name='feedbackText'
                control={control}
                rules={{ required: true }}
                render={({ field, field: { onChange } }) => (
                  <textarea
                    name='feedbackText'
                    disabled={isLoadingSendManualReview || isLoadingRequestFeedback}
                    {...field}
                    className='w-full h-40 border border-gray-300 rounded-md p-2 mt-2'
                    placeholder='Add your feedback prompt here'
                    onChange={(e) => onChange(e.target.value)}
                  />
                )}
              />
            </div>
            <div className='px-6 fixed bottom-0 pb-2 w-full flex flex-col items-center w-full gap-3'>
              <Button
                variant='black'
                classes='w-full'
                onClick={requestReview}
                disabled={isSubmitDisabled}
              >
                Submit
              </Button>
              <Button
                variant='custom'
                disabled={
                  isLoadingAddQuestion
                  || isLoadingSendManualReview
                  || isLoadingRequestFeedback
                }
                classes='bg-white w-full text-black font-bold text-sm px-4 pt-2 pb-2 border rounded-md border-gray-300'
                onClick={() => navigate('/dashboard/home')}
              >
                Cancel
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <div className='px-3'>
              <p className='font-semibold text-black mb-0 mt-3'>Reviewee</p>
              <Controller
                name='selectedReviewee'
                control={control}
                rules={{ required: true }}
                render={({ field, field: { onChange } }) => {
                  let title = '';

                  if (field.value) title = field.value.label;
                  else {
                    if (isFeedback) title = 'Select a Recipient';
                    if (isReview) title = 'Select a Reviewee';
                  }

                  return (
                    <Select
                      classes='w-full'
                      {...field}
                      placeholder='selectedReviewee'
                      options={revieweeOptions}
                      title={title}
                      onChange={(revieweeOption) => {
                        resetField('selectedRole');
                        resetField('selectedReviewer');
                        resetField('scheduledDate');
                        resetField('scheduledTime');
                        onChange(revieweeOption);
                      }}
                      loading={isFetchingRevieweeAccounts}
                      showSearch
                      onSearch={(value) => setRevieweeSearchText(value)}
                      onDropdownClose={() => setRevieweeSearchText('')}
                      disabled={isBasic}
                    />
                  );
                }}
              />
              {selectedReviewee ? (
                <div className='mb-4'>
                  <p className='font-semibold text-black mb-0 mt-3'>Reviewer</p>
                  <Controller
                    name='selectedReviewer'
                    control={control}
                    rules={{ required: true }}
                    render={({ field, field: { onChange } }) => {
                      const title = field.value
                        ? field.value.label
                        : 'Select a Reviewer';
                      return (
                        <Select
                          classes='w-full'
                          {...field}
                          placeholder='selectedReviewer'
                          disabled={revieweeHasNoReviewers}
                          options={reviewerOptions}
                          title={title}
                          onChange={(reviewerOption) => {
                            resetField('selectedRole');
                            resetField('scheduledDate');
                            resetField('scheduledTime');
                            onChange(reviewerOption);
                          }}
                          loading={isFetchingReviewerAccounts}
                          showSearch
                          onSearch={(value) => setReviewerSearchText(value)}
                          onDropdownClose={() => setReviewerSearchText('')}
                        />
                      );
                    }}
                  />
                </div>
              ) : null}
            </div>
            <div className='fixed bottom-0 pb-2 px-6 w-full flex flex-col items-center w-full gap-3'>
              <Button
                variant='black'
                classes='w-full'
                onClick={() => setShowRequestFeedbackLastStep(true)}
                disabled={!selectedReviewer}
              >
                Request Feedback
              </Button>
              <Button
                variant='custom'
                disabled={isLoadingAddQuestion}
                classes='bg-white w-full text-black font-bold text-sm px-4 pt-2 pb-2 border rounded-md border-gray-300'
                onClick={() => navigate('/dashboard/home')}
              >
                Cancel
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileSelectRequestFeedback;
