import React, { useEffect, useState } from 'react';
import { update } from 'src/queries/user';
import {
  Input, Button, toast, Base
} from 'src/components/';
import { useAccount } from 'src/queries/account';
import commonUtils from 'common/commonUtils';
import { get } from 'lodash';
import STYLE from 'src/constants/style';

const UserNotConnected = () => {
  const {
    data: account,
    isFetching: isFetchingAccount,
    isError: isErrorAccount,
    refetch: refetchAccount
  } = useAccount('me');

  const isFetching = isFetchingAccount;
  const isError = isErrorAccount;

  const isReady = account && account._id && !isFetching && !isError;

  const { mutateAsync: updateUser, isLoading: isLoadingUpdateUser } = update();

  const [webexEmail, setWebexEmail] = useState('');

  useEffect(() => {
    if (!isReady) return;
    const currentWebexEmail = get(account, 'webexEmail', '');
    if (webexEmail) setWebexEmail(currentWebexEmail);
  }, [isReady]);

  if (!isReady) return null;

  const saveWebexEmail = async () => {
    const userUpdated = await updateUser({
      id: account._id,
      webexEmail: webexEmail.trim()
    });

    if (!userUpdated.success) {
      toast.error(userUpdated.error);
      return false;
    }

    const {
      data: {
        data: { webexEmail: currentWebexEmail }
      }
    } = await refetchAccount();
    if (currentWebexEmail) setWebexEmail(currentWebexEmail);
  };

  return (
    <Base loading={isLoadingUpdateUser} classes={STYLE.CONTAINER_WHITE}>
      <h5>Webex Integration</h5>
      <p className='mb-4'>
        Receive review requests and other notifications from WorkStory directly
        through Webex:
      </p>

      <div>
        <p className='mb-0'>Let's get started with your Webex email:</p>
        <Input
          onChange={(e) => setWebexEmail(e.target.value)}
          placeholder='Webex Email'
          value={webexEmail}
        />
        <Button
          classes='mb-3'
          variant='yellow'
          onClick={saveWebexEmail}
          disabled={
            !webexEmail
            || webexEmail === account.webexEmail
            || !commonUtils.isEmailValid(webexEmail)
            || isLoadingUpdateUser
          }
        >
          Next
        </Button>
      </div>
    </Base>
  );
};

export default UserNotConnected;
