import React, {
  useMemo, useEffect, useState, useCallback
} from 'react';
import { useUserFeedback } from 'src/containers/UserProfile/FeedbackDashboard/queries';
import FeedbackList from 'src/containers/Feedback/FeedbackList/FeedbackList';
import { useCreateNote } from 'src/hooks/UserReports/useNote';
import { useCompany } from 'src/queries/company';
import {
  getReportAiNoteQuery,
  useReport,
  useReportNotes
} from 'src/queries/reports';
import {
  NotesNewReport,
  AddNoteNewReport
} from 'src/pagesDashboard/NewUserReport/components';
import appUtils from 'src/components/appUtils';
import COMMON_CONSTANTS from 'common/commonConstants';

const {
  REPORT_NOTE_TYPES,
  REPORT_TEMPLATE_TYPES,
  FEEDBACK_TYPE,
  REPORT_PROMISE_TYPES
} = COMMON_CONSTANTS;

const FeedbackListSection = ({
  userId,
  reportId,
  previousReport,
  setIsLoading = () => {},
  isEditorOpenByDefault,
  viewOnly = false
}) => {
  const loggedUser = appUtils.getLoggedUser();
  const [includedEditNoteFeedback, setIncludedEditNoteFeedback] = useState('');
  const [includedNewNoteFeedback, setIncludedNewNoteFeedback] = useState('');
  const [notesOpen, setNotesOpen] = useState(null);
  const [feedbackNotesTemplates, setFeedbackNotesTemplates] = useState([]);

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const {
    data: report,
    isFetching: isFetchingReport,
    isError: isErrorReport
  } = useReport(reportId, {
    type: REPORT_NOTE_TYPES.FEEDBACK
  });

  const reviewFilters = report
    ? {
      start: report.start,
      end: report.end,
      category: report.categories[0],
      reviewerIds: report.reviewerIds,
      reviewerGroup: report.reviewerGroup,
      roles: report.roles,
      includeUnassignedUsers: true
    }
    : {};

  const {
    data: { userFeedbacks: feedbacks, pagination },
    isFetching: isFetchingFeedbacks,
    isError: isErrorFeedbacks
  } = useUserFeedback(userId, {
    range: { start: reviewFilters.start, end: reviewFilters.end },
    filters: reviewFilters,
    pages: {
      page: 1,
      pageSize: 1000
    },
    viewerId: loggedUser.id
  });

  const { data: feedbackNotes } = useReportNotes(reportId, {
    type: REPORT_NOTE_TYPES.FEEDBACK
  });

  const { data: previousFeedbackNotes } = useReportNotes(previousReport, {
    type: REPORT_NOTE_TYPES.FEEDBACK
  });

  const { saveNote } = useCreateNote();

  const isFetching = isFetchingReport || isFetchingFeedbacks || isFetchingCompany;

  useEffect(() => {
    setIsLoading(isFetching);
  }, [isFetching]);

  const isError = isErrorReport || isErrorFeedbacks || isErrorCompany;
  const isReady = !isFetching && !isError && report && feedbacks;

  useEffect(() => {
    if (company && company.templates) {
      const templates = company.templates
        .filter((t) => t.type === REPORT_TEMPLATE_TYPES.FEEDBACK_TEMPLATE)
        .map((t) => ({
          id: t.id,
          label: t.name,
          content: t.content
        }));
      templates.unshift({
        id: 'clear',
        label: 'No template',
        content: ''
      });
      setFeedbackNotesTemplates(templates);
    }
  }, [company]);

  const addToNotes = useCallback(
    (noteId) => {
      if (noteId) {
        const feedback = feedbacks.find((r) => r.id === noteId);
        if (feedback) {
          if (notesOpen === 'edit') {
            setIncludedEditNoteFeedback(`${feedback.text}\n`);
            setTimeout(() => setIncludedEditNoteFeedback(''), 20);
          }
          if (!notesOpen || notesOpen === 'new') {
            setIncludedNewNoteFeedback(`${feedback.text}\n`);
            setTimeout(() => setIncludedNewNoteFeedback(''), 20);
          }
        }
      }
    },
    [feedbacks, notesOpen]
  );

  const clearIncludedText = (input) => {
    if (input === 'edit') {
      setIncludedEditNoteFeedback('');
      clearIncludedText('new');
    }
    if (input === 'new') setIncludedNewNoteFeedback('');
    setNotesOpen(null);
  };

  const feebackListComponent = useMemo(
    () => (
      <FeedbackList
        userId={userId}
        view={FEEDBACK_TYPE.REVIEW}
        list={feedbacks || []}
        onAction={addToNotes}
        actionText='Add to Notes'
        showShareBtn={false}
        showViewBtn={false}
        showActionRule={(feedback) => feedback.text && feedback.text.length}
      />
    ),
    [userId, feedbacks, addToNotes]
  );

  const { mutateAsync: getReportAiNote, isLoading: isLoadingGetReportAiNote } = getReportAiNoteQuery();
  const generateAiNote = async () => {
    const params = {
      reportId,
      userId,
      type: REPORT_PROMISE_TYPES.FEEDBACK_SINGLE
    };
    return getReportAiNote(params);
  };

  return (
    <div>
      <div
        className={`block ${
          feedbacks && feedbacks.length ? 'h-30rem' : 'max-h-15rem'
        } overflow-y-scroll`}
      >
        {feebackListComponent}
      </div>

      <div className='flex flex-col mb-10'>
        <NotesNewReport
          reportId={reportId}
          title='Notes'
          notes={feedbackNotes ?? []}
          previousNotes={previousFeedbackNotes ?? []}
          includeText={includedEditNoteFeedback}
          clearIncludeText={() => clearIncludedText('edit')}
          onFieldOpen={() => setNotesOpen('edit')}
        />

        {!viewOnly && feedbackNotes && notesOpen !== 'edit' ? (
          <AddNoteNewReport
            title='Feedback note'
            reportId={reportId}
            noteType={REPORT_NOTE_TYPES.FEEDBACK}
            includeText={includedNewNoteFeedback}
            templatesOptions={feedbackNotesTemplates}
            isOpenByDefault={isEditorOpenByDefault ?? !feedbackNotes.length}
            onSave={saveNote}
            clearIncludeText={() => clearIncludedText('new')}
            onFieldOpen={() => setNotesOpen('new')}
            parentValues={{
              noteCount: feedbackNotes.length,
              dataCount: pagination?.total ?? 0
            }}
            generateNoteCallback={generateAiNote}
            isGenerateNoteLoading={isLoadingGetReportAiNote}
          />
        ) : null}
      </div>
    </div>
  );
};

export default FeedbackListSection;
