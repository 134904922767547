import React, { useState, useContext } from 'react';
import { Button, toast } from 'src/components';
import CopyIconSVG from 'src/assets/copy-icon.svg';
import appUtils from 'src/components/appUtils';
import DOMPurify from 'dompurify';
import commonUtils from 'common/commonUtils';
import {
  sendShareLinkEmail,
  useBuilderReport,
  useCreatePdf
} from 'src/queries/builder';
import commonDateUtils from 'common/commonDateUtils';
import { PerformanceBuilderContext } from 'src/pages/PerformanceBuilder/PerformanceBuilderContext';
import { Flyout } from 'src/componentsTailwind/index';

const ShareFlyout = (props = {}) => {
  const { setIsOpen, signUpCallback, width } = props;

  const {
    data: { reportId }
  } = useContext(PerformanceBuilderContext);
  const { data: reportData } = useBuilderReport(reportId);
  const { mutateAsync: createPdf, isLoading: isLoadingCreatePdf } = useCreatePdf();

  const {
    reviewer,
    title,
    reviewDate,
    editToken,
    companyid,
    industryId,
    roleId
  } = reportData;

  const formattedReviewDate = commonDateUtils.dateToMonthDayYearFormat(
    new Date(reviewDate)
  );
  const [emails, setEmails] = useState('');

  const editLink = `https://app.workstory.team/builder/${editToken}/edit`;
  const viewLink = `https://app.workstory.team/builder/${reportId}/view`;

  const sanitizedText = (text) => ({
    __html: DOMPurify.sanitize(text)
  });

  const copyToClipboard = (text) => {
    const html = sanitizedText(text).__html;
    const temp = document.createElement('div');
    temp.innerHTML = html;
    const { textContent } = temp;
    appUtils.copyTextToClipboard(textContent);
  };

  const { mutateAsync: sendShareLink, isLoading: isSendShareLinkLoading } = sendShareLinkEmail();

  const handleSendShareLink = async () => {
    const result = await sendShareLink({
      emails,
      link: viewLink,
      reviewerName: `${reviewer.firstName} ${reviewer.lastName}`,
      title,
      reportId,
      reviewDate: formattedReviewDate
    });
    if (result) {
      toast.show('Link sent!');
      setIsOpen(false);
    }
  };

  const onClickCreatePdf = async () => {
    toast.show('Creating PDF');
    let response;
    try {
      response = await createPdf({ reportIds: [reportId], companyid });
    } catch (e) {
      return toast.error('Failed to create PDF');
    }
    toast.show('Report exported as pdf');

    const { documentUrl, name } = response;
    const a = document.createElement('a');
    a.setAttribute('href', documentUrl);
    a.setAttribute('download', name);
    const el = document.getElementById('app');
    el.appendChild(a);
    a.click();
  };

  const isLoggedIn = appUtils.isLoggedIn();

  const isCreatePdfButtonDisabled = () => {
    if (appUtils.isSuperUser()) {
      return false;
    }

    if (!isLoggedIn) return true;

    if (isLoadingCreatePdf) return true;

    if (!industryId || !roleId) return true;
  };

  const isShareButtonDisabled = () => {
    if (appUtils.isSuperUser()) {
      return false;
    }

    if (!isLoggedIn) return true;

    if (isSendShareLinkLoading) return true;

    const emailsArray = emails.trim().split(',');

    if (!emailsArray.length) return true;

    const invalidEmails = emailsArray.filter(
      (email) => !commonUtils.isEmailValid(email.trim())
    );

    if (invalidEmails.length) return true;
  };

  return (
    <Flyout {...props}>
      <div
        className='flex flex-col justify-start h-full w-[100%]'
        loading={(isSendShareLinkLoading || isLoadingCreatePdf).toString()}
      >
        <h5 className='font-bold text-xl mb-2'>Share your review</h5>
        <div>
          <p className='mb-5 text-sm text-[#475467]'>
            You’ve created a new performance review. Share the results with
            others.
          </p>
          <p className='mb-1 bold text-sm'>Edit link</p>
          <p className='mb-1 text-sm text-[#475467]'>
            Anyone with the link can edit the review
          </p>
          <div className='flex items-center mb-5'>
            <input
              className='bg-white rounded-lg border border-slate-300 h-10 w-[90%] outline-none'
              value={editLink}
              readOnly
            />
            <div className='flex items-center w-[10%] justify-center'>
              <button
                onClick={() => copyToClipboard(editLink)}
                className='focus:outline-0 focus:outline-none cursor-pointer text-right tooltip'
              >
                <CopyIconSVG className='text-gray-400 hover:text-gray-600 transition-all duration-200' />
              </button>
            </div>
          </div>
          <p className='mb-1 bold text-sm'>Share link</p>
          <p className='mb-1 text-sm text-[#475467]'>
            Anyone with the link can view the review
          </p>
        </div>
        <div className='flex items-center mb-5'>
          <input
            className='bg-white rounded-lg border border-slate-300 h-10 w-[90%] outline-none'
            value={viewLink}
            readOnly
          />
          <div className='flex items-center w-[10%] justify-center'>
            <button
              onClick={() => copyToClipboard(viewLink)}
              className='focus:outline-0 focus:outline-none cursor-pointer text-right tooltip justify-middle'
            >
              <CopyIconSVG className='text-gray-400 hover:text-gray-600 transition-all duration-200' />
            </button>
          </div>
        </div>
        <div
          className={`flex flex-col self-center ${isLoggedIn ? 'bg-white' : 'bg-tertiary-gray p-7'}`}
          style={{
            width: isLoggedIn ? '100%' : `${width}vw`
          }}
        >
          {isLoggedIn ? null : (
            <span className='flex items-center gap-1 w-full mb-2'>
              <p className='font-[550] text-md m-0'>
                These features are available for registered users
              </p>
              <p className='font-[550] text-md m-0'>{' - '}</p>
              <button
                onClick={signUpCallback}
                className='font-semibold text-purple'
              >
                Sign up
              </button>
            </span>
          )}
          <div className='flex flex-col'>
            <p className='mb-1 bold text-sm leading-5'>Email share link</p>
            <p className='mb-1 text-sm text-[#475467] leading-5'>
              Use commas to separate emails
            </p>
            <div className='flex items-center gap-4 mb-5'>
              <input
                disabled={!isLoggedIn}
                placeholder='Emails'
                className='bg-white rounded-lg border border-slate-300 h-10 w-2/3'
                value={emails}
                onChange={(e) => setEmails(e.target.value)}
              />
              <Button
                variant='custom'
                classes='bg-purple text-white w-1/3 rounded  h-10'
                onClick={() => handleSendShareLink()}
                disabled={isShareButtonDisabled()}
              >
                Share
              </Button>
            </div>
          </div>
          <div className='flex items-center gap-4'>
            <div className='w-2/3'>
              <p className='mb-1 bold text-sm leading-5'>Download PDF</p>
              <p className='mb-1 text-sm text-[#475467] leading-5'>
                Get a printable performance review
              </p>
            </div>
            <Button
              variant='custom'
              classes='bg-purple text-white w-1/3 rounded h-10'
              onClick={() => onClickCreatePdf()}
              disabled={isCreatePdfButtonDisabled()}
            >
              Download PDF
            </Button>
          </div>
        </div>
      </div>
    </Flyout>
  );
};

export default ShareFlyout;
