import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'src/components';
import appUtils from 'src/components/appUtils';
import WorkStoryTitle from 'src/assets/svg/workstory-title.svg';
import { useNavigate, useRouteError } from 'react-router-dom';

const ErrorUI = () => {
  const { error } = useRouteError();
  const navigate = useNavigate();
  const isSuperUser = appUtils.isSuperUser();
  const isProd = process.env.ENV === 'prod';

  const isLoggedIn = appUtils.isLoggedIn();
  const fallbackRoute = isLoggedIn ? appUtils.getHomeRoute() : '/login';

  const [shouldRenderErrorMessage, setShouldRenderErrorMessage] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(10);

  const intervalId = useRef(null);
  useEffect(() => {
    intervalId.current = setInterval(() => {
      setSecondsLeft((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(intervalId.current);
  }, []);

  useEffect(() => {
    if (!secondsLeft) {
      return navigate(fallbackRoute);
    }
  }, [secondsLeft]);

  const canShowError = Boolean(error) && (!isProd || isSuperUser);
  return (
    <div className='w-screen h-screen flex flex-col justify-between'>
      {canShowError ? (
        <>
          <button
            className={`text-white bg-red p-2 rounded-lg m-2 w-20 ${shouldRenderErrorMessage ? `hidden` : `block`}`}
            onClick={() => setShouldRenderErrorMessage(true)}
          >
            Show Error
          </button>
          {shouldRenderErrorMessage ? (
            <div className='text-white bg-red p-2 break-all rounded-lg m-2 max-h-[calc(100%-16px)] overflow-auto'>
              <button
                className='m-2'
                onClick={() => setShouldRenderErrorMessage(false)}
              >
                Close
              </button>
              <p className='m-0'>{error.message.toString()}</p>
              <p className='m-0'>----</p>
              <p className='m-0 whitespace-pre-line'>{error.stack}</p>
            </div>
          ) : null}
        </>
      ) : null}
      <div className='mt-8 mx-auto'>
        <WorkStoryTitle fill='#1C2434' />
      </div>

      <div className='flex flex-col items-center gap-1 mb-[50vh] mx-auto'>
        <p className='my-4 text-black text-4xl font-bold'>
          Oops, Wrong Turn...
        </p>
        <p className='m-0 text-black text-lg leading-4'>
          It seems this page didn't quite meet expecations.
        </p>
        <p className='m-0 text-black text-lg leading-4'>
          Don't worry, it's under review!
        </p>
        <p className='my-4 text-black text-lg leading-4'>
          Our team has been notified and is on it.
        </p>
        <Button
          onClick={() => window.location.replace(fallbackRoute)}
          variant='black'
          classes='w-[170px]'
        >
          {`Back to ${isLoggedIn ? 'Home' : 'Login'} (${secondsLeft})`}
        </Button>
      </div>
    </div>
  );
};
export default ErrorUI;
