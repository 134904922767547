import React from 'react';
import EditSVG from '../../../assets/edit-3.svg';
import './TreeButton.scss';

const TreeButton = ({
  classes, side, onClick, rest
}) => {
  classes = classes || '';
  onClick = onClick || (() => {});
  return (
    <button
      onClick={onClick}
      className={`${classes} tree-button tree-button-${side} focus:outline-none`}
      {...rest}
    >
      <span className='plus flex items-center plus'>+</span>
      <span className='edit'>
        <EditSVG />
      </span>
    </button>
  );
};

export default TreeButton;
