import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { get, isEmpty } from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { useCompany } from 'src/queries/company';
import { useTree } from 'src/queries/tree';
import { useAccount } from 'src/queries/account';
import {
  QUERY_KEYS as FEEDBACK_QUERY_KEYS,
  useFeedbackUpdate,
  useFeedbackV2
} from 'src/queries/feedback';
import DeleteFeedbackModal from 'src/containers/Feedback/FeedbackDetails/components/DeleteFeedbackModal';
import FeedbackQuill from 'src/components/Quill/FeedbackQuill';
import {
  Button, Base, Circle, toast, Radio
} from 'src/components';
import { formatNoteText } from 'src/pagesDashboard/NewUserReport/utils';
import appUtils from 'src/components/appUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import commonPermissions from 'common/commonPermissions';
import commonReviewUtils from 'common/commonReviewUtils';
import commonQuestionUtils from 'common/commonQuestionsUtils';
import CrossSVG from 'src/assets/cross.svg';
import STYLE from 'src/constants/style';

const { SHARE_REVIEW_WITH, renderShareCommentsVisibilityOptions } = COMMON_CONSTANTS;

const displayFormErrors = (errors) => {
  Object.keys(errors).forEach((key) => {
    const val = errors[key];
    const message = get(val, 'message');
    if (!message) {
      return;
    }
    toast.error(message);
  });
};

const EditFeedback = () => {
  const navigate = useNavigate();
  const { feedbackId } = useParams();
  const close = () => navigate(-1);
  const queryClient = useQueryClient();
  const {
    data: loggedAccount,
    isFetching: isFetchingLoggedAccount,
    isError: isErrorLoggedAccount
  } = useAccount('me');
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const {
    data: feedback,
    isFetching: isFetchingFeedback,
    isError: isErrorFeedback
  } = useFeedbackV2(feedbackId, {
    include: {
      reviewer: true,
      reviewee: true
    }
  });

  const [showDeleteFeedbackModal, setShowDeleteFeedbackModal] = useState(false);

  const isFetching = isFetchingFeedback
    || isFetchingCompany
    || isFetchingTree
    || isFetchingLoggedAccount;
  const isError = isErrorCompany || isErrorTree || isErrorFeedback || isErrorLoggedAccount;
  const isReady = company
    && company.id
    && tree
    && tree.id
    && !isEmpty(feedback)
    && !isFetching
    && !isError;

  const { update: updateFeedback, isLoading: isLoadingUpdateFeedback } = useFeedbackUpdate(feedbackId);

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors, isValid },
    reset
  } = useForm({
    mode: 'onChange'
  });

  const formValues = watch();

  useEffect(() => {
    if (isEmpty(feedback) && !isFetchingFeedback) {
      toast.error('Feedback not found');
      navigate(-1);
    }
    if (!isEmpty(feedback) && !isFetchingFeedback) {
      const { reviewee, reviewer } = feedback;

      if (
        !commonPermissions.canViewFeedback({
          tree,
          revieweeId: reviewee._id,
          reviewerId: reviewer._id,
          account: loggedAccount
        })
      ) {
        toast.error('You do not have enough permissions to edit this review');
        navigate(appUtils.getDashRoute());
      }
    }
  }, [feedback, isFetchingFeedback, loggedAccount, navigate, tree]);

  useEffect(() => {
    if (isEmpty(formValues) && !isEmpty(feedback) && !isFetchingFeedback) {
      const formattedFeedbackText = feedback.text
        ? feedback.text.replaceAll('\n', '<br/>')
        : feedback.text;

      const initialValues = {
        commentVisible: feedback.commentVisible,
        text: formattedFeedbackText,
        reason: ''
      };
      reset(initialValues);
    }
  }, [feedback, formValues, isFetchingFeedback, reset]);

  if (!isReady) {
    return null;
  }

  const { reviewee } = feedback;

  const visibilityOptions = renderShareCommentsVisibilityOptions({
    revieweeName: reviewee.name
  });
  const validVisibilityOptions = commonQuestionUtils.getShareCommentOptions(
    company.settings.reviewVisibilityOptions,
    visibilityOptions
  );

  const isAnonymous = feedback.commentVisible === SHARE_REVIEW_WITH.ANONYMOUS;

  const error = () => displayFormErrors(errors);

  const saveUpdate = async () => {
    const { reason, text, commentVisible } = watch();

    let formattedText = text;
    const plainText = commonReviewUtils.getPlainTextFromHTML(formattedText);
    if (!plainText.length) formattedText = null;

    const data = {
      feedbackId: feedback._id,
      commentVisible,
      reason,
      text: formattedText
    };
    const response = await updateFeedback({ data });

    if (!response || !response.success) {
      console.error('Failed to edit a feedback', {
        response,
        feedback
      });
      return toast.error("Oops, we've run into an issue. Try again later!");
    }

    toast.show('Feedback updated');

    queryClient.invalidateQueries(FEEDBACK_QUERY_KEYS.FEEDBACK);
    queryClient.invalidateQueries(FEEDBACK_QUERY_KEYS.FEEDBACK_FEED);
    close();
  };

  const isSaveDisabled = () => {
    if (isLoadingUpdateFeedback) return true;
    const { text, commentVisible, reason } = watch();

    const formattedFeedbackText = feedback.text
      ? feedback.text.replaceAll('\n', '<br/>')
      : feedback.text;

    const plainComments = commonReviewUtils.getPlainTextFromHTML(text);
    const plainPreviousComments = commonReviewUtils.getPlainTextFromHTML(
      formattedFeedbackText
    );

    const isFeedbackDataChanged = plainComments !== plainPreviousComments
      || commentVisible !== feedback.commentVisible;
    const isReasonEmpty = reason === '';
    if (!isReasonEmpty && isFeedbackDataChanged && isValid) return false;
    return true;
  };

  return (
    <Base
      variant='transparent'
      classes={STYLE.BASE}
      loading={isFetching || isLoadingUpdateFeedback}
    >
      {showDeleteFeedbackModal ? (
        <DeleteFeedbackModal
          className='mt-48'
          close={() => setShowDeleteFeedbackModal(false)}
          data={feedback}
        />
      ) : null}
      <Base classes={`${STYLE.CONTAINER_WHITE_PADDINGLESS}`}>
        <div>
          <div className='h-32 -mb-18 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-t-md relative'>
            <div className='absolute right-0'>
              <button className='mr-2 mt-2' onClick={close}>
                <CrossSVG className='w-12 h-12 cursor-pointer' />
              </button>
            </div>
          </div>
          <div className='pb-6 flex flex-col items-center'>
            <Circle
              size='xxl'
              imageUrl={reviewee.imageUrl}
              classes='mb-4 user-setting-profile-image relative h-131 w-131'
            />
            <div className='text-center'>
              <div className='text-2xl bold'>{reviewee.name}</div>
              <div className='text-base text-gray-400'>{reviewee.title}</div>
            </div>
          </div>
        </div>
        <div className='p-4'>
          <div className='inline-block w-2/5 align-top text-left pl-2'>
            <h5 className='text-black focus:outline-none text-2xl mr-5 mb-2 inline-block font-bold mt-2'>
              Edit Feedback
            </h5>
          </div>
        </div>
        <div className='inline-block w-4/5 pr-16 pl-6 pt-8'>
          <div className='flex justify-between h-auto my-10'>
            <p className='mb-0 font-bold text-base text-gray-500'>FEEDBACK *</p>
            <div className='pl-8 pr-6 w-4/6'>
              <Controller
                control={control}
                name='text'
                rules={{
                  validate: (value) => {
                    // min text length === 10
                    if (!value) return false;
                    const plainText = commonReviewUtils.getPlainTextFromHTML(value);
                    const plainTextWithNoSpaces = plainText.replaceAll(' ', '');
                    if (plainTextWithNoSpaces.length < 10) return false;
                    return true;
                  }
                }}
                render={({ field }) => (
                  <FeedbackQuill
                    value={field.value}
                    className='flex flex-col h-20rem pb-10'
                    onChange={(value) => {
                      field.onChange(formatNoteText(value));
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className='flex justify-between my-10'>
            <p className='mb-0 font-bold text-base text-gray-500'>
              COMMENT VISIBILITY
            </p>
            <div className='w-4/6 pl-8'>
              {visibilityOptions.map((option, index) => {
                const isOptionValid = validVisibilityOptions.some(
                  (ao) => ao.value === option.value
                );
                const isCurrent = feedback.commentVisible === option.value;
                if (!isOptionValid && !isCurrent) return null;

                return (
                  <div key={index} className='mb-2 font-bold'>
                    <Controller
                      name='commentVisible'
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { value, onChange } }) => (
                        <Radio
                          name='commentVisible'
                          checked={option.value === formValues.commentVisible}
                          value={value}
                          title={option.title}
                          onChange={() => onChange(option.value)}
                          disabled={isAnonymous || !isOptionValid}
                        />
                      )}
                    />
                    <div>
                      <p className='text-dark-grey'>{option.comments}</p>
                    </div>
                  </div>
                );
              })}
              {formValues.commentVisible === SHARE_REVIEW_WITH.ANONYMOUS ? (
                <p className='text-xs text-red'>
                  Note! Anonymous feedback cannot have their visibility updated.
                </p>
              ) : null}
            </div>
          </div>
          <div className='flex justify-between h-40 my-10'>
            <p className='mb-0 font-bold text-base text-gray-500'>REASON *</p>
            <div className='pl-8 pr-6 h-16 w-4/6'>
              <Controller
                control={control}
                name='reason'
                rules={{
                  required: true
                }}
                render={({ field }) => (
                  <textarea
                    className='block w-full resize-none h-40'
                    {...field}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className='flex mt-4 p-4 justify-between items-center'>
          <div className='inline-block'>
            <Button
              onClick={() => setShowDeleteFeedbackModal(true)}
              variant='red'
            >
              <span className='text-lg font-bold'>Delete</span>
            </Button>
          </div>

          <div className='p-4'>
            <Button
              onClick={handleSubmit(saveUpdate, error)}
              variant='yellow'
              disabled={isSaveDisabled()}
            >
              Done
            </Button>
          </div>
        </div>
      </Base>
    </Base>
  );
};

export default EditFeedback;
