import { isNil } from 'lodash';
import React, { useContext, useState } from 'react';
import { Base, Pagination, appUtils } from 'src/components/';
import STYLE from 'src/constants/style';
import { DashContext } from 'src/pagesDashboard/Dash/context/DashProvider';
import { UserProfileContext } from 'src/pagesDashboard/UserProfile/context/UserProfileProvider';
import { useAccount, useAccountScore, useAccounts } from 'src/queries/account';
import Arrow from 'src/assets/keyboard_arrow_up.svg';
import ClipboardListSVG from 'src/assets/svg/clipboard-list-unfilled.svg';

const ReviewCount = ({ userId }) => {
  const loggedUserId = appUtils.getLoggedUserId();
  const isMe = loggedUserId === userId;
  const { context } = useContext(isMe ? DashContext : UserProfileContext);
  const { start, end } = context.range;

  const [sort, setSort] = useState('desc');
  const [currentPage, setCurrentPage] = useState(1);

  const {
    data: scoresByReviewer,
    isFetching: isFetchingScoresByReviewer,
    isError: isErrorScoresByReviewer
  } = useAccountScore(
    {
      id: userId,
      ...(start && {
        start,
        end
      })
    },
    {
      role: 'reviewee',
      bundleReviewers: true
    }
  );

  const {
    data: account,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount(userId);

  const {
    data: bundledReviewerAccounts,
    isFetching: isFetchingBundledReviewerAccounts,
    isError: isErrorBundledReviewerAccounts
  } = useAccounts(
    {
      ids: scoresByReviewer?.bundledReviewers?.map((reviewer) => reviewer._id)
    },
    {
      page: {
        size: scoresByReviewer?.bundledReviewers?.length
      },
      projection: ['name']
    },
    {
      enabled: Boolean(scoresByReviewer?.bundledReviewers?.length)
    }
  );

  const isFetching = isFetchingScoresByReviewer
    || isFetchingBundledReviewerAccounts
    || isFetchingAccount;
  const isError = isErrorScoresByReviewer || isErrorBundledReviewerAccounts || isErrorAccount;
  const isReady = !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const pageSize = 10;
  const paginatedData = [];

  const { bundledReviewers } = scoresByReviewer;
  bundledReviewers
    .sort((a, b) => (sort === 'desc'
      ? b.reviewCount - a.reviewCount
      : a.reviewCount - b.reviewCount))
    .forEach((reviewer, index) => {
      if (index % pageSize === 0) paginatedData.push([]);

      const reviewerAccount = bundledReviewerAccounts.find(
        (acc) => acc._id === reviewer._id
      );
      paginatedData.at(-1).push({
        ...reviewer,
        name: reviewerAccount.name
      });
    });

  return (
    <Base classes={STYLE.CONTAINER_WHITE}>
      <h5 className='w-full text-xl flex'>Reviewers</h5>
      <p className='text-sm'>
        List of reviewers for this team member. Visible only to managers.
      </p>
      <div className='flex mb-2 py-1 px-1 bg-background-gray text-black rounded-sm'>
        <p className='mb-0 w-4/20 font-bold'>Reviewer</p>
        <p className='mb-0 w-4/20 font-bold'>Average Score</p>
        <p className='mb-0 w-4/20 font-bold'>Average Sentiment</p>
        <div className='mb-0 w-4/20 font-bold flex justify-start'>
          <p className='font-bold m-0 max-w-3/4'>Number of Reviews</p>
          <button
            onClick={() => setSort(sort === 'desc' ? 'asc' : 'desc')}
            className={`focus:outline-none transform ${
              sort === 'desc' && 'rotate-180'
            }`}
          >
            <Arrow />
          </button>
        </div>
        <p className='mb-0 w-4/20 font-bold'>"I'm not sure" Reviews</p>
      </div>

      <div className='py-1 px-1'>
        {paginatedData.length ? (
          paginatedData[currentPage - 1].map((reviewer) => {
            const {
              name,
              avgScore,
              avgSentimentScore,
              reviewCount,
              notSureCount
            } = reviewer;

            const avgScoreLabel = isNil(avgScore) ? '-' : avgScore;

            let avgSentimentScoreParagraph = '';
            if (!isNil(avgSentimentScore)) {
              if (avgSentimentScore > 0) {
                avgSentimentScoreParagraph = (
                  <p className='w-4/20 inline-block truncate'>
                    <span className='text-green-400'>{`+${avgSentimentScore} `}</span>
                    (Positive)
                  </p>
                );
              } else if (avgSentimentScore < 0) {
                avgSentimentScoreParagraph = (
                  <p className='w-4/20 inline-block truncate'>
                    <span className='text-red-400'>{`${avgSentimentScore} `}</span>
                    (Negative)
                  </p>
                );
              }
            } else {
              avgSentimentScoreParagraph = (
                <p className='w-4/20 inline-block truncate'>-</p>
              );
            }

            return (
              <div className='flex w-full justify-start' key={`${name}-row`}>
                <a
                  className='w-4/20 inline-block truncate'
                  href={appUtils.getDashRoute(reviewer._id)}
                >
                  {name}
                </a>
                <p className='w-4/20 inline-block truncate'>{avgScoreLabel}</p>
                {avgSentimentScoreParagraph}
                <p className='w-4/20 inline-block truncate'>{reviewCount}</p>
                <p className='w-4/20 inline-block truncate'>{notSureCount}</p>
                {/* <button className='focus:outline-none'>Edit Relationship</button> */}
              </div>
            );
          })
        ) : (
          <div>
            <div className='mt-6 mb-8 text-center font-bold'>
              <div className='w-10 h-10 mx-auto mb-2'>
                <ClipboardListSVG />
              </div>
              <p className='cursor-default font-bold'>
                {`${account.name} has not received reviews.`}
              </p>
            </div>
          </div>
        )}
      </div>

      <Pagination
        totalPages={paginatedData.length}
        currentPage={currentPage}
        selectPage={setCurrentPage}
      />
    </Base>
  );
};

export default ReviewCount;
