import React, { useState, useEffect } from 'react';
import { Base, Modal } from 'src/components';
import STYLE from 'src/constants/style';
import { useAccountTasks, useAccountUpdate } from 'src/queries/account';
import COMMON_CONSTANTS from 'common/commonConstants';
import appUtils from 'src/components/appUtils';
import CircledCheckmarkSVG from 'src/assets/svg/circled-checkmark.svg';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

const { TASK_TYPES, ONBOARDING_STATUS, PUBLIC_BUCKET_URL } = COMMON_CONSTANTS;

const renderInstructionModal = ({ type }, { close, navigateTo = () => {} }) => {
  const params = {
    title: null,
    text: null,
    gifUrl: null,
    buttonText: null,
    callback: () => {}
  };
  const loggedUserId = appUtils.getLoggedUserId();

  if (type === TASK_TYPES.ONBOARDING_COMPLETE_FIRST_REVIEW) {
    params.title = 'Complete your first review';

    params.text = (
      <p className='text-left m-0'>
        See how easy it is to give feedback. Click on
        {' '}
        <strong>Give Feedback</strong>
        {' '}
        at the top, and follow the steps to
        provide your first review.
      </p>
    );

    params.gifUrl = `${PUBLIC_BUCKET_URL}/onboarding-gifs/submitting-feedback-home-gif.gif`;

    params.buttonText = 'Complete a Review';

    params.callback = () => navigateTo('/dashboard/submit-feedback');
  }

  if (type === TASK_TYPES.ONBOARDING_REQUEST_FEEDBACK_FROM_TEAM_MEMBER) {
    params.title = 'Request feedback from a team member';

    params.text = (
      <p className='text-left m-0'>
        To manually request feedback, click on
        {' '}
        <strong>Request Feedback</strong>
        {' '}
        at the top, and follow the steps to send a request to a team member.
      </p>
    );

    params.gifUrl = `${PUBLIC_BUCKET_URL}/onboarding-gifs/requesting-feedback-home-gif.gif`;

    params.buttonText = 'Request Feedback';

    params.callback = () => navigateTo(
      `/dashboard/request-feedback/review?revieweeId=${loggedUserId}`
    );
  }

  if (type === TASK_TYPES.ONBOARDING_ENABLE_REVIEWS) {
    params.title = 'Enable Automatic Review Scheduling';

    params.text = (
      <div className='text-base text-left m-0'>
        Turning this on will allow you to receive review requests in other
        channels - Teams, Slack, Webex, or email.
        <br />
        <br />
        <ul>
          <li>
            1. Navigate to
            {' '}
            <strong>Organization</strong>
            {' '}
            in the left-hand menu
            and select Events.
          </li>
          <li>
            2. Turn
            {' '}
            <strong>Review</strong>
            {' '}
            events on and save to enable
            automated reviewing
          </li>
        </ul>
      </div>
    );

    params.gifUrl = `${PUBLIC_BUCKET_URL}/onboarding-gifs/turning-reviews-on-gif.gif`;

    params.buttonText = 'Go to Events';

    params.callback = () => navigateTo('/dashboard/organization/events');
  }

  if (type === TASK_TYPES.ONBOARDING_ADJUST_REVIEW_RELATIONSHIPS) {
    params.title = 'Adjust review relationships';

    params.text = (
      <div className='text-base text-left m-0'>
        Setting review relationships if the first step to taking advantage of
        WorkStory's automated feedback process.
        <br />
        <br />
        Once you've done this, you'll be able to give/receive feedback without
        having to log into the platform.
        <br />
        <br />
        <ul>
          <li>
            1. Use the
            {' '}
            <strong>People</strong>
            {' '}
            tab to find a team member
          </li>
          <li>
            2. On their dashboard, navigate to the
            {' '}
            <strong>Profile</strong>
            {' '}
            tab
          </li>
          <li>
            3. Select the
            {' '}
            <strong>Reviews</strong>
            {' '}
            sub-tab
          </li>
          <li>4. Move team members to the appropriate column and save</li>
        </ul>
      </div>
    );

    params.gifUrl = `${PUBLIC_BUCKET_URL}/onboarding-gifs/adjusting-review-relationships-gif.gif`;

    params.buttonText = 'Choose Team Member';

    params.callback = () => navigateTo('/dashboard/people');
  }

  if (type === TASK_TYPES.ONBOARDING_INVITE_TEAM_MEMBERS) {
    params.title = 'Invite team members';

    params.text = (
      <div className='text-base text-left m-0'>
        Add other team members to take advantage of the automated review process
        and make giving/receiving feedback easy.
        <br />
        <br />
        <ul>
          <li>
            1. Navigate to
            {' '}
            <strong>Organization</strong>
            {' '}
            in the left-hand menu
            and select
            {' '}
            <strong>Chart</strong>
          </li>
          <li>
            2. Find the person who will be managing the new team member, hover
            over them and click the green plus icon
          </li>
          <li>
            3. Add the relevant information and click
            {' '}
            <strong>Create User</strong>
          </li>
          <li>
            4. Assign their review relationships and click
            {' '}
            <strong>Update</strong>
          </li>
        </ul>
      </div>
    );

    params.gifUrl = `${PUBLIC_BUCKET_URL}/onboarding-gifs/adding-new-team-member-gif.gif`;

    params.buttonText = 'Go to Organization Chart';

    params.callback = () => navigateTo('/dashboard/organization/settings');
  }

  return (
    <Modal
      variant='blank'
      close={close}
      innerClasses='w-7/20'
      classes='rounded-sm'
    >
      <div className='h-full w-full py-5'>
        <div className='px-5 pb-5'>
          <p className='text-xl text-left font-bold mb-5'>{params.title}</p>
          {params.text}
          <img src={params.gifUrl} alt='gif' className='w-full h-auto mt-5' />
        </div>
        <div className='w-full pt-5 flex justify-evenly gap-6 px-6 border-t border-gray-450'>
          <button
            onClick={close}
            className='text-black text-md text-center font-semibold rounded-md p-2 w-1/2 focus:outline-none border border-gray-450'
          >
            Close
          </button>
          <button
            onClick={params.callback}
            className='text-white text-md text-center font-semibold rounded-md p-2 w-1/2 focus:outline-none bg-purple'
          >
            {params.buttonText}
          </button>
        </div>
      </div>
    </Modal>
  );
};

const renderOnboardingTask = (
  { type, status, key = null },
  { rounded = '', border = '' } = {},
  callback = () => {}
) => {
  const params = {
    title: null,
    subtitle: null,
    buttonText: null,
    buttonCallback: () => {},
    isButtonDisabled: false
  };

  const isDone = status === ONBOARDING_STATUS.DONE;

  if (type === TASK_TYPES.FINISH_ONBOARDING) {
    params.title = 'Finish onboarding';

    params.subtitle = 'Complete all onboarding tasks to finish onboarding';

    params.buttonText = "I'm ready!";

    params.buttonCallback = callback;

    params.isButtonDisabled = !isDone;

    params.checkmarkClasses = 'text-mid-gray';
  } else {
    params.buttonText = isDone ? 'Done' : 'Complete';
    params.isButtonDisabled = isDone;
    params.checkmarkClasses = isDone ? 'text-purple' : 'text-mid-gray';
    if (type === TASK_TYPES.ONBOARDING_COMPLETE_FIRST_REVIEW) {
      params.title = 'Complete your first review';

      params.subtitle = 'Answer a review for a team member to complete this task';

      params.buttonCallback = () => callback('ONBOARDING_COMPLETE_FIRST_REVIEW');
    }

    if (type === TASK_TYPES.ONBOARDING_REQUEST_FEEDBACK_FROM_TEAM_MEMBER) {
      params.title = 'Request feedback from a team member';

      params.subtitle = 'Request feedback from one of your team members to complete this task';

      params.buttonCallback = () => callback('ONBOARDING_REQUEST_FEEDBACK_FROM_TEAM_MEMBER');
    }

    if (type === TASK_TYPES.ONBOARDING_ENABLE_REVIEWS) {
      params.title = 'Enable Automatic Review Scheduling';

      params.subtitle = 'Enable automatic review scheduling for your organization to complete this task';

      params.buttonCallback = () => callback('ONBOARDING_ENABLE_REVIEWS');
    }

    if (type === TASK_TYPES.ONBOARDING_ADJUST_REVIEW_RELATIONSHIPS) {
      params.title = 'Adjust review relationships';

      params.subtitle = 'Adjust review relationships for one of your direct reports to complete this task';

      params.buttonCallback = () => callback('ONBOARDING_ADJUST_REVIEW_RELATIONSHIPS');
    }

    if (type === TASK_TYPES.ONBOARDING_INVITE_TEAM_MEMBERS) {
      params.title = 'Invite team members';

      params.subtitle = 'Invite team members to join your organization to complete this task';

      params.buttonCallback = () => callback('ONBOARDING_INVITE_TEAM_MEMBERS');
    }
  }

  return (
    <div
      key={key ?? `task-${Math.random()}`}
      className={`flex flex-row items-center justify-between w-full h-[85px] grow-0 shrink-0 border-stone-gray py-1 mini:py-4 mini:px-2 
        ${rounded.includes('top') ? 'border-t rounded-t-lg' : ''} ${
        rounded.includes('bottom') ? 'border-b rounded-b-lg' : ''
      } ${border.includes('sides') ? 'border-r border-l' : ''} ${
        border.includes('bottom') ? 'border-b' : ''
      } ${border.includes('top') ? 'border-t' : ''}`}
    >
      <div className='w-8 h-8 m-auto flex items-center justify-center shrink-0'>
        {params.checkmarkClasses ? (
          <CircledCheckmarkSVG className={params.checkmarkClasses} />
        ) : null}
      </div>
      <div className='tracking-wide w-14/20 shrink-0 text-left pl-2'>
        <p className='m-0 text-md truncate'>{params.title}</p>
        <p className='m-0 text-sm text-gray-500 truncate'>{params.subtitle}</p>
      </div>
      <div className='pl-2 mini:pl-4 w-5/20 shrink-0'>
        <button
          disabled={params.isButtonDisabled}
          onClick={params.buttonCallback}
          className='text-white text-md text-center font-semibold tracking-wide rounded-md p-1 mini:py-3 mini:px-4 w-full focus:outline-none bg-purple hover:bg-hover-purple disabled:bg-mid-gray'
        >
          {params.buttonText}
        </button>
      </div>
    </div>
  );
};

const HomeOnboarding = () => {
  const loggedUserId = appUtils.getLoggedUserId();
  const navigate = useNavigate();
  const { showRequestFeedbackButton } = useFlags();
  const {
    data: { tasks: userTasks },
    isFetching: isFetchingTasks,
    isError: isErrorTasks,
    refetch: refetchOnboardingTasks
  } = useAccountTasks('me', {
    specs: { onboarding: true }
  });

  useEffect(() => {
    const refetchOnboardingTasksCallback = async () => refetchOnboardingTasks();
    refetchOnboardingTasksCallback();
  }, []);

  const { update: updateAccount, isLoading: isLoadingUpdateAccount } = useAccountUpdate(loggedUserId);

  const [modalTask, setModalTask] = useState(null);

  const isFetching = isFetchingTasks;
  const isError = isErrorTasks;
  const isReady = !isFetching && !isError;
  if (!isReady) return null;

  let tasks = userTasks;

  if (!showRequestFeedbackButton && tasks) {
    tasks = tasks.filter((task) => task.type !== TASK_TYPES.ONBOARDING_REQUEST_FEEDBACK_FROM_TEAM_MEMBER);
  }

  const areAllTasksDone = tasks.every(
    (task) => task.status === ONBOARDING_STATUS.DONE
  );

  return (
    <Base classes={STYLE.CONTAINER_WHITE}>
      {modalTask
        ? renderInstructionModal(modalTask, {
          close: () => setModalTask(null),
          navigateTo: navigate
        })
        : null}
      <div className='w-full'>
        <p className='m-0 mt-2 font-semibold text-3xl text-black leading-8 mb-10'>
          Quick Onboarding (3 Mins)
        </p>
        {/* <p className='my-2 font-normal text-md text-[#6B7280] leading-8'>
          Let's get you started!
        </p> */}
      </div>
      {tasks.map((task, index) => {
        let border = 'bottom';
        if (index === tasks.length - 1) border = '';

        const callback = () => setModalTask(task);

        task.key = `task-${index}`;
        return renderOnboardingTask(task, { border }, callback);
      })}
      {renderOnboardingTask(
        {
          type: TASK_TYPES.FINISH_ONBOARDING,
          status:
            areAllTasksDone && !isLoadingUpdateAccount
              ? ONBOARDING_STATUS.DONE
              : ONBOARDING_STATUS.PENDING
        },
        { border: 'top' },
        () => updateAccount({
          data: { onboarding: { status: ONBOARDING_STATUS.DONE } }
        })
      )}
    </Base>
  );
};

export default HomeOnboarding;
