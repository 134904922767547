import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  Modal, VisibleSelector, Base, Button
} from 'src/components';
import { updateFeedbackQuery, QUERY_KEYS } from 'src/queries/feedback';
import { useTree } from 'src/queries/tree';
import COMMON_CONSTANTS from 'common/commonConstants';
import commonTreeUtils from 'common/commonTreeUtils';

const { FEEDBACK_VISIBLE, FEEDBACK_TYPE } = COMMON_CONSTANTS;

const EditFeedbackModal = ({ data, close }) => {
  const queryClient = useQueryClient();

  const [feedbackVisibility, setFeedbackVisibility] = useState(
    data.visible ?? FEEDBACK_VISIBLE.PRIVATE_AND_MANAGER
  );
  const [feedbackText, setFeedbackText] = useState(data.text ?? '');

  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const isFetching = isFetchingTree;
  const isError = isErrorTree;
  const isReady = tree && tree.id && !isFetching && !isError;

  const { mutateAsync: updateFeedback, isLoading: updateFeedbackLoading } = updateFeedbackQuery();

  if (!isReady) {
    return null;
  }

  const revieweeNode = commonTreeUtils.findNodeById(tree, data.reviewee);
  const managerNode = commonTreeUtils.findNodeById(
    tree,
    revieweeNode.managerId
  );

  const submit = async () => {
    await updateFeedback({
      feedbackId: data.id,
      reviewee: data.reviewee,
      text: feedbackText,
      visible: feedbackVisibility,
      type: FEEDBACK_TYPE.FEEDBACK
    });

    queryClient.invalidateQueries(QUERY_KEYS.FEEDBACK_FEED);
    close();
  };

  return (
    <Modal
      variant='custom'
      close={close}
      classes='shadow text-left'
      innerClasses='w-40rem text-left'
    >
      <Base
        classes='text-left shadow-none'
        loading={updateFeedbackLoading}
      >
        <h5 className='font-bold text-xl mb-5'>Edit Feedback</h5>
        <p className='text-lg mb-2'>{`Feedback for ${revieweeNode.name}:`}</p>
        <textarea
          placeholder='Write up your comments here'
          className='bg-white w-full h-20 resize-none'
          value={feedbackText}
          onChange={(e) => setFeedbackText(e.target.value)}
        />
        <div className='text-left mt-2 mb-5'>
          <VisibleSelector
            onClick={(value) => setFeedbackVisibility(value)}
            value={feedbackVisibility}
            userName={revieweeNode?.name || ''}
            managerName={managerNode ? managerNode.name : null}
          />
        </div>
        <div className='w-1/2 inline-block text-left'>
          <Button
            onClick={submit}
            disabled={
              feedbackText.length < 7
              || (feedbackText === data.text
                && feedbackVisibility === data.visible)
            }
            variant='yellow'
          >
            Update Feedback
          </Button>
        </div>
        <div className='w-1/2 inline-block text-right'>
          <button
            type='button'
            onClick={close}
            className='text-black text-lg px-4 py-2 mr-0 font-bold'
          >
            Close
          </button>
        </div>
      </Base>
    </Modal>
  );
};

export default EditFeedbackModal;
