import React, { useState } from 'react';
import { get } from 'lodash';
import commonDateUtils from 'common/commonDateUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import commonTreeUtils from 'common/commonTreeUtils';
import { useEvents } from 'src/queries/events';
import { useTree } from 'src/queries/tree';
import { useAccount } from 'src/queries/account';
import { Pagination } from 'src/components/index';

const { EVENT_TYPES } = COMMON_CONSTANTS;

const EventsTab = ({ userId }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const {
    data: account,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount(userId);
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: eventsData,
    isFetching: isFetchingEvents,
    isError: isErrorEvents
  } = useEvents({
    isAdmin: true,
    filters: {
      accountId: userId
    },
    options: {
      page: currentPage,
      size: 10
    }
  });

  const isFetching = isFetchingEvents || isFetchingTree || isFetchingAccount;
  const isError = isErrorEvents || isErrorTree || isErrorAccount;
  const isReady = tree && tree.id && eventsData && !isFetching && !isError && userId;

  if (!isReady) return null;

  const { events, pagination } = eventsData;

  const list = events
    ? events.filter(
      (e) => e.userId === userId || get(e, 'reviewee', '') === userId
    )
    : [];

  return (
    <div className='p-10 bg-white'>
      <p>
        {`Superuser view - events for user ${account.name} including when they review/are being reviewed.`}
      </p>
      <div>
        <div className='mb-2 py-1 px-1 bg-background-gray text-black font-bold rounded-sm'>
          <p className='mb-0 pl-1 w-1/5 inline-block font-bold'>Reviewer</p>
          <p className='mb-0 pl-1 w-1/5 inline-block font-bold'>Reviewee</p>
          <p className='mb-0 w-1/5 inline-block font-bold'>Date</p>
          <p className='mb-0 w-1/5 inline-block font-bold'>Type</p>
        </div>

        <div>
          {list.map((e, index) => {
            const reviewerNode = commonTreeUtils.findNodeById(tree, e.reviewer);
            let reviewerLabel = e.reviewer && reviewerNode ? reviewerNode.name : '-';

            const revieweeNode = commonTreeUtils.findNodeById(tree, e.reviewee);

            const revieweeLabel = e.reviewee && revieweeNode ? revieweeNode.name : '-';

            if (e.type === EVENT_TYPES.SUMMARY_EVENT) {
              reviewerLabel = e.userId
                ? commonTreeUtils.findNodeById(tree, e.userId).name
                : '-';
            }

            if (e.type === EVENT_TYPES.RANDOM_EVENT) {
              reviewerLabel = e.userId
                ? commonTreeUtils.findNodeById(tree, e.userId).name
                : '-';
            }

            return (
              <div key={index}>
                <p className='pl-2 w-1/5 inline-block'>{reviewerLabel}</p>
                <p className='w-1/5 inline-block'>{revieweeLabel}</p>
                <p className='w-1/5 inline-block'>
                  {commonDateUtils.unixToMonthDayYearTimeFormat(
                    parseInt(e.timestamp, 10)
                  )}
                </p>
                <p className='w-1/5 inline-block'>{e.type}</p>
              </div>
            );
          })}
        </div>
      </div>
      {!isFetchingEvents && (
        <Pagination
          name='Events'
          totalPages={pagination.pages}
          currentPage={pagination.current}
          selectPage={setCurrentPage}
          isLoading={isFetchingEvents}
        />
      )}
    </div>
  );
};

export default EventsTab;
