import React, { useState, useEffect } from 'react';
import {
  Modal, Button, toast, Base
} from 'src/components';
import { useCompany, updateCompanyQuery } from 'src/queries/company';

const EditCompanyNameModal = ({ close }) => {
  const {
    data: company,
    refetch: refetchCompany,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.name && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const { mutateAsync: updateCompany, isLoading: updateCompanyLoading } = updateCompanyQuery();
  const [local, setLocal] = useState({ companyName: '' });
  useEffect(() => {
    if (company) {
      setLocal({ ...local, companyName: company.name });
    }
  }, [company]);

  const change = async () => {
    toast.show('Updating company name..');
    await updateCompany({
      name: local.companyName
    });
    toast.show('Saved!');
    refetchCompany();
    close();
  };

  return (
    <Modal
      close={close}
      innerClasses='w-28rem text-left'
      classes=''
      variant='custom'
    >
      <Base
        classes='shadow-none text-left'
        loading={updateCompanyLoading || isFetchingCompany}
      >
        <h5 className='font-bold text-xl mb-3'>Company Name</h5>
        <p className='mb-1'>Change company name to:</p>
        <input
          onChange={(e) => {
            setLocal({ ...local, companyName: e.target.value });
          }}
          className='block mb-3 bg-white rounded-sm'
          type='text'
          value={local.companyName}
        />
        <Button
          disabled={!local.companyName || local.companyName === ''}
          onClick={change}
          variant='yellow'
        >
          Save
        </Button>
      </Base>
    </Modal>
  );
};

export default EditCompanyNameModal;
