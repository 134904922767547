import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button, Circle, Base, toast
} from 'src/components/';
import { useTree } from 'src/queries/tree';
import commonTreeUtils from 'common/commonTreeUtils';
import { submitParticipationReminder } from 'src/queries/reviews';
import appUtils from 'src/components/appUtils';
import { useAccount } from 'src/queries/account';
import { useNavigate, useParams } from 'react-router-dom';

const NotifyRevieweeParticipation = () => {
  const { revieweeId } = useParams();
  const loggedUserId = appUtils.getLoggedUserId();
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const {
    data: revieweeAccount,
    isFetching: isFetchingReviewee,
    isError: isErrorReviewee
  } = useAccount(revieweeId);

  const isFetching = isFetchingReviewee || isFetchingTree;
  const isError = isErrorReviewee || isErrorTree;
  const isReady = tree
    && tree.id
    && revieweeAccount
    && revieweeAccount._id
    && !isFetching
    && !isError;

  const { mutateAsync: submitReminder, isLoading: isSubmitReminderLoading } = submitParticipationReminder();

  const navigate = useNavigate();
  const revieweeTree = commonTreeUtils.findNodeById(tree, revieweeId);
  const {
    watch,
    register,
    reset: resetForm
  } = useForm({
    defaultValues: {
      reminderText:
        'Your continuous feedback helps the team get better and saves us time in the long-run. Please catch-up on your reviews.'
    }
  });

  if (!isReady) {
    return null;
  }

  const isAbove = commonTreeUtils.isNodeDirectlyAbove(
    tree,
    revieweeId,
    loggedUserId
  );
  if (!revieweeId || !isAbove) return navigate(appUtils.getDashRoute());

  const revieweeName = revieweeAccount.name;
  const revieweeTitle = revieweeTree.title;
  const reminderText = watch('reminderText');

  const submit = async () => {
    const data = {
      revieweeId: revieweeTree.id,
      reminderText
    };
    const result = await submitReminder(data);
    if (!result || !result.success) {
      toast.error('Uh oh, we ran into an issue. Please try again later!');
    }
    resetForm();
    return navigate(appUtils.getDashRoute());
  };

  return (
    <Base classes='mt-4 mx-3' loading={isSubmitReminderLoading}>
      <div className='h-32 -mb-18 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-t-xl' />
      <div className='mb-10 pb-6 flex flex-col items-center'>
        <Circle
          size='xxl'
          imageUrl={revieweeAccount.imageUrl}
          classes='mb-4 user-setting-profile-image relative h-131 w-131'
        />
        <div className='text-center'>
          <div className='text-2xl bold'>{revieweeName}</div>
          <div className='text-base text-gray-400'>{revieweeTitle}</div>
        </div>
      </div>
      <div className='mx-auto w-5/6'>
        <div className='mb-8 flex flex-col md:flex-row'>
          <div className='text-gray-500 mb-2 md:w-2/5'>
            <div className='uppercase font-bold text-xl mb-1'>Comments</div>
            <div className='text-base pr-6'>
              {`Write a reminder text to ${revieweeName}`}
            </div>
          </div>
          <textarea
            maxLength='500'
            {...register('reminderText')}
            className='h-36 text-base md:w-3/5'
          />
        </div>
      </div>

      <div className='flex pb-8 md:mr-8 justify-center md:justify-end text-center'>
        <div className='w-32'>
          <Button
            classes='w-32'
            disabled={!reminderText}
            variant='yellow'
            onClick={submit}
          >
            Submit
          </Button>
        </div>
      </div>
    </Base>
  );
};

export default NotifyRevieweeParticipation;
