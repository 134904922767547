import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { get, isEmpty } from 'lodash';
import {
  Button,
  Circle,
  Base,
  Radio,
  toast,
  ReviewAiSuggestions,
  appUtils
} from 'src/components/';
import FeedbackQuill from 'src/components/Quill/FeedbackQuill';
import { QUERY_KEYS } from 'src/queries/Wizard/wizard';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useCompany } from 'src/queries/company';
import commonCompanyUtils from 'common/commonCompanyUtils';
import COMMON_QUESTION_CONSTANTS from 'common/commonQuestionConstants';
import STYLE from 'src/constants/style';
import { formatNoteText } from 'src/pagesDashboard/NewUserReport/utils';
import commonReviewUtils from 'common/commonReviewUtils';
import './ReviewForm.scss';
import MobileReviewForm from 'src/components/ReviewForm/mobile/ReviewForm';
import commonQuestionUtils from 'common/commonQuestionsUtils';

const {
  REVIEW_ANSWER_TYPE,
  IM_NOT_SURE,
  SHARE_REVIEW_WITH,
  renderShareCommentsVisibilityOptions
} = COMMON_CONSTANTS;
const { QUESTION_ANSWER_TYPES } = COMMON_QUESTION_CONSTANTS;

const getAnswerObject = (answer, questionObject) => {
  if (
    answer.id === REVIEW_ANSWER_TYPE.NOT_AVAIL
    || answer === REVIEW_ANSWER_TYPE.NOT_AVAIL
  ) {
    return {
      id: REVIEW_ANSWER_TYPE.NOT_AVAIL,
      value: IM_NOT_SURE,
      answer: '-1',
      score: null
    };
  }
  return questionObject.answers.custom.find(
    (a) => a.id.toString() === answer.toString()
  );
};

const ReviewForm = ({
  userId,
  revieweeName,
  revieweeTitle,
  question,
  imageUrl,
  submitReview,
  questionObject,
  loading,
  allCategoriesQuestions
}) => {
  const {
    textAlternativeOnGiveFeedbackPage,
    enableAiSuggestionsForReviewAndFeedback
  } = useFlags();

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const loggedUserId = appUtils.getLoggedUserId();

  const [commentsRequired, setCommentsRequired] = useState();

  const {
    watch,
    control,
    setValue,
    getValues,
    reset,
    handleSubmit,
    formState: { errors, isDirty }
  } = useForm({
    defaultValues: {
      commentVisible: '',
      reviewAnswer: {},
      reviewComments: '',
      anonymous: false
    }
  });

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError;

  const companySettings = get(company, 'settings', null);
  const reviewVisibilitySettings = get(
    company,
    'settings.reviewVisibilityOptions',
    {}
  );

  const isFreeTextAnswerType = questionObject.answers.type === REVIEW_ANSWER_TYPE.FREE_TEXT;

  const [shareCommentOptions, setShareCommentOptions] = useState([]);

  const commentVisible = watch('commentVisible');
  const reviewAnswer = watch('reviewAnswer');
  const reviewComments = watch('reviewComments');
  const anonymous = watch('anonymous');

  const reviewAnswerId = get(reviewAnswer, 'id', null);
  const reviewAnswerScore = get(reviewAnswer, 'score', null);

  const queryClient = appUtils.getQueryClient();
  useEffect(
    () => () => queryClient.removeQueries(QUERY_KEYS.WIZARD_REVIEW_SUGGESTIONS),
    []
  );

  useEffect(() => {
    if (reviewAnswer || isFreeTextAnswerType) {
      const isRequired = commonCompanyUtils.doesRequireComments(
        companySettings,
        reviewAnswerScore
      ) || isFreeTextAnswerType;

      setCommentsRequired(isRequired);

      if (isFreeTextAnswerType && reviewAnswerId === 'N/A') {
        setCommentsRequired(false);
      }
    }
  }, [
    reviewAnswer,
    isFreeTextAnswerType,
    companySettings,
    reviewAnswerScore,
    reviewAnswerId
  ]);

  useEffect(() => {
    if (!isReady) return;

    if (!isDirty) {
      const defaultValues = {
        commentVisible: companySettings.reviewVisibilityDefaultOption,
        reviewAnswer: '',
        reviewComments: '',
        anonymous: false
      };
      reset(defaultValues);
    }
  }, [isReady]);

  useEffect(() => {
    if (!isEmpty(reviewVisibilitySettings) && revieweeName) {
      const visibilityOptions = renderShareCommentsVisibilityOptions({
        revieweeName
      });
      const options = commonQuestionUtils.getShareCommentOptions(
        reviewVisibilitySettings,
        visibilityOptions
      );

      setShareCommentOptions(options);
    }
  }, [reviewVisibilitySettings, revieweeName]);

  if (!isReady) {
    return null;
  }

  const getCurrentReviewIndex = () => allCategoriesQuestions.findIndex(
    (catQuestion) => catQuestion.id === questionObject.id
  ) + 1;

  const answerOptions = [
    ...questionObject?.answers?.custom.sort((a, b) => (a.score < b.score ? 1 : -1)),
    {
      id: 'N/A',
      value: "I'm not sure"
    }
  ];

  const isAnswerNa = reviewAnswerId === REVIEW_ANSWER_TYPE.NOT_AVAIL;

  const saveReview = async (formValues) => {
    try {
      const data = formValues ?? getValues();
      const { reviewAnswer: answered } = data;

      queryClient.removeQueries(QUERY_KEYS.WIZARD_REVIEW_SUGGESTIONS);

      if (!company.active) {
        return toast.error('Company is not active.');
      }
      let score = null;
      let answer = null;

      if (!isFreeTextAnswerType) {
        const answerObj = getAnswerObject(
          answered.id ?? reviewAnswerId,
          questionObject
        );

        score = answerObj.score;
        answer = answerObj.value;
      }

      const isNA = answer === IM_NOT_SURE
        || (answered && answered.id === REVIEW_ANSWER_TYPE.NOT_AVAIL);

      if (!isNA && isFreeTextAnswerType) {
        if (!commonReviewUtils.areHTMLCommentsValid(reviewComments)) {
          toast.error(
            'Please provide a longer comment to answer this question.'
          );
          return false;
        }
      }

      if (!isNA && !isFreeTextAnswerType && !reviewAnswer) {
        toast.error('Please provide an answer');
        return false;
      }

      if (
        !isNA
        && commentsRequired
        && !commonReviewUtils.areHTMLCommentsValid(reviewComments)
      ) {
        toast.error('Please provide a longer comment to answer this question.');
        return false;
      }

      let formattedComments = reviewComments;

      const plainText = commonReviewUtils
        .getPlainTextFromHTML(formattedComments)
        .replace(' ', '');

      if (!plainText.length) formattedComments = null;

      await submitReview(
        score,
        formattedComments,
        anonymous,
        questionObject,
        answer,
        commentVisible,
        false,
        isNA
      );
      reset();
    } catch (error) {
      toast.error(error);
      return false;
    }
  };

  const isSubmitDisabled = () => {
    if (isAnswerNa) {
      return false;
    }

    if (!isFreeTextAnswerType && !reviewAnswer) return true;
    return false;
  };

  const getAnswerLabels = () => {
    if (questionObject.answers.type === QUESTION_ANSWER_TYPES.ONE_TO_TEN) {
      return `1 = ${questionObject.questionHeaders.left}, 10 = ${questionObject.questionHeaders.right}`;
    }
    if (questionObject.answers.type === QUESTION_ANSWER_TYPES.ONE_TO_FIVE) {
      return `1 = ${questionObject.questionHeaders.left}, 5 = ${questionObject.questionHeaders.right}`;
    }
    if (questionObject.answers.type === QUESTION_ANSWER_TYPES.CUSTOM) {
      return `Low = ${questionObject.questionHeaders.left}, High = ${questionObject.questionHeaders.right}`;
    }
    return false;
  };

  const isMobile = appUtils.getMobileSize();

  const selectAnswer = (option) => {
    const updateAnswer = (answer) => setValue('reviewAnswer', answer);

    const updateOptions = (newOptions) => setShareCommentOptions(newOptions);

    const updateVisibility = (newVisibility) => setValue('commentVisible', newVisibility);

    commonReviewUtils.handleFormAnswerUpdate({
      reviewVisibilitySettings,
      revieweeName,
      selectedAnswer: option,
      currentVisibility: commentVisible,
      defaultVisibility: companySettings?.reviewVisibilityDefaultOption,
      updateAnswer,
      updateOptions,
      updateVisibility
    });
  };

  if (isMobile) {
    return (
      <div>
        <form
          className='flex flex-col items-center'
          onSubmit={handleSubmit(saveReview)}
        >
          <MobileReviewForm
            userId={userId}
            revieweeName={revieweeName}
            revieweeTitle={revieweeTitle}
            question={question}
            imageUrl={imageUrl}
            saveReview={saveReview}
            questionObject={questionObject}
            loading={loading}
            allCategoriesQuestions={allCategoriesQuestions}
            commentsRequired={commentsRequired}
            handleSubmit={handleSubmit}
            control={control}
            errors={errors}
          />
        </form>
      </div>
    );
  }

  return (
    <Base classes={STYLE.CONTAINER_WHITE_PADDINGLESS} loading={loading}>
      <form
        className='flex flex-col items-center'
        onSubmit={handleSubmit(saveReview)}
      >
        <div className='h-32 -mb-18 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-t-md w-0' />
        <div className='mb-10 pb-6 flex flex-col items-center'>
          <Circle
            size='xxl'
            imageUrl={imageUrl}
            classes='mb-4 user-setting-profile-image relative h-131 w-131'
          />
          <div className='text-center'>
            <div className='text-2xl bold'>{revieweeName}</div>
            <div className='text-base text-gray-400'>{revieweeTitle}</div>
          </div>
        </div>

        <div className='mx-auto w-5/6'>
          <div className='mb-8 flex flex-col font-bold md:flex-row'>
            <div className='uppercase text-gray-500 text-xl mb-2 md:w-2/5'>
              Question
            </div>
            <div className='text-lg md:w-3/5 whitespace-pre-line'>
              {question}
            </div>
          </div>
          {!isFreeTextAnswerType && (
            <div className='flex flex-col mb-8 md:flex-row'>
              <div className='text-gray-500 mb-2 md:w-2/5'>
                <div className='uppercase text-gray-500 font-bold text-xl'>
                  Answer
                </div>
                <div className='mb-8'>{getAnswerLabels()}</div>
              </div>
              <div className='md:w-3/5'>
                {answerOptions.map((option, index) => (
                  <div key={index} className='mb-2 font-bold flex'>
                    <Controller
                      name='reviewAnswer'
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { value } }) => (
                        <Radio
                          name='reviewAnswer'
                          value={value}
                          title={option.value}
                          onChange={() => selectAnswer(option)}
                          checked={reviewAnswer.value === option.value}
                        />
                      )}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className='mb-3 flex flex-col md:flex-row'>
            <div className='text-gray-500 mb-2 md:w-2/5'>
              <div className='uppercase font-bold text-xl mb-1'>Comments</div>
              <div className='text-base pr-6'>
                {textAlternativeOnGiveFeedbackPage ? (
                  'Give a specific example of your rating above.'
                ) : (
                  <p>
                    Give an example of what you think
                    <br />
                    (máx: 2000 characters)
                  </p>
                )}
              </div>
            </div>
            <div className='flex flex-col md:w-3/5 '>
              <Controller
                name='reviewComments'
                control={control}
                render={({ field }) => (
                  <FeedbackQuill
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(formatNoteText(value));
                    }}
                  />
                )}
              />
            </div>
          </div>

          {enableAiSuggestionsForReviewAndFeedback ? (
            <ReviewAiSuggestions
              comments={reviewComments}
              userId={userId}
              revieweeName={revieweeName}
              question={question}
              companyid={company.id}
              reviewerId={loggedUserId}
            />
          ) : null}

          <div className='flex flex-col mb-4 md:flex-row'>
            <div className='uppercase text-gray-500 font-bold text-xl mb-2 md:w-2/5'>
              Comment visibility
            </div>
            <div className='md:w-3/5'>
              {shareCommentOptions.length === 1 && (
                <>
                  <p className='fontSize16 mb-0 pd-0 bold'>
                    {shareCommentOptions[0].title}
                  </p>

                  <div className='ml-0'>
                    <p className='mb-0 text-dark-grey'>
                      {shareCommentOptions[0].comments}
                    </p>
                  </div>
                </>
              )}
              {shareCommentOptions.length > 1
                && shareCommentOptions.map((option, index) => (
                  <div key={index} className='font-semibold'>
                    <Controller
                      name='commentVisible'
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { value } }) => (
                        <Radio
                          name='commentVisible'
                          value={value}
                          title={option.title}
                          checked={
                            !commentVisible
                              ? option.value
                                === companySettings.reviewVisibilityDefaultOption
                              : commentVisible === option.value
                          }
                          onChange={() => setValue('commentVisible', option.value)}
                        />
                      )}
                    />
                    <div>
                      <p className='text-dark-grey'>{option.comments}</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div className='w-5/6 flex pb-8 mr-0 justify-between md:justify-end text-center'>
          {allCategoriesQuestions && allCategoriesQuestions.length && (
            <div className='mb-2 font-bold min-w-12rem text-left my-auto'>
              {`Feedback ${getCurrentReviewIndex()} of ${
                allCategoriesQuestions.length
              }`}
            </div>
          )}

          <div
            className={`mt-10 flex w-full ${
              isFreeTextAnswerType && !allCategoriesQuestions
                ? 'justify-between'
                : 'justify-end'
            }`}
          >
            {isFreeTextAnswerType && !allCategoriesQuestions ? (
              <Button
                classes='w-40 mr-4'
                variant='yellow'
                onClick={() => {
                  setValue(
                    'reviewAnswer',
                    answerOptions.find((opt) => opt.id === 'N/A')
                  );
                  saveReview();
                }}
              >
                I'm not sure
              </Button>
            ) : null}
            <Button
              classes='w-32'
              disabled={isSubmitDisabled()}
              variant='yellow'
              onClick={handleSubmit(saveReview)}
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    </Base>
  );
};

export default ReviewForm;
