import React from 'react';
import {
  Button,
  Circle,
  SelectTimezone,
  Base
} from 'src/components';
import STYLE from 'src/constants/style';
import commonDateUtils from 'common/commonDateUtils';
import { useNavigate } from 'react-router-dom';
import NavigationItem from 'src/pagesDashboard/HomePage/components/mobile/NavigationItem';
import { UserIcon } from '@heroicons/react/24/outline';

const MobileUserInformation = ({
  isMutating,
  localData,
  onUpload,
  updateField,
  isSaveDisabled,
  updateAction,
  account,
  myTreeRow
}) => {
  const navigate = useNavigate();
  const isInputDisabled = isMutating;
  return (
    <Base
      classes={STYLE.MOBILE_CONTAINER}
    >
      <div className='w-full fixed top-0 mt-12 bg-white z-50'>
        <NavigationItem
          goBack={() => navigate('/dashboard/home')}
          title='My Profile'
        >
          <UserIcon className='w-6 h-6' />
        </NavigationItem>
      </div>
      <div className='px-3 mt-36 mb-40'>
        <div className='w-full flex justify-center'>
          <Circle
            size='2xl'
            onUpload={onUpload}
            imageUrl={localData.imageUrl}
            isDisabled={isInputDisabled}
          />
        </div>
        <div className='mb-4 mt-2'>
          <p className='mb-1 mt-3'>First Name</p>
          <input
            className='w-full bg-white border border-0 rounded-md ring-1 ring-inset ring-gray-300 h-40px px-3'
            placeholder='Johnny'
            type='text'
            onChange={(e) => updateField(e.target.value, 'firstName')}
            value={localData.firstName}
            disabled={isInputDisabled}
          />
        </div>
        <div className='mb-4 mt-2'>
          <p className='mb-1 mt-3'>Last Name</p>
          <input
            className='w-full bg-white border border-0 rounded-md ring-1 ring-inset ring-gray-300 h-40px px-3'
            placeholder='Silverhand'
            type='text'
            onChange={(e) => updateField(e.target.value, 'lastName')}
            value={localData.lastName}
            disabled={isInputDisabled}
          />
        </div>
        <div className='mb-4 mt-2'>
          <p className='mb-1 mt-3'>Email</p>
          <input
            className='w-full bg-white border border-0 rounded-md ring-1 ring-inset ring-gray-300 h-40px px-3'
            placeholder='johnnysilverhand@workstory.com'
            type='text'
            onChange={(e) => updateField(e.target.value, 'email')}
            value={localData.email}
            disabled={isInputDisabled}
          />
        </div>
        <div className='mb-4 mt-2'>
          <p className='mb-1 mt-3'>Phone</p>
          <input
            className='w-full bg-white border border-0 rounded-md ring-1 ring-inset ring-gray-300 h-40px px-3'
            placeholder='Phone'
            type='text'
            onChange={(e) => updateField(e.target.value, 'phone')}
            value={localData.phone}
            disabled={isInputDisabled}
          />
        </div>
        <div className='mb-4 mt-2'>
          <p className='mb-1 mt-3'>Start Date</p>
          <input
            className='w-full bg-white border border-0 rounded-md ring-1 ring-inset ring-gray-300 h-40px px-3'
            placeholder='Start Date'
            type='text'
            value={
              account.startDate
                ? commonDateUtils.unixToMonthDayYearFormat(account.startDate)
                : 'N/A'
            }
            disabled
          />
        </div>
        <div className='mb-4 mt-2'>
          <SelectTimezone
            value={localData.tz}
            onChange={(timezoneOffset) => {
              updateField(timezoneOffset, 'tz');
            }}
            mobileMode
            drop='up'
          />
        </div>
      </div>
      <div className='w-full flex flex-col items-center pt-4 px-6 pb-2 gap-3 fixed bottom-0 bg-white'>
        <Button
          variant='black'
          disabled={isSaveDisabled(
            {
              ...account,
              tz: myTreeRow.tz
            },
            localData
          ) || isMutating}
          classes='w-full'
          type='submit'
          onClick={updateAction}
        >
          Update
        </Button>
        <Button
          variant='custom'
          disabled={isMutating}
          classes='bg-white w-full text-black font-bold text-sm px-4 pt-2 pb-2 border rounded-md border-gray-300'
          onClick={() => navigate('/dashboard/home')}
        >
          Cancel
        </Button>
      </div>
    </Base>
  );
};

export default MobileUserInformation;
