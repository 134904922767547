import React, { cloneElement } from 'react';
import { Radio, RadioGroup } from '@headlessui/react';
import CheckmarkSVG from 'src/assets/svg/checkmark.svg';

const RadioCards = ({ selectedCard, setSelectedCard, cards = [] }) => (
  <RadioGroup
    by='id'
    value={selectedCard}
    onChange={setSelectedCard}
    className='flex flex-col gap-4 justify-evenly w-full h-full'
  >
    {cards.map((card) => (
      <Radio
        key={card.id || `card-${Math.random()}`}
        value={card}
        style={{
          maxHeight: `${100 / cards.length}%`,
          minHeight: `${100 / cards.length}%`
        }}
        className={({ checked }) => `${
          checked
            ? 'border-alt-purple ring-2 ring-alt-purple bg-alt-purple/10'
            : 'border-gray-300 bg-white'
        }  relative block cursor-pointer rounded-lg border px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between`}
      >
        {({ checked }) => (
          <span className='flex justify-between w-full grow-0 shrink-0'>
            <div className='flex items-center gap-6 h-full'>
              <div className='w-16 h-16 flex items-center justify-center'>{cloneElement(card.icon)}</div>
              {card.label}
            </div>
            <div className='flex items-center h-full'>
              {checked ? (
                <CheckmarkSVG className='w-6 h-6 grow-0 shrink-0 inline-block text-alt-purple' />
              ) : (
                <div className='w-6 h-6 grow-0 shrink-0 inline-block ring-alt-purple rounded-full ring-1' />
              )}
            </div>
          </span>
        )}
      </Radio>
    ))}
  </RadioGroup>
);

export default RadioCards;
