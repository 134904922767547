import React, { useState, useContext } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PerformanceBuilderContext } from 'src/pages/PerformanceBuilder/PerformanceBuilderContext';
import WorkStoryTitle from 'src/assets/svg/workstory-title.svg';
import { Button, appUtils } from 'src/components';
import SignFlyout from 'src/pages/PerformanceBuilder/BuilderComponents/SignFlyout';
import PricingFlyout from 'src/pages/PerformanceBuilder/BuilderComponents/PricingFlyout';
import ShareFlyout from 'src/pages/PerformanceBuilder/BuilderComponents/ShareFlyout';
import commonUtils from 'common/commonUtils';
import {
  AlertModal,
  ActionModal,
  DropMenu
} from 'src/componentsTailwind/index';
import ProfileCircleSVG from 'src/assets/svg/profileCircle.svg';
import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  useUpdateBuilderReport,
  QUERY_KEYS as BUILDER_QUERY_KEYS,
  useBuilderReport
} from 'src/queries/builder';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useQueryClient } from 'react-query';

const { BUILDER_REPORT_STATUS } = COMMON_CONSTANTS;

const BuilderHeader = () => {
  const { viewBuilderShareButton } = useFlags();
  const [isSignFlyoutOpen, setIsSignFlyoutOpen] = useState(false);
  const [isCreateNewModalOpen, setIsCreateNewModalOpen] = useState(false);
  const [isSubmitReviewModalOpen, setIsSubmitReviewModalOpen] = useState(false);
  const [isSubmittedReviewModalOpen, setIsSubmittedReviewModalOpen] = useState(false);
  const [isPricingFlyoutOpen, setIsPricingFlyoutOpen] = useState(false);
  const [isShareFlyoutOpen, setIsShareFlyoutOpen] = useState(false);

  const closeOverlays = (skip) => {
    if (skip !== 'sign') setIsSignFlyoutOpen(false);
    if (skip !== 'create-new') setIsCreateNewModalOpen(false);
    if (skip !== 'submit-review') setIsSubmitReviewModalOpen(false);
    if (skip !== 'submitted-review') setIsSubmittedReviewModalOpen(false);
    if (skip !== 'pricing') setIsPricingFlyoutOpen(false);
    if (skip !== 'share') setIsShareFlyoutOpen(false);
    const appDiv = document.getElementById('app');
    appDiv.inert = false;
  };

  const {
    data: {
      reportId, editToken, companyid, page, isAnyEmpty, companyMode
    }
  } = useContext(PerformanceBuilderContext);
  const isCompanySpecific = companyMode === 'company';

  const isDashboardPage = page === 'dashboard';
  const isViewPage = page === 'view';
  const isGroupedViewPage = page === 'group-view';
  const isAnyViewPage = isViewPage || isGroupedViewPage;
  const isEditPage = page === 'edit';
  const isBuilderPage = page === 'builder';

  const isReportCreated = Boolean(reportId);
  const loggedUser = appUtils.getLoggedUser();
  const isLoggedIn = appUtils.isLoggedIn();

  const currentCompanyId = get(loggedUser, 'companyid') || companyid;

  const navigate = useNavigate();
  const routeToNewReport = (reload = false) => {
    const url = `/builder/new${currentCompanyId ? `?companyid=${currentCompanyId}` : ''}`;

    if (reload) {
      window.location.href = url;
    } else {
      navigate(url);
    }
  };

  const queryClient = useQueryClient();
  const {
    update: updateBuilderReport,
    isLoading: isUpdateBuilderReportLoading
  } = useUpdateBuilderReport(editToken);
  const { data: reportData } = useBuilderReport(reportId);
  const isDraft = isReportCreated
    && get(reportData, 'status') === BUILDER_REPORT_STATUS.DRAFT;
  const showShareButton = !isDashboardPage && !isAnyViewPage && isReportCreated && viewBuilderShareButton;

  return (
    <>
      <ActionModal
        isOpen={isSubmitReviewModalOpen}
        onOpen={() => closeOverlays('submit-review')}
        close={() => setIsSubmitReviewModalOpen(false)}
        title="Are you sure you'd like to submit?"
        content='This indicates that you’re done writing your review.'
        action1Text='Submit Review'
        onAction1={async () => {
          await updateBuilderReport({
            data: { status: BUILDER_REPORT_STATUS.COMPLETE }
          });
          queryClient.invalidateQueries(BUILDER_QUERY_KEYS.BUILDER_DASHBOARD);
          queryClient.invalidateQueries([BUILDER_QUERY_KEYS.BUILDER, reportId]);
          setIsSubmitReviewModalOpen(false);
          setIsSubmittedReviewModalOpen(true);
        }}
        action2Text='Cancel'
        onAction2={() => setIsSubmitReviewModalOpen(false)}
        isLoading={isUpdateBuilderReportLoading}
      />
      <ActionModal
        isOpen={isSubmittedReviewModalOpen}
        onOpen={() => closeOverlays('submitted-review')}
        close={() => setIsSubmittedReviewModalOpen(false)}
        title='Performance Review Submitted'
        content='This review has been submitted. You can view the completed review or create a new one.'
        action1Text='Create New'
        onAction1={() => routeToNewReport(true)}
        action2Text='View Review'
        onAction2={() => {
          setIsSubmittedReviewModalOpen(false);
          navigate(`/builder/${reportId}/view`);
        }}
        type='success'
      />
      <AlertModal
        isOpen={isCreateNewModalOpen}
        onOpen={() => closeOverlays('create-new')}
        close={() => setIsCreateNewModalOpen(false)}
        onAction={() => {
          setIsCreateNewModalOpen(false);
          routeToNewReport(true);
        }}
        title='Create a New Report'
        content='Unless you would like to start fresh, make sure to complete your current review to not lose your work.'
      />
      <SignFlyout
        width={40}
        offset='60px'
        isOpen={isSignFlyoutOpen}
        setIsOpen={setIsSignFlyoutOpen}
        onOpen={() => closeOverlays('sign')}
      />
      <PricingFlyout
        width={80}
        offset='60px'
        isOpen={isPricingFlyoutOpen}
        setIsOpen={setIsPricingFlyoutOpen}
        onOpen={() => closeOverlays('pricing')}
      />
      <ShareFlyout
        width={40}
        offset='60px'
        isOpen={isShareFlyoutOpen}
        setIsOpen={setIsShareFlyoutOpen}
        onOpen={() => closeOverlays('share')}
        signUpCallback={() => {
          setIsSignFlyoutOpen(false);
          setIsShareFlyoutOpen(false);
          setIsPricingFlyoutOpen(true);
        }}
      />
      <div className='bg-black h-14 flex items-center justify-between w-full z-20 fixed px-6'>
        <WorkStoryTitle className='w-44' />
        <div className='flex gap-4 items-center mx-6'>
          {isLoggedIn && !isDashboardPage ? (
            <Button
              variant='transparent'
              classes='px-4 py-tiny rounded text-sm text-white border border-white'
              onClick={() => navigate('/builder/dashboard')}
            >
              Reviews
            </Button>
          ) : null}

          {!isLoggedIn ? (
            <>
              <Button
                variant='transparent'
                classes='px-4 py-tiny rounded text-sm text-white border border-white'
                disabled={isSignFlyoutOpen}
                onClick={() => setIsSignFlyoutOpen(true)}
              >
                Sign in
              </Button>
              <Button
                variant='transparent'
                classes='px-4 py-tiny rounded text-sm text-white border border-white'
                disabled={isPricingFlyoutOpen}
                onClick={() => setIsPricingFlyoutOpen(true)}
              >
                Pricing
              </Button>
            </>
          ) : null}
          {showShareButton ? (
            <Button
              variant='transparent'
              classes='px-4 py-tiny rounded text-sm text-white border border-white'
              disabled={isShareFlyoutOpen}
              onClick={() => setIsShareFlyoutOpen(true)}
            >
              Share
            </Button>
          ) : null}
          {isEditPage && isDraft ? (
            <Button
              variant='purple'
              classes='px-16 py-tiny rounded text-sm flex gap-2 items-center'
              disabled={
                (isCompanySpecific && isAnyEmpty)
                || isSubmitReviewModalOpen
                || isUpdateBuilderReportLoading
                || isSubmittedReviewModalOpen
              }
              onClick={() => setIsSubmitReviewModalOpen(true)}
            >
              Submit Review
            </Button>
          ) : null}
          {isDashboardPage && (
            <Button
              variant='transparent'
              classes='!px-4 py-tiny rounded text-sm text-white border border-white'
              disabled={isCreateNewModalOpen}
              onClick={() => {
                if (isDashboardPage) {
                  return routeToNewReport();
                }

                setIsCreateNewModalOpen(true);
              }}
            >
              Create New Review
            </Button>
          )}
          {isLoggedIn ? (
            <DropMenu
              direction='left'
              component={(
                <span className='flex items-center gap-2 text-white'>
                  <ProfileCircleSVG className='h-full w-auto aspect-square' />
                  <p className='m-0'>
                    {commonUtils.shortenFullName(loggedUser)}
                  </p>
                </span>
              )}
              items={[
                {
                  label: 'Create New Review',
                  onClick: () => {
                    if (isDashboardPage) {
                      return routeToNewReport();
                    }

                    setIsCreateNewModalOpen(true);
                  },
                  disabled: isCreateNewModalOpen,
                  hidden: !isDashboardPage
                },
                {
                  label: 'Sign Out',
                  onClick: () => {
                    appUtils.logUserOut('/builder');
                  }
                }
              ]}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default BuilderHeader;
