import React from 'react';
import NavigationItem from 'src/pagesDashboard/HomePage/components/mobile/NavigationItem';
import FlagSVG from 'src/assets/svg/flag.svg';
import Goal from 'src/containers/UserProfile/Goals/components/mobile/Goal';
import {
  Pagination, Button, appUtils, Base
} from 'src/components/';
import { useNavigate, useLocation } from 'react-router-dom';
import STYLE from 'src/constants/style';

const MobileUserGoals = ({
  userId,
  goals,
  currentPage,
  setCurrentPage,
  totalPages
}) => {
  const loggedUserId = appUtils.getLoggedUserId();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const showPagination = totalPages > 1;

  return (
    <Base classes={STYLE.MOBILE_CONTAINER}>
      <div className='w-full fixed top-0 mt-12 bg-white z-50'>
        <NavigationItem
          goBack={() => navigate('/dashboard/home')}
          title='My Goals'
        >
          <FlagSVG />
        </NavigationItem>
      </div>
      <div className='mt-[132px]'>
        {goals && goals.length > 0 ? (
          <div>
            <div className={showPagination ? `mb-32` : 'mb-24'}>
              {goals.map((goal) => (
                <div key={goal._id}>
                  <Goal goal={goal} userId={userId} redirectUrl={pathname} />
                </div>
              ))}
            </div>
            <div className={`w-full flex flex-col items-center px-6 pb-2 ${showPagination ? 'gap-6' : ''} fixed bottom-0 bg-white`}>
              {
                showPagination ? (
                  <Pagination
                    name={`Dash-goals-${userId}`}
                    totalPages={totalPages}
                    currentPage={currentPage}
                    selectPage={setCurrentPage}
                  />
                ) : (
                  <div className='h-4' />
                )
              }
              <Button
                variant='black'
                classes='w-full'
                onClick={() => navigate(`/dashboard/goal/new/${loggedUserId}`)}
              >
                Set New Goal
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <p className='text-center pt-10'>No goals set yet.</p>
            <div className='w-full items-center px-6 pb-2 mt-1 fixed bottom-0 bg-white'>
              <Button
                variant='black'
                classes='w-full'
                onClick={() => navigate(`/dashboard/goal/new/${loggedUserId}`)}
              >
                Set New Goal
              </Button>
            </div>
          </div>
        )}
      </div>
    </Base>
  );
};

export default MobileUserGoals;
