import React, { useEffect } from 'react';
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild
} from '@headlessui/react';
import CrossSVG from 'src/assets/cross.svg';

const Flyout = ({
  children,
  isOpen,
  setIsOpen,
  onOpen = () => {},
  onClose = () => {},
  width = '25',
  offset = 0,
  showXButton = true
}) => {
  useEffect(() => {
    if (isOpen) onOpen();
    else onClose();
  }, [isOpen]);

  return (
    <Transition show={isOpen}>
      <Dialog className='relative z-10' onClose={() => setIsOpen(false)}>
        <TransitionChild
          enter='ease-in-out duration-[250ms]'
          enterFrom='opacity-0 bg-secondary-gray/50'
          enterTo='opacity-100 bg-secondary-gray/50'
          leave='ease-in-out duration-[250ms]'
          leaveFrom='opacity-100 bg-secondary-gray/50'
          leaveTo='opacity-0 bg-secondary-gray/50'
        >
          <div className='fixed inset-0 backdrop-blur-[2px] bg-secondary-gray/50' />
        </TransitionChild>

        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden'>
            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
              <TransitionChild
                enter='transform transition ease-in-out duration-[250ms]'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-[250ms]'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <DialogPanel
                  className='pointer-events-auto'
                  style={{
                    height: '100%',
                    width: `${width}vw`
                  }}
                >
                  <div
                    className='flex h-full flex-col overflow-y-auto bg-white py-8 px-4 shadow-xl'
                    style={{
                      transform: `translateY(${offset})`,
                      height: `calc(100% - ${offset})`
                    }}
                  >
                    {showXButton ? (
                      <div className='flex justify-end items-center w-full h-[26px] -mb-[26px]'>
                        <button
                          type='button'
                          className='relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none z-10'
                          onClick={() => setIsOpen(false)}
                        >
                          <CrossSVG className='h-6 w-6' aria-hidden='true' />
                        </button>
                      </div>
                    ) : null}
                    <div className='relative flex-1 px-4 flex items-center justify-center'>
                      {children}
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Flyout;
