import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import STYLE from 'src/constants/style';
import { Base } from 'src/components';
import HomeHeader from 'src/pagesDashboard/HomePage/components/HomeHeader';
import HomeTasks from 'src/pagesDashboard/HomePage/components/HomeTasks';
import HomeFeedback from 'src/pagesDashboard/HomePage/components/HomeFeedback';
import HomeTeam from 'src/pagesDashboard/HomePage/components/HomeTeam';
import HomeHighlights from 'src/pagesDashboard/HomePage/components/HomeHighlights';
import HomeNotifications from 'src/pagesDashboard/HomePage/components/HomeNotifications';
import HomeOrganization from 'src/pagesDashboard/HomePage/components/HomeOrganization';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useAccount } from 'src/queries/account';
import HomeOnboarding from 'src/pagesDashboard/HomePage/components/HomeOnboarding';
import { isEmpty } from 'lodash';

const { ACCESS, ONBOARDING_STATUS } = COMMON_CONSTANTS;

const HomePage = () => {
  const {
    data: account,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount('me');

  const { showHomeRosterSection } = useFlags();

  const isFetching = isFetchingAccount;
  const isError = isErrorAccount;
  const isReady = !isFetching && !isError && !isEmpty(account);
  if (!isReady) return null;

  const isAdmin = account.access === ACCESS.ADMIN;
  const {
    onboarding: { status }
  } = account;
  const isOnboarding = status === ONBOARDING_STATUS.PENDING;

  const canViewHomeTeam = showHomeRosterSection;

  return (
    <>
      <HomeHeader />
      <Base classes={STYLE.BASE}>
        <div className='flex justify-between w-full h-full gap-4'>
          {isOnboarding ? (
            <div className='flex flex-col h-full w-full'>
              <HomeOnboarding />
            </div>
          ) : (
            <>
              <div className='flex flex-col h-full w-2/3'>
                <HomeTasks />
                <HomeFeedback />
                {isAdmin ? <HomeOrganization /> : null}
              </div>
              <div className='flex flex-col h-full w-1/3'>
                <HomeNotifications />
                {canViewHomeTeam ? <HomeTeam /> : null}
                <HomeHighlights />
              </div>
            </>
          )}
        </div>
      </Base>
    </>
  );
};

export default HomePage;
