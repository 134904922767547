import { useContext } from 'react';
import { DashContext } from 'src/pagesDashboard/Dash/context/DashProvider';
import { UserProfileContext } from 'src/pagesDashboard/UserProfile/context/UserProfileProvider';
import { useReviewsV2 } from 'src/queries/reviews';
import { useAccountScore, useAccountParticipation } from 'src/queries/account';
import appUtils from 'src/components/appUtils';
import commonDateUtils from 'common/commonDateUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useFeedbacks } from 'src/queries/feedback';

const {
  REVIEW_STATUS, FEEDBACK_TYPE, SHARE_REVIEW_WITH
} = COMMON_CONSTANTS;

export const useActivityTab = (userId) => {
  const loggedUserId = appUtils.getLoggedUserId();
  const isMyProfile = userId === loggedUserId;
  const {
    context,
    context: { range, currentPages, filters },
    updateContext
  } = useContext(isMyProfile ? DashContext : UserProfileContext);
  const {
    revieweeIds, revieweeGroup, roles, category
  } = filters;

  // REVIEWS DATA
  const {
    data: historicReviews,
    meta: { page: historicReviewsPage },
    isFetching: isFetchingHistoricReviews,
    isError: isErrorHistoricReviews
  } = useReviewsV2(
    {
      reviewers: [userId],
      notReviewees: userId !== loggedUserId ? [loggedUserId] : [],
      status: [REVIEW_STATUS.REVIEWED, REVIEW_STATUS.NOT_AVAIL],
      ...(!isMyProfile && { anonymous: false }),
      ...(revieweeIds?.length && {
        reviewees: revieweeIds
      }),
      ...(revieweeGroup && {
        revieweeGroup
      }),
      ...(roles?.length && {
        roles
      }),
      ...(category && {
        categories: [category]
      }),
      ...(range.start && {
        scheduledDate: {
          start: range.start,
          end: range.end
        }
      })
    },
    {
      page: {
        number: currentPages.actHistoricReviews
      },
      sort: {
        field: 'reviewedDate',
        order: 'desc'
      },
      include: {
        answerLabel: true
      }
    },
    {
      enabled: Boolean(userId)
    }
  );

  // SCORE DATA
  const {
    data: topScores,
    isFetching: isFetchingTopScores,
    isError: isErrorTopScores
  } = useAccountScore(
    {
      id: userId,
      ...(range.start && {
        start: range.start,
        end: range.end
      })
    },
    {
      role: 'reviewer'
    },
    {
      enabled: Boolean(userId)
    }
  );

  // PARTICIPATION DATA
  const {
    data: participationStats,
    isFetching: isFetchingParticipationStats,
    isError: isErrorParticipationStats
  } = useAccountParticipation({
    id: userId,
    ...(range.start && {
      start: range.start,
      end: range.end
    })
  });

  // FEEDBACK DATA
  const {
    data: feedbacks,
    meta: { page: feedbacksPage },
    isFetching: isFetchingFeedbacks,
    isError: isErrorFeedbacks
  } = useFeedbacks(
    {
      reviewers: [userId],
      notReviewees: userId !== loggedUserId ? [loggedUserId] : [],
      types: [FEEDBACK_TYPE.FEEDBACK, FEEDBACK_TYPE.NOTE],
      ...(!isMyProfile && {
        notVisibility: [SHARE_REVIEW_WITH.ANONYMOUS]
      }),
      ...(revieweeIds?.length && {
        reviewees: revieweeIds
      }),
      ...(revieweeGroup && {
        revieweeGroup
      }),
      ...(roles?.length && {
        roles
      }),
      ...(category && {
        categories: [category]
      }),
      ...(range.start && {
        createdDate: {
          start: range.start,
          end: range.end
        }
      })
    },
    {
      page: {
        number: currentPages.actFeedback
      },
      sort: {
        field: 'createdDate',
        order: 'desc'
      },
      include: {
        reviewee: true
      }
    },
    {
      enabled: Boolean(userId)
    }
  );
  // formatting feedbacks to fit FeedbackList component props
  let formattedFeedbacks = [];
  if (!isFetchingFeedbacks) {
    formattedFeedbacks = feedbacks.map((feedback) => {
      const { reviewee } = feedback;
      return {
        ...feedback,
        date: feedback.createdDate,
        revieweeName: reviewee.name,
        reviewee: reviewee._id
      };
    });
  }

  const getRangeLabel = (rangeValue) => {
    if (rangeValue === 'lastMonth') {
      return 'Last month';
    }
    if (rangeValue === '3months') {
      return '3 months';
    }
    if (rangeValue === '6months') {
      return '6 months';
    }
    if (rangeValue === '1year') {
      return '1 year';
    }
    if (rangeValue === 'custom') {
      const startDate = commonDateUtils.unixToMonthDayYearFormat(range.start);
      const endDate = commonDateUtils.unixToMonthDayYearFormat(range.end);
      return `${startDate} - ${endDate}`;
    }
    return 'All time';
  };
  const setPage = (key, n) => {
    updateContext({
      currentPages: {
        ...context.currentPages,
        [key]: n
      }
    });
  };

  return {
    context,
    updateContext,
    rangeLabel: getRangeLabel(range.value),
    historicReviews: {
      data: historicReviews,
      isFetching: isFetchingHistoricReviews,
      isError: isErrorHistoricReviews,
      page: {
        ...historicReviewsPage,
        setPage: (n) => setPage('actHistoricReviews', n)
      }
    },
    topScores: {
      data: topScores,
      isFetching: isFetchingTopScores,
      isError: isErrorTopScores
    },
    participationStats: {
      data: participationStats,
      isFetching: isFetchingParticipationStats,
      isError: isErrorParticipationStats
    },
    feedback: {
      data: formattedFeedbacks,
      isFetching: isFetchingFeedbacks,
      isError: isErrorFeedbacks,
      page: {
        ...feedbacksPage,
        setPage: (n) => setPage('actFeedback', n)
      }
    }
  };
};

export default useActivityTab;
