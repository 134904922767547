import React from 'react';
import propTypes from 'prop-types';

const Checkbox = ({
  id,
  value = false,
  onChange,
  label = '',
  classes,
  disabled,
  ...rest
}) => (
  <div className='flex'>
    <div className={`${label ? 'mr-2' : ''}`}>
      <input
        id={`${id}-${label}`}
        className='align-middle h-4 w-4 mb-0.5'
        type='checkbox'
        name={label}
        checked={Boolean(value)}
        onChange={(event) => onChange(event.target.checked)}
        disabled={disabled}
        style={{
          ...(disabled && !value
            ? {
              filter: 'brightness(0.7)'
            }
            : {})
        }}
        {...rest}
      />
    </div>
    <label
      htmlFor={`${id}-${label}`}
      className={`align-middle mb-0 ${classes}`}
    >
      {label}
    </label>
  </div>
);

Checkbox.propTypes = {
  onChange: propTypes.func.isRequired
};

export default Checkbox;
