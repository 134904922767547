import React from 'react';
import COMMON_CONSTANT from 'common/commonConstants';
import Spinner from '../../assets/spinner.svg';
import appUtils from '../../components/appUtils';
import './RedirectPage.scss';

const { PUBLIC_BUCKET_URL } = COMMON_CONSTANT;

const RedirectPage = ({ name }) => {
  const attempts = 0;

  while (!window.FS) {
    console.log('not..');
  }
  console.log('adding..');
  window.FS.event('Redirect to Site', { name });
  console.log('added..');
  window.location.replace('/');

  // const myInterval = setInterval(() => {
  //   attempts++;
  //   console.log('000) attempts', attempts);
  //   if (window.FS && window.FS.event) {
  //     clearInterval(myInterval);
  //     appUtils.customEventFS('Redirect to Site', {
  //       name
  //     });
  //     console.log('addded event, redirecting now');
  //     window.location.replace('/');
  //   }
  //   if (attempts > 30) {
  //     console.log('attempts is > 30.. just redirecting...');
  //     window.location.replace('/');
  //   }
  // }, 300);

  return (
    <div className='redirect-div text-center'>
      <img
        src={`${PUBLIC_BUCKET_URL}/speedback-logo_color-getspeedback.png`}
        className='redirect-logo'
      />
      <Spinner className='redirect-image' />
      <p className='redirect-text'>Redirecting..</p>
    </div>
  );
};

export default RedirectPage;
