import React from 'react';
import { useAllCompanies, runSuperuserActionQuery } from 'src/queries/admin';
import appUtils from 'src/components/appUtils';
import commonDateUtils from 'common/commonDateUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useQueryClient } from 'react-query';

const { ENABLED_STATUS } = COMMON_CONSTANTS;

const Impersonate = () => {
  const queryClient = useQueryClient();
  const { data: companies } = useAllCompanies({ enabled: true });
  const { mutateAsync: runAction, isLoading } = runSuperuserActionQuery();
  const click = async (c) => {
    const resp = await runAction({
      action: 'impersonate-root-of-company',
      companyid: c.id
    });
    if (resp.error) {
      return false;
    }
    const { userData, myTreeRow } = resp;
    appUtils.saveLoggedUser(userData);
    queryClient.removeQueries();
    window.location.reload();
  };

  const now = commonDateUtils.getUnixDateNow();

  return (
    <div>
      <p>
        Click on a company to impersonate the root tree node of the
        organization:
      </p>
      {companies
        && companies
          .sort((a, b) => ((a.events.reviews === ENABLED_STATUS.ENABLED)
            > (b.events.reviews === ENABLED_STATUS.ENABLED)
            ? -1
            : 1))
          .map((c, index) => (
            <a key={index} className='block mb-0' onClick={() => click(c)}>
              <span className='inline-block w-40 overflow-hidden'>
                {c.name}
              </span>
              <span className='inline-block overflow-hidden'>
                -
                {`Events enabled: ${
                  c.events.reviews === ENABLED_STATUS.ENABLED ? '[REVIEWS]' : ''
                }${
                  c.events.goals === ENABLED_STATUS.ENABLED ? '+[GOALS]' : ''
                }${
                  c.events.pulse === ENABLED_STATUS.ENABLED ? '+[PULSE]' : ''
                }`}
              </span>
              -
              {c.nextSchedule
                ? `Next scheduling: ${commonDateUtils
                  .unixToMonthDayYearHourMinutesFormat(c.nextSchedule)
                  .toString()} `
                : ' '}
              {c.nextSchedule && parseFloat(c.nextSchedule) < parseFloat(now)
                ? ' - Scheduling is in the past!'
                : ''}
            </a>
          ))}
    </div>
  );
};

export default Impersonate;
