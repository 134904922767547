import React, { useRef, useEffect } from 'react';
import { get } from 'lodash';
import useEvent from 'src/hooks/useEvent/useEvent';
import './Modal.scss';

// variant: max | md | sm | custom
// animation: 1 | 2
const Modal = ({
  children,
  close,
  variant,
  animation,
  classes,
  innerClasses,
  clickClose = true
}) => {
  const restoreBodyStyle = () => {
    document.body.style.height = 'auto';
    document.body.style.overflow = 'auto';
  };

  const selectRef = useRef(null);
  variant = variant || 'md';

  const handler = (e) => {
    if (!clickClose) {
      return;
    }

    const classes = get(e, 'target.className', '').split(' ');
    if (classes.includes('modal-ignore-clickaway')) {
      return;
    }

    if (selectRef && !selectRef.current.contains(e.target)) {
      restoreBodyStyle();
      close();
    }
  };
  useEvent('click', handler, false, true);

  useEffect(() => {
    document.body.style.height = '100%';
    document.body.style.overflow = 'hidden';
    return () => {
      restoreBodyStyle();
    };
  });

  const keyboardEventListener = (e) => {
    if (e.key === 'Escape') {
      // closeModal();
    }
  };

  if (!window.modalKeyboardEventListener) {
    document.addEventListener('keydown', keyboardEventListener);
    window.modalKeyboardEventListener = 1;
  }

  // document.body.style.height = '100%'
  // document.body.style.overflow = 'hidden'

  return (
    <div className={`speedback-modal ${classes || ''}`}>
      <div
        className={`speedback-modal-inner-${variant} ${innerClasses || ''}`}
        ref={selectRef}
      >
        {/* <CloseButton classes="speedback-modal-close-button" onClick={closeModal} /> */}
        <div
          className={`speedback-modal-content-${variant} ${
            animation ? `animation-${animation}` : ''
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
