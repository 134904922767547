import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { ALL_STEPS, getRoutes } from 'src/pagesDashboard/NewUserReport/utils';
import { useNewUserReportContext } from 'src/pagesDashboard/NewUserReport/context/NewUserReportProvider';
import { GoalsListSection } from 'src/pagesDashboard/NewUserReport/containers/NewUserReportGoalsAndObjectives';
import { useNavigate, useParams } from 'react-router-dom';

const NewUserReportGoalsAndObjectives = () => {
  const { userId, reportId } = useParams();
  const navigate = useNavigate();

  const { updateContext } = useNewUserReportContext();
  const formRef = useRef();

  useEffect(() => {
    updateContext({ formRef, activeStep: ALL_STEPS.GOALS });
  }, []);

  const { handleSubmit } = useForm({
    defaultValues: {}
  });

  const setIsLoading = (isLoading) => updateContext({ isLoading });

  const goNext = () => {
    const routeToGo = getRoutes(userId, reportId, ALL_STEPS.SUMMARY);
    return navigate(routeToGo);
  };

  return (
    <form
      id='new-user-report-performance-categories-form'
      ref={formRef}
      className='flex flex-col h-full w-full justify-between'
      onSubmit={handleSubmit(goNext)}
    >
      <GoalsListSection
        userId={userId}
        reportId={reportId}
        setIsLoading={setIsLoading}
        isEditorOpenByDefault={false}
      />
    </form>
  );
};

export default NewUserReportGoalsAndObjectives;
