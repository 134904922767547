import React from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import { useAccounts } from 'src/queries/account';
import { Base, toast } from 'src/components';
import { giveFeedbackQuery, QUERY_KEYS } from 'src/queries/feedback';
import FeedbackForm from 'src/components/FeedbackForm/FeedbackForm';
import HeaderV2 from 'src/containers/Header/HeaderV2';
import appUtils from 'src/components/appUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import STYLE from 'src/constants/style';

const { FEEDBACK_TYPE } = COMMON_CONSTANTS;

const DirectFeedback = () => {
  const { reviewees } = useParams();
  const navigate = useNavigate();

  const revieweesArray = reviewees.split(',');

  const {
    data: { myTreeRow },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const {
    data: revieweeAccounts,
    isFetching: isFetchingRevieweeAccounts,
    isError: isErrorRevieweeAccounts
  } = useAccounts(
    {
      ids: revieweesArray
    },
    {
      page: {
        size: 30
      }
    },
    {
      keepPreviousData: true,
      staleTime: Infinity,
      cacheTime: Infinity
    }
  );

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const { mutateAsync: giveFeedback, isLoading: giveFeedbackLoading } = giveFeedbackQuery();

  const queryClient = useQueryClient();

  const isFetching = isFetchingCompany || isFetchingRevieweeAccounts || isFetchingTree;
  const isError = isErrorCompany || isErrorRevieweeAccounts || isErrorTree;
  const isReady = company && company.id && myTreeRow && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const submit = async (values) => {
    try {
      const { text, visible } = values;

      const giveFeedbackPromises = [];
      for (const revieweeId of revieweesArray) {
        giveFeedbackPromises.push(() => giveFeedback({
          revieweeId,
          text,
          visible,
          type: FEEDBACK_TYPE.FEEDBACK
        }));
      }

      const [result] = await Promise.all(
        giveFeedbackPromises.map((fn) => fn())
      );

      if (result.success) {
        queryClient.removeQueries(QUERY_KEYS.WIZARD_REVIEW_SUGGESTIONS);
        queryClient.invalidateQueries(QUERY_KEYS.FEEDBACK);
        queryClient.invalidateQueries(QUERY_KEYS.FEEDBACK_FEED);
        queryClient.invalidateQueries(QUERY_KEYS.FEEDBACK_COUNT);
      }

      if (!result || !result.success) {
        toast.error('Uh oh, we ran into an issue. Please try again later!');
      }
      appUtils.scrollToTop();
      toast.show('Thank you!');
      return navigate(`/dashboard/home`);
    } catch (error) {
      toast.error('Uh oh, we ran into an issue. Please try again!');
    }
  };

  const isMobile = appUtils.getMobileSize();

  const revieweeNames = revieweeAccounts.map(
    (reviewee) => `${reviewee.firstName} ${reviewee.lastName.substr(0, 1)}`
  );

  let title = '';
  if (revieweeNames.length === 1) {
    [title] = revieweeNames;
  } else if (revieweeNames.length > 1 && revieweeNames.length <= 3) {
    title = [...revieweeNames].splice(0, 3).join(', ');
  } else {
    title = `${revieweeNames.length} Team Members`;
  }

  if (isMobile) {
    return (
      <FeedbackForm
        revieweesId={revieweesArray}
        submit={submit}
        loading={giveFeedbackLoading}
      />
    );
  }

  return (
    <div className='mt-4'>
      <HeaderV2
        overtitle='Feedback'
        title={`Submit feedback for ${title}`}
      />
      <Base classes={STYLE.BASE}>
        <FeedbackForm
          revieweesId={revieweesArray}
          submit={submit}
          loading={giveFeedbackLoading}
        />
      </Base>
    </div>
  );
};

export default DirectFeedback;
