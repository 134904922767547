import React from 'react';
import commonDateUtils from 'common/commonDateUtils';
import './SummaryAICard.scss';
import CubeIcon from 'src/assets/svg/cube-icon.svg';

const SummaryAICardV2 = ({ strings, endDate }) => {
  const text = strings[1];
  const date = commonDateUtils.unixToMonthYear(endDate);

  return (
    <div className='flex flex-col gap-4'>
      <div className='w-full flex gap-4 items-center justify-between'>
        <div className='flex gap-4 items-center'>
          <div className='p-2 bg-cloud-blue rounded-lg w-10 h-10'>
            <CubeIcon className='h-full w-full' />
          </div>
          <div className='text-xl font-bold text-black'>
            Performance Summary
          </div>
        </div>
      </div>

      <div className='text-xl p-1 rounded-lg bg-cloud-blue'>{text}</div>
      <div className='text-xl font-bold text-right'>{date}</div>
    </div>
  );
};

export default SummaryAICardV2;
