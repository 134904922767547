import React from 'react';
import WorkStoryTitle150 from 'src/assets/svg/workstory-title-150.svg';

const SignUpBase = ({ title, children }) => (
  <div className='flex flex-col justify-center flex-1 m-auto h-full w-full'>
    <div className='sm:mx-auto sm:w-full sm:max-w-md'>
      <WorkStoryTitle150 className='mx-auto' fill='#1C2434' />
      <h2 className='mt-6 text-center text-2xl font-bold leading-9 text-gray-900'>
        {title}
      </h2>
    </div>

    <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]'>
      <div className='bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12 space-y-6'>
        {children}
      </div>
    </div>
  </div>
);

export default SignUpBase;
