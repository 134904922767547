import { useQuery, useMutation, useQueryClient } from 'react-query';
import qs from 'qs';
import api from 'src/services/api';
import commonDateUtils from 'common/commonDateUtils';
import { get, isNil } from 'lodash';

export const QUERY_KEYS = {
  FEEDBACK: 'feedback',
  FEEDBACKS: 'feedbacks',
  FEEDBACK_FEED: 'feedback-feed',
  FEEDBACK_COUNT: 'feedback-count'
};

export const requestFeedback = () => useMutation((data) => api.post('/feedback/request', data));

export const giveFeedbackQuery = () => useMutation(({
  revieweeId, text, visible, type
}) => api.post('/feedback/feedback', {
  revieweeId,
  text,
  visible,
  type
}));

export const updateFeedbackQuery = () => useMutation(({
  feedbackId, reviewee, text, visible, type
}) => api.patch('/feedback/feedback', {
  feedbackId,
  reviewee,
  text,
  visible,
  type
}));

const feedbackPlaceholder = {
  feedbacks: [],
  notes: [],
  pagination: { total: 0 }
};

export const useFeedback = (
  {
    ids,
    range = undefined,
    start = undefined,
    end = undefined,
    roles = undefined,
    category = undefined,
    reviewerGroup = undefined,
    reviewerIds = undefined,
    includeEmptyComments = false,
    includeAnonymousReviews = false,
    options = undefined
  },
  queryOptions = {}
) => {
  const queryKey = qs.stringify(
    {
      ids,
      range,
      roles,
      category,
      reviewerGroup,
      reviewerIds,
      includeEmptyComments,
      includeAnonymousReviews,
      options,
      ...(range === 'custom' ? { start, end } : null)
    },
    { skipNulls: true }
  );

  return useQuery(
    [QUERY_KEYS.FEEDBACK, ...queryKey.split('&')],
    () => {
      const params = `?users=${ids.join(',')}${start ? `&start=${start}` : ''}${
        end ? `&end=${end}` : ''
      }${category ? `&category=${category}` : ''}${
        reviewerGroup ? `&reviewerGroup=${reviewerGroup}` : ''
      }${
        reviewerIds && reviewerIds.length ? `&reviewerIds=${reviewerIds}` : ''
      }${roles && roles.length ? `&roles=${roles}` : ''}${
        options && options.page ? `&page=${options.page}` : ''
      }${!isNil(includeEmptyComments) ? `&includeEmptyComments=${includeEmptyComments}` : ''}${options && options.size ? `&size=${options.size}` : ''}${
        options && options.feedbackTypes
          ? `&feedbackTypes=${options.feedbackTypes.join(',')}`
          : ''
      }${options && !isNil(options.joinReviews) ? `&joinReviews=${options.joinReviews}` : ''}${!isNil(includeAnonymousReviews) ? `&includeAnonymousReviews=${includeAnonymousReviews}` : ''}${options && !isNil(options.viewerId) ? `&viewerId=${options.viewerId}` : ''}`;
      return api.get(`/feedback/feedback${params}`).then((resp) => {
        if (!resp || !resp.success) {
          return { ...feedbackPlaceholder };
        }
        return resp.data;
      });
    },
    {
      enabled: Boolean(ids && ids.length > 0),
      placeholderData: {
        ...feedbackPlaceholder
      },
      ...queryOptions
    }
  );
};

export const useCompanyFeedback = (
  {
    start, end, author, recipient, managerId, includeUnassignedUsers, options
  },
  queryOptions = {}
) => {
  const queryKey = qs.stringify(
    {
      start,
      end,
      author,
      recipient,
      managerId,
      includeUnassignedUsers,
      options
    },
    { skipNulls: true }
  );

  return useQuery(
    [QUERY_KEYS.FEEDBACK_FEED, ...queryKey.split('&')],
    async () => {
      const params = `?${`start=${start}`}
      ${end ? `&end=${end}` : `&end=${commonDateUtils.dateToUnix(new Date())}`}
      ${options && options.size ? `&size=${options.size}` : ''}
      ${options && options.page ? `&page=${options.page}` : ''}
      ${options && options.joinReviews !== undefined ? `&joinReviews=${options.joinReviews}` : ''}
      ${options.sortBy ? `&sortBy=${options.sortBy}` : ''}
      ${author ? `&author=${author}` : ''}${
  recipient ? `&recipient=${recipient}` : ''
}${managerId ? `&managerId=${managerId}` : ''}
${
  includeUnassignedUsers
    ? `&includeUnassignedUsers=${includeUnassignedUsers}`
    : ''
}`;

      const resp = await api.get(`/feedback/company/${params}`);
      if (!resp || !resp.success) {
        return { feedbacks: [], pagination: { pages: 1, current: 1 } };
      }
      return resp.data;
    },
    { ...queryOptions }
  );
};

export const useCompanyFeedbackCount = ({
  groupBy,
  start,
  end,
  includeArchived,
  visible,
  options
}) => {
  const queryKey = qs.stringify(
    {
      groupBy,
      options,
      start,
      end,
      includeArchived,
      visible
    },
    { skipNulls: true }
  );

  return useQuery([QUERY_KEYS.FEEDBACK_COUNT, ...queryKey.split('&')], () => {
    const queryObject = {
      groupBy,
      size: options.size,
      sort: options.sort,
      joinReviews: options.joinReviews,
      start,
      end,
      includeArchived,
      visible
    };

    return api.get(`/feedback/count`, queryObject).then((resp) => {
      if (!resp || !resp.success) {
        return [];
      }
      return resp.data;
    });
  });
};

export const likeFeedbackQuery = () => useMutation(({ id, type, reaction }) => api.post('/feedback/like', {
  id,
  type,
  reaction
}));

export const useFeedbackV2 = (id, { include } = {}) => {
  const options = { include };
  const stringified = qs.stringify({ options }, { skipNulls: true });

  const { data, ...rest } = useQuery(
    ['feedback', id, options],
    () => api.get(`/feedback/${id}?${stringified}`).then((resp) => {
      if (!resp.success) {
        console.error(`GET /feedback/${id}?${stringified} error`);
        return {
          success: false
        };
      }
      return resp;
    }),
    {
      enabled: Boolean(id)
    }
  );

  return {
    data: get(data, 'data', {}),
    meta: get(data, 'meta', {}),
    success: get(data, 'success'),
    ...rest
  };
};

export const useFeedbacks = (
  {
    ids,
    reviewers,
    reviewees,
    notReviewees,
    revieweeGroup,
    visibility,
    notVisibility,
    roles,
    categories,
    types,
    createdDate
  } = {},
  {
    page, sort, include, projection
  } = {},
  queryOptions = {}
) => {
  const filters = {
    ids,
    reviewers,
    reviewees,
    notReviewees,
    revieweeGroup,
    visibility,
    notVisibility,
    roles,
    categories,
    types,
    createdDate
  };
  const options = {
    page,
    sort,
    include,
    projection
  };
  const stringified = qs.stringify({ filters, options }, { skipNulls: true });

  const { data, ...rest } = useQuery(
    [QUERY_KEYS.FEEDBACKS, filters, options],
    () => api.get(`/feedback?${stringified}`).then((resp) => {
      if (!resp.success) {
        console.error(`GET /feedback?${stringified} error`);
        return {
          success: false
        };
      }
      return resp;
    }),
    queryOptions
  );

  return {
    data: get(data, 'data', []),
    meta: get(data, 'meta', {
      page: {
        number: null,
        size: null,
        totalItems: null,
        totalPages: null
      }
    }),
    success: get(data, 'success'),
    ...rest
  };
};

export const useFeedbackUpdate = (id) => {
  const queryClient = useQueryClient();
  const { mutateAsync: mutate, ...rest } = useMutation(
    (data) => api.patch(`/feedback/${id}`, data).then((resp) => {
      if (!resp.success) {
        console.error(`PATCH /feedback/${id} error`);
        return {
          success: false
        };
      }
      return resp;
    }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.FEEDBACK);
        queryClient.invalidateQueries(QUERY_KEYS.FEEDBACKS);
      }
    }
  );

  return {
    update: mutate,
    ...rest
  };
};

export const useFeedbackDelete = (id) => {
  const queryClient = useQueryClient();
  const { mutateAsync: mutate, ...rest } = useMutation(
    (data) => api.delete(`/feedback/${id}`, data).then((resp) => {
      if (!resp.success) {
        console.error(`DELETE /feedback/${id} error`);
        return {
          success: false
        };
      }
      return resp;
    }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.FEEDBACK);
        queryClient.invalidateQueries(QUERY_KEYS.FEEDBACKS);
      }
    }
  );

  return {
    delete: mutate,
    ...rest
  };
};
