import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Impersonate from 'src/containers/Admin/Impersonate';
import SuperUserInfo from 'src/containers/Admin/SuperUserInfo';
import STYLE from 'src/constants/style';
import COMMON_CONSTANTS from 'common/commonConstants';
import { toast, Base, appUtils } from '../../components';
import tableHeaders from '../Organization/tableHeaders';
import { appThunks, adminThunks } from '../../thunks';
import './Admin.scss';
import api from '../../services/api';

const { USER_STATE } = COMMON_CONSTANTS;

const Admin = (props) => {
  const { dispatch } = props;
  const queryClient = appUtils.getQueryClient();

  const [allUsers, setAllUsers] = useState([]);
  const [showImpersonateCompanies, setShowImpersonateCompanies] = useState(null);

  useEffect(() => {
    api.get('/admin/users').then((resp) => {
      setAllUsers(resp.users);
    });
  }, []);

  const switchToUser = (userEmail) => {
    const userRow = allUsers.find((row) => row.email === userEmail);
    adminThunks
      .adminLogin(
        userEmail,
        userRow.companyid
      )(dispatch)
      .then((resp) => {
        appThunks.logUserIn(resp.userData)(dispatch);
        window.location.href = '/dashboard/organization/chart';
        queryClient.removeQueries();
        window.location.reload(true);
        // window.location.reload(true);
      });
  };

  const copyTextToClipboard = (text) => {
    const dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    toast.show(`Id '${text}' copied to clipboard`);
  };

  tableHeaders.ADMIN_REVIEWS_HEADERS[0].onClick = copyTextToClipboard;

  return (
    <div className={STYLE.BASE}>
      <Base className={STYLE.CONTAINER_WHITE}>
        <h3 data-cy='impersonate-user-title'>Impersonate User</h3>
        <button
          onClick={() => {
            console.error('Test rollbar error log', {
              custom: 'data',
              name: 'Jack'
            });
          }}
        >
          Trigger rollbar error
        </button>
        <button
          onClick={() => {
            console.error('Test rollbar error log');
            api.get('/admin/test/error').then((resp) => {
              console.log('resp', resp);
            });
          }}
        >
          Trigger Cloud Error
        </button>
        <p className='mb-0'>
          Warning: if you impersonate button user that doesn't belong to an
          organization (there are some left over, will cleanup), your app will
          break. Logout and re-log.
        </p>

        <SuperUserInfo />
        <button
          className='block'
          onClick={() => setShowImpersonateCompanies(!showImpersonateCompanies)}
        >
          Show impersonate companies
        </button>
        {showImpersonateCompanies ? <Impersonate /> : null}

        {allUsers && allUsers.length ? (
          <>
            <select
              onChange={(e) => {
                switchToUser(e.target.value);
              }}
            >
              <option value='' checked />
              {allUsers
                && allUsers
                  .filter(
                    (row) => row.email && row.status !== USER_STATE.UNASSIGNED
                  )
                  .sort((button, b) => (button.email.toLowerCase() > b.email.toLowerCase() ? 1 : -1))
                  .map((row, index) => (
                    <option key={index} value={row.email}>
                      {row.email}
                    </option>
                  ))}
            </select>
            <div className='mt-10 mb-2 text-base text-black'>
              <p className='mb-2 text-sm text-gray-600'>Quick select:</p>
              <button
                data-cy='mihai@getspeedback.com'
                className='block mb-2 '
                onClick={() => switchToUser('mihai@getspeedback.com')}
              >
                mihai@getspeedback.com
              </button>
              <button
                data-cy='matt@getspeedback.com'
                className='block mb-2'
                onClick={() => switchToUser('matt@getspeedback.com')}
              >
                matt@getspeedback.com
              </button>
              <button
                data-cy='meadowsmz@gmail.com'
                className='block mb-2'
                onClick={() => switchToUser('meadowsmz@gmail.com')}
              >
                meadowsmz@gmail.com
              </button>
              <button
                data-cy='perm-test-admin@getspeedback.com'
                className='block mb-2'
                onClick={() => switchToUser('perm-test-admin@getspeedback.com', queryClient)}
              >
                Automation Test Account - perm-test-admin@getspeedback.com
              </button>
              <br />
              <button
                data-cy='sam@2ton.com'
                className='block mb-2'
                onClick={() => switchToUser('sam@2ton.com')}
              >
                Sam - sam@2ton.com
              </button>
              <button
                data-cy='joe@palmbeachtech.org'
                className='block mb-2'
                onClick={() => switchToUser('joe@palmbeachtech.org')}
              >
                Joe - joe@palmbeachtech.org
              </button>
              <button
                data-cy='lamia@rocketmatter.com'
                className='block mb-2'
                onClick={() => switchToUser('lamia@rocketmatter.com')}
              >
                Lamia - lamia@rocketmatter.com
              </button>
              <button
                data-cy='sean@thedigitalintellect.com'
                className='block mb-2'
                onClick={() => switchToUser('sean@thedigitalintellect.com')}
              >
                Sean - sean@thedigitalintellect.com
              </button>
              <button
                data-cy='todd@bocacode.com'
                className='block mb-2'
                onClick={() => switchToUser('todd@bocacode.com')}
              >
                Todd - todd@bocacode.com
              </button>
              <button
                data-cy='dh@tsl.io'
                className='block mb-2'
                onClick={() => switchToUser('dh@tsl.io')}
              >
                David Hartmann - dh@tsl.io
              </button>
              <button
                data-cy='sduverseau@smartpandalabs.com'
                className='block mb-2'
                onClick={() => switchToUser('sduverseau@smartpandalabs.com')}
              >
                Shamir - sduverseau@smartpandalabs.com
              </button>
              <button
                data-cy='rich@purplerockscissors.com'
                className='block mb-2'
                onClick={() => switchToUser('rich@purplerockscissors.com')}
              >
                Richard Wahl - rich@purplerockscissors.com
              </button>
              <button
                data-cy='georgia@fusionideas.com'
                className='block mb-2'
                onClick={() => switchToUser('georgia@fusionideas.com')}
              >
                Georgia Dardick - georgia@fusionideas.com
              </button>
              <button
                data-cy='warren@mendfamily.com'
                className='block mb-2'
                onClick={() => switchToUser('warren@mendfamily.com')}
              >
                Warren Bates - warren@mendfamily.com
              </button>
              <button
                data-cy='pmyers@peakactivity.com'
                className='block mb-2'
                onClick={() => switchToUser('pmyers@peakactivity.com')}
              >
                Peggy Myers - pmyers@peakactivity.com
              </button>
              {/* <button data-cy="ngraham@gcdtech.com" className="block mb-2" onClick={() => { switchToUser('ngraham@gcdtech.com') }}>Nikki Graham - ngraham@gcdtech.com</button>
              <button data-cy="megan.baker@schlesingercos.com" className="block mb-2" onClick={() => { switchToUser('megan.baker@schlesingercos.com') }}>Megan Baker - megan.baker@schlesingercos.com</button> */}
              <button
                data-cy='staceypage@annuitas.com'
                className='block mb-2'
                onClick={() => switchToUser('staceypage@annuitas.com')}
              >
                Stacey Page - staceypage@annuitas.com
              </button>
              <button
                data-cy='fpatel@reibus.com'
                className='block mb-2'
                onClick={() => switchToUser('fpatel@reibus.com')}
              >
                Fal Patel - fpatel@reibus.com
              </button>
              <button
                data-cy='cweber@affirmus.com'
                className='block mb-2'
                onClick={() => switchToUser('cweber@affirmus.com')}
              >
                Chad Weber - cweber@affirmus.com
              </button>
              <button
                data-cy='brian@cloudcath.com'
                className='block mb-2'
                onClick={() => switchToUser('brian@cloudcath.com')}
              >
                Brian Fisher - brian@cloudcath.com
              </button>
              <button
                data-cy='cpierson@swensonhe.com'
                className='block mb-2'
                onClick={() => switchToUser('cpierson@swensonhe.com')}
              >
                Claudia - cpierson@swensonhe.com
              </button>
              <button
                data-cy='orlando@yourdigitalresource.com'
                className='block mb-2'
                onClick={() => switchToUser('orlando@yourdigitalresource.com', queryClient)}
              >
                Orlando - orlando@yourdigitalresource.com
              </button>
              <button
                data-cy='bob@docspera.com'
                className='block mb-2'
                onClick={() => switchToUser('bob@docspera.com')}
              >
                Bob - bob@docspera.com
              </button>
              <button
                data-cy='andy.morrise@loanpro.io'
                className='block mb-2'
                onClick={() => switchToUser('andy.morrise@loanpro.io')}
              >
                Andy Morrise - andy.morrise@loanpro.io
              </button>
              <button
                data-cy='j.lannon@chargebacks911.com'
                className='block mb-2'
                onClick={() => switchToUser('j.lannon@chargebacks911.com')}
              >
                Jaclyn Lannon - j.lannon@chargebacks911.com
              </button>
            </div>
          </>
        ) : null}
      </Base>
    </div>
  );
};

export const mapStateToProps = (state) => ({
  app: state.appReducer,
  dashboard: state.dashboardReducer,
  admin: state.adminReducer
});

export const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
