import React, { useState, useEffect, forwardRef } from 'react';
import Litepicker from 'litepicker';
import commonDateUtils from 'common/commonDateUtils';
import CalendarSVG from '../../assets/calendar.svg';
import appUtils from '../appUtils';
import './DateRange.scss';

if (!window.speedChartList) {
  window.speedChartList = [];
}

const DateRange = forwardRef(
  (
    {
      datePickerClasses,
      containerClasses,
      iconClasses,
      inputClasses,
      parentElId,
      minDate,
      maxDate,
      startDate,
      endDate,
      onSelect,
      calendarPosition,
      onHide = () => {}
    },
    ref
  ) => {
    const [picker, setPicker] = useState({
      id: `picker_${appUtils.getRandomString(35)}`,
      rendered: false
    });

    const onSelectAddon = (startDateInput, endDateInput) => {
      if (endDateInput) {
        endDateInput.setHours(23);
        endDateInput.setMinutes(59);
        endDateInput.setSeconds(59);
      }
      onSelect ? onSelect(startDateInput, endDateInput) : null;
    };

    useEffect(() => {
      if (startDate && endDate) {
        const pickerInstance = window.speedChartList[picker.id];
        pickerInstance.setDateRange(
          commonDateUtils.unixToDate(startDate),
          commonDateUtils.unixToDate(endDate),
          true
        );
      }
    }, [startDate, endDate]);

    useEffect(() => {
      if (!picker.rendered) {
        const pickerProps = {
          ...(parentElId
            ? { parentEl: document.getElementById(parentElId) }
            : {}),
          element: document.getElementById(picker.id),
          singleMode: false,
          position: calendarPosition ?? 'auto',
          onSelect: onSelectAddon,
          format: 'D MMM, YYYY',
          dropdowns: {
            minYear: 1950,
            years: true,
            months: true
          },
          setup: (picker) => {
            picker.on('selected', (date1, date2) => onSelectAddon(date1.dateInstance, date2.dateInstance));
            picker.on('hide', onHide);
            picker.on('before:render', (ui) => {
              if (datePickerClasses) {
                ui.classList.add(datePickerClasses);
              }
            });
          }
        };
        if (minDate) {
          const minimumDate = parseInt(minDate, 10) - 86400 * 7; // -7 day
          pickerProps.minDate = new Date(minimumDate * 1000);
        }
        if (maxDate) {
          const maximumDate = parseInt(maxDate, 10) + 86400 * 7; // +7 day
          pickerProps.maxDate = new Date(maximumDate);
        }

        window.speedChartList[picker.id] = new Litepicker(pickerProps);

        setPicker({
          ...picker,
          rendered: true
        });
      }
    });

    return (
      <div className={`${containerClasses} date-range-container`}>
        <input
          ref={ref}
          className={`${inputClasses} cursor-none opacity-0 bg-transparent relative hover:border-0 hover:outline-0 border-0 h-6 w-6`}
          placeholder=''
          type='text'
          id={picker.id}
        />
        <CalendarSVG
          className={`${iconClasses} h-5 w-5 text-yellow relative -top-1.5rem pointer-events-none z-0 align-middle calendar-svg`}
        />
      </div>
    );
  }
);

export default DateRange;
