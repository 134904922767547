import { useQuery, useMutation } from 'react-query';
import qs from 'qs';
import api from 'src/services/api';
import appUtils from 'src/components/appUtils';
import {
  getQueryScoreKey,
  getUserScoreKey,
  getTeamScoreKey,
  getOrganizationScoreKey
} from 'src/queries/queryKeys';
import { isNil } from 'lodash';

export const placeholder = {
  overview: {},
  categories: [],
  charts: [],
  asReviewer: null
};

export const QUERY_KEYS = {
  SCORE: 'score',
  QUERY_SCORES: 'queryScores',
  USER_SCORES: 'userScores',
  TEAM_SCORES: 'teamScores',
  ORGANIZATION_SCORES: 'organizationScores',
  GROUPS_SCORES: 'groupsScores'
};

export const useQueryScore = (companyid, filters, sort, page, include) => {
  try {
    const queryKey = getQueryScoreKey({
      companyid,
      filters,
      sort,
      page,
      include
    });

    return useQuery(
      [
        QUERY_KEYS.SCORE,
        QUERY_KEYS.QUERY_SCORES,
        ...queryKey.split('&'),
        page.page
      ],
      async () => {
        try {
          const params = qs.stringify(
            {
              companyid,
              filters,
              sort,
              page,
              include
            },
            { skipNulls: true }
          );
          const resp = await api.get(`/score/users?${params}`);
          if (!resp.success) {
            console.error('GET /score/users error', resp);
            return { ...placeholder };
          }
          return { ...resp.data };
        } catch (error) {
          console.error('score.useQueryScore', error);
          throw error;
        }
      },
      {
        enabled: Boolean(companyid),
        placeholderData: {}
      }
    );
  } catch (error) {
    console.error('useQueryScore error', error);
    return Promise.reject(error);
  }
};

export const useUserScore = ({
  userId,
  filters,
  options,
  queryOptions = {}
}) => {
  const queryKey = getUserScoreKey({ userId, filters, options });
  return useQuery(
    [QUERY_KEYS.SCORE, QUERY_KEYS.USER_SCORES, ...queryKey.split('&')],
    async () => {
      const params = qs.stringify(
        { userId, filters, options },
        { parseBooleans: true, skipNulls: true }
      );
      const resp = await api.get(`/score/user?${params}`);
      if (!resp || !resp.success) {
        console.error('GET /score/user error', resp);
        return { ...placeholder };
      }
      return { ...resp.data };
    },
    {
      placeholderData: {
        ...placeholder
      },
      enabled: Boolean(userId),
      ...queryOptions
    }
  );
};

export const useTeamScore = ({
  teamId,
  managerId,
  userIds,
  filters,
  options
}) => {
  try {
    const queryKey = getTeamScoreKey({
      teamId,
      managerId,
      userIds,
      filters,
      options
    });
    const userIdsValid = userIds && userIds.length;

    return useQuery(
      [QUERY_KEYS.SCORE, QUERY_KEYS.TEAM_SCORES, ...queryKey.split('&')],
      async () => {
        const params = qs.stringify(
          {
            teamId,
            managerId,
            userIds,
            filters,
            options
          },
          { skipNulls: true }
        );
        const resp = await api.get(`/score/team?${params}`);
        if (!resp || !resp.success) {
          console.error('GET /score/team error', resp);
          return { ...placeholder };
        }
        return { ...resp.data };
      },
      {
        enabled: Boolean(teamId || managerId || userIdsValid)
      }
    );
  } catch (error) {
    console.error('useTeamScore error', error);
    return Promise.reject(error);
  }
};

export const useOrganizationScore = ({ companyid, filters, options }) => {
  try {
    const queryKey = getOrganizationScoreKey({ companyid, filters, options });

    return useQuery(
      [
        QUERY_KEYS.SCORE,
        QUERY_KEYS.ORGANIZATION_SCORES,
        ...queryKey.split('&')
      ],
      async () => {
        const params = qs.stringify(
          { companyid, filters, options },
          { skipNulls: true }
        );
        const resp = await api.get(`/score/organization?${params}`);
        if (!resp || !resp.success) {
          console.error('GET /score/organization error', resp);
          return { ...placeholder };
        }
        return { ...resp.data };
      },
      {
        enabled: Boolean(companyid),
        placeholderData: {
          ...placeholder
        }
      }
    );
  } catch (error) {
    console.error('useOrganizationScore error', error);
    return Promise.reject(error);
  }
};

export const useGroupsScore = ({ companyid, filters, options }) => {
  try {
    const queryKey = qs.stringify(
      { companyid, filters, options },
      { skipNulls: true }
    );
    return useQuery(
      [QUERY_KEYS.SCORE, QUERY_KEYS.GROUPS_SCORES, ...queryKey.split('&')],
      async () => {
        const params = qs.stringify(
          { companyid, filters, options },
          { skipNulls: true }
        );
        const resp = await api.get(`/score/groups?${params}`);
        if (!resp || !resp.success) {
          console.error('GET /score/groups error', resp);
          return { ...placeholder };
        }
        return { ...resp.data };
      },
      {
        enabled: Boolean(companyid)
      }
    );
  } catch (error) {
    console.error('useGroupsScore error', error);
    return Promise.reject(error);
  }
};

export const useQueryParticipants = (
  companyid,
  filters,
  sort,
  page,
  completionAvgConditional
) => {
  try {
    const queryKey = getQueryScoreKey({
      companyid,
      filters,
      sort,
      page,
      completionAvgConditional
    });

    return useQuery(
      [
        QUERY_KEYS.SCORE,
        QUERY_KEYS.QUERY_SCORES,
        ...queryKey.split('&'),
        page.page
      ],
      async () => {
        const params = qs.stringify(
          {
            companyid,
            filters,
            sort,
            page,
            completionAvgConditional
          },
          { skipNulls: true }
        );
        const resp = await api.get(`/score/participants?${params}`);
        if (!resp || !resp.success) {
          return { ...placeholder };
        }
        return { ...resp.data };
      },
      {
        enabled: Boolean(companyid),
        placeholderData: {}
      }
    );
  } catch (error) {
    console.error('useQueryScore error', error);
    return Promise.reject(error);
  }
};

export const notifyParticipationRate = () => useMutation((data) => api
  .post('/score/participation/notify', data)
  .then((resp) => resp)
  .catch((err) => {
    console.error('notify participation rate error:', err);
    return Promise.reject(err);
  }));

export const useQueryParticipationTabsTotal = (companyid, filters) => {
  try {
    const queryKey = getQueryScoreKey({
      companyid,
      filters
    });

    return useQuery(
      [
        QUERY_KEYS.SCORE,
        QUERY_KEYS.QUERY_SCORES,
        ...queryKey.split('&'),
        'participation'
      ],
      async () => {
        const params = qs.stringify(
          {
            companyid,
            filters
          },
          { skipNulls: true }
        );
        const resp = await api.get(`/score/participation?${params}`);
        if (!resp || !resp.success) {
          return { ...placeholder };
        }
        return { ...resp.data };
      },
      {
        enabled: Boolean(companyid),
        placeholderData: {}
      }
    );
  } catch (error) {
    console.error('useQueryParticipationTabsTotal error', error);
    return null;
  }
};
