import React from 'react';
import { get } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import COMMON_CONSTANTS from 'common/commonConstants';
import { appUtils } from 'src/components';
import TopScores from 'src/containers/Dash/TopScores/TopScores';
import UserChart from 'src/containers/Dash/UserChart/UserChart';
import Categories from 'src/containers/Dash/Categories/Categories';
import { useCompany } from 'src/queries/company';
import FilterView from 'src/containers/UserProfile/FilterView/FilterView';
import MobileUserAnalytics from 'src/containers/Dash/UserFeedback/mobile/Analytics';

const { VIEW_SCORES_PERMISSIONS } = COMMON_CONSTANTS;

const UserFeedback = ({ userId }) => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const {
    showMydashboardTopScoresSection,
    showMydashboardCategories,
    showMydashboardPerformanceChart
  } = useFlags();
  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.name && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const companyViewScorePermissions = get(
    company,
    'settings.viewScores',
    VIEW_SCORES_PERMISSIONS.MANAGERS
  );
  const canViewCategories = showMydashboardCategories;
  const canViewChart = companyViewScorePermissions === VIEW_SCORES_PERMISSIONS.ALL
    && showMydashboardPerformanceChart;

  const mobileSize = appUtils.getMobileSize();
  const isMobile = mobileSize;

  return (
    <div>
      {isMobile ? (
        <MobileUserAnalytics
          userId={userId}
          canViewCategories={canViewCategories}
        />
      ) : (
        <>
          <FilterView userId={userId} show={['date']} />
          {showMydashboardTopScoresSection ? <TopScores /> : null}
          {canViewChart && <UserChart />}
          {canViewCategories && <Categories />}
        </>
      )}
    </div>
  );
};

export default UserFeedback;
