import React, { useState } from 'react';
import { Base, toast } from 'src/components';
import STYLE from 'src/constants/style';
import {
  useAccount,
  useAccountTasks,
  QUERY_KEYS as ACCOUNT_QUERY_KEYS
} from 'src/queries/account';
import { useTree } from 'src/queries/tree';
import { useReviewsDue } from 'src/queries/reviews';
import { usePulseReviewsDue } from 'src/queries/pulse';
import { renderTask } from 'src/pages/TaskList/TaskList';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import {
  updateReportQuery,
  QUERY_KEYS as REPORTS_QUERY_KEYS
} from 'src/queries/reports';

const { TASK_TYPES, REPORT_STATUS } = COMMON_CONSTANTS;

const HomeTasks = () => {
  const navigate = useNavigate();

  const [modalTaskIndex, setModalTaskIndex] = useState(null);
  const queryClient = useQueryClient();

  const { mutateAsync: updateReport, isLoading: isUpdatingReport } = updateReportQuery();

  const isLoadingModal = isUpdatingReport;

  const setReportStatusToFinished = async (reportId) => {
    try {
      await updateReport({
        reportId,
        status: REPORT_STATUS.FINISHED
      });
      queryClient.invalidateQueries([REPORTS_QUERY_KEYS.REPORT, reportId]);
      queryClient.invalidateQueries(REPORTS_QUERY_KEYS.REPORTS);
      queryClient.invalidateQueries(ACCOUNT_QUERY_KEYS.TASKS);
      setModalTaskIndex(null);
      toast.show('Report status updated');
    } catch (error) {
      console.error('Failed to update report status', error);
      return toast.error(error ?? 'Failed to update report status');
    }
  };

  const {
    data: viewerAccount,
    isFetching: isFetchingViewerAccount,
    isError: isErrorViewerAccount
  } = useAccount('me');
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: { tasks },
    isFetching: isFetchingTasks,
    isError: isErrorTasks
  } = useAccountTasks('me');
  const {
    reviews: reviewsDue,
    isFetching: isFetchingReviewsDue,
    isError: isErrorReviewsDue
  } = useReviewsDue();
  const {
    pulseReviews,
    isFetching: isFetchingPulseReviewsDue,
    isError: isErrorPulseReviewsDue
  } = usePulseReviewsDue();

  const isFetching = isFetchingTasks
    || isFetchingReviewsDue
    || isFetchingPulseReviewsDue
    || isFetchingViewerAccount
    || isFetchingTree;
  const isError = isErrorTasks
    || isErrorReviewsDue
    || isErrorPulseReviewsDue
    || isErrorViewerAccount
    || isErrorTree;
  const isReady = !isFetching && !isError;
  if (!isReady) return null;

  const tasksToDo = [];

  if (reviewsDue.length > 0) {
    tasksToDo.push({
      type: TASK_TYPES.REVIEW_REQUESTS,
      data: reviewsDue
    });
  }

  if (pulseReviews.length > 0) {
    tasksToDo.push({
      type: TASK_TYPES.OPEN_PULSE,
      data: pulseReviews
    });
  }

  let tasksIndex = 0;
  if (tasks.length) {
    for (let i = tasksToDo.length; i < 3; i += 1) {
      const task = tasks[tasksIndex];
      if (task) {
        tasksToDo.push(task);
        tasksIndex += 1;
      }
    }
  }

  return (
    <Base classes={STYLE.CONTAINER_WHITE}>
      <div className='w-full'>
        <p className='m-0 mt-2 font-semibold text-3xl text-black leading-8'>
          Tasks
        </p>
        <p className='my-2 font-normal text-md text-[#6B7280] leading-8'>
          Displaying your latest tasks
        </p>
      </div>
      {tasksToDo.length ? (
        tasksToDo.map((task, index) => {
          let border = 'bottom';
          if (index === tasksToDo.length - 1) border = '';

          let callback = () => {};
          let modal = {};
          if (task.type === TASK_TYPES.REVIEW_DIRECT_REPORTS) {
            const { _id } = task.data;
            callback = () => navigate(`/dashboard/submit-feedback/feedback?revieweeId=${_id}`);
          }
          if (task.type === TASK_TYPES.REPORT_CREATED) {
            modal = {
              isOpen: modalTaskIndex === index,
              isLoading: isLoadingModal,
              action: setReportStatusToFinished,
              open: () => setModalTaskIndex(index),
              close: () => setModalTaskIndex(null)
            };
          }

          task.key = `task-${index}`;
          return renderTask(
            task,
            {
              border,
              tree,
              viewerAccount,
              navigateTo: navigate
            },
            callback,
            modal
          );
        })
      ) : (
        <p className='my-3 text-xl font-thin text-[#A2A6AF]'>
          You're all caught up! No tasks to complete at the moment.
        </p>
      )}
      <button
        type='button'
        onClick={() => navigate('/dashboard/inbox/tasks')}
        className='text-purple text-lg font-bold mr-auto pt-2 mt-2'
      >
        Go to Tasks
      </button>
    </Base>
  );
};

export default HomeTasks;
