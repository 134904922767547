import uuidv4 from 'uuid/v4';
import { isEmpty } from 'lodash';
import COMMON_QUESTION_CONSTANTS from 'common/commonQuestionConstants';
import commonDateUtils from 'common/commonDateUtils';
import COMMON_CONSTANTS from './commonConstants';

const { USER_STATE, ACCESS, DEFAULT_TIMEZONE } = COMMON_CONSTANTS;
const { STATUS } = COMMON_QUESTION_CONSTANTS;

const utils = {};

const getUnixDateNow = () => Math.floor(new Date() / 1000);

utils.isActive = (node) => parseInt(node.active, 10) === 1;

// v2
// tree = []
utils.addNodeV2 = (tree, id, parentId = null) => {
  if (!parentId) {
    tree.push({ id, children: [] });
    return tree;
  }

  const parentNode = utils.findNodeByIdArray(tree, parentId);
  if (!parentNode) {
    console.error('commonTreeUtils.addNodeV2 failed', {
      treeId: tree.id,
      treeEmail: tree.email,
      id,
      parentId,
      parentNode
    });
    return false;
  }
  parentNode.children.push({ id, children: [] });
  return tree;
};

utils.changeParent = (tree, id, newParentId) => {};

utils.addNode = (tree, newNode) => {
  const managerNode = utils.findNodeById(tree, newNode.managerId);
  managerNode.children.push(newNode);
  return tree;
};

utils.getMyTeam = (tree, node) => {
  try {
    let list = [];
    if (node.managerId) {
      const managerNode = utils.findNodeById(tree, node.managerId);
      list = [managerNode, ...managerNode.children];
    }
    if (node.children) {
      list = [...list, ...node.children];
    }
    list = list.filter((row) => row.id !== node.id);
    return list;
  } catch (error) {
    console.error('commonTreeUtils.getMyTeam', {
      treeId: tree.id,
      treeEmail: tree.email,
      nodeId: node.id,
      nodeEmail: node.email
    });
    throw error;
  }
};

// resets the review rules for node with id 'newNodeId' to the 360 default
utils.setNodeReviewRules = (tree, newNodeId) => {
  const newTree = JSON.parse(JSON.stringify(tree));
  const node = utils.findNodeById(newTree, newNodeId);
  node.reviews = [];
  if (node.managerId) {
    const managerNode = utils.findNodeById(newTree, node.managerId);
    const siblingNodes = managerNode.children.filter(
      (child) => child.id !== node.id
    );
    if (
      !managerNode.reviews.find(
        (relationship) => relationship.userId === node.id
      )
    ) {
      managerNode.reviews.push({ userId: node.id, roles: [...node.roles] });
    }
    if (
      !node.reviews.find(
        (relationship) => relationship.userId === managerNode.id
      )
    ) {
      node.reviews.push({
        userId: managerNode.id,
        roles: [...managerNode.roles]
      });
    }
    siblingNodes.forEach((siblingNode) => {
      if (!siblingNode.reviews) {
        siblingNode.reviews = [];
      }
      if (
        !siblingNode.reviews.find(
          (relationship) => relationship.userId === node.id
        )
      ) {
        siblingNode.reviews.push({ userId: node.id, roles: [...node.roles] });
      }
      if (
        !node.reviews.find(
          (relationship) => relationship.userId === siblingNode.id
        )
      ) {
        node.reviews.push({
          userId: siblingNode.id,
          roles: [...siblingNode.roles]
        });
      }
    });
  }
  if (node.children) {
    node.children.forEach((childNode) => {
      if (!childNode.reviews) {
        childNode.reviews = [];
      }
      if (
        !childNode.reviews.find(
          (relationship) => relationship.userId === node.id
        )
      ) {
        childNode.reviews.push({ userId: node.id, roles: [...node.roles] });
      }

      if (
        !node.reviews.find(
          (relationship) => relationship.userId === childNode.id
        )
      ) {
        node.reviews.push({
          userId: childNode.id,
          roles: [...childNode.roles]
        });
      }
    });
  }
  return newTree;
};

utils.convertTreeToListArray = (treeList, options = { byReference: false }) => {
  const FAKE_ID = 'fake_id_101';
  const treeNode = {
    id: FAKE_ID,
    children: treeList
  };
  const list = utils.convertTreeToList(treeNode, options);

  return list.filter((c) => c.id !== FAKE_ID);
};

utils.convertTreeToList = (treeNode, options = { byReference: false }) => {
  const list = [];
  let queue = [treeNode];
  while (queue.length) {
    const node = queue.shift();
    const row = options.byReference ? node : { ...node };

    if (node.parent) {
      row.managerName = node.parent.name;
      row.managerId = node.parent.id;
      row.managerEmail = node.parent.email;
    }

    list.push(row);
    if (node.children && node.children.length) {
      queue = [...queue, ...node.children];
    }
  }
  return list;
};

utils.getUsersWithRole = (tree, roleId) => {
  const treeList = utils.convertTreeToList(tree);
  return treeList.filter((node) => node.roles.includes(roleId));
};

utils.getCompanyDefaultRoles = (companyQuestions) => {
  try {
    const COMPANY_ROLES = companyQuestions.ROLES;
    const roleKey = Object.keys(COMPANY_ROLES)[0];
    return [COMPANY_ROLES[roleKey].id];
  } catch (error) {
    console.error('commonTreeUtils.getCompanyDefaultRoles error', error);
    return false;
  }
};
utils.getCompanyDefaultActiveRoles = (companyQuestions) => {
  try {
    const COMPANY_ROLES = companyQuestions.ROLES;
    const activeRoles = Object.keys(COMPANY_ROLES)
      .filter((key) => companyQuestions.ROLES[key].status !== STATUS.ARCHIVED)
      .map((k) => COMPANY_ROLES[k])
      .sort((a, b) => a.label.localeCompare(b.label));
    return activeRoles;
  } catch (error) {
    console.error('commonTreeUtils.getCompanyDefaultActiveRoles error', error);
    return false;
  }
};

utils.createTreeNode = ({
  email,
  companyid,
  name,
  firstName,
  lastName,
  imageUrl,
  title,
  managerId,
  managerEmail,
  order,
  userAccess,
  startDate,
  roles,
  id = uuidv4(),
  tz = -300,
  websiteLink
}) => {
  const unixNow = getUnixDateNow();
  const access = userAccess || ACCESS.BASIC;

  return {
    id,
    email,
    companyid,
    name,
    ...((firstName && { firstName }) || {}),
    ...((lastName && { lastName }) || {}),
    imageUrl,
    title,
    managerId,
    managerEmail,
    order,
    children: [],
    lastUpdated: unixNow,
    dateAdded: unixNow,
    access,
    startDate,
    roles,
    active: USER_STATE.ACTIVE,
    tz,
    websiteLink
  };
};

// get emails of all nodes that are under node with email 'nodeEmail'
utils.getEmailsUnderNode = (tree, nodeEmail) => {
  try {
    const node = utils.findNodeByEmail(tree, nodeEmail);
    if (!node) {
      return [];
    }
    const nodeList = utils.convertTreeToList(node);
    return nodeList.map((row) => row.email);
  } catch (error) {
    console.error('getEmailsUnderNode error', error);
    return [];
  }
};

utils.getIdsUnderNode = (tree, nodeId) => {
  try {
    if(!nodeId) return [];
    const node = utils.findNodeById(tree, nodeId);
    if (!node) {
      return [];
    }
    const nodeList = utils.convertTreeToList(node);
    return nodeList.filter((n) => n.id !== nodeId).map((row) => row.id);
  } catch (error) {
    console.error('commonTreeUtils.getIdsUnderNode error', error);
    return [];
  }
};

utils.getNamesUnderNode = (tree, nodeEmail) => {
  try {
    const node = utils.findNodeByEmail(tree, nodeEmail);
    if (!node) {
      return [];
    }
    const nodeList = utils.convertTreeToList(node);
    return nodeList.map((row) => ({ name: row.name, email: row.email }));
  } catch (error) {
    console.error('getNamesUnderNode error', error);
    return [];
  }
};

utils.getManagerNodeUnder = (tree, nodeId) => {
  try {
    const node = utils.findNodeById(tree, nodeId);
    if (!node) {
      return [];
    }
    const nodeList = utils.convertTreeToList(node);
    return nodeList.filter((n) => n.children.length).map((row) => row);
  } catch (error) {
    console.error('commonTreeUtils.getManagerNodeUnder error', error);
    return [];
  }
};

// is node with id 'parentId' above node with id 'childId'
// OR parentId === childId
utils.isNodeAbove = (tree, parentId, childId) => {
  const parentTree = utils.findNodeById(tree, parentId);
  return utils.findNodeById(parentTree, childId);
};

utils.getAboveIds = (managerId, tree) => {
  const idsAboveManager = [];
  idsAboveManager.push(managerId);
  let managerNode = utils.findNodeById(tree, managerId);
  while (managerNode && managerNode.managerId) {
    const aboveNode = managerNode.managerId;
    idsAboveManager.push(aboveNode);
    managerNode = utils.findNodeById(tree, managerNode.managerId);
  }
  return idsAboveManager;
};

utils.isManagerOfNode = (tree, userBottomId, userAboveId) => {
  const userBottomTree = utils.findNodeById(tree, userBottomId);
  if (!userBottomTree || !userBottomTree.managerId) {
    return false;
  }
  if (userBottomTree.managerId === userAboveId) {
    return true;
  }
  return false;
};

utils.isNodeDirectlyAbove = (tree, userBottomId, userAboveId) => {
  if (!userBottomId || !userAboveId) {
    return false;
  }
  const userBottomTree = utils.findNodeById(tree, userBottomId);
  if (!userBottomTree || !userBottomTree.managerId) {
    return false;
  }
  if (userBottomTree.managerId === userAboveId) {
    return true;
  }
  return utils.isNodeDirectlyAbove(tree, userBottomTree.managerId, userAboveId);
};

utils.findNodeByEmail = (node, nodeEmail) => {
  if (!node || !nodeEmail) {
    return null;
  }
  if (node.email.toString() === nodeEmail.toString()) {
    return node;
  }
  let myNode = null;
  if (node.children && node.children.length) {
    node.children.forEach((child) => {
      const foundNode = utils.findNodeByEmail(child, nodeEmail);
      if (foundNode) {
        myNode = foundNode;
        return myNode;
      }
    });
  }
  return myNode;
};

// gets the node and parent node
// list = []
utils.findNodeAndParentByIdArray = (list, nodeId, parentNode = null) => {
  let found = list.find((n) => n.id.toString() === nodeId.toString());
  if (found) {
    return { parent: parentNode, node: found };
  }
  for (let i = 0; i < list.length; i++) {
    const n = list[i];
    found = utils.findNodeAndParentById(n, nodeId, n);
    // console.log('this is what we found:', found);
    if (found) {
      return found;
    }
  }

  return false;
};

utils.findNodeAndParentById = (node, nodeId, parentNode = null) => {
  if (isEmpty(node) || !nodeId) {
    return null;
  }
  if (node.id.toString() === nodeId.toString()) {
    return { parent: parentNode, node };
  }
  let myNode = null;

  if (node.children && node.children.length) {
    node.children.forEach((child) => {
      const foundNode = utils.findNodeAndParentById(child, nodeId, node);
      if (foundNode) {
        myNode = foundNode;
        return foundNode;
      }
    });
  }

  if (!myNode) {
    return false;
  }
  return myNode;
};

utils.findNodeByIdArray = (list, nodeId) => {
  let found = list.find((n) => n.id.toString() === nodeId.toString());
  if (found) {
    return found;
  }
  for (let i = 0; i < list.length; i++) {
    const n = list[i];
    found = utils.findNodeById(n, nodeId);
    if (found) {
      return found;
    }
  }
  return false;
};

utils.findNodeById = (node, nodeId) => {
  try {
    if (isEmpty(node) || !nodeId) {
      return null;
    }
    if (node.id.toString() === nodeId.toString()) {
      return node;
    }
    let myNode = null;

    if (node.children && node.children.length) {
      node.children.forEach((child) => {
        const foundNode = utils.findNodeById(child, nodeId);
        if (foundNode) {
          myNode = foundNode;
          return myNode;
        }
      });
    }
    return myNode;
  } catch (error) {
    console.error('commonTreeUtils.findNodeById error', error, {
      node,
      nodeId
    });
  }
};

utils.addPropertyToEntireTree = (node, property) => {
  if (isEmpty(node)) {
    return null;
  }
  const prop = Object.keys(property);
  const [value] = Object.values(property);
  node[prop] = value;
  if (node.children && node.children.length) {
    node.children.forEach((child) => {
      const updatedChildren = utils.addPropertyToEntireTree(child, property);
    });
  }
  return node;
};

utils.deleteNodeAndBelowArray = (treeList, nodeId) => {
  const { parent } = utils.findNodeAndParentByIdArray(treeList, nodeId);
  if (!parent) {
    return treeList.filter((c) => c.id !== nodeId);
  }
  parent.children = parent.children.filter((c) => c.id !== nodeId);
  return treeList;
};

// delete node and move children nodes to the parent
utils.deleteNodeTreeArray = (treeList, nodeId) => {
  const { node, parent } = utils.findNodeAndParentByIdArray(treeList, nodeId);

  if (!parent) {
    node.children.forEach((c) => treeList.push(c));
    treeList = treeList.filter((n) => n.id !== nodeId);

    return treeList;
  }

  node.children.forEach((c) => parent.children.push(c));
  parent.children = parent.children.filter((c) => c.id !== nodeId);
  return treeList;
};

utils.moveNodeTreeArray = (treeList, nodeId, newParentId) => {
  const { node, parent } = utils.findNodeAndParentByIdArray(treeList, nodeId);
  let newParentNode;
  if (!newParentId) {
    newParentNode = treeList;
    treeList.push(node);
  } else {
    ({ node: newParentNode } = utils.findNodeAndParentByIdArray(
      treeList,
      newParentId
    ));
    newParentNode.children.push(node);
  }

  if (parent) {
    parent.children = parent.children.filter((n) => n.id !== nodeId);
  } else {
    treeList = treeList.filter((n) => n.id !== nodeId);
  }

  return treeList;
};

utils.getUsersWithEmployees = (treeList) => treeList.filter((treeRow) => treeRow.children.length);

utils.isAdmin = (treeRow) => treeRow.access === ACCESS.ADMIN;

utils.getUserGroupsForUser = (tree, userId) => {
  const userTree = utils.findNodeById(tree, userId);
  if (!userTree) {
    return null;
  }
  return utils.getUserGroups(tree, userTree);
};

// returns ids for all user groups of tree node
utils.getUserGroups = (tree, userTree) => {
  if (!tree || !userTree) {
    return {
      managers: [], // all managers directly above user
      manager: null,
      coworkers: [],
      employees: [],
      allUsersAbove: []
    };
  }
  const managers = []; // managers directly above user
  const manager = [];
  let coworkers = [];
  const employees = [];
  let allUsersAbove = [];

  userTree.children.forEach((childTree) => {
    employees.push(childTree.id);
  });

  if (userTree.managerId) {
    const managerTree = utils.findNodeById(tree, userTree.managerId);
    manager.push(managerTree.id);
    managers.push(managerTree.id);
    coworkers = managerTree.children
      .filter((childTree) => childTree.id !== userTree.id)
      .map((childTree) => childTree.id);

    allUsersAbove.push(managerTree.id);
    let managerNodeTemp = { ...managerTree };
    while (managerNodeTemp.managerId) {
      const upperManagerTree = utils.findNodeById(
        tree,
        managerNodeTemp.managerId
      );
      managers.push(managerNodeTemp.id);

      const excluded = upperManagerTree.children.filter(
        (childTree) => childTree.id !== managerNodeTemp.id
      );
      allUsersAbove = [
        ...allUsersAbove,
        ...excluded.map((childTree) => childTree.id)
      ];

      managerNodeTemp = upperManagerTree;
    }
    // if (managerNodeTemp.managerId) {
    allUsersAbove.push(managerNodeTemp.id);
    // }
  }

  return {
    allUsersAbove,
    managers,
    manager,
    coworkers,
    employees
  };
};

utils.getSiblingIds = (tree, nodeId) => {
  const { parent } = utils.findNodeAndParentById(tree, nodeId);
  if (!parent) {
    return tree.filter((n) => n.id !== nodeId).map((n) => n.id);
  }
  return parent.children.filter((n) => n.id !== nodeId).map((n) => n.id);
};

utils.trimNodeAttributes = (node, attributes = ['name', 'title', 'email']) => {
  attributes.forEach((attribute) => {
    if (node[attribute]) node[attribute] = node[attribute].trim();
  });
};

utils.archiveNode = (tree, deleted, nodeId) => {
  const newTree = { ...tree };
  const archivedNode = utils.findNodeById(newTree, nodeId);
  const managerNode = utils.findNodeById(newTree, archivedNode.managerId);
  const newManagerChildren = managerNode.children.filter(
    (child) => child.id !== nodeId
  );
  managerNode.children = newManagerChildren;

  archivedNode.active = USER_STATE.UNASSIGNED;
  archivedNode.lastUpdated = commonDateUtils.getUnixDateNow();
  archivedNode.roles = [];
  delete archivedNode.managerId;
  delete archivedNode.managerEmail;
  // these are not in nodes anymore
  delete archivedNode.groups;
  delete archivedNode.reviews;
  const newDeleted = [...deleted, archivedNode];
  return {
    newTree,
    newDeleted
  };
};

utils.unarchiveNode = (tree, deleted, nodeId, { managerId, active, roles }) => {
  const newTree = { ...tree };
  const unarchivedNode = deleted.find((n) => n.id === nodeId);
  const managerNode = utils.findNodeById(newTree, managerId);
  unarchivedNode.managerId = managerId;
  unarchivedNode.managerEmail = managerNode.email;
  unarchivedNode.active = active;
  unarchivedNode.roles = roles;
  unarchivedNode.access = ACCESS.BASIC;
  unarchivedNode.lastUpdated = commonDateUtils.getUnixDateNow();
  const newManagerChildren = managerNode.children.concat(unarchivedNode);
  managerNode.children = newManagerChildren;
  const newDeleted = deleted.filter((n) => n.id !== nodeId);
  return {
    newTree,
    newDeleted
  };
};

utils.getIdsAround = (tree, nodeId) => {
  try {
    const { node, parent } = utils.findNodeAndParentById(tree, nodeId);

    if (!node) throw 'Node not found!';

    const parentId = parent ? parent.id : null;
    const siblingIds = parent
      ? parent.children.filter((n) => n.id !== nodeId).map((n) => n.id)
      : [];
    const childIds = node.children.map((n) => n.id);

    return {
      parentId,
      siblingIds,
      childIds
    };
  } catch (error) {
    console.error('getIdsAround', error, { nodeId, tree });
  }
};

export default utils;
