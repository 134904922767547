/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { isEqual, get } from 'lodash';
import { useQueryClient } from 'react-query';
import {
  Base, Button, toast, Select
} from 'src/components';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useCompany, updateCompanyQuery } from 'src/queries/company';
import { QUERY_KEYS } from 'src/queries/score';
import InformationCircleSVG from 'src/assets/svg/informationCircle.svg';
import STYLE from 'src/constants/style';

const ScoreLabels = () => {
  const queryClient = useQueryClient();

  const {
    data: company,
    refetch: refetchCompany,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError;
  const { mutateAsync: updateCompany, isLoading: isUpdateCompanyLoading } = updateCompanyQuery();

  const {
    control,
    register,
    watch,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const {
    fields, append, remove, replace
  } = useFieldArray({
    control,
    name: 'scoreLabels'
  });

  useEffect(() => {
    if (!isReady) return;
    const companyScoreLabels = get(company, 'settings.scoreLabels');
    if (!companyScoreLabels) return;
    replace(companyScoreLabels);
  }, [isReady]);

  if (!isReady) {
    return null;
  }

  const numValues = [...Array(101).keys()].map((i) => ({ id: i, label: i })); // numbers from 0 to 100

  const scoreLabels = watch('scoreLabels');

  const handleValuesChange = (index, field, value) => {
    const currentScoreLabelsValues = getValues('scoreLabels');

    const previousIndex = index - 1;
    const nextIndex = index + 1;

    const previousScoreLabel = currentScoreLabelsValues[previousIndex];
    const nextScoreLabel = currentScoreLabelsValues[nextIndex];

    if (field === 'max' && nextScoreLabel) {
      const updatedNextScoreMinValue = parseInt(value, 10) + 1;
      setValue(`scoreLabels.${nextIndex}.min`, updatedNextScoreMinValue);
    }

    if (field === 'min' && previousScoreLabel) {
      const updatedPreviousScoreMaxValue = parseInt(value, 10) - 1;
      setValue(
        `scoreLabels.${previousIndex}.max`,
        updatedPreviousScoreMaxValue
      );
    }
  };

  const addField = () => {
    const currentValues = getValues('scoreLabels');

    const [lastScore] = currentValues.slice(-1);
    const lastScoreMaxValue = parseInt(lastScore.max, 10);
    const newLabelMinimumScore = lastScoreMaxValue + 1;

    if (lastScoreMaxValue === 100) {
      return toast.error('Max score reached. Reduce the last label max.');
    }

    return append({
      min: newLabelMinimumScore,
      max: 100,
      label: ''
    });
  };

  const removeField = (index) => {
    remove(index);
    if (index + 1 === scoreLabels.length) {
      setValue(`scoreLabels.${index - 1}.max`, 100);
    }
  };

  const save = async (data) => {
    toast.show('Updating score labels...');

    const { scoreLabels } = data;
    if (!scoreLabels || scoreLabels.length < 2) return;

    const parsedScoreLabels = scoreLabels.map((sl) => ({
      ...sl,
      min: parseInt(sl.min, 10),
      max: parseInt(sl.max, 10)
    }));

    const res = await updateCompany({
      settings: {
        ...company.settings,
        scoreLabels: parsedScoreLabels
      }
    });

    queryClient.invalidateQueries(QUERY_KEYS.SCORE);
    queryClient.invalidateQueries(QUERY_KEYS.USER_SCORES);

    if (!res.success) {
      toast.error(res.message);
      return;
    }

    toast.show('Saved!');
    refetchCompany();
  };

  const companyScoreLabels = get(company, 'settings.scoreLabels');

  const isSaveDisabled = isFetchingCompany
    || isUpdateCompanyLoading
    || isEqual(companyScoreLabels, scoreLabels)
    || !scoreLabels
    || scoreLabels.length < 2;

  const scoreLabelsErrors = errors.scoreLabels;
  const icons = [
    '💢',
    '👎',
    '😧',
    '😬',
    '🙁',
    '😕',
    '😐',
    '🙂',
    '😀',
    '😁',
    '🤩',
    '😍',
    '🤗',
    '🥳',
    '👍',
    '🚀',
    '💯',
    '🔥'
  ];

  return (
    <Base
      classes={STYLE.CONTAINER_WHITE}
      loading={isFetchingCompany || isUpdateCompanyLoading}
    >
      <h5 className='mb-4'>Category Score Labels</h5>
      <p className='mb-4'>
        Provide context for performance levels on dashboards.
      </p>
      <div className='mb-4'>
        {fields.map((field, index) => (
          <div key={index}>
            <div className='flex gap-2 mb-4'>
              <label className='flex flex-wrap flex-col'>
                <span>Min</span>
                <Controller
                  name={`scoreLabels.${index}.min`}
                  defaultValue={numValues[0]}
                  control={control}
                  rules={{
                    required: true,
                    min: {
                      value: index === 0 ? 0 : null,
                      message: 'Min score must be 0'
                    }
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      classes='w-32'
                      options={
                        index > 0
                          ? numValues.filter(
                            (n) => n.id <= scoreLabels[index].max
                                && n.id >= scoreLabels[index - 1].max + 1
                          )
                          : [numValues[0]]
                      }
                      disabled={index === 0}
                      title={value}
                      onChange={(option) => {
                        onChange(option.id);
                        handleValuesChange(index, 'min', option.id);
                      }}
                    />
                  )}
                />
              </label>

              <label className='flex flex-wrap flex-col'>
                <span>Max</span>
                <Controller
                  name={`scoreLabels.${index}.max`}
                  defaultValue={0}
                  control={control}
                  rules={{
                    required: true,
                    min: {
                      value: index === scoreLabels.length - 1 ? 100 : null,
                      message: 'Max score must be 100'
                    }
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      classes='w-32'
                      options={
                        index < scoreLabels.length - 1
                          ? numValues.filter(
                            (n) => n.id >= scoreLabels[index].min
                                && n.id <= scoreLabels[index + 1].max - 1
                          )
                          : numValues.filter(
                            (n) => n.id >= scoreLabels[index].min
                          )
                      }
                      title={value}
                      onChange={(option) => {
                        onChange(option.id);
                        handleValuesChange(index, 'max', option.id);
                      }}
                    />
                  )}
                />
              </label>

              <label className='flex flex-wrap flex-col'>
                <span>Label</span>
                <input
                  className='w-80 bg-white text-black border border-light-gray rounded'
                  key={`${field.id}-label`}
                  {...register(`scoreLabels.${index}.label`, {
                    required: 'Label is required'
                  })}
                  type='text'
                />
              </label>

              <div className='flex flex-wrap flex-col'>
                <span>Icon</span>
                <Controller
                  name={`scoreLabels.${index}.icon`}
                  defaultValue=''
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      classes='w-32'
                      options={icons.map((i) => ({ id: i, label: i }))}
                      title={value}
                      onChange={(option) => onChange(option.id)}
                    />
                  )}
                />
              </div>

              {index > 1 && (
                <div className='flex'>
                  <button
                    type='button'
                    className='m-auto ml-0 mt-8 hover:underline focus:outline-none w-1/5 text-left'
                    onClick={() => removeField(index)}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>
            {scoreLabelsErrors
              && scoreLabelsErrors[index]
              && Object.keys(scoreLabelsErrors[index]).length > 0 && (
                <span className='text-red'>
                  {
                    scoreLabelsErrors[index][
                      Object.keys(scoreLabelsErrors[index])[0]
                    ].message
                  }
                </span>
            )}
          </div>
        ))}
      </div>

      <div className='mb-4 block'>
        <Button variant='empty' disabled={false} onClick={addField}>
          + Add label
        </Button>
      </div>

      <div className='mb-1 block'>
        <Button
          variant='yellow'
          disabled={isSaveDisabled}
          onClick={handleSubmit(save)}
        >
          Update
        </Button>
      </div>
    </Base>
  );
};

export default ScoreLabels;
