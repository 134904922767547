import React from 'react';
import { appUtils } from 'src/components/index';
import { v4 as uuidv4 } from 'uuid';
import SlackIconSmallSVG from 'src/assets/svg/slack-icon-small.svg';

const ConnectToSlack = ({ redirectUri, team, classes }) => {
  // const STATE = appUtils.encodeURIString(userEmail);
  const STATE = uuidv4();
  // TEMPORARY REDIRECT UNTIL SLACK APP APPROVES app.workstory.team as redirect url
  // if (get(window, 'location.host') === 'app.workstory.team') {
  //   return window.location.replace('https://workstory.team/dashboard/settings');
  // }
  const redir = `${process.env.SLACK_REDIRECT_URL}${redirectUri}`;

  let AUTHORIZE_SLACK_FOR_USER = `https://slack.com/oauth/v2/authorize?scope=app_mentions:read,chat:write,commands,im:history&client_id=${process.env.SLACK_CLIENT_ID}&redirect_uri=${redir}&state=${STATE}`;
  if (team) {
    AUTHORIZE_SLACK_FOR_USER += `&team=${team}`;
  }
  return (
    <button
      className={`rounded-md w-fit h-12 border order border-black transition-colors duration-300 hover:text-dark-grey px-4 py-2 ${classes}`}
      onClick={() => {
        appUtils.setSlackState(STATE);
        window.location.href = AUTHORIZE_SLACK_FOR_USER;
      }}
    >
      <div className='w-full h-full flex items-center justify-center gap-3'>
        <SlackIconSmallSVG />
        <p className='m-0 whitespace-nowrap text-black'>
          Add to
          {' '}
          <strong>Slack</strong>
        </p>
      </div>
    </button>
  );
};

export default ConnectToSlack;
