import React, { useEffect } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { isEqual, get } from 'lodash';
import {
  Base,
  Button,
  Checkbox,
  toast,
  Select,
  Placeholder
} from 'src/components';
import { useCompany, updateCompanyQuery } from 'src/queries/company';
import COMMON_CONSTANTS from 'common/commonConstants';
import STYLE from 'src/constants/style';

const { renderShareCommentsVisibilityOptions } = COMMON_CONSTANTS;

const isSaveDisabled = (
  company,
  reviewVisibilityOptions,
  reviewVisibilityDefaultOption,
  isCompanyFetching,
  isUpdateCompanyLoading
) => {
  const initialVisibilityOptions = get(
    company,
    'settings.reviewVisibilityOptions'
  );
  const initialVisibilityDefaultOption = get(
    company,
    'settings.reviewVisibilityDefaultOption'
  );

  const areVisibilityOptionsUnchanged = !reviewVisibilityOptions
    || isEqual(initialVisibilityOptions, reviewVisibilityOptions);
  const isDefaultOptionUnchanged = !reviewVisibilityDefaultOption
    || isEqual(initialVisibilityDefaultOption, reviewVisibilityDefaultOption);

  const noVisibilityOptionSelected = !reviewVisibilityOptions
    || !Object.values(reviewVisibilityOptions).filter((i) => i === true).length;

  if (
    isCompanyFetching
    || isUpdateCompanyLoading
    || noVisibilityOptionSelected
  ) {
    return true;
  }

  if (areVisibilityOptionsUnchanged && isDefaultOptionUnchanged) {
    return true;
  }

  return false;
};

const ReviewVisibilityRules = () => {
  const valuesShareReview = renderShareCommentsVisibilityOptions({});
  const allDefaultVisibilityOptions = valuesShareReview.map((v) => ({
    id: v.value,
    label: v.title
  }));

  const {
    data: company,
    refetch: refetchCompany,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const { mutateAsync: updateCompany, isLoading: isUpdateCompanyLoading } = updateCompanyQuery();
  const {
    handleSubmit, control, setValue, reset, getValues
  } = useForm({});
  const defaultVisibilitySelectOptions = useWatch({
    control,
    name: 'defaultVisibilitySelectOptions'
  });
  const reviewVisibilityOptions = useWatch({
    control,
    name: 'reviewVisibilityOptions'
  });
  const reviewVisibilityDefaultOption = useWatch({
    control,
    name: 'reviewVisibilityDefaultOption'
  });

  const updateDefaultOptions = (visibilityOptions) => {
    if (!visibilityOptions) return;

    const disabledVisibilityOptions = Object.entries(visibilityOptions)
      .filter(([_key, value]) => !value)
      .map((i) => i[0]);

    const updatedDefaultVisibilityOptions = allDefaultVisibilityOptions.filter(
      (item) => !disabledVisibilityOptions.includes(item.id)
    );

    setValue('defaultVisibilitySelectOptions', updatedDefaultVisibilityOptions);

    const defaultOptionCurrentValue = getValues(
      'reviewVisibilityDefaultOption'
    );

    if (updatedDefaultVisibilityOptions.length === 0) {
      setValue('reviewVisibilityDefaultOption', null);
      return;
    }

    if (
      disabledVisibilityOptions.includes(defaultOptionCurrentValue)
      || updatedDefaultVisibilityOptions.length === 1
    ) {
      setValue(
        'reviewVisibilityDefaultOption',
        updatedDefaultVisibilityOptions[0].id
      );
    }
  };

  useEffect(() => {
    const options = get(company, 'settings.reviewVisibilityOptions');
    const defaultOption = get(
      company,
      'settings.reviewVisibilityDefaultOption'
    );

    if (options && defaultOption) {
      reset({
        defaultVisibilitySelectOptions: allDefaultVisibilityOptions,
        reviewVisibilityDefaultOption: defaultOption,
        reviewVisibilityOptions: options
      });
    }
  }, [
    company?.settings?.reviewVisibilityOptions,
    company?.settings?.reviewVisibilityDefaultOption
  ]);

  useEffect(
    () => updateDefaultOptions(reviewVisibilityOptions),
    [reviewVisibilityOptions]
  );

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError && !isUpdateCompanyLoading;

  if (!isReady) {
    return <Placeholder />;
  }

  const getReviewVisibilityLabel = (id) => (id
    ? allDefaultVisibilityOptions.find((i) => i.id === id).label
    : 'You need to check at least one option');

  const save = async (data) => {
    toast.show('Updating review visibility options...');

    const res = await updateCompany({
      settings: {
        ...company.settings,
        reviewVisibilityOptions: data.reviewVisibilityOptions,
        reviewVisibilityDefaultOption: data.reviewVisibilityDefaultOption
      }
    });

    if (!res.success) {
      toast.error(res.message);
      return;
    }

    toast.show('Saved!');
    refetchCompany();
  };

  const isUpdateButtonDisabled = isSaveDisabled(
    company,
    reviewVisibilityOptions,
    reviewVisibilityDefaultOption,
    isFetching,
    isUpdateCompanyLoading
  );

  return (
    <Base classes={STYLE.CONTAINER_WHITE}>
      <div>
        <h5 className='mb-1'>Review Visibility</h5>
        <p className='mb-4'>
          Determines visibility options available when reviewing.
        </p>
        {valuesShareReview.map((opt, index) => (
          <div key={index} className='mb-4'>
            <Controller
              name={`reviewVisibilityOptions.${opt.value}`}
              defaultValue={company.settings.reviewVisibilityOptions[opt.value]}
              control={control}
              rules={{ required: false }}
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  id={opt.value}
                  label={(
                    <div>
                      <div className='mb-2 font-bold'>{opt.title}</div>
                      <div>{opt.comments}</div>
                    </div>
                  )}
                  onChange={onChange}
                  value={value}
                  key={value}
                />
              )}
            />
          </div>
        ))}

        <div className='mt-6'>
          <p className='inline-block mt-1'>Default option</p>
          <div className='mb-2 w-32 ml-3 align-top inline-block'>
            <Controller
              name='reviewVisibilityDefaultOption'
              defaultValue={company.settings.reviewVisibilityDefaultOption}
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Select
                  classes='w-28rem'
                  options={defaultVisibilitySelectOptions}
                  title={getReviewVisibilityLabel(value)}
                  onChange={(option) => onChange(option.id)}
                />
              )}
            />
          </div>
        </div>

        <div className='mb-1 block'>
          <Button
            variant='yellow'
            disabled={isUpdateButtonDisabled}
            onClick={handleSubmit(save)}
          >
            Update
          </Button>
        </div>
      </div>
    </Base>
  );
};

export default ReviewVisibilityRules;
