import React from 'react';
import {
  Button, Base, toast, Multiselect, Select
} from 'src/components';
import COMMON_CONSTANTS from 'common/commonConstants';
import { requestFeedback } from 'src/queries/feedback';
import { useRequestFeedback } from 'src/containers/PerformanceCategoryModal/Tabs/requestFeedbackQueries';

const { USER_STATE } = COMMON_CONSTANTS;

const RequestFeedback = ({ close, categoryName, revieweeId }) => {
  const {
    state,
    setState,
    reviewerOptions,
    setReviewerNode,
    revieweeNode,
    reviewerNode,
    isFetching: isFetchingRequestFeedback,
    isError: isErrorRequestFeedback
  } = useRequestFeedback({ categoryName, revieweeId });
  const { mutateAsync: requestFeedbackFn, isLoading: requestFeedbackLoading } = requestFeedback();

  const isFetching = isFetchingRequestFeedback;
  const isError = isErrorRequestFeedback;
  const isReady = reviewerNode
    && reviewerNode.id
    && revieweeNode
    && revieweeNode.id
    && !isFetching
    && !isError;

  if (!isReady) {
    return null;
  }

  const sendRequest = async () => {
    toast.show('Requesting feedback');
    await requestFeedbackFn({
      reviewerId: reviewerNode.id,
      revieweeId: revieweeNode.id,
      text: state.requestText
    });
    toast.show('Feedback request sent!');
    close();
  };

  if (![USER_STATE.ACTIVE, USER_STATE.INACTIVE].includes(revieweeNode.active)) {
    let text = '';
    if (revieweeNode.active === USER_STATE.PASSIVE) {
      text = `${revieweeNode.name} is a passive employee and cannot be reviewed by others.`;
    }
    if (revieweeNode.active === USER_STATE.UNASSIGNED) {
      text = `${revieweeNode.name} (Unassigned) is not available to be reviewed by others.`;
    }
    return (
      <div className='text-left block mx-4'>
        <Base variant='transparent' loading={requestFeedbackLoading}>
          <div className='inline-block mb-8'>
            <p className='mb-2 font-bold'>Request Feedback</p>
            <p className='mb-0'>{text}</p>
          </div>
        </Base>
      </div>
    );
  }

  return (
    <div className='text-left block mx-4'>
      <Base variant='transparent' loading={requestFeedbackLoading}>
        <div className='inline-block mb-8'>
          <p className='mb-2 font-bold'>Request Feedback</p>
          <p className='mb-2 inline-block'>{state.title}</p>
          <div className='ml-2 w-80 inline-block -mt-3'>
            <Multiselect
              placeholder='Select Member'
              multiselect={false}
              options={reviewerOptions}
              onChange={(n) => {
                setReviewerNode(n);
              }}
              other={{
                variant: 'sm',
                size: 5,
                topLabel: 'Search member'
              }}
            />
          </div>
        </div>

        <textarea
          onChange={(e) => {
            setState({ ...state, requestText: e.target.value });
          }}
          className='block w-full h-16 mb-3 resize-none'
          value={state.requestText}
        />
        <div>
          <Button
            disabled={state.requestText && state.requestText.length < 25}
            variant='yellow'
            onClick={sendRequest}
          >
            Request Feedback
          </Button>
        </div>
      </Base>
    </div>
  );
};

export default RequestFeedback;
