import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { PulseQuestionResultsContext } from 'src/pagesDashboard/PulseQuestionResults/context/PulseQuestionResultsProvider';
import pulseActions from 'src/queries/actions/pulse';
import { usePulseData } from 'src/queries/Pulse/pulseData';
import { useTree } from 'src/queries/tree';
import { Base } from 'src/components';
import commonTreeUtils from 'common/commonTreeUtils';
import commonDateUtils from 'common/commonDateUtils';
import PULSE_CONSTANTS from 'common/pulseConstants';
import STYLE from 'src/constants/style';
import FreeTextResponses from './FreeTextResponses';

const Loading = () => (
  <Base classes={STYLE.CONTAINER_WHITE} loading>
    <h5 className='font-bold text-xl'>Responses</h5>
  </Base>
);

const Responses = () => {
  const { questionId } = useParams();

  const { context } = useContext(PulseQuestionResultsContext);
  const {
    range: { value: range, start, end }
  } = context;
  const pulseAction = pulseActions.getQuestionData({
    questionId,
    range,
    start,
    end,
    options: {
      sort: { field: 'reviewedAt', order: 'desc' }
    }
  });
  const {
    data: pulseData,
    isFetching: isFetchingPulseData,
    isError: isErrorPulseData
  } = usePulseData(pulseAction);
  const {
    data: { tree, deleted },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const isFetching = isFetchingPulseData || isFetchingTree;
  const isError = isErrorPulseData || isErrorTree;
  const isReady = tree && tree.id && pulseData && !isFetching && !isError;

  if (!isReady) {
    return <Loading />;
  }
  const { pulseQuestion } = pulseData;

  if (pulseQuestion.type === PULSE_CONSTANTS.TYPES.FREE) {
    return <FreeTextResponses pulseData={pulseData} tree={tree} />;
  }

  return (
    <Base classes={STYLE.CONTAINER_WHITE}>
      <h5 className='font-bold text-xl mb-4'>Responses</h5>

      <div className='mb-2 py-1 px-1 bg-background-gray text-black font-bold rounded-sm'>
        <p className='mb-0 pl-1 w-2/12 inline-block font-bold'>Reviewed</p>
        <p className='mb-0 w-2/12 inline-block font-bold'>Employee</p>
        <p className='mb-0 w-2/12 inline-block font-bold'>Title</p>
        <p className='mb-0 w-2/12 inline-block font-bold'>Answer</p>
        <p className='mb-0 w-3/12 inline-block font-bold'>Comments</p>
        <p className='mb-0 w-1/12 inline-block' />
      </div>

      {!pulseData.reviews.length ? (
        <p className='text-center mt-10 italic'>No responses yet</p>
      ) : null}
      {pulseData.reviews.map((pulseReview) => {
        const { reviewedAt } = pulseReview;
        let reviewerTree = commonTreeUtils.findNodeById(
          tree,
          pulseReview.reviewerId
        );
        if (!reviewerTree && pulseReview) {
          reviewerTree = deleted.find((d) => d.id === pulseReview.reviewerId);
        }
        let reviewerName = reviewerTree ? (
          reviewerTree.name
        ) : (
          <p className='italic'>Other</p>
        );
        let reviewerTitle = reviewerTree ? (
          reviewerTree.title
        ) : (
          <p className='italic'>Other</p>
        );
        if (pulseReview.anonymous) {
          reviewerName = 'Anonymous';
          reviewerTitle = 'Anonymous';
        }
        const comments = pulseReview.comments && pulseReview.comments !== ''
          ? pulseReview.comments
          : '-';
        const reviewedDate = commonDateUtils.unixToMonthDayYearFormat(reviewedAt);

        return (
          <div className='pl-1' key={pulseReview.id}>
            <p className='w-2/12 inline-block'>{reviewedDate}</p>
            <p className='w-2/12 inline-block'>{reviewerName}</p>
            <p className='w-2/12 inline-block'>{reviewerTitle}</p>
            <p className='w-2/12 inline-block'>{pulseReview.answer}</p>
            <p className='w-3/12 inline-block'>{comments}</p>
            {/*
                <button
                  className="hover:underline focus:outline-none w-1/12"
                  onClick={() => { }}
                >
                  View
                </button>
              */}
          </div>
        );
      })}
    </Base>
  );
};

export default Responses;
