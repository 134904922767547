import React, { forwardRef } from 'react';
import './BuilderContainer.scss';

const BuilderContainer = forwardRef(
  ({
    loading, children, id, classes
  }, ref) => (
    <div className={classes || 'mb-5'} {...(loading ? {} : { ref, id })}>
      {loading ? (
        <div className='sectionbox-loading-content'>
          <div>{children}</div>
        </div>
      ) : (
        children
      )}
    </div>
  )
);

export default BuilderContainer;
