import React, { useContext } from 'react';
import {
  isNil, isEmpty, partition, get, uniqBy
} from 'lodash';
import { PerformanceBuilderContext } from 'src/pages/PerformanceBuilder/PerformanceBuilderContext';
import PreviewCategory from 'src/pages/PerformanceBuilder/PreviewComponents/PreviewCategory';
import PreviewQuestion from 'src/pages/PerformanceBuilder/PreviewComponents/PreviewQuestion';

const PreviewReviews = ({ mode = null, data = {} }) => {
  const {
    data: { dataset: contextDataset, reviewMode: contextReviewMode }
  } = useContext(PerformanceBuilderContext);

  let dataset = [];
  let reviewMode = null;
  let industryIds = [];
  let roleIds = [];
  let reviews = [];

  if (mode === 'form') {
    const { form } = data;
    const { watch } = form;
    const { industry, role, reviews: formReviews } = watch();
    dataset = contextDataset;
    reviewMode = contextReviewMode;
    industryIds = [industry.id];
    roleIds = [role.id];
    reviews = formReviews;

    if (role.id === '70196e3b-6e41-4521-883b-70823b9a6e14') {
      const sortedReviews = [
        reviews.find(
          (r) => r.categoryId === 'f08359c3-212d-4bb5-8af9-ed2ecf313221'
        ),
        reviews.find(
          (r) => r.categoryId === '3aca867d-8166-445e-b3ce-f0a372b72516'
        ),
        reviews.find(
          (r) => r.categoryId === '259918ce-1c62-4f8c-978e-10c3d63d1365'
        ),
        reviews.find(
          (r) => r.categoryId === '53459930-2e94-45ad-8fa3-fd0e226a9adb'
        ),
        reviews.find(
          (r) => r.categoryId === '8d5357c6-c6a5-4291-8548-8c02836f4979'
        ),
        reviews.find(
          (r) => r.categoryId === 'a64e8c7f-4892-42cd-adb3-70277f3b71ef'
        ),
        reviews.find(
          (r) => r.categoryId === '5b7010da-61fc-4410-9885-816704721168'
        ),
        reviews.find(
          (r) => r.categoryId === '672dba39-dbec-4a03-b971-64df3bdd15ff'
        ),
        reviews.find(
          (r) => r.categoryId === '304ef8b3-fdfb-44c0-ad55-0bf61cbdcb41'
        ),
        reviews.find(
          (r) => r.categoryId === '9d597349-61f4-4c9e-b250-17d79187625d'
        ),
        reviews.find(
          (r) => r.categoryId === '83984f34-6f75-4549-a608-aac09b7fcb7b'
        ),
        reviews.find(
          (r) => r.categoryId === '62cc83b5-aa3f-478b-8df1-6b0176d0c4fa'
        ),
        reviews.find(
          (r) => r.categoryId === 'a8e116d4-733a-447d-a37a-508f57ef6c1b'
        )
      ];
      reviews = sortedReviews.filter((category) => !isNil(category));
    }
  }

  if (mode === 'data') {
    const {
      dataset: dataDataset,
      industryIds: dataIndustryIds,
      roleIds: dataRoleIds,
      reviews: dataReviews
    } = data;
    dataset = dataDataset;
    industryIds = dataIndustryIds;
    roleIds = dataRoleIds;
    reviews = dataReviews;
  }

  if (isEmpty(industryIds) || isEmpty(roleIds)) return null;

  const displayedReviews = reviews.filter(
    (review) => !isEmpty(review.comments) || !isEmpty(get(review, 'answer.label'))
  );
  if (isEmpty(displayedReviews)) return null;

  let roles = [];
  let categoriesInRoles = [];

  industryIds.forEach((industryId) => {
    const industryData = dataset.find((d) => d.industryId === industryId);
    const { roles: industryRoles = [] } = industryData;
    roles.push(...industryRoles.filter((r) => roleIds.includes(r.roleId)));
    categoriesInRoles.push(
      ...roles.reduce((acc, role) => [...acc, ...role.categories], [])
    );
  });

  roles = uniqBy(roles, 'roleId');
  categoriesInRoles = uniqBy(categoriesInRoles, 'categoryId');

  const [displayedQuestionReviews, displayedCategoryReviews] = partition(
    displayedReviews,
    (review) => Boolean(review.questionId)
  );

  return (
    <>
      {(!reviewMode || reviewMode === 'questions')
      && !isEmpty(displayedQuestionReviews) ? (
        <>
          {categoriesInRoles.map((category, index) => {
            const categoryReviews = displayedQuestionReviews.filter(
              (review) => review.categoryId === category.categoryId
            );
            if (isEmpty(categoryReviews)) return null;

            const { name, questions } = category;
            return (
              <div key={index}>
                <h3 className='text-lg font-bold text-purple'>{name}</h3>
                {questions.map((question, innerIndex) => {
                  const questionReviews = categoryReviews.filter(
                    (r) => r.questionId === question.questionId
                  );

                  const hasAnswers = questionReviews.some(
                    (r) => !isEmpty(get(r, 'answer.label'))
                  );
                  if (!hasAnswers) return null;

                  return (
                    <div key={`${index}-${innerIndex}`}>
                      <PreviewQuestion
                        reviews={questionReviews}
                        question={question}
                      />
                    </div>
                  );
                })}
                <div className='my-4 border-b rounded border-[#E2E8F0]' />
              </div>
            );
          })}
        </>
        ) : null}
      {(!reviewMode || reviewMode === 'categories')
      && !isEmpty(displayedCategoryReviews) ? (
        <>
          <h2>Performance Categories</h2>
          {categoriesInRoles.map((category, index) => {
            const categoryReviews = displayedCategoryReviews.filter(
              (r) => r.categoryId === category.categoryId
            );
            if (isEmpty(categoryReviews)) return null;

            return (
              <div key={index}>
                <PreviewCategory
                  reviews={categoryReviews}
                  category={category}
                />
              </div>
            );
          })}
          <div className='my-8 border-b rounded border-[#E2E8F0]' />
        </>
        ) : null}
    </>
  );
};

export default PreviewReviews;
