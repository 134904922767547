import React, { useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { isNil, isEqual } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useForm, Controller } from 'react-hook-form';
import { useNewUserReportContext } from 'src/pagesDashboard/NewUserReport/context/NewUserReportProvider';
import { QUERY_KEYS as SCORE_QUERY_KEYS } from 'src/queries/score';
import { useUserFeedback } from 'src/containers/UserProfile/FeedbackDashboard/queries';
import { useAccount } from 'src/queries/account';
import {
  createReportQuery,
  updateReportQuery,
  useReports,
  QUERY_KEYS as REPORTS_QUERY_KEYS
} from 'src/queries/reports';
import { Highlight, toast, Select } from 'src/components/';
import { ReportInputSection } from 'src/pagesDashboard/NewUserReport/components';
import appUtils from 'src/components/appUtils';
import FilterByDate from 'src/containers/Dash/FilterView/FilterByDate';
import FilterByReviewers from 'src/containers/UserProfile/FilterView/FilterByReviewers';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import FilterByRoles from 'src/containers/UserProfile/FilterView/FilterByRoles';
import FilterByCategories from 'src/containers/UserProfile/FilterView/FilterByCategories';
import ReportGenerationSection from 'src/pagesDashboard/NewUserReport/components/ReportGenerationSection';
import AiLoading from 'src/pagesDashboard/NewUserReport/components/AiLoading';
import AiTextIcon from 'src/assets/svg/ai-text-icon.svg';
import TraditionalReportIcon from 'src/assets/svg/traditional-report-icon.svg';
import { getRoutes, ALL_STEPS } from 'src/pagesDashboard/NewUserReport/utils';
import buildParams from 'src/queries/utils/buildParams';
import commonDateUtils from 'common/commonDateUtils';
import { getUnixDateNow } from 'src/common/sharedDateUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import commonPermissions from 'common/commonPermissions';
import commonTreeUtils from 'common/commonTreeUtils';
import { useNavigate, useParams } from 'react-router-dom';

const {
  DATE_RANGE_FILTERS,
  INCLUDE_SCORES_OPTIONS,
  REPORT_GENERATION_TYPE,
  VIEW_SCORES_PERMISSIONS,
  USER_STATE
} = COMMON_CONSTANTS;

const scoresAnswerOptions = [
  {
    value: INCLUDE_SCORES_OPTIONS.YES,
    title: 'Yes, include scores on report'
  },
  {
    value: INCLUDE_SCORES_OPTIONS.NO,
    title: 'No, do not include scores on report'
  }
];

const dateStart = commonDateUtils.getDateFromDaysAgo(
  DATE_RANGE_FILTERS.ONE_YEAR.days
).unix;

const dateEnd = commonDateUtils.getUnixDateNow();

const getDefaultValues = (username, isIncludeScoresEnabled) => ({
  title: `${username}, ${commonDateUtils.dateToMonthDayYearFormat(
    new Date(dateStart * 1000)
  )} - ${commonDateUtils.dateToMonthDayYearFormat(new Date(dateEnd * 1000))}`,
  roles: [],
  range: {
    value: DATE_RANGE_FILTERS.ONE_YEAR.key,
    start: dateStart,
    end: undefined
  },
  reviewers: {
    reviewerIds: null,
    reviewerGroup: null
  },
  category: null,
  previousReport: null,
  includeScores: isIncludeScoresEnabled
    ? INCLUDE_SCORES_OPTIONS.YES
    : INCLUDE_SCORES_OPTIONS.NO,
  generationType: REPORT_GENERATION_TYPE.TRADITIONAL
});

const NewUserReport = () => {
  const { userId, reportId } = useParams();
  const isCreatingReport = reportId === 'new';
  const isEditingReport = reportId && !isCreatingReport;

  const navigate = useNavigate();

  const { enableCreateReviewsWithAi } = useFlags();
  const queryClient = useQueryClient();
  const loggedUser = appUtils.getLoggedUser();
  const loggedUserId = appUtils.getLoggedUserId();
  const isMyProfile = loggedUserId === userId;

  const {
    context: { aiGenerationLoading },
    updateContext
  } = useNewUserReportContext();

  const [fieldsLoading, setFieldsLoading] = useState([]);

  const { mutateAsync: createNewReport, isLoading: isCreateReportLoading } = createReportQuery();
  const { mutateAsync: updateReport, isLoading: isUpdateReportLoading } = updateReportQuery();

  const { data: accountData, isFetching: isFetchingViewerAccount } = useAccount('me');

  const {
    data: reportsData,
    isFetching: isFetchingReports,
    isError: isErrorReports
  } = useReports({ userId });

  const {
    data: userData,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount(userId);

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const formRef = useRef();

  useEffect(() => {
    updateContext({
      formRef,
      activeStep: ALL_STEPS.SETUP
    });
  }, []);

  const isAdmin = commonPermissions.isAdmin(loggedUser);
  const isManager = commonTreeUtils.isNodeDirectlyAbove(
    tree,
    userId,
    loggedUserId
  );
  const canManageAccount = commonPermissions.canManageAccounts(accountData, [
    userId
  ]);

  const isIncludeScoresInputEnabled = isAdmin
    || isManager
    || canManageAccount
    || company.settings.viewScores === VIEW_SCORES_PERMISSIONS.ALL;

  const {
    watch,
    control,
    register,
    setValue,
    handleSubmit,
    reset,
    formState,
    formState: { errors },
  } = useForm({
    defaultValues: getDefaultValues(userData.name, isIncludeScoresInputEnabled)
  });

  const formValues = watch();

  const {
    data: { pagination },
    isFetching: isFetchingFeedbacks,
    isError: isErrorFeedbacks
  } = useUserFeedback(
    userId,
    {
      range: formValues.range,
      filters: {
        category: formValues.category,
        reviewerIds: formValues.reviewers.reviewerIds,
        reviewerGroup: formValues.reviewers.reviewerGroup,
        roles: formValues.roles,
        includeEmptyComments: true,
        includeAnonymous: true,
        includeAnonymousReviews: true
      },
      pages: {
        page: 1,
        pageSize: 1000
      },
      viewerId: loggedUser.id
    },
    { keepPreviousData: true }
  );

  const totalFeedbacks = pagination?.total;

  const { data: reports } = reportsData;

  const currentReport = isEditingReport
    ? reports.find((r) => r._id === reportId)
    : null;

  const olderReports = currentReport
    ? reports.filter(
      (report) => report._id !== reportId
          && report.createdAt <= currentReport.lastUpdated
    )
    : reports;

  const reportsOptions = olderReports.map((report) => ({
    id: report._id,
    label: `${report.title ?? ''} - ${commonDateUtils.unixToMonthDayYearFormat(
      report.createdAt
    )}`
  }));

  reportsOptions.unshift({ id: null, label: 'None' });

  useEffect(() => {
    updateContext({
      isLoading:
        isFetchingReports
        || isCreateReportLoading
        || isUpdateReportLoading
        || isFetchingFeedbacks,
      aiGenerationLoading:
        formValues.generationType === REPORT_GENERATION_TYPE.AI
        && isCreateReportLoading
    });
  }, [
    isFetchingReports,
    isCreateReportLoading,
    isUpdateReportLoading,
    isFetchingFeedbacks
  ]);

  useEffect(() => {
    if (currentReport) {
      reset(
        {
          title: currentReport.title,
          roles: currentReport.roles,
          range: {
            value: DATE_RANGE_FILTERS.CUSTOM.key,
            start: currentReport.start,
            end: currentReport.end
          },
          reviewers: {
            reviewerIds: currentReport.reviewerIds,
            reviewerGroup: currentReport.reviewerGroup
          },
          category:
            currentReport.categories && currentReport.categories.length
              ? currentReport.categories[0]
              : null,
          includeScores: currentReport.includeScores,
          previousReport: currentReport.previousReport ?? null
        },
        { keepDefaultValues: false }
      );
    }
  }, [currentReport]);

  useEffect(() => {
    updateContext({ isLoading: fieldsLoading.length > 0 });
  }, [fieldsLoading.length]);

  if (currentReport && userData.status === USER_STATE.UNASSIGNED) {
    return navigate(`/dashboard/profile/${userId}/report/${reportId}/preview`);
  }

  const goNext = async (values) => {
    try {
      const {
        title,
        range: { start, end },
        reviewers: { reviewerIds, reviewerGroup },
        roles,
        category,
        previousReport,
        includeScores,
        generationType
      } = values;

      const data = {
        title,
        start,
        end: end ?? getUnixDateNow(),
        reviewerIds,
        reviewerGroup,
        roles,
        categories: category ? [category] : [],
        previousReport,
        includeScores,
        user: userId,
        generationType
      };

      if (totalFeedbacks === 0) {
        toast.error('Report needs to include at least one review!');
        return false;
      }

      if (isEditingReport) {
        await updateReport({ reportId, ...data });
        queryClient.invalidateQueries([
          REPORTS_QUERY_KEYS.REPORTS,
          buildParams({ userId })
        ]);
        queryClient.invalidateQueries([REPORTS_QUERY_KEYS.REPORT, reportId]);
        queryClient.invalidateQueries(SCORE_QUERY_KEYS);
        reset({});
        const routeToGo = getRoutes(userId, reportId, ALL_STEPS.FEEDBACK);
        return navigate(routeToGo);
      }

      if (isCreatingReport) {
        const { report: newReport } = await createNewReport(data);
        queryClient.invalidateQueries([
          REPORTS_QUERY_KEYS.REPORTS,
          buildParams({ userId })
        ]);
        queryClient.invalidateQueries(REPORTS_QUERY_KEYS.REPORTS);
        queryClient.invalidateQueries(REPORTS_QUERY_KEYS.MY_REPORTS);
        reset({});
        const encodedReportId = appUtils.encodeURIString(
          newReport._id || newReport.id
        );
        const routeToGo = generationType === REPORT_GENERATION_TYPE.TRADITIONAL
          ? getRoutes(userId, encodedReportId, ALL_STEPS.FEEDBACK)
          : getRoutes(userId, encodedReportId, ALL_STEPS.PREVIEW);
        return navigate(routeToGo);
      }
    } catch (error) {
      console.error('Failed to create/edit report', error);
      return toast.error(error);
    }
  };

  const setIsLoading = (field, isLoading) => {
    const isFieldInArr = fieldsLoading.includes(field);
    if (isFieldInArr && !isLoading) setFieldsLoading((prev) => prev.filter((f) => f !== field));
    if (!isFieldInArr && isLoading) setFieldsLoading((prev) => [...prev, field]);
  };

  const isFetching = isFetchingReports || isFetchingFeedbacks;
  const isError = isErrorReports
    || isErrorFeedbacks
    || isErrorAccount
    || isErrorCompany
    || isErrorTree;
  const isReady = !isFetching
    && !isError
    && (isCreatingReport
      || (isEditingReport
        && !isEqual(formState.defaultValues, getDefaultValues(userData.name))));

  if (!isReady) return <div className='h-full' />;

  if (aiGenerationLoading) {
    return <AiLoading />;
  }

  return (
    <form
      id='new-user-report-form'
      ref={formRef}
      className='flex flex-col h-full w-full justify-between mb-10'
      onSubmit={handleSubmit(goNext)}
    >
      <div
        className={`mb-8 md:mb-16 ${isEditingReport ? 'visible' : 'hidden'}`}
      >
        <Highlight
          variant='yellow'
          title='Date Adjustment'
          text='Please note that changing the date range will not remove any notes previously made in the report. You may need to review those to ensure the information still applies.'
        />
      </div>

      <ReportInputSection
        id='new-user-report-title'
        labelText='Title *'
        errorMessage={errors?.title?.message}
      >
        <input
          id='new-user-report-title'
          className='text-base w-full bg-white border-gray-100 shadow h-10'
          placeholder='Title'
          {...register('title', {
            required: {
              value: true,
              message: 'Write a title for the report'
            },
            minLength: {
              value: 5,
              message: 'Title needs to be longer'
            }
          })}
        />
      </ReportInputSection>

      <ReportInputSection
        id='new-user-report-range'
        labelText='Date Range *'
        errorMessage={errors?.dateRange?.message}
      >
        <Controller
          name='range'
          control={control}
          rules={{
            required: { value: true, message: 'Date Range is mandatory' }
          }}
          render={({ field: { value: fieldValue, onChange } }) => (
            <FilterByDate
              id='new-user-report-range'
              dateRangeSelected={fieldValue}
              onSelectDateRange={(value, start, end) => onChange({ value, start, end })}
              classes='w-full'
              customSelectClasses='w-full h-10'
            />
          )}
        />
      </ReportInputSection>

      <ReportInputSection
        id='new-user-report-reviewers'
        labelText='Reviewers'
        errorMessage={errors?.reviewers?.message}
      >
        <Controller
          name='reviewers'
          control={control}
          render={({ field: { value, onChange } }) => (
            <FilterByReviewers
              id='new-user-report-reviewers'
              isMyProfile={isMyProfile}
              userId={userId}
              reviewerIds={value.reviewerIds}
              reviewerGroup={value.reviewerGroup}
              updateFn={onChange}
              customContainerClasses='flex flex-row max-w-34rem'
              customSelectClasses='w-full'
              setIsLoading={(isLoading) => setIsLoading('new-user-report-reviewers', isLoading)}
            />
          )}
        />
      </ReportInputSection>

      <ReportInputSection
        id='new-user-report-roles'
        labelText='Roles'
        errorMessage={errors?.roles?.message}
      >
        <Controller
          name='roles'
          control={control}
          render={({ field: { value, onChange } }) => (
            <FilterByRoles
              id='new-user-report-roles'
              isMyProfile={isMyProfile}
              userId={userId}
              roles={value}
              updateFn={({ roles }) => onChange(roles)}
              customContainerClasses='flex flex-row max-w-34rem'
              customSelectClasses='w-full'
              setIsLoading={(isLoading) => setIsLoading('new-user-report-roles', isLoading)}
            />
          )}
        />
      </ReportInputSection>

      <ReportInputSection
        id='new-user-report-categories'
        labelText='Categories'
        errorMessage={errors?.category?.message}
      >
        <Controller
          name='category'
          control={control}
          render={({ field: { value, onChange } }) => (
            <FilterByCategories
              id='new-user-report-categories'
              isMyProfile={isMyProfile}
              userId={userId}
              category={value}
              updateFn={({ category }) => onChange(category)}
              customContainerClasses='flex flex-row max-w-34rem'
              customSelectClasses='w-full'
              setIsLoading={(isLoading) => setIsLoading('new-user-report-categories', isLoading)}
            />
          )}
        />
      </ReportInputSection>

      <ReportInputSection
        id='new-user-report-compare-previous'
        labelText='Previous Report'
        errorMessage={errors?.previousReport?.message}
      >
        <Controller
          name='previousReport'
          control={control}
          render={({ field: { value, onChange } }) => {
            const title = reportsOptions.find((opt) => opt.id === value)?.label ?? null;

            return (
              <Select
                id='new-user-report-compare-previous'
                variant='shadow'
                classes='w-full'
                placeholder='Select a previous report (optional)'
                options={reportsOptions}
                title={title}
                onChange={(opt) => onChange(opt.id)}
              />
            );
          }}
        />
      </ReportInputSection>

      <ReportInputSection
        id='new-user-report-scores'
        labelText='Include Scores?'
        errorMessage={errors?.includeScores?.message}
      >
        <Controller
          name='includeScores'
          control={control}
          rules={{
            required: {
              value: true,
              message: 'You need to select an option'
            }
          }}
          render={({ field: { value, onChange } }) => (
            <Select
              id='include-scores-select'
              variant='shadow'
              classes='w-full'
              title={
                scoresAnswerOptions.find((option) => option.value === value)
                  .title
              }
              disabled={!isIncludeScoresInputEnabled}
              options={scoresAnswerOptions}
              onChange={(option) => onChange(option.value)}
            />
          )}
        />
      </ReportInputSection>

      {
        enableCreateReviewsWithAi && (
          <ReportGenerationSection
            id='new-user-report-generation'
            type={REPORT_GENERATION_TYPE.AI}
            labelText='Report Generation'
            errorMessage={errors?.generation?.message}
            isDisabled={isEditingReport}
          >
            <button
              type='button'
              onClick={() => setValue('generationType', REPORT_GENERATION_TYPE.AI)}
              disabled={isEditingReport}
              className={`rounded-md mb-8 focus:outline-none ${
                formValues.generationType === REPORT_GENERATION_TYPE.AI
                && !isEditingReport
                  ? 'border-4 border-purple'
                  : 'border-2 border-black'
              } ${isEditingReport ? 'bg-gray-500 border-4 border-gray-300' : 'bg-black'}`}
            >
              <div className='text-left px-3 py-4'>
                <div className='flex justify-between'>
                  <p className='font-roboto-mono text-white text-xl mb-1 mt-1'>
                    WorkStoryAI
                  </p>
                  {isEditingReport ? (
                    <AiTextIcon className='text-gray' />
                  ) : (
                    <AiTextIcon className='text-green' />
                  )}
                </div>
                <p
                  className={`font-roboto-mono mb-1 font-bold ${
                    isEditingReport ? 'text-white' : 'text-gray-200'
                  }`}
                >
                  AI-Generated Performance Review
                </p>
                <p
                  className={`font-roboto-mono mb-0  ${
                    isEditingReport ? 'text-white' : 'text-gray-200'
                  }`}
                >
                  Automatically summarizes performance and recommends opportunities
                  for future development. Can edit later.
                </p>
              </div>
            </button>
            <button
              type='button'
              onClick={() => setValue('generationType', REPORT_GENERATION_TYPE.TRADITIONAL)}
              disabled={isEditingReport}
              className={`rounded-md focus:outline-none ${
                formValues.generationType === REPORT_GENERATION_TYPE.TRADITIONAL
                  ? 'border-4 border-purple'
                  : 'border-2 border-black'
              } ${isEditingReport ? 'border-4 border-gray-300' : ''}`}
            >
              <div
                className={`text-left px-3 py-4 ${isEditingReport ? 'text-gray-400' : ''}`}
              >
                <div className='flex justify-between'>
                  <p className='text-xl mb-1 mt-1'>Traditional</p>
                  {isEditingReport ? (
                    <TraditionalReportIcon className='text-gray-400' />
                  ) : (
                    <TraditionalReportIcon className='text-purple' />
                  )}
                </div>
                <p className='mb-1 font-bold'>Self-Authored Performance Review</p>
                <p className='mb-0'>
                  Manually review historic data while crafting your own thoughts on
                  performance and growth opportunities.
                </p>
              </div>
            </button>
          </ReportGenerationSection>
        )
      }


      {!isNil(totalFeedbacks) && !isFetchingFeedbacks ? (
        <p className='m-auto text-xl text-gray-600 font-semibold'>
          Includes
          {' '}
          {totalFeedbacks}
          {' '}
          reviews
        </p>
      ) : (
        <div className='h-36' />
      )}
    </form>
  );
};

export default NewUserReport;
