import React from 'react';
import './Radio.scss';

const Radio = ({
  title,
  OptionElement,
  name,
  classes,
  placeholder,
  onEnter,
  labelClass,
  disabled = false,
  value,
  ...rest
}) => {
  placeholder = placeholder || '';

  const id = Math.floor(Math.random() * 900000) + 10000;
  return (
    <div className={`${classes ?? 'flex items-center'} radio-component`}>
      <input
        type='radio'
        id={`${title}_${id}`}
        name={name}
        className={classes}
        disabled={disabled}
        value={Boolean(value)}
        onKeyPress={(e) => {
          if (e.key === 'Enter' && onEnter) {
            onEnter();
          }
        }}
        {...rest}
      />
      {title ? (
        <label
          htmlFor={`${title}_${id}`}
          className={`${labelClass || 'ml-4 text-base'}`}
        >
          {title}
        </label>
      ) : null}
      {OptionElement ? <OptionElement /> : null}
    </div>
  );
};

export default Radio;
