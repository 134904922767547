import React from 'react';
import { Tree } from 'src/containers/Tree';
import 'src/containers/Tree/TreeContainer.scss';
import TreeFilter from 'src/containers/Organization/Tree/TreeFilter';
import TreeSearch from 'src/containers/Organization/Tree/TreeSearch';
import { useTree } from 'src/queries/tree';

const TreeContainer = ({
  treeNode,
  openBelow,
  openAbove,
  openUserDetails,
  visibleDepth
}) => {
  const {
    data: { myTreeRow },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const isFetching = isFetchingTree;
  const isError = isErrorTree;
  const isReady = myTreeRow && myTreeRow.id && !isFetching && !isError;

  if (!isReady) return null;

  return (
    <div className='tree-container'>
      <div>
        <div className='w-1/2 inline-block'>
          <TreeFilter />
        </div>
        <div className='w-1/2 inline-block text-right pr-3'>
          <TreeSearch />
        </div>
      </div>
      <Tree
        classes='team-tree'
        treeNode={treeNode}
        myTreeRow={myTreeRow}
        openBelow={openBelow}
        openAbove={openAbove}
        openUserDetails={openUserDetails}
        visibleDepth={visibleDepth}
      />
      <p className='text-xl font-bold text-light-gray text-center pb-2 mt-2 mb-0 cursor-default'>
        Click on user cards to edit their profile
      </p>
    </div>
  );
};

export default TreeContainer;
