import React, { useState } from 'react';
import { userSettingsThunks } from 'src/thunks';
import signupUtils from 'src/pages/SignUp/utils';
import {
  Button, toast, Input, Base
} from 'src/components';
import STYLE from 'src/constants/style';
import { useAccount } from 'src/queries/account';
import { get, isEmpty } from 'lodash';

const Password = () => {
  const {
    data: account,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount('me');

  const isFetching = isFetchingAccount;
  const isError = isErrorAccount;
  const isReady = account && account._id && !isFetching && !isError;

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  if (!isReady) {
    return null;
  }

  const saveNewPass = () => {
    const isCurrentPasswordValid = signupUtils.isPasswordValid(currentPassword);
    if (!isCurrentPasswordValid.success) {
      return toast.error(isCurrentPasswordValid.message);
    }
    const isNewPasswordValid = signupUtils.isPasswordValid(newPassword);
    if (!isNewPasswordValid.success) {
      return toast.error(isNewPasswordValid.message);
    }
    setIsLoading(true);
    userSettingsThunks
      .saveNewPassword(currentPassword, newPassword)()
      .then((resp) => {
        setCurrentPassword('');
        setNewPassword('');
        setIsLoading(false);
        if (!resp || !resp.success) {
          toast.error(resp.message);
          return;
        }
        toast.show('Password updated');
      });
  };

  // if user has Google SSO active, do not display Change Password section
  if (!isEmpty(get(account, 'googleOauth'))) return null;

  return (
    <Base classes={STYLE.CONTAINER_WHITE} loading={isLoading}>
      <h5 className='pt-2'>Change Password</h5>
      <p>Please enter your current and new password below:</p>
      <form>
        <Input
          type='password'
          onChange={(e) => {
            setCurrentPassword(e.target.value);
          }}
          onEnter={saveNewPass}
          value={currentPassword}
          placeholder='Current Password'
          autoComplete='current-password'
        />
        <Input
          type='password'
          onChange={(e) => {
            setNewPassword(e.target.value);
          }}
          onEnter={saveNewPass}
          value={newPassword}
          placeholder='New Password'
          autoComplete='new-password'
        />
        <Button
          classes='mb-3'
          variant='yellow'
          onClick={saveNewPass}
          disabled={isLoading || !currentPassword || !newPassword}
        >
          Save
        </Button>
      </form>
    </Base>
  );
};

export default Password;
