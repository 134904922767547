import React from 'react';
import commonUtils from 'common/commonUtils';
import DOMPurify from 'dompurify';
import './PreviewSummary.scss';
import { isEmpty } from 'lodash';

const PreviewSummary = ({ mode = null, data = {} }) => {
  let summaries = [];

  if (mode === 'form') {
    const { form } = data;
    const { watch } = form;
    const { summary } = watch();
    summaries = [summary];
  }

  if (mode === 'data') {
    const { summaries: dataSummaries } = data;
    summaries = dataSummaries;
  }

  const areThereSummaries = summaries.some((summary) => Boolean(commonUtils.stripHTMLFromString(summary)));
  if (!areThereSummaries) return null;

  return (
    <>
      <h2>Performance Summary</h2>
      {summaries.map((summary, index) => {
        const hasContent = !isEmpty(commonUtils.stripHTMLFromString(summary));
        if (!hasContent) return null;

        return (
          <div
            className=' w-full flex py-3 flex-col justify-center text-left border border-slate-300 px-6 bg-gray-200 min-h-20 my-2 [&_p]:m-0'
            key={index}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(summary)
              }}
            />
          </div>
        );
      })}
    </>
  );
};

export default PreviewSummary;
