import React, { cloneElement } from 'react';
import STYLE from 'src/constants/style';
import Notifications from 'src/containers/Dash/Notifications/Notifications';
import { Base, appUtils } from 'src/components/index';
import { useCompany } from 'src/queries/company';
import TabNavigator, { TABS } from 'src/components/TabNavigator/TabNavigator';
import HeaderV2 from 'src/containers/Header/HeaderV2';
import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

const Dash = ({ children }) => {
  const navigate = useNavigate();

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const {
    showRequestFeedbackButton,
    showCreateReviewButtonOnMyDashboard
  } = useFlags();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = get(company, 'id') && !isFetching && !isError;

  if (!isReady) {
    return null;
  }
  const loggedUserId = appUtils.getLoggedUserId();
  const tabNavigatorActions = [
    {
      tabs: [TABS.GOALS],
      onClick: () => navigate(`/dashboard/goal/new/${loggedUserId}`),
      buttonText: 'Set a Goal',
      buttonVariant: 'black'
    },
  ];
  if (showRequestFeedbackButton) {
    tabNavigatorActions.push({
      tabs: [TABS.FEEDBACK, TABS.ANALYTICS],
      onClick: () => navigate(`/dashboard/request-feedback?revieweeId=${loggedUserId}`),
      buttonText: 'Request Feedback',
      buttonVariant: 'black'
    });
  }
  if (showCreateReviewButtonOnMyDashboard) {
    tabNavigatorActions.push({
      tabs: [TABS.REPORTS],
      onClick: () => navigate(`/dashboard/profile/${loggedUserId}/report/new`),
      buttonText: 'Create a Review',
      buttonVariant: 'black'
    });
  }

  const mobileSize = appUtils.getMobileSize();
  const isMobile = mobileSize;

  if (isMobile) {
    return (
      <div>{cloneElement(children, { userId: loggedUserId })}</div>
    );
  }

  return (
    <>
      <HeaderV2 overtitle='Dashboards' title='Individual' />
      <Notifications />
      <TabNavigator userId={loggedUserId} actions={tabNavigatorActions} />
      <Base classes={STYLE.BASE}>
        {cloneElement(children, { userId: loggedUserId })}
      </Base>
    </>
  );
};

export default Dash;
