import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useReport } from 'src/queries/reports';
import { useNewUserReportContext } from 'src/pagesDashboard/NewUserReport/context/NewUserReportProvider';
import { ALL_STEPS, getRoutes } from 'src/pagesDashboard/NewUserReport/utils';
import { FeedbackListSection } from 'src/pagesDashboard/NewUserReport/containers/NewUserReportFeedback';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useNavigate, useParams } from 'react-router-dom';

const { REPORT_NOTE_TYPES } = COMMON_CONSTANTS;

const NewUserReportFeedback = () => {
  const { userId, reportId } = useParams();
  const navigate = useNavigate();

  const { updateContext } = useNewUserReportContext();
  const {
    data: report,
    isFetching: isFetchingReport,
    isError: isErrorReport
  } = useReport(reportId, {
    type: REPORT_NOTE_TYPES.FEEDBACK
  });

  const formRef = useRef();

  useEffect(() => {
    updateContext({ formRef, activeStep: ALL_STEPS.FEEDBACK });
  }, []);

  useEffect(() => {
    updateContext({
      isLoading: isFetchingReport
    });
  }, [isFetchingReport]);

  const isFetching = isFetchingReport;
  const isError = isErrorReport;
  const isReady = !isFetching && !isError && report;

  const { handleSubmit } = useForm({
    defaultValues: {}
  });

  const goNext = () => {
    const routeToGo = getRoutes(userId, reportId, ALL_STEPS.CATEGORIES);
    return navigate(routeToGo);
  };

  return (
    <form
      id='new-user-report-feedback-form'
      ref={formRef}
      className='flex flex-col h-full w-full justify-between md:mb-32'
      onSubmit={handleSubmit(goNext)}
    >
      <FeedbackListSection
        userId={userId}
        reportId={reportId}
        previousReport={report.previousReport}
        isEditorOpenByDefault={false}
      />
    </form>
  );
};

export default NewUserReportFeedback;
