import React from 'react';
import COMMON_CONSTANTS from 'common/commonConstants';

const { REPORT_GENERATION_TYPE } = COMMON_CONSTANTS;

const BulkReportsInputSection = ({
  id,
  labelText,
  children,
  errorMessage,
  type
}) => (
  <section className='mb-10 md:mb-16 flex justify-between flex-col md:flex-row'>
    <label
      htmlFor={id}
      className={`text-gray-500 mb-4 uppercase font-bold text-xl ${REPORT_GENERATION_TYPE.AI === type ? '' : 'md:my-auto'}`}
    >
      {labelText}
    </label>

    <div className='w-full max-w-34rem'>
      {children}
      <div className='text-red whitespace-nowrap flex items-center mt-2 absolute'>
        {errorMessage}
      </div>
    </div>
  </section>
);
export default BulkReportsInputSection;
