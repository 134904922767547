import React, { useState } from 'react';
import { SignUpBase } from 'src/componentsTailwind';
import EyeSVG from 'src/assets/svg/eye.svg';
import EyeOffSVG from 'src/assets/svg/eye-off.svg';
import commonUtils from 'common/commonUtils';
import { useNavigate } from 'react-router-dom';

const SignUp = ({
  form,
  onLogin = () => {},
  onSignUp = () => {},
  isLoading = false,
  defaultVariant = 'login',
  allowSwitching = true,
  prevRoute = null
}) => {
  const { watch, setValue } = form;
  const { email, password } = watch();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [variant, setVariant] = useState(defaultVariant);

  let title = '';
  let buttonText = '';
  if (variant === 'login') {
    title = 'Sign in to your account';
    buttonText = 'Sign in';
  } else if (variant === 'signup') {
    title = 'Sign up for an account';
    buttonText = 'Sign up';
  }

  const navigate = useNavigate();
  return (
    <SignUpBase title={title}>
      <div>
        <label
          htmlFor='email'
          className='block text-sm font-medium leading-6 text-black'
        >
          Email address
        </label>
        <div className='mt-2'>
          <input
            value={email}
            onChange={(e) => setValue('email', e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (variant === 'login') onLogin();
                if (variant === 'signup') onSignUp();
              }
            }}
            placeholder='Email'
            disabled={isLoading}
            className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 focus-visible:ring-indigo-600 sm:text-sm sm:leading-6 bg-white'
          />
        </div>
      </div>
      <div>
        <div className='flex items-center justify-between'>
          <label
            htmlFor='password'
            className='block text-sm font-medium leading-6 text-black'
          >
            Password
          </label>
        </div>
        <div className='flex items-center justify-end mt-2'>
          <input
            type={isPasswordVisible ? 'text' : 'password'}
            value={password}
            onChange={(e) => setValue('password', e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (variant === 'login') onLogin();
                if (variant === 'signup') onSignUp();
              }
            }}
            placeholder='Password'
            disabled={isLoading}
            className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-white'
          />
          <button
            className='h-5 w-5 absolute mr-2'
            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          >
            {isPasswordVisible ? <EyeSVG /> : <EyeOffSVG />}
            <span className='sr-only'>
              {isPasswordVisible ? 'Hide password' : 'Show password'}
            </span>
          </button>
        </div>
      </div>
      <button
        type='submit'
        className='flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm
            hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600
            disabled:bg-indigo-400
            '
        onClick={() => {
          if (variant === 'login') onLogin();
          if (variant === 'signup') onSignUp();
        }}
        disabled={
          isLoading
          || !commonUtils.isEmailValid(email)
          || String(password).length < 5
        }
      >
        {buttonText}
      </button>
      {variant === 'login' ? (
        <div className='flex items-center justify-end'>
          <div className='text-sm leading-6'>
            <button
              href='#'
              className='font-semibold text-indigo-600 hover:text-indigo-500'
              onClick={() => navigate(
                `/forgot-password/${prevRoute ? `?switchRoute=${prevRoute}` : ''}`
              )}
            >
              Forgot password?
            </button>
          </div>
        </div>
      ) : null}
      {allowSwitching ? (
        <div className='flex items-center justify-center w-full'>
          <p className='m-0'>
            {variant === 'login' && "Don't have an account? Sign Up "}
            {variant === 'signup' && 'Already have an account? Login '}
            <button
              className='font-semibold text-indigo-600 hover:text-indigo-500 underline'
              onClick={() => {
                if (variant === 'login') setVariant('signup');
                if (variant === 'signup') setVariant('login');
              }}
            >
              here
            </button>
          </p>
        </div>
      ) : null}
    </SignUpBase>
  );
};

export default SignUp;
