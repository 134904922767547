import React from 'react';
import { Controller } from 'react-hook-form';
import { getGoalStatuses } from 'src/containers/UserProfile/Goals/constants';
import { Select } from 'src/components';
import { get } from 'lodash';

const Status = ({ control }) => (
  <div className='flex justify-between h-20'>
    <p className='mb-0 font-bold text-base text-gray-500'>STATUS</p>
    <div className='pl-8 pr-6 h-16 w-4/6'>
      <Controller
        name='status'
        control={control}
        rules={{ required: true }}
        render={({ field }) => {
          const title = get(field.value, 'label', 'Set status');
          return (
            <Select
              placeholder='Set status'
              classes='w-full'
              options={getGoalStatuses()}
              title={title}
              {...field}
            />
          );
        }}
      />
    </div>
  </div>
);

export default Status;
