import React, { useContext, useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useQueryClient } from 'react-query';
import { isEmpty, get } from 'lodash';
import {
  Button, toast, PickDate, Select
} from 'src/components';
import {
  useCreateBuilderReport,
  useUpdateBuilderReport,
  QUERY_KEYS as BUILDER_QUERY_KEYS,
  useBuilderReport
} from 'src/queries/builder';
import BuilderContainer from 'src/pages/PerformanceBuilder/containers/BuilderContainer';
import appUtils from 'src/components/appUtils';
import commonUtils from 'common/commonUtils';
import commonDateUtils from 'common/commonDateUtils';
import commonBuilderUtils from 'common/commonBuilderUtils';
import { PerformanceBuilderContext } from 'src/pages/PerformanceBuilder/PerformanceBuilderContext';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useNavigate } from 'react-router-dom';
import { AlertModal } from 'src/componentsTailwind/index';
import Flag2SVG from 'src/assets/svg/flag-2.svg';

const { USER_STATE, ACCESS } = COMMON_CONSTANTS;
const { WARNINGS } = commonBuilderUtils;

const excludedReviewerIds = [];
const excludedRevieweeIds = [
  '731afde1-a1bc-449c-b5a2-b50e805aca9e',
  'a4dc7e6f-3073-4369-9a02-95a4fd121680',
  '6cfa2150-b648-4e1b-ab62-71863dc57213',
  'a8a85936-c148-49a5-a4ec-cfd1fe164115',
  'a43460f2-6aa4-49e4-ac50-3d8041a1d5e0',
  'f5708d7d-87ed-49d9-a8a2-eb56c84b071c',
  '6333758b-c83a-4af4-8e0e-71aa3bc49668',
  'adaa3503-5a10-429f-a237-aa3b98c0cf77',
  '45dce9d8-f9bc-4e14-bbac-ddf61dec4abc',
  '3d180a9a-a9d0-4f35-a4bc-2744ddf3da0b',
  '589a6e8b-861e-4a6c-aa71-c8557bd46736',
  'e62047c1-6f32-4b60-9169-7da621e5c1c0',
  '0d632c5c-4f29-40b9-ba0d-01f0064d6c2f',
  '5dc6dbd7-00f0-4b87-a93f-d15dd763a24f',
  '9d5a971e-2a6d-4cba-b069-e3128bbf905d',
  '0195c699-aa48-488c-beb0-b6c5624746bc',
  'f6bccc16-90fb-4a5a-9703-f02ed0a28fd5',
  '85957cb9-16fe-4fab-b164-5d9822cf3c97',
  'c5fa3968-2c37-4b72-b589-a9e5f5f374ad',
  '97e5580d-8e20-47a9-920a-8146a1304205',
  '9640e1f4-2b6c-4b43-8624-7b617d903c8a',
  '5ed5c944-748d-443b-a5a1-d9edc294d76c',
  'ba97e6c7-05d6-4a0f-a88b-fc3160967821',
  '3418a486-7911-4f1a-8a4a-8b019dffbc00'
];

const ReviewInfo = () => {
  const {
    data: {
      companyid,
      reportId,
      editToken,
      form,
      page,
      dataset,
      canReviewAnyone,
      companyMode,
      prevDataRef,
      mode
    },
    setDataObject
  } = useContext(PerformanceBuilderContext);
  const { allowBuilderSelfReviews } = useFlags();
  const { isFetching } = useBuilderReport(reportId);

  const isEditPage = page === 'edit';
  const isBuilderPage = page === 'builder';

  const isCompanySpecific = companyMode === 'company';

  const queryClient = useQueryClient();
  const {
    register, control, watch, setValue, getValues
  } = form;
  const prevData = prevDataRef.current;
  const {
    reviewerId,
    reviewerFirstName,
    reviewerLastName,
    reviewerEmail,
    revieweeId,
    revieweeFirstName,
    revieweeLastName,
    revieweeTitle,
    companyName,
    title,
    reviewDate,
    cycleId,
    cycleLabel
  } = watch();
  const {
    create: createBuilderReport,
    isLoading: isCreateBuilderReportLoading
  } = useCreateBuilderReport();
  const {
    update: updateBuilderReport,
    isLoading: isUpdateBuilderReportLoading
  } = useUpdateBuilderReport(editToken);

  const [reviewerSearchText, setReviewerSearchText] = useState('');
  let reviewerOptions = [];
  const reviewerFullName = commonUtils.getFullName(
    reviewerFirstName,
    reviewerLastName
  );

  const [revieweeSearchText, setRevieweeSearchText] = useState('');
  let revieweeOptions = [];
  const revieweeFullName = commonUtils.getFullName(
    revieweeFirstName,
    revieweeLastName
  );

  const isLoggedIn = appUtils.isLoggedIn();
  const loggedUser = appUtils.getLoggedUser();
  const loggedUserId = appUtils.getLoggedUserId();
  const isAdmin = get(loggedUser, 'access') === ACCESS.ADMIN;
  const isJoeleFrank = commonBuilderUtils.getIsJoeleFrank(companyid);
  const isPublicSchoolForum = commonBuilderUtils.getIsPublicSchoolForum(companyid);

  const reportTitleOptions = [
    { id: 'self-evaluation', label: 'Self Evaluation' },
    { id: 'performance-review', label: 'Performance Review' }
  ];

  if (isCompanySpecific) {
    const [{ accounts }] = dataset;

    let filteredReviewers = [];
    if (isAdmin) {
      filteredReviewers = accounts.filter((account) => {
        const common = reviewerId !== account._id
          && account.status !== USER_STATE.UNASSIGNED
          && (!reviewerSearchText
            || account.name
              .toLowerCase()
              .includes(reviewerSearchText.toLowerCase()));

        // Joele Frank rules
        if (isJoeleFrank) {
          return common && !excludedReviewerIds.includes(account._id);
        }

        if (!isAdmin) {
          return common;
        }
        return common;
      });
    } else if (isLoggedIn) {
      const loggedUserAccount = accounts.find(
        (account) => account._id === loggedUserId
      );
      filteredReviewers = [loggedUserAccount];
    }

    if (isEmpty(filteredReviewers)) {
      reviewerOptions = [
        {
          id: null,
          label: 'No member found',
          disabled: true
        }
      ];
    } else {
      reviewerOptions = filteredReviewers.map((account) => ({
        id: account._id,
        label: commonUtils.getFullName(account),
        firstName: account.firstName,
        lastName: account.lastName,
        email: account.email
      }));
    }

    if (reviewerId) {
      let validReviewees = [];
      if (canReviewAnyone) validReviewees = accounts;
      else {
        const reviewerAccount = accounts.find(
          (account) => account._id === reviewerId
        );
        const { validRevieweeIds } = reviewerAccount;
        validReviewees = accounts.filter((account) => validRevieweeIds.includes(account._id));
      }

      const filteredReviewees = validReviewees.filter((account) => {
        const common = revieweeId !== account._id
          && account.status !== USER_STATE.UNASSIGNED
          && (!revieweeSearchText
            || account.name
              .toLowerCase()
              .includes(revieweeSearchText.toLowerCase()));

        if (!allowBuilderSelfReviews && account._id === loggedUserId) {
          return false;
        }

        if (isJoeleFrank) {
          return common && !excludedRevieweeIds.includes(account._id);
        }

        if (isPublicSchoolForum) {
          return common && !excludedRevieweeIds.includes(account._id);
        }

        return common;
      });

      if (isEmpty(filteredReviewees)) {
        revieweeOptions = [
          {
            id: null,
            label: 'No member found',
            disabled: true
          }
        ];
      } else {
        revieweeOptions = filteredReviewees.map((account) => {
          const { settings } = account;
          return {
            id: account._id,
            label: commonUtils.getFullName(account),
            firstName: account.firstName,
            lastName: account.lastName,
            title: account.title,
            defaultTemplateId: settings.defaultTemplateId
          };
        });
      }
    }
  }

  const onChangeReviewer = (option) => {
    const isSelectingADifferentOption = reviewerId && reviewerId !== option.id;
    if (isSelectingADifferentOption) {
      const answer = confirm(
        'Changing and saving the selected reviewer will erase your progress. Are you sure you want to proceed?'
      );
      if (!answer) return;
    }

    const {
      id, firstName, lastName, email
    } = option;
    setValue('reviewerId', id);
    setValue('reviewerFirstName', firstName);
    setValue('reviewerLastName', lastName);
    setValue('reviewerEmail', email);
    setValue('revieweeId', null);
    setValue('revieweeFirstName', '');
    setValue('revieweeLastName', '');
    setValue('revieweeTitle', '');
    if (!isCompanySpecific) setValue('industry', commonBuilderUtils.getEmptyOption());
    setValue('role', commonBuilderUtils.getEmptyOption());
    setValue('reviews', []);
    setValue('supplementalReviews', []);
    setValue('summary', '');
    setValue('summaryTemplate', null);

    if (isJoeleFrank) {
      if (!revieweeId || id !== revieweeId) {
        setValue('title', reportTitleOptions[1].label);
      } else {
        setValue('title', reportTitleOptions[0].label);
      }
    }
  };

  const onChangeReviewee = (option) => {
    const isSelectingADifferentOption = revieweeId && revieweeId !== option.id;
    if (isSelectingADifferentOption) {
      const answer = confirm(
        'Changing and saving the selected reviewee will erase your progress. Are you sure you want to proceed?'
      );
      if (!answer) return;
    }

    const {
      id, firstName, lastName, title: newRevieweeTitle
    } = option;
    setValue('revieweeId', id);
    setValue('revieweeFirstName', firstName);
    setValue('revieweeLastName', lastName);
    setValue('revieweeTitle', newRevieweeTitle);
    if (!isCompanySpecific) setValue('industry', commonBuilderUtils.getEmptyOption());
    setValue('role', commonBuilderUtils.getEmptyOption());
    setValue('reviews', []);
    setValue('supplementalReviews', []);
    setValue('summary', '');
    setValue('summaryTemplate', null);

    if (isJoeleFrank) {
      if (id !== reviewerId) {
        setValue('title', reportTitleOptions[1].label);
      } else {
        setValue('title', reportTitleOptions[0].label);
      }
    }
  };

  const onChangeCycle = (option) => {
    setValue('cycleId', option.id);
    setValue('cycleLabel', option.label);
  };

  const isEmailInvalid = !commonUtils.isEmailValid(reviewerEmail);

  const scrollToChooseReviewTemplate = () => {
    // interval to scroll to element
    let reviewTemplateSection;
    const refreshIntervalId = setInterval(() => {
      reviewTemplateSection = document.getElementById(
        'review-template-section'
      );
      if (!reviewTemplateSection) {
        return;
      }
      reviewTemplateSection.scrollIntoView({ behavior: 'smooth' });
      clearInterval(refreshIntervalId);
    }, 300);
  };

  const navigate = useNavigate();
  const [existingReportEditToken, setExistingReportEditToken] = useState(null);
  const save = async () => {
    if (cycleId === 'removed') {
      return toast.show(
        'The review cycle for this review has been removed from your company. Please select another review cycle before saving.'
      );
    }

    const reviewerData = {
      reviewerId: reviewerId || null,
      firstName: reviewerFirstName,
      lastName: reviewerLastName,
      email: reviewerEmail
    };

    const revieweeData = {
      revieweeId: revieweeId || null,
      firstName: revieweeFirstName,
      lastName: revieweeLastName,
      title: revieweeTitle
    };

    const data = {
      reviewee: revieweeData,
      reviewer: reviewerData,
      companyName,
      title,
      reviewDate,
      companyMode: isCompanySpecific ? 'company' : 'public',
      cycleId
    };

    // we only reset data when changing reviewer or reviewee if we are in company mode
    // they're just text fields otherwise
    if (isCompanySpecific) {
      const [{ industryId, accounts }] = dataset;
      const revieweeAccount = accounts.find(
        (account) => account._id === revieweeId
      );
      const hasChangedReviewee = revieweeAccount._id !== prevData.revieweeId;
      if (hasChangedReviewee) {
        const [firstRoleId] = revieweeAccount.roles;
        data.industryId = industryId;
        data.roleId = firstRoleId;
        data.reviews = [];
        data.summary = '';
      }
    }

    let callback = () => {};

    if (mode === 'test') {
      data.mode = 'test';
    }

    let reportData = null;
    if (isBuilderPage) {
      const isLoggedIn = appUtils.isLoggedIn();
      if (isLoggedIn) data.companyid = loggedUser.companyid;
      else if (isCompanySpecific) data.companyid = companyid;
      const { warning, data: mutationData } = await createBuilderReport({
        data
      });

      if (warning === WARNINGS.REPORT_ALREADY_EXISTS) {
        const { editToken: existingEditToken } = mutationData;
        setExistingReportEditToken(existingEditToken);
        return;
      }

      const { data: createdReport } = mutationData;
      reportData = createdReport;
      toast.show('Performance review created!');
      const encodedBuilderId = appUtils.encodeURIString(
        createdReport.editToken
      );
      callback = () => {
        navigate(`/builder/${encodedBuilderId}/edit`);
        scrollToChooseReviewTemplate();
      };
    }

    if (isEditPage) {
      const { warning, data: mutationData } = await updateBuilderReport({
        data
      });

      if (warning === WARNINGS.REPORT_ALREADY_EXISTS) {
        const { editToken: existingEditToken } = mutationData;
        setExistingReportEditToken(existingEditToken);
        return;
      }

      const { data: updatedReport } = mutationData;
      reportData = updatedReport;
      toast.show('Performance review updated!');
      scrollToChooseReviewTemplate();
    }

    queryClient.invalidateQueries([BUILDER_QUERY_KEYS.BUILDER, reportData._id]);
    queryClient.invalidateQueries(BUILDER_QUERY_KEYS.BUILDER_DASHBOARD);
    setDataObject({
      reportId: reportData._id,
      editToken: reportData.editToken,
      companyid: reportData.companyid,
      reviewMode: reportData.reviewMode,
      companyMode: reportData.companyMode,
      mode: reportData.mode
    });

    if (isCompanySpecific) {
      const { industry: populatedIndustry, role: populatedRole } = commonBuilderUtils.getPopulatedIndustryAndRole(dataset, reportData);
      setValue('industry', populatedIndustry);
      setValue('role', populatedRole);
      const populatedReviews = commonBuilderUtils.getPopulatedReviews(
        dataset,
        reportData
      );
      setValue('reviews', populatedReviews);
      const populatedSupplementalReviews = commonBuilderUtils.getPopulatedSupplementalReviews(dataset, reportData);
      setValue('supplementalReviews', populatedSupplementalReviews);
      setValue('summary', reportData.summary);
      setValue('summaryTemplate', null);
    }

    queryClient.invalidateQueries(BUILDER_QUERY_KEYS.BUILDER);
    prevDataRef.current = JSON.parse(JSON.stringify(getValues()));

    if (isEmpty(reportData.summary) && isCompanySpecific) {
      const [{ industryId, accounts }] = dataset;
      const revieweeAccount = accounts.find(
        (account) => account._id === revieweeId
      );
      const {
        settings: { defaultTemplateId }
      } = revieweeAccount;
      const templateOptions = commonBuilderUtils.getTemplateOptions(
        industryId,
        dataset
      );
      const defaultTemplate = templateOptions.find(
        (template) => template.id === defaultTemplateId
      );
      if (defaultTemplate) {
        setValue('summary', defaultTemplate.content);
        setValue('summaryTemplate', defaultTemplate.label);
      }
    }

    callback();
  };

  const autofillInfo = async () => {
    setValue('reviewerFirstName', 'George');
    setValue('reviewerLastName', 'Costanza');
    setValue('reviewerEmail', 'george_costanza@workstory.team');
    setValue('revieweeFirstName', 'Cosmo');
    setValue('revieweeLastName', 'Kramer');
    setValue('revieweeTitle', 'Model');
    setValue('companyName', 'Test WorkStory');
    setValue('title', 'Test Report');
    setValue('reviewDate', new Date('July 5, 1989'));
  };

  const getIsButtonDisabled = () => {
    if (
      existingReportEditToken
      || isFetching
      || isEmailInvalid
      || isCreateBuilderReportLoading
      || isUpdateBuilderReportLoading
    ) return true;

    const newData = {
      reviewerFirstName,
      reviewerLastName,
      reviewerEmail,
      revieweeFirstName,
      revieweeLastName,
      revieweeTitle,
      companyName,
      title
    };

    const areFieldsEmpty = commonUtils.isAnyFalsy(newData);
    if (areFieldsEmpty) return true;

    const doesReportExist = Boolean(reportId);

    newData.cycleId = cycleId;

    if (newData.cycleId === undefined) {
      return true;
    }

    if (!doesReportExist) return false;

    const oldData = {
      reviewerFirstName: prevData.reviewerFirstName,
      reviewerLastName: prevData.reviewerLastName,
      reviewerEmail: prevData.reviewerEmail,
      revieweeFirstName: prevData.revieweeFirstName,
      revieweeLastName: prevData.revieweeLastName,
      revieweeTitle: prevData.revieweeTitle,
      companyName: prevData.companyName,
      title: prevData.title,
      cycleId: prevData.cycleId
    };

    const oldReviewDate = prevData.reviewDate;
    const newReviewDate = reviewDate;

    const canSubmitForm = !commonUtils.isSame(oldData, newData)
      || !commonDateUtils.isSameDay([oldReviewDate, newReviewDate]);

    return !canSubmitForm;
  };

  let cyclesData = [];
  if (isCompanySpecific) {
    const [{ cycles }] = dataset;
    cyclesData = cycles;
  }

  let cycleOptions = [];

  // TODO: disable No Cycle for Joele Frank (Temporary solution)
  if (!isJoeleFrank) {
    cycleOptions = [
      {
        id: null,
        label: 'No Cycle'
      }
    ];
  }

  cycleOptions = [
    ...cycleOptions,
    ...cyclesData
      .sort((a, b) => {
        const { createdDate: aDate } = a;
        const { createdDate: bDate } = b;

        if (aDate < bDate) return 1;
        if (aDate > bDate) return -1;
        return 0;
      })
      .map((cycle) => ({
        id: cycle.id,
        label: cycle.label
      }))
  ];

  const shouldBreakPageTitle = window.innerWidth <= 1612;

  const isSelectReviewerDisabled = isEditPage || !isAdmin;
  const isSelectRevieweeDisabled = isEditPage || !reviewerId;

  return (
    <BuilderContainer
      loading={isCreateBuilderReportLoading || isUpdateBuilderReportLoading}
    >
      <>
        {existingReportEditToken && (
          <AlertModal
            isOpen={Boolean(existingReportEditToken)}
            customIcon={(
              <div className='p-2 border rounded-md border-[#E4E7EC]'>
                <Flag2SVG />
              </div>
            )}
            close={() => {
              setExistingReportEditToken(null);
            }}
            onAction={() => {
              const encodedBuilderId = appUtils.encodeURIString(
                existingReportEditToken
              );
              navigate(`/builder/${encodedBuilderId}/edit`);
              setExistingReportEditToken(null);
              scrollToChooseReviewTemplate();
            }}
            title="You've already started this review"
            content={(
              <>
                <p className='m-0 text-sm leading-5 mb-3'>
                  It seems that you've already started drafting a review for
                  this team member in this review cycle.
                </p>
                <p className='m-0 text-sm leading-5'>
                  We'll take you there so you can finish it up!
                </p>
              </>
            )}
            type='success'
            actionText='Continue Review'
          />
        )}
        <h1 className='text-3xl'>
          Self-Evaluation and
          {shouldBreakPageTitle ? <br /> : ' '}
          Performance Review Builder
        </h1>
        <p className='text-gray-500 text-lg mb-5'>
          Follow the steps to generate a performance review for yourself or a
          team member.
        </p>
        <h4 className='text-xl'>Review Info</h4>
        <p className='text-gray-500 mb-5'>
          Provide the team member’s information. Click save when you're done.
        </p>
        <div className='flex flex-col w-1/2'>
          <label className='mb-3 flex flex-col gap-2'>
            <span className='text-base font-bold'>Company Name</span>
            <input
              type='text'
              placeholder='ACME Inc.'
              className='bg-white rounded-lg border border-slate-300 h-10 w-full'
              disabled={isCompanySpecific}
              value={companyName}
              {...register('companyName', {
                required: {
                  value: true,
                  message: 'Please provide the company name.'
                }
              })}
            />
          </label>
          {isCompanySpecific ? (
            <div className='mb-3 flex flex-col gap-2'>
              <span className='text-base font-bold'>Review Cycle</span>
              <Select
                customVariantClasses='bg-white rounded-lg border border-slate-300 h-10'
                classes='w-full'
                scrollStyle='purple'
                options={cycleOptions}
                title={cycleLabel || 'Select a Review Cycle'}
                onChange={onChangeCycle}
              />
            </div>
          ) : null}
          <label className='mb-3 flex flex-col gap-2'>
            <span className='text-base font-bold'>Report Title</span>
            {isJoeleFrank || isPublicSchoolForum ? (
              <Select
                customVariantClasses='bg-white rounded-lg border border-slate-300 h-10'
                classes='w-full'
                scrollStyle='purple'
                options={reportTitleOptions}
                title={title || 'Select a Report Title'}
                onChange={(option) => setValue('title', option.label)}
                disabled
              />
            ) : null}
            {!isJoeleFrank && !isPublicSchoolForum ? (
              <input
                type='text'
                placeholder='Performance Review Report'
                className='bg-white rounded-lg border border-slate-300 h-10 w-full'
                value={title}
                {...register('title', {
                  required: {
                    value: true,
                    message: 'Please provide a title for the report.'
                  }
                })}
              />
            ) : null}
          </label>
          {isCompanySpecific ? (
            <div className='mb-3 flex flex-col gap-2'>
              <span className='text-base font-bold'>Reviewer</span>
              <Select
                customVariantClasses='bg-white rounded-lg border border-slate-300 h-10'
                classes='w-full'
                scrollStyle='purple'
                options={reviewerOptions}
                title={reviewerFullName || 'Select a Reviewer'}
                onChange={onChangeReviewer}
                showSearch
                onSearch={(value) => setReviewerSearchText(value)}
                onDropdownClose={() => setReviewerSearchText('')}
                disabled={isSelectReviewerDisabled}
              />
            </div>
          ) : (
            <>
              <label className='mb-3 flex flex-col gap-2'>
                <span className='text-base font-bold'>Reviewer First Name</span>
                <input
                  type='text'
                  placeholder='John'
                  className='bg-white rounded-lg border border-slate-300 h-10 w-full'
                  {...register('reviewerFirstName', {
                    required: {
                      value: true,
                      message: 'Please provide your first name.'
                    }
                  })}
                />
              </label>
              <label className='mb-3 flex flex-col gap-2'>
                <span className='text-base font-bold'>Reviewer Last Name</span>
                <input
                  type='text'
                  placeholder='Smith'
                  className='bg-white rounded-lg border border-slate-300 h-10 w-full'
                  {...register('reviewerLastName', {
                    required: {
                      value: true,
                      message: 'Please provide your last name.'
                    }
                  })}
                />
              </label>
            </>
          )}
          <label className='mb-2 flex flex-col gap-2'>
            <span className='text-base font-bold'>Reviewer Email</span>
            <input
              type='text'
              placeholder={isCompanySpecific ? 'Email' : 'John@example.com'}
              className='bg-white rounded-lg border border-slate-300 h-10 w-full'
              disabled={isCompanySpecific}
              value={reviewerEmail}
              {...register('reviewerEmail', {
                required: {
                  value: true,
                  message: 'Please provide your email.'
                }
              })}
            />
          </label>
          {reviewerEmail && isEmailInvalid ? (
            <span className='text-red-400'>Please provide a valid email.</span>
          ) : null}
          {isCompanySpecific ? (
            <div className='mb-3 flex flex-col gap-2'>
              <span className='text-base font-bold'>Reviewee</span>
              <Select
                customVariantClasses='bg-white rounded-lg border border-slate-300 h-10'
                classes='w-full'
                scrollStyle='purple'
                options={revieweeOptions}
                title={revieweeFullName || 'Select a Reviewee'}
                onChange={onChangeReviewee}
                disabled={isSelectRevieweeDisabled}
                showTitle={false}
                showSearch
                onSearch={(value) => setRevieweeSearchText(value)}
                onDropdownClose={() => setRevieweeSearchText('')}
              />
            </div>
          ) : (
            <>
              <label className='mb-3 flex flex-col gap-2'>
                <span className='text-base font-bold'>Reviewee First Name</span>
                <input
                  type='text'
                  placeholder='Jane'
                  className='bg-white rounded-lg border border-slate-300 h-10 w-full'
                  {...register('revieweeFirstName', {
                    required: {
                      value: true,
                      message: "Please provide the employee's first name."
                    }
                  })}
                />
              </label>
              <label className='mb-3 flex flex-col gap-2'>
                <span className='text-base font-bold'>Reviewee Last Name</span>
                <input
                  type='text'
                  placeholder='Smith'
                  className='bg-white rounded-lg border border-slate-300 h-10 w-full'
                  {...register('revieweeLastName', {
                    required: {
                      value: true,
                      message: "Please provide the employee's last name."
                    }
                  })}
                />
              </label>
            </>
          )}
          <label className='mb-3 flex flex-col gap-2'>
            <span className='text-base font-bold'>Reviewee Title</span>
            <input
              type='text'
              placeholder={
                isCompanySpecific ? 'Job Title' : 'Software Engineer'
              }
              className='bg-white rounded-lg border border-slate-300 h-10 w-full'
              disabled={isCompanySpecific}
              value={revieweeTitle}
              {...register('revieweeTitle', {
                required: {
                  value: true,
                  message: 'Please provide a job title.'
                }
              })}
            />
          </label>
          <div className='h-16 mb-10 flex flex-col gap-2'>
            <span className='text-base font-bold'>Review Date</span>
            <Controller
              name='reviewDate'
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                const curr = field.value ? new Date(field.value) : null;
                return (
                  <PickDate
                    containerClasses='w-full'
                    classes='float-none rounded-lg w-full bg-white border border-slate-300 h-10'
                    placeholder='Review Date'
                    date={curr}
                    onSelect={field.onChange}
                    {...field}
                  />
                );
              }}
            />
          </div>
        </div>
        <div className='flex justify-start gap-4'>
          <Button
            type='button'
            disabled={getIsButtonDisabled()}
            variant='custom'
            classes='w-[7rem] bg-[#0F172A] text-white text-base px-4 py-2 rounded-md transition-colors duration-300'
            onClick={save}
          >
            Save
          </Button>
          {mode === 'test' ? (
            <Button
              type='button'
              variant='custom'
              classes='w-[7rem] bg-red text-white text-base px-4 py-2 rounded-md transition-colors duration-300'
              onClick={autofillInfo}
            >
              Autofill
            </Button>
          ) : null}
        </div>
      </>
    </BuilderContainer>
  );
};

export default ReviewInfo;
