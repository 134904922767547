import React from 'react';
import { Base } from 'src/components';
import LineChart from 'src/components/LineChart/LineChart';
import { useMyProfile } from 'src/pagesDashboard/Dash/queries';
import STYLE from 'src/constants/style';

const UserChart = () => {
  const {
    userScore,
    isFetching: isFetchingMyProfile,
    isError: isErrorMyProfile
  } = useMyProfile();

  const isFetching = isFetchingMyProfile;
  const isError = isErrorMyProfile;
  const isReady = userScore
    && userScore.charts
    && userScore.charts[0]
    && !isFetching
    && !isError;

  if (!isReady) {
    return <p />;
  }
  const myChart = userScore.charts[0];

  return (
    <Base classes={STYLE.CONTAINER_WHITE}>
      <h5 className='mb-5 text-black focus:outline-none text-xl'>
        Performance Trend
      </h5>
      {myChart ? (
        <LineChart
          data={myChart.data}
          options={myChart.options}
          classes='my-chart-wrap'
        />
      ) : null}
    </Base>
  );
};

export default UserChart;
