import { useQuery, useMutation } from 'react-query';
import qs from 'qs';
import api from 'src/services/api';
import { get, isEmpty } from 'lodash';
import buildParams from 'src/queries/utils/buildParams';
import getReviewFilters from 'src/queries/utils/getReviewFilters';

export const QUERY_KEYS = {
  REVIEWS: 'reviews',
  TEAM_REVIEWS: 'teamReviews',
  REVIEWS_DUE: 'reviewsDue'
};

export const placeholderReviewsResponse = {
  reviews: [],
  userData: {
    score: '-',
    reviewsCompleted: '-',
    feedbackReceived: '-'
  },
  teamData: {
    score: '-',
    max: '-',
    min: '-',
    reviews: '-'
  },
  categoriesData: [],
  pagination: {
    current: 1,
    size: 5,
    pages: 0,
    total: 0
  }
};

export const useReviewsDue = (params = {}, queryOptions = {}) => {
  const stringified = qs.stringify(params, { skipNulls: true });

  const { data, ...rest } = useQuery(
    [QUERY_KEYS.REVIEWS_DUE, stringified],
    () => api.get(`/reviews/reviews/due?${stringified}`).then((resp) => {
      if (!resp.success) {
        console.error(`GET /reviews/reviews/due?${stringified} error`);
        return {
          success: false
        };
      }
      return resp;
    }),
    {
      placeholderData: {
        reviews: []
      },
      ...queryOptions
    }
  );

  return {
    reviews: get(data, 'reviews', []),
    ...rest
  };
};

export const useReviews = ({
  userId,
  managerId,
  companyId,
  filters,
  options,
  queryOptions = {}
}) => {
  const queryKey = qs.stringify(
    {
      userId,
      managerId,
      companyId,
      filters: getReviewFilters(filters),
      options
    },
    { skipNulls: true }
  );

  return useQuery(
    [QUERY_KEYS.REVIEWS, ...queryKey.split('&')],
    async () => {
      const params = qs.stringify(
        {
          userId,
          managerId,
          companyId,
          filters,
          options
        },
        { skipNulls: true, arrayFormat: 'brackets' }
      );

      const resp = await api.get(`/reviews/reviews?${params}`);
      if (!resp || !resp.success) {
        console.error('GET /reviews/reviews error', resp);
        return {
          reviews: false
        };
      }
      return {
        reviews: resp.reviews,
        pagination: resp.pagination
      };
    },
    {
      placeholderData: {
        ...placeholderReviewsResponse
      },
      enabled: Boolean(
        !isEmpty(userId) || !isEmpty(companyId) || !isEmpty(managerId)
      ),
      ...queryOptions
    }
  );
};

export const useTeamReviews = ({
  teamId, range, start, end
}) => {
  const queryKey = buildParams({});
  return useQuery(
    [QUERY_KEYS.REVIEWS, QUERY_KEYS.TEAM_REVIEWS, ...queryKey],
    () => {
      const params = buildParams({
        teamId,
        range,
        start,
        end
      });
      return api.get(`/reviews/team?${params}`).then((resp) => {
        if (!resp || !resp.success) {
          console.error('GET /reviews/team/ error');
          return {
            ...placeholderReviewsResponse
          };
        }

        return {
          ...resp
        };
      });
    },
    {
      enabled: Boolean(teamId),
      placeholderData: { reviews: [] }
    }
  );
};

export const sendManualReview = () => useMutation((data) => api.post('/emailing/review/request', data));

export const submitDirectReviewQuery = () => useMutation((data) => api.post('/emailing/submitDirectReview', data), {
  throwOnError: true
});

export const submitParticipationReminder = () => useMutation((data) => api.post('/score/participation/reminder', data));

// Email Review queries:
export const getEmailReviewQuery = () => useMutation((data) => api.post('/emailing/review/email', data), {
  onError: (error) => {
    console.error('getEmailReviewQuery onError', error);
  }
});

export const submitEmailReviewQuery = () => useMutation(
  ({
    id, comments, shareComment, companyid, answer, score, isNA
  }) => api.post('/emailing/review/email/comments', {
    id,
    comments,
    shareComment,
    companyid,
    answer,
    score,
    isNA
  }),
  {
    throwOnError: true
  }
);

export const patchReviewQuery = () => useMutation((data) => api.patch('/reviews/review', data));

export const useReviewV2 = (id, { include } = {}, queryOptions = {}) => {
  const options = { include };
  const stringified = qs.stringify({ options }, { skipNulls: true });

  const { data, ...rest } = useQuery(
    [QUERY_KEYS.REVIEWS, id, options, queryOptions],
    () => api.get(`/reviews/${id}?${stringified}`).then((resp) => {
      if (!resp.success) {
        console.error(`GET /reviews/${id}?${stringified} error`);
        return {
          success: false
        };
      }
      return resp;
    }),
    {
      enabled: Boolean(id),
      ...queryOptions
    }
  );

  return {
    data: get(data, 'data', {}),
    meta: get(data, 'meta', {}),
    success: get(data, 'success'),
    ...rest
  };
};

export const useReviewsV2 = (
  {
    ids,
    reviewers,
    reviewees,
    notReviewees,
    revieweeGroup,
    status,
    visibility,
    notVisibility,
    anonymous,
    reviewedDate,
    roles,
    categories,
    scheduledDate,
    commentsLength
  } = {},
  {
    page, sort, include, projection
  } = {},
  queryOptions = {}
) => {
  const filters = {
    ids,
    reviewers,
    reviewees,
    notReviewees,
    revieweeGroup,
    status,
    visibility,
    notVisibility,
    anonymous,
    reviewedDate,
    roles,
    categories,
    scheduledDate,
    commentsLength
  };
  const options = {
    page,
    sort,
    include,
    projection
  };
  const stringified = qs.stringify({ filters, options }, { skipNulls: true });

  const { data, ...rest } = useQuery(
    ['reviews', filters, options],
    () => api.get(`/reviews?${stringified}`).then((resp) => {
      if (!resp.success) {
        console.error(`GET /reviews?${stringified} error`);
        return {
          success: false
        };
      }
      return resp;
    }),
    queryOptions
  );

  return {
    data: get(data, 'data', []),
    meta: get(data, 'meta', {
      page: {
        number: null,
        size: null,
        totalItems: null,
        totalPages: null
      }
    }),
    success: get(data, 'success'),
    ...rest
  };
};
