import React from 'react';
import COMMON_CONSTANTS from 'common/commonConstants';

const { PUBLIC_BUCKET_URL } = COMMON_CONSTANTS;

const WorkStoryLogo = () => (
  <img
    className='w-48 inline-block'
    alt='WorkStory'
    src={`${PUBLIC_BUCKET_URL}/3.0/logos/ws-logo-2x.png`}
  />
);

export default WorkStoryLogo;
