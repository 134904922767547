import React, { Component } from 'react';
import { connect } from 'react-redux';
import './ModalsContainer.scss';

class ModalsContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      app: { showIntroModal },
      dispatch
    } = this.props;
    return <div className='modals-container' />;
  }
}

export const mapStateToProps = (state) => ({
  app: state.appReducer
});

export const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalsContainer);
