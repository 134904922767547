import React, { useState } from 'react';
import { get } from 'lodash';
import { Modal } from 'src/components';
import TopScoreBox from 'src/componentsAdvanced/TopScoreBox/TopScoreBox';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useUserProfile } from 'src/pagesDashboard/UserProfile/queries';
import commonCompanyUtils from 'common/commonCompanyUtils';

const { DIRECT_REVIEW_PERMISSIONS } = COMMON_CONSTANTS;

const TopScores = ({ userId }) => {
  const [local, setLocal] = useState({ showModal: undefined });
  const {
    data: { myTreeRow }
  } = useTree();
  const { data: company } = useCompany();
  const { userScore, isLoading } = useUserProfile(userId);
  const scoreLabels = get(company, 'settings.scoreLabels');

  const score = userScore && userScore.overview && userScore.overview.score !== undefined
    ? userScore.overview.score
    : '-';
  const reviewsReceived = userScore && userScore.overview && userScore.overview.reviews !== undefined
    ? userScore.overview.reviews
    : '-';
  const completionAvg = userScore?.asReviewer?.completionAverage
    ? `${userScore.asReviewer.completionAverage}%`
    : '-';
  // const reviewsReceived = userScore?.overview?.completionAverage ? `${userScore.asReviewer.completionAverage}%` : '-';
  const showModal = (name) => {
    setLocal({ showModal: name });
  };

  if (!scoreLabels || !userScore) {
    return null;
  }

  return (
    <div className='grid sm:grid-cols-2 lg:grid-cols-4 gap-3'>
      {local?.showModal === 'completion' ? (
        <Modal>
          <p>hi</p>
          <button onClick={() => showModal(false)}>exit</button>
        </Modal>
      ) : null}
      <TopScoreBox
        subtitle={userScore?.overview?.label || '-'}
        loading={isLoading}
        title='Score'
        value={userScore?.overview?.score || '-'}
        tooltip='Overall score'
      />
      <TopScoreBox
        loading={isLoading}
        title='Reviews Received'
        value={reviewsReceived}
        tooltip='Reviews received'
      />
      {/* <TopScoreBox onClick={() => { console.log('cliock'); showModal('completion') }} loading={isLoading} isPurple={false} title='Completion Pct.' value={completionAvg} tooltip='Completion Average' /> */}
      <TopScoreBox
        loading={isLoading}
        isPurple={false}
        title='Completion Pct.'
        value={completionAvg}
        tooltip='Completion Average'
      />
    </div>
  );
};

export default TopScores;
