import React from 'react';
import commonTreeUtils from 'common/commonTreeUtils';
import { Circle, Base } from 'src/components';
import { useCompanyFeedbackCount } from 'src/queries/feedback';
import { useTree } from 'src/queries/tree';
import COMMON_CONSTANTS from 'common/commonConstants';

const { SHARE_REVIEW_WITH } = COMMON_CONSTANTS;

const MostFeedback = ({ title, dateRangeSelected, groupBy }) => {
  const {
    data,
    isFetching: isFetchingCompanyFeedbackCount,
    isError: isErrorCompanyFeedbackCount
  } = useCompanyFeedbackCount({
    groupBy,
    start: dateRangeSelected.start,
    end: dateRangeSelected.end,
    includeArchived: true,
    visible: SHARE_REVIEW_WITH.OPEN_TO_EVERYONE,
    options: { size: 10, sort: 'desc', joinReviews: true }
  });

  const {
    data: treeResponse,
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const isFetching = isFetchingCompanyFeedbackCount || isFetchingTree;
  const isError = isErrorCompanyFeedbackCount || isErrorTree;
  const isReady = treeResponse && data && !isFetching && !isError;
  if (!isReady) return null;

  const { tree, deleted } = treeResponse;

  return (
    <Base classes='shadow-none' loading={isFetching}>
      <div className='flex flex-col min-h-30rem bg-white px-5 pt-4 pb-2 rounded shadow'>
        <div>
          <div className='flex'>
            <p className='text-xl font-medium mb-4'>{title}</p>
          </div>
          <div className='flex flex-col'>
            {data && data.length
              ? data.map((rev, index) => {
                let user = commonTreeUtils.findNodeById(tree, rev.id);
                if (!user) {
                  user = deleted.find((d) => d.id === rev.id);
                }
                return (
                  <div
                    className='flex items-center'
                    key={`${index}-${rev.id}`}
                  >
                    <Circle imageUrl={user?.imageUrl} size='xsm' />
                    <p className='ml-2 mb-2 font-bold m-0 min-w-12rem'>
                      {user?.name}
                    </p>
                    <p className='ml-auto mb-2 text-xl'>{rev.count}</p>
                  </div>
                );
              })
              : !isFetching && (
              <p className='text-center my-auto'>
                No feedback during this time
              </p>
              )}
          </div>
        </div>
      </div>
    </Base>
  );
};

export default MostFeedback;
