import { useQuery, useMutation } from 'react-query';
import qs from 'qs';
import appUtils from 'src/components/appUtils';
import commonTreeUtils from 'common/commonTreeUtils';
import api from 'src/services/api';
import buildParams from 'src/queries/utils/buildParams';
import { useFindRecord } from 'src/queries/api';
import { get } from 'lodash';

export const QUERY_KEYS = {
  REVIEWS: 'reviews',
  TEAM_REVIEWS: 'teamReviews',
  GOAL: 'goal',
  GOALS: 'goals',
  GOAL_STATISTICS: 'goalsStatistics'
};

const goalDataQueryFn = ({
  assignee, companyid, goalId, answerId
}) => () => {
  const params = buildParams({
    assignee,
    companyid,
    goalId,
    answerId
  });
  return api.get(`/emailing/goal/email?${params}`).then((resp) => {
    if (!resp.success) {
      console.error('GET /emailing/goal/email error', resp);
      return null;
    }
    const data = {
      goal: resp.goal,
      message: resp.message,
      result: resp.success,
      assigneeName: resp.assigneeName,
      assigneeImage: resp.assigneeImage,
      assigneeTitle: resp.assigneeTitle
    };
    return data;
  });
};

export const useGoal = (id, { include } = {}) => {
  const filters = {};
  const options = { include };
  const stringified = qs.stringify({ filters, options }, { skipNulls: true });

  const { data, ...rest } = useQuery(
    [QUERY_KEYS.GOALS, filters, options, id],
    () => api.get(`/goals/${id}?${stringified}`).then((resp) => {
      if (!resp.success) {
        console.error(`GET /goals/${id}?${stringified} error`);
        return {
          success: false
        };
      }
      return resp;
    }),
    {
      enabled: Boolean(id)
    }
  );

  return {
    data: get(data, 'data', {}),
    meta: get(data, 'meta', {}),
    success: get(data, 'success'),
    ...rest
  };
};

export const useGoals = (
  {
    ids,
    parentId,
    isTopLevel,
    assignees,
    status,
    createdDate,
    lastUpdated,
    teamId,
    groupId
  } = {},
  {
    page, sort, include, search
  } = {},
  isEnabled = true
) => {
  const filters = {
    ids,
    parentId,
    isTopLevel,
    assignees,
    status,
    createdDate,
    lastUpdated,
    teamId,
    groupId
  };
  const options = {
    page,
    sort,
    include,
    search
  };
  const stringified = qs.stringify({ filters, options }, { skipNulls: true });

  const { data, ...rest } = useQuery(
    [QUERY_KEYS.GOALS, filters, options, ...(ids || [])],
    () => api.get(`/goals?${stringified}`).then((resp) => {
      if (!resp.success) {
        console.error(`GET /goals?${stringified} error`);
        return {
          success: false
        };
      }
      return resp;
    }),
    {
      enabled: Boolean(isEnabled)
    }
  );

  return {
    data: get(data, 'data', []),
    meta: get(data, 'meta', {
      page: {
        number: null,
        size: null,
        totalItems: null,
        totalPages: null
      }
    }),
    success: get(data, 'success'),
    ...rest
  };
};

export const useGoalsLookup = (
  { rootId, treeId } = {},
  { lookup, search } = {},
  isEnabled = true
) => {
  const filters = {
    rootId,
    treeId
  };
  const options = {
    lookup,
    search
  };
  const stringified = qs.stringify({ filters, options }, { skipNulls: true });

  const { data, ...rest } = useQuery(
    [QUERY_KEYS.GOALS, filters, options],
    () => api.get(`/goals/lookup?${stringified}`).then((resp) => {
      if (!resp.success) {
        console.error(`GET /goals/lookup?${stringified} error`);
        return {
          success: false
        };
      }
      return resp;
    }),
    {
      enabled: Boolean(isEnabled)
    }
  );

  return {
    data: get(data, 'data', {
      children: [],
      parents: []
    }),
    meta: get(data, 'meta', {}),
    success: get(data, 'success'),
    ...rest
  };
};

export const useSubgoalsV2 = ({ goalId } = {}, queryOptions = {}) => useGoals(
  {
    parentId: goalId
  },
  {
    page: {
      size: Number.MAX_SAFE_INTEGER
    }
  },
  {
    enabled: Boolean(goalId),
    ...queryOptions
  }
);

export const useSubgoals = () => useMutation(
  (goalId) => api.get(`/goals/subgoals/${goalId}`)
  // {
  //   mutationKey: ['goals', 'subgoals', goalId]
  // }
);

export const usePopulateGoals = () => {
  const { mutateAsync: fetchSubgoals, isLoading } = useSubgoals();

  const populateGoals = async (goals, goalId) => {
    const goalTree = { id: 9, children: goals };
    const goalObj = commonTreeUtils.findNodeById(goalTree, goalId);
    goalObj.isOpen = !goalObj.isOpen;
    const { data: subgoals } = await fetchSubgoals(goalId);
    goalObj.children = subgoals || [];
  };

  return {
    isLoading,
    populateGoals
  };
};

export const updateCompanyQuery = () => useMutation((data) => api.patch('/company/settings', { data }));

export const useGoalUpdate = (goalId) => useMutation((data) => api.patch(`/goals/${goalId}`, { data }), {
  throwOnError: true
});

export const useUserGoals = (assignee) => useFindRecord({
  endpoint: `/goals/user/${assignee}`,
  options: {
    enabled: Boolean(assignee)
  },
  queryKeys: ['goals', 'goal', assignee]
});

export const useSearchGoals = (filters = {}, options = {}) => useQuery([QUERY_KEYS.GOALS, filters, options], () => {
  const stringified = qs.stringify({ filters, options }, { skipNulls: true });
  return api.get(`/goals/search?${stringified}`).then((resp) => {
    if (!resp.success) {
      console.error('GET /goals/search error', resp);
      return null;
    }
    return resp;
  });
});

export const useGoalData = ({
  assignee, companyid, goalId, answerId
}) => useQuery(
  [QUERY_KEYS.GOALS, assignee, companyid, goalId, answerId],
  goalDataQueryFn({
    assignee,
    companyid,
    goalId,
    answerId
  }),
  {
    enabled: Boolean(assignee)
  }
);

export const submitEmailGoalCommentsQuery = () => useMutation(({
  assignee, companyid, goalId, updateData
}) => api.post('/emailing/goal/email/comments', {
  assignee,
  companyid,
  goalId,
  updateData
}));

export const remindLaterGoalEmailQuery = () => useMutation(({
  assignee, companyid, goalId, frequency
}) => api.post('/emailing/goal/email/remind-later', {
  assignee,
  companyid,
  goalId,
  frequency
}));

export const useGoalsStatistics = ({
  goalIds,
  parentId,
  isTopLevel,
  assignees,
  status,
  lastUpdated,
  teamId,
  groupId
} = {}) => {
  const filters = {
    goalIds,
    parentId,
    isTopLevel,
    assignees,
    status,
    lastUpdated,
    teamId,
    groupId
  };

  const stringified = qs.stringify({ filters }, { skipNulls: true });

  const { data, isFetching, isError } = useQuery(
    [QUERY_KEYS.GOAL_STATISTICS, filters, ...(goalIds || [])],
    () => api.get(`/goals/statistics?${stringified}`).then((resp) => {
      if (!resp.success) {
        console.error(`GET /goals/statistics?${stringified} error`);
        return {
          success: false
        };
      }
      return resp;
    })
  );

  return {
    data: get(data, 'data', []),
    success: get(data, 'success'),
    isFetching,
    isError
  };
};
