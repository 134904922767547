import React, { useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import QuestionMarkSVG from 'src/assets/svg/question-mark.svg';
import InvoiceSVG from 'src/assets/svg/invoice.svg';
import DoorSVG from 'src/assets/svg/door.svg';
import SmileSVG from 'src/assets/svg/smile.svg';
import SuperPersonSVG from 'src/assets/svg/super-person.svg';
import appUtils from 'src/components/appUtils';
import SidebarButton from 'src/containers/Sidebar/SidebarButton';
import SidebarNavButton from 'src/containers/Sidebar/SidebarNavButton';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';

const SidebarSupport = ({ routeCallback = () => {} }) => {
  const navigate = useNavigate();
  const { showSidebarSupport, showSidebarReferUs } = useFlags();
  const isSuperUser = appUtils.isSuperUser();

  const [isSupportExpanded, setIsSupportExpanded] = useState(false);
  const queryClient = useQueryClient();

  return (
    <div className='w-full flex flex-col'>
      {
        showSidebarSupport && (
          <>
            <SidebarButton
              text='Support'
              icon={<QuestionMarkSVG />}
              onClick={() => setIsSupportExpanded(!isSupportExpanded)}
              expanded={isSupportExpanded}
            />
            {isSupportExpanded ? (
              <>
                <SidebarNavButton
                  text='Contact Us'
                  onClick={() => window.open('https://home.workstory.team/contact', '_new')}
                  selected={false}
                />
                <SidebarNavButton
                  text='FAQs'
                  onClick={() => window.open('https://home.workstory.team/faq', '_new')}
                  selected={false}
                />
              </>
            ) : null}
          </>
        )
      }
      {/* <SidebarButton
        text='Billing'
        icon={<InvoiceSVG />}
        onClick={() => navigate('/dashboard/billing')}
      /> */}
      {
        showSidebarReferUs && (
          <SidebarButton
            text='Refer Us'
            icon={<SmileSVG />}
            onClick={() => window.open('https://home.workstory.team/referral', '_new')}
          />
        )
      }
      <SidebarButton
        text='Log Out'
        icon={<DoorSVG />}
        onClick={() => appUtils.logUserOut('/login')}
      />
      {isSuperUser && (
        <SidebarButton
          text='Super User'
          icon={<SuperPersonSVG />}
          onClick={() => {
            navigate('/dashboard/admin');
            routeCallback();
          }}
        />
      )}
    </div>
  );
};

export default SidebarSupport;
