import React from 'react';
import Question from 'src/pages/PerformanceBuilder/BuilderComponents/Question';
import CategoryFeedback from 'src/pages/PerformanceBuilder/BuilderComponents/CategoryFeedback';

const Category = ({ category, reviewMode, nextCategoryId }) => {
  const { name, questions } = category;
  return (
    <>
      {reviewMode === 'questions' ? (
        <>
          <h3 className='text-lg font-bold text-purple'>{name}</h3>
          {questions.map((question, index) => (
            <div key={index}>
              <Question
                category={category}
                question={question}
                nextQuestionId={questions[index + 1]?.questionId || null}
              />
            </div>
          ))}
        </>
      ) : null}
      {reviewMode === 'categories' ? (
        <CategoryFeedback category={category} nextCategoryId={nextCategoryId} />
      ) : null}
    </>
  );
};

export default Category;
