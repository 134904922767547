import React, { useEffect, useContext } from 'react';
import { Base, HistoricReviews } from 'src/components';
import { DashTeamContext } from 'src/pagesDashboard/DashTeam/context/DashTeamProvider';
import STYLE from 'src/constants/style';
import { updateData } from 'src/pagesDashboard/DashTeam/context/actions';
import TopScores from 'src/containers/DashTeam/TopScores/TopScores';
import TeamChart from 'src/containers/DashTeam/TeamChart/TeamChart';
import TeamList from 'src/containers/DashTeam/TeamList/TeamList';
import Categories from 'src/containers/DashTeam/Categories/Categories';
import { useTree } from 'src/queries/tree';
import commonTreeUtils from 'common/commonTreeUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import FilterView from 'src/containers/DashTeam/FilterView/FilterView';
import { useTeamProfile } from 'src/pagesDashboard/DashTeam/queries';
import FilterByDate from 'src/containers/UserProfile/FilterView/FilterByDate';
import ParticipationStats from 'src/containers/CompanyDash/Stats/ParticipationStats';
import HeaderV2 from 'src/containers/Header/HeaderV2';
import { useAccount } from 'src/queries/account';
import { get } from 'lodash';

const { ACCESS } = COMMON_CONSTANTS;

const DashTeam = () => {
  const { context, dispatch, updateContext } = useContext(DashTeamContext);
  const {
    data: account,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount('me');
  const {
    data: { tree, myTreeRow, managerList },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    teamReviews,
    isFetching: isFetchingTeamReviews,
    isError: isErrorTeamReviews,
    pagination: TeamReviewsPagination
  } = useTeamProfile();

  const isFetching = isFetchingAccount || isFetchingTree;
  const isError = isErrorAccount || isErrorTeamReviews || isErrorTree;
  const isReady = get(tree, 'id') && get(account, '_id') && !isFetching && !isError;

  useEffect(() => {
    if (managerList.length) {
      if (myTreeRow.children.length) {
        dispatch(updateData({ managerId: myTreeRow.id }));
      } else if (myTreeRow.access === ACCESS.ADMIN) {
        dispatch(updateData({ managerId: tree.id }));
      }
    }
  }, [managerList]);

  if (!managerList.length) {
    return null;
  }

  const { managerId } = context;

  let noEmployees = false;
  if (managerId) {
    const userGroups = commonTreeUtils.getUserGroupsForUser(tree, managerId);
    noEmployees = userGroups
      && userGroups.employees
      && !userGroups.employees.filter((id) => id !== myTreeRow.id).length;
  }

  const selectHistoricReviewsPage = (page) => {
    updateContext({
      teamHistoricReviews: {
        ...context.teamHistoricReviews,
        page: parseInt(page, 10)
      }
    });
  };

  if (!isReady) {
    return null;
  }

  return (
    <>
      <HeaderV2 overtitle='Dashboards' title='Team' />
      <Base classes={STYLE.BASE}>
        {noEmployees ? (
          <div>
            <p className='p-4 text-center'>
              Data hidden due to visibility rules.
            </p>
          </div>
        ) : (
          <div>
            <div className='flex gap-2 mt-2'>
              <FilterView mode='dashTeam' />
              <FilterByDate
                dateRangeSelected={context.range}
                onSelectDateRange={(value, start, end) => {
                  if (!value) {
                    return;
                  }
                  if (value === 'custom') dispatch(updateData({ range: { value, start, end } }));
                  else {
                    dispatch(
                      updateData({ range: { value, start, end: undefined } })
                    );
                  }
                }}
              />
            </div>
            <TopScores />
            <div className='flex justify-evenly'>
              <div className='w-1/2 pr-2'>
                <TeamChart />
              </div>
              <div className='w-1/2 pl-2'>
                <TeamList />
              </div>
            </div>
            <Categories />
            <ParticipationStats
              range={context.range}
              managerId={context.managerId}
              categoryId={context.category}
              accountIds={context.userIds}
            />
            <HistoricReviews
              reviews={teamReviews}
              isLoading={isFetchingTeamReviews}
              showRequestReview={false}
              showVisibleTooltip={false}
              title='Team Historic Reviews'
              emptyPlaceholder='Team has not received reviews.'
              pagination={{
                totalPages: TeamReviewsPagination.pages,
                currentPage: TeamReviewsPagination.current,
                selectPage: selectHistoricReviewsPage
              }}
            />
          </div>
        )}
      </Base>
    </>
  );
};

export default DashTeam;
