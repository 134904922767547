import { get, isEmpty } from 'lodash';
import React from 'react';

const PreviewCategory = ({ reviews, category }) => {
  const { name } = category;
  return (
    <>
      <h3 className='text-lg font-bold text-purple p-0 my-2'>{name}</h3>
      {reviews.map((review, index) => {
        const { answer, comments, label } = review;

        const answerLabel = get(answer, 'label');
        const hasAnswerLabel = typeof answerLabel === 'string';
        return (
          <div
            className='w-full flex py-1 flex-col justify-center text-left border border-slate-300 px-6 bg-gray-200 min-h-16 my-2'
            key={index}
          >
            <span className='text-sm'>
              {label}
              {hasAnswerLabel ? (
                <>
                  <strong>{answer.label}</strong>
                  {' '}
                  {!isEmpty(comments) ? '- ' : null}
                </>
              ) : null}
              {comments}
            </span>
          </div>
        );
      })}
    </>
  );
};

export default PreviewCategory;
