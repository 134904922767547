import React from 'react';
import { HistoricReviews } from 'src/components/index';
import { useActivityTab } from 'src/containers/UserProfile/Activity/queries';

const HistoricReviewsForReviewer = ({ userId }) => {
  const {
    historicReviews: {
      data: historicReviews,
      isFetching: isFetchingHistoricReviews,
      isError: isErrorHistoricReviews,
      page: { setPage, totalPages, number }
    }
  } = useActivityTab(userId);

  const isFetching = isFetchingHistoricReviews;
  const isError = isErrorHistoricReviews;
  const isReady = historicReviews && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  return (
    <HistoricReviews
      showVisibleTooltip
      emptyPlaceholder='No reviews given'
      reviews={historicReviews}
      isLoading={isFetchingHistoricReviews}
      userId={userId}
      showRequestReview={false}
      pagination={{
        totalPages,
        currentPage: number,
        selectPage: setPage
      }}
    />
  );
};

export default HistoricReviewsForReviewer;
