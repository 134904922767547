import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, toast, appUtils } from 'src/components/';
import OnboardingBase from 'src/pagesDashboard/Onboarding/OnboardingBase';
import MSTeamsIconSVG from 'src/assets/svg/msteams-icon.svg';

const OnboardingMSTeams = () => {
  const navigate = useNavigate();
  const isLoggedIn = appUtils.isLoggedIn();

  if (!isLoggedIn) {
    toast.show('Please login to continue');
    return navigate(appUtils.getBaseUrl());
  }

  return (
    <OnboardingBase>
      <>
        <div className='w-full text-left'>
          <MSTeamsIconSVG className='w-12 h-12 m-3' />
          <p className='text-xl font-bold m-0 text-black'>
            Microsoft Teams Set Up
          </p>
        </div>
        <div className='w-full text-left'>
          <div className='text-left m-0 border rounded-md p-3'>
            <p className='font-bold'>Instructions:</p>
            <ul className='m-0'>
              <li className='mb-0'>
                1. In Microsoft Teams, open up the Apps menu
              </li>
              <li className='mb-0'>2. Search for WorkStory and add the app</li>
              <li className='mb-0'>
                3. Send a "Hello" message and click the authenticate button in
                the response
              </li>
            </ul>
          </div>
        </div>
        <div className='flex justify-between gap-4 w-full'>
          <Button
            variant='empty-with-border'
            classes='!rounded-md !w-1/2 !h-11'
            onClick={() => navigate(`/dashboard/onboarding/comms`)}
          >
            Previous
          </Button>
          <Button
            variant='purple'
            classes='!rounded-md !w-1/2 !h-11'
            onClick={() => navigate(`/dashboard/onboarding/complete`)}
          >
            Continue
          </Button>
        </div>
      </>
    </OnboardingBase>
  );
};

export default OnboardingMSTeams;
