import React, { useContext } from 'react';
import appUtils from 'src/components/appUtils';
import { PerformanceBuilderContext } from 'src/pages/PerformanceBuilder/PerformanceBuilderContext';
import COMMON_CONSTANTS from 'common/commonConstants';
import { Flyout, SignUp } from 'src/componentsTailwind';
import { useForm } from 'react-hook-form';
import { useAccountLogin, useAccountSignUp } from 'src/queries/account';
import { get } from 'lodash';
import { useUpdateBuilderReport } from 'src/queries/builder';

const { LOGIN_TYPES, APP_MODES } = COMMON_CONSTANTS;

const SignFlyout = (props = {}) => {
  const { setIsOpen } = props;
  const {
    data: { prevDataRef, editToken }
  } = useContext(PerformanceBuilderContext);

  const initialValues = {};
  const prevData = get(prevDataRef, 'current');
  if (prevData) {
    const {
      companyName, reviewerFirstName, reviewerLastName, reviewerEmail
    } = prevData;
    if (companyName) initialValues.companyName = String(companyName);
    if (reviewerEmail) initialValues.email = String(reviewerEmail);
    if (reviewerFirstName) initialValues.firstName = String(reviewerFirstName);
    if (reviewerLastName) initialValues.lastName = String(reviewerLastName);
  }

  const form = useForm({
    defaultValues: {
      email: initialValues.email || '',
      password: ''
    }
  });
  const { watch } = form;
  const { email, password } = watch();

  const { login, isLoading: isLoadingLogin } = useAccountLogin({
    redir: '/builder/new?companyid=',
    callback: () => setIsOpen(false)
  });
  const onLogin = () => {
    login({
      data: {
        email,
        password,
        loginType: LOGIN_TYPES.SPEEDBACK
      }
    });
  };

  const { signup, isLoading: isLoadingSignUp } = useAccountSignUp({
    callback: () => setIsOpen(false)
  });
  const { update: updateReport, isLoading: isUpdateReportLoading } = useUpdateBuilderReport(editToken);
  const onSignUp = async () => {
    const { success, userData } = await signup({
      data: {
        email,
        password,
        loginType: LOGIN_TYPES.SPEEDBACK,
        timezoneOffset: appUtils.getTimezoneOffset(),
        appMode: APP_MODES.BUILDER,
        ...initialValues
      }
    });

    if (editToken && success) {
      await updateReport({ data: { companyid: get(userData, 'companyid') } });
    }
  };

  const isLoading = isLoadingLogin || isLoadingSignUp || isUpdateReportLoading;

  return (
    <Flyout {...props}>
      <SignUp
        defaultVariant='login'
        form={form}
        onLogin={onLogin}
        onSignUp={onSignUp}
        isLoading={isLoading}
        switchRoute={window.location.pathname}
      />
    </Flyout>
  );
};

export default SignFlyout;
