import React, { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'src/components/';
import { update } from 'src/queries/user';
import {
  useAccount,
  QUERY_KEYS as ACCOUNT_QUERY_KEYS
} from 'src/queries/account';
import { useQueryClient, useIsMutating } from 'react-query';

const UserNotConnected = () => {
  const {
    data: account,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount('me');

  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();

  const { mutateAsync: updateUser } = update();
  const isMutating = useIsMutating();

  const saveMsTeamsEmail = async (msteamsdata) => {
    const teamsData = atob(msteamsdata);

    const { msTeamsUserId, msTeamsEmail, ...msTeamsAuth } = JSON.parse(teamsData);

    const userUpdated = await updateUser({
      id: account._id,
      msTeamsEmail: msTeamsEmail.trim(),
      msTeamsAuth,
      msTeamsUserId
    });

    if (!userUpdated.success) {
      toast.error(userUpdated.error);
      return false;
    }

    toast.show('Microsoft Teams connected!');
    queryClient.invalidateQueries(ACCOUNT_QUERY_KEYS.ACCOUNT);
    queryClient.invalidateQueries(ACCOUNT_QUERY_KEYS.ACCOUNTS);
    setSearchParams();
    return userUpdated;
  };

  useEffect(() => {
    const msteamsdata = searchParams.get('msteamsdata');

    const connect = async () => {
      if (
        msteamsdata
        && !account.msTeamsAuth
        && !isFetchingAccount
        && !isMutating
      ) {
        await saveMsTeamsEmail(msteamsdata);
      }
    };

    connect();
  }, [searchParams]);

  const isFetching = isFetchingAccount;
  const isError = isErrorAccount;

  const isReady = account && account._id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  return (
    <>
      <p className='mb-4'>
        Receive review requests and other WorkStory notifications directly
        through Microsoft Teams.
      </p>

      <div className='pb-4'>
        <p className='mb-4'>Instructions:</p>
        <p className='mb-0'>1. In Microsoft Teams, open up the Apps menu</p>
        <p className='mb-0'>2. Search for WorkStory and add the app</p>
        <p className='mb-0'>
          3. Send a "Hello" message and click the authenticate button in the
          response
        </p>
      </div>
    </>
  );
};

export default UserNotConnected;
