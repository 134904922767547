import React, { useContext, useState } from 'react';
import { CompanyDashContext } from 'src/pagesDashboard/CompanyDash/context/Provider';
import { Select, toast } from 'src/components';
import { exportCompanyQuery } from 'src/pagesDashboard/CompanyDash/queries/queries';
import FilterByDate from 'src/containers/UserProfile/FilterView/FilterByDate';
import GeneralFilter from 'src/containers/UserProfile/FilterView/GeneralFilter';
import { useCompany } from 'src/queries/company';
import { useAccounts } from 'src/queries/account';
import sharedUtils from 'src/common/sharedUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import { isBoolean, uniqBy } from 'lodash';
import { COMPANY_EXPORT_VIEWS } from 'common/commonCompanyUtils';
import commonDateUtils from 'common/commonDateUtils';

const { REPORT_TYPE, REPORT_STATUS } = COMMON_CONSTANTS;

const getActionsOptions = () => [{ id: 'excel', label: 'Export Data' }];

const TopFilters = ({ show = [], individualClasses = 'w-60' }) => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const { context, updateContext } = useContext(CompanyDashContext);
  const {
    reviewers, reviewees, roleIds, reportIsSelf, reportStatus
  } = context;

  const currentReviewerIds = reviewers.map((reviewer) => reviewer.id);
  const currentRevieweeIds = reviewees.map((reviewee) => reviewee.id);

  const [reviewerSearchText, setReviewerSearchText] = useState('');
  const {
    data: reviewerAccounts,
    isFetching: isFetchingReviewerAccounts,
    isRefetching: isRefetchingReviewerAccounts,
    isError: isErrorReviewerAccounts
  } = useAccounts(
    {
      ...(currentReviewerIds.length ? { notIds: currentReviewerIds } : {})
    },
    {
      page: {
        size: 30
      },
      search: {
        enabled: true,
        field: 'name',
        value: reviewerSearchText
      },
      projection: ['name']
    },
    {
      keepPreviousData: true
    }
  );

  const [revieweeSearchText, setRevieweeSearchText] = useState('');
  const {
    data: revieweeAccounts,
    isFetching: isFetchingRevieweeAccounts,
    isRefetching: isRefetchingRevieweeAccounts,
    isError: isErrorRevieweeAccounts
  } = useAccounts(
    {
      ...(currentRevieweeIds.length ? { notIds: currentRevieweeIds } : {})
    },
    {
      page: {
        size: 30
      },
      search: {
        enabled: true,
        field: 'name',
        value: revieweeSearchText
      },
      projection: ['name']
    },
    {
      keepPreviousData: true
    }
  );

  const isInitialFetchingReviewerAccounts = isFetchingReviewerAccounts && !isRefetchingReviewerAccounts;
  const isInitialFetchingRevieweeAccounts = isFetchingRevieweeAccounts && !isRefetchingRevieweeAccounts;
  const isFetching = isFetchingCompany
    || isInitialFetchingReviewerAccounts
    || isInitialFetchingRevieweeAccounts;
  const isError = isErrorCompany || isErrorReviewerAccounts || isErrorRevieweeAccounts;
  const isReady = company && company.id && !isFetching && !isError;

  const { mutateAsync: exportData, isLoading: exportLoading } = exportCompanyQuery(COMPANY_EXPORT_VIEWS.ORGANIZATION);
  if (!isReady) return null;

  const actionsOptions = getActionsOptions();

  const exportFn = async () => {
    const res = await exportData('Organization');
    toast.show('Export successful');
  };

  const reviewerOptions = uniqBy(
    [
      ...reviewers.map((reviewer) => ({
        id: reviewer.id,
        label: reviewer.name,
        checked: true
      })),
      ...reviewerAccounts.map((account) => ({
        id: account._id,
        label: account.name,
        checked: reviewers.some((reviewer) => reviewer.id === account._id)
      }))
    ],
    'id'
  );

  const revieweeOptions = uniqBy(
    [
      ...reviewees.map((reviewee) => ({
        id: reviewee.id,
        label: reviewee.name,
        checked: true
      })),
      ...revieweeAccounts.map((account) => ({
        id: account._id,
        label: account.name,
        checked: reviewees.some((reviewee) => reviewee.id === account._id)
      }))
    ],
    'id'
  );

  const roleOptions = sharedUtils.getRoleOptions(company.questions, roleIds);

  const reportTypeOptions = [
    {
      id: null,
      label: 'All'
    },
    {
      id: REPORT_TYPE.REVIEW,
      label: REPORT_TYPE.REVIEW
    },
    {
      id: REPORT_TYPE.SELF_REVIEW,
      label: REPORT_TYPE.SELF_REVIEW
    }
  ];

  const reportStatusOptions = [
    {
      id: REPORT_STATUS.FINISHED,
      label: REPORT_STATUS.FINISHED,
      checked: reportStatus.includes(REPORT_STATUS.FINISHED)
    },
    {
      id: REPORT_STATUS.IN_PROGRESS,
      label: REPORT_STATUS.IN_PROGRESS,
      checked: reportStatus.includes(REPORT_STATUS.IN_PROGRESS)
    }
  ];

  const getTitle = (field) => {
    if (field === 'reviewers') {
      const count = reviewers.length;
      return count ? `${count} reviewers selected` : 'Filter by Reviewers';
    }
    if (field === 'reviewees') {
      const count = reviewees.length;
      return count ? `${count} reviewees selected` : 'Filter by Reviewees';
    }
    if (field === 'roles') {
      const count = roleIds.length;
      return count ? `${count} roles selected` : 'Filter by Roles';
    }
    if (field === 'report-types') {
      const isSelected = isBoolean(reportIsSelf);
      if (!isSelected) return 'Filter by Type';
      return reportIsSelf ? REPORT_TYPE.SELF_REVIEW : REPORT_TYPE.REVIEW;
    }
    if (field === 'report-status') {
      const count = reportStatus.length;
      return count ? `${count} status selected` : 'Filter by Status';
    }
  };

  return (
    <div className='flex justify-between gap-2 mt-1 mb-2'>
      {show.includes('actions') && (
        <div className='w-60'>
          <Select
            variant='shadow'
            title='Actions'
            options={actionsOptions}
            onChange={(option) => {
              if (option.id === 'excel') return exportFn();
            }}
          />
        </div>
      )}
      <div className='flex flex-wrap full:flex-nowrap justify-end gap-2 w-full'>
        {show.includes('reviewers') && (
          <GeneralFilter
            isLoading={isFetchingReviewerAccounts}
            multiselect
            wrapperClasses={individualClasses}
            title={getTitle('reviewers')}
            options={reviewerOptions}
            onSearch={(value) => setReviewerSearchText(value)}
            onDropdownClose={() => setReviewerSearchText('')}
            onChange={(option) => {
              updateContext({
                ...context,
                reviewers: option.checked
                  ? reviewers.filter((reviewer) => reviewer.id !== option.id)
                  : [...reviewers, { id: option.id, name: option.label }]
              });
            }}
            onClear={() => {
              updateContext({
                ...context,
                reviewers: []
              });
            }}
          />
        )}
        {show.includes('reviewees') && (
          <GeneralFilter
            isLoading={isFetchingRevieweeAccounts}
            multiselect
            wrapperClasses={individualClasses}
            title={getTitle('reviewees')}
            options={revieweeOptions}
            onSearch={(value) => setRevieweeSearchText(value)}
            onDropdownClose={() => setRevieweeSearchText('')}
            onChange={(option) => {
              updateContext({
                ...context,
                reviewees: option.checked
                  ? reviewees.filter((reviewer) => reviewer.id !== option.id)
                  : [...reviewees, { id: option.id, name: option.label }]
              });
            }}
            onClear={() => {
              updateContext({
                ...context,
                reviewees: []
              });
            }}
          />
        )}
        {show.includes('roles') && (
          <GeneralFilter
            multiselect
            wrapperClasses={individualClasses}
            title={getTitle('roles')}
            options={roleOptions}
            onChange={(option) => {
              updateContext({
                ...context,
                roleIds: option.checked
                  ? roleIds.filter((id) => id !== option.id)
                  : [...roleIds, option.id]
              });
            }}
            onClear={() => {
              updateContext({
                ...context,
                roleIds: []
              });
            }}
          />
        )}
        {show.includes('report-types') && (
          <GeneralFilter
            wrapperClasses={individualClasses}
            title={getTitle('report-types')}
            options={reportTypeOptions}
            onChange={(option) => {
              if (!option.id) {
                return updateContext({
                  ...context,
                  reportIsSelf: undefined
                });
              }

              updateContext({
                ...context,
                reportIsSelf: option.id === REPORT_TYPE.SELF_REVIEW
              });
            }}
          />
        )}
        {show.includes('report-status') && (
          <GeneralFilter
            multiselect
            wrapperClasses={individualClasses}
            title={getTitle('report-status')}
            options={reportStatusOptions}
            onChange={(option) => {
              updateContext({
                ...context,
                reportStatus: option.checked
                  ? reportStatus.filter((id) => id !== option.id)
                  : [...reportStatus, option.id]
              });
            }}
            onClear={() => {
              updateContext({
                ...context,
                reportStatus: []
              });
            }}
          />
        )}
        {show.includes('date') && (
          <FilterByDate
            classes={individualClasses}
            dateRangeSelected={context.range}
            onSelectDateRange={(value, start, end) => {
              const startValue = commonDateUtils.getFirstUnixOfDateFromUnix(
                start || commonDateUtils.dateToUnix('2020-01-01')
              );
              const endValue = commonDateUtils.getFirstUnixOfDateFromUnix(
                end || commonDateUtils.dateToUnix(new Date())
              );

              const isCustom = value === 'custom';
              updateContext({
                ...context,
                range: {
                  value,
                  start: startValue,
                  end: isCustom ? endValue : undefined
                }
              });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default TopFilters;
