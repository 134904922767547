import React, { useContext } from 'react';
import { Select } from 'src/components';
import { OrganizationContext } from 'src/pagesDashboard/Organization/context/OrganizationProvider';
import { useTree } from 'src/queries/tree';
import { updateData } from 'src/pagesDashboard/Organization/context/actions';
import commonTreeUtils from 'common/commonTreeUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import commonUtils from 'common/commonUtils';

const { PUBLIC_PROFILE_IMAGES_URL } = COMMON_CONSTANTS;

const TreeFilter = () => {
  const { state, dispatch } = useContext(OrganizationContext);
  const {
    data: { tree, treeList },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const isFetching = isFetchingTree;
  const isError = isErrorTree;
  const isReady = tree && tree.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const bucketName = commonUtils.getCloudStorageImagesBucketName(
    process.env.ENV
  );

  const selectOptions = treeList
    .filter((node) => node.children.length)
    .map((node) => ({
      id: node.id,
      label: node.name,
      image: node.imageUrl
        ? `${PUBLIC_PROFILE_IMAGES_URL}/${bucketName}/${node.imageUrl}`
        : null
    }));

  return (
    <div>
      <Select
        classes='w-72'
        options={selectOptions}
        variant='minimal'
        title={`Team ${state.displayTree.name}`}
        onChange={(option) => {
          const node = commonTreeUtils.findNodeById(tree, option.id);
          dispatch(updateData({ search: '', displayTree: node }));
        }}
      />
    </div>
  );
};

export default TreeFilter;
