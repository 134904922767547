import React, { useState } from 'react';
import { useTree } from 'src/queries/tree';
import commonTreeUtils from 'common/commonTreeUtils';
import commonGoalUtils from 'common/commonGoalUtils';
import PAGE_ROUTES from 'src/constants/pageRoutes';
import Spinner from 'src/assets/spinner.svg';
import CaretSVG from 'src/assets/svg/caret.svg';
import { useSubgoalsV2 } from 'src/queries/goal';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useAccount } from 'src/queries/account';
import { appUtils } from 'src/components/index';
import { get } from 'lodash';
import commonPermissions from 'common/commonPermissions';
import { useNavigate } from 'react-router-dom';

const { ACCESS } = COMMON_CONSTANTS;

const Goal = ({ goal, level = 0, redirectUrl = null }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: loggedAccount,
    isFetching: isFetchingLoggedAccount,
    isError: isErrorLoggedAccount
  } = useAccount('me');
  const {
    data: subgoals,
    isFetched: isFetchedSubgoals,
    isFetching: isFetchingSubgoals,
    isError: iseErrorSubgoals
  } = useSubgoalsV2(
    {
      goalId: goal._id
    },
    { enabled: isOpen, keepPreviousData: true }
  );

  const isFetching = isFetchingLoggedAccount || isFetchingTree;
  const isError = isErrorLoggedAccount || isErrorTree;
  const isReady = get(tree, 'id') && get(loggedAccount, '_id') && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const canManageAccount = commonPermissions.canManageAccounts(loggedAccount, [
    goal.assignee
  ]);
  const isAdmin = loggedAccount.access === ACCESS.ADMIN;
  const canManageGoal = canManageAccount
    || loggedAccount._id === goal.assignee
    || isAdmin
    || commonTreeUtils.isNodeAbove(tree, loggedAccount._id, goal.assignee)
    || (!goal.assignee && isAdmin);

  const titleClass = { marginLeft: `${level}rem` };
  const addGoalClass = { marginLeft: `${(level + 1) * 1.25}rem` };
  const assigneeNode = goal.assignee
    ? commonTreeUtils.findNodeById(tree, goal.assignee)
    : { name: 'Unassigned' };
  const assignee = assigneeNode || { name: 'Unassigned' };

  const goalProgress = commonGoalUtils.getGoalProgressLabel(goal);
  const goalStatus = commonGoalUtils.getGoalStatusLabel(
    goal,
    loggedAccount.companyid
  );

  return (
    <div>
      <div className='w-5/12 inline-block align-middle'>
        <div style={titleClass}>
          <div className='cursor-pointer flex items-center my-2'>
            <button
              onClick={() => setIsOpen(!isOpen)}
              className={`border-none focus:ring-0 transform ${
                isOpen ? 'rotate-90' : ''
              } inline-block max-w-1/5 focus:outline-none`}
            >
              <CaretSVG />
            </button>
            <button
              className='w-4/5 text-left inline-block text-lg whitespace-nowrap overflow-hidden focus:outline-none'
              onClick={() => setIsOpen(!isOpen)}
            >
              {goal.title}
            </button>
          </div>
        </div>
      </div>
      <div className='w-2/12 inline-block align-middle'>{assignee.name}</div>
      <div className='w-2/12 inline-block align-middle'>{goalProgress}</div>
      <div className='w-2/12 inline-block align-middle'>{goalStatus}</div>
      <div className='w-1/12 inline-block align-middle'>
        {canManageGoal ? (
          <>
            <button
              className='mr-4'
              onClick={() => navigate(
                `/dashboard/goal/edit/${goal.assignee}/${goal._id}?redir=${
                  redirectUrl || PAGE_ROUTES.ORG_GOALS_ROUTE
                }`
              )}
            >
              Edit
            </button>
            <button
              onClick={() => navigate(
                `/dashboard/goal/view/${goal.assignee}/${goal._id}?redir=${
                  redirectUrl || PAGE_ROUTES.ORG_GOALS_ROUTE
                }`
              )}
            >
              View
            </button>
          </>
        ) : null}
      </div>
      {isOpen ? (
        <div>
          {!isFetchedSubgoals ? (
            <div
              className='spinner-container-xs inline-block -my-5'
              style={titleClass}
            >
              <Spinner />
            </div>
          ) : null}
          {isFetchedSubgoals ? (
            <>
              {canManageGoal ? (
                <button
                  className='text-purple italic'
                  style={addGoalClass}
                  onClick={() => {
                    navigate(
                      `/dashboard/goal/new/${
                        goal.assignee || appUtils.getLoggedUserId()
                      }?parentId=${goal._id}`
                    );
                  }}
                >
                  Add subgoal
                </button>
              ) : null}
              {!canManageGoal && !subgoals.length ? (
                <div style={addGoalClass}>No subgoals</div>
              ) : null}
              {subgoals.length ? (
                <>
                  {subgoals.map((g, index) => (
                    <div key={index}>
                      <Goal level={level + 1} goal={g} />
                    </div>
                  ))}
                </>
              ) : null}
            </>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default Goal;
