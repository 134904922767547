import React from 'react';
import { XButton, Select } from 'src/components';
import commonQuestions from 'common/commonQuestions';
import { useCompany } from 'src/queries/company';
import { useUserProfile } from 'src/pagesDashboard/UserProfile/queries';
import { useMyProfile } from 'src/pagesDashboard/Dash/queries';

const getFilterRoleTitle = (roles, company) => {
  let filterTitle = 'Filter by roles';
  if (!company || !company.questions || !roles || !roles.length) {
    return filterTitle;
  }
  if (roles) {
    if (roles.length > 1) {
      filterTitle = `${roles.length} roles selected`;
    }
    if (roles.length === 1) {
      const roleObj = commonQuestions.getRoleById(roles[0], company.questions);
      if (roleObj) {
        filterTitle = `1 role selected`;
      }
    }
  }
  return filterTitle;
};

const FilterByRevieweeRoles = ({
  isMyProfile,
  userId,
  roles,
  updateFn,
  customSelectClasses
}) => {
  const MyProfile = useMyProfile();
  const UserProfile = useUserProfile(userId);
  const {
    revieweeRolesOptions,
    isFetching: isFetchingRolesOptions,
    isError: isErrorRolesOptions
  } = isMyProfile ? MyProfile : UserProfile;

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingCompany || isFetchingRolesOptions;
  const isError = isErrorCompany || isErrorRolesOptions;
  const isReady = company && company.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const filterByRole = (id) => {
    const exists = roles && roles.includes(id);
    const currentRoles = roles ?? [];
    const newRoles = exists
      ? roles.filter((rid) => rid !== id)
      : [...currentRoles, id];

    return updateFn({ roles: newRoles, category: null });
  };

  const clearRolesFilter = () => updateFn({ roles: [] });

  const filterRoleTitle = revieweeRolesOptions.length
    ? getFilterRoleTitle(roles, company)
    : 'No roles available';

  return (
    <Select
      variant='shadow'
      multiselect
      optionsWidth='wide'
      title={filterRoleTitle}
      onChange={(option) => {
        filterByRole(option.id);
      }}
      options={revieweeRolesOptions}
      classes={customSelectClasses ?? 'w-60'}
      showXButton={roles && roles.length}
      onClickXButton={clearRolesFilter}
      disabled={!revieweeRolesOptions.length}
    />
  );
};

export default FilterByRevieweeRoles;
