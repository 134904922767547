import { get } from 'lodash';
import { useContext } from 'react';
import { DashContext } from 'src/pagesDashboard/Dash/context/DashProvider';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import { useUserScore } from 'src/queries/score';
import { useFeedback } from 'src/queries/feedback';
import populateCategories, {
  populateCategoryData
} from 'src/utils/populateCategories';
import commonQuestions from 'common/commonQuestions';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useAccountScore } from 'src/queries/account';
import { useReviewerAndRevieweeHistory } from 'src/pagesDashboard/UserProfile/queries';
import { useSearchParams } from 'react-router-dom';
import { appUtils } from 'src/components/index';
import useMyProfileInitialState from 'src/pagesDashboard/Dash/context/state';

const { CATEGORIES_VIEW, FEEDBACK_TYPE } = COMMON_CONSTANTS;

const getFilterByRoles = (reviewedRoles, company, rolesFilter) => {
  if (
    !company
    || !company.questions
    || !reviewedRoles
    || !reviewedRoles.length
  ) {
    return [];
  }
  return (
    reviewedRoles
    && reviewedRoles
      .filter((roleId) => commonQuestions.getRoleById(roleId, company.questions))
      .map((roleId) => {
        const roleObj = commonQuestions.getRoleById(roleId, company.questions);
        return {
          id: roleId,
          label: roleObj.label,
          name: roleObj.label,
          checked: rolesFilter && Boolean(rolesFilter.includes(roleId))
        };
      })
      .sort((a, b) => (a.label < b.label ? -1 : 1))
  );
};

export const useMyProfile = () => {
  const contextObject = useContext(DashContext);
  const context = get(contextObject, 'context', useMyProfileInitialState);
  const {
    data: { myTreeRow },
    isFetching: isFetchingTree,
    isError: isErrorTree,
    isFetched: isFetchedTree
  } = useTree();
  const [matches] = useSearchParams();
  const isReport = Boolean(matches.get('reportId'));
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany,
    isFetched: isFetchedCompany
  } = useCompany();
  const userId = appUtils.getLoggedUserId();
  const { showBundledCategories } = context;
  const range = context.range.value;
  const { start } = context.range;
  const { end } = context.range;

  const {
    data: { feedbacks: feedbackData, notes: userNotes } = {
      feedbacks: [],
      notes: []
    },
    isFetching: isFetchingFeedback,
    isError: isErrorFeedback,
    isFetched: isFetchedFeedback
  } = useFeedback({
    ids: [userId],
    range,
    start,
    end,
    options: {
      feedbackTypes: [FEEDBACK_TYPE.FEEDBACK, FEEDBACK_TYPE.NOTE]
    }
  });

  const filters = { range, start, end };
  const options = {
    isReport: isReport || null,
    showBundledCategories: showBundledCategories
      ? CATEGORIES_VIEW.CATEGORIES_BUNDLED
      : CATEGORIES_VIEW.CATEGORIES_NOT_BUNDLED
  };
  const {
    data: userScore,
    isFetching: isFetchingScores,
    isError: isErrorScores,
    isFetched: isFetchedScores
  } = useUserScore({
    userId,
    filters,
    options
  });

  let feedbackReceived = '-';
  if (feedbackData) {
    feedbackReceived = feedbackData.filter(
      (f) => f.type === FEEDBACK_TYPE.FEEDBACK || f.type === FEEDBACK_TYPE.NOTE
    ).length + userNotes.length;
  }

  const rolesFilter = null;
  const userRoles = get(myTreeRow, 'roles', []);
  const { categories: bundledCategories, roles: rolesData } = populateCategoryData(
    userScore,
    company,
    rolesFilter,
    showBundledCategories,
    {
      userRoles
    }
  );

  let rolesSelected = [];
  if (!rolesFilter || !rolesFilter.length) {
    rolesSelected = userScore.roles;
  } else {
    rolesSelected = rolesFilter;
  }

  let myCategories = [];
  myCategories = populateCategories(
    rolesSelected,
    company,
    userScore,
    { showBundledCategories },
    isReport
  );

  const filterByRoles = getFilterByRoles(userScore.roles, company, rolesFilter);

  const {
    reviewers,
    reviewees,
    revieweeRoles,
    revieweeCategories: allRevieweeCategories,
    isFetching: isFetchingReviewerAndRevieweeHistory,
    isError: isErrorReviewerAndRevieweeHistory,
    isFetched: isFetchedReviewerAndRevieweeHistory
  } = useReviewerAndRevieweeHistory(userId, {
    start,
    end
  });

  const { roles: filteredRoles } = context.filters;
  const revieweeRolesOptions = revieweeRoles.map((role) => ({
    id: role.id,
    label: role.label,
    checked: filteredRoles?.includes(role.id)
  }));
  let revieweeCategories = [];
  if (filteredRoles?.length) {
    const validCategoryIds = revieweeRoles
      .filter((role) => filteredRoles.includes(role.id))
      .map((role) => role.categories)
      .flat();
    revieweeCategories = allRevieweeCategories.filter((cat) => validCategoryIds.includes(cat.id));
  } else revieweeCategories = allRevieweeCategories;

  const {
    data: topScores,
    isFetching: isFetchingTopScores,
    isError: isErrorTopScores
  } = useAccountScore(
    {
      id: userId,
      ...(start && {
        start,
        end
      })
    },
    {
      role: 'reviewee'
    }
  );

  return {
    bundledCategories,
    rolesData,
    userScore,
    isFetchingScores,
    feedbackReceived,
    myCategories,
    filterByRoles,
    revieweeRolesOptions,
    revieweeCategories,
    isFetching:
      isFetchingCompany
      || isFetchingFeedback
      || isFetchingScores
      || isFetchingTopScores
      || isFetchingReviewerAndRevieweeHistory
      || isFetchingTree,
    isError:
      isErrorCompany
      || isErrorFeedback
      || isErrorReviewerAndRevieweeHistory
      || isErrorScores
      || isErrorTopScores
      || isErrorTree,
    isFetched:
      isFetchedTree
      || isFetchedCompany
      || isFetchedFeedback
      || isFetchedScores
      || isFetchedReviewerAndRevieweeHistory,
    reviewees,
    reviewers,
    topScores
  };
};

export default useMyProfile;
