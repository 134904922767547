import React from 'react';
import TrendSVG from 'src/assets/svg/trend.svg';

const MobileCategory = ({ category }) => (
  <button
    type='button'
    className='flex w-full items-center text-left mobile-border px-3 py-2 h-22'
  >
    <div className='flex w-full h-14 items-center text-left'>
      <div className='mr-3 w-[59px] shrink-0 h-full rounded-lg bg-gray flex items-center justify-center text-black'>
        <TrendSVG />
      </div>
      <div className='w-full'>
        <p className='font-bold mb-1'>{category.label}</p>
        <p className='mb-1 leading-4 text-sm'>{category.scoreLabel}</p>
      </div>
    </div>
  </button>
);

export default MobileCategory;
