import React, { useState, useContext } from 'react';
import {
  Base,
  ToggleBundledCategories,
  DisplayBundledCategories,
  DisplayRoleCategories
} from 'src/components';
import CategoryBox from 'src/componentsAdvanced/CategoryBox/CategoryBox';
import { useTree } from 'src/queries/tree';
import PerformanceCategoryModal from 'src/containers/PerformanceCategoryModal/PerformanceCategoryModal';
import { useMyProfile } from 'src/pagesDashboard/Dash/queries';
import InformationCircleSVG from 'src/assets/svg/informationCircle.svg';
import { DashContext } from 'src/pagesDashboard/Dash/context/DashProvider';
import { updateData } from 'src/pagesDashboard/Dash/context/actions';
import STYLE from 'src/constants/style';

const Categories = () => {
  const { context, dispatch } = useContext(DashContext);
  const {
    bundledCategories,
    rolesData,
    isFetching: isFetchingMyProfile,
    isError: isErrorMyProfile
  } = useMyProfile();
  const {
    data: { myTreeRow },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const [local, setLocal] = useState({ showModal: false });

  const showModal = (data) => {
    setLocal({ ...local, showModal: data });
  };
  const hideModal = () => {
    setLocal({ ...local, showModal: false });
  };

  const isFetching = isFetchingMyProfile || isFetchingTree;
  const isError = isErrorMyProfile || isErrorTree;
  const isReady = myTreeRow && myTreeRow.id && !isFetching && !isError;

  if (!isReady) {
    return <Base classes='w-full h-40' loading />;
  }

  const { showBundledCategories } = context;

  const bundleCategories = () => {
    const newState = {
      ...context,
      showBundledCategories: !showBundledCategories
    };
    dispatch(updateData(newState));
  };

  return (
    <>
      {local.showModal ? (
        <PerformanceCategoryModal close={hideModal} data={local.showModal} />
      ) : null}
      <Base classes={STYLE.CONTAINER_BLUE}>
        <ToggleBundledCategories
          onClick={bundleCategories}
          showBundled={showBundledCategories}
        />

        {showBundledCategories ? (
          <DisplayBundledCategories
            categories={bundledCategories}
            onCategoryClick={(category) => {
              showModal({
                name: category.label,
                category,
                revieweeId: myTreeRow.id
              });
            }}
          />
        ) : null}

        {!showBundledCategories ? (
          <DisplayRoleCategories
            roles={rolesData}
            onCategoryClick={(category) => {
              showModal({
                name: category.label,
                category,
                revieweeId: myTreeRow.id
              });
            }}
          />
        ) : null}
      </Base>
    </>
  );
};

export default Categories;
