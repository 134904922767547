import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { get } from 'lodash';
import uuidv4 from 'uuid/v4';
import STYLE from 'src/constants/style';
import {
  Base,
  Select,
  Multiselect,
  PickDate,
  toast,
  Checkbox
} from 'src/components/index';
import {
  saveQuestionsQuery,
  usePulseQuestions,
  updateActiveQuestionQuery,
  usePulseReviews,
  useDeletePulseQuestion,
  disableActiveQuestionQuery,
  enableDraftQuestionQuery
} from 'src/queries/pulse';
import PulseButtons from 'src/pagesDashboard/Pulse/PulseButtons';
import utils from 'src/pagesDashboard/Pulse/containers/PulseQuestion/utils/utils';
import commonQuestions from 'common/commonQuestions';
import { useCompany } from 'src/queries/company';
import { useTree } from 'src/queries/tree';
import appUtils from 'src/components/appUtils';
import HeaderV2 from 'src/containers/Header/HeaderV2';
import PulseInputSection from 'src/pagesDashboard/Pulse/components/PulseInputSection';
import PulseChoiceSection from 'src/pagesDashboard/Pulse/components/PulseChoiceSection';
import QuestionPreview from 'src/pagesDashboard/Pulse/containers/PulseQuestion/containers/QuestionPreview';
import ConfirmArchivePulseModal from 'src/pagesDashboard/Pulse/components/ConfirmArchivePulseModal';
import {
  ANSWER_TYPES,
  PULSE_REPEATS,
  ANONYMITY,
  MC_ANSWER_CHOICES
} from 'src/pagesDashboard/Pulse/containers/PulseQuestion/context/constants';
import PULSE_CONSTANTS from 'common/pulseConstants';
import commonDateUtils from 'common/commonDateUtils';
import { useNavigate, useParams } from 'react-router-dom';

const { CATEGORIES: PULSE_CATEGORIES, PULSE_QUESTION_VISIBILITY } = PULSE_CONSTANTS;

const SAMPLE_CUSTOM_FIELDS = [
  {
    id: uuidv4(),
    score: commonQuestions.getScoreAtIndex([1, 2], 0).toString(),
    label: ''
  },
  {
    id: uuidv4(),
    score: commonQuestions.getScoreAtIndex([1, 2], 1).toString(),
    label: ''
  }
];

function generateTimeOptions() {
  const TIME_OPTIONS = [];
  for (let hours = 7; hours <= 19; hours += 1) {
    // eslint-disable-next-line no-restricted-syntax
    for (const minutes of [0, 15, 30, 45]) {
      const period = hours < 12 ? 'am' : 'pm';
      const displayHours = hours <= 12 ? hours : hours - 12;
      const displayMinutes = minutes === 0 ? '00' : minutes;
      const label = `${displayHours}:${displayMinutes}${period}`;
      TIME_OPTIONS.push({ hours, minutes, label });
    }
  }
  return TIME_OPTIONS;
}

const current = new Date();
const timeOptions = generateTimeOptions();

const defaultAllTemMembersGroupOption = {
  id: 'allTeamMembers',
  name: 'All Team Members',
  label: 'All Team Members',
  checked: true
};

const getDefaultValues = () => ({
  question: '',
  answerType: { id: 1, key: 'NPS', label: 'NPS' },
  answerChoices: null,
  category: { id: 1, label: 'Values' },
  repeats: { id: 1, key: 'Never', label: 'No Repeat' },
  requireComments: true,
  anonymity: {
    id: 1,
    label: 'Allow anonymous answers',
    value: 'allowAnonymous'
  },
  scheduleDate: current,
  scheduleTime: timeOptions[0],
  groups: [defaultAllTemMembersGroupOption]
});

const getSortedGroupOptions = (groups) => {
  // if "All Team Members" is checked, it will always be first
  // If "All Team Members" is not checked, it will be placed between checked and other unchecked groups
  const allTeamMembersOption = groups.find((g) => g.id === 'allTeamMembers');

  const allOtherGroups = groups.filter((g) => g.id !== 'allTeamMembers');
  const uncheckedGroups = allOtherGroups.filter((g) => !g.checked);
  const sortedUncheckedGroups = uncheckedGroups.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
  const checkedGroups = allOtherGroups.filter((g) => g.checked);
  const sortedCheckedGroups = checkedGroups.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));

  return [
    allTeamMembersOption,
    ...sortedCheckedGroups,
    ...sortedUncheckedGroups
  ];
};

const setPulseData = ({
  pulseQuestions,
  pulseQuestionId,
  setPulseQuestion,
  setQuestionData,
  getAllGroupOptions
}) => {
  const foundQuestion = pulseQuestions.find(
    (question) => question.id === pulseQuestionId
  );

  if (!foundQuestion) {
    return toast.error('Question not found.');
  }
  const { timestamp } = foundQuestion;
  const answerOption = ANSWER_TYPES.find(
    (obj) => obj.key === foundQuestion.type
  );
  const repeatsOption = PULSE_REPEATS.find(
    (obj) => obj.key === foundQuestion.repeats
  );
  const nextTimestamp = timestamp || commonDateUtils.getUnixDateNow() + 60 * 60 * 24;
  const scheduled = new Date();
  const hours = scheduled.getHours();
  const minutes = scheduled.getMinutes();
  const time = commonDateUtils.formatAMPM(scheduled);
  const category = PULSE_CATEGORIES.find(
    (cat) => cat.id.toString() === foundQuestion.category.toString()
  );

  setPulseQuestion({
    id: foundQuestion.id,
    text: foundQuestion.text,
    answerType: answerOption,
    choices: foundQuestion.choices,
    category,
    requireComments: foundQuestion.requireComments,
    repeats: repeatsOption,
    status: foundQuestion.status,
    scheduleDate: scheduled,
    pulseGroups: [],
    pulseGroupIds: foundQuestion.groupIds,
    schedule: {
      time,
      hours,
      minutes
    },
    pulseAnonymity: foundQuestion.anonymity
  });
  setQuestionData(
    {
      ...foundQuestion,
      schedule: {
        time,
        hours,
        minutes
      }
    },
    getAllGroupOptions()
  );
};

const getUnixTomorrow = () => {
  const curr = new Date();
  const currentHours = curr.getHours() + 24;
  curr.setHours(currentHours);
  const currentUnix = commonDateUtils.dateToUnix(curr);
  return currentUnix;
};

const PulseQuestionSettings = (props) => {
  const navigate = useNavigate();
  const { mode } = props;
  const { pulseQuestionId } = useParams();
  const [showConfirmArchivePulseModal, setShowConfirmArchivePulseModal] = useState(false);
  const [pulseQuestion, setPulseQuestion] = useState();
  const [isPulseQuestionDataReady, setIsPulseQuestionDataReady] = useState(false);
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const {
    data: { tree, treeList },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: pulseQuestions,
    isFetching: isFetchingPulseQuestions,
    isError: isErrorPulseQuestions,
    refetch: refetchPulseQuestions
  } = usePulseQuestions({
    filters: {
      visibility: PULSE_QUESTION_VISIBILITY.DEFAULT
    }
  });
  const { refetch: refetchPendingPulse } = usePulseReviews({});
  const { mutateAsync: saveQuestions, isLoading: saveQuestionsLoading } = saveQuestionsQuery();
  const {
    mutateAsync: updateActiveQuestion,
    isLoading: updateActiveQuestionLoading
  } = updateActiveQuestionQuery();
  const { mutateAsync: archivePulseQuestion, isLoading: archiveLoading } = useDeletePulseQuestion();
  const {
    mutateAsync: disableActiveQuestion,
    isLoading: disableActiveQuestionLoading
  } = disableActiveQuestionQuery();
  const {
    mutateAsync: enableDraftQuestion,
    isLoading: enableDraftQuestionLoading
  } = enableDraftQuestionQuery();

  const formData = useForm();
  const {
    watch,
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue
  } = formData;

  const formValues = watch();
  const getAllGroupOptions = () => {
    if (!company.groups && !treeList.length) {
      return [defaultAllTemMembersGroupOption];
    }

    const teams = treeList
      .filter((node) => node.children.length)
      .map((node) => ({
        id: node.id,
        label: node.name,
        name: `Team ${node.name}`
      }));
    const allGroups = [...company.groups, ...teams];
    const allGroupOptions = allGroups.map((g) => ({
      ...g,
      label: g.name,
      checked: false
    }));
    const allTeamMembersOption = {
      ...defaultAllTemMembersGroupOption,
      checked:
        allGroupOptions.length && allGroupOptions.every((opt) => !opt.checked)
    };
    const sortedOptions = getSortedGroupOptions([
      allTeamMembersOption,
      ...allGroupOptions
    ]);
    return sortedOptions;
  };

  const setQuestionData = (foundQuestion, allGroupOptions) => {
    const formattedQuestion = utils.getFoundQuestionData(
      foundQuestion,
      allGroupOptions
    );
    reset(formattedQuestion, { keepDefaultValues: false });
    setIsPulseQuestionDataReady(!isPulseQuestionDataReady);
  };

  const pulseMutations = {
    saveQuestions,
    updateActiveQuestion,
    archivePulseQuestion,
    disableActiveQuestion,
    enableDraftQuestion
  };

  const closeDeleteTemplateModal = () => setShowConfirmArchivePulseModal(false);

  const isFetching = isFetchingCompany || isFetchingTree || isFetchingPulseQuestions;
  const isError = isErrorTree || isErrorCompany || isErrorPulseQuestions;
  const isReady = company && company.id && tree && tree.id && !isFetching && !isError;

  const [isFormInitialized, setIsFormInitialized] = useState(false);
  useEffect(() => {
    if (!isReady) return;
    if (!isFormInitialized) {
      if (pulseQuestionId && pulseQuestions) {
        setPulseData({
          pulseQuestions,
          pulseQuestionId,
          setPulseQuestion,
          setQuestionData,
          getAllGroupOptions
        });
        if (mode === 'edit') {
          const currentQuestion = pulseQuestions.find(
            (q) => q.id === pulseQuestionId
          );
          setValue('requireComments', currentQuestion.requireComments);
        }
      } else {
        reset(getDefaultValues());
      }
      if (company.groups && treeList.length && !pulseQuestionId) {
        const allOptions = getAllGroupOptions();
        setValue('groups', allOptions);
      }
      setIsFormInitialized(true);
    }
  }, [isReady]);

  useEffect(() => {
    if (mode === 'new' && formValues.answerType) {
      if (formValues.answerType.key === PULSE_CONSTANTS.TYPES.MC) {
        setValue('answerChoices', MC_ANSWER_CHOICES[0]);
      }
      if (formValues.answerType.key === PULSE_CONSTANTS.TYPES.CUSTOM) {
        setValue('answerChoices', SAMPLE_CUSTOM_FIELDS);
      }
      if (
        formValues.answerChoices
        && (formValues.answerType.key === PULSE_CONSTANTS.TYPES.NPS
          || formValues.answerType.key === PULSE_CONSTANTS.TYPES.FREE)
      ) {
        setValue('answerChoices', null);
        setValue('requireComments', true);
      }
    }
  }, [formValues.answerType]);

  if (!isReady || !isFormInitialized) return null;

  const getQuestion = () => {
    let choices = null;
    if (formValues.answerType.key === PULSE_CONSTANTS.TYPES.MC) {
      if (!formValues.answerChoices) {
        choices = MC_ANSWER_CHOICES[0].key;
      } else {
        choices = formValues.answerChoices.key;
      }
    }

    if (
      formValues.answerType.key === PULSE_CONSTANTS.TYPES.CUSTOM
      && Array.isArray(formValues.answerChoices)
    ) {
      choices = formValues.answerChoices.map((option) => ({
        score: option.score,
        label: option.label
      }));
    } else {
      choices = [];
    }

    return {
      choices,
      text: formValues.question,
      type: formValues.answerType.key
    };
  };

  const isSuperUser = appUtils.isSuperUser();

  const handleCheckAllTeamMembersOption = () => {
    const checkedAllTeamMembersOption = {
      ...defaultAllTemMembersGroupOption,
      checked: true
    };
    const newUncheckedOptions = formValues.groups
      .filter((g) => g.id !== 'allTeamMembers')
      .map((g) => ({ ...g, checked: false }));
    const sortedOptions = getSortedGroupOptions([
      checkedAllTeamMembersOption,
      ...newUncheckedOptions
    ]);
    setValue('groups', sortedOptions);
  };

  const removeGroup = (groupObj) => {
    if (groupObj.id === 'allTeamMembers') {
      return;
    }
    const allGroupsAreBeingUnchecked = formValues.groups
      .filter((g) => g.id !== groupObj.id)
      .every((g) => !g.checked);

    // check "All Team Members" option if all other options are unchecked
    if (allGroupsAreBeingUnchecked) {
      handleCheckAllTeamMembersOption();
      return;
    }
    const uncheckedGroup = { ...groupObj, checked: false };
    const allOtherGroups = formValues.groups.filter(
      (group) => group.id !== uncheckedGroup.id
    );
    const sortedOptions = getSortedGroupOptions([
      uncheckedGroup,
      ...allOtherGroups
    ]);
    setValue('groups', sortedOptions);
  };

  const addGroup = (groupObj) => {
    const isCheckingAllTeamMembersOption = groupObj.id === 'allTeamMembers';

    // check "All Team Members" option and uncheck all other options
    if (isCheckingAllTeamMembersOption) {
      handleCheckAllTeamMembersOption();
      return;
    }

    const checkedGroup = { ...groupObj, checked: true };
    const allOtherGroups = formValues.groups.filter(
      (group) => group.id !== checkedGroup.id && group.id !== 'allTeamMembers'
    );
    const currentCheckedGroups = allOtherGroups.filter(
      (group) => group.checked
    );
    const currentUncheckedGroups = allOtherGroups.filter(
      (group) => !group.checked
    );
    const uncheckedAllTeamMembersOption = {
      ...defaultAllTemMembersGroupOption,
      checked: false
    };

    const newCheckedGroups = [checkedGroup, ...currentCheckedGroups];
    const newUncheckedGroups = [
      uncheckedAllTeamMembersOption,
      ...currentUncheckedGroups
    ];
    const sortedOptions = getSortedGroupOptions([
      ...newCheckedGroups,
      ...newUncheckedGroups
    ]);
    setValue('groups', sortedOptions);
  };

  const toggleGroup = (group) => {
    const isUnchecking = group.checked;

    if (isUnchecking) {
      removeGroup(group);
      return;
    }
    addGroup(group);
  };

  const archiveQuestion = async (id) => {
    const result = await archivePulseQuestion(id);
    if (!result) {
      return toast.error(
        'Oops, we ran into an issue. Try again a little later!'
      );
    }
    refetchPulseQuestions();
    toast.show('Question archived');
    navigate(`/dashboard/pulse`, true);
  };
  const save = async (isEnabled) => {
    if (!formValues.question || formValues.question === '') {
      return toast.error('Please type out a question.');
    }
    const question = utils.getQuestionToSave(formValues, pulseQuestionId);
    if (!question) {
      return;
    }
    const newQuestion = {
      ...question,
      status: isEnabled ? 'Active' : 'Draft'
    };
    newQuestion.category = newQuestion.category.join(',');

    if (newQuestion.type === PULSE_CONSTANTS.TYPES.FREE) {
      newQuestion.requireComments = true;
    }

    const result = await saveQuestions([newQuestion]);

    if (result.warning) {
      const message = get(
        result,
        'message',
        'Failed to enable question. Try again later!'
      );
      toast.closeAll();
      return toast.error(message);
    }

    refetchPendingPulse();
    refetchPulseQuestions();

    toast.show('Question saved');
    navigate(`/dashboard/pulse`, true);
  };

  const savePulse = async () => {
    save(true);
  };

  const showChoiceSection = formValues.answerType.key === PULSE_CONSTANTS.TYPES.MC
    || formValues.answerType.key === PULSE_CONSTANTS.TYPES.CUSTOM;

  const disableButton = saveQuestionsLoading
    || updateActiveQuestionLoading
    || archiveLoading
    || disableActiveQuestionLoading
    || enableDraftQuestionLoading;

  const displayRequireCommentsField = formValues.answerType.key !== PULSE_CONSTANTS.TYPES.FREE;
  return (
    <div>
      <HeaderV2 overtitle='Tracking' title='Pulse' />
      <form
        className='flex flex-col items-center'
        onSubmit={handleSubmit(savePulse)}
      >
        <Base
          className={STYLE.BASE}
          loading={
            saveQuestionsLoading
            || updateActiveQuestionLoading
            || archiveLoading
            || disableActiveQuestionLoading
            || enableDraftQuestionLoading
          }
        >
          <Base classes={STYLE.CONTAINER_WHITE}>
            {showConfirmArchivePulseModal ? (
              <ConfirmArchivePulseModal
                pulseQuestionId={pulseQuestion.id}
                isLoading={archiveLoading}
                close={closeDeleteTemplateModal}
                archiveFn={archiveQuestion}
              />
            ) : null}
            {mode === 'edit' ? (
              <div className='mb-10 mt-4 font-extrabold text-black text-2xl pl-3'>
                Edit Pulse Question
              </div>
            ) : (
              <div className='mb-10 mt-4 font-extrabold text-black text-2xl pl-3'>
                New Pulse Question
              </div>
            )}
            <div className='w-5/6 pr-40 mb-12 p-3'>
              <PulseInputSection
                id='pulse-question'
                labelText='Question'
                errorMessage={errors?.question?.message}
              >
                <input
                  id='pulse-question'
                  className='text-base w-full max-w-34rem bg-white border-gray-100 shadow h-10'
                  placeholder='Type the question here'
                  {...register('question', {
                    required: {
                      value: true,
                      message: 'Write a question'
                    }
                  })}
                />
              </PulseInputSection>
              <PulseInputSection
                id='answer-type'
                labelText='Answer type'
                errorMessage={errors?.answerType?.message}
              >
                <Controller
                  name='answerType'
                  control={control}
                  render={({ field }) => {
                    const { value } = field;
                    return (
                      <div className='w-full max-w-34rem inline-block align-top'>
                        <Select
                          id='answerType'
                          classes='w-full'
                          disabled={mode === 'edit'}
                          variant='shadow'
                          title={value.label}
                          options={ANSWER_TYPES}
                          {...field}
                        />
                      </div>
                    );
                  }}
                />
              </PulseInputSection>
              {showChoiceSection ? (
                <PulseChoiceSection
                  id='answerChoices'
                  disabled={mode === 'edit'}
                  labelText='Answer Choices'
                  errorMessage={errors?.answerChoices?.message}
                  answerType={formValues.answerType}
                  mode={mode}
                  formData={formData}
                />
              ) : null}
              <PulseInputSection
                id='category'
                labelText='Category'
                errorMessage={errors?.category?.message}
              >
                <Controller
                  name='category'
                  control={control}
                  render={({ field }) => {
                    const { value } = field;
                    return (
                      <div className='w-full max-w-34rem inline-block align-top'>
                        <Select
                          id='category'
                          classes='w-full'
                          disabled={mode === 'edit'}
                          variant='shadow'
                          title={value.label}
                          options={PULSE_CATEGORIES}
                          {...field}
                        />
                      </div>
                    );
                  }}
                />
              </PulseInputSection>
              <PulseInputSection
                id='repeats'
                labelText='Repeats'
                errorMessage={errors?.repeats?.message}
              >
                <Controller
                  name='repeats'
                  control={control}
                  render={({ field }) => {
                    const { value } = field;
                    return (
                      <div className='w-full max-w-34rem inline-block align-top'>
                        <Select
                          id='repeats'
                          classes='w-full'
                          variant='shadow'
                          title={value.label}
                          options={PULSE_REPEATS}
                          {...field}
                        />
                      </div>
                    );
                  }}
                />
              </PulseInputSection>
              {displayRequireCommentsField && (
                <PulseInputSection
                  id='requireComments'
                  labelText='Require Comments'
                  errorMessage={errors?.requireComments?.message}
                >
                  <Controller
                    name='requireComments'
                    control={control}
                    render={({ field }) => {
                      const { value } = field;
                      return (
                        <div className='w-full max-w-34rem inline-block align-top'>
                          <label>
                            <span className='inline-block'>
                              <Checkbox
                                id='requireComments'
                                key={value?.label}
                                disabled={mode === 'edit'}
                                label={(
                                  <div>
                                    <div className='mb-2 font-bold'>
                                      {value?.label}
                                    </div>
                                  </div>
                                )}
                                value={value}
                                {...field}
                              />
                            </span>
                            <span className='inline-block ml-1'>
                              Require additional comments for this question
                            </span>
                          </label>
                        </div>
                      );
                    }}
                  />
                </PulseInputSection>
              )}
              <PulseInputSection
                id='anonymity'
                labelText='Anonymity'
                errorMessage={errors?.anonymity?.message}
              >
                <Controller
                  name='anonymity'
                  control={control}
                  render={({ field }) => {
                    const { value } = field;
                    return (
                      <div className='w-full max-w-34rem inline-block align-top'>
                        <Select
                          id='anonymity'
                          classes='w-full'
                          disabled={mode === 'edit'}
                          variant='shadow'
                          title={value.label}
                          options={ANONYMITY}
                          {...field}
                        />
                      </div>
                    );
                  }}
                />
              </PulseInputSection>

              <PulseInputSection
                id='scheduleDate'
                labelText='Start Date'
                errorMessage={errors?.dateRange?.message}
              >
                <Controller
                  name='scheduleDate'
                  control={control}
                  render={({ field }) => {
                    const curr = field.value
                      && !Number.isNaN(formValues.scheduleDate.valueOf())
                      ? new Date(field.value)
                      : null;
                    return (
                      <PickDate
                        classes='float-none w-full'
                        minDate={getUnixTomorrow()}
                        inputClasses='w-full'
                        date={curr}
                        onSelect={field.onChange}
                        {...field}
                      />
                    );
                  }}
                />
                <p className='mt-1 mb-4 mr-6 italic'>
                  Pulse questions are scheduled according to your current
                  timezone.
                </p>
              </PulseInputSection>

              <PulseInputSection
                id='scheduleTime'
                labelText='Start Time'
                errorMessage={errors?.scheduleTime?.message}
              >
                <Controller
                  name='scheduleTime'
                  control={control}
                  render={({ field }) => {
                    const { value } = field;
                    return (
                      <div className='w-full max-w-34rem inline-block align-top'>
                        <Select
                          id='scheduleTime'
                          classes='w-full'
                          variant='shadow'
                          title={value.label}
                          options={timeOptions}
                          {...field}
                        />
                      </div>
                    );
                  }}
                />
              </PulseInputSection>
              <PulseInputSection
                id='groups'
                labelText='Groups'
                errorMessage={errors?.groups?.message}
              >
                <div className='w-full max-w-34rem inline-block align-top'>
                  <Multiselect
                    multiSelectSelectedItemClasses='w-8.5rem'
                    options={formValues.groups}
                    placeholder='All team members'
                    variant='minimal'
                    multiselect
                    disabled={mode === 'edit'}
                    onChange={(option, type) => {
                      if (type === 'select') {
                        return toggleGroup(option);
                      }
                      if (type === 'remove') {
                        return removeGroup(option);
                      }
                    }}
                  />
                </div>
              </PulseInputSection>
              <PulseInputSection
                id='preview'
                labelText='Preview'
                labelClasses='md:mb-auto md:mt-0'
              >
                <QuestionPreview question={getQuestion()} />
              </PulseInputSection>
            </div>

            {/* {mode === 'edit' && isSuperUser ? (
              <SendTestReview close={close} />
            ) : null} */}
            {pulseQuestionId && isSuperUser ? (
              <div className='text-purple mb-6 ml-3'>
                (Superuser view) Question Id:
                {pulseQuestionId}
              </div>
            ) : null}
            <PulseButtons
              mode={mode}
              setShowConfirmArchivePulseModal={setShowConfirmArchivePulseModal}
              pulseQuestion={pulseQuestion}
              formData={formData}
              formValues={formValues}
              pulseQuestionId={pulseQuestionId}
              disableButton={disableButton}
              pulseMutations={pulseMutations}
              refetchPendingPulse={refetchPendingPulse}
              refetchPulseQuestions={refetchPulseQuestions}
              save={save}
            />
          </Base>
        </Base>
      </form>
    </div>
  );
};

export default PulseQuestionSettings;
