const rollbarConfig = (isEnabled) => ({
  enabled: isEnabled,
  accessToken: process.env.ROLLBAR_TOKEN,
  environment: process.env.ENV,
  endpoint: `${process.env.SERVICE}/admin/rollbar`,
  captureUncaught: true,
  captureUnhandledRejections: true,
  server: {
    root: 'webpack:///./'
  },
  payload: {
    environment: process.env.ENV,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: process.env.LAST_COMMIT,
        // Optionally have Rollbar guess which frames the error was thrown from when the browser does not provide line and column numbers.
        guess_uncaught_frames: true
      }
    }
  }
});

export default rollbarConfig;
