import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import commonDateUtils from 'common/commonDateUtils';
import KeyboardArrowDownSVG from 'src/assets/keyboard_arrow_down.svg';
import KeyboardArrowUpSVG from 'src/assets/keyboard_arrow_up.svg';
import CopyIconSVG from 'src/assets/copy-icon.svg';
import appUtils from 'src/components/appUtils';
import ThumbsUpSvg from 'src/assets/svg/thumbs-up-outlined.svg';
import ThumbsDownSvg from 'src/assets/svg/thumbs-down-outlined.svg';
import Thumbs from 'src/assets/svg/thumbs-up.svg';
import { Button, Modal, toast } from 'src/components';
import { useSendFeedback } from 'src/queries/Internal/internal';
import COMMON_CONSTANTS from 'common/commonConstants';
import './SummaryAICard.scss';

const { INTERNAL_ENDPOINT_TYPES } = COMMON_CONSTANTS;

const SummaryAICard = ({
  wizardId,
  text,
  endDate,
  collapsable
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const [summaryFeedbackImpression, setIsSummaryFeedbackImpression] = useState(null);
  const [isSummaryFeedbackModalOpen, setIsSummaryFeedbackModalOpen] = useState(false);
  const [summaryFeedbackComments, setSummaryFeedbackComments] = useState('');

  const {
    mutateAsync: sendSummaryFeedback,
    isLoading: sendingFeedbackLoading
  } = useSendFeedback();

  useEffect(() => {
    if (collapsable) setCollapsed(true);
  }, []);

  const toggleCollapse = () => setCollapsed(!collapsed);

  const sanitizedText = () => ({
    __html: DOMPurify.sanitize(text)
  });

  const monthYear = commonDateUtils.unixToMonthYear(endDate);

  const title = 'WorkStory AI Assist';
  const subtitle = `Performance Summary - ${monthYear}`;

  const onThumbClick = (thumbClicked) => {
    setIsSummaryFeedbackImpression(thumbClicked);
    setIsSummaryFeedbackModalOpen(true);
  };

  const closeSummaryFeedbackModal = () => {
    setIsSummaryFeedbackModalOpen(false);
    setIsSummaryFeedbackImpression(null);
    setSummaryFeedbackComments('');
  };

  const copyToClipboard = () => {
    const html = sanitizedText().__html;
    const temp = document.createElement('div');
    temp.innerHTML = html;
    const { textContent } = temp;
    appUtils.copyTextToClipboard(textContent);
  };

  const submitSummaryFeedback = async () => {
    toast.show('Sending');
    const response = await sendSummaryFeedback({
      feedback: summaryFeedbackComments,
      impression: summaryFeedbackImpression,
      wizardId,
      type: INTERNAL_ENDPOINT_TYPES.AI_SUMMARY_FEEDBACK
    });
    if (!response.success) {
      return toast.error('We ran into an issue - try again!');
    }
    toast.show('Feedback submitted!');
    closeSummaryFeedbackModal();
  };

  return (
    <>
      {isSummaryFeedbackModalOpen ? (
        <Modal
          classes='overflow-y-auto'
          innerClasses='w-34rem h-41rem overflow-y-auto'
          variant='custom'
          close={closeSummaryFeedbackModal}
          clickClose
        >
          <h3 className='text-left'>WorkStory AI Assist Feedback</h3>
          <p className='mb-2 text-left'>
            Sharing
            {' '}
            {summaryFeedbackImpression}
            {' '}
            feedback:
          </p>
          <textarea
            className='block w-full h-40 resize-none'
            value={summaryFeedbackComments}
            onChange={(e) => setSummaryFeedbackComments(e.target.value)}
          />
          <div className='mt-4 flex justify-between'>
            <button
              type='submit'
              onClick={closeSummaryFeedbackModal}
              className='float-left text-black text-lg px-4 py-2 mr-0 font-bold'
            >
              Close
            </button>
            <Button
              variant='yellow'
              disabled={!summaryFeedbackComments || sendingFeedbackLoading}
              onClick={submitSummaryFeedback}
            >
              Send
            </Button>
          </div>
        </Modal>
      ) : null}

      {collapsed ? (
        <div className='ql-editor'>
          <div className='flex justify-between'>
            <h2 className='text-lg p-0'>{subtitle}</h2>
            <button onClick={toggleCollapse} className='text-lg'>
              {!collapsed ? <KeyboardArrowUpSVG /> : <KeyboardArrowDownSVG />}
            </button>
          </div>
        </div>
      ) : (
        <div className='ql-editor'>
          <div className='flex justify-between'>
            <div className='w-full flex justify-between items-center'>
              <h1 className='text-purple text-xl mb-1 p-0'>{title}</h1>
              <a
                className={`text-purple mb-0 ${collapsable && 'hidden'}`}
                href='https://home.workstory.team/faq/what-is-workstory-ai-assist'
                target='_blank'
                rel='noreferrer'
              >
                Have Questions?
              </a>
            </div>

            <button
              onClick={toggleCollapse}
              className={`${!collapsable && 'hidden'}`}
            >
              {!collapsed ? <KeyboardArrowUpSVG /> : <KeyboardArrowDownSVG />}
            </button>
          </div>
          <h2 className='text-lg p-0'>{subtitle}</h2>
          <hr className='mt-2 mb-2 border-[#eeeeee] bg-[#eeeeee]' />

          <div
            dangerouslySetInnerHTML={sanitizedText()}
            className='whitespace-normal text-16px my-0'
          />

          <div className='flex justify-between mt-5'>
            <div className='flex justify-between items-center text-dark-grey'>
              We'd love your feedback: is this summary helpful?
              <div className='ml-3 flex flex-row items-center'>
                <div>
                  <ThumbsUpSvg className='w-4 mr-1 cursor-pointer text-gray-400 hover:text-transparent fill-current inline' />
                  <Thumbs
                    className='transition-all duration-200 w-4 mr-1 cursor-pointer text-transparent hover:text-blue-500 fill-current inline -ml-5'
                    onClick={() => onThumbClick('positive')}
                  />
                </div>

                <div>
                  <ThumbsDownSvg className='w-4 ml-1 cursor-pointer text-gray-400 hover:text-transparent fill-current inline' />

                  <Thumbs
                    className='transform transition-all duration-200 -rotate-180 w-4 cursor-pointer text-transparent hover:text-red fill-current inline -ml-4'
                    onClick={() => onThumbClick('negative')}
                  />
                </div>
              </div>
            </div>
            <button
              onClick={copyToClipboard}
              className='focus:outline-0 focus:outline-none cursor-pointer text-right tooltip'
            >
              <span className='bg-black text-white -ml-20 -mt-8 tooltip-text'>
                Copy to clipboard
              </span>
              <CopyIconSVG className='text-gray-400 hover:text-gray-600 transition-all duration-200' />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SummaryAICard;
