import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import pulseActions from 'src/queries/actions/pulse';
import { PulseQuestionResultsContext } from 'src/pagesDashboard/PulseQuestionResults/context/PulseQuestionResultsProvider';
import { usePulseData } from 'src/queries/Pulse/pulseData';
import { Base } from 'src/components/';
import STYLE from 'src/constants/style';

const Loading = () => (
  <Base loading classes='bg-white p-2 px-3 mx-2 h-24'>
    <h5 className='font-bold text-xl'>Question</h5>
  </Base>
);

const QuestionHeader = () => {
  const { questionId } = useParams();
  const { context } = useContext(PulseQuestionResultsContext);
  const {
    range: { value: range, start, end }
  } = context;

  const pulseAction = pulseActions.getQuestionData({
    questionId,
    range,
    start,
    end
  });

  const {
    data: pulseData,
    isFetching: isFetchingPulse,
    isError: isErrorPulse
  } = usePulseData(pulseAction);

  const isFetching = isFetchingPulse;
  const isError = isErrorPulse;
  const isReady = pulseData && !isFetching && !isError;

  if (!isReady) {
    return <Loading />;
  }

  let pulseQuestion = '';
  if (pulseData) {
    pulseQuestion = pulseData.pulseQuestion.text;
  }

  return (
    <Base loading={isFetchingPulse} classes={STYLE.CONTAINER_WHITE}>
      <h5 className='font-bold text-xl'>Question</h5>
      <p className='ml-2 mt-5 mb-3'>
        {' '}
        {pulseQuestion}
      </p>
    </Base>
  );
};

export default QuestionHeader;
