import React from 'react';
import CircleV2 from 'src/components/Circle/CircleV2';

const MobileTeamMember = ({ account }) => {
  const userImage = account && account.imageUrl
    ? account.imageUrl
    : '';
  return (
    <button
      type='button'
      className='flex w-full items-center text-left mobile-border px-3 py-2 h-22'
    >
      <div className='flex w-full h-14 items-center'>
        <div className='flex gap-4 w-full'>
          <CircleV2
            imageUrl={userImage}
            size='sm'
          />
          <div className='w-full'>
            <p className='font-bold mb-1 w-[85%] truncate'>{account.name}</p>
            <p className='mb-1 leading-4 text-sm'>{account.title}</p>
          </div>
        </div>
      </div>
    </button>
  );
};

export default MobileTeamMember;
