const BADGE_COLOR_CLASSES = {
  GREEN: 'bg-green-50 text-green-700 ring-green-600/20',
  RED: 'bg-red-50 text-red-700 ring-red-600/20',
  GRAY: 'bg-gray-50 text-gray-700 ring-gray-600/20',
  GOLD: 'bg-yellow text-black ring-yellow/20',
  PURPLE: 'bg-purple text-white ring-purple/20',
  BLUE: 'bg-blue-50 text-blue-700 ring-blue-600/20'
};

export { BADGE_COLOR_CLASSES };
