import React, { useState, useEffect } from 'react';
import { update, disconnectCommunicationQuery } from 'src/queries/user';
import {
  Base, Input, Button, toast
} from 'src/components/';
import STYLE from 'src/constants/style';
import webexUtils from 'src/containers/Settings/WebexIntegration/webexUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useAccount } from 'src/queries/account';
import { get } from 'lodash';
import commonUtils from 'common/commonUtils';

const { COMMUNICATION_TYPES } = COMMON_CONSTANTS;

const UserConnected = () => {
  let intervalId;

  const {
    data: account,
    refetch: refetchAccount,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount('me');

  const { refetch: refetchQuietAccount } = useAccount(
    'me',
    {},
    {
      webexIntegration: true
    }
  );

  const isFetching = isFetchingAccount;
  const isError = isErrorAccount;
  const isReady = !isFetching && !isError;

  const [isEditing, setIsEditing] = useState(false);
  const [webexEmail, setWebexEmail] = useState('');

  const {
    mutateAsync: disconnectCommunication,
    isLoading: isLoadingDisconnectCommunication
  } = disconnectCommunicationQuery();
  const { mutateAsync: updateUser, isLoading: isLoadingUpdateUser } = update();

  useEffect(() => {
    if (!isReady) return;
    const currentWebexEmail = get(account, 'webexEmail', '');
    setWebexEmail(currentWebexEmail);
  }, [isReady]);

  if (!isReady) {
    return null;
  }

  const switchIsEditing = () => {
    if (intervalId) clearInterval(intervalId);
    if (isEditing) setWebexEmail(account.webexEmail);
    setIsEditing(!isEditing);
  };

  const saveWebexEmail = async () => {
    await disconnectCommunication({
      communication: COMMUNICATION_TYPES.WEBEX
    });

    const userUpdated = await updateUser({
      id: account._id,
      webexEmail: webexEmail.trim()
    });

    if (!userUpdated.success) {
      toast.error(userUpdated.error);
      return false;
    }

    switchIsEditing();
    const {
      data: {
        data: { webexEmail: currentWebexTeamsEmail }
      }
    } = await refetchAccount();
    if (currentWebexTeamsEmail) setWebexEmail(currentWebexTeamsEmail);
  };

  const openWebex = () => {
    window.open(webexUtils.getWebexOpenLink(), '_blank', 'noopener noreferrer');

    intervalId = setInterval(async () => {
      const {
        data: {
          data: { webexEmail: currentWebexEmail }
        }
      } = await refetchQuietAccount();
      if (currentWebexEmail) setWebexEmail(currentWebexEmail);
    }, 2500);

    setTimeout(() => clearInterval(intervalId), 60000);
  };

  const isWebexEmailSet = Boolean(get(account, 'webexEmail'));
  const isAuthenticated = Boolean(get(account, 'webexAuth'));

  return (
    <Base classes={STYLE.CONTAINER_WHITE}>
      <h5>Webex Integration</h5>
      <p className='mb-3'>
        {isAuthenticated ? 'Edit' : 'Setup'}
        {' '}
        your Webex integration:
      </p>
      <div className='flex items-center'>
        <Input
          title=''
          placeholder='Webex Email'
          onChange={(e) => setWebexEmail(e.target.value)}
          value={webexEmail}
          disabled={!isEditing}
        />
        <button
          type='button'
          className='ml-4 mb-auto mt-2'
          onClick={switchIsEditing}
        >
          {isEditing ? 'Cancel' : 'Edit'}
        </button>
      </div>

      {isEditing && (
        <div className='mb-3 block'>
          <Button
            variant='yellow'
            onClick={saveWebexEmail}
            disabled={
              !webexEmail
              || webexEmail === account.webexEmail
              || !commonUtils.isEmailValid(webexEmail)
              || isLoadingUpdateUser
              || isLoadingDisconnectCommunication
            }
          >
            Next
          </Button>
        </div>
      )}

      {isWebexEmailSet && !isAuthenticated && !isEditing && (
        <>
          <p className='mb-2'>
            Open Webex and send a
            {' '}
            <strong>Hello</strong>
            {' '}
            message to our bot:
          </p>
          <div className='mb-3 block'>
            <Button variant='yellow' onClick={openWebex} disabled={!webexEmail}>
              Open Webex
            </Button>
          </div>
        </>
      )}

      <p className='mb-2 mt-1'>
        The bot name is WorkStory. For more information, visit our
        {' '}
        <a
          target='_blank'
          href='https://home.workstory.team/faq/connecting-your-webex-account'
          rel='noreferrer'
        >
          Webex FAQ page
        </a>
        .
      </p>
    </Base>
  );
};

export default UserConnected;
