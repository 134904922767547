import { isEmpty, get } from 'lodash';
import React from 'react';

const PreviewQuestion = ({ reviews, question }) => {
  const questionText = get(question, 'text') || get(question, 'question');
  return (
    <>
      <div className='text-sm font-semibold my-2'>{questionText}</div>
      {reviews.map((review, index) => {
        const { answer, comments, label } = review;

        const answerLabel = get(answer, 'label');
        const hasAnswerLabel = typeof answerLabel === 'string';
        return (
          <div
            className='w-full flex py-3 flex-col justify-center text-left border border-slate-300 px-6 bg-gray-200 min-h-16 my-2'
            key={index}
          >
            <span className='text-sm'>
              {label}
              {hasAnswerLabel ? (
                <>
                  <strong>{answer.label}</strong>
                  {' '}
                  {!isEmpty(comments) ? '- ' : null}
                </>
              ) : null}
              {comments}
            </span>
          </div>
        );
      })}
    </>
  );
};

export default PreviewQuestion;
