import React from 'react';
import { toast } from 'src/components';
import { usePulseQuestions, useDeletePulseQuestion } from 'src/queries/pulse';
import PULSE_CONSTANTS from 'common/pulseConstants';
import { PulseContext } from 'src/pagesDashboard/Pulse/context/PulseProvider';
import appUtils from 'src/components/appUtils';
import InformationCircleSVG from 'src/assets/svg/informationCircle.svg';
import commonDateUtils from 'common/commonDateUtils';
import { useNavigate } from 'react-router-dom';

const { PULSE_QUESTION_VISIBILITY } = PULSE_CONSTANTS;

const PulseOverview = () => {
  const { mutateAsync: deletePulseQuestion, isLoading: deleteLoading } = useDeletePulseQuestion();
  const {
    data: list,
    refetch: refetchPulseQuestions,
    isFetching: isFetchingPulse
  } = usePulseQuestions({
    filters: {
      visibility: PULSE_QUESTION_VISIBILITY.DEFAULT
    }
  });

  const navigate = useNavigate();
  const goTo = (questionId) => {
    navigate(`/dashboard/pulse/${questionId}`);
  };

  const editQuestion = (pulseQuestionId) => {
    navigate(`/dashboard/pulse/edit/${pulseQuestionId}`);
  };

  const deleteQuestion = async (id) => {
    const result = await deletePulseQuestion(id);
    if (!result) {
      return toast.error(
        'Oops, we ran into an issue. Try again a little later!'
      );
    }
    toast.show('Question deleted');
    refetchPulseQuestions();
    // console.log('delete this pulse question', pulseQuestionId);
  };

  return (
    <div className='mt-6'>
      <div className='mb-2 py-1 px-1 bg-background-gray text-black font-bold rounded-sm cursor-default'>
        <div className='w-7rem inline-block'>
          <p className='mb-0 inline-block font-bold'>Last send</p>
          <div className='tooltip w-5 inline-block'>
            <span className='text-white bg-black ml-5 -mt-1 tooltip-text'>
              Last date question was scheduled
            </span>
            <div className='mt-0.5'>
              <InformationCircleSVG />
            </div>
          </div>
        </div>
        <p className='mb-0 w-1/12 inline-block font-bold pl-3'>Status</p>
        <p className='mb-0 w-1/12 inline-block font-bold'>Repeats</p>
        <p className='mb-0 w-1/12 inline-block font-bold'>Type</p>
        <p className='mb-0 w-4/12 inline-block font-bold'>Question</p>
        <p className='mb-0 w-1/12 inline-block font-bold'>Answers</p>
        <p className='mb-0 w-1/12 inline-block font-bold'>Category</p>
      </div>

      {!isFetchingPulse && list && list.length === 0 ? (
        <div>
          <p className='text-center font-bold mt-6'>
            You have no survey questions
          </p>
        </div>
      ) : null}
      {list && list.length
        ? list
          .sort((a, b) => (a.created > b.created ? -1 : 1))
          .map((pulseQuestion, index) => {
            const category = PULSE_CONSTANTS.CATEGORIES.find(
              (cat) => cat.id === parseInt(pulseQuestion.category, 10)
            );
            return (
              <div
                key={index}
                className='mb-2 py-1 px-1 text-black rounded-sm cursor-default'
              >
                <p className='mb-0 w-7rem inline-block'>
                  {pulseQuestion.lastSend
                    ? commonDateUtils.unixToMonthDayYearFormat(
                      pulseQuestion.lastSend
                    )
                    : '-'}
                </p>
                <p className='mb-0 pl-3 w-1/12 inline-block'>
                  {pulseQuestion.status}
                </p>
                <p className='mb-0 pl-1 w-1/12 inline-block'>
                  {pulseQuestion.repeats}
                </p>
                <p className='mb-0 w-1/12 inline-block'>
                  {pulseQuestion.type}
                </p>
                <p className='mb-0 w-4/12 inline-block pr-5'>
                  {pulseQuestion.text}
                </p>
                <p className='mb-0 w-1/12 inline-block'>
                  {pulseQuestion.answersCount || '-'}
                </p>
                <p className='mb-0 w-1/12 inline-block'>{category?.label}</p>
                <div className='mb-0 w-3.7rem inline-block text-center'>
                  <button
                    onClick={() => {
                      editQuestion(pulseQuestion.id);
                    }}
                    className='text-md mt-0.25 align-top focus:outline-none hover:text-purple hover:font-bold'
                  >
                    Edit
                  </button>
                </div>
                <div className='mb-0 w-4.5rem inline-block text-center'>
                  <button
                    onClick={() => {
                      goTo(pulseQuestion.id);
                    }}
                    className='text-md mt-0.25 focus:outline-none hover:text-purple hover:font-bold'
                  >
                    Results
                  </button>
                </div>
                {appUtils.isSuperUser() ? (
                  <div className='border-purple bg-yellow tooltip inline-block ml-1'>
                    <span className='tooltip-text bg-black text-white rounded -mt-16'>
                      Superuser only
                    </span>
                    <button
                      onClick={() => {
                        deleteQuestion(pulseQuestion.id);
                      }}
                      className='text-md mt-0.25 focus:outline-none hover:text-purple hover:font-bold'
                      disabled={deleteLoading}
                    >
                      Delete
                    </button>
                  </div>
                ) : null}
              </div>
            );
          })
        : null}
    </div>
  );
};

export default PulseOverview;
