import axios from 'axios';
import { get } from 'lodash';
import apiUtils from './apiUtils';
import appUtils from '../components/appUtils';

const {
  PUBLIC_ROUTES,
  Response401,
  handle401,
  commonHeaders,
  DEFAULT_TIMEOUT
} = apiUtils;

const api = {};
const DEFAULT_API_BASE = process.env.SERVICE;

const REQUEST_ABORTED = 'REQUEST_ABORTED';
const getAborter = new AbortController();
const postAborter = new AbortController();

api.abort = (operations = ['*']) => {
  const queryClient = appUtils.getQueryClient();

  if (operations.includes('*')) {
    getAborter.abort();
    postAborter.abort();
    if (queryClient) {
      queryClient.cancelQueries();
      queryClient.cancelMutations();
    }
    return;
  }

  if (operations.includes('get')) {
    getAborter.abort();
    if (queryClient) queryClient.cancelQueries();
  }

  if (operations.includes('post')) {
    postAborter.abort();
    if (queryClient) queryClient.cancelMutations();
  }
};

// API Hook files/functions: src/queries/company.js, src/queries/tree.js, src/queries/account.js, etc.
// 1. API hookswhen API hooks call api.get(), api.post(), etc., and the API response is 401
// 2. If response is 401, return an unresolved promise so the API hook does not receive any data when calling api.get(), api.post(), etc.
// 3. In the meantime, the user is redirected to /login
const returnUnresolvedPromise = () => new Promise((resolve, reject) => {
  // console.log('do not resolve or reject promise');
  // do nothing
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const isCanceled = error.code === 'ERR_CANCELED' || error.code === 'ECONNABORTED';
    if (isCanceled) {
      console.log(
        'axios.interceptors.response request was canceled/aborted by the app'
      );
      return Promise.reject(new Error(REQUEST_ABORTED));
    }

    if (get(error, 'response.status') === 401) {
      handle401();
      return returnUnresolvedPromise();
    }

    console.log('need this `error` to get logged in rollbar..', error);
    console.error('axios.interceptors.response error', error);
    return Promise.reject(error);
  }
);

const getAuthorizationHeader = () => {
  try {
    const isLoggedIn = appUtils.isLoggedIn();
    const loggedUser = appUtils.getLoggedUser();
    const token = get(loggedUser, 'jwt.token', null);
    const { pathname } = window.location;
    const isPublicRoute = PUBLIC_ROUTES.some((publicRoute) => new RegExp(publicRoute).test(pathname));

    if (!isPublicRoute && (!isLoggedIn || !token)) {
      console.log('getAuthorizationHeader log user out');
      appUtils.logUserOut();
      return false;
    }

    if (token) {
      return {
        Authorization: JSON.stringify({
          token,
          companyid: loggedUser.companyid
        })
      };
    }

    if (isPublicRoute) {
      return { Authorization: null };
    }

    return false;
  } catch (error) {
    console.error('api.getAuthorizationHeader', error);
    throw error;
  }
};

const getBaseAPI = () => {
  try {
    const isLoggedIn = appUtils.isLoggedIn();
    const loggedUser = appUtils.getLoggedUser();
    if (isLoggedIn && loggedUser.baseAPI) {
      return loggedUser.baseAPI;
    }
    return DEFAULT_API_BASE;
  } catch (error) {
    console.error('api.getBaseAPI error', error);
  }
};

api.getXLS = async (endpoint) => {
  try {
    const authorization = getAuthorizationHeader();
    if (!authorization) {
      return console.log('api.getXLS ****** do not make api call', endpoint);
    }

    const resp = await axios.get(`${getBaseAPI()}${endpoint}`, {
      method: 'get',
      withCredentials: true,
      timeout: DEFAULT_TIMEOUT,
      responseType: 'blob',
      headers: {
        ...authorization
      },
      signal: getAborter.signal
    });

    return get(resp, 'data', null);
  } catch (error) {
    if (error.message === REQUEST_ABORTED) return;

    apiUtils.logAxiosError('[api] services/api.getXLS', error, endpoint);

    const errorMessage = get(
      error,
      'response.data.message',
      'An error occured, please try again later or contact an administrator!'
    );
    return Promise.reject(errorMessage);
  }
};

api.get = async (endpoint, queryObject) => {
  try {
    const authorization = getAuthorizationHeader();
    if (!authorization) {
      return console.log('api.get ****** do not make api call', endpoint);
    }
    if (queryObject && Object.keys(queryObject).length) {
      const queryString = Object.keys(queryObject)
        .filter((key) => queryObject[key] !== undefined)
        .map((key) => `${key}=${queryObject[key]}`)
        .join('&');
      endpoint += `?${queryString}`;
    }

    const resp = await axios.get(`${getBaseAPI()}${endpoint}`, {
      method: 'get',
      withCredentials: true,
      timeout: DEFAULT_TIMEOUT,
      headers: {
        ...commonHeaders,
        ...authorization
      },
      signal: getAborter.signal
    });

    if (appUtils.isOnReviewPage()) {
      return;
    }
    return get(resp, 'data', null);
  } catch (error) {
    if (error.message === REQUEST_ABORTED) return;

    if (get(error, 'code') === 'ERR_NETWORK') {
      return handle401();
    }

    apiUtils.logAxiosError('[api] services/api.get', {
      error,
      endpoint,
      queryObject
    });

    const errorMessage = get(
      error,
      'response.data.message',
      'An error occured, please try again later or contact an administrator!'
    );

    return Promise.reject(error);
  }
};

api.post = async (endpoint, data) => {
  try {
    const authorization = getAuthorizationHeader();
    if (!authorization) {
      return console.log('api.post ****** do not make api call', endpoint);
    }
    const resp = await axios.post(`${getBaseAPI()}${endpoint}`, data, {
      method: 'post',
      withCredentials: true,
      headers: {
        ...commonHeaders,
        ...authorization
      },
      signal: postAborter.signal
    });

    return get(resp, 'data', null);
  } catch (error) {
    if (error.message === REQUEST_ABORTED) return;

    console.log('api.post error 1', error);
    console.log('api.post error 2', endpoint);
    console.log('api.post error 3', data);
    console.error('-- [api] services/api.post', { error, endpoint, data });
    apiUtils.logAxiosError('[api] services/api.post', error, endpoint, data);

    const errorMessage = get(
      error,
      'response.data.message',
      'An error occured, please try again later or contact an administrator!'
    );
    return Promise.reject(errorMessage);
  }
};

api.patch = async (endpoint, data) => {
  try {
    const authorization = getAuthorizationHeader();
    if (!authorization) {
      return console.log('api.patch ****** do not make api call', endpoint);
    }
    const resp = await axios.patch(`${getBaseAPI()}${endpoint}`, data, {
      method: 'patch',
      withCredentials: true,
      headers: {
        ...commonHeaders,
        ...authorization
      }
    });

    return get(resp, 'data', null);
  } catch (error) {
    if (error.message === REQUEST_ABORTED) return;

    console.error('-- [api] services/api.patch', { error, endpoint, data });
    apiUtils.logAxiosError('[api] services/api.patch', error, endpoint, data);

    const errorMessage = get(
      error,
      'response.data.message',
      'An error occured, please try again later or contact an administrator!'
    );
    return Promise.reject(errorMessage);
  }
};

api.put = async (endpoint, data) => {
  try {
    const authorization = getAuthorizationHeader();
    if (!authorization) {
      return console.log('api.put ****** do not make api call', endpoint);
    }
    const resp = await axios.put(`${getBaseAPI()}${endpoint}`, data, {
      method: 'put',
      withCredentials: true,
      headers: {
        ...commonHeaders,
        ...authorization
      }
    });

    return get(resp, 'data', null);
  } catch (error) {
    if (error.message === REQUEST_ABORTED) return;

    console.error('-- [api] services/api.put', { error, endpoint, data });
    apiUtils.logAxiosError('[api] services/api.put', error, endpoint, data);

    const errorMessage = get(
      error,
      'response.data.message',
      'An error occured, please try again later or contact an administrator!'
    );
    return Promise.reject(errorMessage);
  }
};

api.postForm = async (endpoint, formData) => {
  try {
    const authorization = getAuthorizationHeader();
    if (!authorization) {
      return console.log('api.postForm ****** do not make api call', endpoint);
    }
    const resp = await axios.post(`${getBaseAPI()}${endpoint}`, formData, {
      method: 'post',
      withCredentials: true,
      headers: {
        ...commonHeaders,
        ...authorization,
        'Content-Type': 'multipart/form-data'
      }
    });

    return get(resp, 'data', null);
  } catch (error) {
    if (error.message === REQUEST_ABORTED) return;

    console.error('-- [api] services/api.postForm', { error, endpoint });
    apiUtils.logAxiosError(
      '[api] services/api.postForm',
      error,
      endpoint,
      formData
    );

    const errorMessage = get(
      error,
      'response.data.message',
      'An error occured, please try again later or contact an administrator!'
    );
    return Promise.reject(errorMessage);
  }
};

api.delete = async (endpoint, data) => {
  try {
    const authorization = getAuthorizationHeader();
    if (!authorization) {
      return console.log('api.delete ****** do not make api call', endpoint);
    }
    const resp = await axios.delete(`${getBaseAPI()}${endpoint}`, {
      data,
      method: 'delete',
      withCredentials: true,
      headers: {
        ...commonHeaders,
        ...authorization
      }
    });

    return get(resp, 'data', null);
  } catch (error) {
    if (error.message === REQUEST_ABORTED) return;

    console.error('-- [api] services/api.delete', { error, endpoint, data });
    apiUtils.logAxiosError('[api] services/api.delete', error, endpoint, data);
    const errorMessage = get(
      error,
      'response.data.message',
      'An error occured, please try again later or contact an administrator!'
    );
    return Promise.reject(errorMessage);
  }
};

export default api;
