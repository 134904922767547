import commonTreeUtils from 'common/commonTreeUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import commonPermissions from 'common/commonPermissions';

const { USER_STATE, ACCESS } = COMMON_CONSTANTS;

const utils = {};

utils.hasSlackAuthenticated = (account) => account && account.slackAuth;

utils.isVerified = (account) => account.verified;

utils.canUnarchiveAccount = (managerId, userState, roles) => {
  try {
    const VALID_USER_STATES = [
      USER_STATE.ACTIVE,
      USER_STATE.INACTIVE,
      USER_STATE.PASSIVE
    ];
    if (
      !managerId
      || !userState
      || !VALID_USER_STATES.includes(userState)
      || !roles
      || !roles.length
    ) {
      return false;
    }

    return true;
  } catch (error) {
    console.error('commonUserUtils.canUnarchiveAccount', error);
    throw error;
  }
};

utils.isVerifiedAndInTree = (account, tree) => {
  const userTree = commonTreeUtils.findNodeById(tree, account.id);
  return (
    userTree
    && utils.isVerified(account)
    && userTree.active === USER_STATE.ACTIVE
  );
};

utils.canUpdateUser = (tree, viewerAccount, user, allowSelf = false) => {
  const userId = user._id || user.id;
  const viewerId = viewerAccount._id || viewerAccount.id;
  if (allowSelf && viewerId === userId) return true;

  if (commonPermissions.canManageAccounts(viewerAccount, [userId])) return true;
  if (viewerAccount.access === ACCESS.BASIC) return false;
  if (viewerAccount.access === ACCESS.ADMIN) return true;

  if (
    viewerAccount.access === ACCESS.MANAGER
    && viewerAccount._id === user.managerId
  ) {
    return true;
  }

  if (
    viewerAccount.access === ACCESS.MANAGER
    && commonTreeUtils.isNodeDirectlyAbove(tree, user.managerId, viewerAccount._id)
  ) {
    return true;
  }

  return false;
};

export default utils;
