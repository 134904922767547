import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Modal, toast, Button } from 'src/components/';
import { useFeedbackUpdate, useFeedbackV2 } from 'src/queries/feedback';

const EditNoteModal = ({ feedbackId, close }) => {
  const {
    data: feedback,
    isFetching: isFetchingFeedback,
    isError: isErrorFeedback
  } = useFeedbackV2(feedbackId);

  const isFetching = isFetchingFeedback;
  const isError = isErrorFeedback;
  const isReady = feedback && feedback.text && !isFetching && !isError;

  const { update: updateFeedback, isLoading: isFeedbackUpdateLoading } = useFeedbackUpdate(feedbackId);
  const {
    handleSubmit,
    control,
    watch,
    formState: { isValid, isDirty },
    reset
  } = useForm({ mode: 'onChange' });

  useEffect(() => {
    if (!isReady) return;

    if (!isDirty) {
      const defaultValues = {
        text: feedback.text
      };
      reset(defaultValues);
    }
  }, [isReady]);

  if (!isReady) {
    return null;
  }

  const formState = watch();

  const saveAction = async () => {
    const { success } = await updateFeedback({
      data: { text: formState.text }
    });
    if (!success) {
      toast.error('Try again!');
    } else {
      toast.show('Note updated!');
      close();
    }
  };

  const isButtonDisabled = !isValid || isFeedbackUpdateLoading || formState.text === feedback.text;
  return (
    <Modal variant='custom-no-scroll' innerClasses='w-2/5' close={close}>
      <div className='flex flex-col'>
        <p className='font-bold mb-3 text-xl w-full'>Edit Note</p>
        <Controller
          control={control}
          name='text'
          rules={{
            required: true,
            minLength: 7
          }}
          render={({ field }) => (
            <textarea
              className='block w-full resize-none h-32'
              value={feedback.text}
              {...field}
            />
          )}
        />
        <div className='text-xs text-red italic text-left h-1'>
          {!formState.text ? 'Write text for your note' : ''}
          {formState.text && formState.text.length < 7
            ? 'Notes must be at least 7 characters long'
            : ''}
        </div>
        <div className='py-5 w-full flex justify-between'>
          <div className='w-1/2 text-left'>
            <Button classes='text-xl' variant='transparent' onClick={close}>
              Close
            </Button>
          </div>
          <div className='w-1/2 text-right'>
            <Button
              disabled={isButtonDisabled}
              variant='yellow'
              onClick={handleSubmit(saveAction)}
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditNoteModal;
