import React, { Component } from 'react';
import { connect } from 'react-redux';
import appThunks from '../../thunks/appThunks';
import Header from '../../containers/Header/Header';
import './Profile.scss';
import ProfileIcon from '../../assets/user.svg';
import SettingsIcon from '../../assets/settings.svg';
import LogOutIcon from '../../assets/log-out.svg';
import { useNavigate } from 'react-router-dom';

class Profile extends Component {
  logOut = () => {
    const { dispatch } = this.props;
    appThunks.logOut()(dispatch);
    window.location.href = '/';
  };

  render() {
    const {
      subPage,
      app: { userLogged }
    } = this.props;

    const navigate = useNavigate();
    if (subPage === '') {
      navigate('/profile/user/');
    }

    return (
      <div className='profile-page'>
        <Header />
        <div className='left-side col-xs-3'>
          <h1>User Profile</h1>
          <a
            href='/profile/user/'
            className={`menu-title ${subPage === 'user' ? 'active' : ''}`}
          >
            <ProfileIcon />
            User info
          </a>
          <a
            href='/profile/settings/'
            className={`menu-title ${subPage === 'settings' ? 'active' : ''}`}
          >
            <SettingsIcon />
            Settings
          </a>
          <a className='menu-title log-out-btn' onClick={this.logOut}>
            <LogOutIcon />
            Log Out
          </a>
        </div>
        <div className='right-side col-xs-13'></div>
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    app: state.appReducer
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
