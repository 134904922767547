import React, { useContext } from 'react';
import { appUtils, Base, toast } from 'src/components';
import PreviewInfo from 'src/pages/PerformanceBuilder/PreviewComponents/PreviewInfo';
import PreviewReviews from 'src/pages/PerformanceBuilder/PreviewComponents/PreviewReviews';
import PreviewSupplemental from 'src/pages/PerformanceBuilder/PreviewComponents/PreviewSupplemental';
import PreviewSummary from 'src/pages/PerformanceBuilder/PreviewComponents/PreviewSummary';
import { useBuilderDataset, useBuilderReports } from 'src/queries/builder';
import {
  get, isEmpty, uniqBy, uniq
} from 'lodash';
import commonUtils from 'common/commonUtils';
import { useCompanyAsDataset } from 'src/queries/company';
import commonBuilderUtils from 'common/commonBuilderUtils';
import { useNavigate } from 'react-router-dom';
import commonDateUtils from 'common/commonDateUtils';
import { renderToStaticMarkup } from 'react-dom/server';

const GroupedReportView = ({ cycleId, revieweeId }) => {
  const loggedUser = appUtils.getLoggedUser();
  const loggedUserId = appUtils.getLoggedUserId();
  const { companyid } = loggedUser;

  const {
    data: builderDataset,
    isFetching: isFetchingBuilderDataset,
    isError: isErrorBuilderDataset
  } = useBuilderDataset();

  const {
    data: companyData,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompanyAsDataset(companyid, loggedUserId);

  const {
    data,
    isFetching: isFetchingReports,
    isError: isErrorReports
  } = useBuilderReports(
    {
      cycleIds: [cycleId || 'null'],
      revieweeIds: [revieweeId]
    },
    {
      viewerId: loggedUserId,
      bundleCycleAndReviewee: true
    }
  );

  const navigate = useNavigate();
  if (!appUtils.isLoggedIn()) {
    toast.show('Please sign in to view this page');
    return navigate('/builder');
  }

  const isFetching = isFetchingReports || isFetchingCompany || isFetchingBuilderDataset;
  const isError = isErrorReports || isErrorCompany || isErrorBuilderDataset;
  const isReady = !isFetching && !isError;
  if (!isReady) return null;

  const reports = get(data, '[0].data', []);
  const [sample] = reports;

  const { reviewee } = sample;
  const { firstName: revieweeFirstName } = reviewee;
  const revieweeName = commonUtils.getFullName(reviewee);
  const reviewerNames = reports.map((report) => commonUtils.getFullName(report.reviewer));

  const { dataset } = companyData;
  const companyAsIndustry = dataset.find(
    (industryData) => industryData.industryId === companyid
  );

  const allRolesData = uniqBy(
    [
      get(companyAsIndustry, 'roles', []),
      ...builderDataset.map((industryData) => industryData.roles)
    ].flat(),
    'roleId'
  );

  const roleIds = uniq(reports.map((report) => report.roleId).filter(Boolean));

  let roleLabels = [];
  if (!isEmpty(roleIds)) {
    const rolesData = allRolesData.filter((r) => roleIds.includes(r.roleId));
    roleLabels = rolesData.map((r) => r.name);
  }

  const latestUpdatedDate = reports.reduce((latest, report) => {
    const date = new Date(report.lastUpdated);
    return date > latest ? date : latest;
  }, new Date(0));

  const reviews = [];
  const supplementalReviews = [];
  const summaries = [];

  reports.forEach((report) => {
    const reviewerName = commonUtils.getFullName(report.reviewer);
    const date = new Date(report.lastUpdated);

    const label = (
      <>
        <strong>{reviewerName}</strong>
        {date
          && ` -  ${commonDateUtils.dateToMonthDayYearFormat(new Date(date))}`}
        <br />
      </>
    );

    const populatedReviews = commonBuilderUtils
      .getPopulatedReviews(dataset, report)
      .map((review) => ({
        ...review,
        label
      }));
    reviews.push(...populatedReviews);

    const populatedSupplementalReviews = commonBuilderUtils
      .getPopulatedSupplementalReviews(dataset, report)
      .map((review) => ({
        ...review,
        label
      }));
    supplementalReviews.push(...populatedSupplementalReviews);

    if (!isEmpty(report.summary)) {
      const stringifiedLabel = renderToStaticMarkup(label);
      summaries.push(stringifiedLabel.concat(report.summary));
    }
  });

  const companyAndPublicDataset = [companyAsIndustry, ...builderDataset];
  const industryIds = dataset.map((d) => d.industryId);
  const reportIds = reports.map((r) => r._id);
  return (
    <Base classes='w-full h-[calc(100vh-3.5rem)] max-h-32 overflow-y-scroll'>
      <div className='px-8 py-8 mb-20'>
        <PreviewInfo
          mode='data'
          data={{
            revieweeName,
            reviewerNames,
            roleLabels,
            date: latestUpdatedDate,
            params: { reportIds, companyid }
          }}
        />
        <PreviewReviews
          mode='data'
          data={{
            dataset: companyAndPublicDataset,
            industryIds,
            roleIds,
            reviews
          }}
        />
        <PreviewSupplemental
          mode='data'
          data={{
            revieweeFirstName,
            dataset: companyAndPublicDataset,
            industryIds,
            roleIds,
            supplementalReviews
          }}
        />
        <PreviewSummary
          mode='data'
          data={{
            summaries
          }}
        />
      </div>
    </Base>
  );
};

export default GroupedReportView;
