import { get } from 'lodash';
import appUtils from 'src/components/appUtils';

const utils = {};

utils.Response401 = {
  success: false,
  status: 401
};
utils.DEFAULT_TIMEOUT = 60000;

utils.commonHeaders = {
  'Content-Type': 'application/json'
  // hardcoded api key for the Digital Resource local copy
  // 'x-api-key': '46f20464-d695-46d0-bffc-f13a9d4c814e'
};

utils.PUBLIC_ROUTES = [
  '/user/me',
  '/login',
  '/sign-up',
  '/recovery-email',
  '/forgot-password',
  '/recover-password',
  '/saved-password',
  '/reset-password',
  '/success',
  '/dashboard/email-review',
  '/dashboard/pulse-review',
  '/dashboard/email-goal',
  '/dashboard/onboarding',
  '/builder'
];

utils.handle401 = () => {
  try {
    console.log('apiUtils.handle401 log user out..');
    appUtils.logUserOut();
    return utils.Response401;
  } catch (error) {
    console.error('utils.handle401', error);
    throw error;
  }
};

utils.logAxiosError = (prefix, error, endpoint, requestData) => {
  try {
    console.error(prefix, 'axios error curated', {
      errorMessage: get(error, 'message', 'unknown error message'),
      errorData: get(error, 'response.data', 'unknown error'),
      errorResponse: get(error, 'response', 'unknown error response'),
      errorCode: get(error, 'code', 'unknown error code'),
      requestMethod: get(error, 'config.method', 'unknown method'),
      endpoint,
      requestData,
      pathname: get(window, 'location.pathname'),
      error
    });
  } catch (err) {
    console.error('logAxiosError', err, {
      error,
      endpoint,
      requestData
    });
  }
};

// is this not used anywhere??
// const saveData = (function () {
//   const a = document.createElement('a');
//   document.body.appendChild(a);
//   a.style = 'display: none';
//   return function (data, fileName) {
//     const json = JSON.stringify(data);
//     const blob = new Blob([json], { type: 'octet/stream' });
//     const url = window.URL.createObjectURL(blob);
//     a.href = url;
//     a.download = fileName;
//     a.click();
//     window.URL.revokeObjectURL(url);
//   };
// }());

export default utils;
