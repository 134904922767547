import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import {
  getPulseRowQuery,
  submitEmailPulseReviewQuery
} from 'src/queries/pulse';
import {
  Base, toast, Button, Checkbox
} from 'src/components/';
import appUtils from 'src/components/appUtils';
import { useForm, Controller } from 'react-hook-form';
import PULSE_CONSTANTS from 'common/pulseConstants';
import commonPulseUtils from 'common/commonPulseUtils';
import { useNavigate, useParams } from 'react-router-dom';

const { PULSE_ANONYMITY } = PULSE_CONSTANTS;
const EMAIL_PAGE_STYLE = 'right-standard m-3 sm:w-38rem md:w-45rem sm:mx-auto mb-12 p-2 px-4 sm:px-1 rounded-md bg-cloud-blue mt-0';

const Loading = () => (
  <div className='pt-10 h-20 w-full'>
    <div className={EMAIL_PAGE_STYLE}>
      <Base variant='transparent' classes='text-center pb-20 pt-20' loading>
        Loading..
      </Base>
    </div>
  </div>
);

const EmailPulseReview = () => {
  const routeParams = useParams();
  const navigate = useNavigate();
  const { pulseId: routePulseId, score, companyid } = routeParams;

  const {
    handleSubmit, watch, control, register
  } = useForm();

  const [pulseId, setPulseId] = useState(null);
  const [result, setResult] = useState(null);
  const [answer, setAnswer] = useState(null);

  const {
    data,
    refetch: refetchPulse,
    isFetching: isFetchingPulseRow,
    isError: isErrorPulseRow
  } = getPulseRowQuery({
    pulseId
  });

  const isFetching = isFetchingPulseRow;
  const isError = isErrorPulseRow;
  const isReady = data && !isFetching && !isError;

  const {
    mutateAsync: submitEmailPulseReview,
    isLoading: submitEmailPulseReviewLoading
  } = submitEmailPulseReviewQuery();

  useEffect(() => {
    if (routePulseId) {
      const { label } = routeParams;
      setPulseId(routePulseId);
      setAnswer(label);
    }
  }, [routePulseId, setPulseId, setAnswer, routeParams]);

  useEffect(() => {
    if (pulseId) {
      refetchPulse();
    }
    setResult(data);
  }, [data, pulseId, refetchPulse, setResult]);

  if (!isReady) {
    return null;
  }

  if (get(result, 'success', null) === false) {
    return (
      <div className='pt-10 h-20 w-full'>
        <div className={EMAIL_PAGE_STYLE}>
          <Base variant='transparent' classes='text-center pb-6'>
            <p className='font-bold mt-4 mb-0 text-lg'>{result.message}</p>
          </Base>
        </div>
      </div>
    );
  }

  const userName = get(result, 'userName');
  const pulseQuestionText = get(result, 'pulseQuestionText');
  if (!pulseId || !userName || !pulseQuestionText) {
    return <Loading />;
  }

  const comments = watch('comments');
  const visibility = watch('isAnonymous');

  const redirect = () => {
    const isLoggedIn = appUtils.isLoggedIn();
    if (isLoggedIn) {
      return navigate(appUtils.getDashRoute());
    }
    navigate('/login');
  };

  const submitComments = async () => {
    let anonymous = null;
    if (result.anonymity === PULSE_ANONYMITY.FORCE_ANONYMOUS) {
      anonymous = true;
    } else if (result.anonymity === PULSE_ANONYMITY.PREVENT_ANONYMOUS) {
      anonymous = false;
    } else if (PULSE_ANONYMITY.ALLOW_ANONYMOUS) {
      anonymous = visibility;
    }
    const saveResponse = await submitEmailPulseReview({
      pulseId,
      answer:
        result.pulseQuestionType !== PULSE_CONSTANTS.TYPES.FREE ? answer : null,
      comments,
      anonymous,
      companyid,
      score
    });
    if (!saveResponse || !saveResponse.success) {
      toast.error(saveResponse.message);
      return redirect();
    }
    toast.show('Answer submitted');
    redirect();
  };

  const isFreeTextQuestion = result.pulseQuestionType === PULSE_CONSTANTS.TYPES.FREE;
  return (
    <form className='pt-10 h-20 w-full' onSubmit={handleSubmit(submitComments)}>
      <div className={EMAIL_PAGE_STYLE}>
        <Base
          variant='transparent'
          loading={submitEmailPulseReviewLoading}
          classes='text-center pb-6'
        >
          {(!result || !result.success) && appUtils.isLoggedIn() ? (
            <div className='mt-6'>
              <Button
                onClick={() => {
                  navigate(appUtils.getDashRoute());
                }}
                variant='yellow'
              >
                Dashboard
              </Button>
            </div>
          ) : null}
          {result.success ? (
            <div>
              <p className='font-bold mt-4 mb-4 text-lg'>
                {result.pulseQuestionText}
              </p>
              <p>
                <span className='font-bold'>Answer:</span>
                {' '}
                {isFreeTextQuestion ? null : answer}
              </p>
              <div className='mb-8'>
                {isFreeTextQuestion ? (
                  <textarea
                    value={comments}
                    maxLength='500'
                    className='resize-none mx-auto w-full sm:w-34rem h-24 block mb-2'
                    placeholder='Write your answer here'
                    {...register('comments', {
                      required: {
                        value: true,
                        message:
                          'Please provide a longer comment to answer this question.'
                      },
                      minLength: {
                        value: commonPulseUtils.PULSE_MIN_COMMENTS_LENGTH,
                        message:
                          'Please provide a longer comment to answer this question.'
                      }
                    })}
                  />
                ) : (
                  <textarea
                    value={comments}
                    maxLength='500'
                    className='resize-none mx-auto w-full sm:w-34rem h-24 block mb-2'
                    placeholder='Do you have any additional comments?'
                    {...register('comments', {
                      required: {
                        value: true,
                        message:
                          'Please provide a longer comment to answer this question.'
                      },
                      minLength: {
                        value: commonPulseUtils.PULSE_MIN_COMMENTS_LENGTH,
                        message:
                          'Please provide a longer comment to answer this question.'
                      }
                    })}
                  />
                )}
                <div className='text-black mx-auto whitespace-nowrap'>
                  Min. 10 characters required
                </div>
              </div>

              {result.anonymity === PULSE_ANONYMITY.ALLOW_ANONYMOUS ? (
                <div>
                  <div className='flex w-auto mb-0 ml-20 pl-1'>
                    <p className='bold mb-0'>
                      Make review completely anonymous
                    </p>
                  </div>
                  <div className='w-100 sm:pl-8 md:w-38rem mx-auto mb-4 text-left mt-1'>
                    <Controller
                      name='isAnonymous'
                      control={control}
                      defaultValue={false}
                      render={({ field: { value, onChange } }) => (
                        <Checkbox
                          name='isAnonymous'
                          checked={value}
                          onChange={onChange}
                          classes='leading-normal'
                          label='Check this box to keep the review anonymous from everyone'
                        />
                      )}
                    />
                  </div>
                </div>
              ) : null}
              {result.anonymity === PULSE_ANONYMITY.FORCE_ANONYMOUS ? (
                <div>
                  <p className='bold mb-4'>
                    Your answer is completely anonymous.
                  </p>
                </div>
              ) : null}
              <Button
                variant='yellow'
                type='submit'
                disabled={!comments || comments.length < 10}
              >
                Save
              </Button>
            </div>
          ) : (
            <div className='mt-6'>
              <p className='font-bold mt-4 mb-0 text-lg'>
                We've run into an issue, try again later
              </p>
            </div>
          )}
        </Base>
      </div>
    </form>
  );
};

export default EmailPulseReview;
