import React, { useContext } from 'react';
// import { SelectTwo } from 'src/components/Select/Select';
import Multiselect from 'src/components/Select/Multiselect';
import { NewUserContext } from 'src/containers/NewUser/context/NewUserContext';
import { useCompany } from 'src/queries/company';

const SelectGroups = () => {
  const {
    state: { newData },
    updateContext
  } = useContext(NewUserContext);
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const addGroup = (g) => {
    const ng = newData.groups ? [...newData.groups, g.id] : [g.id];
    updateContext({ newData: { ...newData, groups: ng } });
  };

  const removeGroup = (g) => {
    const ng = newData.groups.filter((n) => n !== g.id);
    updateContext({ newData: { ...newData, groups: ng } });
  };

  const toggleGroup = (g) => {
    const groupExists = newData.groups.find((n) => n === g.id);
    if (groupExists) {
      return removeGroup(g);
    }
    return addGroup(g);
  };

  const groups = company.groups.map((g) => ({
    ...g,
    label: g.name,
    checked: newData.groups && newData.groups.find((i) => i === g.id)
  }));

  return (
    <div>
      <span className='inline-block min-w-44 text-left'>Groups</span>
      <div className='inline-block'>
        <Multiselect
          classes='w-80'
          multiSelectSelectedItemClasses='w-8.5rem'
          options={groups}
          placeholder='Select groups'
          variant='minimal'
          onChange={(option, type) => {
            if (type === 'select') {
              return toggleGroup(option);
            }
            if (type === 'remove') {
              return removeGroup(option);
            }
          }}
        />
      </div>
    </div>
  );
};

export default SelectGroups;
