/* eslint-disable import/prefer-default-export */
import { useContext } from 'react';
import { DashTeamContext } from 'src/pagesDashboard/DashTeam/context/DashTeamProvider';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import { useTeamScore } from 'src/queries/score';
import commonTreeUtils from 'common/commonTreeUtils';
import commonQuestions from 'common/commonQuestions';
import { useReviews } from 'src/queries/reviews';

import COMMON_CONSTANTS from 'common/commonConstants';
import { populateCategoryData } from 'src/utils/populateCategories';
import { useAccountsScore } from 'src/queries/account';
import { isNumber } from 'lodash';
import { appUtils } from 'src/components/index';

const { CATEGORIES_VIEW } = COMMON_CONSTANTS;

export const useTeamProfile = () => {
  const { context } = useContext(DashTeamContext);
  const {
    data: { tree, myTreeRow },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const range = context.range.value;
  const {
    userIds,
    category,
    showBundledCategories,
    range: { start, end }
  } = context;

  const managerId = context && context.managerId ? context.managerId : null;
  const filters = {
    range,
    start,
    end,
    category
  };
  const { teamHistoricReviews } = context;

  const teamScoreOptions = {
    showBundledCategories: showBundledCategories
      ? CATEGORIES_VIEW.CATEGORIES_BUNDLED
      : CATEGORIES_VIEW.CATEGORIES_NOT_BUNDLED
  };
  const {
    data: teamScore,
    isFetching: isFetchingScores,
    isError: isErrorScores
  } = useTeamScore({
    teamId: undefined,
    managerId,
    userIds,
    filters,
    options: teamScoreOptions
  });

  const rolesFilter = null;
  const { categories: bundledCategories, roles: rolesData } = populateCategoryData(
    teamScore,
    company,
    rolesFilter,
    showBundledCategories
  );

  let userGroups = tree && tree.id && managerId
    ? commonTreeUtils.getUserGroupsForUser(tree, managerId)
    : null;
  if (userIds && userIds.length) {
    userGroups = { employees: userIds };
  }

  let employeeIds = userGroups ? userGroups.employees : [];
  const viewerId = myTreeRow ? myTreeRow.id : null;
  employeeIds = employeeIds.filter((eid) => eid !== viewerId);

  const membersData = [];
  const teamCategories = [];

  employeeIds.forEach((employeeId) => {
    // members
    const employeeTree = commonTreeUtils.findNodeById(tree, employeeId);
    const employeeStat = teamScore && teamScore.reviewers
      ? teamScore.reviewers.find((rObj) => rObj.userId === employeeId)
      : null;
    membersData.push({
      id: employeeTree.id,
      name: employeeTree.name,
      imageUrl: employeeTree.imageUrl,
      score: employeeStat ? employeeStat.score : null
    });

    // categories
    const employeeCategories = company
      ? commonQuestions.getRoleCategories(employeeTree.roles, company.questions)
      : [];
    if (employeeCategories) {
      employeeCategories.forEach((c) => {
        if (!teamCategories.find((e) => e.id === c.id)) {
          const found = teamScore
            && teamScore.categories
            && c.id
            && teamScore.categories.find(
              (f) => f.categoryId && f.categoryId.toString() === c.id.toString()
            );
          if (found) {
            c.score = found.score;
            c.reviews = found.count;
          } else {
            c.score = null;
            c.reviews = 0;
          }
          teamCategories.push(c);
        }
      });
    }
  });

  // get historic reviews
  const reviewFilters = {
    reviewerIds: undefined,
    reviewerGroup: undefined,
    category,
    range,
    start,
    end,
    includeEmptyComments: true,
    includeNA: true,
    includeAnonymous: true,
    visible: false
  };

  const {
    data: { reviews: teamReviews, pagination },
    isFetching: isFetchingReviews,
    isError: isErrorReviews
  } = useReviews({
    userId: userIds,
    managerId,
    filters: reviewFilters,
    options: {
      page: teamHistoricReviews.page,
      size: teamHistoricReviews.pageSize
    }
  });

  const {
    data: activityScoresData,
    isFetching: isFetchingActivityScores,
    isError: isErrorActivityScores
  } = useAccountsScore(
    {
      managerId,
      ...(start && {
        start,
        end
      })
    },
    {
      page: {
        size: employeeIds.length
      }
    },
    {
      enabled: Boolean(employeeIds.length)
    }
  );

  const activityScores = {
    avgScoreGiven: null,
    avgSentimentGiven: null,
    avgSentimentReceived: null,
    reviewedGivenCount: 0,
    scoredGivenCount: 0
  };
  if (!isFetchingActivityScores) {
    let totalScoreGiven = 0;
    let scoreReviewersCount = 0;
    let totalSentimentGiven = 0;
    let sentimentReviewersCount = 0;
    let totalSentimentReceived = 0;
    let sentimentRevieweesCount = 0;
    activityScoresData.forEach((score) => {
      if (isNumber(score.avgScoreGiven)) {
        totalScoreGiven += score.avgScoreGiven;
        scoreReviewersCount += 1;
      }
      if (isNumber(score.avgSentimentGiven)) {
        totalSentimentGiven += score.avgSentimentGiven;
        sentimentReviewersCount += 1;
      }
      if (isNumber(score.avgSentimentReceived)) {
        totalSentimentReceived += score.avgSentimentReceived;
        sentimentRevieweesCount += 1;
      }
      activityScores.reviewedGivenCount += score.reviewedGivenCount;
      activityScores.scoredGivenCount += score.scoredGivenCount;
    });
    activityScores.avgScoreGiven = totalScoreGiven / scoreReviewersCount;
    activityScores.avgSentimentGiven = totalSentimentGiven / sentimentReviewersCount;
    activityScores.avgSentimentReceived = totalSentimentReceived / sentimentRevieweesCount;
  }

  return {
    bundledCategories,
    rolesData,
    teamScore,
    pagination,
    categories: teamCategories,
    members: membersData,
    teamReviews,
    activityScores,
    isFetching:
      isFetchingScores
      || isFetchingActivityScores
      || isFetchingReviews
      || isFetchingTree
      || isFetchingCompany,
    isError:
      isErrorScores
      || isErrorActivityScores
      || isErrorReviews
      || isErrorTree
      || isErrorCompany
  };
};
