import React from 'react';
import COMMON_CONSTANTS from 'common/commonConstants';
import { Header } from '../../containers';
import { TextLink } from '../../components';
import './Page404.scss';

const { PUBLIC_BUCKET_URL } = COMMON_CONSTANTS;

const Page404 = () => (
  <div className='page404-page'>
    <Header />
    <div className='text-center'>
      <img
        className='error-404-image m-auto'
        src={`${PUBLIC_BUCKET_URL}/404_image.png`}
      />
      <h1>Oops, this page isn't available...</h1>
      <h2>The page you were looking for couldn't be found.</h2>
      <div className='action-links' />
    </div>
  </div>
);

export default Page404;
