import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import { Base, appUtils } from 'src/components';
import commonDateUtils from 'common/commonDateUtils';
import commonGoalUtils from 'common/commonGoalUtils';
import TagSVG from 'src/assets/svg/tag.svg';
import AddGoalUpdate from 'src/containers/UserProfile/Goals/AddGoalUpdate';
import EditGoalUpdate from 'src/containers/UserProfile/Goals/EditGoalUpdate';
import PencilSVG from 'src/assets/svg/pencil.svg';
import CrossSVG from 'src/assets/cross.svg';
import STYLE from 'src/constants/style';
import { useGoal, QUERY_KEYS } from 'src/queries/goal';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import commonViewPermissions from 'common/commonViewPermissions';
import commonQuestions from 'common/commonQuestions';
import { useAccount } from 'src/queries/account';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import COMMON_CONSTANTS from 'common/commonConstants';
import MobileViewGoal from 'src/pagesDashboard/GoalSettings/mobile/ViewGoal';
import { useQueryClient } from 'react-query';

const { APP_SIZES } = COMMON_CONSTANTS;

const ViewGoal = ({ goalId }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [goalCommentIdToEdit, setGoalCommentIdToEdit] = useState(null);
  const close = () => {
    const params = new URLSearchParams(window.location.search);
    const redirectUrl = params.get('redir');
    if (redirectUrl === '/dashboard/goals') {
      navigate(`${redirectUrl}?keepFilters=true`);
    } else if (redirectUrl) {
      navigate(redirectUrl);
    } else {
      window.history.back();
    }
  };

  const {
    isFetching: isFetchingGoal,
    isError: isErrorGoal,
    data: goal
  } = useGoal(goalId);
  const {
    data: assigneeData,
    isFetching: isFetchingGoalAssigneeData,
    isError: isErrorGoalAssigneeData
  } = useAccount(goal.assignee);
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const {
    isFetching: isFetchingParentGoal,
    isError: isErrorParentGoal,
    data: parentGoal
  } = useGoal(goal.parentId);
  const {
    data: parentGoalAssigneeData,
    isFetching: isFetchingParentGoalAssigneeData,
    isError: isErrorParentGoalAssigneeData
  } = useAccount(parentGoal.assignee);

  const isFetching = isFetchingGoal
    || isFetchingGoalAssigneeData
    || isFetchingTree
    || isFetchingCompany
    || isFetchingParentGoal
    || isFetchingParentGoalAssigneeData;
  const isError = isErrorGoal
    || isErrorGoalAssigneeData
    || isErrorTree
    || isErrorCompany
    || isErrorParentGoal
    || isErrorParentGoalAssigneeData;

  const isReady = tree && tree.id && company && company.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  if (isEmpty(goal)) {
    return (
      <Base classes='p-5'>
        <p className='mb-0'>Goal not found.</p>
      </Base>
    );
  }

  const category = goal.categoryId
    ? commonQuestions.getCategory(goal.categoryId, company.questions.CATEGORIES)
    : {
      value: null,
      label: 'No category'
    };

  const assignee = goal.assignee
    ? assigneeData
    : {
      id: null,
      name: 'Unassigned'
    };

  const parentGoalAssignee = parentGoal.assignee
    ? parentGoalAssigneeData
    : {
      id: null,
      name: 'Unassigned'
    };

  const loggedUserId = appUtils.getLoggedUserId();
  const canAddUpdate = commonViewPermissions.canManageGoal(
    tree,
    loggedUserId,
    goal.assignee
  );

  const mobileSize = appUtils.getMobileSize();
  const isMobile = mobileSize;

  return (
    <div>
      {isMobile ? (
        <MobileViewGoal goal={goal} />
      ) : (
        <>
          <Base classes={STYLE.CONTAINER_WHITE}>
            {goalCommentIdToEdit ? (
              <EditGoalUpdate
                goalId={goalId}
                updateId={goalCommentIdToEdit}
                close={() => {
                  setGoalCommentIdToEdit(null);
                  queryClient.invalidateQueries(QUERY_KEYS.GOALS);
                }}
              />
            ) : null}
            <div className='w-full align-top text-left flex flex-col'>
              <div className='mb-3'>
                <div className='w-8 h-8 inline-block'>
                  <TagSVG />
                </div>
                <div className='align-top float-right font-bold text-lg flex flex-col'>
                  <button onClick={close}>
                    <CrossSVG className='w-8 h-8 cursor-pointer' />
                  </button>
                </div>
              </div>
              <h5 className='text-black focus:outline-none text-xl w-full flex justify-between mr-5 mb-0'>
                <div className='max-w-16.5/20'>{goal.title}</div>
                <div>{assignee.name}</div>
              </h5>
              <div className='max-w-16.5/20'>
                <div
                  className='ql-editor'
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(goal.description)
                  }}
                />
              </div>
              <div className='text-md my-2'>
                <div className='w-60'>
                  <div>{`Category: ${category.label}`}</div>
                </div>
                <div className='inline-block text-left align-top'>
                  {!isEmpty(parentGoal)
                    ? `Parent goal: ${parentGoal.title} (${parentGoalAssignee.name})`
                    : null}
                  <div>
                    {`Progress: ${commonGoalUtils.getGoalProgressLabel(goal)}`}
                  </div>
                </div>
                <div className='w-60'>
                  <div>
                    {`Due: ${commonDateUtils.dateToMonthDayYearFormat(
                      new Date(goal.deadline)
                    )}`}
                  </div>
                </div>
              </div>
            </div>
          </Base>
          <Base classes={STYLE.CONTAINER_WHITE}>
            <div>
              <div className='mt-4'>
                <p className='font-bold mb-3 text-xl w-full'>Goal updates</p>
                <div className='mb-2 py-1 px-1 bg-background-gray text-black font-bold rounded-sm'>
                  <p className='mb-0 pl-1 w-2/12 inline-block font-bold'>
                    Date
                  </p>
                  <p className='mb-0 w-2/12 inline-block font-bold'>Name</p>
                  <p className='mb-0 w-2/12 inline-block font-bold'>Progress</p>
                  <p className='mb-0 w-4/12 inline-block font-bold'>Comments</p>
                </div>
                <div className='flex flex-col-reverse'>
                  {goal.updates.length
                    ? goal.updates.map((update) => (
                      <div
                        className='mb-2 py-1 px-1 text-black font-bold rounded-sm'
                        key={update._id}
                      >
                        <p className='mb-0 pl-1 w-2/12 inline-block'>
                          {commonDateUtils
                            .unixToMonthDayYearFormat(update.timestamp)
                            .toString()}
                        </p>
                        <p className='mb-0 w-2/12 inline-block'>
                          {update.authorAccount.name}
                        </p>
                        <p className='mb-0 w-2/12 inline-block'>
                          {commonGoalUtils.getGoalProgressLabel({
                            type: goal.type,
                            progress: update.progress
                          })}
                        </p>
                        <p className='mb-0 w-4/12 inline-block'>
                          {update.text}
                        </p>
                        <p className='mb-0 w-1/12 inline-block text-right'>
                          {canAddUpdate ? (
                            <button
                              onClick={() => setGoalCommentIdToEdit(update._id)}
                            >
                              <PencilSVG width='24px' height='24px' />
                            </button>
                          ) : null}
                        </p>
                      </div>
                    ))
                    : 'No updates yet'}
                </div>
              </div>
            </div>
          </Base>
          {canAddUpdate ? (
            <Base classes={STYLE.CONTAINER_WHITE}>
              <AddGoalUpdate goal={goal} />
            </Base>
          ) : null}
        </>
      )}
    </div>
  );
};

export default ViewGoal;
