import React, { useContext } from 'react';
import { isNil, get, isEmpty } from 'lodash';
import Category from 'src/pages/PerformanceBuilder/BuilderComponents/Category';
import { PerformanceBuilderContext } from 'src/pages/PerformanceBuilder/PerformanceBuilderContext';
import { appUtils } from 'src/components/index';

const PerformanceCategories = () => {
  const {
    data: { dataset, reviewMode, prevDataRef }
  } = useContext(PerformanceBuilderContext);

  const prevData = prevDataRef.current;
  const { industry, role } = prevData;

  const industryData = dataset.find((data) => data.industryId === industry.id);
  const roleData = industryData.roles.find((data) => data.roleId === role.id);
  let { categories } = roleData;

  // specific order for the categories
  if (role.id === '70196e3b-6e41-4521-883b-70823b9a6e14') {
    const sortedCategories = [
      categories[10],
      categories[0],
      categories[11],
      categories[1],
      categories[2],
      categories[12],
      categories[3],
      categories[4],
      categories[5],
      categories[6],
      categories[7],
      categories[8],
      categories[9]
    ].filter((category) => !isNil(category));
    categories = sortedCategories;
  }

  const isLoggedIn = appUtils.isLoggedIn();
  const revieweeId = get(prevDataRef.current, 'revieweeId');
  return (
    <>
      <div
        id='performance-data-section'
        className='mb-8 border-b rounded border-[#E2E8F0]'
      />
      <div className='flex justify-between'>
        <h2>Performance Categories</h2>
        {isLoggedIn && !isEmpty(revieweeId) ? (
          <a
            className='underline text-purple'
            target="_blank"
            rel="noopener noreferrer"
            href={appUtils.getDashRoute(revieweeId)}
          >
            See Historic Feedback
          </a>
        ) : null}
      </div>
      {categories.map((category, index) => (
        <div key={index}>
          <Category
            category={category}
            nextCategoryId={categories[index + 1]?.categoryId || null}
            reviewMode={reviewMode}
            key={category.categoryId}
          />
        </div>
      ))}
    </>
  );
};

export default PerformanceCategories;
