import React from 'react';
import { Modal, toast, Button } from 'src/components/';
import { useFeedbackDelete } from 'src/queries/feedback';

const DeleteNoteModal = ({ feedbackId, close }) => {
  const { delete: deleteFeedback, isLoading: isFeedbackDeleteLoading } = useFeedbackDelete(feedbackId);

  const saveAction = async () => {
    const response = await deleteFeedback();
    if (!response || !response.success) {
      toast.error('Try again!');
    } else {
      toast.show('Note deleted!');
      close();
    }
  };

  return (
    <Modal variant='custom-no-scroll' innerClasses='w-1/5' close={close}>
      <div className='flex flex-col'>
        <p className='font-bold mb-3 text-xl w-full'>
          Are you sure you want to delete this note?
        </p>
        <div className='py-5 w-full flex justify-between'>
          <div className='w-1/2 text-left'>
            <Button classes='text-xl' variant='transparent' onClick={close}>
              Close
            </Button>
          </div>
          <div className='w-1/2 text-right'>
            <Button
              disabled={isFeedbackDeleteLoading}
              variant='yellow'
              onClick={saveAction}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteNoteModal;
