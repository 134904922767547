import React, { useState } from 'react';
import { get } from 'lodash';
import { Base } from 'src/components/';
import { useCompanyDashboard } from 'src/pagesDashboard/CompanyDash/queries/queries';
import GroupModal from 'src/containers/CompanyDash/GroupsStats/GroupModal';
import commonUtils from 'common/commonUtils';
import STYLE from 'src/constants/style';
import { useNavigate } from 'react-router-dom';

const GroupsStats = () => {
  const { groups, groupsAsReviewers, isFetchingScores } = useCompanyDashboard();
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  return (
    <Base classes={STYLE.CONTAINER_WHITE} loading={isFetchingScores}>
      {showModal ? (
        <GroupModal group={showModal} close={() => setShowModal(false)} />
      ) : null}
      <div className='w-full flex justify-between mb-6'>
        <h5 className='w-1/2 text-xl'>Groups</h5>
        <button
          type='button'
          onClick={() => navigate(`/dashboard/organization/settings?scrollTo=groups`)}
          className='text-purple mr-2 text-lg font-bold'
        >
          Create group
        </button>
      </div>

      <div className='ml-5 mr-4 pb-2 mb-3'>
        <div className='pl-2 grid grid-cols-7 pb-1'>
          <p className='font-bold mb-0 col-span-2'>Group Name</p>
          <p className='font-bold mb-0'>Score</p>
          <p className='font-bold mb-0'>Change</p>
          <p className='font-bold mb-0'>Reviews done</p>
          <p className='font-bold mb-0'>Comments given</p>
          <p className='font-bold mb-0'>Participation</p>
        </div>
        <div className='border-b border-transparent-grey mr-2 mb-2' />
        {!groups || !groups.length ? (
          <>
            <p className='text-dark-grey text-center mt-6 mb-2 font-normal'>
              No groups have been designated.
            </p>
            <p className='text-dark-grey text-center mt-0 mb-4 font-normal'>
              Assign groups to team members on the
              {' '}
              <a
                className='font-bold pt-1 text-purple'
                onClick={() => navigate('/dashboard/organization/chart')}
              >
                Organization
              </a>
              {' '}
              page.
            </p>
          </>
        ) : (
          groups.map((d, index) => {
            const changeUnderZero = d.change && d.change < 0;
            const changeOverZero = d.change && d.change > 0;

            const groupAsReviewer = groupsAsReviewers.find(
              (g) => g.groupId === d.groupId
            );
            const completionPercent = get(groupAsReviewer, 'completionAverage')
              ? `${groupAsReviewer.completionAverage}%`
              : '-';
            const reviewsCompleted = get(groupAsReviewer, 'reviewed', '-');
            const richReviewsCompleted = get(groupAsReviewer, 'rich', '-');

            return (
              <div key={index} className='pl-2 grid grid-cols-7 pb-2 mb-3'>
                <a onClick={() => setShowModal(d)} className='mb-0 col-span-2'>
                  {d.groupName}
                </a>
                <p className='mb-0'>{d.score || '-'}</p>
                <p
                  className={`font-bold mb-0 ${
                    changeOverZero > 0 ? 'text-purple' : ''
                  } ${changeUnderZero ? 'text-red' : ''}`}
                >
                  {d.change !== undefined && d.change !== null
                    ? changeOverZero
                      ? `+${d.change}`
                      : d.change
                    : ''}
                </p>
                <p className='mb-0'>
                  {commonUtils.getToLocaleStringValue(reviewsCompleted)}
                </p>
                <p className='mb-0'>
                  {commonUtils.getToLocaleStringValue(richReviewsCompleted)}
                </p>
                <p className='mb-0'>{completionPercent}</p>
              </div>
            );
          })
        )}
      </div>
    </Base>
  );
};

export default GroupsStats;
