import React, { useContext, useState } from 'react';
import { PerformanceBuilderContext } from 'src/pages/PerformanceBuilder/PerformanceBuilderContext';
import {
  useUpdateBuilderReport,
  QUERY_KEYS as BUILDER_QUERY_KEYS
} from 'src/queries/builder';
import COMMON_CONSTANTS from 'common/commonConstants';
import { Button } from 'src/components';
import { ActionModal } from 'src/componentsTailwind/index';
import { useQueryClient } from 'react-query';

const { BUILDER_REPORT_STATUS } = COMMON_CONSTANTS;

const SubmitReviewBanner = () => {
  const {
    data: {
      reportId, isAnyEmpty, editToken, companyMode
    }
  } = useContext(PerformanceBuilderContext);
  const isCompanySpecific = companyMode === 'company';

  const [isSubmitReviewModalOpen, setIsSubmitReviewModalOpen] = useState(false);

  const queryClient = useQueryClient();
  const {
    update: updateBuilderReport,
    isLoading: isUpdateBuilderReportLoading
  } = useUpdateBuilderReport(editToken);

  return (
    <>
      <ActionModal
        isOpen={isSubmitReviewModalOpen}
        close={() => setIsSubmitReviewModalOpen(false)}
        title="Are you sure you'd like to submit?"
        content='This indicates that you’re done writing your review.'
        action1Text='Submit Review'
        onAction1={async () => {
          await updateBuilderReport({
            data: { status: BUILDER_REPORT_STATUS.COMPLETE }
          });
          queryClient.invalidateQueries(BUILDER_QUERY_KEYS.BUILDER_DASHBOARD);
          queryClient.invalidateQueries([BUILDER_QUERY_KEYS.BUILDER, reportId]);
          setIsSubmitReviewModalOpen(false);
        }}
        action2Text='Cancel'
        onAction2={() => setIsSubmitReviewModalOpen(false)}
        isLoading={isUpdateBuilderReportLoading}
      />
      <div className='bg-[#f6f6f6] flex items-center -mx-8 p-8 w-[(calc(100%+64px))] justify-between -mt-12'>
        <div className='w-1/2'>
          <p className='m-0 text-gray-500'>
            Done with your evaluation? Make sure to submit the review for this
            review cycle.
          </p>
        </div>
        <div className='w-fit'>
          <Button
            variant='purple'
            classes='px-16 !rounded-md text-sm py-2'
            disabled={
              (isCompanySpecific && isAnyEmpty)
              || isSubmitReviewModalOpen
              || isUpdateBuilderReportLoading
            }
            onClick={() => setIsSubmitReviewModalOpen(true)}
          >
            Submit Review
          </Button>
        </div>
      </div>
    </>
  );
};

export default SubmitReviewBanner;
