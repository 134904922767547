import React, { useEffect, useState, useRef } from 'react';
import { useQueryClient } from 'react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { get, debounce, unionWith } from 'lodash';
import {
  Button, Base, toast, appUtils, Select
} from 'src/components';
import { useTree } from 'src/queries/tree';
import {
  useCompany,
  QUERY_KEYS as COMPANY_QUERY_KEYS
} from 'src/queries/company';
import commonTreeUtils from 'common/commonTreeUtils';
import commonQuestions from 'common/commonQuestions';
import COMMON_QUESTION_CONSTANTS from 'common/commonQuestionConstants';
import CategoryOption from 'src/containers/UserProfile/TopScores/CategoryOption';
import commonReviewUtils from 'common/commonReviewUtils';
import { useAccount, useAccounts } from 'src/queries/account';
import { newQuestionQuery } from 'src/queries/questions';
import MobileSelectGiveFeedback from 'src/pagesDashboard/SelectFeedback/mobile/SelectGiveFeedback';
import COMMON_CONSTANTS from 'common/commonConstants';
import STYLE from 'src/constants/style';

const { USER_STATE, ACCESS, DIRECT_REVIEW_PERMISSIONS } = COMMON_CONSTANTS;

const PAGE_ROUTES = {
  FEEDBACK: 'feedback',
  REVIEW: 'review'
};

const SelectGiveFeedback = ({ type = PAGE_ROUTES.FEEDBACK }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const revieweeId = searchParams.get('revieweeId');

  const isReview = type === PAGE_ROUTES.REVIEW;

  const queryClient = useQueryClient();

  const { textAlternativeOnRequestFeedbackPage } = useFlags();

  const { mutateAsync: addQuestion, isLoading: isLoadingAddQuestion } = newQuestionQuery();

  const {
    data: loggedAccount,
    isFetching: isFetchingLoggedAccount,
    isFetched: isFetchedLoggedAccount,
    isError: isErrorLoggedAccount
  } = useAccount('me');

  const {
    data: defaultReviewee,
    isFetching: isFetchingDefaultReviewee,
    isError: isErrorDefaultReviewee
  } = useAccount(
    revieweeId,
    {
      projection: ['name']
    },
    { enabled: Boolean(revieweeId) }
  );

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany,
    refetch: refetchCompany,
    isRefetching: isRefetchingCompany
  } = useCompany({
    keepPreviousData: true,
    isSelectFeedback: true
  });

  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const loggedUser = appUtils.getLoggedUser();
  const isAdmin = loggedUser.access === ACCESS.ADMIN;
  const isManager = loggedUser.access === ACCESS.MANAGER;
  const canReviewAnyone = isAdmin
    || isManager
    || get(company, 'settings.directReviewPermissions')
      === DIRECT_REVIEW_PERMISSIONS.OPEN;

  const [revieweeIds, setRevieweeIds] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [feedbackReviewees, setFeedbackReviewees] = useState([]);
  const [feedbackSelectTitle, setFeedbackSelectTitle] = useState('');
  const [isCheckedQuestionsEmpty, setIsCheckedQuestionsEmpty] = useState(true);
  const [checkedQuestions, setCheckedQuestions] = useState([]);
  const [selectedRevieweeData, setSelectedRevieweeData] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [revieweeOptions, setRevieweeOptions] = useState(null);

  const cleanupRef = useRef(() => {});

  const { data: revieweeAccounts, isFetching: isFetchingRevieweeAccounts } = useAccounts(
    {
      ...(!canReviewAnyone ? { ids: revieweeIds } : {}),
      status: [USER_STATE.ACTIVE, USER_STATE.PASSIVE]
    },
    {
      search: {
        enabled: true,
        field: 'name',
        value: searchText
      },
      page: { size: 100 },
      projection: ['name']
    },
    {
      enabled: Boolean(canReviewAnyone || revieweeIds.length)
    }
  );

  const isInitalFetchingCompany = isFetchingCompany && !isRefetchingCompany;
  const isFetching = isFetchingLoggedAccount
    || isInitalFetchingCompany
    || isFetchingTree
    || isFetchingDefaultReviewee;
  const isError = isErrorLoggedAccount
    || isErrorCompany
    || isErrorTree
    || isErrorDefaultReviewee;
  const isReady = company && company.id && tree && tree.id && !isFetching && !isError;

  useEffect(() => () => cleanupRef.current(), []);

  useEffect(() => {
    if (isFetchedLoggedAccount) {
      setRevieweeIds(
        loggedAccount.reviews.map((relationship) => relationship.userId)
      );
    }
  }, [isFetchedLoggedAccount]);

  const canReviewDefaultReviewee = canReviewAnyone || revieweeIds.includes(revieweeId);
  const shouldSetDefaultReviewee = Boolean(
    revieweeId && canReviewDefaultReviewee
  );
  useEffect(() => {
    // if user choose to give review but is not allowed to, redirect to feedback
    if (
      revieweeId
      && defaultReviewee._id
      && !canReviewDefaultReviewee
      && type
    ) {
      const searchQ = `?revieweeId=${revieweeId}&type=${PAGE_ROUTES.FEEDBACK}`;
      toast.error(
        `You do not have permission to review ${defaultReviewee.name}`
      );
      return setTimeout(
        () => navigate(`/dashboard/submit-feedback${searchQ}`),
        500
      );
    }
  }, [revieweeId, defaultReviewee._id, canReviewDefaultReviewee, type]);

  const onChangeReviewee = (reviewee, updateType) => {
    if (updateType === 'feedback') {
      setFeedbackReviewees(reviewee);
      return;
    }
    setSelectedRevieweeData(reviewee);

    setSelectedRole(null);
    setCheckedQuestions([]);
    setIsCheckedQuestionsEmpty(true);
  };

  useEffect(() => {
    if (shouldSetDefaultReviewee && !isFetchingDefaultReviewee) {
      setSelectedRevieweeData({
        value: defaultReviewee._id,
        label: defaultReviewee.name,
        data: defaultReviewee
      });
    }
  }, [shouldSetDefaultReviewee, isFetchingDefaultReviewee, defaultReviewee]);

  useEffect(() => {
    setSelectedRole(null);
    setCheckedQuestions([]);
    setIsCheckedQuestionsEmpty(true);

    const defaultReviewees = feedbackReviewees.map((item) => ({
      ...item,
      checked: false
    }));
    setFeedbackReviewees(defaultReviewees);
  }, [type]);

  useEffect(() => {
    if (isReady && shouldSetDefaultReviewee && !isFetchingDefaultReviewee) {
      setSelectedRevieweeData({
        value: defaultReviewee._id,
        label: defaultReviewee.name,
        data: defaultReviewee
      });
    }
  }, [
    isReady,
    shouldSetDefaultReviewee,
    isFetchingDefaultReviewee,
    defaultReviewee
  ]);

  useEffect(() => {
    if (type === PAGE_ROUTES.FEEDBACK && feedbackReviewees.length) {
      const updatedReviewees = feedbackReviewees.map((reviewee) => {
        if (reviewee.id === revieweeId) {
          return { ...reviewee, checked: true };
        }
        return reviewee;
      });
      setFeedbackReviewees(updatedReviewees);
    }
  }, [feedbackReviewees.length, revieweeId, type]);

  useEffect(() => {
    if (revieweeAccounts && revieweeAccounts.length) {
      const sortedRevieweeAccounts = revieweeAccounts.toSorted((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
      const reviewAccsOptions = sortedRevieweeAccounts.map((reviewee) => ({
        id: reviewee._id,
        label: reviewee.name,
        data: reviewee,
        checked: false
      }));
      const feedbackAccsOptions = sortedRevieweeAccounts.map((reviewee) => ({
        id: reviewee._id,
        label: reviewee.name,
        checked: false,
        color: '#727272'
      }));

      const selectedFeedbackReviewees = feedbackReviewees.filter(
        (item) => item.checked
      );

      setRevieweeOptions(reviewAccsOptions);
      setFeedbackReviewees(
        unionWith(
          selectedFeedbackReviewees,
          feedbackAccsOptions,
          (item1, item2) => item1.id === item2.id
        )
      );
    }
  }, [revieweeAccounts, searchText]);

  const selectedFeedbackReviewees = feedbackReviewees.filter(
    (item) => item.checked
  );

  useEffect(() => {
    const revieweeNames = selectedFeedbackReviewees.map(
      (reviewee) => reviewee.label
    );
    let title = '';
    if (revieweeNames.length === 1) {
      [title] = revieweeNames;
    } else if (revieweeNames.length > 1 && revieweeNames.length <= 3) {
      title = [...revieweeNames].splice(0, 3).join(', ');
    } else if (revieweeNames.length > 3) {
      title = ` ${revieweeNames.length} Team Members selected`;
    } else {
      title = 'Select Team Member(s)';
    }
    setFeedbackSelectTitle(title);
  }, [selectedFeedbackReviewees]);

  const COMPANY_QUESTIONS = company.questions;
  const selectedReviewee = selectedRevieweeData?.data;

  if (!isReady) {
    return null;
  }

  const roleOptions = [];
  if (selectedReviewee && selectedReviewee._id) {
    let roleIds;
    if (canReviewAnyone) {
      const revieweeNode = commonTreeUtils.findNodeById(
        tree,
        selectedReviewee._id
      );
      roleIds = revieweeNode.roles;
    } else {
      const reviewRelationship = loggedAccount.reviews.find(
        (relationship) => relationship.userId === selectedReviewee._id
      );
      roleIds = reviewRelationship.roles;
    }
    roleIds.forEach((roleId) => {
      const role = commonQuestions.getRoleById(roleId, COMPANY_QUESTIONS);
      roleOptions.push({
        id: roleId,
        label: role.label
      });
    });
  }

  const categoryOptions = [];
  if (selectedRole) {
    const roleCategories = commonQuestions.getRoleCategories(
      [selectedRole.id],
      COMPANY_QUESTIONS
    );
    roleCategories.forEach((category) => {
      categoryOptions.push({
        ...category,
        questionObjects: category.questions
          .filter(
            (qid) => commonQuestions.getQuestion(qid, COMPANY_QUESTIONS.QUESTIONS)
              .status === COMMON_QUESTION_CONSTANTS.STATUS.ACTIVE
          )
          .map((qid) => commonReviewUtils.getDirectReviewQuestion({
            name: selectedReviewee.name,
            frequency: company.emailFrequency,
            isSelfReview: selectedReviewee._id === loggedAccount._id,
            roleId: selectedRole.id,
            questionData: {
              questionId: qid,
              categoryId: category.id
            },
            COMPANY_QUESTIONS
          }))
      });
    });
  }

  const goToReview = async () => {
    if (!company.active) {
      return toast.error('Company is not active.');
    }

    const feedbackRevieweesIds = selectedFeedbackReviewees
      .map((reviewee) => reviewee.id)
      .join(',');

    const urlQuestions = JSON.stringify(checkedQuestions);
    const url = isReview
      ? `/dashboard/submit-review/${selectedReviewee._id}/${selectedRole.id}/${urlQuestions}`
      : `/dashboard/submit-feedback/${feedbackRevieweesIds}/feedback`;

    return navigate(url);
  };

  const reviewCount = checkedQuestions.length;
  const areDataFieldsEmpty = (isReview && !selectedReviewee)
    || (isReview && !selectedRole)
    || (!isReview && !selectedFeedbackReviewees.length);

  const isSubmitDisabled = areDataFieldsEmpty
    || (isReview && isCheckedQuestionsEmpty)
    || isLoadingAddQuestion;

  const submitButtonTitle = `Give Feedback ${reviewCount > 1 ? `(${reviewCount})` : ''}`;

  const isMobile = appUtils.getMobileSize();

  const loadingProps = {
    isLoadingAddQuestion,
    isRefetchingCompany
  };

  const stateProps = {
    setCheckedQuestions,
    setIsCheckedQuestionsEmpty,
    setSelectedRole
  };

  const searchFn = (value) => setSearchText(value);

  const revieweeProps = {
    revieweeOptions,
    feedbackReviewees,
    selectedRevieweeData,
    onChangeReviewee,
    searchFn,
    isFetchingRevieweeAccounts,
    revieweeId
  };

  const titleText = textAlternativeOnRequestFeedbackPage
    ? 'Select questions to base your review(s) on – please choose at least two areas of strength and at least two areas of development'
    : 'Select questions to base your review request(s) on';

  return (
    <div>
      {!isMobile ? (
        <Base classes={STYLE.CONTAINER}>
          <div className='min-h-80 bg-white'>
            <div className='mb-4'>
              <span className='font-semibold text-black'>
                {isReview ? 'Reviewee' : 'Reviewee(s)'}
              </span>
              {isReview ? (
                <Select
                  classes='w-full text-black'
                  title={selectedReviewee?.name ?? 'Select a Team Member'}
                  options={revieweeOptions}
                  placeholder='Select Team Member'
                  onChange={(option) => onChangeReviewee(option, 'review')}
                  showSearch
                  onSearch={debounce(searchFn, 200)}
                  onDropdownClose={() => setSearchText('')}
                  loading={isFetchingRevieweeAccounts}
                />
              ) : (
                <Select
                  classes='w-full text-black'
                  options={feedbackReviewees}
                  placeholder='Select Team Member(s)'
                  multiselect
                  title={feedbackSelectTitle ?? 'Select Team Member(s)'}
                  showSearch
                  onChange={(option) => {
                    const foundInOption = feedbackReviewees.find(
                      (opt) => opt.id === option.id
                    );
                    if (foundInOption) {
                      const newOptions = feedbackReviewees.map((opt) => {
                        if (opt.id === foundInOption.id) {
                          return { ...opt, checked: !opt.checked };
                        }
                        return opt;
                      });
                      onChangeReviewee(newOptions, 'feedback');
                    }
                  }}
                  onSearch={debounce(searchFn, 200)}
                  onDropdownClose={() => searchFn('')}
                  loading={isFetchingRevieweeAccounts}
                />
              )}
            </div>
            {selectedReviewee && isReview ? (
              <div>
                {roleOptions.length ? (
                  <div className='mb-4'>
                    <span className='font-semibold text-black'>Role</span>

                    <Select
                      classes='w-full text-black'
                      title={
                        selectedRole ? selectedRole.label : 'Select a Role'
                      }
                      options={roleOptions}
                      placeholder='Select Team Member'
                      onChange={(roleOption) => {
                        setCheckedQuestions([]);
                        setIsCheckedQuestionsEmpty(true);
                        setSelectedRole(roleOption);
                      }}
                      loading={isFetchingRevieweeAccounts}
                    />
                  </div>
                ) : (
                  <div className='h-80 flex items-center'>
                    <span className='font-base'>
                      You do not review
                      {' '}
                      {selectedReviewee.name}
                      {' '}
                      by any roles.
                      Please adjust review relationships if necessary
                    </span>
                  </div>
                )}
                {selectedRole ? (
                  <div className='mb-4'>
                    <span className='font-semibold text-black'>
                      {titleText}
                    </span>
                    {categoryOptions.map((category) => (
                      <CategoryOption
                        key={category.id}
                        category={category}
                        checkedQuestions={checkedQuestions}
                        setCheckedQuestions={setCheckedQuestions}
                        setIsButtonDisabled={setIsCheckedQuestionsEmpty}
                        showCustomQuestion
                        isLoadingSaveCustomQuestion={
                          isLoadingAddQuestion || isRefetchingCompany
                        }
                        onSaveCustomQuestion={async (questionObject) => {
                          const { success, questionId } = await addQuestion({
                            questionObject,
                            categoryId: category.id
                          });

                          if (!success) return toast.error('Failed to add question');

                          cleanupRef.current = () => {
                            queryClient.invalidateQueries(
                              COMPANY_QUERY_KEYS.COMPANY
                            );
                            queryClient.invalidateQueries(
                              COMPANY_QUERY_KEYS.COMPANY_QUESTIONS
                            );
                          };

                          refetchCompany();
                          setCheckedQuestions((prev) => [
                            ...prev,
                            {
                              categoryId: category.id,
                              questionId
                            }
                          ]);
                          setIsCheckedQuestionsEmpty(false);
                        }}
                      />
                    ))}
                  </div>
                ) : null}
              </div>
            ) : null}
            <div className='flex justify-start text-center mt-12 mb-4'>
              <Button
                data-test-id="give-feedback-to-reviewees"
                onClick={goToReview}
                disabled={isSubmitDisabled}
                variant='black'
                classes='!border-0 !rounded-lg'
              >
                {submitButtonTitle}
              </Button>
            </div>
          </div>
        </Base>
      ) : (
        <MobileSelectGiveFeedback
          revieweeProps={revieweeProps}
          addQuestion={addQuestion}
          goToReview={goToReview}
          submitButtonTitle={submitButtonTitle}
          isSubmitDisabled={isSubmitDisabled}
          isReview={isReview}
          selectedRole={selectedRole}
          titleText={titleText}
          categoryOptions={categoryOptions}
          checkedQuestions={checkedQuestions}
          stateProps={stateProps}
          loadingProps={loadingProps}
          refetchCompany={refetchCompany}
          roleOptions={roleOptions}
        />
      )}
    </div>
  );
};

export default SelectGiveFeedback;
