/* eslint-disable no-param-reassign */
import React, { cloneElement } from 'react';

const ButtonV2 = ({
  disabled = false,
  children = '',
  text = '',
  icon = null,
  onClick = () => {},
  onPressEnter = () => {},
  color = 'yellow',
  paddingClasses = 'px-2 py-1',
  textClasses = 'font-semibold text-sm',
  sizeClasses = ''
}) => {
  if (typeof children === 'string') text = children;

  let colorClasses = '';
  if (disabled) {
    textClasses = ' text-white';
    colorClasses = `bg-state-disabled`;
  } else {
    if (['yellow'].includes(color)) textClasses = ' text-black';
    else textClasses = ' text-white';
    colorClasses = `bg-primary-${color} hover:bg-secondary-${color}`;
  }

  return (
    <button
      className={`flex items-center justify-center gap-1 rounded-md focus:outline-none min-w-fit min-h-fit transition duration-500 ${paddingClasses} ${sizeClasses} ${textClasses} ${colorClasses}`}
      disabled={disabled}
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter') onPressEnter();
      }}
    >
      {icon ? (
        <div className='flex w-fit h-full'>
          {cloneElement(icon, { className: 'w-full h-full' })}
        </div>
      ) : null}
      <p className='m-0 w-full text-center'>{text}</p>
    </button>
  );
};

export default ButtonV2;
