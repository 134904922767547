import React, { useContext } from 'react';
import { QuestionBuilderContext } from 'src/pagesDashboard/QuestionBuilder/context/QuestionBuilderProvider';
import COMMON_QUESTION_CONSTANTS from 'common/commonQuestionConstants';

const { QUESTION_ANSWER_TYPES } = COMMON_QUESTION_CONSTANTS;

const QuestionPreviewAnswers = () => {
  const {
    context,
    context: { left, right },
    dispatch: dispatchContext
  } = useContext(QuestionBuilderContext);

  if (
    !context.answers.type
    || context.answers.type === QUESTION_ANSWER_TYPES.ONE_TO_TEN
  ) {
    return (
      <>
        <div className='mb-6'>
          {left === '' ? (
            <span>
              <i>Left</i>
            </span>
          ) : (
            <span>{left}</span>
          )}
          {right === '' ? (
            <span className='float-right'>
              <i>Right</i>
            </span>
          ) : (
            <span className='float-right'>{right}</span>
          )}
        </div>
        <div className='text-center'>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num, index) => (
            <p key={index} className='inline-block w-10'>
              {num}
            </p>
          ))}
        </div>
      </>
    );
  }

  if (context.answers.type === QUESTION_ANSWER_TYPES.ONE_TO_FIVE) {
    return (
      <>
        <div className='mb-6'>
          {left === '' ? (
            <span>
              <i>Left</i>
            </span>
          ) : (
            <span>{left}</span>
          )}
          {right === '' ? (
            <span className='float-right'>
              <i>Right</i>
            </span>
          ) : (
            <span className='float-right'>{right}</span>
          )}
        </div>
        <div className='text-center'>
          {[1, 2, 3, 4, 5].map((num, index) => (
            <p key={index} className='inline-block w-16'>
              {num}
            </p>
          ))}
        </div>
      </>
    );
  }

  if (context.answers.type === QUESTION_ANSWER_TYPES.CUSTOM) {
    return (
      <>
        <div className='mb-6'>
          {left === '' ? (
            <span>
              <i>Left</i>
            </span>
          ) : (
            <span>{left}</span>
          )}
          {right === '' ? (
            <span className='float-right'>
              <i>Right</i>
            </span>
          ) : (
            <span className='float-right'>{right}</span>
          )}
        </div>
        <div className='text-center'>
          {context.answers.custom.map((obj, index) => (
            <p
              key={index}
              className='shadow hover:bg-hover-purple cursor-default inline-block w-20 text-sm truncate text-ellipsis mx-2 bg-purple rounded text-white'
            >
              {obj.value}
            </p>
          ))}
        </div>
      </>
    );
  }
};

export default QuestionPreviewAnswers;
