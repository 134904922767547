import React, { useState, useEffect } from 'react';
import { CompanyDashContext } from 'src/pagesDashboard/CompanyDash/context/Provider';
import { useAccountsParticipation } from 'src/queries/account';
import { Base, Pagination, Tooltip } from 'src/components/';
import { useTree } from 'src/queries/tree';
import commonUtils from 'common/commonUtils';
import Arrow from 'src/assets/keyboard_arrow_up.svg';
import InformationCircleSVG from 'src/assets/svg/informationCircle.svg';
import STYLE from 'src/constants/style';
import COMMON_CONSTANTS from 'common/commonConstants';
import utils from './utils';

const { USER_STATE } = COMMON_CONSTANTS;

const ParticipationStats = ({
  range,
  categoryId = null,
  managerId = null,
  accountIds = null
}) => {
  const {
    data: { myTreeRow },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState('desc');
  const {
    data: reviewers,
    meta: {
      page: { totalPages }
    },
    isFetching: isFetchingAccountsParticipation,
    isError: isErrorAccountsParticipation
  } = useAccountsParticipation(
    {
      managerId,
      categoryId,
      ids: accountIds,
      start: range.start,
      end: range.end,
      notStatus: [USER_STATE.UNASSIGNED]
    },
    {
      page: {
        number: currentPage,
        size: 10
      },
      sort: {
        order: sort
      },
      include: {
        account: true
      }
    }
  );

  const isFetching = isFetchingAccountsParticipation || isFetchingTree;
  const isError = isErrorAccountsParticipation || isErrorTree;
  const isReady = myTreeRow && myTreeRow.id && reviewers && !isFetching && !isError;

  useEffect(() => {
    setCurrentPage(1);
  }, [range, sort]);

  if (!isReady) {
    return null;
  }

  return (
    <Base
      classes={`${STYLE.CONTAINER_WHITE} min-h-41rem justify-between`}
      loading={!isReady}
    >
      <div className='min-h-38rem'>
        <h5 className='w-full text-xl mb-6 flex'>
          Review Participation
          <Tooltip>
            <div className='tooltip-text bg-black text-white w-25rem ml-5'>
              <div className='h-full flex flex-col items-start pl-2 pt-2 pr-4 pb-4'>
                <p className='mb-1 font-bold'>Definitions:</p>
                <div className='flex flex-col items-start'>
                  <ul className='list-outside list-disc ml-5 mb-0'>
                    <li className='text-justify mb-3'>
                      <p className='m-0'>
                        <span className='font-bold'>Reviews Completed </span>
                        {`- number of reviews answered as well as "I'm not sure" reviews`}
                      </p>
                    </li>
                    <li className='text-justify my-3'>
                      <p className='m-0'>
                        <span className='font-bold'>Feedback Comments </span>
                        -
                        number of reviews comments provided when answering
                        reviews
                      </p>
                    </li>
                    <li className='text-justify mt-3'>
                      <p className='m-0'>
                        <span className='font-bold'>{`"I'm Not Sure" Reviews `}</span>
                        - number of reviews deemed not applicable, at the time,
                        by the reviewer
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Tooltip>
        </h5>
        <div className='ml-5 mr-4 pb-2 mb-3'>
          {reviewers && reviewers.length ? (
            <>
              <div className='flex pl-2 pb-1'>
                <p className='w-1/4 mb-0 font-bold'>Name</p>
                <div className='flex justify-start w-1/4 mb-0'>
                  <p className=' font-bold m-0 max-w-3/4'>Reviews Completed</p>
                  <button
                    onClick={() => {
                      setSort(sort === 'desc' ? 'asc' : 'desc');
                    }}
                    className={`focus:outline-none transform ${
                      sort === 'desc' && 'rotate-180'
                    }`}
                  >
                    <Arrow />
                  </button>
                </div>
                <p className='w-1/4 mb-0 font-bold'>Feedback Comments</p>
                <p className='w-1/4 mb-0 font-bold'>{`"I'm Not Sure" Reviews`}</p>
              </div>
              <div className='border-b border-transparent-grey mr-2 mb-2' />
              <div className='w-full flex flex-col'>
                {reviewers.map((d, index) => {
                  if (
                    !d.account
                    || d.account.status === USER_STATE.UNASSIGNED
                  ) {
                    return (
                      <div key={index} className='flex pl-2 pb-2 mb-3'>
                        <p className='w-1/4 mb-0 overflow-ellipsis truncate italic'>
                          User Unassigned
                        </p>
                        <p className='w-1/4 m-0'>-</p>
                        <p className='w-1/4 m-0'>-</p>
                        <p className='w-1/4 m-0'>-</p>
                      </div>
                    );
                  }

                  const routeLink = utils.getUserProfileLink(
                    {
                      name: d.account.name,
                      userId: d.account._id
                    },
                    myTreeRow
                  );
                  const reviewsCompleted = d.sent && d.sent > 0
                    ? `${commonUtils.getToLocaleStringValue(d.reviewed)} / ${
                      d.sent
                    }`
                    : '-';
                  const feedbackComments = d.reviewed && d.reviewed > 0 ? (
                    `${commonUtils.getToLocaleStringValue(d.rich)} / ${
                      d.reviewed
                    }`
                  ) : (
                    <span className='italic text-sm'>
                      No reviews completed
                    </span>
                  );
                  const notSureReviews = d.reviewed && d.reviewed > 0 ? (
                    `${commonUtils.getToLocaleStringValue(d.notSure)} / ${
                      d.reviewed
                    }`
                  ) : (
                    <span className='italic text-sm'>
                      No reviews completed
                    </span>
                  );
                  return (
                    <div key={index} className='flex pl-2 pb-2 mb-3'>
                      <a
                        href={routeLink}
                        className={`w-1/4 mb-0 overflow-ellipsis truncate ${
                          !d.account.name ? 'italic' : ''
                        }`}
                      >
                        {d.account.name || 'Other'}
                      </a>
                      <p className='w-1/4 m-0'>{reviewsCompleted}</p>
                      <p className='w-1/4 m-0'>{feedbackComments}</p>
                      <p className='w-1/4 m-0'>{notSureReviews}</p>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            isReady && (
              <p className='text-center mt-8 mb-6 min-h-22rem'>
                No reviewers found.
              </p>
            )
          )}
        </div>
      </div>
      <Pagination
        name='Review Participation'
        totalPages={totalPages}
        currentPage={currentPage}
        selectPage={setCurrentPage}
      />
    </Base>
  );
};

export default ParticipationStats;
