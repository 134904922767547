import React, { useCallback, useEffect, useState } from 'react';
import {
  get, isEmpty, debounce, isNumber
} from 'lodash';
import { Base, Pagination } from 'src/components';
import appUtils from 'src/components/appUtils';
import { CircleItems, Flyout } from 'src/componentsTailwind/index';
import CircledIcon from 'src/components/CircledIcon/CircledIcon';
import ReviewOutlinedSVG from 'src/assets/svg/review-outlined.svg';
import { useCompany } from 'src/queries/company';
import { useUserFeedback } from 'src/containers/UserProfile/FeedbackDashboard/queries';
import commonDateUtils from 'common/commonDateUtils';
import { ITEM_TYPES } from 'src/componentsTailwind/CircleItems/CircleItems';
import commonQuestionUtils from 'common/commonQuestionsUtils';
import SpinnerSVG from 'src/assets/spinner.svg';
import { QUERY_KEYS as FEEDBACK_QUERY_KEYS } from 'src/queries/feedback';

const buildFeedbackFeed = (queryKey) => {
  const queryClient = appUtils.getQueryClient();
  const queryCache = queryClient.getQueryCache();
  const cachedQueries = queryCache.findAll(queryKey);
  const feedbacks = [];

  cachedQueries.forEach((query) => {
    const { data: queryData = {} } = query.state;
    const { feedbacks: data = [] } = queryData;
    feedbacks.push(...data);
  });
  return feedbacks;
};

const CategoryFeedbackFlyout = (props = {}) => {
  const {
    categoryId = null,
    userId = appUtils.getLoggedUserId(),
    viewerId = appUtils.getLoggedUserId(),
    range = {},
    filters = {},
    isOpen
  } = props;
  const queryClient = appUtils.getQueryClient();
  const baseQueryKey = [
    FEEDBACK_QUERY_KEYS.FEEDBACK,
    userId,
    categoryId,
    viewerId
  ];

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const [currentPage, setCurrentPage] = useState(1);
  const {
    data: { pagination },
    isError: isErrorFeedbacks
  } = useUserFeedback(
    userId,
    {
      range,
      filters: {
        ...filters,
        ...(categoryId && { category: categoryId })
      },
      pages: {
        page: currentPage,
        pageSize: 10
      },
      ...(viewerId && { viewerId })
    },
    {
      queryKey: [...baseQueryKey, currentPage],
      enabled: Boolean(userId && categoryId)
    }
  );

  const isFetching = isFetchingCompany;
  const isError = isErrorFeedbacks || isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError;
  const totalPages = get(pagination, 'pages', 0);

  const isFetchingAnyFeedback = Boolean(queryClient.isFetching(baseQueryKey));

  // const handleScroll = useCallback(
  //   (event) => {
  //     if (
  //       event.deltaY > 0
  //       && Math.round(window.innerHeight + window.scrollY)
  //         >= document.body.offsetHeight
  //       && !isFetchingAnyFeedback
  //       && currentPage < totalPages
  //     ) {
  //       setCurrentPage(currentPage + 1);
  //     }
  //   },
  //   [currentPage, totalPages, isFetchingAnyFeedback]
  // );

  useEffect(() => {
    setCurrentPage(1);
    // if (isOpen) {
    //   window.addEventListener('wheel', handleScroll, true);
    // } else {
    //   window.removeEventListener('wheel', handleScroll, true);
    // }
  }, [isOpen]);

  if (!isReady) {
    return null;
  }

  const feedbacks = buildFeedbackFeed(baseQueryKey);
  const items = feedbacks.map((f) => ({
    type: ITEM_TYPES.TEXT,
    label: f.reviewerName,
    underlabel: (
      <span className='w-full flex justify-between items-center'>
        <p className='m-0 whitespace-nowrap'>{`Shared ${commonDateUtils.dateToTimeAgo(f.createdDate || f.reviewedDate)}`}</p>
      </span>
    ),
    text: f.text,
    imageSrc: appUtils.getProfilePictureSrc(f.reviewerImage)
  }));

  const companyQuestions = company.questions;
  const category = commonQuestionUtils.findCategory(
    companyQuestions,
    categoryId
  );

  return (
    <Flyout {...props}>
      <Base classes='h-[100%] w-full' relative>
        <CircledIcon svg={<ReviewOutlinedSVG className='text-purple' />} />
        <p className='text-xl text-black font-bold mb-0 mr-8'>
          Feedback Details
        </p>
        <p className='m-0 text-sm font-normal mt-2 mb-4 leading-5 text-bluish-gray'>
          Feedback related to the
          {' '}
          {get(category, 'label', 'selected')}
          {' '}
          category.
        </p>
        <div className='border-b border-mid-gray my-3 transform -translate-x-[32px] w-[calc(100%+64px)]' />
        <div>
          <CircleItems items={items} />
        </div>
        <div className='flex w-full justify-center h-8 pt-2'>
          {isFetchingAnyFeedback && <SpinnerSVG className='w-16 h-16' />}
          {!isFetchingAnyFeedback && currentPage < totalPages ? (
            <>
              {/* <p className='m-0'>Scroll down to load more</p> */}
              <button
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
                disabled={isFetchingAnyFeedback}
              >
                Load more
              </button>
            </>
          ) : null}
        </div>
      </Base>
    </Flyout>
  );
};

export default CategoryFeedbackFlyout;
