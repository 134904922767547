import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import uuidv4 from 'uuid/v4';
import { useCompany } from 'src/queries/company';
import { TreeButton } from './components';
import { toast } from 'src/components';
import { treeActions } from '../../reducers/actions';
import utils from './utils';
import { ACCESS } from '../../constants';
import appUtils from '../../components/appUtils';
import { useTree } from 'src/queries/tree';
import { useAccount } from 'src/queries/account';
import CircleV2 from 'src/components/Circle/CircleV2';
import commonViewPermissions from 'common/commonViewPermissions';
import commonTreeUtils from 'common/commonTreeUtils';
import './Tree.scss';
import NodeTooltip from 'src/containers/Tree/NodeTooltip';

let dragStartId = null;
class ClassTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      cardType: null,
      direction: null,
      hoveredNodeId: null,
      newCard: {
        name: '',
        email: '',
        title: '',
        access: ACCESS.BASIC,
        reviewing: [],
        reviewedBy: [],
        cardType: null
      }
    };
  }

  onDrop = (e) => {
    const { tree } = this.props;
    const targetId = e.target.getAttribute('data-nodeid');
    const source = utils.findNode(tree, dragStartId);
    const target = utils.findNode(tree, targetId);
    dragStartId = null;
    if (!source || !target) {
      return;
    }
    const tempSourceOrder = source.order;
    source.order = target.order;
    target.order = tempSourceOrder;
    const { dispatch } = this.props;
    dispatch(treeActions.setTree(tree));
  };

  deleteNode = (node) => {
    if (!node.parent) {
      console.warn(`can't delete root node`);
      return;
    }
    const parent = node.parent;
    let index = -1;
    parent.children.forEach((child, i) => {
      if (child.id === node.id) {
        index = i;
      }
    });
    parent.children.splice(index, 1);
    const { dispatch, tree } = this.props;
    dispatch(treeActions.setTree(tree));
  };

  dragStart = (e) => {
    e.target.classList.add('dragging');
    dragStartId = e.target.getAttribute('data-nodeid');
  };

  dragEnd = (e) => {
    e.target.classList.remove('dragging');
  };

  addNode = (direction, node, e) => {
    if (!e.target.className.includes('plus')) {
      return;
    }
    const { dispatch } = this.props;
    dispatch(treeActions.showTreeNodeModalAddNode(node));
    this.props.openUserDetails(node.id);
    appUtils.scrollToTop();
  };

  editNode = (node, e) => {
    if (e.target.className.includes('plus')) {
      return;
    }

    const { tree, loggedAccount, company } = this.props;
    const { viewUserProfile } = company.settings;

    if (
      commonViewPermissions.canAccessUserDashboard(
        loggedAccount,
        node,
        tree,
        viewUserProfile
      )
    ) {
      return this.toggleCard(node, e);
    }
    toast.show('You do not have access to view this team member.');
  };

  toggleCard = (node, event) => {
    if (
      event &&
      event.target &&
      event.target.className &&
      ['plus'].indexOf(event.target.className) > -1
    )
      return;

    this.props.navigate(appUtils.getDashRoute(node.id, 'information/profile'));
  };

  renderNode = (node, depth, props) => {
    const { tree, loggedAccount, company } = props;

    if (!node) {
      return null;
    }
    if (!node.id) {
      node.id = uuidv4();
    }
    let newChildren = node.children || [];
    if (node.order && node.children) {
      newChildren = node.children.sort((a, b) => {
        return a.order - b.order;
      });
    }

    const showBumpButton = false;
    const showLeftArrow = false;

    const isNodeAdmin = node.access === ACCESS.ADMIN;
    const isNodeManager = node.access === ACCESS.MANAGER;

    const isNodeAbove = () => {
      commonTreeUtils.isNodeDirectlyAbove(tree, node.id, loggedAccount._id);
    };

    const canAddNode = () => {
      if (loggedAccount.access === ACCESS.ADMIN) return true;

      const isAbove = isNodeAbove();

      return (
        loggedAccount.access === ACCESS.MANAGER &&
        (node.id === loggedAccount._id || isAbove)
      );
    };

    const visibleDepth = this.props.visibleDepth + 1;

    return (
      <li className={`node`} data-nodeid={node.id} key={node.id}>
        {depth === 1 && node.managerId ? (
          <button
            onClick={() => {
              this.props.openAbove(node);
            }}
            className='block mb-3 w-6 h-6 mx-auto hover:text-orange focus:outline-none'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
              />
            </svg>
            <div className='mx-auto w-0 h-4 border border-black' />
          </button>
        ) : null}
        <a
          className={`tooltip node-button ${node.bumped ? 'bumped' : ''} ${
            isNodeAdmin ? 'is-admin' : ''
          } ${isNodeManager ? 'is-manager' : ''} outline-none`}
          data-nodeid={node.id}
          data-email={node.email}
          draggable
          onClick={(e) => this.editNode(node, e)}
          onDrop={(e) => {
            this.onDrop(e);
          }}
          onDragOver={(e) => {
            e.preventDefault();
          }}
          onDragStart={(e) => {
            this.dragStart(e);
          }}
          onDragEnd={(e) => {
            this.dragEnd(e);
          }}
          onMouseEnter={() =>
            this.setState({ ...this.state, hoveredNodeId: node.id })
          }
          onMouseLeave={() =>
            this.setState({ ...this.state, hoveredNodeId: null })
          }
        >
          {this.state.hoveredNodeId === node.id ? (
            <NodeTooltip nodeId={node.id} />
          ) : null}
          <>
            {showBumpButton && (
              <button
                data-nodeid={node.id}
                onClick={() => {
                  this.bump(node.id);
                }}
                className='bump-me'
              />
            )}
            {showLeftArrow && node.parent && (
              <TreeButton
                data-nodeid={node.id}
                side='left'
                onClick={(e) => {
                  this.addNode('left', node, e);
                }}
              />
            )}
            {canAddNode() ? (
              <TreeButton
                data-nodeid={node.id}
                side='bottom'
                onClick={(e) => {
                  this.addNode('bottom', node, e);
                }}
              />
            ) : null}
            {node.bumped ? <p data-nodeid={node.id} className='bumpy' /> : null}
            <div
              data-nodeid={node.id}
              className='flex max-h-[63px] max-w-[165px]'
            >
              <div className='min-h-fit min-w-fit -ml-7'>
                <CircleV2 size='md' imageUrl={node.imageUrl || ''} />
              </div>
              <div
                className='flex flex-col items-start justify-center max-w-[135.25px] h-[63px] text-start'
                data-nodeid={node.id}
              >
                <p
                  data-nodeid={node.id}
                  className='m-0 text-[15px] leading-[24px] text-ellipsis overflow-hidden whitespace-nowrap w-full px-2'
                >
                  {node.name}
                </p>
                <p
                  data-nodeid={node.id}
                  className='m-0 text-[13px] leading-[24px] text-ellipsis overflow-hidden whitespace-nowrap w-full px-2'
                >
                  {node.title || ''}
                </p>
              </div>
            </div>
          </>
        </a>
        {depth + 1 < visibleDepth && newChildren.length ? (
          <ul>
            {newChildren.map((child) => {
              return this.renderNode(child, depth + 1, props);
            })}
          </ul>
        ) : null}
        {depth + 1 >= visibleDepth && newChildren.length ? (
          <ul>
            <button
              onClick={() => {
                this.props.openBelow(node);
              }}
              className='pl-1 w-6 h-6 mx-auto hover:text-purple focus:outline-none'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                />
              </svg>
            </button>
          </ul>
        ) : null}
      </li>
    );
  };

  render() {
    let { classes, treeNode } = this.props;

    return (
      <div className={`tree ${classes}`}>
        {this.state.message}

        <ul>{treeNode ? this.renderNode(treeNode, 1, this.props) : null}</ul>
      </div>
    );
  }
}

const Tree = (props) => {
  const navigate = useNavigate();
  const {
    data: loggedAccount,
    isFetching: isFetchingLoggedAccount,
    isError: isErrorLoggedAccount
  } = useAccount('me');
  const {
    data: { tree } = {},
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const isFetching =
    isFetchingCompany || isFetchingTree || isFetchingLoggedAccount;
  const isError = isErrorCompany || isErrorTree || isErrorLoggedAccount;
  const isReady =
    company && company.id && tree && tree.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  return (
    <ClassTree
      loggedAccount={loggedAccount}
      tree={tree}
      company={company}
      navigate={navigate}
      {...props}
    />
  );
};

export const mapStateToProps = (state) => {
  return {
    app: state.appReducer
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tree);
