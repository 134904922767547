import React, { useEffect } from 'react';
import { useCreateWizard, useWizard } from 'src/queries/Wizard/wizard';
import SummaryAICard from 'src/containers/UserProfile/Wizard/components/SummaryAICard';
import SummaryAICardV2 from 'src/containers/UserProfile/Wizard/components/SummaryAICardV2';
import NoSummaryCard from 'src/containers/UserProfile/Wizard/components/NoSummaryCard';
import commonDateUtils from 'common/commonDateUtils';
import STYLE from 'src/constants/style';
import { Base } from 'src/components';
import COMMON_CONSTANTS from 'common/commonConstants';
import Spinner from 'src/assets/spinner.svg';

const { WIZARD_SUMMARIES_RANGE } = COMMON_CONSTANTS;

const UserProfileWizard = ({
  account,
  range = WIZARD_SUMMARIES_RANGE.LAST_MONTH,
  version = 'V1'
}) => {
  const {
    createWizardSummary,
    data,
    isLoading: isCreateWizardSummaryLoading
  } = useCreateWizard();
  const {
    data: aiSummaries,
    isFetched: isFetchedSummaries,
    isFetching: isFetchingSummaries,
    isError: isErrorSummaries
  } = useWizard({
    filters: { account },
    options: {
      sort: {
        field: 'end',
        order: 'desc'
      },
      size: range.value,
      stringify: true
    }
  });

  const isFetching = isFetchingSummaries;
  const isError = isErrorSummaries;
  const isReady = aiSummaries && !isFetching && !isError;

  const aiSummariesMonths = aiSummaries.map((i) => commonDateUtils.unixToMonth(i.end));
  const currentDate = commonDateUtils.dateToUnix(new Date());
  const currentDateMonth = commonDateUtils.unixToMonth(currentDate);

  const noSummariesForCurrentMonth = isFetchedSummaries && !aiSummariesMonths.includes(currentDateMonth);
  useEffect(() => {
    if (noSummariesForCurrentMonth) {
      createWizardSummary(account);
    }
  }, [isFetchedSummaries, aiSummaries.length]);

  if (!isReady) return null;

  const noSummariesAvailable = isFetchedSummaries
    && !aiSummaries.length
    && data
    && data.message
      === 'Requirements to create a summary recommendation are not met.';

  if (version === 'V2') {
    return !isFetchedSummaries || noSummariesAvailable ? null : (
      <Base
        classes={`${STYLE.CONTAINER_WHITE_PADDINGLESS} p-2`}
        loading={!isFetchedSummaries || isCreateWizardSummaryLoading}
      >
        {aiSummaries.length
          ? aiSummaries.map((s, index) => (
            <div key={index}>
              <SummaryAICardV2
                wizardId={s.id}
                strings={s.strings}
                endDate={s.end}
                collapsable={index > 0}
              />
            </div>
          ))
          : null}
      </Base>
    );
  }

  return (
    <Base
      classes={`${STYLE.CONTAINER_WHITE_PADDINGLESS} relative min-h-15rem`}
      loading={!isFetchedSummaries || isCreateWizardSummaryLoading}
    >
      {aiSummaries.length
        ? aiSummaries.map((s, index) => (
          <div key={index}>
            <SummaryAICard
              wizardId={s.id}
              text={s.html}
              endDate={s.end}
              collapsable={index > 0}
            />
          </div>
        ))
        : null}
      {noSummariesAvailable ? (
        <div>
          <NoSummaryCard account={account} />
        </div>
      ) : null}
    </Base>
  );
};

export default UserProfileWizard;
