import React, { useContext, useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TeamContext } from 'src/pagesDashboard/Team/context/TeamProvider';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import { Base, toast, Pagination } from 'src/components';
import COMMON_CONSTANTS from 'common/commonConstants';
import commonTreeUtils from 'common/commonTreeUtils';
import CONSTANTS from 'src/pagesDashboard/Team/context/constants';
import FilterByLetter from 'src/components/FilterByLetter/FilterByLetter';
import appUtils from 'src/components/appUtils';
import STYLE from 'src/constants/style';
import UnarchiveAccountModal from 'src/containers/Team/List/UnarchiveAccountModal';
import { useAccount, useAccounts } from 'src/queries/account';
import commonViewPermissions from 'common/commonViewPermissions';
import { Table } from 'src/componentsTailwind';
import { TYPES } from 'src/componentsTailwind/Table/Table';
import { BADGE_COLOR_CLASSES } from 'src/componentsTailwind/tailwindConstants';
import { useNavigate } from 'react-router-dom';

const { VIEWS } = CONSTANTS;

const {
  USER_STATE, COMMUNICATION_TYPES, ACCESS, GET_ACCOUNT_SPEC_OPS
} = COMMON_CONSTANTS;

const TeamList = () => {
  const {
    textAlternativePeoplePageManagerColumn,
  } = useFlags();
  const [currentLetter, setCurrentLetter] = useState('ALL');
  const [currentPage, setCurrentPage] = useState(1);
  const [accountIdToUnarchive, setAccountIdToUnarchive] = useState(null);
  const { state: context } = useContext(TeamContext);

  const {
    data: loggedAccount,
    isFetching: isFetchingLoggedAccount,
    isError: isErrorLoggedAccount
  } = useAccount('me');

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const {
    data: { tree, treeList },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const filters = {
    status: [USER_STATE.ACTIVE, USER_STATE.INACTIVE, USER_STATE.PASSIVE]
  };

  if (context.view === VIEWS.MY_TEAM) {
    filters.specs = [
      {
        op: GET_ACCOUNT_SPEC_OPS.AROUND_ACCOUNTID,
        accountId: appUtils.getLoggedUserId()
      }
    ];
  } else if (context.view === VIEWS.UNASSIGNED) {
    filters.status = [USER_STATE.UNASSIGNED];
  }

  const pageSize = currentLetter === 'ALL' ? 1000 : 50;
  const {
    data: accounts,
    meta: { page },
    isFetching: isFetchingAccounts,
    isError: isErrorAccounts
  } = useAccounts(filters, {
    page: { size: pageSize, number: currentPage },
    search: {
      enabled: Boolean(currentLetter !== 'ALL' || context.search),
      field: 'name',
      value: currentLetter !== 'ALL' ? `^${currentLetter}` : context.search
    }
  });

  const [checkedIds, setCheckedIds] = useState([]);

  useEffect(() => {
    setCheckedIds([]);
  }, [context.view, currentLetter, isFetchingAccounts]);

  useEffect(() => {
    setCurrentPage(1);
  }, [context.view, currentLetter]);

  useEffect(() => {
    setCurrentLetter('ALL');
  }, [context.view]);

  const navigate = useNavigate();

  const isFetching = isFetchingCompany
    || isFetchingTree
    || isFetchingAccounts
    || isFetchingLoggedAccount;
  const isError = isErrorCompany || isErrorTree || isErrorAccounts || isErrorLoggedAccount;
  const isReady = company
    && company.id
    && tree
    && tree.id
    && accounts
    && !isFetching
    && !isError;

  if (!isReady) {
    return null;
  }

  const noUsersFound = !isFetchingAccounts && !isError && !accounts.length;
  const showPagination = page.totalPages && page.totalPages !== 1;
  const loggedUserId = appUtils.getLoggedUserId();

  const selectUser = (user) => {
    const { isArchived } = user.settings;
    if (user.status === USER_STATE.UNASSIGNED || isArchived) {
      return setAccountIdToUnarchive(user._id);
    }

    if (loggedUserId === user._id) {
      return navigate(appUtils.getDashRoute());
    }

    const { viewUserProfile } = company.settings;
    if (
      commonViewPermissions.canAccessUserDashboard(
        loggedAccount,
        user,
        tree,
        viewUserProfile
      )
    ) {
      return navigate(appUtils.getDashRoute(user._id));
    }
    toast.show('You do not have access to view this team member.');
  };

  const isAdmin = loggedAccount.access === ACCESS.ADMIN;

  const columns = [
    // {
    //   type: TYPES.CHECKBOX,
    //   span: 0.5,
    //   onChange: (bool) => {
    //     if (bool) setCheckedIds(accounts.map((account) => account._id));
    //     else setCheckedIds([]);
    //   },
    //   value: commonUtils.isSame(
    //     checkedIds,
    //     accounts.map((account) => account._id)
    //   )
    // },
    {
      type: TYPES.IMAGE,
      span: 0.4
    },
    {
      label: 'Name',
      span: 1.25
    },
    {
      label: 'Title',
      span: 1.25
    },
    {
      label: 'Manager'
    },
    {
      label: 'Access',
      span: 0.75
    },
    {
      label: 'Status',
      span: 0.75
    },
    ...(isAdmin
      ? [
        {
          type: TYPES.BADGES,
          label: 'Channel',
          span: 0.75
        }
      ]
      : []),
    {
      span: 0.5
    }
  ];

  const rows = accounts.map((account) => {
    const { isArchived } = account.settings;
    const node = account.status === USER_STATE.UNASSIGNED || isArchived
      ? commonTreeUtils.findNodeByIdArray(treeList, account._id)
      : commonTreeUtils.findNodeById(tree, account._id);
    const manager = node.managerId
      ? commonTreeUtils.findNodeByIdArray(treeList, node.managerId)
      : null;

    const communicationBadge = {
      badgeClasses: '!px-[10px] !py-[3px]'
    };

    const num = Math.random();
    if (account.preferredCommunication === COMMUNICATION_TYPES.EMAIL) {
      communicationBadge.label = 'Email';
      communicationBadge.colorClasses = BADGE_COLOR_CLASSES.BLUE;
    } else if (
      account.preferredCommunication === COMMUNICATION_TYPES.MS_TEAMS
    ) {
      communicationBadge.label = 'MS Teams';
      communicationBadge.colorClasses = BADGE_COLOR_CLASSES.PURPLE;
    } else if (account.preferredCommunication === COMMUNICATION_TYPES.SLACK) {
      communicationBadge.label = 'Slack';
      communicationBadge.colorClasses = BADGE_COLOR_CLASSES.GREEN;
    } else if (account.preferredCommunication === COMMUNICATION_TYPES.WEBEX) {
      communicationBadge.label = 'Webex';
      communicationBadge.colorClasses = BADGE_COLOR_CLASSES.GRAY;
    }

    return [
      // {
      //   type: TYPES.CHECKBOX,
      //   onChange: (bool) => {
      //     if (bool) setCheckedIds((prev) => [...prev, account._id]);
      //     else setCheckedIds((prev) => prev.filter((accountId) => accountId !== account._id));
      //   },
      //   value: checkedIds.includes(account._id)
      // },
      {
        type: TYPES.IMAGE,
        imageSrc: appUtils.getImageUrl(account.imageUrl),
        imageClasses: `!rounded-full !h-12 !w-12 !-mr-4`
      },
      {
        label: account.name,
        labelClasses: '!text-[#111928]',
        underlabel: account.email,
        underlabelClasses: '!text-[#637381]'
      },
      {
        label: account.title,
        labelClasses: '!text-[#637381]'
      },
      {
        label: manager?.name || 'N/A',
        labelClasses: '!text-[#637381]'
      },
      {
        label: account.access,
        labelClasses: '!text-[#637381]'
      },
      {
        label: account.status,
        labelClasses: '!text-[#637381]'
      },
      ...(isAdmin
        ? [
          {
            type: TYPES.BADGES,
            badges: [communicationBadge]
          }
        ]
        : []),
      {
        type: TYPES.ACTION,
        label: context.view === VIEWS.UNASSIGNED ? 'Restore' : 'View',
        onClick: () => selectUser(account),
        disabled: !commonViewPermissions.canAccessUserDashboard(
          loggedAccount,
          account,
          tree,
          company.settings.viewUserProfile
        )
      }
    ];
  });

  // feature flag
  columns.forEach((column) => {
    if (column.label === 'Manager') {
      column.label = textAlternativePeoplePageManagerColumn || 'Manager';
    }
  });

  return (
    <>
      {accountIdToUnarchive ? (
        <UnarchiveAccountModal
          accountId={accountIdToUnarchive}
          close={() => setAccountIdToUnarchive(null)}
        />
      ) : null}
      {context.view === VIEWS.ALL_MEMBERS
      || context.view === VIEWS.UNASSIGNED ? (
        <div className='-mb-4'>
          <FilterByLetter
            currentLetter={currentLetter}
            changeLetter={setCurrentLetter}
          />
        </div>
        ) : null}
      <Base classes={STYLE.CONTAINER_WHITE_PADDINGLESS}>
        <Table
          columns={columns}
          rows={rows}
          placeholderMessage='No team members found'
        />
        {noUsersFound ? (
          <div>
            {showPagination ? (
              <div className='p-4'>
                <Pagination
                  name='People'
                  totalPages={page.totalPages}
                  currentPage={currentPage}
                  selectPage={setCurrentPage}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </Base>
    </>
  );
};

export default TeamList;
