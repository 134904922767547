import React, { useContext, useEffect, useRef } from 'react';
import { get } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { updateData } from 'src/pagesDashboard/Team/context/actions';
import CONSTANTS from 'src/pagesDashboard/Team/context/constants';
import { TeamContext } from 'src/pagesDashboard/Team/context/TeamProvider';
import useKeyPress from 'src/hooks/useKeyPress/useKeyPress';
import { useCompany } from 'src/queries/company';
import { ACCESS } from 'src/constants/index';
import { useAccount } from 'src/queries/account';

const { VIEWS } = CONSTANTS;

const TeamHeader = () => {
  const escapePressed = useKeyPress('Escape');
  const { state, dispatch } = useContext(TeamContext);
  const searchRef = useRef(null);
  const { showPeopleAllMembersTab } = useFlags();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const {
    data: account,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount('me');

  const isFetching = isFetchingCompany || isFetchingAccount;
  const isError = isErrorCompany || isErrorAccount;
  const isReady = company && company.id && account && account._id && !isFetching && !isError;

  useEffect(() => {
    if (!showPeopleAllMembersTab) {
      dispatch(updateData({ view: VIEWS.MY_TEAM }));
    }
  }, []);

  if (!isReady) {
    return null;
  }

  let isAdmin = false;
  if (get(account, 'access') && get(company, 'settings.viewPermissions')) {
    isAdmin = account.access === ACCESS.ADMIN;
  }

  if (escapePressed && state.search !== '') {
    return dispatch(updateData({ search: '' }));
  }

  const search = (value) => {
    if (value === '') {
      return dispatch(updateData({ search: '' }));
    }
    dispatch(updateData({ search: value.replace('\\', '') }));
  };

  const changeTab = (tab) => {
    dispatch(updateData({ view: tab, search: '' }));
  };

  if (searchRef && searchRef.current && searchRef.current.focus) {
    searchRef.current.focus();
  }

  return (
    <div className='flex justify-between w-19.5/20 mx-auto text-white bg-purple py-4 p-5 rounded-md relative z-1 mb-2 mt-0'>
      <div className='flex align-top text-left pl-4'>
        <button
          onClick={() => {
            changeTab(VIEWS.MY_TEAM);
          }}
          className={`focus:outline-none text-2xl mr-12 ${
            state.view === VIEWS.MY_TEAM
              ? 'font-bold'
              : 'text-lightest-blue hover:text-white transition-colors duration-300'
          }`}
        >
          My Team
        </button>
        {
          showPeopleAllMembersTab && (
            <button
              onClick={() => {
                changeTab(VIEWS.ALL_MEMBERS);
              }}
              className={`focus:outline-none text-2xl mr-12 ${
                state.view === VIEWS.ALL_MEMBERS
                  ? 'font-bold'
                  : 'text-lightest-blue hover:text-white transition-colors duration-300'
              }`}
            >
              All Members
            </button>
          )
        }
        {isAdmin ? (
          <button
            onClick={() => {
              changeTab(VIEWS.UNASSIGNED);
            }}
            className={`focus:outline-none text-2xl ${
              state.view === VIEWS.UNASSIGNED
                ? 'font-bold'
                : 'text-lightest-blue hover:text-white transition-colors duration-300'
            }`}
          >
            Unassigned
          </button>
        ) : null}
      </div>
      <div className='flex align-top text-right pr-4'>
        <div className='tooltip'>
          {state.search !== '' ? (
            <span className='bg-black text-white -mt-10 tooltip-text'>
              Press
              {' '}
              <span className='italic'>Escape</span>
              {' '}
              to clear search
            </span>
          ) : null}
          {state.view === VIEWS.ALL_MEMBERS
          || state.view === VIEWS.UNASSIGNED ? (
            <input
              ref={searchRef}
              id='team-search'
              type='input'
              placeholder='Search'
              className='border-none text-lg focus:outline-none rounded bg-white text-black'
              value={state.search}
              onChange={(e) => {
                search(e.target.value);
              }}
            />
            ) : null}
        </div>
      </div>
    </div>
  );
};

export default TeamHeader;
