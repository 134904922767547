import React, { useContext } from 'react';
import { get } from 'lodash';
import { DashContext } from 'src/pagesDashboard/Dash/context/DashProvider';
import { useNavigate } from 'react-router-dom';
import NavigationItem from 'src/pagesDashboard/HomePage/components/mobile/NavigationItem';
import { Base } from 'src/components';
import TrendSVG from 'src/assets/svg/trend.svg';
import Category from 'src/containers/Dash/UserFeedback/mobile/Category';
import { useTree } from 'src/queries/tree';
import { useMyProfile } from 'src/pagesDashboard/Dash/queries';
import STYLE from 'src/constants/style';
import COMMON_CONSTANTS from 'common/commonConstants';
import useMyProfileInitialState from 'src/pagesDashboard/Dash/context/state';

const { DATE_RANGE_FILTERS } = COMMON_CONSTANTS;

const MobileUserAnalytics = () => {
  const navigate = useNavigate();
  const contextObject = useContext(DashContext);
  const context = get(contextObject, 'context', useMyProfileInitialState);
  const range = context.range.value;
  const {
    bundledCategories,
    isFetching: isFetchingMyProfile,
    isError: isErrorMyProfile
  } = useMyProfile();
  const {
    data: { myTreeRow },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const isFetching = isFetchingMyProfile || isFetchingTree;
  const isError = isErrorMyProfile || isErrorTree;
  const isReady = myTreeRow && myTreeRow.id && !isFetching && !isError;

  if (!isReady) {
    return <Base classes='w-full h-40' loading />;
  }

  const isLoading = isFetchingMyProfile || isFetchingTree;

  const subtitle = Object.values(DATE_RANGE_FILTERS).find((filter) => filter.key === range).longLabel;

  return (
    <Base classes={STYLE.MOBILE_CONTAINER}>
      <div className='w-full fixed top-0 mt-12 bg-white z-50'>
        <NavigationItem
          goBack={() => navigate('/dashboard/home')}
          title='My Performance'
          subtitle={subtitle}
        >
          <TrendSVG />
        </NavigationItem>
      </div>
      <div className='mt-[132px]'>
        {
          bundledCategories && bundledCategories.length > 0 ? (
            <div>
              {bundledCategories.map((category, index) => (
                <div key={`category_${category.id}`}>
                  <Category category={category} />
                  {bundledCategories.length === index + 1
                  && window.innerHeight >= 665
                  && window.innerHeight <= 783 ? (
                    <div className='h-10' />
                    ) : null}
                  {bundledCategories.length === index + 1
                  && window.innerHeight < 665 ? (
                    <div className='h-20' />
                    ) : null}
                </div>
              ))}
            </div>
          ) : (
            <div className='flex justify-center pt-10 text-center h-full'>
              {!isLoading ? (
                <p className='mx-3'>You have no roles set yet.</p>
              ) : null}
            </div>
          )
        }
      </div>
    </Base>
  );
};

export default MobileUserAnalytics;
