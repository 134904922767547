import React from 'react';
import { Base, Modal, Button } from 'src/components/';
import { useActivityTab } from 'src/containers/UserProfile/Activity/queries';
import { isEmpty } from 'lodash';

const UserParticipationStatsModal = ({ close, userId }) => {
  const {
    rangeLabel,
    participationStats: {
      data: participationStats,
      isFetching: isFetchingParticipationStats,
      isError: isErrorParticipationStats
    }
  } = useActivityTab(userId);

  const isFetching = isFetchingParticipationStats;
  const isError = isErrorParticipationStats;
  const isReady = participationStats && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const {
    sent, reviewed, rich, completionAverage, notSure
  } = participationStats;

  return (
    <Modal
      variant='custom-no-scroll'
      close={close}
      classes='shadow text-left pl-0'
      innerClasses='w-2/5 text-left'
    >
      <Base
        classes='text-left shadow-none'
        loading={isFetchingParticipationStats}
      >
        <div className='w-max mb-8 text-left inline-block align-top'>
          <h5 className='font-bold'>{`Participation stats (${rangeLabel})`}</h5>
        </div>
        {isEmpty(participationStats) ? (
          <div className='flex items-center w-max justify-around h-48 mr-5'>
            <p className='font-bold text-xl'>
              No reviews were performed in this period of time
            </p>
          </div>
        ) : (
          <div className='text-black pl-4 bg-gray-100'>
            <div className='w-3/6 inline-block align-top mt-4'>
              <h5 className='mb-4 pr-8 font-bold'>Reviews Completed</h5>
              <p className='mb-4 text-3xl mt-4 pr-6 font-bold'>{`${reviewed} / ${sent}`}</p>
            </div>
            <div className='w-3/6 inline-block align-top pl-6 mt-4'>
              <h5 className='mb-4 pr-8 font-bold'>Participation</h5>
              <p className='mb-4 text-3xl mt-4 pr-6 font-bold'>
                {completionAverage === null ? '-' : `${completionAverage}%`}
              </p>
            </div>
            <div className='w-3/6 inline-block align-top mt-10'>
              <h5 className='mb-4 font-bold'>Feedback Comments</h5>
              <p className='mb-4 text-3xl mt-4 pr-6 font-bold '>{`${rich} / ${reviewed}`}</p>
            </div>
            <div className='w-3/6 inline-block align-top pl-6 mt-10'>
              <h5 className='mb-4 font-bold'>{`"I'm Not Sure" Reviews`}</h5>
              <p className='mb-4 text-3xl mt-4 pr-6 font-bold'>{`${notSure} / ${reviewed}`}</p>
            </div>
          </div>
        )}
        <div className='w-max flex justify-end pr-8 pt-4 pb-4'>
          <Button
            onClick={close}
            variant='white'
            classes='text-2xl text-purple pr-12 pt-0 h-12 pt-2 font-extrabold w-20 focus:outline-none'
          >
            Close
          </Button>
        </div>
      </Base>
    </Modal>
  );
};

export default UserParticipationStatsModal;
