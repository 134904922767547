import React, { useContext, useEffect } from 'react';
import qs from 'qs';
import { DashTeamContext } from 'src/pagesDashboard/DashTeam/context/DashTeamProvider';
import { Select, toast } from 'src/components';
import { useTree } from 'src/queries/tree';
import commonTreeUtils from 'common/commonTreeUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import { updateData } from 'src/pagesDashboard/UserProfile/context/actions';
import HistoricReviewFilter from 'src/containers/DashTeam/FilterView/HistoricReviewFilter';
import { useNavigate } from 'react-router-dom';

const { ACCESS, MAX_ITEMS_PER_REQUEST } = COMMON_CONSTANTS;

const FilterView = ({ mode }) => {
  const navigate = useNavigate();
  const {
    data: { myTreeRow, tree, managerList },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const isFetching = isFetchingTree;
  const isError = isErrorTree;
  const isReady = myTreeRow && myTreeRow.id && !isFetching && !isError;

  const { context, dispatch } = useContext(DashTeamContext);

  useEffect(() => {
    const { team, category, userIds } = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    dispatch(
      updateData({
        ...(team && !userIds?.length && { managerId: team, userIds: null }),
        ...(userIds?.length && !team && { userIds, managerId: null }),
        ...(category && { category })
      })
    );
  }, [window.location.search]);

  if (!isReady) {
    return null;
  }

  const { userIds } = context;
  const teamNode = context.managerId
    ? commonTreeUtils.findNodeById(tree, context.managerId)
    : null;

  const mine = myTreeRow.access === ACCESS.ADMIN
    ? managerList
    : commonTreeUtils.getManagerNodeUnder(tree, myTreeRow.id);
  const teamOptions = mine.map((node) => ({
    id: node.id,
    label: node.name
  }));

  const filterUserTitle = userIds && userIds.length
    ? `${userIds.length} ${userIds.length === 1 ? 'user' : 'users'} selected`
    : 'Filter by users';

  const filterByUser = (userId) => {
    let newUserIds = userIds ? [...userIds] : [];
    const exists = userIds && userIds.find((id) => id === userId);
    if (exists) {
      newUserIds = newUserIds.filter((id) => id !== userId);
    } else {
      if (newUserIds.length >= MAX_ITEMS_PER_REQUEST) {
        return toast.show(
          `You can only select ${MAX_ITEMS_PER_REQUEST} members at a time`
        );
      }
      newUserIds.push(userId);
    }

    const newManagerId = newUserIds.length === 0 ? teamOptions[0].id : null;

    if (mode === 'dashTeam') {
      const userFilters = qs.stringify({
        team: newManagerId,
        userIds: newUserIds,
        category: context.category || null
      });
      navigate(`/dashboard/team?${userFilters}`);
    }

    dispatch(
      updateData({
        managerId: newManagerId,
        teamId: null,
        userIds: newUserIds,
        teamHistoricReviews: {
          ...context.teamHistoricReviews,
          page: 1
        }
      })
    );
  };

  let users = myTreeRow.access === ACCESS.ADMIN
    ? commonTreeUtils.convertTreeToList(tree, { byReference: false })
    : commonTreeUtils.convertTreeToList(myTreeRow, { byReference: false });

  users = users
    .filter((u) => {
      if (!u.name || !u.title) {
        console.error('FilterView name or title undefined for tree node', u);
      }
      return u.id !== myTreeRow.id;
    })
    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

  const filterByRoles = users.map((u) => ({
    id: u.id,
    label: u.name,
    checked: userIds && userIds.find((id) => id === u.id)
  }));

  return (
    <div className='flex gap-2 mb-2'>
      <div className='text-left w-60'>
        <Select
          variant='shadow'
          title={teamNode ? `Team ${teamNode.name}` : 'Select team'}
          options={teamOptions}
          onChange={(option) => {
            if (mode === 'dashTeam') {
              const teamFilters = qs.stringify({
                team: option.id,
                userIds: null,
                category: context.category || null
              });
              navigate(`/dashboard/team?${teamFilters}`);
            }
            dispatch(
              updateData({
                teamId: undefined,
                managerId: option.id,
                teamHistoricReviews: {
                  ...context.teamHistoricReviews,
                  page: 1
                }
              })
            );
          }}
        />
      </div>
      <div className='text-left w-60'>
        <Select
          variant='shadow'
          multiselect
          title={filterUserTitle}
          onChange={(option) => {
            filterByUser(option.id);
          }}
          options={filterByRoles}
        />
      </div>
      <HistoricReviewFilter mode={mode} />
    </div>
  );
};

export default FilterView;
