import React from 'react';
import { Base } from 'src/components';
import STYLE from 'src/constants/style';
import HeaderV2 from 'src/containers/Header/HeaderV2';
import EventsMasterSwitch from 'src/containers/OrganizationSettings/EventsMasterSwitch';
import Events from 'src/containers/Organization/Events/Events';
import appUtils from 'src/components/appUtils';

const OrganizationEvents = () => {
  const isSuperUser = appUtils.isSuperUser();
  return (
    <>
      <HeaderV2 overtitle='Organization' title='Events' />
      <Base className={STYLE.BASE}>
        <EventsMasterSwitch />
        <Events isSuperUser={false} />
        {isSuperUser ? <Events isSuperUser={isSuperUser} /> : null}
      </Base>
    </>
  );
};
export default OrganizationEvents;
