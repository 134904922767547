/* eslint-disable camelcase */
import React, { useContext } from 'react';
import { isEmpty, get } from 'lodash';
import { Flyout, Tiers } from 'src/componentsTailwind/index';
import { PerformanceBuilderContext } from 'src/pages/PerformanceBuilder/PerformanceBuilderContext';
import { BADGE_COLOR_CLASSES } from 'src/componentsTailwind/tailwindConstants';
import COMMON_CONSTANTS from 'common/commonConstants';

const { APP_SIZES } = COMMON_CONSTANTS;

export const STRIPE_UTM_SOURCES = {
  NO_REPORT_ID: 'stripe_no_report',
  REPORT_ID: 'stripe_report'
};

const getStripeLink = (reportId, email) => {
  let utm_source = '';
  if (reportId) {
    utm_source += `${STRIPE_UTM_SOURCES.REPORT_ID}_${reportId}`;
  } else {
    utm_source += STRIPE_UTM_SOURCES.NO_REPORT_ID;
  }
  if (!isEmpty(email)) {
    utm_source += `&prefilled_email=${email}`;
  }
  return `${process.env.STRIPE_PUBLIC_BUILDER_CHECKOUT}?utm_source=${utm_source}`;
};

const PricingFlyout = (props = {}) => {
  const {
    data: { reportId, prevDataRef }
  } = useContext(PerformanceBuilderContext);

  let email = '';
  if (get(prevDataRef, 'current')) {
    const prevData = prevDataRef.current;
    email = prevData.reviewerEmail;
  }

  const stripeCheckoutLink = getStripeLink(reportId, email);

  const tiers = [
    {
      title: 'Basic',
      price: { value: 'Free', label: null },
      description:
        'Perfect for writing performance reviews for yourself or others with WorkStory best-in-class templates.',
      badge: { text: 'Current plan', colorClasses: BADGE_COLOR_CLASSES.GRAY },
      button: { text: 'Your current plan', disabled: true },
      features: [
        'WorkStory review templates',
        'Free updates',
        'AI feedback coaching'
      ],
      current: true
    },
    {
      title: 'Standard',
      price: { value: '$5', label: '/month' },
      description:
        'We’ll work to customize for your team to use for their unique performance review processes.',
      badge: { text: 'Best Value', colorClasses: BADGE_COLOR_CLASSES.GOLD },
      button: {
        text: 'Upgrade',
        onClick: () => window.open(stripeCheckoutLink, '_self')
      },
      features: [
        'WorkStory review templates',
        'Secure access',
        'Free updates',
        'AI feedback coaching',
        'Review Storage',
        'Instant sharing via email',
        'PDF downloads'
      ],
      highlight: true
    },
    {
      title: 'Team',
      price: {
        value: '$4',
        label: '/user/month',
        observation: 'billed quarterly'
      },
      description:
        'We’ll work to customize for your team to use for their unique performance review processes.',
      badge: { text: 'Custom', colorClasses: BADGE_COLOR_CLASSES.PURPLE },
      button: {
        text: 'Book a demo',
        onClick: () => window.open('https://home.workstory.team/schedule-demo')
      },
      features: [
        'Customized templates',
        'Team access',
        'Free updates',
        'AI feedback coaching',
        'Review Storage',
        'Instant sharing via email',
        'PDF downloads'
      ]
    }
  ];

  return (
    <Flyout {...props}>
      <div className='h-full'>
        <div className='mb-8'>
          <p className='w-full font-bold text-4xl leading-[52px] text-black m-0'>
            Free for you to use.
          </p>
          <p className='w-full font-bold text-4xl leading-[52px] text-black m-0'>
            Customize for your whole company.
          </p>
        </div>
        <div className='flex h-[80%] m-auto'>
          <Tiers tiers={tiers} />
        </div>
      </div>
    </Flyout>
  );
};

export default PricingFlyout;
