import React, { isValidElement } from 'react';
import { get, isNumber, isEmpty } from 'lodash';
import Checkbox from 'src/components/Checkbox/Checkbox';
import Pagination from 'src/components/Pagination/Pagination';
import { BADGE_COLOR_CLASSES } from 'src/componentsTailwind/tailwindConstants';
import { DropMenu } from 'src/componentsTailwind/index';

export const TYPES = {
  ACTION: 'action',
  BADGES: 'badges',
  CHECKBOX: 'checkbox',
  IMAGE: 'image',
  DROP_MENU: 'drop-menu'
};

const defaultRenderEmptyPlaceholder = (message = 'No data available') => (
  <div className='flex justify-center items-center h-52 w-full'>
    <p className='text-black text-md m-0'>{message}</p>
  </div>
);

const Table = ({
  columns,
  rows,
  wrapperClasses = 'py-2 px-4',
  headClasses = 'border-b border-gray-300',
  bodyClasses = 'bg-white',
  pagination = {},
  placeholderMessage = 'No data available',
  renderEmptyPlaceholder = defaultRenderEmptyPlaceholder
}) => {
  const { currentPage, setCurrentPage, totalPages } = pagination;
  const isPaginated = !isEmpty(pagination);

  const renderHeader = (headerData, index = null) => {
    const { paddingless = false } = headerData;
    const colSpan = isNumber(headerData.span) ? headerData.span * 10 : 1 * 10;

    if (headerData.type === TYPES.CHECKBOX) {
      return (
        <th key={`header-${index ?? Math.random()}`} colSpan={colSpan}>
          <span
            className={`flex justify-center items-center  ${headerData.hidden ? 'hidden' : ''}`}
          >
            <Checkbox
              onChange={headerData.onChange}
              value={headerData.value}
              disabled={headerData.disabled}
            />
          </span>
        </th>
      );
    }

    return (
      <th
        key={`header-${index ?? Math.random()}`}
        className={paddingless ? 'p-0' : 'px-4 py-3'}
        colSpan={colSpan}
      >
        <div
          className={`text-left text-sm font-semibold text-gray-900 ${headerData.hidden ? 'hidden' : ''}`}
        >
          {headerData.label}
        </div>
      </th>
    );
  };

  const renderCell = (column, cellData, index = null) => {
    const paddingless = get(column, 'paddingless', false) || get(cellData, 'paddingless', false);
    const colSpan = isNumber(column.span) ? column.span * 10 : 1 * 10;

    if (cellData.type === TYPES.ACTION) {
      const {
        hidden, disabled, icon, classes = ''
      } = cellData;
      return (
        <td
          key={`cell-${index ?? Math.random()}`}
          className={paddingless ? 'p-0' : 'px-4 py-3'}
          colSpan={colSpan}
        >
          <span className='flex justify-left'>
            <button
              className={`text-indigo-600 disabled:text-mid-gray enabled:hover:text-indigo-900 text-center text-sm font-medium ${hidden ? 'hidden' : ''} ${classes}`}
              onClick={cellData.onClick}
              disabled={disabled}
            >
              {icon}
              {cellData.label}
            </button>
          </span>
        </td>
      );
    }

    if (cellData.type === TYPES.BADGES) {
      return (
        <td
          key={`cell-${index ?? Math.random()}`}
          className={paddingless ? 'p-0' : 'px-4 py-3'}
          colSpan={colSpan}
        >
          <div className='flex flex-wrap gap-1'>
            {cellData.badges.map(
              (
                {
                  label,
                  labelClasses = '',
                  badgeClasses = '',
                  colorClasses = BADGE_COLOR_CLASSES.GREEN
                },
                badgeIndex
              ) => (
                <button
                  key={`badge-${badgeIndex}`}
                  disabled
                  className='relative'
                >
                  <span
                    className={`tooltip rounded-md px-2 py-[2px] ring-1 ring-inset w-fit truncate ${badgeClasses} ${colorClasses}`}
                  >
                    <span className='tooltip-text w-fit bg-black text-white text-xs font-medium whitespace-nowrap -top-[35px] left-[0%]'>
                      {label}
                    </span>
                    <div
                      className={`m-0 leading-4 text-xs font-medium truncate max-w-[100px] ${labelClasses}`}
                    >
                      {label}
                    </div>
                  </span>
                </button>
              )
            )}
          </div>
        </td>
      );
    }

    if (cellData.type === TYPES.CHECKBOX) {
      return (
        <td key={`cell-${index ?? Math.random()}`} colSpan={colSpan}>
          <span className='flex justify-center items-center'>
            <Checkbox
              onChange={cellData.onChange}
              value={cellData.value}
              disabled={cellData.disabled}
            />
          </span>
        </td>
      );
    }

    if (cellData.type === TYPES.IMAGE) {
      const { imageClasses = '' } = cellData;
      return (
        <td
          key={`cell-${index ?? Math.random()}`}
          className='py-3'
          colSpan={colSpan}
        >
          <div className='flex items-center justify-center m-auto w-full h-full'>
            <img
              className={`object-cover grow-0 shrink-0 size-fit ${imageClasses}`}
              src={cellData.imageSrc}
              alt='row'
            />
          </div>
        </td>
      );
    }

    if (cellData.type === TYPES.DROP_MENU) {
      const {
        items = [],
        label = 'Actions',
        direction = 'left',
        disabled = false,
        ifEmpty = 'disable'
      } = cellData;

      if (ifEmpty === 'hide' && isEmpty(items)) {
        return (
          <td
            key={`cell-${index ?? Math.random()}`}
            className={paddingless ? 'p-0' : 'px-4 py-3'}
            colSpan={colSpan}
          />
        );
      }

      return (
        <td
          key={`cell-${index ?? Math.random()}`}
          className={paddingless ? 'p-0' : 'px-4 py-3'}
          colSpan={colSpan}
        >
          <DropMenu
            label={label}
            items={items}
            direction={direction}
            disabled={disabled || (ifEmpty === 'disable' && isEmpty(items))}
          />
        </td>
      );
    }

    return (
      <td
        key={`cell-${index ?? Math.random()}`}
        className={paddingless ? 'p-0' : 'px-4 py-3'}
        colSpan={colSpan}
      >
        <span className='tooltip relative'>
          <span className='tooltip-text w-fit bg-black text-white text-xs font-medium whitespace-nowrap -top-[35px] left-[0%]'>
            {cellData.label}
            {cellData.underlabel ? ` - ${cellData.underlabel}` : ''}
          </span>
          <div
            className={`m-0 text-gray-500 text-sm ${column.multiline ? 'multiline-ellipsis' : 'truncate'} ${cellData.labelClasses || ''}`}
          >
            {cellData.label}
            {cellData.underlabel ? (
              <div
                className={`m-0 text-gray-400 text-xs truncate leading-4 ${cellData.underlabelClasses || ''}`}
              >
                {cellData.underlabel}
              </div>
            ) : null}
          </div>
        </span>
      </td>
    );
  };

  const isDataEmpty = isEmpty(rows);
  return (
    <div className={`${wrapperClasses} !inline-block`}>
      <div className='overflow-x-auto w-full'>
        <table className='w-full max-w-[100%] table-fixed'>
          <thead className={headClasses}>
            <tr>
              {columns.map((column, colIndex) => renderHeader(column, colIndex))}
            </tr>
          </thead>

          <tbody className={bodyClasses}>
            {rows.map((row, rowIndex) => {
              let classes = '';

              const { meta } = get(row, '0', {});
              if (!isEmpty(meta)) {
                const { classes: metaClasses } = meta;
                if (!isEmpty(metaClasses)) classes = metaClasses;
              }

              return (
                <tr className={classes} key={`row-${rowIndex}`}>
                  {columns.map((column, colIndex) => renderCell(column, row[colIndex], colIndex))}
                </tr>
              );
            })}
          </tbody>
        </table>
        {isDataEmpty ? <>{renderEmptyPlaceholder(placeholderMessage)}</> : null}
      </div>
      {isPaginated ? (
        <Pagination
          name='Table'
          totalPages={totalPages}
          currentPage={currentPage}
          selectPage={setCurrentPage}
        />
      ) : null}
    </div>
  );
};

export default Table;
