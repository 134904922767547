import { useMutation } from 'react-query';
import qs from 'qs';
import api from 'src/services/api';
import { useContext, useCallback } from 'react';
import { CompanyDashContext } from 'src/pagesDashboard/CompanyDash/context/Provider';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import {
  useOrganizationScore,
  useQueryScore,
  useQueryParticipants,
  useQueryParticipationTabsTotal
} from 'src/queries/score';
import appUtils from 'src/components/appUtils';
import companyDashQueryUtils from 'src/pagesDashboard/CompanyDash/queries/utils';
import blobUtils from 'src/utils/blob';
import COMMON_CONSTANTS from 'common/commonConstants';

const { DATE_RANGE_FILTERS, ACCESS } = COMMON_CONSTANTS;

const getQueryParams = () => {
  const { context } = useContext(CompanyDashContext);
  const {
    reviewerIds, revieweeIds, roleIds, reportIsSelf, reportStatus
  } = context;
  const { value, start, end } = context.range;

  const filters = {
    range: value,
    start,
    end,
    reviewerIds,
    revieweeIds,
    roleIds,
    reportIsSelf,
    reportStatus
  };
  return { filters, options: {} };
};

export const useCompanyDashboard = () => {
  const { filters, options } = getQueryParams();
  const loggedUser = appUtils.getLoggedUser();
  const {
    data: teamScore,
    isFetching: isFetchingScores,
    isError: isErrorScores
  } = useOrganizationScore({
    companyid: loggedUser?.companyid,
    filters,
    options
  });

  const {
    data: { tree, treeListActive },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const { groupsAsReviewers, groups, groupsLastPeriod } = teamScore;
  const departments = companyDashQueryUtils.populateDepartments(
    tree,
    teamScore
  );
  const populatedGroups = companyDashQueryUtils.populateGroups(
    company,
    groups,
    groupsLastPeriod
  );
  const reviews = teamScore?.overview?.reviews;

  return {
    reviews,
    teamScore,
    groupsAsReviewers,
    groups: populatedGroups,
    departments,
    isFetching: isFetchingScores || isFetchingCompany || isFetchingTree,
    isError: isErrorScores || isErrorCompany || isErrorTree,
    memberCount: treeListActive?.length
  };
};

export const useTopStats = () => {
  const { context, updateContext } = useContext(CompanyDashContext);
  const { value, start, end } = context.range;
  const loggedUser = appUtils.getLoggedUser();
  const {
    data: { tree, deleted: deletedNodes }
  } = useTree();
  const pdFilter = { range: value, start, end };

  const tpSort = { order: 'desc', key: 'score' };
  const bpSort = { order: 'asc', key: 'score' };
  const include = { change: true };

  const updatePageContext = useCallback((field, pageValue) => {
    updateContext({ [field]: { page: pageValue, size: 10 } });
  }, []);

  const { data: topPerformers, isFetching: isFetchingTopPerformers } = useQueryScore(
    loggedUser?.companyid,
    pdFilter,
    tpSort,
    context.topPerformers,
    include
  );
  const { data: bottomPerformers, isFetching: isFetchingBottomPerformers } = useQueryScore(
    loggedUser?.companyid,
    pdFilter,
    bpSort,
    context.bottomPerformers,
    include
  );

  const topPerformersPagination = {
    ...topPerformers.pagination,
    selectPage: useCallback(
      (pageValue) => updatePageContext('topPerformers', pageValue),
      [updatePageContext]
    ),
    isLoading: isFetchingTopPerformers
  };

  const bottomPerformersPagination = {
    ...bottomPerformers.pagination,
    selectPage: useCallback(
      (pageValue) => updatePageContext('bottomPerformers', pageValue),
      [updatePageContext]
    ),
    isLoading: isFetchingBottomPerformers
  };

  return {
    topPerformers: companyDashQueryUtils.populateUser(
      tree,
      deletedNodes,
      topPerformers.overview,
      topPerformers.previousOverview,
      'reviewee'
    ),
    bottomPerformers: companyDashQueryUtils.populateUser(
      tree,
      deletedNodes,
      bottomPerformers.overview,
      bottomPerformers.previousOverview,
      'reviewee'
    ),
    topPerformersPagination,
    bottomPerformersPagination
  };
};

// query to get a company's overview or reports tab data in a spreadsheet
export const exportCompanyQuery = (view) => {
  const { filters, options } = getQueryParams();
  options.view = view;

  return useMutation(async (filename) => {
    const params = qs.stringify({ filters, options }, { skipNulls: true });
    const blob = await api.getXLS(`/company/export?${params}`);
    blobUtils.triggerBlobDownload(blob, filename);
  });
};

export const useParticipationStats = (completionAvgConditional) => {
  const { context, updateContext } = useContext(CompanyDashContext);
  const { completionAvgMin, completionAvgMax } = completionAvgConditional;
  const { value, start, end } = context.range;
  const loggedUser = appUtils.getLoggedUser();
  const pdFilter = { range: value, start, end };
  const {
    data: { tree, deleted: deletedNodes },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const updatePageContext = useCallback((field, pageValue) => {
    updateContext({ [field]: { page: pageValue, size: 5 } });
  }, []);
  const participantsSort = { order: 'desc', key: 'completionAverage' };

  const getContextPagination = () => {
    let pagination = null;
    if (completionAvgMin === 75 && completionAvgMax === 100) {
      pagination = context.completionAvg75to100;
    }
    if (completionAvgMin === 50 && completionAvgMax === 74) {
      pagination = context.completionAvg50to74;
    }
    if (completionAvgMin === 25 && completionAvgMax === 49) {
      pagination = context.completionAvg25to49;
    }
    if (completionAvgMin === 0 && completionAvgMax === 24) {
      pagination = context.completionAvg0to24;
    }
    return pagination;
  };

  const {
    data: usersData,
    isFetching: isFetchingParticipants,
    isError: isErrorParticipants
  } = useQueryParticipants(
    loggedUser?.companyid,
    pdFilter,
    participantsSort,
    getContextPagination(),
    completionAvgConditional
  );

  const getParticipantsPagination = () => {
    let participantsPagination = null;
    if (completionAvgMin === 75 && completionAvgMax === 100) {
      participantsPagination = {
        ...usersData.pagination,
        selectPage: useCallback(
          (pageValue) => updatePageContext('completionAvg75to100', pageValue),
          [updatePageContext, completionAvgConditional]
        ),
        isLoading: isFetchingParticipants
      };
    }
    if (completionAvgMin === 50 && completionAvgMax === 74) {
      participantsPagination = {
        ...usersData.pagination,
        selectPage: useCallback(
          (pageValue) => updatePageContext('completionAvg50to74', pageValue),
          [updatePageContext, completionAvgConditional]
        ),
        isLoading: isFetchingParticipants
      };
    }
    if (completionAvgMin === 25 && completionAvgMax === 49) {
      participantsPagination = {
        ...usersData.pagination,
        selectPage: useCallback(
          (pageValue) => updatePageContext('completionAvg25to49', pageValue),
          [updatePageContext, completionAvgConditional]
        ),
        isLoading: isFetchingParticipants
      };
    }
    if (completionAvgMin === 0 && completionAvgMax === 24) {
      participantsPagination = {
        ...usersData.pagination,
        selectPage: useCallback(
          (pageValue) => updatePageContext('completionAvg0to24', pageValue),
          [updatePageContext, completionAvgConditional]
        ),
        isLoading: isFetchingParticipants
      };
    }
    return participantsPagination;
  };
  const { participants: participantsData } = usersData;
  const participantsPagination = getParticipantsPagination();
  return {
    participantsData: companyDashQueryUtils.populateParticipants(
      tree,
      deletedNodes,
      participantsData
    ),
    participantsPagination,
    isFetching: isFetchingTree || isFetchingParticipants,
    isError: isErrorParticipants || isErrorTree
  };
};

export const useParticipationTabsTotal = () => {
  const { context } = useContext(CompanyDashContext);
  const { value, start, end } = context.range;
  const loggedUser = appUtils.getLoggedUser();
  const pdFilter = { range: value, start, end };

  const {
    data: tabsTotal,
    isFetching: isFetchingTabsTotal,
    isError: isErrorTabsTotal
  } = useQueryParticipationTabsTotal(loggedUser?.companyid, pdFilter);
  return {
    tabsTotalData: tabsTotal,
    isFetching: isFetchingTabsTotal,
    isError: isErrorTabsTotal
  };
};
