import commonDateUtils from 'common/commonDateUtils';
import { isNumber } from 'lodash';

const utils = {};

// input: start and end unix timestamps
// output: group chart data by week, month or year
utils.getChartGroupBy = (start, end) => {
  if (start) {
    const endUnix = end || commonDateUtils.getUnixDateNow();
    const daysDiff = Math.floor((endUnix - start) / 86400);
    if (daysDiff > 0 && daysDiff < 181) {
      return 'week';
    }
    if (daysDiff > 180 && daysDiff < 1095) {
      return 'month';
    }
    if (daysDiff > 1095) {
      return 'year';
    }
  }
  return 'week';
};

// input list of data points:
// [{ score: 23 }, { score: 89 }, { score: 94 }, ..]
//
// output - a min and max value for the chart boundaries
utils.getChartMinMax = (chartPoints) => {
  let minVal = 0;
  let maxVal = 100;
  if (chartPoints && chartPoints.length) {
    chartPoints.forEach((p) => {
      if (isNumber(p.score)) p.score = Math.round(p.score);
    });
    chartPoints.sort((a, b) => (a.score < b.score ? 1 : -1));
    const max = chartPoints[0].score;
    const min = chartPoints[chartPoints.length - 1].score;

    if (min < 10) {
      minVal = 0;
    } else {
      minVal = min - 10;
    }

    if (max > 90) {
      maxVal = 100;
    } else {
      maxVal = max + 10;
    }
  }
  return { min: minVal, max: maxVal };
};

const colors = [
  '#5B7FFF',
  '#FFEA7E',
  '#FF5964',
  '#61D04B',
  '#F2994A',
  '#0038FF',
  '#FFD500',
  '#FF0011',
  '#1CA700',
  '#EF7000',
  '#93AAFF',
  '#FFF2AE',
  '#FF9098',
  '#92D284',
  '#F0BA8A',
  '#D3DDFF',
  '#E3DDBA',
  '#FFD0D3',
  '#C0E9B7',
  '#F4DDC8'
];

// input list of data points:
// [{ score: 23, date: 2021-03-01 }, { score: 89, date: 2021-03-05 }, { score: 94, date: 2021-03-09 }, ..]
//
// output - chartjs compatible dataset
utils.createDataset = (
  chartPoints,
  label = '',
  colorIndex = 0,
  options = {}
) => {
  const color = colors[colorIndex];

  return {
    datasets: [
      {
        backgroundColor: color,
        borderColor: color,
        data: chartPoints
          .sort((a, b) => b.date - a.date)
          .map((p) => ({
            x: p.date ? commonDateUtils.dateToMonthDayYearFormat(p.date) : null,
            y: p.score
          })),
        fill: false,
        label,
        lineTension: 0.5,
        pointBackgroundColor: color,
        pointBorderColor: color,
        pointBorderWidth: 6,
        pointHitRadius: 15,
        pointHoverBackgroundColor: color,
        pointHoverBorderColor: color,
        pointHoverBorderWidth: 2,
        pointHoverRadius: 5,
        pointRadius: 4,
        tension: 0
      }
    ],
    labels: []
  };
};

// groupBy = week or month or year
utils.createOptions = ({
  min, max, groupBy, showLegend = false
}) => ({
  maintainAspectRatio: false,
  responsive: true,
  animation: false,
  legend: {
    display: showLegend
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          drawOnChartArea: false
        },
        type: 'time',
        time: {
          unit: groupBy
        }
      }
    ],
    yAxes: [
      {
        gridLines: {
          drawOnChartArea: false,
          borderDash: [5]
        },
        ticks: {
          padding: 0,
          min,
          max
        }
      }
    ]
  }
});

export default utils;
