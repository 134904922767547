import React from 'react';
import { DATA } from '../../constants';
import { SectionBox } from '../../components';

const { IMAGES } = DATA;

const classes = 'inline-block width200 marginBottom35 width130sm marginLeft5per marginRight5per';

const Companies = () => (
  <>
    <img className={classes} src={IMAGES.LOGO_1909} />
    <img className={classes} src={IMAGES.LOGO_FAU} />
    <img className={classes} src={IMAGES.LOGO_MYPHOTO} />
    <img className={classes} src={IMAGES.LOGO_ROCKET_MATTER} />
    <img className={classes} src={IMAGES.LOGO_SOFLA_TECH} />
    <img className={classes} src={IMAGES.LOGO_TDI} />
  </>
);

export default Companies;
