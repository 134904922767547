import React from 'react';
import { Base, appUtils } from 'src/components';
import NewGoal from 'src/pagesDashboard/GoalSettings/NewGoal';
import EditGoal from 'src/pagesDashboard/GoalSettings/EditGoal';
import ViewGoal from 'src/pagesDashboard/GoalSettings/ViewGoal';
import STYLE from 'src/constants/style';
import { useParams, useSearchParams } from 'react-router-dom';

const mobileSize = appUtils.getMobileSize();
const isMobile = mobileSize;

const GoalSettings = ({ mode, parentProps }) => {
  const { goalId, userId } = useParams();
  const [searchParams] = useSearchParams();
  const parentId = searchParams.get('parentId');

  return (
    <Base classes={isMobile ? '' : STYLE.BASE}>
      {mode === 'view' ? (
        <ViewGoal
          goalId={goalId}
          userId={userId}
          parentId={parentId}
          {...parentProps}
        />
      ) : null}
      {mode === 'edit' ? (
        <EditGoal
          goalId={goalId}
          userId={userId}
          parentId={parentId}
          {...parentProps}
        />
      ) : null}
      {mode === 'new' ? (
        <NewGoal
          goalId={goalId}
          userId={userId}
          parentId={parentId}
          {...parentProps}
        />
      ) : null}
    </Base>
  );
};

export default GoalSettings;
