import React from 'react';
import { Base } from 'src/components';
import commonTreeUtils from 'common/commonTreeUtils';
import commonDateUtils from 'common/commonDateUtils';

const FreeTextResponses = ({ pulseData, tree }) => (
  <Base classes='bg-white p-2 px-3 mx-2 mt-3'>
    <h5 className='font-bold text-xl mb-4'>Responses</h5>

    <div className='mb-2 py-1 px-1 bg-background-gray text-black font-bold rounded-sm'>
      <p className='mb-0 pl-1 w-2/12 inline-block font-bold'>Reviewed</p>
      <p className='mb-0 pl-1 w-2/12 inline-block font-bold'>Employee</p>
      <p className='mb-0 w-3/12 inline-block font-bold'>Title</p>
      <p className='mb-0 w-4/12 inline-block font-bold'>Comments</p>
      <p className='mb-0 w-1/12 inline-block' />
    </div>

    {!pulseData.reviews.length ? (
      <p className='text-center mt-10 italic'>No responses yet</p>
    ) : null}
    {pulseData.reviews.map((pulseReview) => {
      const { timestamp, reviewerId, comments } = pulseReview;
      const reviewerTree = commonTreeUtils.findNodeById(tree, reviewerId);
      let reviewerName = reviewerTree ? reviewerTree.name : 'Other';
      let reviewerTitle = reviewerTree ? reviewerTree.title : 'Other';
      const commentsText = comments && comments !== '' ? comments : '-';
      const reviewedDate = commonDateUtils.unixToMonthDayYearFormat(timestamp);
      if (pulseReview.anonymous) {
        reviewerName = 'Anonymous';
        reviewerTitle = 'Anonymous';
      }
      return (
        <div className='pl-1' key={pulseReview.id}>
          <p className='w-2/12 inline-block'>{reviewedDate}</p>
          <p className='w-2/12 inline-block'>{reviewerName}</p>
          <p className='w-3/12 inline-block'>{reviewerTitle}</p>
          <p className='w-4/12 inline-block'>{commentsText}</p>
        </div>
      );
    })}
  </Base>
);

export default FreeTextResponses;
