import React from 'react';

const DetailsSVG = ({ classes }) => (
  <svg
    className={classes}
    width='16'
    height='15'
    viewBox='0 0 16 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 10V2C16 1.46957 15.7893 0.960859 15.4142 0.585786C15.0391 0.210714 14.5304 0 14 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V10C0 10.5304 0.210714 11.0391 0.585786 11.4142C0.960859 11.7893 1.46957 12 2 12H5L8 15L11 12H14C14.5304 12 15.0391 11.7893 15.4142 11.4142C15.7893 11.0391 16 10.5304 16 10ZM3 4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3H12C12.2652 3 12.5196 3.10536 12.7071 3.29289C12.8946 3.48043 13 3.73478 13 4C13 4.26522 12.8946 4.51957 12.7071 4.70711C12.5196 4.89464 12.2652 5 12 5H4C3.73478 5 3.48043 4.89464 3.29289 4.70711C3.10536 4.51957 3 4.26522 3 4ZM4 7C3.73478 7 3.48043 7.10536 3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8C3 8.26522 3.10536 8.51957 3.29289 8.70711C3.48043 8.89464 3.73478 9 4 9H7C7.26522 9 7.51957 8.89464 7.70711 8.70711C7.89464 8.51957 8 8.26522 8 8C8 7.73478 7.89464 7.48043 7.70711 7.29289C7.51957 7.10536 7.26522 7 7 7H4Z'
      fill='black'
    />
  </svg>
);

const CategoryBox = ({
  onClick,
  title,
  value,
  label,
  highlight = false
}) => (
  <div className='mb-1 inline-block align-top' key={`${title}-${Math.random()}`}>
    <div className=''>
      <button
        className={`${
          highlight ? 'bg-purple' : 'bg-white'
        } shadow text-center relative rounded-md py-1 w-full align-top group transition-colors duration-300 hover:bg-yellow focus:outline-none`}
        onClick={onClick}
      >
        <div className='absolute mx-auto w-full mt-9 opacity-0 group-hover:opacity-100'>
          <DetailsSVG classes='mx-auto mb-2' />
          <span className='font-bold'>View Details</span>
        </div>
        <p
          className={`${
            highlight ? 'text-white' : 'text-black'
          } block mt-2 text-md font-bold mb-0 pb-0 px-3 truncate overflow-ellipsis group-hover:opacity-0`}
        >
          {title}
        </p>
        <p
          className={`${
            highlight ? 'text-white' : ''
          } group-hover:opacity-0 mb-0 w-5/6 mx-auto text-sm truncate overflow-ellipsis`}
        >
          {label}
        </p>
        <p
          className={`${
            highlight ? 'text-white' : 'text-black'
          } mb-3 text-3xl font-bold pt-4 pb-1 group-hover:opacity-0`}
        >
          {value || '-'}
        </p>
      </button>
    </div>
  </div>
);

export default CategoryBox;
