import React from 'react';
import COMMON_CONSTANTS from 'common/commonConstants';

const { EVENT_TYPES } = COMMON_CONSTANTS;
const EventsTableHeader = ({ filterEvent }) => (
  <div className='mb-2 py-1 px-1 bg-background-gray text-black font-bold rounded-sm'>
    {filterEvent
    && (filterEvent.id === EVENT_TYPES.SUMMARY_EVENT
      || filterEvent.id === EVENT_TYPES.RANDOM_EVENT) ? (
        <div className='w-11/12'>
          <p className='mb-0 pl-1 w-1/5 inline-block font-bold'>Member</p>
          <p className='mb-0 w-1/5 inline-block font-bold'>Date</p>
        </div>
      ) : null}
    {filterEvent && filterEvent.id === EVENT_TYPES.REVIEW ? (
      <div className='w-11/12'>
        <p className='mb-0 pl-1 w-1/5 inline-block font-bold'>Reviewer</p>
        <p className='mb-0 pl-1 w-1/5 inline-block font-bold'>Reviewee</p>
        <p className='mb-0 w-1/5 inline-block font-bold'>Date</p>
        <p className='mb-0 pl-1 w-1/5 inline-block font-bold'>Role</p>
      </div>
    ) : null}
    {filterEvent && filterEvent.id === EVENT_TYPES.GOAL_UPDATE ? (
      <div className='w-11/12'>
        <p className='mb-0 pl-1 w-1/5 inline-block font-bold'>Member</p>
        <p className='mb-0 w-1/5 inline-block font-bold'>Date</p>
        <p className='mb-0 pl-1 w-1/5 inline-block font-bold'>Goal</p>
      </div>
    ) : null}
    {filterEvent
    && (filterEvent.id === EVENT_TYPES.PULSE.PULSE_REVIEW
      || filterEvent.id === EVENT_TYPES.PULSE.PULSE_QUESTION) ? (
        <div className='w-11/12'>
          <p className='mb-0 pl-1 w-1/5 inline-block font-bold'>Member</p>
          <p className='mb-0 w-1/5 inline-block font-bold'>Date</p>
          <p className='mb-0 pl-1 w-1/5 inline-block font-bold'>Question</p>
        </div>
      ) : null}
    {filterEvent
    && (filterEvent.id === EVENT_TYPES.TRIAL_EXPIRES
      || filterEvent.id === EVENT_TYPES.ENABLE_COMPANY_REVIEWS) ? (
        <div className='w-11/12'>
          <p className='mb-0 w-1/5 inline-block font-bold'>Date</p>
        </div>
      ) : null}
    {!filterEvent ? (
      <div className='w-11/12'>
        <p className='mb-0 pl-1 w-1/5 inline-block font-bold'>Member</p>
        <p className='mb-0 w-1/5 inline-block font-bold'>Date</p>
        <p className='mb-0 pl-1 w-1/5 inline-block font-bold'>Role</p>
      </div>
    ) : null}
  </div>
);

export default EventsTableHeader;
