import React, { useState, useEffect, useContext } from 'react';
import { get } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCompany } from 'src/queries/company';
import { getReportAiNoteQuery, useReportNotes } from 'src/queries/reports';
import { useCreateNote } from 'src/hooks/UserReports/useNote';
import { useReviews } from 'src/queries/reviews';
import { UserProfileContext } from 'src/pagesDashboard/UserProfile/context/UserProfileProvider';
import { Button, HistoricReviews } from 'src/components';
import NotesNewReport from 'src/pagesDashboard/NewUserReport/components/NotesNewReport';
import AddNoteNewReport from 'src/pagesDashboard/NewUserReport/components/AddNoteNewReport';
import {
  getLabel,
  getLabelByIcon
} from 'src/pagesDashboard/NewUserReport/utils';
import COMMON_CONSTANTS from 'common/commonConstants';

const { REPORT_TEMPLATE_TYPES, REPORT_NOTE_TYPES, REPORT_PROMISE_TYPES } = COMMON_CONSTANTS;

const LoadingPlaceholder = ({ category }) => (
  <div className='mb-4 border border-zinc-400 shadow px-6'>
    <button
      className='focus:outline-none text-xl pt-4 pb-4 bg-white w-full rounded flex'
      type='button'
    >
      <div className='flex flex-col text-left w-2/3'>
        <span className=' text-2xl font-bold mb-1'>{category.label}</span>
        <span className='mb-0 text-md text-gray-500 font-bold'>
          Loading data...
        </span>
      </div>
    </button>
  </div>
);

const PreviousCategorySection = ({
  showStatsOnReportBuilder,
  previousCategory,
  isScoreVisible,
  scoreLabels
}) => {
  if (!showStatsOnReportBuilder) return null;

  if (previousCategory) {
    const previousLabel = isScoreVisible
      ? getLabel(scoreLabels, previousCategory.score)
      : getLabelByIcon(scoreLabels, previousCategory.score);

    return (
      <span className='mb-0 text-md text-gray-500 font-bold'>
        {`Previous Comparison Score: ${previousLabel ?? ''} ${
          isScoreVisible ? `- ${previousCategory.score}` : ''
        } ${!previousLabel && !isScoreVisible ? 'N/A' : ''}`}
      </span>
    );
  }
  return (
    <span className='mb-0 text-md text-gray-500 font-bold'>
      Previous Comparison Score: N/A
    </span>
  );
};

const CategoryNewReport = ({
  category,
  previousCategory,
  reportId,
  previousReport,
  start,
  end,
  userId,
  roleLabels,
  viewOnly = false,
  isEditorOpenByDefault
}) => {
  const { context, updateContext } = useContext(UserProfileContext);
  const { showStatsOnReportBuilder } = useFlags();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const {
    data: categoryNotes,
    isFetching: isFetchingNotes,
    isError: isErrorCategoryNotes
  } = useReportNotes(reportId, {
    type: REPORT_NOTE_TYPES.CATEGORY,
    meta: { categoryId: category.id }
  });

  const {
    data: previousCategoryNotes,
    isFetching: isFetchingPreviousNotes,
    isError: isErrorPreviousCategoryNotes
  } = useReportNotes(previousReport, {
    type: REPORT_NOTE_TYPES.CATEGORY,
    meta: { categoryId: category.id }
  });

  const { mutateAsync: getReportAiNote, isLoading: isLoadingGetReportAiNote } = getReportAiNoteQuery();

  const [notesOpen, setNotesOpen] = useState(null);
  const [includedEditNoteFeedback, setIncludedEditNoteFeedback] = useState('');
  const [includedNewNoteFeedback, setIncludedNewNoteFeedback] = useState('');
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);

  const { saveNote } = useCreateNote();

  const {
    data: { reviews, pagination },
    isLoading: isLoadingHistoricReviews
  } = useReviews({
    userId,
    filters: {
      category: category.id,
      start,
      end,
      includeUnassignedUsers: true
    },
    options: {
      page: context.historicReviews?.page,
      size: context.historicReviews?.pageSize
    }
  });

  useEffect(() => {
    if (categoryNotes && categoryNotes.length) {
      setIsCategoryOpen(true);
    }
  }, [viewOnly, categoryNotes.length]);

  const isFetching = isFetchingCompany || isFetchingNotes || isFetchingPreviousNotes;
  const isError = isErrorCompany || isErrorCategoryNotes || isErrorPreviousCategoryNotes;
  const isRenderReady = !isFetching && !isError && company;

  if (!isRenderReady) return <LoadingPlaceholder category={category} />;

  const scoreLabels = get(company, 'settings.scoreLabels');
  const isScoreVisible = !typeof category.score === 'string' || typeof category.score === 'number';
  const label = isScoreVisible
    ? getLabel(scoreLabels, category.score)
    : getLabelByIcon(scoreLabels, category.score);

  const toggleCategory = () => {
    setIsCategoryOpen(!isCategoryOpen);
  };

  const selectPage = (page) => {
    updateContext({
      historicReviews: {
        ...context.historicReviews,
        page: parseInt(page, 10)
      }
    });
  };

  const categoryNotesTemplates = company.templates
    .filter((t) => t.type === REPORT_TEMPLATE_TYPES.CATEGORY_TEMPLATE)
    .map((t) => ({
      id: t.id,
      label: t.name,
      content: t.content
    }));
  categoryNotesTemplates.unshift({
    id: 'clear',
    label: 'No template',
    content: ''
  });

  const clearIncludedText = (input) => {
    if (input === 'edit') {
      setIncludedEditNoteFeedback('');
      clearIncludedText('new');
    }
    if (input === 'new') setIncludedNewNoteFeedback('');
    setNotesOpen(null);
  };

  const addToNotes = (noteId) => {
    if (noteId) {
      const review = reviews.find((r) => r._id === noteId);
      if (review) {
        if (notesOpen === 'edit') {
          setIncludedEditNoteFeedback(`${review.text}\n`);
          setTimeout(() => setIncludedEditNoteFeedback(''), 20);
        }

        if (!notesOpen || notesOpen === 'new') {
          setIncludedNewNoteFeedback(`${review.text}\n`);
          setTimeout(() => setIncludedNewNoteFeedback(''), 20);
        }
      }
    }
  };

  const generateAiNote = async () => {
    const params = {
      reportId,
      userId,
      type: REPORT_PROMISE_TYPES.CATEGORIES_SINGLE,
      categoryId: category.id
    };
    return getReportAiNote(params);
  };

  return (
    <div
      className={`mb-4 border border-zinc-400 shadow px-6 ${
        isCategoryOpen ? 'pb-4' : ''
      }`}
      key={`c_${category.id}`}
    >
      <div className='focus:outline-none text-xl pt-4 pb-4 bg-white w-full rounded flex'>
        <div className='flex flex-col text-left w-2/3'>
          <span className=' text-2xl font-bold mb-1'>{category.label}</span>
          {roleLabels && showStatsOnReportBuilder ? (
            <span className='mb-0 text-md text-gray-500 font-bold'>
              {`Current Score: ${label ?? ''} ${
                isScoreVisible ? `- ${category.score}` : ''
              }  ${!label && !isScoreVisible ? 'N/A' : ''}`}
            </span>
          ) : null}
          {previousReport ? (
            <PreviousCategorySection
              showStatsOnReportBuilder={showStatsOnReportBuilder}
              previousCategory={previousCategory}
              isScoreVisible={isScoreVisible}
              scoreLabels={scoreLabels}
            />
          ) : null}
        </div>

        <div className='flex flex-row ml-auto pr-4 my-auto'>
          <div className='inline-block text-sm w-full'>
            <Button
              type='button'
              variant={isCategoryOpen ? 'empty-with-border' : 'custom'}
              classes={
                !isCategoryOpen
                  ? 'min-w-6.82rem bg-purple hover:bg-hover-purple transition-colors duration-300 px-5 py-2 rounded-sm text-white bold text-lg'
                  : 'min-w-6.82rem'
              }
              onClick={() => {
                toggleCategory();
              }}
            >
              {isCategoryOpen ? 'Collapse' : 'View'}
            </Button>
          </div>
        </div>
      </div>
      {isCategoryOpen ? (
        <div className='mt-2'>
          <HistoricReviews
            showViewReviewButton={false}
            userId={userId}
            reviews={reviews}
            title=''
            isLoading={isLoadingHistoricReviews}
            showRequestReview={false}
            showVisibleTooltip={false}
            actionText='Add to notes'
            onAction={addToNotes}
            showActionRule={(review) => !viewOnly && review.text && review.text.length}
            pagination={{
              totalPages: pagination.pages,
              currentPage: pagination.current,
              selectPage
            }}
            customClasses='flex flex-col shadow-none pl-0'
          />

          <NotesNewReport
            reportId={reportId}
            title='Notes'
            notes={categoryNotes ?? []}
            previousNotes={previousCategoryNotes ?? []}
            includeText={includedEditNoteFeedback}
            clearIncludeText={() => clearIncludedText('edit')}
            onFieldOpen={() => setNotesOpen('edit')}
            viewOnly={viewOnly}
          />

          {!viewOnly && notesOpen !== 'edit' ? (
            <AddNoteNewReport
              title='Feedback note'
              reportId={reportId}
              noteType={REPORT_NOTE_TYPES.CATEGORY}
              includeText={includedNewNoteFeedback}
              templatesOptions={categoryNotesTemplates}
              isOpenByDefault={isEditorOpenByDefault ?? !categoryNotes.length}
              meta={{
                categoryId: category.id
              }}
              parentValues={{
                noteCount: categoryNotes.length,
                dataCount: pagination?.total ?? 0
              }}
              generateNoteCallback={generateAiNote}
              isGenerateNoteLoading={isLoadingGetReportAiNote}
              onSave={saveNote}
              clearIncludeText={() => clearIncludedText('new')}
              onFieldOpen={() => setNotesOpen('new')}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default CategoryNewReport;
