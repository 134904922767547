import React from 'react';
import { Base, Modal, appUtils } from 'src/components/';
import commonTreeUtils from 'common/commonTreeUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useTree } from 'src/queries/tree';
import { useGroupMembers } from 'src/queries/company';
import commonUtils from 'common/commonUtils';
import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';

const getUserProfileLink = (node, myTreeRow) => {
  if (!node || (!myTreeRow && !myTreeRow.id)) {
    return null;
  }
  if (node.name) {
    return appUtils.getDashRoute(node.id);
  }
  return null;
};

const { PUBLIC_PROFILE_IMAGES_URL } = COMMON_CONSTANTS;

const GroupModal = ({ close, group }) => {
  const {
    data: { myTreeRow },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data,
    isFetching: isFetchingGroupMembers,
    isError: isErrorGroupMembers
  } = useGroupMembers(group.groupId);

  const isFetching = isFetchingTree || isFetchingGroupMembers;
  const isError = isErrorTree || isErrorGroupMembers;
  const isReady = data && myTreeRow && myTreeRow.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const bucketName = commonUtils.getCloudStorageImagesBucketName(
    process.env.ENV
  );

  const groupData = get(data, 'data', {
    count: 0,
    members: []
  });

  const navigate = useNavigate();
  return (
    <Modal
      variant='custom'
      close={close}
      classes='shadow text-left'
      innerClasses='w-40rem text-left'
    >
      <Base classes='text-left shadow-none' loading={isFetchingGroupMembers}>
        <h5 className='font-bold text-xl mb-5'>
          <p className='mb-0 inline-block text-left w-1/2 font-bold'>
            {group.groupName}
          </p>
          <p className='mb-0 inline-block text-right w-1/2 text-dark-grey'>
            {groupData.count}
            {' '}
            members
          </p>
        </h5>
        <div className='h-64'>
          {groupData.members.length === 0
            ? 'This group has no members assigned to it'
            : null}
          {groupData.members.map((m, index) => {
            const isLast = groupData.members.length - 1;
            const routeLink = getUserProfileLink(m, myTreeRow);
            return (
              <div className={`mb-3 ${isLast ? 'pb-5' : ''}`}>
                <div className='inline-block align-middle'>
                  {m.imageUrl ? (
                    <img
                      className='shadow w-10 h-10 rounded-full inline-block'
                      src={`${PUBLIC_PROFILE_IMAGES_URL}/${bucketName}/${m.imageUrl}`}
                    />
                  ) : (
                    <div className='w-10 h-10 bg-purple rounded-full' />
                  )}
                </div>
                <a
                  onClick={() => {
                    routeLink ? navigate(routeLink) : null;
                  }}
                  className='hover:purple ml-3 mb-0 inline-block align-middle'
                >
                  {m.name}
                </a>
              </div>
            );
          })}
        </div>
      </Base>
    </Modal>
  );
};

export default GroupModal;
