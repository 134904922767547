import { useQuery, useMutation } from 'react-query';
import api from 'src/services/api';
import qs from 'qs';
import { get } from 'lodash';
import buildParams from 'src/queries/utils/buildParams';
import PULSE_CONSTANTS from 'common/pulseConstants';

const { PULSE_QUESTION_VISIBILITY } = PULSE_CONSTANTS;

const QUERY_KEYS = {
  REVIEWS_DUE: 'reviews_due',
  PULSE_QUESTIONS: 'pulse_questions',
  PULSE_REVIEWS: 'pulseReviews'
};

const getPulseReviewFilters = (filters = {}) => {
  const reviewFilters = { ...filters };
  if (reviewFilters.range === 'custom') {
    return JSON.stringify(reviewFilters);
  }
  delete reviewFilters.start;
  delete reviewFilters.end;
  return JSON.stringify(reviewFilters);
};

export const usePulseQuestions = ({ filters, options }) => {
  const formattedFilters = {
    visibility: PULSE_QUESTION_VISIBILITY.DEFAULT,
    ...filters
  };
  const queryKey = qs.stringify(
    {
      filters: formattedFilters,
      options
    },
    { skipNulls: true }
  );

  return useQuery(
    [QUERY_KEYS.PULSE_QUESTIONS, ...queryKey.split('&')],
    async () => {
      const params = queryKey;
      const resp = await api.get(`/pulse/questions?${params}`);
      if (!resp || !resp.success) {
        console.error('GET /pulse/questions error', resp);
        return {
          pulseQuestions: false
        };
      }
      return resp.list;
    },
    {
      enabled: true
    }
  );
};

export const usePulseReviews = (filters, options, queryOptions = {}) => {
  const queryKey = qs.stringify(
    {
      filters: getPulseReviewFilters(filters),
      options
    },
    { skipNulls: true }
  );
  return useQuery(
    [QUERY_KEYS.PULSE_REVIEWS, ...queryKey.split('&')],
    async () => {
      const params = qs.stringify(
        {
          filters,
          options
        },
        { skipNulls: true }
      );
      const resp = await api.get(`/pulse/reviews?${params}`);
      if (!resp || !resp.success) {
        console.error('GET /pulse/reviews error', resp);
        return {
          list: false
        };
      }
      return {
        list: resp.list,
        total: resp.total
      };
    },
    {
      ...queryOptions
    }
  );
};

export const saveQuestionsQuery = () => useMutation((data) => api.post('/pulse/pulse', { data }), {
  mutationKey: 'saveQuestionsMutation'
});

export const enableDraftQuestionQuery = () => useMutation((data) => api.post('/pulse/questions/draft/enable', { data }));

export const updateActiveQuestionQuery = () => useMutation((data) => api.post('/pulse/questions/update', { data }));

export const disableActiveQuestionQuery = () => useMutation((data) => api.post('/pulse/questions/disable', { data }));

export const useDeletePulseQuestion = () => useMutation((id) => api.delete(`/pulse/questions/${id}`));

export const sendTestPulseQuery = () => useMutation((data) => api.post('/pulse/review/test', data));

const pulseDataQueryFn = ({ pulseId }) => () => {
  const params = buildParams({ pulseId });
  return api.get(`/emailing/pulse/email?${params}`).then((resp) => {
    if (!resp.success) {
      console.error('GET /emailing/pulse/email error', resp, params);
      return resp;
    }
    const data = {
      success: resp.success,
      userName: resp.userName,
      pulseQuestionText: resp.pulseQuestionText,
      pulseQuestionType: resp.pulseQuestionType,
      anonymity: resp.anonymity
    };
    return data;
  });
};

export const getPulseRowQuery = ({ pulseId }) => useQuery(['pulseData', pulseId], pulseDataQueryFn({ pulseId }), {
  enabled: Boolean(pulseId)
});

export const submitEmailPulseReviewQuery = () => useMutation((data) => api.post('/emailing/pulse/email', data));

export const submitPulseReviewDueQuery = () => useMutation((data) => api.post('/emailing/pulse/email', data));

export const usePulseReviewsDue = (queryOptions = {}) => {
  const { data, ...rest } = useQuery(
    [QUERY_KEYS.REVIEWS_DUE],
    () => api.get('/pulse/reviews/due').then((resp) => {
      if (!resp.success) {
        console.error(`GET /pulse/reviews/due error`);
        return {
          success: false
        };
      }
      return resp;
    }),
    {
      placeholderData: {
        pulseReviews: []
      },
      ...queryOptions
    }
  );

  return {
    pulseReviews: get(data, 'pulseReviews', []),
    ...rest
  };
};
