import React, { useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { Modal, Base, Button } from 'src/components';
import { giveFeedbackQuery, QUERY_KEYS } from 'src/queries/feedback';
import { useTree } from 'src/queries/tree';
import COMMON_CONSTANTS from 'common/commonConstants';

const { FEEDBACK_VISIBLE, FEEDBACK_TYPE } = COMMON_CONSTANTS;

const WriteNoteModal = ({
  view, revieweeId, defaultVisibility, close
}) => {
  const queryClient = useQueryClient();
  const [local, setLocal] = useState({
    visible: defaultVisibility ?? FEEDBACK_VISIBLE.PRIVATE_AND_MANAGER,
    text: ''
  });
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const { mutateAsync: giveFeedback, isLoading: giveFeedbackLoading } = giveFeedbackQuery();

  useEffect(() => {
    if (view === FEEDBACK_TYPE.NOTE) {
      setLocal({ ...local, visible: FEEDBACK_VISIBLE.PRIVATE });
    }
  }, []);

  const isFetching = isFetchingTree;
  const isError = isErrorTree;
  const isReady = tree && tree.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const submit = async () => {
    const result = await giveFeedback({
      revieweeId,
      text: local.text,
      visible: local.visible,
      type: view
    });

    if (result.success) {
      queryClient.invalidateQueries(QUERY_KEYS.FEEDBACK);
    }

    close();
  };

  return (
    <Modal
      variant='custom'
      close={close}
      classes='shadow text-left'
      innerClasses='w-40rem text-left'
    >
      <Base
        classes='text-left shadow-none'
        loading={giveFeedbackLoading}
      >
        <h5 className='font-bold text-xl mb-5'>Write Note</h5>
        <p className='text-lg mb-2'>What would you like to remember later?</p>
        <textarea
          placeholder='Write up your comments here'
          className='bg-white w-full h-20 resize-none'
          value={local.text}
          onChange={(e) => {
            setLocal({ ...local, text: e.target.value });
          }}
        />

        <div className='mb-5 mt-2 ml-1'>Only visible to you.</div>
        <div className='w-1/2 inline-block text-left'>
          <Button
            onClick={submit}
            disabled={local.text.length < 7}
            variant='yellow'
          >
            Save Note
          </Button>
        </div>
        <div className='w-1/2 inline-block text-right'>
          <button
            type='button'
            onClick={close}
            className='text-black text-lg px-4 py-2 mr-0 font-bold'
          >
            Close
          </button>
        </div>
      </Base>
    </Modal>
  );
};

export default WriteNoteModal;
