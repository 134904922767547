import React from 'react';
import { SectionBox } from '../../components';
import './Footer.scss';
import COMMON_CONSTANTS from 'common/commonConstants';

const { HELLO_WORKSTORY_EMAIL } = COMMON_CONSTANTS;

const Footer = () => (
  <SectionBox
    classes='footer-container border0 text-center marginBottom0 backgroundLightBlue'
    width='full'
    innerClasses='row'
  >
    <div className='col-xs-16 col-sm-8 col-md-4 text-center-sm paddingBottom20sm'>
      <a href='/product'>
        <h5 className='marginBottom10'>Product</h5>
      </a>
      <a href='/360-reviews' className='dark-grey'>
        <p className='marginBottom10'>360 Reviews</p>
      </a>
      <a href='/feedback' className='dark-grey'>
        <p className='marginBottom10'>Feedback</p>
      </a>
      <a href='/performance-reviews' className='dark-grey'>
        <p className='marginBottom10'>Perfomance Reviews</p>
      </a>
      <a href='/best-practices' className='dark-grey'>
        <p className='marginBottom10'>Best Practices</p>
      </a>
      <a href='/transparency' className='dark-grey'>
        <p className='marginBottom10'>Total Transparency</p>
      </a>
    </div>
    <div className='col-xs-16 col-sm-8 col-md-4 text-center-sm paddingBottom20sm'>
      <a href='/resources'>
        <h5 className='marginBottom10'>Resources</h5>
      </a>
      {/* <a href="/pricing" className="dark-grey">
          <p className="marginBottom10">Pricing</p>
        </a> */}
      <a href='/slack' className='dark-grey'>
        <p className='marginBottom10'>Slack</p>
      </a>
      <a href='/blogs' className='dark-grey'>
        <p className='marginBottom10'>Blog</p>
      </a>
      <a href='/knowledge' className='dark-grey'>
        <p className='marginBottom10'>Knowledge Library</p>
      </a>
    </div>
    <div className='col-xs-16 col-sm-8 col-md-4 text-center-sm paddingBottom20sm'>
      <h5 className='marginBottom10'>Legal</h5>
      <a href='/terms' className='dark-grey'>
        <p className='marginBottom10'>Terms of Service</p>
      </a>
      <a href='/privacy' className='dark-grey'>
        <p className='marginBottom10'>Privacy Policy</p>
      </a>
    </div>
    <div className='col-xs-16 col-sm-8 col-md-4 text-center-sm paddingBottom20sm'>
      <h5 className='marginBottom10'>Contact Us</h5>
      <a className='dark-grey'>
        <a href='/demo' className='marginBottom10'>
          {HELLO_WORKSTORY_EMAIL}
        </a>
      </a>
    </div>
  </SectionBox>
);

export default Footer;
