import React from 'react';
import { Base } from 'src/components';
import LineChart from 'src/components/LineChart/LineChart';
import { useTeamProfile } from 'src/pagesDashboard/DashTeam/queries';
import STYLE from 'src/constants/style';

const TeamChart = () => {
  const {
    teamScore,
    isFetching: isFetchingTeamProfile,
    isError: isErrorTeamProfile
  } = useTeamProfile();

  const isFetching = isFetchingTeamProfile;
  const isError = isErrorTeamProfile;
  const isReady = teamScore
    && teamScore.charts
    && teamScore.charts[0]
    && !isFetching
    && !isError;

  if (!isReady) {
    return <Base classes='mt-4 h-25rem mb-16' loading />;
  }

  console.log(teamScore);
  return (
    <Base classes={STYLE.CONTAINER_WHITE}>
      <h5 className='text-black text-left text-xl mb-2'>Performance Trend</h5>
      <LineChart
        data={teamScore.charts[0].data}
        options={teamScore.charts[0].options}
        classes='team-chart-wrap h-21rem'
      />
    </Base>
  );
};

export default TeamChart;
