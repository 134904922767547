import React, { useState, useEffect } from 'react';
import {
  submitEmailGoalCommentsQuery,
  remindLaterGoalEmailQuery,
  useGoalData
} from 'src/queries/goal';
import {
  Base, toast, Button, Circle
} from 'src/components/';
import appUtils from 'src/components/appUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import commonGoalUtils from 'common/commonGoalUtils';
import { getGoalStatuses } from 'src/containers/UserProfile/Goals/constants';
import DOMPurify from 'dompurify';
import { useNavigate, useParams } from 'react-router-dom';
import { get } from 'lodash';

const EMAIL_PAGE_STYLE = 'right-standard m-3 sm:w-38rem md:w-45rem sm:mx-auto mb-12 p-2 px-4 sm:px-1 rounded-md bg-cloud-blue mt-0';

const Loading = () => (
  <div className='pt-10 h-20 w-full'>
    <div className={EMAIL_PAGE_STYLE}>
      <Base variant='transparent' classes='text-center pb-20 pt-20' loading>
        Loading..
      </Base>
    </div>
  </div>
);

const { GOALS, INTERACTION_SOURCE } = COMMON_CONSTANTS;

const EmailGoal = () => {
  const {
    assignee, companyid, goalId, answerId
  } = useParams();
  const [selectOptions, setSelectOptions] = useState([]);
  const [state, setState] = useState({
    comments: '',
    newGoalValue: '0',
    goalData: null,
    message: null,
    result: null,
    frequency: '',
    assignee: null,
    assigneeName: '',
    assigneeTitle: '',
    assigneeImage: '',
    goalId: null,
    answerId: null,
    answer: '',
    companyid: null,
    status: ''
  });

  const { mutateAsync: submitEmailGoalComments } = submitEmailGoalCommentsQuery();
  const { mutateAsync: remindLaterGoalEmail } = remindLaterGoalEmailQuery();
  const [isLoading, setIsLoading] = useState(false);
  const {
    data,
    isFetching: isFetchingGoal,
    isError: isErrorGoal
  } = useGoalData({
    assignee: state.assignee,
    companyid: state.companyid,
    goalId: state.goalId,
    answerId: state.answerId
  });

  const GOAL_STATUSES = getGoalStatuses();

  useEffect(() => {
    if (assignee && companyid) {
      setState({
        ...state,
        assignee,
        companyid,
        goalId,
        answerId
      });
    }
  }, [assignee, companyid]);

  useEffect(() => {
    if (data && data.goal && data.goal.id) {
      setState({
        ...state,
        goalData: data.goal,
        status: data.goal.status,
        message: data.message,
        result: data.result,
        assigneeName: data.assigneeName,
        assigneeTitle: data.assigneeTitle,
        assigneeImage: data.assigneeImage
      });
    }
  }, [data]);

  useEffect(() => {
    if (state.goalData) {
      const frequency = commonGoalUtils.getGoalFrequency(state.goalData);
      setState({ ...state, frequency });

      const { type, progress } = state.goalData;

      if (type === GOALS.TYPES.BINARY) {
        setSelectOptions(['Not done', 'Done']);

        if (progress === '0') {
          setState({
            ...state,
            goalData: { ...state.goalData, progress: 'Not done' }
          });
        } else if (progress === '1') {
          setState({
            ...state,
            goalData: { ...state.goalData, progress: 'Done' }
          });
        }
      } else if (type === GOALS.TYPES.PERCENT) {
        const options = [];

        for (let i = 0; i <= 100; i += 10) {
          options.push(i);
        }

        setSelectOptions(options);
      }
    }
  }, [state.goalData]);

  const navigate = useNavigate();
  useEffect(() => {
    if (state.answerId === '1') {
      setState({ ...state, answer: 'update goal' });
    }
  }, [state.answerId]);

  const redirect = () => {
    const isLoggedIn = appUtils.isLoggedIn();
    if (isLoggedIn) {
      return navigate(appUtils.getDashRoute());
    }
    navigate('/login');
  };

  const remindLater = async () => {
    let remindLaterResponse;

    setIsLoading(true);
    try {
      remindLaterResponse = await remindLaterGoalEmail({
        goalId: state.goalId,
        companyid: state.companyid,
        assignee: state.assignee
      });
    } catch (error) {
      toast.error('An error occurred while reminding later');
      setIsLoading(false);
      return;
    }
    setIsLoading(false);

    if (!get(remindLaterResponse, 'success')) {
      toast.error('This goal has expired');
      return redirect();
    }
    toast.show(
      `Thanks ${state.assigneeName}, we will ask for an update in ${state.frequency}`
    );
    redirect();
  };

  useEffect(() => {
    if (state.answerId === '-1' && state.goalData) {
      remindLater();
    }
  }, [state.frequency]);

  const isFetching = isFetchingGoal;
  const isError = isErrorGoal;
  const isReady = data && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const { result } = state;

  const submitComments = async () => {
    if (!state.comments) {
      toast.error('The comments are required');
      return;
    }

    let commentsResponse;
    setIsLoading(true);
    try {
      commentsResponse = await submitEmailGoalComments({
        assignee: state.assignee,
        companyid: state.companyid,
        goalId: state.goalId,
        updateData: {
          newGoalValue: state.newGoalValue,
          text: state.comments,
          status: state.status,
          source: INTERACTION_SOURCE.EMAIL
        }
      });
    } catch (error) {
      toast.error('An error occurred while submitting the comments');
      setIsLoading(false);
      return;
    }
    setIsLoading(false);

    if (!get(commentsResponse, 'success')) {
      toast.error(get(commentsResponse, 'message'));
      return redirect();
    }
    toast.show(`Thanks ${state.assigneeName}, your goal has been updated!`);
    redirect();
  };

  if (!assignee || !state.result) {
    return <Loading />;
  }

  const {
    title: goalTitle,
    description: goalDescription,
    type: goalType,
    progress: goalValue
  } = state.goalData;

  return (
    result
    && state.answer === 'update goal' && (
      <div className='flex flex-col items-center w-full'>
        <div className='max-w-1240 w-full'>
          <Base variant='transparent' classes='pb-6' loading={isFetchingGoal}>
            <div className='h-32 -mb-18 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500' />
            <div className='flex flex-col items-center mb-8'>
              <Circle
                size='xxl'
                imageUrl={state.assigneeImage}
                classes='mb-4 user-setting-profile-image relative h-131 w-131'
              />
              <div className=''>
                <div className='text-2xl bold'>{state.assigneeName}</div>
                <div className='text-base text-gray-400 text-center'>
                  {state.assigneeTitle}
                </div>
              </div>
            </div>
            {!state.result && appUtils.isLoggedIn() ? (
              <div className='mt-6'>
                <Button
                  onClick={() => {
                    navigate(appUtils.getDashRoute());
                  }}
                  variant='yellow'
                >
                  Dashboard
                </Button>
              </div>
            ) : null}
            <div className='mx-auto w-5/6'>
              <div className='mb-8 flex flex-col font-bold md:flex-row'>
                <div className='uppercase text-gray-500 text-xl mb-2 md:w-2/5'>
                  Title:
                </div>
                <div className='text-lg md:w-3/5 ml-6'>{goalTitle}</div>
              </div>
              <div className='mb-8 flex flex-col font-bold md:flex-row'>
                <div className='uppercase text-gray-500 text-xl mb-2 md:w-2/5'>
                  Description:
                </div>
                <div
                  className='text-lg md:w-3/5 ml-6 ql-editor'
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(goalDescription)
                  }}
                />
              </div>
              <div className='mb-8 flex flex-col font-bold md:flex-row'>
                <div className='uppercase text-gray-500 text-xl mb-2 md:w-2/5'>
                  Current Goal Value:
                </div>
                <div className='text-lg flex md:w-3/5'>
                  <div className='mt-2 mr-2 mb-2'>
                    {commonGoalUtils.getGoalType(goalType)}
                  </div>
                  <div className='mt-2 mb-2'>{goalValue}</div>
                </div>
              </div>

              <div className='mb-8 flex flex-col md:flex-row'>
                <div className='uppercase text-gray-500 font-bold text-xl mb-2 md:w-2/5'>
                  Updated Goal Value:
                </div>
                <div className='text-lg flex md:w-3/5'>
                  <div className='mt-2 mr-2 mb-2'>
                    {commonGoalUtils.getGoalType(goalType)}
                  </div>

                  {goalType === GOALS.TYPES.DOLLAR
                  || goalType === GOALS.TYPES.NUMBER ? (
                    <input
                      type='number'
                      placeholder='Any progress?'
                      value={state.newGoalValue}
                      onChange={(e) => {
                        setState({ ...state, newGoalValue: e.target.value });
                      }}
                      className='resize-none w-20 sm:w-20rem h-8 block mt-1.5'
                    />
                    ) : (
                      <select
                        className='resize-none w-20 sm:w-20rem h-8 block mt-1.5'
                        onChange={(e) => {
                          setState({ ...state, newGoalValue: e.target.value });
                        }}
                      >
                        {selectOptions.map((element, index) => (
                          <option
                            key={index}
                            value={
                            goalType === GOALS.TYPES.BINARY ? index : element
                          }
                          >
                            {element}
                          </option>
                        ))}
                      </select>
                    )}
                </div>
              </div>

              <div className='mb-8 flex flex-col md:flex-row'>
                <div className='uppercase text-gray-500 font-bold text-xl mb-2 md:w-2/5'>
                  Status:
                </div>
                <div className='md:w-3/5'>
                  <select
                    className='resize-none w-20 sm:w-20rem h-8 mt-1.5 ml-6'
                    onChange={(e) => {
                      setState({ ...state, status: e.target.value });
                    }}
                  >
                    {GOAL_STATUSES.map((status, index) => (
                      <option
                        key={index}
                        value={status.value}
                        selected={status.value === state.goalData.status}
                      >
                        {status.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className='mb-8 flex flex-col md:flex-row'>
                <div className='uppercase text-gray-500 font-bold text-xl mb-2 md:w-2/5'>
                  Additional comments:
                </div>
                <div className='md:w-3/5'>
                  <textarea
                    value={state.comments}
                    maxLength='500'
                    onChange={(e) => {
                      setState({ ...state, comments: e.target.value });
                    }}
                    className='resize-none mx-auto w-full sm:w-34rem ml-6 h-24 block mb-8'
                    placeholder='Note your updates here'
                  />
                </div>
              </div>
              <div className='flex justify-center md:justify-end mt-20'>
                <Button
                  variant='yellow'
                  onClick={submitComments}
                  disabled={isLoading}
                >
                  Save
                </Button>
              </div>
            </div>
          </Base>
        </div>
      </div>
    )
  );
};

export default EmailGoal;
