import React, { useEffect, useState } from 'react';
import InboxSVG from 'src/assets/svg/inbox.svg';
import Home2SVG from 'src/assets/svg/home-2.svg';
import SidebarButton from 'src/containers/Sidebar/SidebarButton';
import SidebarNavButton from 'src/containers/Sidebar/SidebarNavButton';
import { useReviewsDue } from 'src/queries/reviews';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useAccountTasks } from 'src/queries/account';
import { usePulseReviewsDue } from 'src/queries/pulse';
import { appUtils } from 'src/components/index';
import { useNotifications } from 'src/queries/notifications';
import { useNavigate } from 'react-router-dom';

const {
  SIDEBAR_PAGES: { HOME, INBOX }
} = COMMON_CONSTANTS;

const SidebarInbox = ({ page, routeCallback = () => {} }) => {
  const navigate = useNavigate();
  const loggedUserId = appUtils.getLoggedUserId();
  const {
    reviews,
    isFetching: isFetchingReviewsDue,
    isRefetching: isRefetchingReviewsDue
  } = useReviewsDue();
  const {
    data: taskCountData,
    isFetching: isFetchingTaskCount,
    isRefetching: isRefetchingTaskCount
  } = useAccountTasks('me', {
    specs: { count: true }
  });
  const {
    data: notificationsCountData,
    isFetching: isFetchingNotificationsCount,
    isRefetching: isRefetchingNotificationsCount
  } = useNotifications(
    {
      recipientIds: [loggedUserId],
      isRead: false
    },
    {
      specs: { count: true }
    }
  );
  const {
    pulseReviews,
    isFetching: isFetchingPulseReviewsDue,
    isError: isErrorPulseReviewsDue,
    isRefetching: isRefetchingPulseReviewsDue
  } = usePulseReviewsDue();

  const [isInboxExpanded, setIsInboxExpanded] = useState(
    Object.values(INBOX).includes(page)
  );
  useEffect(() => {
    if (Object.values(INBOX).includes(page)) setIsInboxExpanded(true);
  }, [page]);

  const isFetching = isFetchingReviewsDue
    || isFetchingTaskCount
    || isFetchingPulseReviewsDue
    || isFetchingNotificationsCount;

  const isRefetching = isRefetchingReviewsDue
    || isRefetchingTaskCount
    || isRefetchingPulseReviewsDue
    || isRefetchingNotificationsCount;

  const inboxCount = reviews.length
    + notificationsCountData.count
    + taskCountData.count
    + pulseReviews.length;
  let taskCount = reviews.length > 0 ? taskCountData.count + 1 : taskCountData.count;
  taskCount = pulseReviews.length > 0 ? taskCount + 1 : taskCount;
  const notificationsCount = notificationsCountData.count;

  return (
    <div className='w-full flex flex-col'>
      <SidebarButton
        text='Home'
        icon={<Home2SVG />}
        onClick={() => {
          navigate(appUtils.getHomeRoute());
          routeCallback();
        }}
        selected={page === HOME}
      />
      <SidebarButton
        text='Inbox'
        icon={<InboxSVG />}
        onClick={() => setIsInboxExpanded(!isInboxExpanded)}
        box={{
          show: inboxCount > 0,
          value: inboxCount,
          isLoading: isFetching && !isRefetching && inboxCount <= 9
        }}
        expanded={isInboxExpanded}
      />
      {isInboxExpanded ? (
        <>
          <SidebarNavButton
            text='Tasks'
            onClick={() => {
              navigate('/dashboard/inbox/tasks');
              routeCallback();
            }}
            box={{
              show: taskCount > 0,
              value: taskCount,
              isLoading:
                isFetchingTaskCount && !isRefetchingTaskCount && taskCount <= 9
            }}
            selected={page === INBOX.TASKS}
          />
          <SidebarNavButton
            text='Notifications'
            onClick={() => {
              navigate('/dashboard/inbox/notifications');
              routeCallback();
            }}
            box={{
              show: notificationsCount > 0,
              value: notificationsCount,
              isLoading:
                isFetchingNotificationsCount
                && !isRefetchingNotificationsCount
                && notificationsCount <= 9
            }}
            selected={page === INBOX.NOTIFICATIONS}
          />
          {reviews?.length ? (
            <SidebarNavButton
              text='Reviews due'
              onClick={() => {
                navigate('/dashboard/inbox/reviews');
                routeCallback();
              }}
              box={{
                show: reviews.length > 0,
                value: reviews.length,
                isLoading:
                  isFetchingReviewsDue
                  && !isRefetchingReviewsDue
                  && reviews.length <= 9
              }}
              selected={page === INBOX.REVIEWS_DUE}
            />
          ) : null}
          {pulseReviews?.length ? (
            <SidebarNavButton
              text='Pulse due'
              onClick={() => {
                navigate('/dashboard/inbox/pulse');
                routeCallback();
              }}
              box={{
                show: pulseReviews.length > 0,
                value: pulseReviews.length,
                isLoading:
                  isFetchingPulseReviewsDue
                  && !isRefetchingPulseReviewsDue
                  && pulseReviews.length <= 9
              }}
              selected={page === INBOX.PULSE_DUE}
            />
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default SidebarInbox;
