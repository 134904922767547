import React, {
  cloneElement,
  isValidElement,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  Menu, MenuButton, MenuItem, MenuItems
} from '@headlessui/react';

const DropMenu = ({
  label = 'Open Menu',
  items = [],
  component = null,
  direction = 'right',
  disabled = false
}) => {
  const buttonRef = useRef(null);
  const [position, setPosition] = useState(null);

  useEffect(() => {
    if (buttonRef.current) {
      const { right, width } = buttonRef.current.getBoundingClientRect();
      const { innerWidth } = window;
      if (direction === 'left') {
        setPosition(innerWidth - right);
      } else {
        setPosition(right + width);
      }
    }
  }, [buttonRef]);

  return (
    <Menu as='div'>
      {isValidElement(component) ? (
        <MenuButton
          ref={buttonRef}
          disabled={disabled}
          className='flex items-center justify-center h-full w-full'
        >
          {cloneElement(component)}
        </MenuButton>
      ) : (
        <MenuButton
          ref={buttonRef}
          disabled={disabled}
          className='inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-[16px] py-[7px] text-sm text-gray-900 font-semibold shadow-sm ring-1 ring-inset ring-gray-300 enabled:hover:bg-gray-50 truncate'
        >
          {label}
        </MenuButton>
      )}
      <MenuItems
        transition
        className='absolute z-10 mt-2 w-48 rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in'
        style={{
          right: direction === 'left' ? position : 'auto'
        }}
      >
        {items
          .filter(({ hidden = false }) => !hidden)
          .map(
            (
              {
                label: itemLabel = 'Action',
                onClick = () => {},
                disabled: itemDisabled = false
              },
              index
            ) => (
              <MenuItem key={index}>
                <button
                  onClick={onClick}
                  className='block px-4 py-2 text-sm text-black hover:bg-gray-50 text-left w-full'
                  disabled={itemDisabled}
                >
                  {itemLabel}
                </button>
              </MenuItem>
            )
          )}
      </MenuItems>
    </Menu>
  );
};

export default DropMenu;
