import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useAllCompanies } from '../../queries/admin';
import { SectionBox } from '../../components';
import { adminThunks } from '../../thunks';
import utils from './utils';

const AdminStats = () => {
  const [reviews, setReviews] = useState({});
  const {
    data: companies,
    isFetching: isFetchingCompanies,
    isError: isErrorCompanies
  } = useAllCompanies({
    enabled: true
  });

  const isFetching = isFetchingCompanies;
  const isError = isErrorCompanies;
  const isReady = companies && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const getCompanyData = (companyid) => {
    adminThunks
      .getCompany(companyid)()
      .then((resp) => {
        const stats = utils.getStats(resp.data);
        const newReviews = {
          ...reviews,
          [companyid]: {
            reviews: resp.data,
            stats
          }
        };
        setReviews(newReviews);
      });
  };

  return (
    <div>
      <SectionBox
        classes='minHeight150px'
        sectionType='dash'
        loading={!isReady}
      >
        {companies
          && companies.map((company, index) => (
            <div key={index}>
              <a
                className='block blue linkBlue'
                onClick={() => {
                  getCompanyData(company.id);
                }}
              >
                Company:
                {' '}
                {company.name}
              </a>
              {reviews[company.id] ? (
                <div>
                  <p>
                    All - Reviewed:
                    {reviews[company.id].stats.reviewed}
                  </p>
                  <p>
                    All - Reviewed + Sent:
                    {reviews[company.id].stats.reviewedAndSent}
                  </p>
                  <p>
                    All - Percent of reviews reviewed:
                    {reviews[company.id].stats.reviewPercent}
                  </p>

                  <p>
                    Slack - Reviewed:
                    {reviews[company.id].stats.reviewedSlack}
                  </p>
                  <p>
                    Slack - Reviewed + Sent:
                    {reviews[company.id].stats.reviewedAndSentSlack}
                  </p>
                  <p>
                    Slack - Percent of reviews reviewed:
                    {reviews[company.id].stats.reviewPercentSlack}
                  </p>
                </div>
              ) : null}
            </div>
          ))}
      </SectionBox>
    </div>
  );
};

export default AdminStats;
