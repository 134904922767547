import { useContext } from 'react';
import { DashContext } from 'src/pagesDashboard/Dash/context/DashProvider';
import { UserProfileContext } from 'src/pagesDashboard/UserProfile/context/UserProfileProvider';
import appUtils from 'src/components/appUtils';
import { useAccountOverview } from 'src/queries/account';
import COMMON_CONSTANTS from 'common/commonConstants';
import { COLORS } from 'src/constants/style';
import commonQuestionsUtils from 'common/commonQuestionsUtils';
import { useCompany } from 'src/queries/company';
import { isEmpty } from 'lodash';

const { DATE_RANGE_FILTERS } = COMMON_CONSTANTS;

const getMaxHeatmapBatches = (rangeValue) => {
  if (
    rangeValue === DATE_RANGE_FILTERS.LAST_MONTH.key
    || rangeValue === DATE_RANGE_FILTERS.THREE_MONTHS.key
  ) {
    return 0;
  }
  if (rangeValue === DATE_RANGE_FILTERS.SIX_MONTHS.key) {
    return 1;
  }
  if (rangeValue === DATE_RANGE_FILTERS.ONE_YEAR.key) {
    return 4;
  }
  return 12;
};

export const useOverviewTab = (userId) => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const loggedUserId = appUtils.getLoggedUserId();
  const isMyProfile = userId === loggedUserId;
  const { context } = useContext(
    isMyProfile ? DashContext : UserProfileContext
  );
  const { range } = context;
  const { start, end, value } = range;

  const {
    data,
    isFetching: isFetchingAccountOverview,
    isError: isErrorAccountOverview
  } = useAccountOverview({
    id: userId,
    start,
    end
  });

  const {
    account = {},
    topScores = {},
    revieweeHeatmap = {},
    reviewerHeatmap = {},
    categoryRadialGraph = [],
    sentimentPieChart = {},
    highestSentimentReviewReceived = {},
    topReviewers = [],
    team = {}
  } = data;

  const { reviewedReceivedCount } = topScores;

  let topReviewersData = {};
  if (reviewedReceivedCount) {
    topReviewersData = {
      reviewers: topReviewers,
      reviewedReceivedCount
    };
  }

  let categoryRadialGraphData = [];
  if (!isFetchingCompany) {
    categoryRadialGraphData = categoryRadialGraph.map((cat) => {
      const category = commonQuestionsUtils.findCategory(
        company.questions,
        cat._id
      );
      return {
        ...cat,
        value: cat.avgScore,
        subLabel: cat.avgScoreLabel,
        label: category?.label || 'Unavailable'
      };
    });
  }

  const sentimentPieChartData = {};
  const { positiveReceivedPercentage, negativeReceivedPercentage } = sentimentPieChart;
  if (positiveReceivedPercentage || negativeReceivedPercentage) {
    sentimentPieChartData.Positive = {
      value: positiveReceivedPercentage,
      color: COLORS.INDIGO
    };
    sentimentPieChartData.Negative = {
      value: negativeReceivedPercentage,
      color: COLORS.PINK
    };
  }

  const teamData = {};
  const { manager, directReports, peers } = team;
  if (!isEmpty(manager) || !isEmpty(directReports) || !isEmpty(peers)) {
    teamData.manager = manager;
    teamData.directReports = directReports;
    teamData.peers = peers;
  }

  const maxHeatmapBatches = getMaxHeatmapBatches(value);
  return {
    isFetching: isFetchingAccountOverview || isFetchingCompany,
    isError: isErrorAccountOverview || isErrorCompany,
    account,
    topScores: {
      data: topScores
    },
    revieweeHeatmap: {
      data: revieweeHeatmap,
      maxBatches: maxHeatmapBatches
    },
    reviewerHeatmap: {
      data: reviewerHeatmap,
      maxBatches: maxHeatmapBatches
    },
    categoryRadialGraph: {
      data: categoryRadialGraphData
    },
    sentimentPieChart: {
      data: sentimentPieChartData
    },
    highestSentimentReviewReceived: {
      data: highestSentimentReviewReceived
    },
    topReviewers: {
      data: topReviewersData
    },
    team: {
      data: teamData
    }
  };
};

export default useOverviewTab;
