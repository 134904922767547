import React, { useState } from 'react';
import { Button, toast, Base } from 'src/components';
import { useCompany } from 'src/queries/company';
import { sendInvites } from 'src/queries/user';
import STYLE from 'src/constants/style';
import { useAccounts } from 'src/queries/account';
import COMMON_CONSTANTS from 'common/commonConstants';
import { set } from 'lodash';

const { USER_STATE } = COMMON_CONSTANTS;

const InviteUsers = () => {
  const { mutateAsync: invite } = sendInvites();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const {
    data: unverifiedUsers,
    isFetching: isFetchingUnverifiedUsers,
    isError: isErrorUnverifiedUsers
  } = useAccounts(
    {
      verified: false,
      status: [USER_STATE.ACTIVE]
    },
    {
      page: {
        size: Number.MAX_SAFE_INTEGER
      }
    }
  );

  const isFetching = isFetchingCompany || isFetchingUnverifiedUsers;
  const isError = isErrorCompany || isErrorUnverifiedUsers;
  const isReady = company && company.id && !isFetching && !isError;

  const [disabledIds, setDisabledIds] = useState([]);

  if (!isReady) {
    return null;
  }

  return (
    <Base classes={STYLE.CONTAINER_WHITE} loading={!isReady}>
      <div className='flex flex-col'>
        <h5 className='mb-2'>
          Invite Users
          {' '}
          {unverifiedUsers.length ? `(${unverifiedUsers.length})` : ''}
        </h5>
        {unverifiedUsers.length ? (
          <p className='mb-0'>
            These (active) users have not accepted their invitation:
          </p>
        ) : (
          <p className='mb-0'>
            All active users have accepted their invitation to join WorkStory
          </p>
        )}
        <ul
          className={`${unverifiedUsers.length ? 'overflow-y-scroll' : ''} h-64 mb-2`}
        >
          {unverifiedUsers.map((user, index) => (
            <div key={index} className='flex flex-row my-2'>
              <li className='text-left inline-block'>
                {user.name}
                {' '}
                (
                {user.email}
                )
              </li>
              <div className='flex-1 inline-block text-right align-top mr-3'>
                <button
                  disabled={disabledIds.some((id) => user._id === id)}
                  className='px-2 bg-yellow hover:bg-hover-yellow disabled:bg-light-gray focus:outline-none rounded'
                  onClick={async () => {
                    toast.show('Sending invite');
                    setDisabledIds([...disabledIds, user._id]);
                    await invite({ users: [user] });
                    setTimeout(() => {
                      setDisabledIds(
                        disabledIds.filter((id) => id !== user._id)
                      );
                    }, 15000);
                    toast.show('Invite sent!');
                  }}
                >
                  Send
                </button>
              </div>
            </div>
          ))}
        </ul>
        <div className='mb-1'>
          <Button
            variant='yellow'
            disabled={
              !company.active
              || !unverifiedUsers.length
              || disabledIds.length === unverifiedUsers.length
            }
            onClick={async () => {
              toast.show('Sending invite emails');
              setDisabledIds(unverifiedUsers.map((user) => user._id));
              await invite({ users: unverifiedUsers });
              toast.show('Invites sent!');
            }}
          >
            Send All Invites
          </Button>
        </div>
      </div>
    </Base>
  );
};

export default InviteUsers;
