import React from 'react';
import NavigationItem from 'src/pagesDashboard/HomePage/components/mobile/NavigationItem';
import {
  Button, Base, toast, Select
} from 'src/components/';
import { useForm, Controller } from 'react-hook-form';
import FlagSVG from 'src/assets/svg/flag.svg';
import STYLE from 'src/constants/style';
import { useQueryClient } from 'react-query';
import appUtils from 'src/components/appUtils';
import commonGoalUtils from 'common/commonGoalUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useGoalUpdate, QUERY_KEYS as GOAL_QUERY_KEYS } from 'src/queries/goal';
import { get } from 'lodash';
import commonDateUtils from 'common/commonDateUtils';
import { getGoalStatuses } from 'src/containers/UserProfile/Goals/constants';

const { GOALS: GOAL_CONSTANTS } = COMMON_CONSTANTS;

const displayFormErrors = (errors) => {
  Object.keys(errors).forEach((key) => {
    const val = errors[key];
    const message = get(val, 'message');
    if (!message) {
      return;
    }
    toast.error(message);
  });
};

const MobileAddGoalUpdate = ({ goal, setShowAddUpdate }) => {
  const { mutateAsync: updateGoal, isLoading: isUpdating } = useGoalUpdate(
    goal._id
  );
  const GOAL_STATUSES = getGoalStatuses();
  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      text: '',
      status: commonGoalUtils.getGoalStatusOption(goal, GOAL_STATUSES),
      progress: commonGoalUtils.getGoalProgressOption(goal)
    }
  });
  const formValues = watch();
  const queryClient = useQueryClient();

  const saveUpdate = async () => {
    try {
      const loggedUser = appUtils.getLoggedUser();
      const payload = {
        text: formValues.text,
        status: formValues.status.value,
        progress: formValues.progress.value || formValues.progress,
        author: loggedUser.id,
        timestamp: commonDateUtils.getUnixDateNow()
      };

      const response = await updateGoal({ updates: [payload] });
      if (!response || !response.success) {
        toast.error('Try again!');
      } else if (response.warning) {
        const message = get(
          response,
          'message',
          'Failed to create goal. Try again later!'
        );
        toast.closeAll();
        return toast.show(message);
      } else {
        toast.show('Update added!');
        reset();
        queryClient.invalidateQueries(GOAL_QUERY_KEYS.GOALS);
        queryClient.invalidateQueries(GOAL_QUERY_KEYS.GOAL_STATISTICS);
        setShowAddUpdate(false);
      }
    } catch (error) {
      return toast.error(error ?? 'Try again!');
    }
  };

  const error = () => displayFormErrors(errors);

  return (
    <Base classes={STYLE.MOBILE_CONTAINER_NO_SCROLL_MARGINLESS}>
      <div>
        <div className='w-full fixed top-0 mt-12 bg-white z-50'>
          <NavigationItem
            goBack={() => setShowAddUpdate(false)}
            title={goal.title}
          >
            <FlagSVG />
          </NavigationItem>
        </div>
      </div>
      <div className='px-3 mt-[87px]'>
        <div className='flex'>
          <div className='w-full'>
            <p className='mb-1'>Goal Status</p>
            <Controller
              control={control}
              name='status'
              rules={{ required: true }}
              render={({ field }) => {
                const title = field.value.label;
                return (
                  <Select
                    placeholder='Select status'
                    classes='w-full'
                    disabled={isUpdating}
                    options={GOAL_STATUSES}
                    title={title}
                    {...field}
                  />
                );
              }}
            />
          </div>
        </div>
        <div className='w-full'>
          <p className='mb-1 mt-3'>Goal Value</p>
          <Controller
            control={control}
            name='progress'
            rules={{
              required: true,
              ...(goal.type === GOAL_CONSTANTS.TYPES.PERCENT && {
                min: 0,
                max: 100
              })
            }}
            render={({ field }) => {
              if (goal.type === GOAL_CONSTANTS.TYPES.BINARY) {
                const title = field.value.label;
                const options = [
                  { value: '0', label: 'Not done' },
                  { value: '1', label: 'Done' }
                ];
                return (
                  <Select
                    placeholder='Any progress?'
                    classes='w-full'
                    disabled={isUpdating}
                    options={options}
                    title={title}
                    {...field}
                  />
                );
              }
              if (goal.type === GOAL_CONSTANTS.TYPES.DOLLAR) {
                return (
                  <div className='flex items-center w-full bg-white border border-0 rounded-md ring-1 ring-inset ring-gray-300 h-40px px-3'>
                    <span className='align-middle'>
                      {commonGoalUtils.getGoalType(goal.type)}
                    </span>
                    <input
                      className='w-full ml-1.5 w-full bg-white border border-0 px-0 focus:outline-none'
                      placeholder='Any progress?'
                      disabled={isUpdating}
                      type='number'
                      value={field.value}
                      {...field}
                    />
                  </div>
                );
              }
              if (goal.type === GOAL_CONSTANTS.TYPES.NUMBER) {
                return (
                  <div className='flex items-center w-full bg-white border border-0 rounded-md ring-1 ring-inset ring-gray-300 h-40px px-3'>
                    <span className='align-middle'>
                      {commonGoalUtils.getGoalType(goal.type)}
                    </span>
                    <input
                      className='w-full ml-1.5 w-full bg-white border border-0 px-0 focus:outline-none'
                      placeholder='Any progress?'
                      disabled={isUpdating}
                      type='number'
                      value={field.value}
                      {...field}
                    />
                  </div>
                );
              }
              if (goal.type === GOAL_CONSTANTS.TYPES.PERCENT) {
                return (
                  <div className='flex items-center gap-1 w-full bg-white border border-0 rounded-md ring-1 ring-inset ring-gray-300 h-40px px-3'>
                    <span className='align-middle text-gray-500'>
                      {commonGoalUtils.getGoalType(goal.type)}
                    </span>
                    <input
                      className='w-full bg-white border border-0 px-0 focus:outline-none'
                      placeholder='Any progress?'
                      disabled={isUpdating}
                      type='number'
                      min='0'
                      max='100'
                      value={field.value}
                      {...field}
                    />
                  </div>
                );
              }
            }}
          />
        </div>
        <div className='w-full mt-3 mb-24'>
          <p className='mb-1'>Goal Update</p>
          <Controller
            control={control}
            name='text'
            rules={{
              required: true,
              minLength: {
                value: 5,
                message: 'Comments must be at least 5 characters long'
              }
            }}
            render={({ field }) => (
              <textarea
                className='w-full bg-white border border-0 rounded-md ring-1 ring-inset ring-gray-300 px-3 h-20'
                placeholder='Note your updates here'
                disabled={isUpdating}
                {...field}
              />
            )}
          />
        </div>
      </div>
      <div className='w-full flex flex-col items-center px-6 pb-2 gap-3 mt-1 fixed bottom-0 bg-white'>
        <Button
          variant='black'
          disabled={!isValid || isUpdating}
          classes='w-full'
          type='submit'
          onClick={handleSubmit(saveUpdate, error)}
        >
          Add Update
        </Button>
        <Button
          variant='custom'
          disabled={isUpdating}
          classes='bg-white w-full text-black font-bold text-sm px-4 pt-2 pb-2 border rounded-md border-gray-300'
          onClick={() => setShowAddUpdate(false)}
        >
          Cancel
        </Button>
      </div>
    </Base>
  );
};

export default MobileAddGoalUpdate;
