import COMMON_CONSTANTS from 'common/commonConstants';
import commonDateUtils from 'common/commonDateUtils';

const { DATE_RANGE_FILTERS } = COMMON_CONSTANTS;

export default {
  managerId: null,
  userIds: null,
  status: null,
  lastUpdated: {
    value: DATE_RANGE_FILTERS.THREE_MONTHS.key,
    start: commonDateUtils.getDateFromDaysAgo(
      DATE_RANGE_FILTERS.THREE_MONTHS.days
    ).unix,
    end: undefined
  }
};
