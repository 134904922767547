import React from 'react';
import NavigationItem from 'src/pagesDashboard/HomePage/components/mobile/NavigationItem';
import { useNavigate } from 'react-router-dom';
import {
  Button, Base, Select, appUtils
} from 'src/components/';
import { Controller } from 'react-hook-form';
import FlagSVG from 'src/assets/svg/flag.svg';
import STYLE from 'src/constants/style';
import COMMON_CONSTANTS from 'common/commonConstants';
import {
  GOAL_TYPES,
  getGoalStatuses,
  GOAL_UPDATE_FREQUENCIES
} from 'src/containers/UserProfile/Goals/constants';

const { MOBILE_SIZES } = COMMON_CONSTANTS;

const MobileGoalSetup = ({
  register,
  control,
  handleSubmit,
  error,
  isCreateDisabled,
  save,
  isUpdating
}) => {
  const navigate = useNavigate();
  const mobileSize = appUtils.getMobileSize();
  return (
    <Base classes={STYLE.MOBILE_CONTAINER_NO_SCROLL}>
      <div>
        <div className='w-full fixed top-0 mt-12 bg-white z-50'>
          <NavigationItem
            goBack={() => navigate('/dashboard/me/goals')}
            title='New Goal'
          >
            <FlagSVG />
          </NavigationItem>
        </div>
      </div>
      <div className='px-3 overflow-y-scroll h-65vh mt-[87px]'>
        <div className='mb-4'>
          <p className='mb-1'>Goal Title</p>
          <input
            className='w-full bg-white border-0 rounded-md ring-1 ring-inset ring-gray-300 h-40px px-3'
            placeholder='Give your goal a title'
            type='text'
            disabled={isUpdating}
            name='title'
            {...register('title', {
              required: 'Please write a title for this goal'
            })}
          />
        </div>
        <div className='mb-4'>
          <p className='mb-1 mt-3'>Goal Description</p>
          <input
            className='w-full bg-white border-0 rounded-md ring-1 ring-inset ring-gray-300 h-40px px-3'
            placeholder='Give your goal a title'
            type='text'
            disabled={isUpdating}
            name='description'
            {...register('description', {
              required: 'Please write a goal description'
            })}
          />
        </div>
        <div className='mb-4'>
          <p className='mb-1 mt-3'>Goal Measurement</p>
          <div className='w-full'>
            <Controller
              name='progressType'
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                const title = field.value ? field.value.label : '';
                return (
                  <Select
                    placeholder='abcd'
                    classes='w-full'
                    disabled={isUpdating}
                    options={GOAL_TYPES}
                    title={title}
                    drop='up'
                    {...field}
                  />
                );
              }}
            />
          </div>
        </div>
        <div className='mb-4'>
          <p className='mb-1 mt-3'>Goal Status</p>
          <div className='w-full'>
            <Controller
              name='status'
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                const title = field.value ? field.value.label : '';
                return (
                  <Select
                    placeholder='Set status'
                    classes='w-full'
                    disabled={isUpdating}
                    options={getGoalStatuses()}
                    title={title}
                    drop='up'
                    {...field}
                  />
                );
              }}
            />
          </div>
        </div>
        <div className={mobileSize === MOBILE_SIZES.MINI ? 'mb-24' : `mb-16`}>
          <p className='mb-1 mt-3'>Goal Frequency</p>
          <div className='w-full'>
            <Controller
              name='frequency'
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                const title = field.value ? field.value.label : '';
                return (
                  <Select
                    placeholder='Set status'
                    classes='w-full'
                    disabled={isUpdating}
                    options={GOAL_UPDATE_FREQUENCIES}
                    title={title}
                    drop='up'
                    {...field}
                  />
                );
              }}
            />
          </div>
        </div>
      </div>
      <div className='w-full flex flex-col items-center px-6 pb-2 gap-3 mt-1 fixed bottom-0 bg-white'>
        <Button
          onClick={handleSubmit(save, error)}
          variant='black'
          classes='w-full'
          disabled={isCreateDisabled() || isUpdating}
        >
          Create Goal
        </Button>
        <Button
          variant='custom'
          disabled={isUpdating}
          classes='bg-white w-full text-black font-bold text-sm px-4 pt-2 pb-2 border rounded-md border-gray-300'
          onClick={() => navigate('/dashboard/me/goals')}
        >
          Cancel
        </Button>
      </div>
    </Base>
  );
};

export default MobileGoalSetup;
