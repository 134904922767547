import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import NavigationItem from 'src/pagesDashboard/HomePage/components/mobile/NavigationItem';
import FlagSVG from 'src/assets/svg/flag.svg';
import { Button, Base } from 'src/components/';
import commonDateUtils from 'common/commonDateUtils';
import STYLE from 'src/constants/style';
import CircleV2 from 'src/components/Circle/CircleV2';
import AddGoalUpdate from 'src/pagesDashboard/GoalSettings/mobile/AddGoalUpdate';
import DOMPurify from 'dompurify';

const MobileViewGoal = ({ goal }) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const [showAddUpdate, setShowAddUpdate] = useState(false);

  const close = () => {
    const params = new URLSearchParams(search);
    const redirectUrl = params.get('redir');
    if (redirectUrl === '/dashboard/goals') {
      navigate(`${redirectUrl}?keepFilters=true`);
    } else if (redirectUrl) {
      navigate(redirectUrl);
    } else {
      window.history.back();
    }
  };

  return (
    <Base classes={STYLE.MOBILE_CONTAINER}>
      {showAddUpdate ? (
        <AddGoalUpdate goal={goal} setShowAddUpdate={setShowAddUpdate} />
      ) : (
        <div>
          <div className='w-full fixed top-0 mt-12 bg-white z-50'>
            <div className='w-full fixed top-0 mt-12 bg-white z-50'>
              <NavigationItem goBack={close} title={goal.title}>
                <FlagSVG />
              </NavigationItem>
            </div>
            <div className='px-4 w-full mb-4 mt-[132px]'>
              <Button
                variant='black'
                classes='w-full'
                onClick={() => setShowAddUpdate(true)}
              >
                Add Update
              </Button>
            </div>
          </div>
          <div className='mt-[234px]'>
            <div className='w-full text-left px-6 mobile-border mb-5'>
              <p className='mb-1'>Description:</p>
              <div
                className='mb-2 mobile-paragraph'
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(goal.description)
                }}
              />
            </div>
            {goal.updates.length
              ? goal.updates.map((update) => (
                <div
                  className='text-left px-6 mobile-border mb-5'
                  key={update._id}
                >
                  <div className='flex gap-4 mb-2'>
                    <CircleV2
                      imageUrl={update.authorAccount.imageUrl}
                      size='sm'
                    />
                    <div className='flex flex-col w-full justify-center align-middle'>
                      <p className='bold mb-0'>{update.authorAccount.name}</p>
                      <p className='mb-0 leading-4 text-sm'>
                        {commonDateUtils
                          .unixToTimeAgo(update.timestamp)
                          .toString()}
                      </p>
                    </div>
                  </div>
                  <p className='mb-2'>{update.text}</p>
                </div>
              ))
              : (
                <div className='flex justify-center pt-10 text-center h-full'>
                  <p className='mx-3'>No goal updates set yet.</p>
                </div>
              )}
          </div>
        </div>
      )}
    </Base>
  );
};

export default MobileViewGoal;
