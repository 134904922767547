import React, { useContext, useEffect } from 'react';
import Profile from 'src/containers/NewUser/Profile/Profile';
import { useAccount } from 'src/queries/account';
import CrossSVG from 'src/assets/cross.svg';
import { NewUserProvider } from 'src/containers/NewUser/context/NewUserContext';
import useKeyPress from 'src/hooks/useKeyPress/useKeyPress';
import { useNavigate, useLocation } from 'react-router-dom';
import { get } from 'lodash';
import { Base } from '../../components';
import { NewUserContext } from './context/NewUserContext';
import newUserUtils from './utils';

const TABS = {
  PROFILE: 'Profile',
  ACCESS: 'Access'
};

const NewUserSection = ({ actionData, close, refetchOrganization }) => {
  const navigate = useNavigate();
  const {
    data: managerData,
    isFetching: isFetchingManager,
    isError: isErrorManager
  } = useAccount(actionData.managerId);

  const {
    state: { activeTab, isDataSet },
    updateContext
  } = useContext(NewUserContext);

  const manager = {
    ...managerData,
    id: managerData._id
  };

  const isFetching = isFetchingManager;
  const isError = isErrorManager;
  const isReady = managerData && !isFetching && !isError;

  useEffect(() => {
    if (isReady && !isDataSet) {
      const initialState = newUserUtils.getInitialNewState(manager);
      return updateContext({
        ...initialState,
        sendInviteImmediately: true,
        closeAction: close,
        refetchOrgAction: refetchOrganization,
        activeTab: TABS.PROFILE,
        isDataSet: true
      });
    }
  }, [isReady]);

  const location = useLocation();

  const handleRoute = (location) => {
    const pageRoute = get(location, 'pathname');
    // NOTE! This is added so the default route gets set to the Profile page
    // this should be removed upon using routes in the Organization page
    if (pageRoute === '/dashboard/organization/chart') {
      navigate('/dashboard/organization/chart/user/profile');
    }
  };
  useEffect(() => {
    handleRoute(location);
  }, [location]);

  const escapePressed = useKeyPress('Escape');
  if (escapePressed) {
    close();
    navigate('/dashboard/organization/chart');
  }

  return (
    <Base loading={!isReady || !isDataSet}>
      <div className='float-right'>
        <button
          onClick={() => {
            navigate('/dashboard/organization/chart');
            close();
          }}
          className='tooltip'
        >
          <span className='rounded-sm -ml-20 bg-black text-white -mt-8 tooltip-text'>
            Press
            <span className='italic'> Escape </span>
            to close
          </span>
          <CrossSVG className='w-8 h-8 cursor-pointer' />
        </button>
      </div>
      <h2 className='text-center mb-0 ml-8'>Add New User</h2>
      <Profile />
    </Base>
  );
};

const NewUser = (props) => (
  <NewUserProvider>
    <NewUserSection {...props} />
  </NewUserProvider>
);

export default NewUser;
