import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import NavigationItem from 'src/pagesDashboard/HomePage/components/mobile/NavigationItem';
import { TeamContext } from 'src/pagesDashboard/Team/context/TeamProvider';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import { Base, appUtils } from 'src/components';
import STYLE from 'src/constants/style';
import { useAccounts } from 'src/queries/account';
import { UserGroupIcon } from '@heroicons/react/24/outline';
import COMMON_CONSTANTS from 'common/commonConstants';
import CONSTANTS from 'src/pagesDashboard/Team/context/constants';
import MobileTeamMember from 'src/pagesDashboard/Team/mobile/TeamMember';

const { VIEWS } = CONSTANTS;

const { USER_STATE } = COMMON_CONSTANTS;

const MobileTeam = () => {
  const navigate = useNavigate();

  const { state: context } = useContext(TeamContext);

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const {
    data: { tree, teamNodes },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const filters = {
    status: [USER_STATE.ACTIVE, USER_STATE.INACTIVE, USER_STATE.PASSIVE]
  };

  const options = {
    page: {
      number: 1,
      size: Number.MAX_SAFE_INTEGER
    }
  };

  if (context.view === VIEWS.MY_TEAM) {
    const teamIds = teamNodes.map((node) => node.id);
    filters.ids = teamIds;
  } else if (context.view === VIEWS.UNASSIGNED) {
    filters.status = [USER_STATE.UNASSIGNED];
  }

  const {
    data: accounts,
    isFetching: isFetchingAccounts,
    isError: isErrorAccounts
  } = useAccounts(filters, options);

  const isFetching = isFetchingCompany
    || isFetchingTree
    || isFetchingAccounts;
  const isError = isErrorCompany || isErrorTree || isErrorAccounts;
  const isReady = company
    && company.id
    && tree
    && tree.id
    && accounts
    && !isFetching
    && !isError;

  if (!isReady) {
    return null;
  }

  const listHeight = () => {
    if (window.innerHeight <= 392) {
      return 'h-60vh';
    }
    if (window.innerHeight <= 470) {
      return 'h-65vh';
    }

    if (window.innerHeight <= 550) {
      return 'h-70vh';
    }

    if (window.innerHeight <= 720) {
      return 'h-75vh';
    }

    return 'h-80vh';
  };

  return (
    <Base classes={STYLE.MOBILE_CONTAINER}>
      <div className='w-full fixed top-0 mt-12 bg-white z-50'>
        <NavigationItem
          goBack={() => navigate('/dashboard/home')}
          title='People'
        >
          <UserGroupIcon className='w-6 h-6' />
        </NavigationItem>
      </div>
      <div className='mt-16'>
        {accounts.map((account, index) => (
          <div key={account._id}>
            <MobileTeamMember account={account} />
            {
              accounts.length === index + 1 && window.innerHeight >= 665 && window.innerHeight <= 783 ? (
                <div className='h-10' />
              ) : null
            }
            {
              accounts.length === index + 1 && window.innerHeight < 665 ? (
                <div className='h-20' />
              ) : null
            }
          </div>
        ))}
      </div>
    </Base>
  );
};

export default MobileTeam;
