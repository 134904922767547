import React from 'react';

const FilterByLetter = ({ currentLetter, changeLetter }) => {
  const options = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  options.unshift('ALL');

  return (
    <div className='flex justify-evenly items-center mb-5 p-4 bg-white rounded shadow mt-2'>
      <span className='text-base font-bold mr-2 lg:hidden xl:inline'>
        Filter by name:
      </span>

      {options.map((option) => {
        const style = currentLetter === option
          ? 'bg-purple text-white'
          : 'hover:bg-purple hover:text-white';

        return (
          <button
            className={`${style} mt-1 mx-0.5 py-1 px-1.5 font-bold text-sm rounded focus:outline-none transition-colors duration-200 xl:text-base`}
            key={option}
            onClick={() => changeLetter(option)}
          >
            {option}
          </button>
        );
      })}
    </div>
  );
};

export default FilterByLetter;
