import React from 'react';
import { Controller } from 'react-hook-form';
import { SimpleDatePicker } from 'src/components';

const Deadline = ({ control }) => (
  <div className='flex justify-between h-20'>
    <p className='mb-0 font-bold text-base text-gray-500'>DEADLINE</p>
    <div className='pl-8 pr-6 h-16 w-4/6'>
      <Controller
        name='deadline'
        control={control}
        rules={{ required: true }}
        render={({ field }) => {
          const curr = field.value ? new Date(field.value) : null;
          return (
            <SimpleDatePicker
              classes='float-none'
              inputClasses='w-full'
              placeholder='Set date'
              date={curr}
              onSelect={field.onChange}
              {...field}
            />
          );
        }}
      />
    </div>
  </div>
);

export default Deadline;
