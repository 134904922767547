import React from 'react';
import { useAccount } from 'src/queries/account';
import { get } from 'lodash';
import UserNotConnected from './UserNotConnected';
import UserConnected from './UserConnected';

const WebexIntegration = () => {
  const {
    data: account,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount('me');

  const isFetching = isFetchingAccount;
  const isError = isErrorAccount;
  const isReady = account && account._id && !isFetching && !isError;

  if (!isReady) {
    return true;
  }

  const isWebexEmailSet = Boolean(get(account, 'webexEmail'));
  return isWebexEmailSet ? <UserConnected /> : <UserNotConnected />;
};

export default WebexIntegration;
