import React from 'react';
import DOMPurify from 'dompurify';
import { Circle } from 'src/components';
import commonDateUtils from 'common/commonDateUtils';
import commonTreeUtils from 'common/commonTreeUtils';
import { useTree } from 'src/queries/tree';
import ThumbsUpSvg from 'src/assets/svg/thumbs-up.svg';
import Edit from 'src/assets/edit-3.svg';
import ChevronDownSVG from 'src/assets/svg/chevronDown.svg';

const FeedbackCard = ({
  id,
  reviewer,
  reviewee,
  date,
  text,
  likes,
  liked,
  onLike,
  type,
  isAdmin,
  editFn
}) => {
  const {
    data: { tree, deleted },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const isFetching = isFetchingTree;
  const isError = isErrorTree;
  const isReady = tree && tree.id && deleted && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  let reviewerNode = commonTreeUtils.findNodeById(tree, reviewer);
  let revieweeNode = commonTreeUtils.findNodeById(tree, reviewee);

  if (!reviewerNode) {
    reviewerNode = deleted.find((d) => d.id === reviewer);
  }

  if (!revieweeNode) {
    revieweeNode = deleted.find((d) => d.id === reviewee);
  }

  const sanitizedText = () => ({
    __html: DOMPurify.sanitize(text)
  });

  return (
    <div
      className='flex flex-col w-full bg-white px-4 pt-3 mb-3 pb-4 shadow rounded'
      key={`inner-card-${type}-${id}`}
    >
      <div className='flex items-center'>
        <div className='min-w-3rem'>
          <Circle imageUrl={reviewerNode?.imageUrl} size='sm' />
        </div>
        <div className='flex justify-between ml-2 w-full mb-3'>
          <div className='flex flex-col'>
            <div className='flex items-center font-bold text-lg'>
              {reviewerNode?.name}
              <span className='transform w-3 h-3 mx-2 rotate-90 stroke-2 inline-block'>
                <ChevronDownSVG />
              </span>
              {revieweeNode?.name}
            </div>
            <span className='text-gray-500 font-medium'>
              {/* December 9 at 11:43 AM */}
              {commonDateUtils.unixToMonthDayYearTimeFormat(date)}
            </span>
          </div>
          {/* {isAdmin && (
            <Edit className='cursor-pointer text-gray-400' onClick={editFn} />
          )} */}
        </div>
      </div>

      <div
        className='ql-editor p-0 mb-5'
        dangerouslySetInnerHTML={sanitizedText()}
      />

      <div className='flex justify-between'>
        <button
          className='flex font-bold text-gray-400 mb-0 focus:outline-none'
          type='button'
          onClick={() => onLike(id, type, 'like')}
        >
          <ThumbsUpSvg className={`mr-1 ${liked && 'text-purple'}`} />
          <span className='font-bold text-black'>{likes || '0'}</span>
        </button>
        {/* <p className='font-bold text-gray-400 mb-0'>{type}</p> */}
      </div>
    </div>
  );
};

export default FeedbackCard;
