import React from 'react';
import XCircleSVG from 'src/assets/svg/x-circle.svg';

const XButtonV2 = ({ onClick, classes }) => (
  <button
    onClick={onClick}
    className={`text-purple hover:text-hover-purple focus:outline-none ${classes || 'w-5 h-5'}`}
  >
    <XCircleSVG />
  </button>
);

export default XButtonV2;
