import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import NavigationItem from 'src/pagesDashboard/HomePage/components/mobile/NavigationItem';
import SpeechBubbleSVG from 'src/assets/svg/speech-bubble.svg';
import { appUtils, Base } from 'src/components/';
import STYLE from 'src/constants/style';
import UserFeedback from 'src/containers/UserProfile/FeedbackDashboard/components/mobile/Feedback';
import COMMON_CONSTANTS from 'common/commonConstants';

const mobileSize = appUtils.getMobileSize();

const { MOBILE_SIZES } = COMMON_CONSTANTS;

const MobileFeedbackDashboard = ({
  feedbacks,
  isLoading,
  reviewee,
  showToggle,
  viewerId,
  setViewerId,
  renderPagination
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <Base classes={STYLE.MOBILE_CONTAINER}>
      <div className='w-full fixed top-0 mt-12 bg-white z-50'>
        <NavigationItem
          goBack={() => navigate('/dashboard/home')}
          title='My Feedback'
        >
          <SpeechBubbleSVG />
        </NavigationItem>
      </div>
      <div className='mt-[148px]'>
        {feedbacks && feedbacks.length > 0 ? (
          feedbacks.map((feedback, index) => (
            <div key={`feedback_${feedback.id}`} className='mt-5'>
              <UserFeedback feedback={feedback} redirectUrl={pathname} />
              {feedbacks.length === index + 1
              && window.innerHeight >= 665
              && window.innerHeight <= 783 ? (
                <div className='h-10' />
                ) : null}
              {feedbacks.length === index + 1 && window.innerHeight < 665 ? (
                <div className='h-20' />
              ) : null}
            </div>
          ))
        ) : (
          <div className='flex justify-center pt-10 text-center h-full'>
            {!isLoading ? (
              <p className='mx-3'>You haven't received any feedback yet.</p>
            ) : null}
          </div>
        )}
      </div>
    </Base>
  );
};

export default MobileFeedbackDashboard;
