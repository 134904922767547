import React from 'react';
import { DateRange } from 'src/components';
import commonDateUtils from 'common/commonDateUtils';
import COMMON_CONSTANTS from 'common/commonConstants';

const { DATE_RANGE_FILTERS } = COMMON_CONSTANTS;

export const getDefaultRange = () => ({
  value: DATE_RANGE_FILTERS.THREE_MONTHS.key,
  start: commonDateUtils.getDateFromDaysAgo(
    DATE_RANGE_FILTERS.THREE_MONTHS.days
  ).unix,
  end: undefined
});

const commonClass = 'focus:outline-none px-2 font-bold sm:text-lg md:text-md lg:text-lg xl:text-lg';

const today = new Date();
const parsedToday = Date.parse(today);
const customDate = null;

const Range = ({
  value,
  onClick,
  start = null,
  end = null,
  options = { showCustom: false, enabled: true },
  maxDate
}) => {
  const { enabled } = options;
  // console.log('range inputs are', { start, end })
  return (
    <div>
      {options.showCustom ? (
        <button
          className={`${
            value === 'custom'
              ? 'rounded-lg py-1 text-black'
              : 'text-light-gray hover:text-black transition-colors duration-300'
          } h-10 md:mr-1 lg:mr-1 ${commonClass}`}
        >
          <div
            className={`${
              value === 'custom' ? 'bg-yellow' : ''
            } h-8 p-1 pr-2 rounded`}
          >
            <DateRange
              classes='inline-block'
              innerClasses='float-right'
              // minDate={today}
              maxDate={maxDate}
              startDate={start || null}
              endDate={end || null}
              clearDateFn={() => {
                console.log('clear');
              }}
              onSelect={(startArg, endArg) => {
                if (!enabled) {
                  return;
                }
                // console.log('ARGS', { startArg, endArg });
                const startUnix = startArg
                  ? commonDateUtils.dateToUnix(startArg)
                  : null;
                const endUnix = endArg
                  ? commonDateUtils.dateToUnix(endArg)
                  : null;
                // console.log('set to..', { startUnix, endUnix });
                onClick('custom', startUnix, endUnix);
              }}
            />
          </div>
        </button>
      ) : null}
      <button
        className={`${
          value === DATE_RANGE_FILTERS.ALL_TIME.key
            ? 'bg-yellow rounded-lg py-1 text-black'
            : 'text-light-gray hover:text-black transition-colors duration-300'
        } md:mr-1 lg:mr-1 ${commonClass}`}
        onClick={() => {
          if (!enabled) {
            return;
          }
          onClick(DATE_RANGE_FILTERS.ALL_TIME.key);
        }}
      >
        All Time
      </button>
      <button
        className={`${
          value === DATE_RANGE_FILTERS.ONE_YEAR.key
            ? 'bg-yellow rounded-lg py-1 text-black'
            : 'text-light-gray hover:text-black transition-colors duration-300'
        } md:mr-1 lg:mr-1 ${commonClass}`}
        onClick={() => {
          if (!enabled) {
            return;
          }
          onClick(
            DATE_RANGE_FILTERS.ONE_YEAR.key,
            commonDateUtils.getDateFromDaysAgo(DATE_RANGE_FILTERS.ONE_YEAR.days)
              .unix,
            commonDateUtils.getUnixDateNow()
          );
        }}
      >
        1 Year
      </button>
      <button
        className={`${
          value === DATE_RANGE_FILTERS.SIX_MONTHS.key
            ? 'bg-yellow rounded-lg py-1 text-black'
            : 'text-light-gray hover:text-black transition-colors duration-300'
        } md:mr-1 lg:mr-1 ${commonClass}`}
        onClick={() => {
          if (!enabled) {
            return;
          }
          onClick(
            DATE_RANGE_FILTERS.SIX_MONTHS.key,
            commonDateUtils.getDateFromDaysAgo(
              DATE_RANGE_FILTERS.SIX_MONTHS.days
            ).unix,
            commonDateUtils.getUnixDateNow()
          );
        }}
      >
        6 Months
      </button>
      <button
        className={`${
          value === DATE_RANGE_FILTERS.THREE_MONTHS.key
            ? 'bg-yellow rounded-lg py-1 text-black'
            : 'text-light-gray hover:text-black transition-colors duration-300'
        } md:mr-1 lg:mr-1 ${commonClass}`}
        onClick={() => {
          if (!enabled) {
            return;
          }
          onClick(
            DATE_RANGE_FILTERS.THREE_MONTHS.key,
            commonDateUtils.getDateFromDaysAgo(
              DATE_RANGE_FILTERS.THREE_MONTHS.days
            ).unix,
            commonDateUtils.getUnixDateNow()
          );
        }}
      >
        3 Months
      </button>
      <button
        className={`${
          value === DATE_RANGE_FILTERS.LAST_MONTH.key
            ? 'bg-yellow rounded-lg py-1 text-black'
            : 'text-light-gray hover:text-black transition-colors duration-300'
        } ${commonClass}`}
        onClick={() => {
          if (!enabled) {
            return;
          }
          onClick(
            DATE_RANGE_FILTERS.LAST_MONTH.key,
            commonDateUtils.getDateFromDaysAgo(
              DATE_RANGE_FILTERS.LAST_MONTH.days
            ).unix,
            commonDateUtils.getUnixDateNow()
          );
        }}
      >
        Last Month
      </button>
    </div>
  );
};

export default Range;
