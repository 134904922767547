import React, { Component, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { useQueryClient } from 'react-query';
import appUtils from '../../components/appUtils';
import BILLING_CONSTANTS from '../../containers/Billing/constants';
import sharedTreeUtils from '../../common/sharedTreeUtils';
import sharedDateUtils from '../../common/sharedDateUtils';
import SHARED_CONSTANTS from '../../common/sharedConstants';
import COMMON_CONSTANTS from 'common/commonConstants';
import { billingThunks, appThunks } from '../../thunks';
import { billingActions } from '../../reducers/actions';
import { SectionBox, Button, Input, toast } from '../../components';
import PricingPlans from './components/PricingPlans';
import BillingHeader from './components/BillingHeader';
import GrowthPlan from './components/GrowthPlan';
import TrialPlan from './components/TrialPlan';
import InactiveCompany from './components/InactiveCompany';

const Checkout = lazy(() => import('./components/Checkout'));

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      borderColor: 'red',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

let queryClient = null;

const { BILLING_PLANS, HELLO_WORKSTORY_EMAIL } = COMMON_CONSTANTS;
const { VIEWS: BILLING_VIEWS } = BILLING_CONSTANTS;

class Billing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      billingInformationLoading: false,
      cardLoading: false,
      startSubscriptionLoading: false,
      numberOfUsers: '-'
    };
    const {
      dispatch,
      billing: { view }
    } = this.props;
    if (view !== BILLING_VIEWS.INITIAL) {
      this.setView(BILLING_VIEWS.INITIAL);
    }
    billingThunks
      .getAccount()(dispatch)
      .then((resp) => {
        const { tree } = resp;
        const treeList = sharedTreeUtils.convertTreeToList(tree);
        this.setState({
          numberOfUsers: treeList.length
        });
      });
  }

  handleSubmit = async (event, elements, stripe) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    toast.show('Verifying card');
    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement)
    });
    const { paymentMethod } = payload;
    if (!payload || !paymentMethod || !paymentMethod.id) {
      return this.setState({ cardLoading: false });
    }
    const { id: paymentMethodId } = paymentMethod;
    const { dispatch } = this.props;
    this.setState({ cardLoading: true });
    billingThunks
      .savePaymentMethodId(paymentMethodId)(dispatch)
      .then((resp) => {
        this.setState({ cardLoading: false });
      })
      .catch((err) => {
        this.setState({ cardLoading: false });
      });
  };

  setView = (view) => {
    const { dispatch } = this.props;
    appUtils.scrollToTop();
    dispatch(billingActions.setBillingView(view));
  };

  selectPlan = (plan) => {
    const { dispatch } = this.props;
    dispatch(billingActions.selectBillingPlan(plan));
  };

  updateBillingInformation = (event, fieldName) => {
    const { dispatch } = this.props;
    const value = event.target.value;
    dispatch(billingActions.updateBillingInformation(fieldName, value));
  };

  saveBillingInformation = () => {
    this.setState({
      billingInformationLoading: true
    });
    const {
      dispatch,
      billing: { billingInformation }
    } = this.props;

    billingThunks
      .saveBillingInformation(billingInformation)(dispatch)
      .then((resp) => {
        this.setState({ billingInformationLoading: false });
      })
      .catch((err) => {
        this.setState({ billingInformationLoading: false });
      });
  };

  startSubscription = () => {
    this.setState({ startSubscriptionLoading: true });
    const {
      dispatch,
      billing: { selectedPlan }
    } = this.props;
    const { numberOfUsers } = this.state;
    const data = {
      numberOfUsers,
      total: numberOfUsers * 15,
      plan: selectedPlan
    };
    billingThunks
      .startSubscription(data)(dispatch)
      .then(() => {
        this.setState({ startSubscriptionLoading: false });
      })
      .catch(() => {
        this.setState({ startSubscriptionLoading: false });
      });
  };

  renderPaySection = () => {
    const { startSubscriptionLoading, numberOfUsers } = this.state;
    const {
      app: { tree }
    } = this.props;
    const price = 15;
    return (
      <SectionBox sectionType='dash' loading={startSubscriptionLoading}>
        <h5>Checkout Summary</h5>
        <p>There are {numberOfUsers} users in your organization</p>

        <p>Select payment frequency:</p>
        {/*<a onClick={this.setBillingFrequency} className={`${planFrequency === BILLING_FREQUENCY.MONTHLY ? 'red' : 'black'}`}>
          Monthly
        </a>
        <a onClick={this.setBillingFrequency} className={`${planFrequency === BILLING_FREQUENCY.YEARLY ? 'red' : 'black'}`}>
          Yearly
        </a>*/}
        <p>
          Your monthly invoice is {numberOfUsers} * ${price} ={' '}
          {numberOfUsers * price}
        </p>
        <Button
          variant='red'
          classes='marginRight20'
          onClick={this.startSubscription}
        >
          Start Subscription
        </Button>
        <Button
          variant='gray'
          onClick={() => {
            this.setView(BILLING_VIEWS.INITIAL);
          }}
        >
          Back to Billing
        </Button>
      </SectionBox>
    );
  };

  renderCheckout = () => {
    const {
      app: { tree },
      billing: {
        billingInformation: { name, address1, address2 },
        customerid,
        defaultPaymentMethodId
      },
      billing: { defaultPaymentMethodData, subscriptionData, selectedPlan },
      dispatch
    } = this.props;
    const { billingInformationLoading, numberOfUsers } = this.state;

    return (
      <Elements stripe={stripePromise}>
        <h5 className='marginLeft5'>Step 1</h5>
        <Button
          variant='transparent'
          classes='positionTopRight'
          onClick={() => {
            this.setView(BILLING_VIEWS.INITIAL);
          }}
        >
          Back to Billing
        </Button>
        <BillingInformation
          dispatch={dispatch}
          name={name}
          address1={address1}
          address2={address2}
        />
        <h5 className='marginLeft5'>Step 2</h5>
        <PaymentInformation
          dispatch={dispatch}
          defaultPaymentMethodDataObj={defaultPaymentMethodData}
        />
        <h5 className='marginLeft5'>Step 3</h5>
        <PaySubscription
          numberOfUsers={numberOfUsers}
          price={15}
          selectedPlan={selectedPlan}
          backFn={() => {
            this.setView(BILLING_VIEWS.INITIAL);
          }}
          customerid={customerid}
          defaultPaymentMethodId={defaultPaymentMethodId}
          subscriptionData={subscriptionData}
          dispatch={dispatch}
          refetchBilling={() => {
            billingThunks.getAccount()(dispatch);
            appThunks.getDashboard({ lastUpdated: null })(dispatch);
          }}
        />
      </Elements>
    );
  };

  selectPlanFn = (selectedPlan) => {
    if (selectedPlan === BILLING_PLANS.FREE) {
      return toast.show('You are on the free plan already :)');
    }
    if (selectedPlan === BILLING_PLANS.GROWTH) {
      const { dispatch } = this.props;
      dispatch(billingActions.selectBillingPlan(BILLING_PLANS.GROWTH));
      return this.setView(BILLING_VIEWS.CHECKOUT);
    }
  };

  selectGrowthPlan = () => {
    const { dispatch } = this.props;
    dispatch(billingActions.selectBillingPlan(BILLING_PLANS.GROWTH));
    return this.setView(BILLING_VIEWS.CHECKOUT);
  };

  cancelSubscription = () => {
    const {
      billing: {
        subscriptionData: { id }
      },
      dispatch
    } = this.props;
    this.setState({ accountLoading: true });
    billingThunks
      .cancelSubscription(id)(dispatch)
      .then(() => {
        toast.show('Subscription cancelled');
        this.setState({ accountLoading: false });
        billingThunks.getAccount()(this.props.dispatch);
        appThunks.getDashboard({ lastUpdated: null })(this.props.dispatch);
      })
      .catch(() => {
        toast.error(
          `We ran into an issue. Please contact us at ${HELLO_WORKSTORY_EMAIL}`
        );
        this.setState({ accountLoading: false });
      });
  };

  render() {
    const {
      app: { userLogged },
      billing: { view },
      billing,
      company: { freeTrialDays, startDate: companyUnixStartDate, daysLeft },
      dispatch
    } = this.props;
    const { numberOfUsers, accountLoading } = this.state;

    if (view === BILLING_VIEWS.CHECKOUT) {
      return (
        <Suspense fallback={<div>loading...</div>}>
          <Checkout
            refetchBilling={() => {
              billingThunks.getAccount()(dispatch);
              appThunks.getDashboard({ lastUpdated: null })(dispatch);
            }}
            setView={this.setView}
          />
        </Suspense>
      );
    }

    const {
      billing: {
        billingInformation: { name, address1, address2 },
        defaultPaymentMethodData,
        subscriptionData,
        activeSubscription
      },
      company
    } = this.props;

    const activePlan = activeSubscription && activeSubscription.plan;

    let freeTrialDaysText = '';
    if (activePlan === BILLING_PLANS.FREE) {
      if (daysLeft === 0) {
        freeTrialDaysText = 'Your free trial has expired.';
      }
      if (daysLeft > 0) {
        freeTrialDaysText = `It is expiring in ${daysLeft} days.`;
      }
    }

    const isSuperUser =
      userLogged && userLogged.isSuperUser ? userLogged.isSuperUser : false;
    return (
      <>
        {view === BILLING_VIEWS.SUCCESS ? (
          <SectionBox sectionType='dash'>
            <h5>Success</h5>
            <p>Thank you for signing up. Your subscription has started.</p>
            <Button
              variant='gray'
              onClick={() => {
                this.setView(BILLING_VIEWS.INITIAL);
              }}
            >
              Close
            </Button>
          </SectionBox>
        ) : null}
        <BillingHeader />
        {subscriptionData && isSuperUser ? (
          <SectionBox
            sectionType='dash'
            innerClasses='paddingBottom0'
            loading={accountLoading}
          >
            <a
              className='red block marginBottom0 marginTop10'
              onClick={this.cancelSubscription}
            >
              Cancel active subscription (superuser action)
            </a>
          </SectionBox>
        ) : null}

        {company.active ? (
          activePlan ? (
            <GrowthPlan />
          ) : (
            <TrialPlan selectPlan={this.selectGrowthPlan} />
          )
        ) : (
          <InactiveCompany selectPlan={this.selectGrowthPlan} />
        )}

        {/*
          {
            !subscriptionData ? (
              <PricingPlans
                selectPlanFn={this.selectPlanFn}
              />
            ) : null
          }
        */}
      </>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    billing: state.billingReducer,
    app: state.appReducer,
    company: state.companyReducer
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
