import React from 'react';
import { Controller } from 'react-hook-form';
import { appUtils } from 'src/components/index';
import Quill from 'src/components/Quill/Quill';

const formatText = (text) => {
  const keyword = 'style="';

  while (text.includes(keyword)) {
    const styleInit = text.indexOf(keyword);
    const styleEnd = text.indexOf('"', styleInit + keyword.length) + 1;
    const substring = text.substring(styleInit, styleEnd);
    text = text.replace(substring, '');
  }

  return text;
};

const Description = ({ control, setValue }) => (
  <div className='flex justify-between h-48'>
    <p className='mb-0 font-bold text-base text-gray-500'>DESCRIPTION</p>
    <div className='pl-8 pr-6 w-4/6 h-full'>
      <Controller
        name='description'
        control={control}
        rules={{ required: 'Please write a goal description' }}
        render={({ field }) => (
          <Quill
            value={field.value}
            className='flex flex-col h-full pb-10'
            onChange={(value) => {
              field.onChange(value);
              setValue('description', formatText(value));
            }}
          />
        )}
      />
    </div>
  </div>
);

export default Description;
