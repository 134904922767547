import React from 'react';
import { useQueryClient } from 'react-query';
import appUtils from 'src/components/appUtils';
import commonGoalUtils from 'common/commonGoalUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useGoalUpdate, QUERY_KEYS as GOAL_QUERY_KEYS } from 'src/queries/goal';
import { useForm, Controller } from 'react-hook-form';
import { Select } from 'src/components/';
import { Button, toast } from 'src/components';
import { get } from 'lodash';
import commonDateUtils from 'common/commonDateUtils';
import { getGoalStatuses } from 'src/containers/UserProfile/Goals/constants';

const { GOALS: GOAL_CONSTANTS } = COMMON_CONSTANTS;

const displayFormErrors = (errors) => {
  Object.keys(errors).forEach((key) => {
    const val = errors[key];
    const message = get(val, 'message');
    if (!message) {
      return;
    }
    toast.error(message);
  });
};

const AddGoalUpdate = ({ goal }) => {
  const { mutateAsync: updateGoal, isLoading: isUpdating } = useGoalUpdate(
    goal._id
  );
  const GOAL_STATUSES = getGoalStatuses();

  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      text: '',
      status: GOAL_STATUSES.find((status) => status.value === goal.status),
      progress: commonGoalUtils.getGoalProgressOption(goal)
    }
  });
  const formValues = watch();
  const queryClient = useQueryClient();

  const saveUpdate = async (values) => {
    try {
      const loggedUserId = appUtils.getLoggedUserId();
      const payload = {
        text: values.text,
        status: values.status.value,
        progress: get(values, 'progress.value') || get(values, 'progress') || 0,
        author: loggedUserId,
        timestamp: commonDateUtils.getUnixDateNow()
      };

      const response = await updateGoal({ updates: [payload] });
      if (!response || !response.success) {
        toast.error('Try again!');
      } else if (response.warning) {
        const message = get(
          response,
          'message',
          'Failed to add update. Try again later!'
        );
        toast.closeAll();
        return toast.show(message);
      } else {
        toast.show('Update added!');
        reset();
        queryClient.invalidateQueries(GOAL_QUERY_KEYS.GOALS);
        queryClient.invalidateQueries(GOAL_QUERY_KEYS.GOAL_STATISTICS);
      }
    } catch (error) {
      return toast.error(error ?? 'Try again!');
    }
  };

  const error = () => displayFormErrors(errors);

  return (
    <form className='mb-0 pb-3' onSubmit={handleSubmit(saveUpdate, error)}>
      <p className='font-bold mb-3 text-xl w-full'>Add update</p>

      <p className='mb-1'>Comments (required):</p>
      <Controller
        control={control}
        name='text'
        rules={{
          required: true,
          minLength: {
            value: 5,
            message: 'Comments must be at least 5 characters long'
          }
        }}
        render={({ field }) => (
          <textarea
            className='block w-full resize-none h-16'
            placeholder='Note your updates here'
            {...field}
          />
        )}
      />

      <div className='flex'>
        <div className='w-1/2 pr-1'>
          <p className='mb-1 mt-3'>Status:</p>
          <Controller
            control={control}
            name='status'
            rules={{ required: true }}
            render={({ field }) => {
              const title = field.value.label;
              return (
                <Select
                  placeholder='Select status'
                  classes='w-full'
                  options={GOAL_STATUSES}
                  title={title}
                  drop='up'
                  {...field}
                />
              );
            }}
          />
        </div>
        <div className='w-1/2 pl-1'>
          <p className='mb-1 mt-3'>Progress:</p>
          <Controller
            control={control}
            name='progress'
            rules={{
              required: true,
              ...(goal.type === GOAL_CONSTANTS.TYPES.PERCENT && {
                min: 0,
                max: 100
              })
            }}
            render={({ field }) => {
              if (goal.type === GOAL_CONSTANTS.TYPES.BINARY) {
                const title = field.value.label;
                const options = [
                  { value: '0', label: 'Not done' },
                  { value: '1', label: 'Done' }
                ];
                return (
                  <Select
                    placeholder='Any progress?'
                    classes='w-full'
                    options={options}
                    title={title}
                    {...field}
                  />
                );
              }

              const value = Number(field.value) ?? Number(formValues.progress.value);
              if (goal.type === GOAL_CONSTANTS.TYPES.DOLLAR) {
                return (
                  <div className='flex items-center'>
                    <span className='align-middle'>
                      {commonGoalUtils.getGoalType(goal.type)}
                    </span>
                    <input
                      className='w-full ml-1.5'
                      placeholder='Any progress?'
                      type='number'
                      {...field}
                      value={value}
                    />
                  </div>
                );
              }
              if (goal.type === GOAL_CONSTANTS.TYPES.NUMBER) {
                return (
                  <div className='flex items-center'>
                    <span className='align-middle'>
                      {commonGoalUtils.getGoalType(goal.type)}
                    </span>
                    <input
                      className='w-full ml-1.5'
                      placeholder='Any progress?'
                      type='number'
                      {...field}
                      value={value}
                    />
                  </div>
                );
              }
              if (goal.type === GOAL_CONSTANTS.TYPES.PERCENT) {
                return (
                  <div className='flex items-center'>
                    <span className='align-middle'>
                      {commonGoalUtils.getGoalType(goal.type)}
                    </span>
                    <input
                      className='w-full ml-1.5'
                      placeholder='Any progress?'
                      type='number'
                      min='0'
                      max='100'
                      {...field}
                      value={value}
                    />
                  </div>
                );
              }
            }}
          />
        </div>
      </div>
      <div className='mt-2'>
        <div className='w-full mt-3 text-right inline-block'>
          <Button
            disabled={!isValid || isUpdating}
            variant='yellow'
            type='submit'
          >
            Save update
          </Button>
        </div>
      </div>
    </form>
  );
};

export default AddGoalUpdate;
