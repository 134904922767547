import ACTIONS from '../actionTypes';
import appState from '../states/app';
import treeUtils from '../../containers/Tree/utils';
import sharedTreeUtils from '../../common/sharedTreeUtils';
import SHARED_CONSTANTS from '../../common/sharedConstants';

function appReducer(state = appState, action) {
  const newState = {};
  switch (action.type) {
    case 'RESET_APP': {
      appState.userLogged = null;
      return appState;
    }
    case 'SET_SHOW_USER_CARD': {
      return {
        ...state,
        showUserCard: action.showUserCard
      };
    }
    case ACTIONS.SET_LINE_CHART_COMPONENT: {
      return {
        ...state,
        LineChartComponent: action.LineChart
      };
    }
    case ACTIONS.SET_COMPONENT: {
      return {
        ...state,
        [action.key]: action.component
      };
    }
    case 'SHOW_INTRO_MODAL': {
      return {
        ...state,
        showIntroModal: action.show
      };
    }
    case 'SET_TOUR_LOADING': {
      return {
        ...state,
        isTourLoading: action.loading
      };
    }
    case 'SHOW_MOBILE_MENU': {
      return {
        ...state,
        showMobileMenu: action.show
      };
    }
    case 'LOG_USER_IN': {
      return {
        ...state,
        userLogged: action.user
      };
    }
    case 'SET_PROFILE_IMAGE_URL': {
      return {
        ...state,
        userLogged: {
          ...state.userLogged,
          imageUrl: action.imageUrl
        }
      };
    }
    // LOG_USER_OUT might be deprecated
    case 'LOG_USER_OUT': {
      return {
        ...state,
        userLogged: null
      };
    }
    case 'LOGGED_USER_STATUS_CHECKED': {
      return {
        ...state,
        isCheckingLoggedUserStatus: action.isCheckingLoggedUserStatus
      };
    }
    case 'SET_COMPANY_NAME': {
      return {
        ...state
      };
    }
    case 'SET_EMAILING': {
      return {
        ...state,
        emailing: action.emailing
      };
    }
    case 'SET_FREQUENCY': {
      return {
        ...state,
        emailFrequency: action.frequency,
        newEmailFrequency: action.frequency
      };
    }
    case 'SET_NEW_FREQUENCY': {
      return {
        ...state,
        newEmailFrequency: action.frequency
      };
    }
    case 'SET_USER_TO_REVIEW': {
      return {
        ...state,
        reviewThisUser: action.user,
        reviewThisUserApiCallInProgress: false
      };
    }
    case 'SET_REVIEW_THIS_USER_IN_PROGRESS': {
      return {
        ...state,
        reviewThisUserApiCallInProgress: action.loading
      };
    }
    // move the reducers below to their own 'treeReducer':
    case ACTIONS.SET_TREE: {
      const treeList = sharedTreeUtils.convertTreeToList(action.tree);
      return {
        ...state,
        tree: action.tree,
        treeList
      };
    }
    case 'ADD_TREE_NODE': {
      const { tree } = state;
      const { node } = action;
      const newTree = treeUtils.addNode(tree, node, true);
      return {
        tree: newTree,
        ...state
      };
    }
    case 'SET_TREE_NODE_LOADING': {
      const { tree } = state;
      const { email, loading } = action;
      const newTree = treeUtils.setLoadingForNode(tree, email, loading);
      return {
        tree: newTree,
        ...state
      };
    }
    default: {
      return state;
    }
  }
}

export default appReducer;
