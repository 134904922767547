import React, { useEffect } from 'react';
import commonQuestionsUtils from 'common/commonQuestionsUtils';
import { Select, XButton } from 'src/components/';
import { useCompany } from 'src/queries/company';
import { useUserProfile } from 'src/pagesDashboard/UserProfile/queries';
import { useMyProfile } from 'src/pagesDashboard/Dash/queries';
import { getCategoryOptions } from 'src/utils/historicFilterUtils';
import LoadingSelect from 'src/containers/UserProfile/FilterView/LoadingSelect';

const FilterByCategories = ({
  id,
  isMyProfile,
  userId,
  category,
  updateFn,
  customSelectClasses,
  setIsLoading = () => {}
}) => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const MyProfile = useMyProfile();
  const UserProfile = useUserProfile(userId);
  const {
    myCategories,
    isFetching: isFetchingMyCategories,
    isError: isErrorMyCategories
  } = isMyProfile ? MyProfile : UserProfile;

  const isFetching = isFetchingCompany || isFetchingMyCategories;
  const isError = isErrorCompany || isErrorMyCategories;
  const isReady = company && company.id && !isFetching && !isError;

  useEffect(() => {
    setIsLoading(isFetching);
  }, [isFetching]);

  if (!isReady) {
    return (
      <LoadingSelect
        variant='shadow'
        title='Filter by category'
        customSelectClasses={customSelectClasses}
      />
    );
  }

  const categoryOptions = getCategoryOptions(myCategories);
  const categorySelected = category;

  const cat = categorySelected
    ? commonQuestionsUtils.findCategory(company.questions, categorySelected)
    : null;

  const title = categoryOptions.length
    ? `Filter by ${cat ? cat.label : 'category'}`
    : 'No categories available';

  const clearFilter = () => updateFn({ category: null });

  const onChange = (option) => updateFn({ category: option.id });

  return (
    <Select
      id={id}
      variant='shadow'
      optionsWidth='wide'
      title={title}
      options={categoryOptions}
      onChange={onChange}
      classes={customSelectClasses ?? 'w-60'}
      showXButton={categorySelected}
      onClickXButton={clearFilter}
      disabled={!categoryOptions.length}
    />
  );
};

export default FilterByCategories;
