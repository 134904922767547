import React from 'react';
import { SectionBox, Button, toast } from '../../../components';
import SHARED_CONSTANTS from '../../../common/sharedConstants';

const { BILLING_PLANS } = SHARED_CONSTANTS;

const PricingPlans = ({ selectPlanFn }) => (
  <div className='row'>
    {/*
    <div className="col-xs-5">
      <h5 className="text-center marginBottom10">
        Personal
      </h5>
      <SectionBox
        sectionType="dash"
        classes="text-center"
      >
        <div className="height280px">
          <p className="marginBottom10">Automatic Reviews</p>
          <p className="marginBottom10">Dynamic Reports</p>
          <p className="marginBottom10">Performance Analysis</p>
          <p className="marginBottom10">Feedback Triggers</p>
          <p className="marginBottom10">Integration for Slack</p>
          <p className="marginBottom10">Email and Phone Support</p>
          <p className="marginTop30 marginBottom10">5 users or less</p>
        </div>

        <Button
          variant="black"
          onClick={() => { selectPlanFn(BILLING_PLANS.FREE); }}
        >
          Continue
        </Button>
      </SectionBox>
    </div>
    */}
    <div className='col-xs-6'>
      <h5 className='red text-center marginBottom10'>Team</h5>
      <SectionBox sectionType='dash' classes='text-center'>
        <div className='height280px'>
          <p className='marginBottom10'>Automatic Reviews</p>
          <p className='marginBottom10'>Dynamic Reports</p>
          <p className='marginBottom10'>Performance Analysis</p>
          <p className='marginBottom10'>Feedback Triggers</p>
          <p className='marginBottom10'>Integration for Slack</p>
          <p className='marginBottom10'>Email and Phone Support</p>
          <p className='red marginTop30 marginBottom10'>
            {'$15 '}
            <span className='fonSize14'>per user per mo</span>
          </p>
        </div>

        <Button
          variant='red'
          onClick={() => {
            selectPlanFn(BILLING_PLANS.STANDARD);
          }}
        >
          Get Started
        </Button>
      </SectionBox>
    </div>
    {/*
    <div className="col-xs-5">
      <h5 className="text-center marginBottom10">
        Corporate
      </h5>
      <SectionBox
        sectionType="dash"
        classes="text-center"
      >
        <div className="height280px">
          <p className="marginBottom10">Automatic Reviews</p>
          <p className="marginBottom10">Dynamic Reports</p>
          <p className="marginBottom10">Performance Analysis</p>
          <p className="marginBottom10">Feedback Triggers</p>
          <p className="marginBottom10">Integration for Slack</p>
          <p className="marginBottom10">Email and Phone Support</p>
          <p className="marginBottom10">Custom Integrations</p>
        </div>

        <Button
          variant="black"
          onClick={() => { selectPlanFn(BILLING_PLANS.PLUS); }}
        >
          Contact Us
        </Button>
      </SectionBox>
    </div>
    */}
  </div>
);

export default PricingPlans;
