import React from 'react';
import InfoCircleAlterSVG from 'src/assets/svg/infoCircle-alter.svg';
import './Highlight.scss';

const Highlight = ({
  variant, icon = null, title, text
}) => {
  let variantContainerClass = '';
  let variantTitleClass = '';
  let variantTextClass = '';
  if (variant === 'yellow') {
    variantContainerClass = 'yellow-container';
    variantTitleClass = 'yellow-title';
    variantTextClass = 'yellow-text';
  }
  if (variant === 'green') {
    variantContainerClass = 'green-container';
    variantTitleClass = 'green-title';
    variantTextClass = 'green-text';
  }
  if (variant === 'blue') {
    variantContainerClass = 'blue-container';
    variantTitleClass = 'blue-title';
    variantTextClass = 'blue-text';
  }
  return (
    <div className={`${variantContainerClass}`}>
      {icon ? (
        <div className='infoCircleSection'>
          <InfoCircleAlterSVG className='infoCircle' />
        </div>
      ) : null}
      <div className='flex flex-col'>
        <span className={`${variantTitleClass}`}>{title}</span>
        <span className={`${variantTextClass}`}>{text}</span>
      </div>
    </div>
  );
};

export default Highlight;
