import React, { useState, useEffect, useReducer } from 'react';
import { connect } from 'react-redux';
import { useQuestions } from 'src/queries/company';
import { Base } from 'src/components';
import appUtils from 'src/components/appUtils';
import COMMON_QUESTION_CONSTANTS from 'common/commonQuestionConstants';
import STYLE from 'src/constants/style';
import QuestionBuilderProvider from 'src/pagesDashboard/QuestionBuilder/context/QuestionBuilderProvider';
import ArchivedQuestions from 'src/containers/QuestionBuilder/ArchivedQuestions';
import CompanyQuestionsJSON from 'src/containers/QuestionBuilder/CompanyQuestionsJSON';
import UploadCategoriesAndQuestions from 'src/containers/OrganizationQuestions/Upload/UploadCategoriesAndQuestions';
import HeaderV2 from 'src/containers/Header/HeaderV2';
import { NewRole, NewCategory, NewQuestion } from './newSection';
import { RoleColumn, CategoryColumn, QuestionColumn } from './columns';
import reducer from './reducer';
import initialState from './initialState';

const { STATUS } = COMMON_QUESTION_CONSTANTS;

const Loading = () => (
  <>
    <HeaderV2 overtitle='Organization' title='Questions' />
    <Base classes={STYLE.BASE} />
  </>
);

const QuestionBuilder = ({ dispatch }) => {
  const [showArchived, setShowArchived] = useState(false);
  const {
    data: companyQuestions,
    isLoading: isFetchingQuestions,
    isError: isErrorQuestions
  } = useQuestions();

  const isFetching = isFetchingQuestions;
  const isError = isErrorQuestions;
  const isReady = companyQuestions && !isFetching && !isError;

  const [state, questionDispatch] = useReducer(reducer, initialState);
  const {
    roleModal,
    categoryModal,
    questionModal,
    selectedRole,
    selectedCategory,
    selectedQuestions,
    selectedIds
  } = state;

  const [categories, setCategories] = useState(null);

  const selectRole = (e, roleObject, roleKey) => {
    if (e) {
      e.preventDefault();
    }
    const { id: roleId } = roleObject;
    questionDispatch({ type: 'SET_SELECTED_ROLE', data: roleKey });
    questionDispatch({ type: 'SET_SELECTED_CATEGORY', data: undefined });
    questionDispatch({ type: 'SET_SELECTED_QUESTIONS', data: undefined });
    questionDispatch({
      type: 'SET_SELECTED_IDS',
      data: { ...selectedIds, role: roleId }
    });

    const categoryIds = companyQuestions.ROLES[roleKey].categories;
    const newCategories = {};
    Object.keys(companyQuestions.CATEGORIES).forEach((key) => {
      const categoryObj = companyQuestions.CATEGORIES[key];
      if (categoryObj.status !== STATUS.ACTIVE) {
        return;
      }
      if (categoryIds.indexOf(categoryObj.id) > -1) {
        newCategories[key] = categoryObj;
      }
    });
    setCategories(newCategories);
  };

  const selectCategory = (e, categoryObject, categoryKey) => {
    if (e) {
      e.preventDefault();
    }
    const { id: categoryId } = categoryObject;
    questionDispatch({ type: 'SET_SELECTED_CATEGORY', data: categoryKey });
    questionDispatch({
      type: 'SET_SELECTED_IDS',
      data: { ...selectedIds, category: categoryId }
    });

    const questionIds = companyQuestions.CATEGORIES[categoryKey].questions;
    const newQuestions = companyQuestions.QUESTIONS.filter(
      (question) => questionIds.indexOf(question.id) > -1
    );
    questionDispatch({ type: 'SET_SELECTED_QUESTIONS', data: newQuestions });
  };

  useEffect(() => {
    let roleKey;
    if (companyQuestions && selectedIds.role) {
      roleKey = Object.keys(companyQuestions.ROLES).find(
        (key) => companyQuestions.ROLES[key].id === selectedIds.role
      );
      if (roleKey) {
        const role = companyQuestions.ROLES[roleKey];
        selectRole(undefined, role, roleKey);
      }
    }

    if (companyQuestions && selectedIds.category) {
      const categoryKey = Object.keys(companyQuestions.CATEGORIES).find(
        (key) => companyQuestions.CATEGORIES[key].id === selectedIds.category
      );
      if (categoryKey) {
        const categoryObject = companyQuestions.CATEGORIES[categoryKey];
        selectCategory(undefined, categoryObject, categoryKey);
      }
    }
  }, [companyQuestions]);

  if (!isReady) {
    return <Loading />;
  }

  const editQuestion = (type, roleKey, categoryKey, questionId) => {
    questionDispatch({
      type: 'OPEN_QUESTION_MODAL',
      data: {
        show: true,
        type,
        roleKey,
        categoryKey,
        questionId
      }
    });
  };

  const editRole = (type, role, roleKey) => {
    questionDispatch({
      type: 'OPEN_ROLE_MODAL',
      data: {
        show: true,
        type,
        role,
        roleKey
      }
    });
    appUtils.scrollToTop();
  };

  const editCategory = (type, roleKey, category, categoryKey) => {
    questionDispatch({
      type: 'OPEN_CATEGORY_MODAL',
      data: {
        show: true,
        type,
        category,
        categoryKey,
        roleKey
      }
    });
    appUtils.scrollToTop();
  };

  const closeModal = () => {
    questionDispatch({ type: 'CLOSE_ALL_MODALS' });
    appUtils.scrollToTop();
  };

  if (roleModal.show) {
    return (
      <>
        <HeaderV2 overtitle='Organization' title='Questions' />
        <Base className={STYLE.BASE}>
          <NewRole
            role={roleModal.role}
            roleKey={roleModal.roleKey}
            type={roleModal.type}
            dispatch={dispatch}
            close={closeModal}
          />
        </Base>
      </>
    );
  }

  if (categoryModal.show) {
    return (
      <>
        <HeaderV2 overtitle='Organization' title='Questions' />
        <Base className={STYLE.BASE}>
          <NewCategory
            category={categoryModal.category}
            categoryKey={categoryModal.categoryKey}
            type={categoryModal.type}
            roleKey={categoryModal.roleKey}
            dispatch={dispatch}
            close={closeModal}
          />
        </Base>
      </>
    );
  }

  if (questionModal.show) {
    return (
      <>
        <HeaderV2 overtitle='Organization' title='Questions' />
        <Base className={STYLE.BASE}>
          <NewQuestion
            type={questionModal.type}
            roleKey={questionModal.roleKey}
            categoryKey={questionModal.categoryKey}
            questionId={questionModal.questionId}
            dispatch={dispatch}
            close={closeModal}
          />
        </Base>
      </>
    );
  }

  const isSuperUser = appUtils.isSuperUser();

  return (
    <>
      <HeaderV2 overtitle='Organization' title='Questions' />
      <Base classes={STYLE.BASE}>
        <div>
          <input
            onChange={(e) => setShowArchived(e.target.checked)}
            type='checkbox'
            checked={showArchived}
            className='inline-block align-middle'
          />
          <p className='text-black inline-block align-middle ml-2 mb-0'>
            Show archived resources
          </p>
        </div>

        <Base classes={STYLE.CONTAINER_WHITE_PADDINGLESS}>
          <div className='flex justify-evenly gap-3 px-2 pt-1'>
            <div className='w-1/3'>
              <RoleColumn
                companyQuestions={companyQuestions}
                selectedRole={selectedRole}
                selectRole={selectRole}
                editRole={editRole}
              />
            </div>
            <div className='w-1/3'>
              <CategoryColumn
                selectedRole={selectedRole}
                categories={categories}
                selectedCategory={selectedCategory}
                selectCategory={selectCategory}
                editCategory={editCategory}
              />
            </div>

            <div className='w-1/3'>
              <QuestionColumn
                selectedCategory={selectedCategory}
                questions={selectedQuestions}
                edit={editQuestion}
                roleKey={selectedRole}
                categoryKey={selectedCategory}
                options={{
                  showArchived
                }}
              />
            </div>
          </div>
          {isSuperUser ? <ArchivedQuestions /> : null}
          {isSuperUser ? <CompanyQuestionsJSON /> : null}
        </Base>
        {isSuperUser ? <UploadCategoriesAndQuestions /> : null}
      </Base>
    </>
  );
};

const QuestionBuilderPage = (props) => (
  <QuestionBuilderProvider>
    <QuestionBuilder {...props} />
  </QuestionBuilderProvider>
);

export const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(null, mapDispatchToProps)(QuestionBuilderPage);
